type IconMicrosoftAdsProps = {
  size: "24px";
  className?: string;
};

export const IconMicrosoftAds: React.FC<IconMicrosoftAdsProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M21.25 2L6.25 8H21.25V2Z" fill="#90BDE7" />
          <path d="M21.25 21.7L6.25 15.7H21.25V21.7Z" fill="#2B66A8" />
          <path
            d="M0.960012 8.39877C1.0037 8.16594 1.2077 8 1.4446 8H20.155C20.3919 8 20.5959 8.16594 20.6396 8.39877C20.7774 9.13313 21.0498 10.7268 21.0498 11.85C21.0498 12.9732 20.7774 14.5669 20.6396 15.3012C20.5959 15.5341 20.3919 15.7 20.155 15.7H1.4446C1.2077 15.7 1.0037 15.5341 0.960012 15.3012C0.822206 14.5669 0.549805 12.9732 0.549805 11.85C0.549805 10.7268 0.822207 9.13313 0.960012 8.39877Z"
            fill="#5398DB"
          />
          <path
            d="M0.987481 8.35282C1.04132 8.14122 1.23336 8 1.4517 8H6.2498V15.7H1.4517C1.23336 15.7 1.04132 15.5588 0.987481 15.3472C0.833196 14.7408 0.549805 13.4142 0.549805 11.85C0.549805 10.2858 0.833196 8.95918 0.987481 8.35282Z"
            fill="#3C80C8"
          />
          <ellipse cx="21.3996" cy="11.9" rx="2.05" ry="9.9" fill="#3D8BD9" />
          <rect
            x="1.75"
            y="10.5"
            width="11.5"
            height="11.5"
            rx="1"
            fill="#225387"
          />
          <path
            d="M9.28965 19.3L8.68838 17.6385H6.05381L5.48008 19.3H4.28672L6.779 12.7182H7.99072L10.4922 19.3H9.28965ZM7.38027 13.705H7.35273C7.33131 13.8886 7.29919 14.0462 7.25635 14.1778L6.35674 16.7159H8.37627L7.47207 14.1778C7.44147 14.0982 7.41087 13.9406 7.38027 13.705Z"
            fill="white"
          />
          <path
            d="M20.5378 15.3024C20.494 15.535 20.2901 15.7008 20.0533 15.7008H19.5497C19.5497 15.7008 19.3497 12.9009 19.3497 11.8508C19.3497 10.8007 19.5497 8.00081 19.5497 8.00081H20.0533C20.2901 8.00081 20.494 8.16656 20.5378 8.39922C20.6762 9.13341 20.9497 10.7274 20.9497 11.8508C20.9497 12.9742 20.6762 14.5682 20.5378 15.3024Z"
            fill="#3C7FC7"
          />
        </svg>
      );
  }
};
