type IconVoluumProps = {
  size: "24px";
  className?: string;
};

export const IconVoluum: React.FC<IconVoluumProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.61 4.37289L11.9978 0L19.3861 4.37243V5.89841L11.9978 18.763L5.57132 7.5762L7.64622 6.4066H15.3377L13.9714 8.7967H9.06343L11.9978 13.9833L16.8053 5.5934L11.9978 2.79647L4.61 7.11891V4.37289ZM17.0076 18.3052V11.7964L19.3861 7.62711V19.7289L11.9978 24L4.61 19.7289V7.72893L6.98899 11.8473V18.3052L11.9978 21.254L17.0076 18.3052Z"
            fill="#C800A0"
          />
        </svg>
      );
  }
};
