import { useParams } from "react-router-dom";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { type AppEducationSlideshowNode } from "../../api/handlers/appEducationSlideshows";
import { useCroIdeasQuery } from "../../api/handlers/croIdeas";
import { CroIdeasItem } from "./CroIdeasItem";
import { CroIdeasList } from "./CroIdeasList";

/* ========================================================================== */

type CroIdeasProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
};

export const CroIdeas: React.FC<CroIdeasProps> = ({
  appEducationSlideshows
}) => {
  const croIdeas = useCroIdeasQuery();
  const { slug } = useParams<{ slug?: string }>();

  if (croIdeas.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (croIdeas.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  if (slug) {
    const croIdea = croIdeas.data.find(idea => idea.slug === slug);

    if (croIdea) {
      return (
        <CroIdeasItem
          croIdea={croIdea}
          otherIdeas={croIdeas.data
            .filter(ci => ci.page === croIdea.page)
            .filter(ci => ci.id !== croIdea.id)}
        />
      );
    } else {
      return (
        <CenteredWrapper>
          <ErrorOccurred />
        </CenteredWrapper>
      );
    }
  } else {
    return (
      <CroIdeasList
        appEducationSlideshows={appEducationSlideshows}
        croIdeas={croIdeas.data}
      />
    );
  }
};

/* ========================================================================== */

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
