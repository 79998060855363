import styled, { css } from "styled-components";

/* ========================================================================== */

type RadioProps = {
  isSelected: boolean;
  isHovered: boolean;
  isFocused: boolean;
};

export const Radio = styled.div<RadioProps>`
  width: ${props => props.theme.gridBase * 2}px;
  height: ${props => props.theme.gridBase * 2}px;
  min-width: ${props => props.theme.gridBase * 2}px;
  min-height: ${props => props.theme.gridBase * 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.isSelected ? props.theme.palette.blue1 : props.theme.palette.grey5};
  background-color: ${props =>
    props.isSelected ? props.theme.palette.blue1 : props.theme.palette.white};
  transition:
    background-color ${props => props.theme.other.transition},
    border-color ${props => props.theme.other.transition},
    box-shadow ${props => props.theme.other.transition};

  &::before {
    content: "";
    display: block;
    width: ${props => props.theme.gridBase}px;
    height: ${props => props.theme.gridBase}px;
    background-color: ${props => props.theme.palette.white};
    border-radius: 50%;
    opacity: ${props => (props.isSelected ? 1 : 0)};
    visibility: ${props => (props.isSelected ? "visible" : "hidden")};
    transition:
      opacity ${props => props.theme.other.transition},
      visibility ${props => props.theme.other.transition};
  }

  ${props =>
    (props.isHovered || props.isFocused) &&
    css`
      border-color: ${props => props.theme.palette.blue1};
    `}

  ${props =>
    props.isFocused &&
    css`
      box-shadow: 0 0 0 2px ${props => props.theme.palette.blue4};
    `}
`;
