import dayjs from "dayjs";
import { transparentize } from "polished";
import styled from "styled-components";

import { ButtonPrimaryAsLink } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { IconCheckMark, IconPlus } from "elevar-design-system/src/icons";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  formatProductCategory,
  type PurchasedProduct,
  usePurchasedProductsQuery
} from "../../../api/handlers/products";
import { PageCard } from "../../../components/PageCard";
import { useCompanyId, useWebsiteId } from "../../../utils/idHooks";

/* ========================================================================== */

export const PurchasedProducts: React.FC = () => {
  const purchasedProducts = usePurchasedProductsQuery();

  if (purchasedProducts.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (purchasedProducts.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <PurchasedProductsContents purchasedProducts={purchasedProducts.data} />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type PurchasedProductsContentsProps = {
  purchasedProducts: Array<PurchasedProduct>;
};

const PurchasedProductsContents: React.FC<PurchasedProductsContentsProps> = ({
  purchasedProducts
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const hasPurchaseProduct = purchasedProducts.length > 0;
  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const exploreProductsUrl = `${websiteUrl}/settings/products/explore`;

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading>Audits & Setups</PageHeading>
      </PageHeader>
      <PageCard>
        <ExploreCardHeader>
          <ExploreCardIconWrapper>
            <div>
              <ElevarLogo />
            </div>
            <div>
              <IconPlus size="16px" />
            </div>
          </ExploreCardIconWrapper>
          <ExploreCardHeading>Elevar Audits & Setups</ExploreCardHeading>
        </ExploreCardHeader>
        <ExploreCardExplainer>
          Do you need experts to help move faster? Explore our "Done-for-you"
          products to maximize your value from Elevar.
        </ExploreCardExplainer>
        {!hasPurchaseProduct ? (
          <ExploreCardIncludedItems>
            <ExploreCardIncludedItem>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>One-Time Setups for GA, Facebook, etc.</div>
            </ExploreCardIncludedItem>
            <ExploreCardIncludedItem>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>Fully managed installations</div>
            </ExploreCardIncludedItem>
            <ExploreCardIncludedItem>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>Ad-hoc tagging requests</div>
            </ExploreCardIncludedItem>
            <ExploreCardIncludedItem>
              <div>
                <IconCheckMark size="16px" />
              </div>
              <div>Conversion optimization consultation</div>
            </ExploreCardIncludedItem>
          </ExploreCardIncludedItems>
        ) : null}
        <ButtonPrimaryAsLink variant="SMALL" to={exploreProductsUrl}>
          Explore Audits & Setups
        </ButtonPrimaryAsLink>
      </PageCard>
      {hasPurchaseProduct ? (
        <>
          <PurchasedStateSubHeading>Purchased</PurchasedStateSubHeading>
          <div>
            {purchasedProducts.map(purchasedProduct => (
              <PurchasedProductPageCard key={purchasedProduct.id}>
                <PurchasedProductCategory>
                  {formatProductCategory(purchasedProduct.category)}
                </PurchasedProductCategory>
                <PurchasedProductName>
                  {purchasedProduct.name}
                </PurchasedProductName>
                <PurchasedProductDetailsWrapper>
                  <PurchasedProductPrice>
                    ${purchasedProduct.price}
                  </PurchasedProductPrice>
                  <PurchasedProductPurchaseDate>
                    Purchased on{" "}
                    {dayjs(purchasedProduct.purchaseDate).format("MMM DD YYYY")}
                  </PurchasedProductPurchaseDate>
                </PurchasedProductDetailsWrapper>
              </PurchasedProductPageCard>
            ))}
          </div>
        </>
      ) : null}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 4}px;
  max-width: ${props => props.theme.gridBase * 70.5}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageHeading = styled.div`
  ${heading2Styles};
`;

const ExploreCardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ExploreCardIconWrapper = styled.div`
  display: flex;
  margin-right: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.gridBase * 6}px;
    height: ${props => props.theme.gridBase * 6}px;
    background-color: ${props => props.theme.palette.grey8};
    border-radius: 50%;

    > svg {
      width: ${props => props.theme.gridBase * 3.5}px;
      height: ${props => props.theme.gridBase * 3.5}px;
    }
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.gridBase * 2}px;
    height: ${props => props.theme.gridBase * 2}px;
    background-color: ${props => props.theme.palette.purple2};
    color: ${props => props.theme.palette.white};
    border-radius: 50%;
    margin-left: ${props => props.theme.gridBase * -1}px;
  }
`;

const ExploreCardHeading = styled.div`
  ${heading2Styles};
`;

const ExploreCardExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ExploreCardIncludedItems = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ExploreCardIncludedItem = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.purple2};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const PurchasedStateSubHeading = styled.div`
  ${heading3Styles};
  margin-top: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const PurchasedProductPageCard = styled(PageCard)`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const PurchasedProductCategory = styled.div`
  ${subheadingStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const PurchasedProductName = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 1.25}px;
`;

const PurchasedProductDetailsWrapper = styled.div`
  display: flex;
`;

const PurchasedProductPrice = styled.div`
  ${normalBodyStyles};
  font-weight: 500;
  margin-right: ${props => props.theme.gridBase * 1.5}px;
`;

const PurchasedProductPurchaseDate = styled.div`
  ${normalBodyStyles};
  padding: 0 ${props => props.theme.gridBase}px;
  border-radius: 2px;
  color: ${props => props.theme.palette.purple2};
  background-color: ${props =>
    transparentize(0.9, props.theme.palette.purple2)};
`;
