type IconCookiebotProps = {
  size: "24px";
  className?: string;
};

export const IconCookiebot: React.FC<IconCookiebotProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3578 17.5748C12.0715 17.2646 12.7139 16.8114 13.2453 16.2429C13.9311 17.074 14.7778 17.7579 15.7346 18.2535C14.8802 19.1807 13.8445 19.9225 12.6917 20.4331C11.5388 20.9436 10.2934 21.212 9.03263 21.2216H8.96883C6.57195 21.2005 4.28142 20.2292 2.60005 18.5209C0.918676 16.8126 -0.0161156 14.5069 0.000886622 12.11C-0.0247749 10.2832 0.507151 8.49201 1.52572 6.97528C2.54428 5.45856 4.00108 4.28844 5.7018 3.62098C7.40251 2.95353 9.2663 2.82046 11.0446 3.23954C12.8229 3.65862 14.4311 4.60992 15.6548 5.96656C14.6676 6.47231 13.8144 7.20514 13.1655 8.10481C12.0918 7.04023 10.6404 6.44365 9.12833 6.44527C7.59 6.44104 6.11296 7.04809 5.02218 8.13289C3.93139 9.21768 3.31622 10.6914 3.31199 12.2297C3.30776 13.7681 3.91481 15.2451 4.99961 16.3359C6.08441 17.4267 7.55808 18.0418 9.09643 18.0461C9.87454 18.0454 10.6442 17.8849 11.3578 17.5748ZM14.5375 8.28634C15.5579 7.23895 16.9527 6.63984 18.4148 6.62079C19.1393 6.60961 19.8588 6.74168 20.5322 7.0094C21.2054 7.27712 21.8192 7.67522 22.3381 8.18085C22.8571 8.68647 23.2711 9.28964 23.5562 9.95572C23.8414 10.6218 23.9921 11.3376 23.9999 12.0622V12.1419C24.0101 13.5928 23.4447 14.9886 22.4278 16.0235C21.4109 17.0583 20.0253 17.648 18.5744 17.6631C18.5744 17.6471 18.5744 17.6471 18.5584 17.6471C17.0963 17.6662 15.6864 17.1036 14.639 16.0832C13.5916 15.0627 12.9925 13.668 12.9734 12.2058C12.9544 10.7436 13.517 9.33373 14.5375 8.28634ZM16.5167 14.1517C17.0591 14.6941 17.7915 15.0035 18.5584 15.0143C19.1329 15.0143 19.6944 14.8439 20.172 14.5248C20.6496 14.2057 21.0218 13.7521 21.2416 13.2214C21.4614 12.6907 21.5189 12.1068 21.4069 11.5434C21.2948 10.9801 21.0182 10.4626 20.612 10.0565C20.2059 9.6503 19.6884 9.37371 19.125 9.26165C18.5616 9.14959 17.9777 9.2071 17.4471 9.42692C16.9164 9.64673 16.4629 10.019 16.1437 10.4966C15.8246 10.9741 15.6543 11.5356 15.6543 12.11C15.665 12.8769 15.9744 13.6094 16.5167 14.1517Z"
            fill="#1032CF"
          />
        </svg>
      );
  }
};
