type IconRetentionProps = {
  size: "24px";
  className?: string;
};

export const IconRetention: React.FC<IconRetentionProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5611 11.7373C9.26641 10.5975 9.57626 8.49496 11.1365 7.77568C12.0218 7.36624 13.0731 7.56542 13.7813 8.26258C14.9653 9.45769 14.6444 11.361 13.1395 12.0582C12.221 12.4897 11.2804 12.368 10.5611 11.7373ZM13.8366 9.51302C13.6928 8.94866 13.1505 8.37323 12.6304 8.22938C11.9001 8.03019 11.3468 8.19618 10.8267 8.7716C10.1406 9.53515 10.1627 10.4979 10.882 11.184C12.1767 12.4123 14.2903 11.2172 13.8366 9.51302ZM8.09275 14.3488C8.39154 13.7512 9.13294 13.1315 9.69731 12.9988C10.3391 12.8549 10.4166 12.8549 11.0252 13.043C11.7113 13.2533 12.3531 13.2533 13.0392 13.043C14.2454 12.6779 15.529 13.3086 16.038 14.5037C16.1155 14.7029 16.1819 15.2341 16.1819 15.6989V16.5288H11.9658H7.73865L7.80504 15.6656C7.83824 15.1013 7.93783 14.6365 8.09275 14.3488ZM12.6408 13.7512C12.1982 13.8398 11.8773 13.8398 11.4789 13.7623C10.2838 13.4967 9.85222 13.5078 9.38745 13.7955C8.80097 14.1607 8.4358 14.7914 8.4358 15.4444V15.9755H12.0322H15.6286V15.5329C15.6286 14.9131 15.4073 14.4373 14.9204 14.0057C14.3118 13.4746 14.1015 13.4525 12.6408 13.7512Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4458 3.56941L7.5264 0.649994L6.48376 1.69264L7.50546 2.71433H3.53471H2.64999V3.59904V20.4086V21.2934H3.53471H7.67438V19.5239H4.41942V4.48376H7.48631L6.48376 5.4863L7.5264 6.52894L10.4458 3.60951L10.4258 3.58948L10.4458 3.56941ZM13.5615 20.4383L16.4809 23.3577L17.5235 22.3151L16.5019 21.2934H20.4726H21.3573V20.4086V3.59904V2.71433H20.4726H16.3329V4.48376H19.5879V19.5239H16.521L17.5235 18.5214L16.4809 17.4788L13.5615 20.3982L13.5816 20.4182L13.5615 20.4383Z"
            fill="#639FF7"
          />
        </svg>
      );
  }
};
