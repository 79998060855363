type IconKameleoonProps = {
  size: "24px";
  className?: string;
};

export const IconKameleoon: React.FC<IconKameleoonProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3.61459 13.7848C3.61459 17.4219 6.57811 20.3854 10.2152 20.3854C13.8522 20.3854 16.8157 17.4219 16.8157 13.7848C16.8157 10.1478 13.8522 7.18428 10.2152 7.18428C6.57811 7.18428 3.61459 10.1478 3.61459 13.7848ZM7.20674 13.7848C7.20674 12.1235 8.55379 10.7764 10.2152 10.7764C11.8765 10.7764 13.2236 12.1235 13.2236 13.7848C13.2236 15.4462 11.8765 16.7933 10.2376 16.7933C8.55379 16.7933 7.20674 15.4462 7.20674 13.7848Z"
            fill="#FE2020"
          />
          <path
            d="M10.2152 3.59215V7.18429C6.57811 7.18429 3.61459 10.1478 3.61459 13.7849C3.61459 17.4219 6.57811 20.3854 10.2152 20.3854C13.8522 20.3854 16.8157 17.4443 16.8157 13.7849H20.4079C20.4079 19.42 15.8279 23.9776 10.1927 23.9776C4.55753 24 0 19.42 0 13.7849C0 8.14968 4.55753 3.59215 10.2152 3.59215Z"
            fill="#2EE5AC"
          />
          <path
            d="M10.2151 3.59214V0.606174C10.2151 0.269411 10.4846 0 10.8213 0H10.8438C17.9832 0.314312 23.6857 6.03929 24 13.1562C24.0224 13.493 23.753 13.7624 23.4387 13.7848H23.4163H20.4079C20.4079 8.14967 15.8503 3.59214 10.2151 3.59214Z"
            fill="#2EE5AC"
          />
          <path
            d="M10.2151 7.18429V3.59215C15.8503 3.59215 20.4079 8.14968 20.4303 13.7849H16.8382C16.8157 10.1478 13.8746 7.18429 10.2151 7.18429Z"
            fill="#6464FF"
          />
          <path
            d="M10.2152 16.7933C11.8765 16.7933 13.2236 15.4462 13.2236 13.7848C13.2236 12.1235 11.8765 10.7764 10.2152 10.7764C8.55379 10.7764 7.20674 12.1235 7.20674 13.7848C7.20674 15.4462 8.55379 16.7933 10.2152 16.7933Z"
            fill="#0A0A0A"
          />
        </svg>
      );
  }
};
