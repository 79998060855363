import dayjs from "dayjs";
import { createContext, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { IconCircledInfo } from "elevar-design-system/src/icons";
import { linkStyles } from "elevar-design-system/src/links/links";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type Timezone =
  | { selection: "SYSTEM" }
  | { selection: "WEBSITE"; value: string };

const timezoneContext = createContext<Timezone | undefined>(undefined);

/* ========================================================================== */

const useSystemTimezoneStorageKey = "use-system-timezone";

export const getPersistedTimezoneSelection = (): Timezone["selection"] => {
  return localStorage.getItem(useSystemTimezoneStorageKey)
    ? "SYSTEM"
    : "WEBSITE";
};

export const setPersistedTimezoneSelection = (
  timezone: Timezone["selection"]
) => {
  if (timezone === "SYSTEM") {
    localStorage.setItem(useSystemTimezoneStorageKey, "true");
  } else {
    localStorage.removeItem(useSystemTimezoneStorageKey);
  }
};

type TimezoneProviderProps = {
  websiteDetails: WebsiteDetails;
  children: React.ReactNode;
};

export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({
  websiteDetails,
  children
}) => {
  const selection = getPersistedTimezoneSelection();

  const value = useMemo<Timezone>(
    () =>
      selection === "SYSTEM"
        ? { selection: "SYSTEM" }
        : { selection: "WEBSITE", value: websiteDetails.timezone },
    [selection, websiteDetails.timezone]
  );

  return (
    <timezoneContext.Provider value={value}>
      {children}
    </timezoneContext.Provider>
  );
};

/* ========================================================================== */

export const useTimezone = () => {
  const timezone = useContext(timezoneContext);

  if (timezone !== undefined) {
    return timezone;
  } else {
    throw new Error("`useTimezone`: value not set");
  }
};

export const useZonedDayjs = (): typeof dayjs => {
  const timezone = useTimezone();

  if (timezone.selection === "SYSTEM") {
    return dayjs;
  } else {
    const fn = (...args: Parameters<typeof dayjs>) => {
      return dayjs(...args).tz(timezone.value);
    };
    return fn as typeof dayjs;
  }
};

/* ========================================================================== */

export const TimezoneNotice: React.FC = () => {
  const theme = useTheme();
  const timezone = useTimezone();

  return (
    <TooltipBig
      placement="top"
      maxWidth={`${theme.gridBase * 30}px`}
      render={() => (
        <TimezoneNoticeTooltipContent>
          {timezone.selection === "WEBSITE" ? (
            <p>
              Your Website's timezone is:
              <br />"{timezone.value}"
            </p>
          ) : null}
          <p>
            You can change the timezone used in your{" "}
            <Link to="/account/general">Account Settings</Link>.
          </p>
        </TimezoneNoticeTooltipContent>
      )}
    >
      <TimezoneNoticeWrapper>
        <div>
          {timezone.selection === "SYSTEM"
            ? "Using your system timezone"
            : "Using your Website's timezone"}
        </div>
        <div>
          <IconCircledInfo size="16px" />
        </div>
      </TimezoneNoticeWrapper>
    </TooltipBig>
  );
};

const TimezoneNoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  cursor: default;

  > div:first-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey3};
  }

  > div:last-child {
    display: flex;
    color: ${props => props.theme.palette.grey3};
  }
`;

const TimezoneNoticeTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  a {
    ${linkStyles};
  }
`;
