import dayjs from "dayjs";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCheckMark } from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading3Styles,
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  useDestinationOAuthConnectMutation,
  useDestinationOAuthDisconnectMutation
} from "../../api/handlers/website";
import { ActionWarningModal } from "../../components/ActionWarningModal";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { formatTitle } from "../../utils/format";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { destinationAttv as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-attentive-in-elevar-server-side-destination";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationAttvProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationAttv: React.FC<DestinationAttvProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Attentive Settings" },
          { name: "Events" },
          { name: "Consent Mode" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" }
        ]}
        context={{ setSaveModalState, shopifyOAuthScopes }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
  }
};

/* ========================================================================== */

const recommendedEvents: ReturnType<typeof useConfig>["enabledEvents"] = {
  purchase: true,
  addToCart: true,
  viewItem: true
};

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () => (
                  <>
                    Attentive account <span>connected</span>
                  </>
                )
              },
              {
                step: 2,
                type: "EVENTS",
                selectedEvents: {
                  enabledEvents: config.enabledEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                },
                recommendedEvents: getRecommendedEventState({
                  destination,
                  enabledEvents: recommendedEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                })
              },
              {
                step: 3,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 4,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 5,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Set up this server-side tracking integration to:
          <ul>
            <li>Improve Abandonment Flow performance</li>
            <li>
              Identify abandoners that your client-side tracking misses due to
              cookie limitations
            </li>
          </ul>
          <StyledLinkExternal
            href="https://www.getelevar.com/how-to/improve-klaviyo-abandoned-flow-performance/"
            text="Learn more"
          />
        </Step0Explainer>
      }
      whatsNextOverrides={{
        title: "Set Up Your Split Test",
        explainer: (
          <>
            Send messages to only the net new abandoners identified by Elevar so
            that you can see the net new revenue generated.
          </>
        ),
        media: { type: "NONE", spanContent: true },
        link: {
          href: "https://docs.getelevar.com/docs/how-to-calculate-additional-revenue-from-attentive-server-side-tracking",
          text: "How to Setup Attentive Split Test Journeys"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > ul {
    margin-top: ${props => props.theme.gridBase}px;
    margin-bottom: ${props => props.theme.gridBase * 2}px;
    padding-left: ${props => props.theme.gridBase * 2}px;

    > li {
      list-style: disc;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 0.25}px;
      }
    }
  }
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const { mutateAsync: destinationOAuthConnectMutation } =
    useDestinationOAuthConnectMutation({ target: destination.configKey });
  const { mutateAsync: destinationOAuthDisconnectMutation } =
    useDestinationOAuthDisconnectMutation({ target: destination.configKey });

  const [isLoading, setIsLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const isConnected = config.accessToken !== "";
  const authDate = config.last_authed_at ? dayjs(config.last_authed_at) : null;
  const isStepCompleted = completedStep !== null && completedStep >= 1;

  return (
    <>
      <Step1Wrapper>
        <PageCard>
          <StepSection
            title="Attentive Settings"
            setupGuideHref={setupGuideHref}
            description={
              <Step1Explainer>
                To start sending your e-commerce funnel data to Attentive,
                please connect your account.
              </Step1Explainer>
            }
          >
            {isConnected ? (
              <Step1ConnectedWrapper>
                <div>
                  <div>
                    <div>
                      <IconCheckMark size="24px" />
                    </div>
                    <div>Account connected</div>
                  </div>
                  {authDate ? (
                    <div>
                      <div>Last updated:</div>
                      <div>{authDate.format("MMM DD YYYY")}</div>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button onClick={() => setIsModalShown(true)}>
                    Disconnect
                  </button>
                </div>
              </Step1ConnectedWrapper>
            ) : (
              <Step1ConnectButton
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);

                  try {
                    const result = await destinationOAuthConnectMutation();
                    window.location.replace(result.redirect_url);
                  } catch (error) {
                    setIsLoading(false);
                    toast.errorUnexpected(error);
                  }
                }}
              >
                <destination.icon size="16px" color={theme.palette.white} />
                <div>Connect Attentive Account</div>
              </Step1ConnectButton>
            )}
          </StepSection>
          {isConnected ? (
            <MarketGroupSettings
              config={config}
              destination={destination}
              isLoading={isLoading && !isModalShown}
              isStepCompleted={isStepCompleted}
              onSave={async data => {
                const action = async () => {
                  setIsLoading(true);
                  await configMutation({
                    ...data,
                    ...(!isStepCompleted ? { completedStep: 1 } : {})
                  });
                  setIsLoading(false);
                };

                if (config.live) {
                  setSaveModalState({ isVisible: true, onConfirm: action });
                } else {
                  await action();
                }
              }}
            />
          ) : null}
        </PageCard>
      </Step1Wrapper>
      {isConnected ? (
        <ActionWarningModal
          isVisible={isModalShown}
          onClose={() => setIsModalShown(false)}
          isLoading={isLoading}
          subheading={formatTitle(destination.name, config.label)}
          heading="Disconnect Attentive Account"
          text="By disconnecting your account, you're accepting that:"
          checkBoxItems={[
            "Your integration will be automatically set to offline",
            "Your integration will stop receiving conversions data",
            "Your conversion data accuracy will be interrupted"
          ]}
          confirmActionText="Disconnect"
          onConfirmAction={async () => {
            setIsLoading(true);
            await destinationOAuthDisconnectMutation();
            await configMutation({
              live: false,
              testMode: false,
              completedStep: 0
            });
            setIsLoading(false);
            toast.success("Attentive disconnected");
          }}
          cancelActionText="Go Back"
        />
      ) : null}
    </>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1ConnectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    > div:first-child {
      display: flex;
      gap: ${props => props.theme.gridBase}px;

      > div:first-child {
        color: ${props => props.theme.palette.green};
      }

      > div:last-child {
        ${normalBodyStyles};
        font-weight: 500;
      }
    }

    > div:not(:first-child) {
      ${smallTextStyles};
      display: flex;
      gap: ${props => props.theme.gridBase}px;
      margin-top: ${props => props.theme.gridBase * 1.5}px;

      > div:first-child {
        color: ${props => props.theme.palette.grey2};
      }

      > div:last-child {
        color: ${props => props.theme.palette.grey1};
      }
    }
  }

  > div:last-child {
    > button {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }
`;

const Step1ConnectButton = styled(ButtonPrimary)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, shopifyOAuthScopes }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Attentive.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={isLoading}
        isStepCompleted={isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async () => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...eventState,
              ...(!isStepCompleted ? { completedStep: 2 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 5 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};
