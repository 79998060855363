type IconGoogleCloudProps = {
  size: "24px";
  className?: string;
};

export const IconGoogleCloud: React.FC<IconGoogleCloudProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.9613 7.32674L18.0475 5.24052L18.1865 4.36208C14.3849 0.905238 8.34166 1.29721 4.91463 5.17996C3.9627 6.25836 3.25648 7.60283 2.87988 8.99118L3.62707 8.88589L7.79941 8.19786L8.12154 7.86843C9.97751 5.83011 13.1156 5.55589 15.2585 7.29018L15.9613 7.32674Z"
            fill="#EA4335"
          />
          <path
            d="M21.0194 8.92981C20.5399 7.16393 19.5554 5.57647 18.1866 4.36212L15.2586 7.29012C16.4949 8.30037 17.1993 9.82231 17.1691 11.4186V11.9383C18.6082 11.9383 19.7751 13.1051 19.7751 14.5442C19.7751 15.9835 18.6082 17.1209 17.1691 17.1209H11.9499L11.4375 17.6772V20.8029L11.9499 21.2933H17.1691C20.9125 21.3224 23.9708 18.3407 24 14.5973C24.0176 12.3282 22.8989 10.2011 21.0194 8.92981Z"
            fill="#4285F4"
          />
          <path
            d="M6.7379 21.2933H11.9497V17.1209H6.7379C6.36656 17.1208 6.00693 17.041 5.66925 16.8867L4.92984 17.1136L2.829 19.1998L2.646 19.9099C3.82415 20.7995 5.26162 21.2997 6.7379 21.2933Z"
            fill="#34A853"
          />
          <path
            d="M6.73784 7.75865C2.99431 7.78096 -0.0221895 10.8338 0.000122987 14.5773C0.0125917 16.6677 0.988904 18.6355 2.64594 19.9099L5.66919 16.8867C4.35753 16.2941 3.77469 14.7505 4.36728 13.439C4.95978 12.1274 6.50347 11.5446 7.81493 12.1371C8.3929 12.3981 8.85575 12.8611 9.11684 13.439L12.1401 10.4158C10.8537 8.73421 8.855 7.75106 6.73784 7.75865Z"
            fill="#FBBC05"
          />
        </svg>
      );
  }
};
