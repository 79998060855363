import styled from "styled-components";

import { type InputFieldProps, inputFieldStyles } from "./sharedInputStyles";

/* ========================================================================== */

type InputFieldTextProps = InputFieldProps & {
  type?: "email" | "password" | "search" | "tel" | "text" | "url";
};

export const InputFieldText = styled.input<InputFieldTextProps>`
  ${inputFieldStyles}
`;
