import { useId } from "react";

/* ========================================================================== */

type IconGoogleProps = {
  size: "24px";
  className?: string;
};

export const IconGoogle: React.FC<IconGoogleProps> = ({ size, className }) => {
  const idPrefix = useId();
  const clipPathId = `${idPrefix}-clipPath`;

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath={`url(#${clipPathId})`}>
            <path
              d="M23.524 12.2753C23.524 11.4595 23.4579 10.6394 23.3168 9.83691H11.998V14.4579H18.4798C18.2108 15.9482 17.3466 17.2666 16.0811 18.1044V21.1028H19.9481C22.2189 19.0127 23.524 15.9262 23.524 12.2753Z"
              fill="#4285F4"
            />
            <path
              d="M11.9989 24.0003C15.2353 24.0003 17.9647 22.9377 19.9533 21.1034L16.0863 18.1051C15.0105 18.837 13.6215 19.2515 12.0033 19.2515C8.87267 19.2515 6.21825 17.1394 5.26584 14.2998H1.27539V17.3907C3.3125 21.4429 7.46169 24.0003 11.9989 24.0003Z"
              fill="#34A853"
            />
            <path
              d="M5.26059 14.2987C4.75792 12.8084 4.75792 11.1946 5.26059 9.70422V6.61328H1.27455C-0.427454 10.0041 -0.427454 13.9989 1.27455 17.3897L5.26059 14.2987Z"
              fill="#FBBC04"
            />
            <path
              d="M11.9989 4.74868C13.7097 4.72223 15.3632 5.36599 16.6022 6.54769L20.0283 3.12164C17.8589 1.08453 14.9796 -0.0354426 11.9989 -0.000167897C7.46169 -0.000167897 3.3125 2.55725 1.27539 6.61383L5.26143 9.70477C6.20943 6.86075 8.86826 4.74868 11.9989 4.74868Z"
              fill="#EA4335"
            />
          </g>
          <defs>
            <clipPath id={clipPathId}>
              <rect width="23.5238" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};
