import { FocusOn } from "react-focus-on";
import styled from "styled-components";

import { Overlay } from "./Overlay";

/* ========================================================================== */

type ModalProps = {
  isVisible: boolean;
  onClose: () => void;
  disallowClose?: boolean;
  children: React.ReactNode;
};

export const Modal: React.FC<ModalProps> = ({
  isVisible,
  onClose,
  disallowClose = false,
  children
}) => {
  return (
    <Overlay isVisible={isVisible} onClose={() => !disallowClose && onClose()}>
      {isVisible ? (
        <FocusOn>
          <ModalWrapper>
            <div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div onClick={event => event.stopPropagation()}>
                <div>{children}</div>
              </div>
            </div>
          </ModalWrapper>
        </FocusOn>
      ) : null}
    </Overlay>
  );
};

/* ========================================================================== */

const ModalWrapper = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;

  > div {
    max-height: 100vh;
    max-width: 100vw;

    > div {
      padding: ${props => props.theme.gridBase}px;

      > div {
        border-radius: 4px;
        padding: ${props => props.theme.gridBase * 4}px;
        background-color: ${props => props.theme.palette.white};
        box-shadow: ${props => props.theme.other.boxShadowDropdown};
        overflow-x: hidden;
      }
    }
  }
`;
