import { useQuery } from "@tanstack/react-query";

import { type WebsiteFeatureRoute } from "../types";
import { graphqlRequest } from "../utils";

/* ========================================================================== */

type AppEducationSlideImage = {
  title: string;
  file: string; // URL
  width: number;
  height: number;
};

type AppEducationSlideEmbed = {
  url: string; // URL
};

type AppEducationSlide = {
  id: string | number; // ID
  title: string;
  content: string; // HTML
  image: AppEducationSlideImage;
  embed: AppEducationSlideEmbed | null;
};

export type AppEducationSlideshowNode = {
  page: WebsiteFeatureRoute;
  slides: Array<AppEducationSlide>;
};

/* -------------------------------------------------------------------------- */

type AppEducationSlideshowsResponse = {
  data: { appEducationSlideshows: Array<AppEducationSlideshowNode> };
};

const fetchAppEducationSlideshows = async () => {
  const response = await graphqlRequest<AppEducationSlideshowsResponse>({
    data: {
      query: /* GraphQL */ `
        query AppEducationSlideshows {
          appEducationSlideshows {
            page
            slides {
              id
              title
              content
              image {
                title
                file
                width
                height
              }
              embed {
                url
              }
            }
          }
        }
      `
    }
  });

  return response.data.appEducationSlideshows;
};

export const useAppEducationSlideshowsQuery = () => {
  return useQuery({
    queryKey: ["cms", "appEducationSlideshows"],
    queryFn: fetchAppEducationSlideshows
  });
};
