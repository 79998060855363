type IconGAProps = {
  size: "24px" | "16px";
  className?: string;
};

export const IconGA: React.FC<IconGAProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.1136 3.625V20.125C16.1136 21.9713 17.3887 23 18.7387 23C19.9887 23 21.3637 22.125 21.3637 20.125V3.75C21.3637 2.0575 20.1137 1 18.7387 1C17.3637 1 16.1136 2.16625 16.1136 3.625Z"
            fill="#F9AB00"
          />
          <path
            d="M9.23865 11.9991V20.1241C9.23865 21.9704 10.5137 22.9991 11.8637 22.9991C13.1137 22.9991 14.4887 22.1241 14.4887 20.1241V12.1241C14.4887 10.4316 13.2387 9.37411 11.8637 9.37411C10.4887 9.37411 9.23865 10.5404 9.23865 11.9991Z"
            fill="#E37400"
          />
          <path
            d="M4.98866 22.9993C6.43842 22.9993 7.61367 21.824 7.61367 20.3743C7.61367 18.9245 6.43842 17.7493 4.98866 17.7493C3.53891 17.7493 2.36365 18.9245 2.36365 20.3743C2.36365 21.824 3.53891 22.9993 4.98866 22.9993Z"
            fill="#E37400"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M11 1.90909V13.9091C11 15.2518 11.9273 16 12.9091 16C13.8182 16 14.8182 15.3636 14.8182 13.9091V2C14.8182 0.769091 13.9091 0 12.9091 0C11.9091 0 11 0.848182 11 1.90909Z"
            fill="#F9AB00"
          />
          <path
            d="M6 7.99937V13.9085C6 15.2512 6.92728 15.9994 7.9091 15.9994C8.8182 15.9994 9.8182 15.363 9.8182 13.9085V8.09028C9.8182 6.85937 8.90911 6.09028 7.9091 6.09028C6.9091 6.09028 6 6.93846 6 7.99937Z"
            fill="#E37400"
          />
          <path
            d="M2.9091 15.9995C3.96347 15.9995 4.8182 15.1448 4.8182 14.0904C4.8182 13.036 3.96347 12.1813 2.9091 12.1813C1.85473 12.1813 1 13.036 1 14.0904C1 15.1448 1.85473 15.9995 2.9091 15.9995Z"
            fill="#E37400"
          />
        </svg>
      );
  }
};
