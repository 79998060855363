type IconYotpoProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconYotpo: React.FC<IconYotpoProps> = ({
  size,
  color,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12.0655 23.4416C18.5388 23.4416 23.7864 18.1941 23.7864 11.7208C23.7864 5.24759 18.5388 0 12.0655 0C5.59232 0 0.344727 5.24759 0.344727 11.7208C0.344727 18.1941 5.59232 23.4416 12.0655 23.4416Z"
            fill={color ? "white" : "#0042E4"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.61133 6.112H10.3083L12.0654 10.9563L13.9062 6.112H16.5195L12.0508 17.3296H9.46623L10.788 13.8538L7.61133 6.112Z"
            fill={color ?? "white"}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8.04337 15.6278C12.3589 15.6278 15.8573 12.1294 15.8573 7.81388C15.8573 3.49839 12.3589 0 8.04337 0C3.72789 0 0.229492 3.49839 0.229492 7.81388C0.229492 12.1294 3.72789 15.6278 8.04337 15.6278Z"
            fill={color ? "white" : "#0042E4"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.07373 4.07468H6.87174L8.04314 7.30421L9.27029 4.07468H11.0125L8.03338 11.5531H6.31033L7.19149 9.23589L5.07373 4.07468Z"
            fill={color ?? "white"}
          />
        </svg>
      );
  }
};
