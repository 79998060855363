import styled, { keyframes, useTheme } from "styled-components";

import { IconCirclePartial } from "./icons";

/* ========================================================================== */

type SpinnerProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const Spinner: React.FC<SpinnerProps> = ({ size, color, className }) => {
  const theme = useTheme();

  return (
    <SpinnerWrapper className={className}>
      <IconCirclePartial size={size} color={color ?? theme.palette.grey2} />
    </SpinnerWrapper>
  );
};

/* ========================================================================== */

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

const SpinnerWrapper = styled.div`
  animation: ${rotate} 1.4s linear infinite;
  display: flex;
  align-items: center;
`;
