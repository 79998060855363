import { useQuery } from "@tanstack/react-query";

import { graphqlRequest } from "../utils";

/* ========================================================================== */

type CroIdeaImage = {
  title: string;
  file: string; // URL
  width: number;
  height: number;
};

type CroIdeaEmbed = {
  url: string; // URL
};

type CroIdeaSubmitter = {
  name: string;
  company: string;
  companyLink: string | null; // URL
  picture: CroIdeaImage;
};

export type CroIdea = {
  id: string | number; // ID
  title: string;
  content: string; // HTML
  slug: string;
  image: CroIdeaImage;
  embed: CroIdeaEmbed | null;
  impact: 1 | 2 | 3 | 4 | 5;
  effort: "EASY" | "MEDIUM" | "HARD";
  metrics: Array<
    | "ADD_TO_CART"
    | "AOV"
    | "CONVERSION"
    | "INITIATE_CHECKOUT"
    | "PDP_VIEWS"
    | "PLP_VIEWS"
    | "REVENUE_PER_SESSION"
  >;
  page: "SITEWIDE" | "HOMEPAGE" | "PLP" | "PDP" | "CART" | "CHECKOUT";
  submitter: CroIdeaSubmitter;
};

/* -------------------------------------------------------------------------- */

type CroIdeasResponse = {
  data: { croIdeas: Array<CroIdea> };
};

const fetchCroIdeas = async () => {
  const response = await graphqlRequest<CroIdeasResponse>({
    data: {
      query: /* GraphQL */ `
        query CroIdeas {
          croIdeas {
            id
            title
            content
            slug
            image {
              title
              file
              width
              height
            }
            embed {
              url
            }
            impact
            effort
            metrics
            page
            submitter {
              name
              company
              companyLink
              picture {
                title
                file
                width
                height
              }
            }
          }
        }
      `
    }
  });

  return response.data.croIdeas;
};

export const useCroIdeasQuery = () => {
  return useQuery({
    queryKey: ["cms", "croIdeas"],
    queryFn: fetchCroIdeas
  });
};
