import { type Filter, type ResultSet } from "@cubejs-client/core";
import { groupBy, partition } from "lodash-es";
import { useMemo } from "react";
import { type DefaultTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";
import { type MonitoringChannel } from "elevar-common-ts/src/monitoringTypes";
import { isNotNull } from "elevar-common-ts/src/utils";

import { useCubeQuery } from "../../../api/handlers/cube";
import { type NoneExplainerDetails } from "../../../components/NoneExplainer";
import {
  getCubeDateRangeFromMonitoringTimePeriod,
  getCubeGranularityFromMonitoringTimePeriod,
  type MonitoringTimePeriod
} from "../../../context/MonitoringTimePeriod";
import { formatTitle } from "../../../utils/format";
import { type Destination, destinations } from "../../myTracking/data";

/* ========================================================================== */

type ValidInfoItem = {
  destination: Destination;
  configs: EventsConnectorConfig[Destination["configKey"]];
};

type ConversionAccuracyQueryData = {
  "ServerSideEvents.channel": MonitoringChannel;
  "ServerSideEvents.config_id": number;
  "ServerSideEvents.provided_market": string;
  "ServerSideEvents.success_count": number;
  "ServerSideEvents.ignored_count": number;
  "ServerSideEvents.failure_count": number;
  "ServerSideEvents.time": string;
};

type TransformResultSetArgs = {
  eventsConnectorConfig: EventsConnectorConfig;
  validInfo: Array<ValidInfoItem>;
  resultSet: ResultSet<ConversionAccuracyQueryData>;
};

type MappedPoint = {
  time: string;
  successCount: number;
  ignoredCount: number;
  failureCount: number;
};

export type TransformedDataItem = {
  config: EventsConnectorConfig[Destination["configKey"]][number];
  destination: Destination;
  shopifyPoints: Array<MappedPoint>;
  channelPoints: Array<MappedPoint>;
  title: string;
};

type TransformResultSet = (
  args: TransformResultSetArgs
) => Array<TransformedDataItem>;

const transformResultSet: TransformResultSet = ({
  eventsConnectorConfig,
  validInfo,
  resultSet
}) => {
  const { marketGroups } = eventsConnectorConfig;
  const { marketsEnabled } = eventsConnectorConfig.globalConfig;

  const rawData = resultSet.rawData();
  const times = resultSet.categories().map(i => i.x);

  const [shopifyEntries, channelsEntries] = partition(
    Object.entries(groupBy(rawData, i => i["ServerSideEvents.channel"])),
    ([channel]) => channel === "Shopify"
  );

  const getFilterData = (
    config: EventsConnectorConfig[Destination["configKey"]][number],
    data: Array<ConversionAccuracyQueryData>
  ) => {
    if (!marketsEnabled || config.all_markets) {
      return data;
    } else {
      const configMarketGroups = marketGroups.filter(group =>
        config.market_groups.includes(group.id)
      );

      return data.filter(point =>
        configMarketGroups.some(group =>
          group.markets.some(
            m => m.external_id === point["ServerSideEvents.provided_market"]
          )
        )
      );
    }
  };

  const parsePoints = (points: Array<ConversionAccuracyQueryData>) => {
    return times.map<MappedPoint>(time => {
      return points
        .filter(p => p["ServerSideEvents.time"] === time)
        .reduce<MappedPoint>(
          (acc, point) => ({
            time,
            successCount:
              acc.successCount + point["ServerSideEvents.success_count"],
            ignoredCount:
              acc.ignoredCount + point["ServerSideEvents.ignored_count"],
            failureCount:
              acc.failureCount + point["ServerSideEvents.failure_count"]
          }),
          { time, successCount: 0, ignoredCount: 0, failureCount: 0 }
        );
    });
  };

  const allShopifyData = shopifyEntries[0]?.[1] ?? [];

  const channelData = channelsEntries.flatMap(([name, points]) => {
    const info = validInfo.find(i => i.destination.name === name)!;

    const configEntries = Object.entries(
      groupBy(points, point => point["ServerSideEvents.config_id"])
    );

    return configEntries.map(([configId, points]) => {
      const config = info.configs.find(c => c.id === Number(configId))!;
      return {
        config,
        destination: info.destination,
        shopifyPoints: parsePoints(getFilterData(config, allShopifyData)),
        channelPoints: parsePoints(getFilterData(config, points)),
        title: formatTitle(info.destination.name, config.label)
      };
    });
  });

  return validInfo.flatMap(info =>
    info.configs.map<(typeof channelData)[number]>(
      config =>
        channelData.find(channel => channel.config === config) ?? {
          config,
          destination: info.destination,
          shopifyPoints: parsePoints(getFilterData(config, allShopifyData)),
          channelPoints: parsePoints([]),
          title: formatTitle(info.destination.name, config.label)
        }
    )
  );
};

/* -------------------------------------------------------------------------- */

type ConversionAccuracyDataResult =
  | { status: "LOADING" }
  | { status: "ERROR"; error: Error }
  | { status: "SUCCESS_NONE"; details: NoneExplainerDetails }
  | { status: "SUCCESS_SOME"; data: Array<TransformedDataItem> };

type UseConversionAccuracyData = (args: {
  eventsConnectorConfig: EventsConnectorConfig;
  monitoringTimePeriod: MonitoringTimePeriod;
}) => ConversionAccuracyDataResult;

export const useConversionAccuracyData: UseConversionAccuracyData = ({
  eventsConnectorConfig,
  monitoringTimePeriod
}) => {
  const cubeDateRange =
    getCubeDateRangeFromMonitoringTimePeriod(monitoringTimePeriod);
  const cubeGranularity =
    getCubeGranularityFromMonitoringTimePeriod(monitoringTimePeriod);

  const validInfo = useMemo(() => {
    return destinations
      .map<ValidInfoItem | null>(destination => {
        const configs = eventsConnectorConfig[destination.configKey].filter(
          config =>
            config.live &&
            config.enabledEvents.purchase &&
            (!("webhookOverrides" in config) ||
              config.webhookOverrides.purchase)
        ) as EventsConnectorConfig[Destination["configKey"]];

        return configs.length > 0 ? { destination, configs } : null;
      })
      .filter(isNotNull);
  }, [eventsConnectorConfig]);

  const cube = useCubeQuery<ConversionAccuracyQueryData>({
    query: {
      measures: [
        "ServerSideEvents.success_count",
        "ServerSideEvents.ignored_count",
        "ServerSideEvents.failure_count"
      ],
      dimensions: [
        "ServerSideEvents.channel",
        "ServerSideEvents.config_id",
        ...(eventsConnectorConfig.globalConfig.marketsEnabled
          ? ["ServerSideEvents.provided_market"]
          : [])
      ],
      timeDimensions: [
        {
          dimension: "ServerSideEvents.time",
          dateRange: cubeDateRange,
          granularity: cubeGranularity
        }
      ],
      filters: [
        {
          member: "ServerSideEvents.event",
          operator: "equals",
          values: ["Purchase"]
        },
        {
          or: [
            {
              member: "ServerSideEvents.channel",
              operator: "equals",
              values: ["Shopify"]
            },
            ...validInfo.map<Filter>(({ destination, configs }) => ({
              and: [
                {
                  member: "ServerSideEvents.channel",
                  operator: "equals",
                  values: [destination.name]
                },
                {
                  member: "ServerSideEvents.config_id",
                  operator: "equals",
                  values: configs.map(c => String(c.id))
                }
              ]
            }))
          ]
        }
      ]
    }
  });

  return useMemo<ConversionAccuracyDataResult>(() => {
    if (cube.error) {
      return { status: "ERROR", error: cube.error };
    } else if (cube.data === undefined) {
      return { status: "LOADING" };
    } else if (validInfo.length === 0) {
      return {
        status: "SUCCESS_NONE",
        details: { type: "DESTINATIONS" }
      };
    } else if (cube.data.resultSet.rawData().length === 0) {
      return {
        status: "SUCCESS_NONE",
        details: { type: "PURCHASES", monitoringTimePeriod }
      };
    } else {
      return {
        status: "SUCCESS_SOME",
        data: transformResultSet({
          eventsConnectorConfig,
          validInfo,
          resultSet: cube.data.resultSet
        })
      };
    }
  }, [
    cube.error,
    cube.data,
    validInfo,
    monitoringTimePeriod,
    eventsConnectorConfig
  ]);
};

/* ========================================================================== */

type GetPercentageMatch = (args: {
  shopify: number;
  channel: number;
}) => number | null;

export const getPercentageMatch: GetPercentageMatch = ({
  shopify,
  channel
}) => {
  return shopify === 0 && channel === 0
    ? 100
    : shopify === 0
      ? null
      : Math.round((100 / shopify) * channel);
};

/* -------------------------------------------------------------------------- */

type GetPercentageColor = (args: {
  theme: DefaultTheme;
  current: number;
  target: number;
  context: "CONVERSION_ACCURACY" | "DASHBOARD";
}) => string;

export const getPercentageColor: GetPercentageColor = ({
  theme,
  current,
  target,
  context
}) => {
  if (current === 0) {
    return theme.palette.grey3;
  } else if (current >= 110 || current <= target - 10) {
    return theme.palette.red1;
  } else if (current >= 105 || current < target) {
    return theme.palette.orange;
  } else {
    return context === "CONVERSION_ACCURACY"
      ? theme.palette.green
      : theme.palette.grey1;
  }
};
