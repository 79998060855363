type IconChevronDownProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconChevronDown: React.FC<IconChevronDownProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4 6L8 10L12 6"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
