import Cleave from "cleave.js/react";
import styled, { type StyledComponentPropsWithRef } from "styled-components";

import {
  type InputFieldProps,
  inputFieldStyles
} from "elevar-design-system/src/inputs/sharedInputStyles";
import {
  largeTextStyles,
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type InputFieldPercentageWithoutPrefixProps = StyledComponentPropsWithRef<
  typeof InputFieldPercentageWithoutPrefix
>;

type InputFieldPercentageProps = InputFieldProps &
  Omit<InputFieldPercentageWithoutPrefixProps, "options">;

export const InputFieldPercentage: React.FC<InputFieldPercentageProps> = ({
  variant,
  error,
  disabled,
  ...baseElementProps
}) => {
  return (
    <Wrapper variant={variant}>
      <div>%</div>
      <InputFieldPercentageWithoutPrefix
        variant={variant}
        error={error}
        disabled={disabled}
        {...baseElementProps}
      />
    </Wrapper>
  );
};

/* ========================================================================== */

const InputFieldPercentageWithoutPrefix = styled(Cleave)
  .withConfig({
    shouldForwardProp: prop => !["variant", "error"].includes(prop)
  })
  .attrs<
    React.ComponentPropsWithRef<typeof Cleave>,
    React.ComponentPropsWithRef<typeof Cleave>
  >(props => ({
    placeholder: props.placeholder ?? "6",
    type: "text",
    options: {
      numeral: true,
      numeralPositiveOnly: true
    }
  }))<InputFieldProps>`
  ${inputFieldStyles}
`;

type WrapperProps = {
  variant: InputFieldProps["variant"];
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;

  > div:first-child {
    ${props => props.variant === "TINY" && smallTextStyles};
    ${props => props.variant === "SMALL" && normalBodyStyles};
    ${props => props.variant === "LARGE" && largeTextStyles};

    z-index: 1;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${props =>
      props.variant === "LARGE"
        ? props.theme.gridBase * 2.25
        : props.theme.gridBase * 1.75}px;
  }

  > ${InputFieldPercentageWithoutPrefix} {
    padding-left: ${props =>
      props.variant === "LARGE"
        ? props.theme.gridBase * 5.25
        : props.theme.gridBase * 4.5}px;
  }
`;
