/**
 * Originally from https://github.com/hinok/react-router-last-location
 * Vendored due to no updates + wanting to upgrade/replace `react-router-dom`
 */

// eslint-disable-next-line import/no-extraneous-dependencies
import { createLocation } from "history";
import { Component, createContext, useContext } from "react";
import { Redirect, type RedirectProps, useLocation } from "react-router-dom";
import { z } from "zod";

/* ========================================================================== */

type RRLocation = ReturnType<typeof useLocation>;
type LiteLocation = Omit<RRLocation, "state">;
const stateSchema = z.object({ preventLastLocation: z.unknown() }).partial();

const lastLocationContext = createContext<RRLocation | null>(null);

/* ========================================================================== */

const prevented: Array<LiteLocation> = [];
let lastLocation: RRLocation | null = null;

const shouldPrevent = (location: RRLocation) => {
  const parsedState = stateSchema.safeParse(location.state);
  return parsedState.success
    ? Boolean(parsedState.data.preventLastLocation)
    : false;
};

const hasBeenPrevented = (location: RRLocation) => {
  return prevented.some(i => location.key === i.key);
};

const prevent = (location: RRLocation) => {
  const { state, ...rest } = location;
  prevented.push(rest);
};

const updateLastLocation = (current: RRLocation | null, next: RRLocation) => {
  if (current === null || next === current) {
    return;
  } else if (shouldPrevent(next) && !hasBeenPrevented(next)) {
    prevent(next);
  } else {
    lastLocation = { ...current };
  }
};

type Props = { location: RRLocation; children: React.ReactNode };
type State = Readonly<{ currentLocation: RRLocation | null }>;

class LastLocationProviderInner extends Component<Props, State> {
  static getDerivedStateFromProps(props: Props, state: State) {
    updateLastLocation(state.currentLocation, props.location);
    return { currentLocation: props.location };
  }

  readonly state: State = { currentLocation: null };

  render() {
    return (
      <lastLocationContext.Provider value={lastLocation}>
        {this.props.children}
      </lastLocationContext.Provider>
    );
  }
}

type LastLocationProviderProps = {
  children: React.ReactNode;
};

export const LastLocationProvider: React.FC<LastLocationProviderProps> = ({
  children
}) => {
  const location = useLocation();

  return (
    <LastLocationProviderInner location={location}>
      {children}
    </LastLocationProviderInner>
  );
};

/* ========================================================================== */

export const useLastLocation = () => useContext(lastLocationContext);

/* ========================================================================== */

const getAugmentedTo = (to: RedirectProps["to"]) => {
  if (typeof to === "string") {
    return createLocation(to, { preventLastLocation: true });
  } else {
    const parsedState = stateSchema.passthrough().safeParse(to.state);
    return {
      ...to,
      state: {
        preventLastLocation: true,
        ...(parsedState.success ? parsedState.data : {})
      }
    };
  }
};

export const RedirectWithoutLastLocation: React.FC<RedirectProps> = ({
  to,
  ...rest
}) => {
  return <Redirect {...rest} to={getAugmentedTo(to)} />;
};
