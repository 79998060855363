type IconPersonasProps = {
  size: "24px";
  color?: string;
  className?: string;
};

export const IconPersonas: React.FC<IconPersonasProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 12.5H17"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 10.5H17"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 8.5H17"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 21V20C17 18.9391 16.5786 17.9217 15.8284 17.1716C15.0783 16.4214 14.0609 16 13 16H5C3.93913 16 2.92172 16.4214 2.17157 17.1716C1.42143 17.9217 1 18.9391 1 20V21"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 13C11.7614 13 14 10.7614 14 8C14 5.23858 11.7614 3 9 3C6.23858 3 4 5.23858 4 8C4 10.7614 6.23858 13 9 13Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
