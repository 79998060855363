type IconLiveIntentProps = {
  size: "24px";
  className?: string;
};

export const IconLiveIntent: React.FC<IconLiveIntentProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10.4116 22.9993H18.0491C20.7679 22.9993 22.9886 20.7787 22.9886 18.0599V15.9222C18.3604 15.9015 12.3625 19.4089 10.4116 22.9993Z"
            fill="#FAA429"
          />
          <path
            d="M18.0284 1H15.8077C15.3511 1.99619 15.0813 3.11691 15.0813 4.29989C15.0813 8.57523 18.568 12.0619 22.8433 12.0619C22.8848 12.0619 22.9471 12.0619 22.9886 12.0619V5.91871C22.9678 3.22069 20.7679 1 18.0284 1Z"
            fill="#FAA429"
          />
          <path
            d="M11.7399 4.32138C11.7399 3.1799 11.9059 2.05919 12.2587 1.02148H5.94947C3.23069 1.02148 1.01001 3.24216 1.01001 5.96095V18.0606C1.01001 20.7793 3.23069 23 5.94947 23H6.28154C6.32305 22.8755 6.38531 22.7717 6.42682 22.668C7.94186 19.119 12.2172 15.5493 16.8661 13.6607C13.7737 11.6683 11.7399 8.24389 11.7399 4.32138Z"
            fill="#FCC87F"
          />
        </svg>
      );
  }
};
