import { useId } from "react";

/* ========================================================================== */

type IconZeenkProps = {
  size: "24px";
  className?: string;
};

export const IconZeenk: React.FC<IconZeenkProps> = ({ size, className }) => {
  const idPrefix = useId();
  const linearGradientId = `${idPrefix}-linearGradient`;

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM7.50241 9.16556V6.54545H17.4545L11.7927 15.8262H16.8327V18.4463H6.54546L11.1254 10.945L12.5831 11.6549L12.2781 8.63781L9.12384 9.00485L7.50241 9.16556Z"
            fill={`url(#${linearGradientId})`}
          />

          <defs>
            <linearGradient
              id={linearGradientId}
              x1="1.04348"
              y1="12.0001"
              x2="24.0002"
              y2="12.0001"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1C75BA" />
              <stop offset="1" stopColor="#C348DF" />
            </linearGradient>
          </defs>
        </svg>
      );
  }
};
