import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";

import { type AccountDetails } from "elevar-common-ts/src/apiTypes";

import { Spinner } from "elevar-design-system/src/Spinner";
import { usePrevious } from "elevar-design-system/src/usePrevious";

import { apiRequest } from "../api/utils";
import { track } from "../utils/track";

/* ========================================================================== */

export type UserContext =
  | {
      isLoggedIn: true;
      checkIsLoggedIn: () => Promise<unknown>;
      accountDetails: AccountDetails;
    }
  | {
      isLoggedIn: false;
      checkIsLoggedIn: () => Promise<unknown>;
    };

const userContext = createContext<UserContext | undefined>(undefined);

/* ========================================================================== */

const useAccountDetailsQuery = () => {
  return useQuery({
    queryKey: ["account", "details"],
    queryFn: () => apiRequest<AccountDetails>({ endpoint: "/user/me" }),
    retry: false // Only succeeds if user is logged in
  });
};

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const accountDetails = useAccountDetailsQuery();

  if (accountDetails.data !== undefined || accountDetails.error) {
    return (
      <UserProviderInner
        accountDetails={accountDetails.data ?? null}
        refetchAccountDetails={accountDetails.refetch}
      >
        {children}
      </UserProviderInner>
    );
  } else {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type UserProviderInnerProps = {
  accountDetails: AccountDetails | null;
  refetchAccountDetails: () => Promise<unknown>;
  children: React.ReactNode;
};

const UserProviderInner: React.FC<UserProviderInnerProps> = ({
  accountDetails,
  refetchAccountDetails,
  children
}) => {
  const user = useMemo<UserContext>(() => {
    return accountDetails
      ? {
          isLoggedIn: true,
          checkIsLoggedIn: refetchAccountDetails,
          accountDetails
        }
      : {
          isLoggedIn: false,
          checkIsLoggedIn: refetchAccountDetails
        };
  }, [accountDetails, refetchAccountDetails]);

  const previousUser = usePrevious(user);

  useEffect(() => {
    if (user.isLoggedIn) {
      track.userAuth(user.accountDetails);
      if (previousUser && !previousUser.isLoggedIn) track.userLogin();
    }
  }, [user, previousUser]);

  return <userContext.Provider value={user}>{children}</userContext.Provider>;
};

/* ========================================================================== */

export const useUserOptional = () => {
  const user = useContext(userContext);

  if (user !== undefined) {
    return user;
  } else {
    throw new Error("`useUserOptional`: value not set");
  }
};

export const useUserRequired = () => {
  const user = useContext(userContext);

  if (user?.isLoggedIn) {
    return user;
  } else {
    throw new Error("`useUserRequired`: value not set");
  }
};
