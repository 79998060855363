type IconPostscriptProps = {
  size: "24px";
  className?: string;
};

export const IconPostscript: React.FC<IconPostscriptProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M24 12.1685C24 14.12 23.5115 21.2514 12 21.2514C8.71718 21.2514 0 21.2857 0 21.2857V12.1685C0 6.70856 4.40857 3 12 3C18.6286 3 24 5.71142 24 12.1685Z"
            fill="#5724E9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.75512 13.3812C9.74659 13.3812 10.5503 11.6883 10.5503 9.5999C10.5503 7.51151 9.74659 5.81854 8.75512 5.81854C7.76373 5.81854 6.95996 7.51151 6.95996 9.5999C6.95996 11.6883 7.76373 13.3812 8.75512 13.3812ZM15.1648 13.3815C16.1563 13.3815 16.96 11.6884 16.96 9.60007C16.96 7.51169 16.1563 5.81872 15.1648 5.81872C14.1733 5.81872 13.3696 7.51169 13.3696 9.60007C13.3696 11.6884 14.1733 13.3815 15.1648 13.3815Z"
            fill="white"
          />
        </svg>
      );
  }
};
