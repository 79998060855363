import styled, { css } from "styled-components";

import { type InputFieldProps, inputFieldStyles } from "./sharedInputStyles";

/* ========================================================================== */

type InputFieldTextAreaProps = InputFieldProps & {
  defaultHeight?: string;
  minHeight?: string;
  maxHeight?: string;
  isResizeable?: boolean;
  isScrollable?: boolean;
};

export const InputFieldTextArea = styled.textarea<InputFieldTextAreaProps>`
  ${inputFieldStyles};
  height: ${props => props.defaultHeight ?? `${props.theme.gridBase * 12.5}px`};
  min-height: ${props => props.minHeight ?? `${props.theme.gridBase * 12.5}px`};
  max-height: ${props => props.maxHeight ?? `${props.theme.gridBase * 37.5}px`};
  resize: ${props => (props.isResizeable ? "vertical" : "none")};

  ${props =>
    !props.isScrollable
      ? css`
          scrollbar-width: none;
        `
      : null}
`;
