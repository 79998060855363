import styled, { useTheme } from "styled-components";

/* ========================================================================== */

type AvatarStackProps = {
  avatars: Array<{ src: string; alt: string }>;
};

export const AvatarStack: React.FC<AvatarStackProps> = ({ avatars }) => {
  const theme = useTheme();

  return (
    <AvatarStackWrapper>
      {[...avatars].reverse().map(avatar => (
        <div key={avatar.src}>
          <img
            src={avatar.src}
            alt={avatar.alt}
            width={theme.gridBase * 4}
            height={theme.gridBase * 4}
          />
        </div>
      ))}
    </AvatarStackWrapper>
  );
};

/* ========================================================================== */

const AvatarStackWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: max-content;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.theme.gridBase * 4.5}px;
    height: ${props => props.theme.gridBase * 4.5}px;

    > img {
      border-radius: 50%;
      filter: drop-shadow(2px 2px 8px rgba(33, 43, 54, 0.16));
    }

    &:not(:last-child) {
      margin-left: ${props => props.theme.gridBase * -1}px;
    }
  }
`;
