import styled, { type CSSProperties } from "styled-components";

import {
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  BrandLogoFaherty,
  BrandLogoSkims,
  BrandLogoSnow,
  BrandLogoVessi
} from "./BrandLogos";

/* ========================================================================== */

type Alignment = CSSProperties["textAlign"] & CSSProperties["alignItems"];

type BrandShowcaseProps = {
  alignment?: Alignment;
};

export const BrandShowcase: React.FC<BrandShowcaseProps> = ({
  alignment = "center"
}) => {
  return (
    <BrandShowcaseWrapper alignment={alignment}>
      <BrandShowcaseHeading>
        Trusted by Over 6,500 D2C Brands
      </BrandShowcaseHeading>
      <BrandShowcaseImagesWrapper>
        <BrandLogoVessi />
        <BrandLogoSnow />
        <BrandLogoSkims />
        <BrandLogoFaherty />
      </BrandShowcaseImagesWrapper>
      <BrandShowcaseExplainer alignment={alignment}>
        # 1 server-side conversion tracking choice for fast-scaling Shopify
        brands
      </BrandShowcaseExplainer>
    </BrandShowcaseWrapper>
  );
};

/* ========================================================================== */

type BrandShowcaseWrapperProps = {
  alignment: Alignment;
};

const BrandShowcaseWrapper = styled.div<BrandShowcaseWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignment};
`;

const BrandShowcaseHeading = styled.div`
  ${subheadingStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const BrandShowcaseImagesWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 7.5%;

  > svg {
    width: 100%;
    height: min-content;
    max-width: ${props => props.theme.gridBase * 14}px;
    padding: ${props => props.theme.gridBase * 0.5}px 0;
  }
`;

type BrandShowcaseExplainerProps = {
  alignment: Alignment;
};

const BrandShowcaseExplainer = styled.div<BrandShowcaseExplainerProps>`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey4};
  text-align: ${props => props.alignment};
`;
