type IconLuckyOrangeProps = {
  size: "24px";
  className?: string;
};

export const IconLuckyOrange: React.FC<IconLuckyOrangeProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33935 20.907C6.15857 19.0387 5.22236 16.641 5.96414 14.4437C6.70573 12.2465 7.70377 10.6941 9.4071 10.066C10.3002 9.73662 12.9878 8.49915 15.0794 10.4111C17.1709 12.3233 18.2203 14.7096 18.2979 16.3687C18.5125 20.9627 12.6297 22.8471 11.5961 22.8471C10.2027 22.8471 8.12436 22.1491 7.33935 20.907Z"
            fill="#FF704C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0166 1.12459C14.2296 -0.00829064 11.7096 6.93489 12.9038 7.79138C13.2064 8.00847 16.7254 3.58452 15.0166 1.12459Z"
            fill="#424A3C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.525 7.82627C11.7041 8.79819 9.51251 5.55204 9.96213 4.95661C10.993 3.59067 11.4194 7.25294 11.525 7.82627Z"
            fill="#424A3C"
          />
        </svg>
      );
  }
};
