import { Link } from "react-router-dom";
import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading2Styles,
  heading3Styles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { useShopifyOAuthScopesQuery } from "../../../../api/handlers/shopify";
import { useWebsiteDetailsQuery } from "../../../../api/handlers/website";
import { useServerSideDetails } from "../../../../context/ServerSideDetails";
import { useCompanyId, useWebsiteId } from "../../../../utils/idHooks";
import { PageCardGoogle } from "./PageCardGoogle";
import { PageCardGtmServer } from "./PageCardGtmServer";
import { PageCardGtmWeb } from "./PageCardGtmWeb";

/* ========================================================================== */

export const Connections: React.FC = () => {
  const websiteDetails = useWebsiteDetailsQuery();
  const shopifyOAuthScopes = useShopifyOAuthScopesQuery();

  if (websiteDetails.error !== null || shopifyOAuthScopes.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    websiteDetails.data === undefined ||
    shopifyOAuthScopes.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return <ConnectionsInner websiteDetails={websiteDetails.data} />;
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type ConnectionsInnerProps = {
  websiteDetails: WebsiteDetails;
};

const ConnectionsInner: React.FC<ConnectionsInnerProps> = ({
  websiteDetails
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { isFullyManaged } = useServerSideDetails();

  const isGtmWebCardVisible = websiteDetails.permissions.includes("GTM_AUTH");
  const isGtmServerCardVisible =
    websiteDetails.workspace.ss_client_id !== null && !isFullyManaged;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;

  return (
    <ConnectionsWrapper>
      <PageHeading>Connections</PageHeading>
      <PageSectionHeading>Google</PageSectionHeading>
      <PageSectionContent>
        <div>
          <PageCardGoogle websiteDetails={websiteDetails} />
        </div>
        {isGtmWebCardVisible ? (
          <div>
            <PageCardGtmWeb websiteDetails={websiteDetails} />
          </div>
        ) : null}
        {isGtmServerCardVisible ? (
          <div>
            <PageCardGtmServer websiteDetails={websiteDetails} />
          </div>
        ) : null}
        {websiteDetails.shopify_shop ? (
          <ShopifyNotice>
            The Shopify connection now lives in{" "}
            <Link to={`${websiteUrl}/my-tracking/source-shopify`}>
              your Shopify Source's
            </Link>{" "}
            "Store Connection" step.
          </ShopifyNotice>
        ) : null}
      </PageSectionContent>
    </ConnectionsWrapper>
  );
};

const ConnectionsWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 4}px;
  max-width: ${props => props.theme.gridBase * 70.5}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageSectionHeading = styled.h2`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const PageSectionContent = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const ShopifyNotice = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-top: ${props => props.theme.gridBase * 3}px;

  > a {
    ${linkStyles};
  }
`;
