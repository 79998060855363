type IconSmartlyProps = {
  size: "24px";
  className?: string;
};

export const IconSmartly: React.FC<IconSmartlyProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.8414 16.4314L0 14.8906V12.0317H7.8414V16.4314Z"
            fill="#25275E"
          />
          <path
            d="M16.1578 12.0317H7.84138V6L16.1578 7.63442V12.0317Z"
            fill="#FCE24A"
          />
          <path
            d="M16.1578 18.0666L7.84138 16.4314V12.0317H16.1578V18.0666Z"
            fill="#38A7C8"
          />
          <path
            d="M16.1578 7.63441L24 9.17446V12.0333H16.1578V7.63441Z"
            fill="#F16179"
          />
        </svg>
      );
  }
};
