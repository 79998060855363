type IconFairingProps = {
  size: "24px";
  className?: string;
};

export const IconFairing: React.FC<IconFairingProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5023 6.64124L5.83079 8.8308C4.88272 9.01193 4 8.27112 4 7.29976V5.15955C4 4.20475 4.67021 3.3816 5.60197 3.21696L17.2734 1.02737C18.2215 0.846279 19.1042 1.58711 19.1042 2.55844V4.69865C19.1042 5.65345 18.4341 6.46017 17.5023 6.64124ZM17.5027 15.1197L11.5525 16.2391V12.2222C11.5525 11.2673 12.2227 10.4441 13.1544 10.2795L17.2738 9.50577C18.2218 9.32465 19.1046 10.0655 19.1046 11.0368V13.177C19.1046 14.1319 18.4344 14.955 17.5027 15.1197ZM5.83079 22.9726L9.95015 22.2153C10.8819 22.0343 11.5521 21.2111 11.5521 20.2562V16.2392L5.60197 17.3587C4.67021 17.5234 4 18.3465 4 19.3014V21.4416C4 22.4129 4.88272 23.1537 5.83079 22.9726Z"
            fill="#1E233C"
          />
        </svg>
      );
  }
};
