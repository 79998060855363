type IconCaretUpProps = {
  size: "8px";
  color?: string;
  className?: string;
};

export const IconCaretUp: React.FC<IconCaretUpProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "8px":
      return (
        <svg
          className={className}
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M3.58266 1.63249C3.78028 1.33299 4.21972 1.33299 4.41734 1.63249L6.95249 5.47463C7.17183 5.80706 6.93342 6.25 6.53515 6.25H1.46485C1.06657 6.25 0.828165 5.80706 1.04751 5.47463L3.58266 1.63249Z"
            fill={color}
          />
        </svg>
      );
  }
};
