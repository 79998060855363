import styled from "styled-components";

/* ========================================================================== */

type ToggleProps = {
  isOn: boolean;
  className?: string;
};

export const Toggle = styled.div<ToggleProps>`
  width: ${props => props.theme.gridBase * 5.5}px;
  height: ${props => props.theme.gridBase * 3}px;
  border-radius: 22px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  transition:
    background-color ${props => props.theme.other.transition},
    border-color ${props => props.theme.other.transition};
  border-color: ${props =>
    props.isOn ? props.theme.palette.blue1 : props.theme.palette.grey6};
  background-color: ${props =>
    props.isOn ? props.theme.palette.blue1 : props.theme.palette.grey6};

  &::after {
    content: "";
    width: ${props => props.theme.gridBase * 2.5}px;
    border-radius: 50%;
    transition: left ${props => props.theme.other.transition};
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${props => (props.isOn ? "calc(100% - 20px)" : 0)};
    background-color: ${props => props.theme.palette.white};
  }
`;
