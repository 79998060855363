type IconAttentiveProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconAttentive: React.FC<IconAttentiveProps> = ({
  size,
  color,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.18179 3.18182C6.18179 5.72727 4.36361 9.72727 4.36361 9.72727C4.36361 9.72727 6.36361 8.68603 7.81816 9.18177C8.82628 9.52536 9.61207 10.2727 9.99998 11C11.2454 13.3352 14.4905 19.6696 15.3977 21.4424L15.5213 21.6842C15.5327 21.7065 15.5827 21.766 15.6282 21.8182C15.6478 21.8487 15.6678 21.879 15.6881 21.909C15.7423 21.989 15.799 22.0672 15.858 22.1435C16.6894 23.2175 17.9912 23.909 19.4546 23.909C21.965 23.909 24 21.874 24 19.3636C24 18.7196 23.8661 18.1069 23.6246 17.5518L23.6363 17.5454L23.5388 17.3663C23.4815 17.2493 23.4193 17.135 23.3524 17.0239C21.9575 14.4622 15.8866 3.32172 15.6363 3C15.0706 2.27268 14 1 12.1818 1C10.3636 1 9.16271 1.93337 8.18179 3.18182ZM5.09091 23.5454C7.90254 23.5454 10.1818 21.2661 10.1818 18.4545C10.1818 15.6429 7.90254 13.3636 5.09091 13.3636C2.27928 13.3636 0 15.6429 0 18.4545C0 21.2661 2.27928 23.5454 5.09091 23.5454Z"
            fill={color ?? "#1A1E22"}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.45453 2.12123C4.1212 3.8182 2.90907 6.48487 2.90907 6.48487C2.90907 6.48487 4.24241 5.79071 5.2121 6.1212C5.88419 6.35026 6.40804 6.84848 6.66665 7.33335C7.49696 8.89017 9.66034 13.1131 10.2651 14.295L10.3476 14.4561C10.3552 14.471 10.3885 14.5107 10.4188 14.5455C10.4319 14.5658 10.4452 14.586 10.4588 14.606C10.4949 14.6594 10.5327 14.7115 10.572 14.7623C11.1263 15.4784 11.9942 15.9394 12.9697 15.9394C14.6433 15.9394 16 14.5827 16 12.9091C16 12.4798 15.9107 12.0713 15.7497 11.7012L15.7576 11.697L15.6926 11.5775C15.6543 11.4995 15.6129 11.4234 15.5683 11.3493C14.6383 9.64153 10.591 2.2145 10.4242 2.00002C10.0471 1.51514 9.33332 0.666687 8.1212 0.666687C6.90907 0.666687 6.10848 1.28894 5.45453 2.12123ZM3.39394 15.697C5.26836 15.697 6.78788 14.1774 6.78788 12.303C6.78788 10.4286 5.26836 8.90908 3.39394 8.90908C1.51952 8.90908 0 10.4286 0 12.303C0 14.1774 1.51952 15.697 3.39394 15.697Z"
            fill={color ?? "#1A1E22"}
          />
        </svg>
      );
  }
};
