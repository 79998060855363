import { lowerFirst, startCase } from "lodash-es";

import {
  type DerivedEventKey,
  type EventKey as BaseEventKey
} from "elevar-common-ts/src/apiTypes";
import { type MonitoringEvent } from "elevar-common-ts/src/monitoringTypes";

/* ========================================================================== */

export const formatPrice = (value: number, currency = "USD") => {
  return value.toLocaleString("en", { style: "currency", currency });
};

export const formatTitle = (name: string, label: string | null) => {
  return label ? `${name} - ${label}` : name;
};

type EventKey = BaseEventKey | DerivedEventKey;

export const formatEventName = (name: MonitoringEvent | EventKey) => {
  const normalizedName = lowerFirst(name) as Uncapitalize<typeof name>;

  switch (normalizedName) {
    case "signUp":
      return "Account Sign Up";
    case "subscribe":
      return "Email Sign Up";
    default:
      return startCase(normalizedName);
  }
};
