import { useId } from "react";

/* ========================================================================== */

type IconPostieProps = {
  size: "24px";
  className?: string;
};

export const IconPostie: React.FC<IconPostieProps> = ({ size, className }) => {
  const idPrefix = useId();
  const mask1Id = `${idPrefix}-mask1`;
  const mask2Id = `${idPrefix}-mask2`;
  const mask3Id = `${idPrefix}-mask3`;

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <mask
            id={mask1Id}
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="11"
            y="0"
            width="2"
            height="5"
          >
            <path d="M11.1437 0H12.8596V4.99182H11.1437V0Z" fill="white" />
          </mask>
          <g mask={`url(#${mask1Id})`}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.8171 1.70239V4.80644C11.8171 4.90878 11.8997 4.99178 12.0016 4.99178C12.1035 4.99178 12.1861 4.90878 12.1861 4.80644V1.70239C12.5712 1.61738 12.8596 1.27389 12.8596 0.861711C12.8596 0.385869 12.4755 -0.00012207 12.0016 -0.00012207C11.5277 -0.00012207 11.1437 0.385869 11.1437 0.861711C11.1437 1.27389 11.4323 1.61738 11.8171 1.70239Z"
              fill="#1E4768"
            />
          </g>
          <mask
            id={mask2Id}
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="6"
            y="19"
            width="12"
            height="5"
          >
            <path d="M17.7655 24H6.23767V19.2731H17.7655V24Z" fill="white" />
          </mask>
          <g mask={`url(#${mask2Id})`}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.1064 19.2731H6.89688C6.53288 19.2731 6.23767 19.5694 6.23767 19.9351V23.3379C6.23767 23.7035 6.53288 24.0001 6.89688 24.0001H17.1064C17.4704 24.0001 17.7655 23.7035 17.7655 23.3379V19.9351C17.7655 19.5694 17.4704 19.2731 17.1064 19.2731Z"
              fill="#1E4768"
            />
          </g>
          <mask
            id={mask3Id}
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="2"
            y="12"
            width="20"
            height="10"
          >
            <path
              d="M2.42004 21.4801V12.0338H21.5831V21.4801H2.42004V21.4801Z"
              fill="white"
            />
          </mask>
          <g mask={`url(#${mask3Id})`}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.0343 15.8144C17.5726 15.8144 17.1984 15.4384 17.1984 14.9747C17.1984 14.5109 17.5726 14.135 18.0343 14.135C18.496 14.135 18.8702 14.5109 18.8702 14.9747C18.8702 15.4384 18.496 15.8144 18.0343 15.8144ZM20.924 12.0338H3.07893C2.71513 12.0338 2.41992 12.3302 2.41992 12.6958V20.8181C2.41992 21.1838 2.71513 21.4801 3.07893 21.4801H4.80526V19.6706C4.80526 18.6011 5.66842 17.734 6.73314 17.734H17.27C18.3347 17.734 19.1979 18.6011 19.1979 19.6706V21.4801H20.924C21.288 21.4801 21.583 21.1838 21.583 20.8181V12.6958C21.583 12.3302 21.288 12.0338 20.924 12.0338Z"
              fill="#1E4768"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.40347 7.0511C9.98045 7.0511 10.4483 7.5211 10.4483 8.10069C10.4483 8.68028 9.98045 9.15008 9.40347 9.15008C8.82649 9.15008 8.3586 8.68028 8.3586 8.10069C8.3586 7.5211 8.82649 7.0511 9.40347 7.0511ZM14.5999 7.0511C15.1771 7.0511 15.6448 7.5211 15.6448 8.10069C15.6448 8.68028 15.1771 9.15008 14.5999 9.15008C14.0229 9.15008 13.5552 8.68028 13.5552 8.10069C13.5552 7.5211 14.0229 7.0511 14.5999 7.0511ZM6.8968 10.4622H17.1066C17.4706 10.4622 17.7656 10.1658 17.7656 9.80018V6.401C17.7656 6.03536 17.4706 5.73901 17.1066 5.73901H6.8968C6.533 5.73901 6.23779 6.03536 6.23779 6.401V9.80018C6.23779 10.1658 6.533 10.4622 6.8968 10.4622Z"
            fill="#1E4768"
          />
        </svg>
      );
  }
};
