import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";
import { unsafeTypedEntries } from "elevar-common-ts/src/utils";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { useGoogleAdsCreateConversionActionsMutation } from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { destinationGads as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { containsNumbersOnly, conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-google-ads-in-elevar-server-side-destination";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationGadsProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationGads: React.FC<DestinationGadsProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Give Elevar Access" },
          { name: "Google Ads Settings" },
          { name: "Events" },
          { name: "Consent Mode" },
          { name: "Transaction Identifier" },
          { name: "Product Identifier" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" }
        ]}
        context={{ setSaveModalState, shopifyOAuthScopes }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
  }
};

/* ========================================================================== */

const recommendedEvents: ReturnType<typeof useConfig>["enabledEvents"] = {
  purchase: true,
  addToCart: false,
  beginCheckout: false,
  viewItem: false,
  newCustomerPurchase: true,
  returningCustomerPurchase: true
};

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 2,
                type: "CUSTOM",
                render: () => (
                  <>
                    The Customer ID used is{" "}
                    <span>{config.googleAdAccountId}</span>
                  </>
                )
              },
              {
                step: 3,
                type: "EVENTS",
                selectedEvents: {
                  enabledEvents: config.enabledEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                },
                recommendedEvents: getRecommendedEventState({
                  destination,
                  enabledEvents: recommendedEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                })
              },
              {
                step: 4,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 5,
                type: "TRANSACTION_IDENTIFIER",
                value: config.dataConfig.orderAttributeId
              },
              {
                step: 6,
                type: "PRODUCT_IDENTIFIER",
                value: config.dataConfig.productAttributeMapping
              },
              {
                step: 7,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 8,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          <p>
            This server-side integration performs better than client-side
            tracking if you use post-purchase upsells.
          </p>
          <p>
            If you don't use post-purchase upsells, set up this integration
            along with client-side tracking and the GA4/Google Ads Import
            integration to determine what performs best for your business.
          </p>
          <StyledLinkExternal
            href="https://docs.getelevar.com/docs/how-to-implement-google-ads-remarketing-and-tracking-via-google-tag-manager"
            text="How to set up Google Ads via Google Tag Manager"
          />
          <StyledLinkExternal
            href="https://support.google.com/analytics/answer/10632359?hl=en"
            text="How to set up GA4/Google Ads Import Integration"
          />
        </Step0Explainer>
      }
      integrationNotes={
        <div>
          For optimal performance, enable the{" "}
          <span>Enhanced Conversions for Leads</span> setting in your Google Ads
          account prior to setting up this destination.{" "}
          <LinkExternal href="https://support.google.com/google-ads/answer/11347292">
            How do I do this?
          </LinkExternal>
        </div>
      }
      whatsNextOverrides={{
        title: "Make Previous Conversion Action Secondary",
        explainer: (
          <>
            To avoid duplicate tracking, mark your previous Conversion Actions
            in Google Ads as Secondary Conversions and split test against
            Elevar.
          </>
        ),
        media: { type: "NONE", spanContent: true },
        link: {
          href: "https://docs.getelevar.com/docs/how-to-set-up-google-ads-in-elevar-server-side-destination",
          text: "How to Mark Conversion Actions as Secondary"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > p {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > a:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const { configMutation, completedStep } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  return (
    <PageCard>
      <StepSection
        title="Give Elevar Access"
        setupGuideHref={setupGuideHref}
        description={
          <Step1Explainer>
            <p>
              To get started, please email us at{" "}
              <LinkExternal href="mailto:help@getelevar.com">
                help@getelevar.com
              </LinkExternal>{" "}
              with your Google Ads Account ID and a note to request access.
              Expect a response from our team within 24 business hours.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/how-to-find-google-ads-account-id"
              text="How do I find my Google Ads Account ID?"
            />
            <p>
              Why? Google requires the party who's sending the server data
              (Elevar) to have access to the account the data's going to (your
              Google Ads Account).
            </p>
          </Step1Explainer>
        }
        descriptionSpacing={!isStepCompleted}
      >
        {!isStepCompleted ? (
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await configMutation({ completedStep: 1 });
              setIsLoading(false);
            }}
          >
            Mark as Complete
          </ButtonPrimary>
        ) : null}
      </StepSection>
    </PageCard>
  );
};

const Step1Explainer = styled.div`
  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    &:first-child {
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    &:last-child {
      margin-top: ${props => props.theme.gridBase * 2}px;
    }

    > a {
      ${linkStyles}
    }
  }
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  const [isLoading, setIsLoading] = useState(false);
  const [merchantId, setMerchantId] = useState(config.merchantCenterId);
  const [customerId, setCustomerId] = useState(config.googleAdAccountId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areGoogleAdsFieldsFilledIn = customerId !== "";
  const areGoogleAdsFieldsUnique = uniq([merchantId, customerId]).length === 2;
  const isMerchantIdValid =
    merchantId === "" || containsNumbersOnly(merchantId);
  const isCustomerIdValid = containsNumbersOnly(customerId);

  return (
    <Step2Wrapper>
      <PageCard>
        <StepSection
          title="Google Ads Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step2Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Google Ads account.
            </Step2Explainer>
          }
        >
          <Step2SectionInnerWrapper ref={inputWrapperRef}>
            <div>
              <InputWrapper
                labelText="Merchant ID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40}px`,
                  render: () => (
                    <Step2InputWrapperTooltipContent>
                      <p>This is your Google Merchant Center ID.</p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-google-merchant-center-id"
                        text="How do I find this?"
                      />
                    </Step2InputWrapperTooltipContent>
                  )
                }}
                optional={true}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={merchantId}
                  onChange={event => setMerchantId(event.target.value)}
                  placeholder="123456789"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Customer ID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 33}px`,
                  render: () => (
                    <Step2InputWrapperTooltipContent>
                      <p>
                        This is a unique number used to identify your Google Ads
                        account.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-google-ads-customer-id"
                        text="How do I find this?"
                      />
                    </Step2InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={customerId}
                  onChange={event => setCustomerId(event.target.value)}
                  placeholder="123456789"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
          </Step2SectionInnerWrapper>
        </StepSection>
        <Step2AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step2AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step2AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <InputWrapper labelText="Conversion Value" disabled={isLoading}>
              <InputFieldSelect
                variant="SMALL"
                disabled={isLoading}
                value={selectedConversionValueOption}
                setValue={option => setConversionValue(option.value)}
                options={conversionValueOptions}
                placeholder="Select a Conversion Value"
              />
            </InputWrapper>
          </Step2AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areGoogleAdsFieldsFilledIn
              ? "All fields are required"
              : !isMerchantIdValid
                ? "The Merchant ID field must only contain numbers"
                : !isCustomerIdValid
                  ? "The Customer ID field must only contain numbers"
                  : !areGoogleAdsFieldsUnique
                    ? "All fields must be unique"
                    : null
          }
          onSave={async data => {
            const action = async () => {
              setIsLoading(true);
              await configMutation({
                ...data,
                merchantCenterId: merchantId,
                googleAdAccountId: customerId,
                dataConfig: { conversionValue },
                ...(!isStepCompleted ? { completedStep: 2 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
              setIsLoading(false);
            };

            if (config.live) {
              setSaveModalState({ isVisible: true, onConfirm: action });
            } else {
              await action();
            }
          }}
        />
      </PageCard>
    </Step2Wrapper>
  );
};

const Step2Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step2SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step2InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const Step2AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step2AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step2AdvancedOptionsWrapper = styled.div<Step2AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, shopifyOAuthScopes }
  } = useSetupFlowDetails();

  const { mutateAsync: googleAdsCreateConversionActionsMutation } =
    useGoogleAdsCreateConversionActionsMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [conversionNameArrays, setConversionNameArrays] = useState(
    config.conversionNameArrays
  );

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step3Explainer>
            Select what events you want to send to Google Ads.
          </Step3Explainer>
        }
      />
      <EventDestinationTable
        isLoading={isLoading}
        isStepCompleted={isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name,
          conversionNameArrays,
          setConversionNameArrays
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async () => {
          const action = async () => {
            setIsLoading(true);

            const shouldCreateActions = unsafeTypedEntries(
              eventState.enabledEvents
            ).some(([k, v]) => v && conversionNameArrays[k].length === 0);

            if (shouldCreateActions) {
              try {
                await configMutation({ ...eventState, conversionNameArrays });
                await googleAdsCreateConversionActionsMutation();
                if (!isStepCompleted) {
                  await configMutation({ completedStep: 3 });
                }
                toast.success("Conversion actions created in Google Ads");
              } catch (error) {
                toast.errorUnexpected(error);
              }
            } else {
              await configMutation({
                ...eventState,
                conversionNameArrays,
                ...(!isStepCompleted ? { completedStep: 3 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
            }

            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }}
      />
    </PageCard>
  );
};

const Step3Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <TransactionIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["id", "order_number", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { orderAttributeId },
            ...(!isStepCompleted ? { completedStep: 5 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  const isStepCompleted = completedStep !== null && completedStep >= 6;

  return (
    <ProductIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { productAttributeMapping },
            ...(!isStepCompleted ? { completedStep: 6 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 7;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 7 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 8;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 8 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};
