import { useId } from "react";
import styled, { useTheme } from "styled-components";

import { type ProductAttributeMapping } from "elevar-common-ts/src/apiTypes";
import { type NonEmptyArray } from "elevar-common-ts/src/utils";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { type Destination } from "./data";
import { getProductIdentifierDisplayName } from "./shared";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

const getProductIdentifierTooltip = (value: ProductAttributeMapping) => {
  switch (value) {
    case "sku":
      return 'Example: "shirt-1234"';
    case "product_id":
      return 'Example: "1234567891234"';
    case "variant_id":
      return 'Example: "36554240589980"';
  }
};

/* ========================================================================== */

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

type ProductIdentifierProps = {
  isLoading: boolean;
  isStepCompleted: boolean;
  setupGuideHref: StepSectionProps["setupGuideHref"];
  destinationName: Destination["name"];
  media?: StepSectionProps["media"];
  hasRecommendedOption?: boolean;
  options: NonEmptyArray<ProductAttributeMapping>;
  productAttributeMapping: ProductAttributeMapping;
  setProductAttributeMapping: SetState<ProductAttributeMapping>;
  onSave: () => void;
  descriptionOverride?: React.ReactNode;
};

export const ProductIdentifier: React.FC<ProductIdentifierProps> = ({
  isLoading,
  isStepCompleted,
  setupGuideHref,
  destinationName,
  media = { type: "VIDEO", url: "https://player.vimeo.com/video/669140450" },
  hasRecommendedOption = true,
  options,
  productAttributeMapping,
  setProductAttributeMapping,
  onSave,
  descriptionOverride
}) => {
  const theme = useTheme();
  const radioGroupName = useId();

  return (
    <PageCard>
      <StepSection
        title="What is your Product Identifier?"
        setupGuideHref={setupGuideHref}
        description={
          descriptionOverride ? (
            <ProductIdentifierExplainer>
              {descriptionOverride}
            </ProductIdentifierExplainer>
          ) : hasRecommendedOption ? (
            <ProductIdentifierExplainer>
              {getProductIdentifierDisplayName(options[0])} is almost always
              used as the primary product identifier in {destinationName}{" "}
              integrations, but you can override this if needed.
            </ProductIdentifierExplainer>
          ) : (
            <ProductIdentifierExplainer>
              Please choose the primary product identifier you'd like to use in
              this {destinationName} integration.
            </ProductIdentifierExplainer>
          )
        }
        media={media}
      >
        <ProductIdentifierWrapper>
          <ProductIdentifierRadioWrapper>
            {options.map((option, index) => (
              <LabeledRadioText
                key={option}
                groupName={radioGroupName}
                isSelected={productAttributeMapping === option}
                setIsSelected={() => setProductAttributeMapping(option)}
                text={getProductIdentifierDisplayName(option)}
                isDisabled={isLoading}
                tooltip={{ text: getProductIdentifierTooltip(option) }}
                tag={
                  index === 0 && hasRecommendedOption
                    ? { text: "Recommended", color: theme.palette.green }
                    : null
                }
              />
            ))}
          </ProductIdentifierRadioWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={onSave}
          >
            {isStepCompleted ? "Save" : "Save & Continue"}
          </ButtonPrimary>
        </ProductIdentifierWrapper>
      </StepSection>
    </PageCard>
  );
};

/* ========================================================================== */

const ProductIdentifierExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const ProductIdentifierWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProductIdentifierRadioWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;
