import { type QueryKey, useQueryClient } from "@tanstack/react-query";

import { type ExceptionCode } from "elevar-common-ts/src/apiTypes";
import { sleepForMs } from "elevar-common-ts/src/utils";

/* ========================================================================== */

/**
 * This hook should be used in favour of using `useQueryClient` directly
 * from a `react-query` import statement.
 */
export const useExtendedQueryClient = () => {
  const baseQueryClient = useQueryClient();

  const invalidateOrRemoveQueries = async (queryKey: QueryKey) => {
    const queryCache = baseQueryClient.getQueryCache();
    const activeQueries = queryCache.findAll({ queryKey, type: "active" });

    if (activeQueries.length > 0) {
      await baseQueryClient.invalidateQueries({ queryKey });
    } else {
      baseQueryClient.removeQueries({ queryKey });
    }
  };

  return {
    base: baseQueryClient,
    extensions: {
      invalidateOrRemoveQueriesList: async (queryKeys: Array<QueryKey>) => {
        await Promise.all(queryKeys.map(k => invalidateOrRemoveQueries(k)));
      }
    }
  };
};

/* ========================================================================== */

export const apiUrl = import.meta.env.VITE_ELEVAR_API_URL;

export const getApiAuthToken = () => {
  return localStorage.getItem("token");
};
export const setApiAuthToken = (value: string) => {
  localStorage.setItem("token", value);
};
export const clearApiAuthToken = () => {
  localStorage.removeItem("token");
};

/* ========================================================================== */

type ApiErrorCause = {
  status: number;
  statusText: string;
  errors?: unknown;
};

const ApiError = (cause: ApiErrorCause) => Error("Api Error", { cause });

type BaseRequestArgs<RequestData> = {
  url: string;
  includeCredentials: boolean;
  method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  requiresAuth?: boolean;
  data?: RequestData;
  stringifyData?: boolean;
  contentType?: string | null;
};

const baseRequest = async <ResponseData = unknown, RequestData = unknown>({
  url,
  includeCredentials,
  method = "GET",
  requiresAuth = true,
  data,
  stringifyData = true,
  contentType = "application/json"
}: BaseRequestArgs<RequestData>): Promise<ResponseData> => {
  const fetchOptions: Record<string, unknown> = {
    method,
    credentials: includeCredentials ? "include" : "omit",
    headers: {
      Accept: "application/json",
      ...(contentType ? { "Content-Type": contentType } : {}),
      ...(requiresAuth
        ? { Authorization: `Token ${getApiAuthToken() ?? ""}` }
        : {})
    },
    ...(data ? { body: stringifyData ? JSON.stringify(data) : data } : {})
  };

  const response = await fetch(url, fetchOptions);
  const clonedResponseBeforeBodyRead = response.clone();

  if (response.ok) {
    try {
      const responseData = (await response.json()) as ResponseData;
      return responseData;
    } catch {
      return clonedResponseBeforeBodyRead as unknown as ResponseData;
    }
  } else {
    let errors: unknown;

    try {
      errors = await response.json();
      throw new Error("Error response parsed successfully");
    } catch (error) {
      if (error instanceof SyntaxError) {
        throw ApiError({
          status: response.status,
          statusText: response.statusText
        });
      } else {
        throw ApiError({
          status: response.status,
          statusText: response.statusText,
          errors
        });
      }
    }
  }
};

/* -------------------------------------------------------------------------- */

type ApiRequestArgs<RequestData> = {
  endpoint: string;
  params?: ConstructorParameters<typeof URLSearchParams>[0];
} & Pick<
  BaseRequestArgs<RequestData>,
  "method" | "requiresAuth" | "data" | "stringifyData" | "contentType"
>;

export const apiRequest = <ResponseData = unknown, RequestData = unknown>({
  endpoint,
  params = undefined,
  method = "GET",
  requiresAuth = true,
  data,
  stringifyData = true,
  contentType = "application/json"
}: ApiRequestArgs<RequestData>): Promise<ResponseData> => {
  const url =
    params !== undefined
      ? `${apiUrl}/api${endpoint}?${new URLSearchParams(params).toString()}`
      : `${apiUrl}/api${endpoint}`;

  return baseRequest<ResponseData, RequestData>({
    url,
    method,
    requiresAuth,
    includeCredentials: true,
    data,
    stringifyData,
    contentType
  });
};

/* -------------------------------------------------------------------------- */

type ApiTaskDefaultErrorData = {
  code: ExceptionCode;
  error?: string;
  detail?: string;
};

export type ApiTaskDetails<
  ResponseData = unknown,
  ErrorData = ApiTaskDefaultErrorData
> =
  | { task: string; state: "PENDING"; data: null }
  | { task: string; state: "SUCCESS"; data: ResponseData }
  | { task: string; state: "ERROR"; data: ErrorData };

type ApiTaskArgs<ResponseData, ErrorData> = {
  endpoint: string;
  taskDetails: ApiTaskDetails<ResponseData, ErrorData>;
  checkIntervalMs?: number;
} & Pick<BaseRequestArgs<never>, "method" | "requiresAuth">;

type ApiTaskReturnType<ResponseData, ErrorData> =
  | { state: "SUCCESS"; data: ResponseData }
  | { state: "ERROR"; data: ErrorData };

export const apiTask = async <
  ResponseData = unknown,
  ErrorData = ApiTaskDefaultErrorData
>({
  endpoint,
  method = "GET",
  requiresAuth = true,
  taskDetails,
  checkIntervalMs = 2000
}: ApiTaskArgs<ResponseData, ErrorData>) => {
  type CheckTaskArgs = { isInitialCheck: boolean };
  type CheckTaskResult = Promise<ApiTaskReturnType<ResponseData, ErrorData>>;
  type CheckTask = (args: CheckTaskArgs) => CheckTaskResult;

  const checkTask: CheckTask = async ({ isInitialCheck }) => {
    const taskResponse = isInitialCheck
      ? taskDetails
      : await baseRequest<ApiTaskDetails<ResponseData, ErrorData>>({
          url: `${apiUrl}/api${endpoint}?task=${taskDetails.task}`,
          method,
          requiresAuth,
          includeCredentials: true
        });

    switch (taskResponse.state) {
      case "PENDING": {
        await sleepForMs(checkIntervalMs);
        return checkTask({ isInitialCheck: false });
      }
      case "SUCCESS": {
        return { state: "SUCCESS", data: taskResponse.data };
      }
      case "ERROR": {
        return { state: "ERROR", data: taskResponse.data };
      }
    }
  };

  return checkTask({ isInitialCheck: true });
};

/* -------------------------------------------------------------------------- */

type ExternalRequestArgs<RequestData> = {
  endpoint: string;
} & Pick<
  BaseRequestArgs<RequestData>,
  "method" | "data" | "stringifyData" | "contentType"
>;

export const externalRequest = <ResponseData = unknown, RequestData = unknown>({
  endpoint,
  method = "GET",
  data,
  stringifyData,
  contentType = ""
}: ExternalRequestArgs<RequestData>): Promise<ResponseData> => {
  return baseRequest<ResponseData, RequestData>({
    url: endpoint,
    method,
    requiresAuth: false,
    includeCredentials: false,
    data,
    stringifyData,
    contentType
  });
};

/* -------------------------------------------------------------------------- */

type GraphQLRequestArgs<RequestData> = Pick<
  BaseRequestArgs<RequestData>,
  "requiresAuth" | "data" | "stringifyData" | "contentType"
>;

export const graphqlRequest = <ResponseData = unknown, RequestData = unknown>({
  requiresAuth = true,
  data,
  stringifyData = true,
  contentType = "application/json"
}: GraphQLRequestArgs<RequestData>): Promise<ResponseData> => {
  return baseRequest<ResponseData, RequestData>({
    url: `${apiUrl}/graphql`,
    includeCredentials: true,
    method: "POST",
    requiresAuth,
    data,
    stringifyData,
    contentType
  });
};
