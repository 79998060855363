import { apiRequest } from "../utils";

/* ========================================================================== */

type UploadFileData = { id: number; file: string };

export const uploadFile = (file: File) => {
  const data = new FormData();
  data.append("file", file);

  return apiRequest<UploadFileData, FormData>({
    endpoint: "/upload",
    method: "POST",
    data,
    stringifyData: false,
    contentType: null // this is set automatically - includes boundary info
  });
};
