import {
  GoogleOAuthProvider,
  useGoogleLogin,
  type UseGoogleLoginOptionsAuthCodeFlow,
  type UseGoogleLoginOptionsImplicitFlow
} from "@react-oauth/google";
import { useState } from "react";
import styled from "styled-components";
import { type SetReturnType } from "type-fest";

import {
  ButtonPrimaryAsLinkExternal,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { Modal } from "../components/Modal";
import { toast } from "./toast";

/* ========================================================================== */

type GoogleAuthProviderProps = {
  children: React.ReactNode;
};

export const GoogleAuthProvider: React.FC<GoogleAuthProviderProps> = ({
  children
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
        onScriptLoadError={() => setIsModalVisible(true)}
      >
        {children}
      </GoogleOAuthProvider>
      <GoogleAuthFailureModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
      />
    </>
  );
};

/* -------------------------------------------------------------------------- */

type GoogleAuthFailureModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const GoogleAuthFailureModal: React.FC<GoogleAuthFailureModalProps> = ({
  isVisible,
  setIsVisible
}) => {
  return (
    <Modal isVisible={isVisible} onClose={() => setIsVisible(false)}>
      <ModalContents>
        <ModalTitle>Google Authentication Failed</ModalTitle>
        <ModalBody>
          Something went wrong when we tried to start authenticating with
          Google. This can happen for many reasons, but the most common are
          browser related issues. Please visit our knowledgebase article on this
          topic for more information.
        </ModalBody>
        <ModalButtons>
          <ButtonSecondary variant="SMALL" onClick={() => setIsVisible(false)}>
            Dismiss
          </ButtonSecondary>
          <VisitArticleLink
            variant="SMALL"
            href="https://docs.getelevar.com/docs/why-cant-i-authenticate-with-google"
          >
            Visit Article
          </VisitArticleLink>
        </ModalButtons>
      </ModalContents>
    </Modal>
  );
};

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  text-align: center;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  text-align: center;
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

const VisitArticleLink = styled(ButtonPrimaryAsLinkExternal)`
  width: 100%;
`;

/* ========================================================================== */

type GoogleOAuthScope =
  | "openid"
  | "profile"
  | "email"
  | "https://www.googleapis.com/auth/userinfo.profile"
  | "https://www.googleapis.com/auth/userinfo.email"
  | "https://www.googleapis.com/auth/analytics.readonly"
  | "https://www.googleapis.com/auth/tagmanager.edit.containers"
  | "https://www.googleapis.com/auth/tagmanager.manage.accounts";

const getScope = (scopes: Array<GoogleOAuthScope>) => scopes.join(" ");

/* -------------------------------------------------------------------------- */

type ImplicitOnSuccessSync = NonNullable<
  UseGoogleLoginOptionsImplicitFlow["onSuccess"]
>;
type ImplicitOnSuccess = SetReturnType<
  ImplicitOnSuccessSync,
  Promise<ReturnType<ImplicitOnSuccessSync>>
>;

type UseGoogleLoginImplicitArgs = {
  emailHint?: string;
  scopes: Array<GoogleOAuthScope>;
  onSuccess?: ImplicitOnSuccess;
};

export const useGoogleLoginImplicit = (args: UseGoogleLoginImplicitArgs) => {
  return useGoogleLogin({
    flow: "implicit",
    prompt: "select_account",
    hint: args.emailHint,
    overrideScope: true,
    scope: getScope(args.scopes),
    onSuccess: (...successArgs) => void args.onSuccess?.(...successArgs),
    onError: error => toast.errorUnexpected(error)
  });
};

/* -------------------------------------------------------------------------- */

type AuthCodeOnSuccessSync = NonNullable<
  UseGoogleLoginOptionsAuthCodeFlow["onSuccess"]
>;
type AuthCodeOnSuccess = SetReturnType<
  AuthCodeOnSuccessSync,
  Promise<ReturnType<AuthCodeOnSuccessSync>>
>;

type UseGoogleLoginAuthCodeArgs = {
  emailHint?: string;
  scopes: Array<GoogleOAuthScope>;
  onSuccess?: AuthCodeOnSuccess;
};

export const useGoogleLoginAuthCode = (args: UseGoogleLoginAuthCodeArgs) => {
  return useGoogleLogin({
    flow: "auth-code",
    ux_mode: "popup",
    hint: args.emailHint,
    overrideScope: true,
    scope: getScope(args.scopes),
    onSuccess: (...successArgs) => void args.onSuccess?.(...successArgs),
    onError: error => toast.errorUnexpected(error)
  });
};
