import { useMutation, useQuery } from "@tanstack/react-query";

import { useWebsiteId } from "../../utils/idHooks";
import { graphqlRequest, useExtendedQueryClient } from "../utils";

/* ========================================================================== */

export type ContainerTypeIconTypes =
  | "ADWORDS"
  | "ANALYTICS"
  | "AWIN"
  | "BING"
  | "CART_HOOK"
  | "CLARITY"
  | "COMMISSSION_JUNCTION"
  | "CONSENTMO"
  | "COOKIEBOT"
  | "CRITEO"
  | "DATA_LAYER_V1"
  | "DATA_LAYER_V2"
  | "FACEBOOK"
  | "FAIRING"
  | "GA4"
  | "HOTJAR"
  | "IMPACT"
  | "IUBENDA"
  | "KAMELEOON"
  | "KLAVIYO"
  | "LIVE_INTENT"
  | "LUCKY_ORANGE"
  | "MISC"
  | "NORTHBEAM"
  | "OKENDO"
  | "ONE_TRUST"
  | "OUTBRAIN"
  | "PANDECTES"
  | "PDF"
  | "PEBBLE_POST"
  | "PINTEREST"
  | "PODSIGHTS"
  | "POSTIE"
  | "RAKEUTEN"
  | "RE_CHARGE"
  | "REDDIT"
  | "RETENTION"
  | "SHARE_A_SALE"
  | "SHOPIFY"
  | "SNAPCHAT"
  | "STACK_ADAPT"
  | "STEEL_HOUSE"
  | "TABOOLA"
  | "TATARI"
  | "TIK_TOK"
  | "TWITTER"
  | "UPSCRIBE"
  | "WUNDERKIND"
  | "YAHOO"
  | "YOTPO"
  | "YOUTUBE"
  | "ZEENK";

export type ContainerDetails = {
  id: string | number; // ID
  name: string;
  description: string; // HTML
  typeIcon: ContainerTypeIconTypes;
  type: Array<{ name: string }>;
  tagType: string;
  secondaryDescription: string; // HTML
  containerJson: string; // URL
  linkQueryParam: string;
  updatedAt: string; // DateTime
  containerType: "WEB" | "SS";
  lastDownloadedAt: string | null; // DateTime
  setupGuideLink: string | null; // URL
  parameterHelpLink: string | null; // URL
  parameters: Array<{
    inputLabel: string;
    inputPlaceholder: string;
    tooltipContents: string; // HTML
    variableName: string;
  }>;
};

/* -------------------------------------------------------------------------- */

type FetchContainerDetailsListArgs = {
  websiteId: number;
  version: string | null;
};

type FetchContainerDetailsListResponse = {
  data: { downloadableGtmContainers: Array<ContainerDetails> };
};

const fetchContainerDetailsList = async ({
  websiteId,
  version
}: FetchContainerDetailsListArgs) => {
  const response = await graphqlRequest<FetchContainerDetailsListResponse>({
    data: {
      query: /* GraphQL */ `
        query DownloadableContainersQuery($websiteId: ID!, $version: String) {
          downloadableGtmContainers(websiteId: $websiteId, version: $version) {
            id
            name
            description
            typeIcon
            type {
              name
            }
            tagType
            secondaryDescription
            containerJson
            linkQueryParam
            updatedAt
            containerType
            lastDownloadedAt
            setupGuideLink
            parameterHelpLink
            parameters {
              inputLabel
              inputPlaceholder
              tooltipContents
              variableName
            }
          }
        }
      `,
      variables: { websiteId, version }
    }
  });

  return response.data.downloadableGtmContainers;
};

type UseContainerDetailsListQueryArgs = { version: string | null };

export const useContainerDetailsListQuery = ({
  version
}: UseContainerDetailsListQueryArgs) => {
  const websiteId = useWebsiteId();

  return useQuery({
    queryKey: ["websites", websiteId, "containerDetailsList", version],
    queryFn: () => fetchContainerDetailsList({ websiteId, version })
  });
};

/* -------------------------------------------------------------------------- */

type MarkContainerDownloadedRequest = {
  websiteId: number;
  containerId: string | number;
};

type MarkContainerDownloadedResponse = {
  data: {
    markContainerDownloaded: {
      container: Pick<ContainerDetails, "lastDownloadedAt">;
    };
  };
};

const markContainerDownloaded = async ({
  websiteId,
  containerId
}: MarkContainerDownloadedRequest) => {
  const response = await graphqlRequest<MarkContainerDownloadedResponse>({
    data: {
      query: /* GraphQL */ `
        mutation MarkContainerDownloaded($websiteId: ID!, $containerId: ID!) {
          markContainerDownloaded(
            websiteId: $websiteId
            containerId: $containerId
          ) {
            container {
              lastDownloadedAt
            }
          }
        }
      `,
      variables: { websiteId, containerId }
    }
  });

  return response.data.markContainerDownloaded.container;
};

type UseMarkContainerDownloadedMutationData = { containerId: string | number };

export const useMarkContainerDownloadedMutation = ({
  containerId
}: UseMarkContainerDownloadedMutationData) => {
  const websiteId = useWebsiteId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => markContainerDownloaded({ websiteId, containerId }),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["websites", websiteId, "containerDetailsList"]
      ]);
    }
  });
};
