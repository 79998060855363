import { css } from "styled-components";

import { linkStyles } from "../links/links";

/* ========================================================================== */

export const richTextStyles = css`
  b {
    font-weight: 600;
  }

  i {
    font-style: italic;
  }

  a {
    ${linkStyles};
  }

  p:not(:first-child) {
    margin-top: ${props => props.theme.gridBase}px;
  }
`;
