import { captureException } from "@sentry/browser";

import { createToastShortcuts } from "elevar-design-system/src/toast/toast";

/* ========================================================================== */

export const toast = createToastShortcuts({
  errorUnexpectedHandler: error => {
    console.log(error);
    captureException(error);
  }
});
