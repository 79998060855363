import { Range as ReactRange } from "react-range";
import styled from "styled-components";

import { IconCode } from "elevar-design-system/src/icons";

/* ========================================================================== */

type ReactRangeProps = React.ComponentPropsWithoutRef<typeof ReactRange>;

/* ========================================================================== */

type RangeProps = {
  step: ReactRangeProps["step"];
  min: ReactRangeProps["min"];
  max: ReactRangeProps["max"];
  disabled?: ReactRangeProps["disabled"];
  values: ReactRangeProps["values"];
  onChange: ReactRangeProps["onChange"];
};

export const Range: React.FC<RangeProps> = ({
  step,
  min,
  max,
  disabled = false,
  values,
  onChange
}) => {
  return (
    <RangeWrapper>
      <ReactRange
        step={step}
        min={min}
        max={max}
        values={values}
        onChange={onChange}
        disabled={disabled}
        renderTrack={({ props, children }) => (
          <RangeTrack
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={props.style}
          >
            <div ref={props.ref}>{children}</div>
          </RangeTrack>
        )}
        renderThumb={({ props }) => (
          <RangeThumb {...props} isRangeDisabled={disabled}>
            <IconCode size="24px" />
          </RangeThumb>
        )}
      />
    </RangeWrapper>
  );
};

/* ========================================================================== */

const RangeWrapper = styled.div`
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: ${props => props.theme.gridBase * 2}px;
    height: ${props => props.theme.gridBase * 0.25}px;
    background-color: ${props => props.theme.palette.grey6};
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &::after {
    content: "";
    display: block;
    width: ${props => props.theme.gridBase * 2}px;
    height: ${props => props.theme.gridBase * 0.25}px;
    background-color: ${props => props.theme.palette.grey6};
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const RangeTrack = styled.div`
  display: flex;
  width: 100%;
  height: ${props => props.theme.gridBase * 4}px;

  > div {
    width: 100%;
    align-self: center;
    height: ${props => props.theme.gridBase * 0.25}px;
    background-color: ${props => props.theme.palette.grey6};
  }
`;

type RangeThumbProps = {
  isRangeDisabled: boolean;
};

const RangeThumb = styled.div<RangeThumbProps>`
  width: ${props => props.theme.gridBase * 4}px;
  height: ${props => props.theme.gridBase * 4}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.white};
  background-color: ${props =>
    props.isRangeDisabled
      ? props.theme.palette.grey5
      : props.theme.palette.purple2};
  transition: background-color ${props => props.theme.other.transition};
`;
