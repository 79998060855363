import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { returnDestinationOAuth } from "../api/handlers/website";
import { toast } from "../utils/toast";
import { type Destination, destinations } from "./myTracking/data";

/* ========================================================================== */

export const OAuthReturn: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { target } = useParams<{ target: Destination["configKey"] }>();

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fn = async () => {
      try {
        const params = new URLSearchParams(location.search);

        const urlData = {
          state: params.get("state"),
          code: params.get("code"),
          error: params.get("error"),
          appKey: params.get("app_key"),
          token: params.get("oauth_token"),
          verifier: params.get("oauth_verifier"),
          denied: params.get("denied") // contains an OAuth token if present
        };

        const getResult = async () => {
          if (target === "twitter") {
            const state = urlData.token ?? urlData.denied;

            if (state) {
              return await returnDestinationOAuth({
                target,
                state,
                ...(urlData.token && urlData.verifier
                  ? { verifier: urlData.verifier }
                  : { declined: true })
              });
            }
          } else if (
            urlData.state &&
            (urlData.code || urlData.error === "access_denied")
          ) {
            return await returnDestinationOAuth({
              target,
              state: urlData.state,
              ...(urlData.code
                ? {
                    code: urlData.code,
                    ...(urlData.appKey ? { app_key: urlData.appKey } : {})
                  }
                : { declined: true })
            });
          }
        };

        const result = await getResult();

        if (result) {
          const companyId = result.website.company;
          const websiteId = result.website.id;
          const websiteUrl = `/company/${companyId}/website/${websiteId}`;
          const myTrackingUrl = `${websiteUrl}/my-tracking`;
          const destination = destinations.find(d => d.configKey === target);

          if (destination) {
            const shorthand = destination.shorthand;
            const destinationUrl = `${myTrackingUrl}/destination-${shorthand}`;
            history.replace(`${destinationUrl}/${result.trigger_config_id}`);

            if (urlData.error || urlData.denied) {
              toast.info("Access was declined");
            }
          } else {
            setIsError(true);
            toast.errorExpected("Destination not found");
          }
        } else {
          setIsError(true);
          toast.errorExpected("URL invalid");
        }
      } catch (error) {
        setIsError(true);
        toast.errorUnexpected(error);
      }
    };

    void fn();
  }, [location.search, target, history]);

  return (
    <CenteredWrapper>
      {isError ? <ErrorOccurred /> : <Spinner size="24px" />}
    </CenteredWrapper>
  );
};

/* ========================================================================== */

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
