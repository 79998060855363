import {
  createContext,
  type Dispatch,
  type Reducer,
  type ReducerAction,
  type ReducerState,
  useContext,
  useMemo
} from "react";

import { type ExceptionCode } from "elevar-common-ts/src/apiTypes";

/* ========================================================================== */

type GlobalErrorCode = Extract<
  ExceptionCode,
  "SHOPIFY_LACKS_ACCESS" | "SHOPIFY_LACKS_SCOPE"
>;

type GlobalErrorItemBase = { websiteId: number; code: GlobalErrorCode };

type GlobalErrorReducerState = {
  reactive: GlobalErrorItemBase | null;
  proactive: (GlobalErrorItemBase & { isDismissed: boolean }) | null;
};

export const initialGlobalErrorState: GlobalErrorReducerState = {
  reactive: null,
  proactive: null
};

type GlobalErrorReducerAction =
  | { type: "SET_REACTIVE"; payload: GlobalErrorItemBase | null }
  | { type: "SET_PROACTIVE"; payload: GlobalErrorItemBase | null }
  | { type: "DISMISS_PROACTIVE" }
  | { type: "RESHOW_PROACTIVE" };

export const globalErrorReducer = (
  state: GlobalErrorReducerState,
  action: GlobalErrorReducerAction
): GlobalErrorReducerState => {
  switch (action.type) {
    case "SET_REACTIVE":
      return {
        ...state,
        reactive: action.payload !== null ? { ...action.payload } : null
      };
    case "SET_PROACTIVE":
      return {
        ...state,
        proactive:
          action.payload !== null
            ? { ...action.payload, isDismissed: false }
            : null
      };
    case "DISMISS_PROACTIVE":
      return {
        ...state,
        proactive:
          state.proactive !== null
            ? { ...state.proactive, isDismissed: true }
            : null
      };
    case "RESHOW_PROACTIVE":
      return {
        ...state,
        proactive:
          state.proactive !== null
            ? { ...state.proactive, isDismissed: false }
            : null
      };
  }
};

/* ========================================================================== */

type GlobalErrorReducer = Reducer<
  GlobalErrorReducerState,
  GlobalErrorReducerAction
>;

type GlobalErrorContext = {
  globalError: ReducerState<GlobalErrorReducer>;
  globalErrorDispatch: Dispatch<ReducerAction<GlobalErrorReducer>>;
};

const globalErrorContext = createContext<GlobalErrorContext | undefined>(
  undefined
);

/* ========================================================================== */

type GlobalErrorProviderProps = {
  globalError: ReducerState<GlobalErrorReducer>;
  globalErrorDispatch: Dispatch<ReducerAction<GlobalErrorReducer>>;
  children: React.ReactNode;
};

export const GlobalErrorProvider: React.FC<GlobalErrorProviderProps> = ({
  globalError,
  globalErrorDispatch,
  children
}) => {
  const value = useMemo(
    () => ({ globalError, globalErrorDispatch }),
    [globalError, globalErrorDispatch]
  );

  return (
    <globalErrorContext.Provider value={value}>
      {children}
    </globalErrorContext.Provider>
  );
};

/* ========================================================================== */

export const useGlobalError = () => {
  const globalError = useContext(globalErrorContext);

  if (globalError !== undefined) {
    return globalError;
  } else {
    throw new Error("`useGlobalError`: value not set");
  }
};
