import { useMultipleSelection, useSelect } from "downshift";
import styled, { css, useTheme } from "styled-components";

import { CheckBox } from "elevar-design-system/src/CheckBox";
import { IconChevronDown } from "elevar-design-system/src/icons";
import { type Option } from "elevar-design-system/src/inputs/InputFieldSelect";
import {
  inputFieldFocusStyles,
  type InputFieldProps,
  inputFieldStyles
} from "elevar-design-system/src/inputs/sharedInputStyles";
import { scrollbarMixin } from "elevar-design-system/src/scrollbar";
import { useDownshiftEnvironment } from "elevar-design-system/src/shadowRootHandlers";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  largeTextStyles,
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

export type MultiSelectItem<T> = Option<T> & {
  tag?: string | null;
  error?: boolean | null;
  explainer?: string | null;
  disabled?: boolean | null;
  tooltip?: string | null;
};

type InputFieldMultiSelectProps<T> = InputFieldProps & {
  items: Array<MultiSelectItem<T>>;
  selectedItems: Array<MultiSelectItem<T>>;
  setSelectedItems: (items: Array<MultiSelectItem<T>>) => void;
  placeholder: string;
  disabled?: boolean;
  renderedOn?: "CARD" | "PAGE";
  maxMenuHeight?: string;
};

export const InputFieldMultiSelect = <T,>({
  items,
  selectedItems,
  setSelectedItems,
  placeholder,
  disabled = false,
  renderedOn = "CARD",
  maxMenuHeight = "100%",
  variant
}: InputFieldMultiSelectProps<T>): ReturnType<React.FC> => {
  const theme = useTheme();
  const downshiftEnvironment = useDownshiftEnvironment();

  const { getDropdownProps, addSelectedItem, removeSelectedItem } =
    useMultipleSelection({
      environment: downshiftEnvironment,
      selectedItems,
      onStateChange: ({ type, selectedItems: newSelectedItems }) => {
        const { stateChangeTypes } = useMultipleSelection;
        if (
          type === stateChangeTypes.FunctionRemoveSelectedItem ||
          type === stateChangeTypes.FunctionAddSelectedItem
        ) {
          setSelectedItems(newSelectedItems ?? []);
        }
      }
    });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    selectedItem: null,
    defaultHighlightedIndex: 0,
    items,
    itemToString: item => item?.name ?? "",
    stateReducer: (state, actionAndChanges) => {
      const { stateChangeTypes } = useSelect;
      const { changes, type } = actionAndChanges;
      if (
        type === stateChangeTypes.ToggleButtonKeyDownEnter ||
        type === stateChangeTypes.ToggleButtonKeyDownSpaceButton ||
        type === stateChangeTypes.ItemClick
      ) {
        return {
          ...changes,
          isOpen: true,
          highlightedIndex: state.highlightedIndex
        };
      } else {
        return changes;
      }
    },
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      const { stateChangeTypes } = useSelect;
      if (
        (type === stateChangeTypes.ToggleButtonKeyDownEnter ||
          type === stateChangeTypes.ToggleButtonKeyDownSpaceButton ||
          type === stateChangeTypes.ItemClick) &&
        newSelectedItem
      ) {
        if (!newSelectedItem.disabled) {
          if (selectedItems.includes(newSelectedItem)) {
            removeSelectedItem(newSelectedItem);
          } else {
            addSelectedItem(newSelectedItem);
          }
        }
      }
    }
  });

  return (
    <Wrapper onClick={event => event.preventDefault()}>
      <Button
        {...getToggleButtonProps({
          ...getDropdownProps({ preventKeyAction: isOpen }),
          variant,
          error: selectedItems.some(i => i.error),
          renderedOn,
          disabled,
          selectedItemCount: selectedItems.length,
          isMenuOpen: isOpen
        })}
      >
        {selectedItems.length === 0 ? (
          <span>{placeholder}</span>
        ) : (
          selectedItems
            .map(i => (i.tag ? `${i.name} (${i.tag})` : i.name))
            .join(", ")
        )}
      </Button>
      <IconWrapper variant={variant}>
        <IconChevronDown
          size="16px"
          color={disabled ? theme.palette.grey5 : theme.palette.grey4}
        />
      </IconWrapper>
      <MenuWrapper isOpen={isOpen}>
        <Menu {...getMenuProps({ maxHeight: maxMenuHeight })}>
          {isOpen
            ? items.map((item, index) => (
                <Tooltip
                  key={item.name}
                  placement="top"
                  text={item.tooltip ?? ""}
                  disabled={!item.tooltip}
                  hideOnClick={false}
                  delay={[250, 0]}
                >
                  <div>
                    <MenuItem
                      disabled={item.disabled ?? false}
                      highlighted={index === highlightedIndex}
                      {...getItemProps({ item, index, variant })}
                    >
                      <div>
                        <CheckBox
                          variant={item.error ? "WARNING" : "NORMAL"}
                          isChecked={selectedItems.includes(item)}
                          isHovered={
                            !item.disabled && index === highlightedIndex
                          }
                        />
                      </div>
                      <div>
                        <div>
                          {item.name}
                          {item.tag ? (
                            <>
                              {" "}
                              <span>({item.tag})</span>
                            </>
                          ) : null}
                        </div>
                        {item.explainer ? <div>{item.explainer}</div> : null}
                      </div>
                    </MenuItem>
                  </div>
                </Tooltip>
              ))
            : null}
        </Menu>
      </MenuWrapper>
    </Wrapper>
  );
};

/* ========================================================================== */

const Wrapper = styled.div`
  position: relative;
`;

type ButtonProps = InputFieldProps & {
  renderedOn: InputFieldMultiSelectProps<unknown>["renderedOn"];
  selectedItemCount: number;
  isMenuOpen: boolean;
};

const Button = styled.button<ButtonProps>`
  ${inputFieldStyles};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: ${props =>
    props.variant === "LARGE"
      ? props.theme.gridBase * 6.5
      : props.theme.gridBase * 4.5}px;

  ${props =>
    props.selectedItemCount === 0 &&
    css<ButtonProps>`
      > span {
        color: ${props => props.theme.palette.grey4};

        &:disabled {
          color: ${props => props.theme.palette.grey5};
        }
      }
    `};

  ${props =>
    props.renderedOn === "PAGE" &&
    css<ButtonProps>`
      &,
      &:hover&:not(:disabled),
      &:disabled {
        border-color: ${props => props.theme.palette.white};
        background-color: ${props => props.theme.palette.white};
      }

      &:focus&:not(:disabled) {
        border-color: ${props => props.theme.palette.blue1};
      }
    `};

  ${props =>
    props.isMenuOpen &&
    css<ButtonProps>`
      &,
      &:hover&:not(:disabled) {
        ${inputFieldFocusStyles};
      }
    `};
`;

type IconWrapperProps = {
  variant: InputFieldProps["variant"];
};

const IconWrapper = styled.div<IconWrapperProps>`
  pointer-events: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${props =>
    props.variant === "LARGE"
      ? props.theme.gridBase * 2
      : props.theme.gridBase * 1.5}px;
`;

type MenuWrapperProps = {
  isOpen: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  top: calc(100% + ${props => props.theme.gridBase}px);
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.grey6};
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  z-index: 2;
`;

type MenuProps = {
  maxHeight: string;
};

const Menu = styled.ul<MenuProps>`
  ${scrollbarMixin};
  margin: ${props => props.theme.gridBase * 0.5}px;
  overflow-y: auto;
  max-height: ${props => props.maxHeight};
`;

type MenuItemProps = {
  variant: InputFieldProps["variant"];
  disabled: boolean;
  highlighted: boolean;
};

const MenuItem = styled.li<MenuItemProps>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 1.25}px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  user-select: none;
  border-radius: 2px;
  background-color: ${props =>
    !props.disabled && props.highlighted
      ? props.theme.palette.grey8
      : props.theme.palette.white};
  transition: background-color ${props => props.theme.other.transition};
  padding: ${props =>
    props.variant === "SMALL"
      ? props.theme.gridBase
      : props.theme.gridBase * 1.5}px;

  > div:last-child {
    > div:first-child {
      ${props => props.variant === "SMALL" && normalBodyStyles};
      ${props => props.variant === "LARGE" && largeTextStyles};

      > span {
        color: ${props => props.theme.palette.grey3};
      }
    }

    > div:not(:first-child) {
      ${smallTextStyles};
      color: ${props => props.theme.palette.grey3};
    }
  }
`;
