import { CheckBox, type CheckBoxVariant } from "../CheckBox";
import { LabeledCheckBox } from "./LabeledCheckBox";

/* ========================================================================== */

export type LabeledCheckBoxStandaloneProps = {
  variant: CheckBoxVariant;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  isDisabled?: boolean;
};

export const LabeledCheckBoxStandalone: React.FC<
  LabeledCheckBoxStandaloneProps
> = ({ variant, isChecked, setIsChecked, isDisabled }) => {
  return (
    <LabeledCheckBox
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      isDisabled={isDisabled}
    >
      {({ isHovered, isFocused }) => (
        <CheckBox
          variant={variant}
          isChecked={isChecked}
          isHovered={isHovered}
          isFocused={isFocused}
        />
      )}
    </LabeledCheckBox>
  );
};
