type IconIterableProps = {
  size: "24px";
  className?: string;
};

export const IconIterable: React.FC<IconIterableProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.25"
            d="M18.6292 9.80626L9.89355 18.5419L14.2942 22.9425L23.0298 14.2069L18.6292 9.80626Z"
            fill="#59C1A7"
          />
          <path
            opacity="0.25"
            d="M14.2361 0.998767L9.83545 5.39941L18.5711 14.135L22.9717 9.73438L14.2361 0.998767Z"
            fill="#36C3F2"
          />
          <path
            opacity="0.15"
            d="M5.365 9.81188L0.964355 14.2125L9.69997 22.9481L14.1006 18.5475L5.365 9.81188Z"
            fill="#EF3D55"
          />
          <path
            opacity="0.15"
            d="M9.90846 0.792464L1.17285 9.52808L5.5735 13.9287L14.3091 5.19311L9.90846 0.792464Z"
            fill="#6A266D"
          />
          <path
            d="M11.9826 6.26996C13.714 6.26996 15.1176 4.86638 15.1176 3.13498C15.1176 1.40358 13.714 0 11.9826 0C10.2512 0 8.84766 1.40358 8.84766 3.13498C8.84766 4.86638 10.2512 6.26996 11.9826 6.26996Z"
            fill="#6A266D"
          />
          <path
            d="M20.8303 15.1524C22.5617 15.1524 23.9653 13.7488 23.9653 12.0174C23.9653 10.286 22.5617 8.88245 20.8303 8.88245C19.0989 8.88245 17.6953 10.286 17.6953 12.0174C17.6953 13.7488 19.0989 15.1524 20.8303 15.1524Z"
            fill="#36C3F2"
          />
          <path
            d="M11.9826 24.0001C13.714 24.0001 15.1176 22.5965 15.1176 20.8651C15.1176 19.1337 13.714 17.7301 11.9826 17.7301C10.2512 17.7301 8.84766 19.1337 8.84766 20.8651C8.84766 22.5965 10.2512 24.0001 11.9826 24.0001Z"
            fill="#59C1A7"
          />
          <path
            d="M3.13498 15.1176C4.86638 15.1176 6.26996 13.714 6.26996 11.9826C6.26996 10.2512 4.86638 8.84766 3.13498 8.84766C1.40358 8.84766 0 10.2512 0 11.9826C0 13.714 1.40358 15.1176 3.13498 15.1176Z"
            fill="#EF3D55"
          />
        </svg>
      );
  }
};
