type IconRtbHouseProps = {
  size: "24px";
  className?: string;
};

export const IconRtbHouse: React.FC<IconRtbHouseProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M23 16.4014H1V19.4578H23V16.4014ZM23 4.54504H1V7.60142H23V4.54504Z"
            fill="#ED4136"
          />
        </svg>
      );
  }
};
