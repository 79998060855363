type IconKlaviyoProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconKlaviyo: React.FC<IconKlaviyoProps> = ({
  size,
  color,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M23 4.42001H1V19.5756H23L18.6 11.7533L23 4.42001Z"
            fill={color ?? "#232426"}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.3333 2.94666H0.666656V13.0504H15.3333L12.4 7.83554L15.3333 2.94666Z"
            fill={color ?? "#232426"}
          />
        </svg>
      );
  }
};
