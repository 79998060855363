import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { useCubeQuery } from "../../../api/handlers/cube";

/* ========================================================================== */

type LastEventQueryData = {
  "ServerSideEvents.count": number;
  "ServerSideEvents.time": string;
};

export const useLastEventText = () => {
  const [lastEventText, setLastEventText] = useState<string | null>(null);

  const lastEventQueryResult = useCubeQuery<LastEventQueryData>({
    query: {
      measures: ["ServerSideEvents.count"],
      order: { "ServerSideEvents.time": "desc" },
      limit: 1,
      timeDimensions: [
        {
          dimension: "ServerSideEvents.time",
          dateRange: "from 1 days ago to now",
          granularity: "second"
        }
      ]
    }
  });

  const lastEventData = lastEventQueryResult.data?.resultSet.rawData() ?? null;

  useEffect(() => {
    setLastEventText(
      lastEventData?.[0]
        ? dayjs(lastEventData[0]["ServerSideEvents.time"]).fromNow()
        : null
    );

    const intervalId = setInterval(() => {
      setLastEventText(
        lastEventData?.[0]
          ? dayjs(lastEventData[0]["ServerSideEvents.time"]).fromNow()
          : null
      );
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastEventData]);

  return useMemo(() => lastEventText, [lastEventText]);
};
