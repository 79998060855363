import Cleave from "cleave.js/react";
import { produce } from "immer";
import { transparentize } from "polished";
import { useCallback, useLayoutEffect, useState } from "react";
import styled, { css, useTheme } from "styled-components";

import {
  baseButtonStyles,
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  IconCaretDown,
  IconCaretUp,
  IconCross,
  IconSearchContent
} from "elevar-design-system/src/icons";
import {
  type InputFieldProps,
  inputFieldStyles
} from "elevar-design-system/src/inputs/sharedInputStyles";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  normalBodyStyles,
  normalTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { formatPrice } from "../utils/format";
import { track } from "../utils/track";

/* ========================================================================== */

type ColumnKey = number | string;
type ColumnType = "integer" | "text";
type ColumnFormat = "number" | "currency" | "percent";

export type TableColumn = {
  title: string;
  key: ColumnKey;
  type: ColumnType;
  format?: ColumnFormat;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (value: any, key: ColumnKey) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customFilterInput?: (filter: any) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFilter?: (filter: any, value: any) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sorter?: (a: any, b: any) => number;
};

export type TableRow = {
  key: string | number;
  [columnKey: string]: string | number | null;
};

/* ========================================================================== */

type FilterValue = NumberFilterValue | TextFilterValue;
type FilterState = Array<{ key: ColumnKey; value: FilterValue }>;

export const useTableFilters = (
  defaultFilters: FilterState | (() => FilterState) = []
) => {
  const [filters, setFilters] = useState<FilterState>(defaultFilters);

  return {
    filters,
    updateFilter: (key: ColumnKey, value: FilterValue) => {
      setFilters(prevFilters =>
        produce(prevFilters, draftFilters => {
          const index = draftFilters.findIndex(f => f.key === key);
          const filter = index === -1 ? null : draftFilters[index];

          if (filter) {
            if (value === "") {
              draftFilters.splice(index, 1);
            } else {
              filter.value = value;
            }
          } else {
            draftFilters.push({ key, value });
          }
        })
      );
    },
    resetFilters: () => setFilters(defaultFilters)
  };
};

const numberBetween = (
  filterValue: NumberFilterValue,
  value: number | null | undefined
) => {
  if (value === null || value === undefined) {
    return false;
  } else {
    return (
      (filterValue.from === "" || value >= Number(filterValue.from)) &&
      (filterValue.to === "" || value <= Number(filterValue.to))
    );
  }
};

export const addDefaultFilter = (
  column: TableColumn,
  filterValue: undefined | FilterValue,
  updateFilter: (key: ColumnKey, value: FilterValue) => void
): TableColumn => {
  const draftColumn = { ...column };
  if (column.type === "text") {
    draftColumn.onFilter = stringContains;
    draftColumn.filterValue = filterValue ?? "";
    draftColumn.customFilterInput = (value: TextFilterValue) => (
      <TextFilter
        value={value}
        updateFilter={newValue => updateFilter(draftColumn.key, newValue)}
      />
    );
  } else {
    draftColumn.onFilter = numberBetween;
    draftColumn.filterValue = filterValue ?? { to: "", from: "" };
    draftColumn.customFilterInput = (value: NumberFilterValue) => (
      <NumberFilter
        value={value}
        updateFilter={newValue => updateFilter(draftColumn.key, newValue)}
      />
    );
  }
  return draftColumn;
};

/* ========================================================================== */

type SortState = { column: ColumnKey; direction: "ASC" | "DESC" } | undefined;

type TableProps = {
  columns: Array<TableColumn>;
  rows: Array<TableRow>;
  filtersOpen?: boolean;
  maxVisible?: number;
};

export const Table: React.FC<TableProps> = ({
  columns,
  rows,
  filtersOpen,
  maxVisible = 25
}) => {
  const theme = useTheme();
  const [tableRows, setTableRows] = useState<Array<TableRow>>(rows);
  const [showAll, setShowAll] = useState<boolean>(false);

  const limitOrAll = useCallback(() => {
    if (showAll) return tableRows.slice(0, 1000);
    return tableRows.slice(0, maxVisible);
  }, [tableRows, showAll, maxVisible]);

  const [limitedRows, setLimitedRows] = useState<Array<TableRow>>(limitOrAll);
  const [sort, setSort] = useState<SortState>(undefined);

  /* ------------------------------------------------------------------------ */

  useLayoutEffect(() => {
    const applyFilters = (rows: Array<TableRow>) => {
      return rows.filter(row => {
        return columns.every(column => {
          if (column.onFilter) {
            return column.onFilter(column.filterValue, row[column.key]);
          } else {
            return true;
          }
        });
      });
    };

    const sortByColumn = (
      rows: Array<TableRow>,
      sort: SortState
    ): Array<TableRow> => {
      if (!sort) return rows;

      return rows.sort((a, b) => {
        const bigger = sort.direction === "DESC" ? 1 : -1;
        const smaller = sort.direction === "DESC" ? -1 : 1;

        const first = a[sort.column];
        const second = b[sort.column];

        if (first === second) return 0;
        if (first === null || first === undefined) return smaller;
        if (second === null || second === undefined) return bigger;
        if (typeof first === "string" && typeof second === "string")
          return first.toLowerCase() > second.toLowerCase() ? bigger : smaller;
        return first > second ? bigger : smaller;
      });
    };

    setTableRows(sortByColumn(applyFilters(rows), sort));
  }, [rows, columns, sort]);

  useLayoutEffect(() => {
    setLimitedRows(limitOrAll());
  }, [limitOrAll]);

  /* ------------------------------------------------------------------------ */

  return (
    <TableWrapper columnTypes={columns.map(column => column.type)}>
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <CellHeader key={column.title}>
                <TitleButton
                  dataType={column.type}
                  onClick={() => {
                    setSort(state => {
                      if (!state || state.column !== column.key) {
                        return { column: column.key, direction: "ASC" };
                      } else if (state.direction === "ASC") {
                        return { ...state, direction: "DESC" };
                      } else {
                        return undefined;
                      }
                    });
                    track.tableSortClick();
                  }}
                >
                  <div>
                    {sort?.column === column.key ? (
                      <SortIconWrapper alignRight={column.type !== "text"}>
                        <IconCaretUp
                          size="8px"
                          color={
                            sort.direction === "ASC"
                              ? theme.palette.grey1
                              : theme.palette.grey5
                          }
                        />
                        <IconCaretDown
                          size="8px"
                          color={
                            sort.direction === "DESC"
                              ? theme.palette.grey1
                              : theme.palette.grey5
                          }
                        />
                      </SortIconWrapper>
                    ) : null}
                    {column.title}
                  </div>
                </TitleButton>
              </CellHeader>
            ))}
          </tr>
          {filtersOpen ? (
            <tr>
              {columns.map(column => (
                <CellHeader key={column.key}>
                  {column.customFilterInput?.(column.filterValue)}
                </CellHeader>
              ))}
            </tr>
          ) : null}
        </thead>
        {limitedRows.length > 0 ? (
          <tbody>
            {limitedRows.map(row => (
              <tr key={row.key}>
                {columns.map(column => (
                  <Cell key={column.title}>
                    {column.render ? (
                      column.render(row[column.key], row.key)
                    ) : (
                      <DefaultCellContent dataType={column.type}>
                        {column.type === "integer"
                          ? column.format === "currency"
                            ? formatPrice(Number(row[column.key])).slice(1)
                            : Number(row[column.key]).toLocaleString("en")
                          : row[column.key]}
                      </DefaultCellContent>
                    )}
                  </Cell>
                ))}
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
      {limitedRows.length === 0 && (
        <FooterWrapper>No Results Found</FooterWrapper>
      )}
      {tableRows.length > 0 ? (
        !showAll && tableRows.length > maxVisible ? (
          <FooterWrapper>
            Showing {maxVisible} of {rows.length.toLocaleString("en")} results -{" "}
            <button onClick={() => setShowAll(true)}>View All</button>
          </FooterWrapper>
        ) : (
          <FooterWrapper>
            Showing {tableRows.length} of {rows.length.toLocaleString("en")}{" "}
            results
          </FooterWrapper>
        )
      ) : null}
    </TableWrapper>
  );
};

type TableWrapperProps = {
  columnTypes: Array<ColumnType>;
};

const TableWrapper = styled.div<TableWrapperProps>`
  width: 100%;
  max-width: 100%;

  > table {
    width: 100%;
    border-collapse: collapse;
    display: grid;
    overflow-x: auto;

    & thead {
      border-bottom: 1px solid ${props => props.theme.palette.grey7};

      tr {
        padding-bottom: ${props => props.theme.gridBase * 2}px;

        &:first-of-type {
          padding-top: ${props => props.theme.gridBase}px;
          padding-bottom: ${props => props.theme.gridBase - 1}px;
        }

        &:last-of-type:not(:first-of-type) {
          padding-bottom: ${props => props.theme.gridBase * 2 - 1}px;
        }
      }
    }

    & tr {
      padding-left: ${props => props.theme.gridBase * 3}px;
      padding-right: ${props => props.theme.gridBase * 3}px;
      display: grid;
      column-gap: ${props => props.theme.gridBase * 1.5}px;
      grid-template-columns: ${props =>
        props.columnTypes.map(t =>
          t === "integer" ? "minmax(120px, 1fr)" : "minmax(240px, 2fr)"
        )};
    }

    & tbody {
      tr {
        border-bottom: 1px solid ${props => props.theme.palette.grey7};
        transition: background-color ${props => props.theme.other.transition};

        &:hover {
          background-color: ${props => props.theme.palette.grey8};

          // This is needed for the History table (as it has inputs in rows)
          textarea {
            background-color: ${props =>
              transparentize(0.5, props.theme.palette.grey6)};

            &:hover {
              background-color: ${props => props.theme.palette.white};
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.td`
  ${normalBodyStyles};
  border: 0;
  padding: 0;
`;

type DefaultCellContentProps = {
  dataType: ColumnType;
};

const DefaultCellContent = styled.div<DefaultCellContentProps>`
  text-align: ${props => (props.dataType === "integer" ? "right" : "left")};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5 - 1}px;
  font-feature-settings:
    "cv06" 1,
    "tnum" 1;
  overflow-wrap: break-word;
`;

const CellHeader = styled.th`
  border: 0;
  padding: 0;
`;

type TitleButtonProps = {
  dataType: ColumnType;
};

const TitleButton = styled.button<TitleButtonProps>`
  ${normalTextStyles};
  font-weight: 500;
  width: 100%;
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase}px;

  > div {
    position: relative;
    text-align: ${props => (props.dataType === "integer" ? "right" : "left")};
  }
`;

type SortIconWrapperProps = {
  alignRight: boolean;
};

const SortIconWrapper = styled.div<SortIconWrapperProps>`
  position: absolute;
  top: -13px;
  line-height: ${props => props.theme.gridBase}px;
  height: ${props => props.theme.gridBase}px;

  ${props =>
    props.alignRight &&
    css`
      right: 0;
    `};
`;

const FooterWrapper = styled.div`
  ${normalTextStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;

  > button {
    ${linkStyles};
  }
`;

/* ========================================================================== */

type TablePreHeaderProps = {
  filtersOpen: boolean;
  filtersChanged: boolean;
  setFiltersOpen: (open: boolean) => void;
  resetFilters: () => void;
  renderActions?: () => React.ReactNode;
};

export const TablePreHeader: React.FC<TablePreHeaderProps> = ({
  filtersOpen,
  filtersChanged,
  setFiltersOpen,
  resetFilters,
  renderActions
}) => {
  return (
    <TablePreHeaderWrapper>
      <DefaultActionsWrapper>
        <HideFiltersWrapper>
          <HideFiltersButton
            onClick={() => {
              if (filtersOpen) {
                track.tableFilterHide();
              } else {
                track.tableFilterShow();
              }

              setFiltersOpen(!filtersOpen);
            }}
          >
            <IconSearchContent size="24px" />
            {filtersOpen ? "Hide Search & Filter" : "Show Search & Filter"}
          </HideFiltersButton>
        </HideFiltersWrapper>
        {filtersChanged ? (
          <ResetFiltersButton onClick={resetFilters}>
            Reset Filters
          </ResetFiltersButton>
        ) : null}
      </DefaultActionsWrapper>
      {renderActions?.()}
    </TablePreHeaderWrapper>
  );
};

const TablePreHeaderWrapper = styled.div`
  display: flex;
  padding-right: ${props => props.theme.gridBase * 3}px;
  padding-left: ${props => props.theme.gridBase * 2.5}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey7};
  color: ${props => props.theme.palette.grey3};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DefaultActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HideFiltersWrapper = styled.div`
  min-width: ${props => props.theme.gridBase * 23}px;
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5 - 1}px;
`;

const HideFiltersButton = styled.button`
  ${iconTextButtonStyles};
  text-align: left;
  width: max-content;
  color: ${props => props.theme.palette.grey3};
`;

const ResetFiltersButton = styled.button`
  ${subheadingStyles};
  ${linkStyles};
  color: ${props => props.theme.palette.grey4};
  margin-left: ${props => props.theme.gridBase * 2.5}px;

  &:hover {
    color: ${props => props.theme.palette.grey3};
  }
`;

/* ========================================================================== */

export const ButtonCell = styled.button`
  ${baseButtonStyles};
  ${normalTextStyles};
  display: block;
  background-color: transparent;
  text-align: left;
  margin-top: ${props => props.theme.gridBase * 0.75 - 1}px;
  margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: pre-line;

  &:hover {
    background-color: ${props => props.theme.palette.white};
  }
`;

/* ========================================================================== */

export type TextFilterValue = string;

const stringContains = (
  filterValue: TextFilterValue,
  value: string | null | undefined
) => {
  if (filterValue === "") return true;
  if (value === null || value === undefined) return false;
  return value.toLowerCase().includes(filterValue.toLowerCase().trim());
};

type TextFilterProps = {
  value: TextFilterValue;
  updateFilter: (value: TextFilterValue) => void;
};

const TextFilter: React.FC<TextFilterProps> = ({ value, updateFilter }) => {
  return (
    <TextFilterWrapper>
      <TextFilterInput
        variant="TINY"
        placeholder="Search"
        value={value}
        options={{ delimiter: "" }}
        onChange={event => updateFilter(event.target.rawValue)}
      />
      <div>
        {value !== "" && (
          <ClearInputButton onClick={() => updateFilter("")}>
            <IconCross size="16px" />
          </ClearInputButton>
        )}
      </div>
    </TextFilterWrapper>
  );
};

const TextFilterWrapper = styled.div`
  position: relative;

  > div:last-child {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${props => props.theme.gridBase * 0.5 - 1}px;

    > svg path {
      transition: stroke ${props => props.theme.other.transition};
    }
  }
`;

const TextFilterInput = styled(Cleave).attrs(() => ({
  variant: "TINY"
}))<InputFieldProps>`
  ${inputFieldStyles};
  padding-right: ${props => props.theme.gridBase * 2.75}px;
`;

const ClearInputButton = styled.button`
  ${iconButtonStyles};
  padding: ${props => props.theme.gridBase * 0.25}px;
`;

/* ========================================================================== */

type NumberFilterValue = { to: string; from: string };

type NumberFilterProps = {
  value: NumberFilterValue;
  updateFilter: (value: NumberFilterValue) => void;
};

const NumberFilter: React.FC<NumberFilterProps> = ({ value, updateFilter }) => {
  return (
    <NumberFilterWrapper>
      <NumberFilterInput
        variant="TINY"
        options={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        placeholder="From"
        value={value.from}
        onChange={event => {
          updateFilter({ from: event.target.rawValue, to: value.to });
        }}
      />
      <NumberFilterInput
        variant="TINY"
        options={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
        placeholder="To"
        value={value.to}
        onChange={event => {
          updateFilter({ from: value.from, to: event.target.rawValue });
        }}
      />
    </NumberFilterWrapper>
  );
};

const NumberFilterWrapper = styled.div`
  display: grid;
  gap: ${props => props.theme.gridBase}px;
  grid-template-columns: 1fr 1fr;
`;

const NumberFilterInput = styled(Cleave)<InputFieldProps>`
  ${inputFieldStyles};
`;
