import { useTheme } from "styled-components";

/* ========================================================================== */

type IconDataLayerV1Props = {
  size: "24px";
  className?: string;
};

export const IconDataLayerV1: React.FC<IconDataLayerV1Props> = ({
  size,
  className
}) => {
  const theme = useTheme();

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10.56 22.62L3.65992 17.52C2.33992 16.56 2.33992 14.58 3.65992 13.62L10.62 8.58C11.46 7.98 12.6 7.98 13.4399 8.58L20.4 13.62C21.7199 14.58 21.7199 16.56 20.4 17.52L13.4399 22.56C12.54 23.22 11.46 23.22 10.56 22.62Z"
            fill="#6254EB"
          />
          <path
            d="M20.3401 13.68L19.7401 13.2L13.4401 17.82C12.6001 18.42 11.4601 18.42 10.6201 17.82L4.26011 13.2L3.60011 13.68C2.28011 14.64 2.28011 16.62 3.60011 17.5799L10.5601 22.62C11.4001 23.2199 12.5401 23.2199 13.3801 22.62L20.3401 17.5799C21.6601 16.56 21.6601 14.64 20.3401 13.68Z"
            fill="#5424E1"
          />
          <path
            d="M21.3599 8.40002V10.8C21.3599 11.52 20.9999 12.24 20.3999 12.72L13.4399 17.76C12.5999 18.36 11.4599 18.36 10.6199 17.76L3.59989 12.72C2.93989 12.24 2.63989 11.52 2.63989 10.8V8.40002H21.3599Z"
            fill="#6254EB"
          />
          <path
            d="M10.56 15.4201L3.65992 10.3201C2.33992 9.36006 2.33992 7.38006 3.65992 6.42006L10.62 1.38005C11.46 0.780054 12.6 0.780054 13.4399 1.38005L20.4 6.42006C21.7199 7.38006 21.7199 9.36006 20.4 10.3201L13.4399 15.3601C12.54 16.0201 11.46 16.0201 10.56 15.4201Z"
            fill="#B0ABF4"
          />
          <path
            d="M9.41931 5.56366H8.85681C8.82357 5.61735 8.44767 6.34094 7.63977 6.34094V6.87272C8.24061 6.87272 8.67015 6.62732 8.74431 6.4841H8.78517V10.8H9.41931V5.56366ZM11.3694 10.8409C11.6226 10.8409 11.8296 10.6338 11.8296 10.3807C11.8296 10.1276 11.6226 9.92048 11.3694 9.92048C11.1163 9.92048 10.9092 10.1276 10.9092 10.3807C10.9092 10.6338 11.1163 10.8409 11.3694 10.8409ZM14.3212 10.8716C15.4769 10.8716 16.1417 9.89234 16.1417 8.18186C16.1417 6.4841 15.4667 5.49207 14.3212 5.49207C13.1757 5.49207 12.5007 6.4841 12.5007 8.18186C12.5007 9.89234 13.1655 10.8716 14.3212 10.8716ZM14.3212 10.3091C13.5593 10.3091 13.1144 9.54464 13.1144 8.18186C13.1144 6.8216 13.5644 6.04436 14.3212 6.04436C15.078 6.04436 15.528 6.8216 15.528 8.18186C15.528 9.54464 15.0831 10.3091 14.3212 10.3091Z"
            fill={theme.palette.white}
          />
        </svg>
      );
  }
};
