import { transparentize } from "polished";
import { useState } from "react";
import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconGoogle } from "elevar-design-system/src/icons";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { useWebsiteGoogleAuthMutation } from "../../../../api/handlers/website";
import { PageCard } from "../../../../components/PageCard";
import { useGoogleLoginAuthCode } from "../../../../utils/googleAuth";
import { toast } from "../../../../utils/toast";

/* ========================================================================== */

type PageCardGoogleProps = {
  websiteDetails: WebsiteDetails;
};

export const PageCardGoogle: React.FC<PageCardGoogleProps> = ({
  websiteDetails
}) => {
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);

  const { mutateAsync: websiteGoogleAuthMutation } =
    useWebsiteGoogleAuthMutation();

  const googleAuth = useGoogleLoginAuthCode({
    scopes: [
      "openid",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/analytics.readonly",
      "https://www.googleapis.com/auth/tagmanager.edit.containers",
      "https://www.googleapis.com/auth/tagmanager.manage.accounts"
    ],
    onSuccess: async response => {
      setIsGoogleAuthLoading(true);
      await websiteGoogleAuthMutation({ auth_code: response.code });
      setIsGoogleAuthLoading(false);
      toast.success("Google auth updated");
    }
  });

  const onConnectOrEditClick = () => {
    if (!isGoogleAuthLoading) googleAuth();
  };

  const googleAccount = websiteDetails.google_account;

  if (googleAccount) {
    return (
      <PageCard>
        <AuthedGoogleAccountPageCardContents>
          <div>
            <div>
              <IconGoogle size="24px" />
            </div>
            <div>
              <AuthedGoogleAccountPageCardTitle>
                Google Account
              </AuthedGoogleAccountPageCardTitle>
              <AuthedGoogleAccountPageCardSubText>
                <div>Email:</div>
                <div>{googleAccount.email}</div>
              </AuthedGoogleAccountPageCardSubText>
            </div>
          </div>
          <div>
            {isGoogleAuthLoading ? (
              <LoadingSpinnerWrapper>
                <Spinner size="24px" />
              </LoadingSpinnerWrapper>
            ) : (
              <EditButton onClick={onConnectOrEditClick}>
                {googleAccount.last_refresh_failed ? "Re-authenticate" : "Edit"}
              </EditButton>
            )}
          </div>
        </AuthedGoogleAccountPageCardContents>
        {googleAccount.last_refresh_failed ? (
          <AuthedGoogleAccountPageCardErrorNotice>
            Access to the Google account associated to this Website has been
            revoked. To use all the features available to this Website, please
            re-auth with the associated Google account above.
          </AuthedGoogleAccountPageCardErrorNotice>
        ) : null}
      </PageCard>
    );
  } else {
    return (
      <PageCard>
        <NotAuthedGoogleAccountPageCardContents>
          <div>
            <div>
              <IconGoogle size="24px" />
            </div>
            <div>
              <NotAuthedGoogleAccountPageCardTitle>
                Google Account
              </NotAuthedGoogleAccountPageCardTitle>
            </div>
          </div>
          <div>
            <NotAuthedGoogleAccountPageCardSubText>
              Please authenticate a Google account that has access to all the
              Google related connections that you require.
            </NotAuthedGoogleAccountPageCardSubText>
          </div>
          <div>
            <ButtonPrimary
              variant="SMALL"
              state={isGoogleAuthLoading ? "LOADING" : "IDLE"}
              onClick={onConnectOrEditClick}
            >
              Authenticate
            </ButtonPrimary>
          </div>
        </NotAuthedGoogleAccountPageCardContents>
      </PageCard>
    );
  }
};

/* ========================================================================== */

const AuthedGoogleAccountPageCardContents = styled.div`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    display: flex;

    > div:first-child {
      display: flex;
      margin-right: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const AuthedGoogleAccountPageCardErrorNotice = styled.div`
  ${normalBodyStyles};
  margin-top: ${props => props.theme.gridBase * 2}px;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;
  color: ${props => props.theme.palette.red1};
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
`;

const AuthedGoogleAccountPageCardTitle = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const AuthedGoogleAccountPageCardSubText = styled.div`
  ${normalTextStyles};
  display: flex;
  gap: ${props => props.theme.gridBase * 0.75}px;

  > div:first-child {
    color: ${props => props.theme.palette.grey3};
    white-space: nowrap;
  }

  > div:last-child {
    color: ${props => props.theme.palette.grey2};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
`;

const EditButton = styled.button`
  ${normalTextStyles};
  ${linkStyles};
`;

const NotAuthedGoogleAccountPageCardContents = styled.div`
  > div:nth-child(1) {
    display: flex;
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      display: flex;
      margin-right: ${props => props.theme.gridBase * 1.5}px;
    }
  }

  > div:nth-child(2) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const NotAuthedGoogleAccountPageCardTitle = styled.div`
  ${heading3Styles};
`;

const NotAuthedGoogleAccountPageCardSubText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;
