import "react-day-picker/style.css";

import {
  type DateRange as RawCubeDateRange,
  type TimeDimensionGranularity
} from "@cubejs-client/core";
import dayjs from "dayjs";
import { transparentize } from "polished";
import { createContext, useContext, useId, useMemo, useState } from "react";
import {
  Chevron,
  type DateRange as DayPickerDateRange,
  DayButton,
  DayPicker
} from "react-day-picker";
import { useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { type SetNonNullable, type Tagged } from "type-fest";

import { ButtonDropdownBase } from "elevar-design-system/src/buttons/ButtonDropdown";
import {
  baseButtonStyles,
  smallButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight
} from "elevar-design-system/src/icons";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { track } from "../utils/track";

/* ========================================================================== */

type PredefinedMonitoringTimePeriod =
  | "LAST_24_HOURS"
  | "LAST_7_DAYS"
  | "LAST_30_DAYS";

export type MonitoringTimePeriod =
  | { selection: PredefinedMonitoringTimePeriod }
  | ({ selection: "CUSTOM" } & Required<SetNonNullable<DayPickerDateRange>>);

type MonitoringTimePeriodContext = {
  monitoringTimePeriod: MonitoringTimePeriod;
  setMonitoringTimePeriod: (monitoringTimePeriod: MonitoringTimePeriod) => void;
};

const monitoringTimePeriodContext = createContext<
  MonitoringTimePeriodContext | undefined
>(undefined);

/* ========================================================================== */

type MonitoringTimePeriodProviderProps = {
  defaultSelection: PredefinedMonitoringTimePeriod;
  children: React.ReactNode;
};

export const MonitoringTimePeriodProvider: React.FC<
  MonitoringTimePeriodProviderProps
> = ({ defaultSelection, children }) => {
  const location = useLocation();

  const [monitoringTimePeriod, setMonitoringTimePeriod] =
    useState<MonitoringTimePeriod>(() => {
      const searchParams = new URLSearchParams(location.search);
      const period = searchParams.get("monitoringTimePeriodDefault");

      switch (period) {
        case "LAST_24_HOURS":
          return { selection: "LAST_24_HOURS" };
        case "LAST_7_DAYS":
          return { selection: "LAST_7_DAYS" };
        case "LAST_30_DAYS":
          return { selection: "LAST_30_DAYS" };
        default:
          return { selection: defaultSelection };
      }
    });

  const contextValue = useMemo(() => {
    return { monitoringTimePeriod, setMonitoringTimePeriod };
  }, [monitoringTimePeriod, setMonitoringTimePeriod]);

  return (
    <monitoringTimePeriodContext.Provider value={contextValue}>
      {children}
    </monitoringTimePeriodContext.Provider>
  );
};

/* ========================================================================== */

export const useMonitoringTimePeriod = () => {
  const monitoringTimePeriod = useContext(monitoringTimePeriodContext);

  if (monitoringTimePeriod !== undefined) {
    return monitoringTimePeriod;
  } else {
    throw new Error("`useMonitoringTimePeriod`: value not set");
  }
};

/* ========================================================================== */

export type CubeDateRange = Tagged<RawCubeDateRange, "CubeDateRange">;

type GetCubeDateRangeOptions = { fromNow?: boolean };

const formatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD");

export const getCubeDateRangeFromMonitoringTimePeriod = (
  period: MonitoringTimePeriod,
  options?: GetCubeDateRangeOptions
): CubeDateRange => {
  const fn = (): RawCubeDateRange => {
    switch (period.selection) {
      case "LAST_24_HOURS":
        return options?.fromNow ? "from 24 hours ago to now" : "last 24 hours";
      case "LAST_7_DAYS":
        return options?.fromNow ? "from 7 days ago to now" : "last 7 days";
      case "LAST_30_DAYS":
        return options?.fromNow ? "from 30 days ago to now" : "last 30 days";
      case "CUSTOM":
        return options?.fromNow && dayjs().isSame(period.to, "day")
          ? `from ${period.from.toISOString()} to now`
          : [formatDate(period.from), formatDate(period.to)];
    }
  };
  return fn() as CubeDateRange;
};

export type CubeGranularity = Extract<
  TimeDimensionGranularity,
  "week" | "hour" | "day"
>;

export const getCubeGranularityFromMonitoringTimePeriod = (
  period: MonitoringTimePeriod
): CubeGranularity => {
  switch (period.selection) {
    case "LAST_24_HOURS":
      return "hour";
    case "LAST_7_DAYS":
      return "day";
    case "LAST_30_DAYS":
      return "day";
    case "CUSTOM": {
      const from = dayjs(period.from);
      const to = dayjs(period.to).add(1, "day");

      return Math.abs(from.diff(to, "hour")) <= 48
        ? "hour"
        : Math.abs(from.diff(to, "day")) <= 48
          ? "day"
          : "week";
    }
  }
};

/* ========================================================================== */

const currentDate = dayjs();
const defaultMonth = currentDate.subtract(1, "month").toDate();
const startDate = currentDate.subtract(90, "days").toDate();
const endDate = currentDate.toDate();

const getPeriodDisplayText = (period: MonitoringTimePeriod): string => {
  switch (period.selection) {
    case "LAST_24_HOURS":
      return "Last 24 Hours";
    case "LAST_7_DAYS":
      return "Last 7 Days";
    case "LAST_30_DAYS":
      return "Last 30 Days";
    case "CUSTOM":
      return dayjs(period.from).isSame(period.to, "day")
        ? formatDate(period.from)
        : `${formatDate(period.from)} – ${formatDate(period.to)}`;
  }
};

type CustomDraft = DayPickerDateRange | null;

export const InputFieldMonitoringTimePeriod: React.FC = () => {
  const theme = useTheme();
  const radioGroupName = useId();
  const { monitoringTimePeriod: period, setMonitoringTimePeriod } =
    useMonitoringTimePeriod();

  const [customDraft, setCustomDraft] = useState<CustomDraft>(null);

  const setPeriod = (value: typeof period) => {
    setCustomDraft(null);
    setMonitoringTimePeriod(value);
    track.monitoringTimePeriodChange({ period: value });
  };

  const isCustomPeriodSelected =
    period.selection === "CUSTOM" || customDraft !== null;

  return (
    <PeriodButtonDropdown
      dropdownPlacement="bottom-end"
      dropdownContent={popOutArgs => (
        <PeriodButtonDropdownContent>
          <LabeledRadioText
            groupName={radioGroupName}
            isSelected={
              period.selection === "LAST_24_HOURS" && !isCustomPeriodSelected
            }
            setIsSelected={() => setPeriod({ selection: "LAST_24_HOURS" })}
            text={getPeriodDisplayText({ selection: "LAST_24_HOURS" })}
            isFullWidth={true}
          />
          <LabeledRadioText
            groupName={radioGroupName}
            isSelected={
              period.selection === "LAST_7_DAYS" && !isCustomPeriodSelected
            }
            setIsSelected={() => setPeriod({ selection: "LAST_7_DAYS" })}
            text={getPeriodDisplayText({ selection: "LAST_7_DAYS" })}
            isFullWidth={true}
          />
          <LabeledRadioText
            groupName={radioGroupName}
            isSelected={
              period.selection === "LAST_30_DAYS" && !isCustomPeriodSelected
            }
            setIsSelected={() => setPeriod({ selection: "LAST_30_DAYS" })}
            text={getPeriodDisplayText({ selection: "LAST_30_DAYS" })}
            isFullWidth={true}
          />
          <LabeledRadioText
            groupName={radioGroupName}
            isSelected={isCustomPeriodSelected}
            setIsSelected={() => {
              setCustomDraft({ from: undefined, to: undefined });
            }}
            text="Custom"
            isFullWidth={true}
          />
          {isCustomPeriodSelected ? (
            <CustomRangePickerWrapper>
              <CustomRangePicker
                mode="range"
                numberOfMonths={2}
                defaultMonth={defaultMonth}
                startMonth={startDate}
                endMonth={endDate}
                ISOWeek={true}
                min={0}
                formatters={{
                  formatWeekdayName: date => dayjs(date).format("ddd")
                }}
                components={{
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  Nav: props => <>{props.children}</>,
                  Chevron: props =>
                    props.orientation === "left" ? (
                      <IconChevronLeft size="16px" />
                    ) : props.orientation === "right" ? (
                      <IconChevronRight size="16px" />
                    ) : (
                      <Chevron {...props} />
                    ),
                  DayButton: props => (
                    <Tooltip
                      placement="top"
                      maxWidth={`${theme.gridBase * 15.5}px`}
                      text={
                        dayjs(props.day.date).isAfter(endDate)
                          ? "You cannot select days in the future"
                          : "You can go back as far as 90 days"
                      }
                      disabled={!props.disabled}
                      delay={[250, 0]}
                    >
                      <span>
                        <DayButton {...props} />
                      </span>
                    </Tooltip>
                  )
                }}
                disabled={{ before: startDate, after: endDate }}
                selected={
                  customDraft ??
                  (period.selection === "CUSTOM" ? period : undefined)
                }
                onSelect={selected => {
                  setCustomDraft(
                    selected ?? { from: undefined, to: undefined }
                  );
                }}
              />
              {customDraft?.from ? (
                <CustomRangeDraftActions>
                  <ButtonSecondary
                    variant="SMALL"
                    onClick={() => {
                      setCustomDraft(null);
                      popOutArgs.hidePopOut();
                    }}
                  >
                    Cancel
                  </ButtonSecondary>
                  <ButtonPrimary
                    variant="SMALL"
                    onClick={() => {
                      setPeriod({
                        selection: "CUSTOM",
                        from: customDraft.from!,
                        to: customDraft.to ?? customDraft.from!
                      });
                      popOutArgs.hidePopOut();
                    }}
                  >
                    Select
                  </ButtonPrimary>
                </CustomRangeDraftActions>
              ) : null}
            </CustomRangePickerWrapper>
          ) : null}
        </PeriodButtonDropdownContent>
      )}
    >
      <div>{getPeriodDisplayText(period)}</div>
      <div>
        <IconChevronDown size="16px" />
      </div>
    </PeriodButtonDropdown>
  );
};

const PeriodButtonDropdown = styled(ButtonDropdownBase)`
  ${baseButtonStyles};
  ${smallButtonStyles};
  background-color: ${props => props.theme.palette.white};
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  min-width: ${props => props.theme.gridBase * 20}px;
  text-align: left;
  white-space: nowrap;
  position: relative;

  > div:last-child {
    display: flex;
    color: ${props => props.theme.palette.grey4};
  }
`;

const PeriodButtonDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.gridBase * 0.5}px;
  width: ${props => props.theme.gridBase * 20}px;
`;

const CustomRangePickerWrapper = styled.div`
  position: absolute;
  top: calc(100% + ${props => props.theme.gridBase * 0.25}px);
  right: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${props => props.theme.palette.grey6};
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  padding: ${props => props.theme.gridBase * 3}px;
`;

const CustomRangePicker = styled(DayPicker)`
  --rdp-accent-color: ${props => props.theme.palette.purple2};
  --rdp-accent-background-color: ${props =>
    transparentize(0.92, props.theme.palette.purple2)};
  --rdp-day-height: ${props => props.theme.gridBase * 4.75}px;
  --rdp-day-width: ${props => props.theme.gridBase * 4.5}px;
  --rdp-day_button-border-radius: 2px;
  --rdp-disabled-opacity: 1;
  --rdp-today-color: inherit;
  --rdp-months-gap: ${props => props.theme.gridBase * 4}px;
  --rdp-nav_button-disabled-opacity: 1;

  .rdp-months {
    ${normalTextStyles};
    flex-wrap: nowrap;
  }

  .rdp-month_caption {
    ${heading3Styles};
    justify-content: center;
    height: ${props => props.theme.gridBase * 4}px;
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  .rdp-button_previous,
  .rdp-button_next {
    position: absolute;
    height: ${props => props.theme.gridBase * 4}px;
    color: ${props => props.theme.palette.grey3};

    &:disabled {
      cursor: not-allowed;
      color: ${props => props.theme.palette.grey4};
    }

    &.rdp-button_previous {
      left: 0;
    }

    &.rdp-button_next {
      right: 0;
    }
  }

  .rdp-weekday {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey3};
  }

  .rdp-day {
    &.rdp-selected {
      ${normalTextStyles};

      &.rdp-range_start,
      &.rdp-range_end {
        color: ${props => props.theme.palette.white};
      }
    }

    &.rdp-disabled {
      cursor: not-allowed;
      color: ${props => props.theme.palette.grey4};
    }
  }
`;

const CustomRangeDraftActions = styled.div`
  display: flex;
  justify-content: end;
  gap: ${props => props.theme.gridBase}px;
  margin-top: ${props => props.theme.gridBase * 2}px;
`;
