type IconHotjarProps = {
  size: "24px";
  className?: string;
};

export const IconHotjar: React.FC<IconHotjarProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3049 1C16.3049 6.6425 12.8159 8.58127 10.2684 9.99655L10.2683 9.99655C7.94179 11.2891 6.40483 12.143 6.40483 15.3002H2.18C2.18 9.65828 5.66765 7.72001 8.21504 6.30429L8.21661 6.30342L8.21672 6.30335C10.5432 5.01081 12.0801 4.15695 12.0801 1H16.3049ZM13.7172 14.0033C16.0438 12.7109 17.5807 11.8571 17.5807 8.6999H21.8052C21.8052 14.3429 18.3164 16.2811 15.7689 17.6964L15.7688 17.6965L15.7688 17.6965C13.4422 18.989 11.9053 19.8429 11.9053 23H7.68062C7.68062 17.3579 11.1694 15.4191 13.7162 14.0039L13.7172 14.0033Z"
            fill="#FF3200"
          />
        </svg>
      );
  }
};
