type IconDownloadCloudProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconDownloadCloud: React.FC<IconDownloadCloudProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8 17L12 21L16 17"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 12V21"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.88 18.09C21.7494 17.4786 22.4014 16.6061 22.7413 15.5991C23.0812 14.5921 23.0914 13.503 22.7704 12.4898C22.4494 11.4766 21.8139 10.5921 20.9561 9.96456C20.0983 9.33703 19.0628 8.99916 18 8.99999H16.74C16.4392 7.82787 15.8765 6.73924 15.0941 5.81607C14.3117 4.89291 13.3301 4.15925 12.2232 3.67035C11.1163 3.18144 9.91284 2.95002 8.70352 2.99351C7.4942 3.037 6.31051 3.35426 5.24155 3.92142C4.17259 4.48858 3.24622 5.29084 2.53218 6.26782C1.81814 7.2448 1.33505 8.37104 1.11925 9.56174C0.903464 10.7524 0.960604 11.9766 1.28637 13.142C1.61214 14.3074 2.19805 15.3838 2.99999 16.29"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M5.33252 11.3411L7.99918 14.0077L10.6658 11.3411"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.99902 8.00781V14.0078"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.919 12.0678C14.4986 11.6602 14.9332 11.0785 15.1598 10.4072C15.3864 9.73587 15.3932 9.00979 15.1792 8.33433C14.9652 7.65886 14.5415 7.06917 13.9697 6.65082C13.3978 6.23246 12.7075 6.00721 11.999 6.00777H11.159C10.9584 5.22635 10.5833 4.50061 10.0617 3.88516C9.54012 3.26972 8.88574 2.78062 8.14778 2.45468C7.40982 2.12874 6.60754 1.97446 5.80133 2.00345C4.99511 2.03245 4.20598 2.24395 3.49334 2.62205C2.7807 3.00015 2.16313 3.53501 1.6871 4.18633C1.21108 4.83765 0.889013 5.58847 0.745153 6.38228C0.601293 7.17608 0.639383 7.99216 0.856563 8.76911C1.07374 9.54606 1.46435 10.2636 1.99898 10.8678"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
