import { useQuery } from "@tanstack/react-query";

import { graphqlRequest } from "../utils";

/* ========================================================================== */

export type PopularArticleNode = {
  id: string | number; // ID
  title: string;
  link: string; // URL
};

type PopularArticlesResponse = {
  data: { popularArticles: Array<PopularArticleNode> };
};

const fetchPopularArticles = async () => {
  const response = await graphqlRequest<PopularArticlesResponse>({
    data: {
      query: /* GraphQL */ `
        query PopularArticles {
          popularArticles {
            id
            title
            link
          }
        }
      `
    }
  });

  return response.data.popularArticles;
};

export const usePopularArticlesQuery = () => {
  return useQuery({
    queryKey: ["cms", "popularArticles"],
    queryFn: fetchPopularArticles
  });
};

/* ========================================================================== */

export type SupportTeamMemberNode = {
  id: string | number; // ID
  name: string;
  title: string;
  description: string;
  picture: {
    title: string;
    file: string; // URL
  };
};

type SupportTeamMembersResponse = {
  data: { supportTeamMembers: Array<SupportTeamMemberNode> };
};

const fetchSupportTeamMembers = async () => {
  const response = await graphqlRequest<SupportTeamMembersResponse>({
    data: {
      query: /* GraphQL */ `
        query SupportTeamMembers {
          supportTeamMembers {
            id
            name
            title
            description
            picture {
              title
              file
            }
          }
        }
      `
    }
  });

  return response.data.supportTeamMembers;
};

export const useSupportTeamMembersQuery = () => {
  return useQuery({
    queryKey: ["cms", "supportTeamMembers"],
    queryFn: fetchSupportTeamMembers
  });
};
