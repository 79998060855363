type IconCrossProps = {
  size: "24px" | "16px" | "8px";
  color?: string;
  className?: string;
};

export const IconCross: React.FC<IconCrossProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 6L6 18"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.64645 11.6464C3.45118 11.8417 3.45118 12.1583 3.64645 12.3536C3.84171 12.5488 4.15829 12.5488 4.35355 12.3536L8 8.70711L11.6464 12.3536C11.8417 12.5488 12.1583 12.5488 12.3536 12.3536C12.5488 12.1583 12.5488 11.8417 12.3536 11.6464L8.70711 8L12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645C12.1583 3.45119 11.8417 3.45119 11.6464 3.64645L8 7.29289L4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645C3.45119 3.84171 3.45119 4.15829 3.64645 4.35355L7.29289 8L3.64645 11.6464Z"
            fill={color}
          />
        </svg>
      );
    case "8px":
      return (
        <svg
          className={className}
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path d="M7 1L1 7" stroke={color} strokeLinecap="round" />
          <path d="M7 7L1 0.999999" stroke={color} strokeLinecap="round" />
        </svg>
      );
  }
};
