type IconRechargeProps = {
  size: "24px";
  className?: string;
};

export const IconRecharge: React.FC<IconRechargeProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 12C24 18.6275 18.6274 24 12 24C5.37258 24 0 18.6275 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM7.19783 16.7476H13.1453C13.2366 16.7476 13.2823 16.6337 13.2366 16.5541L8.43069 10.6001C8.36216 10.5205 8.24804 10.5546 8.2252 10.6456L7.07224 16.5996C7.06082 16.6794 7.11791 16.7476 7.19783 16.7476ZM7.08603 7.7603H13.0335C13.1134 7.7603 13.1705 7.8286 13.1591 7.9083L12.0061 13.8623C11.9832 13.9534 11.8691 13.9875 11.8006 13.9079L6.9947 7.94245C6.93761 7.87414 6.98328 7.7603 7.08603 7.7603ZM13.6382 7.85137C13.6496 7.80584 13.6953 7.7603 13.7523 7.7603H18.0331C18.1017 7.7603 18.1702 7.8286 18.1587 7.89691L17.3596 11.7676C17.3482 11.8245 17.3026 11.8586 17.2455 11.8586H17.12C15.0081 11.8586 13.33 9.91186 13.6382 7.85137Z"
            fill="#3901F1"
          />
        </svg>
      );
  }
};
