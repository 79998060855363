import { toast as underlyingToast } from "react-toastify";
import styled, { type DefaultTheme, useTheme } from "styled-components";

import {
  IconAlertCircle,
  IconCheckMark,
  IconCircledInfo,
  IconCross
} from "../icons";
import { normalTextStyles } from "../typography/typography";

/* ========================================================================== */

type TypeOptions = "info" | "success" | "warning" | "error";

const baseToast = (type: TypeOptions, content: string) => {
  underlyingToast(
    ({ closeToast }) => (
      <Toast type={type} content={content} closeToast={closeToast} />
    ),
    { type }
  );
};

type CreateToastShortcutsArgs = {
  errorUnexpectedHandler: (error: unknown) => void;
};

export const createToastShortcuts = (args: CreateToastShortcutsArgs) => {
  return {
    info: (content: string) => baseToast("info", content),
    success: (content: string) => baseToast("success", content),
    warning: (content: string) => baseToast("warning", content),
    errorExpected: (content: string) => baseToast("error", content),
    errorUnexpected: (error: unknown) => {
      args.errorUnexpectedHandler(error);
      baseToast("error", "Unexpected error occurred");
    }
  };
};

/* ========================================================================== */

const getColorForType = (type: TypeOptions, theme: DefaultTheme) => {
  switch (type) {
    case "info":
      return theme.palette.blue1;
    case "success":
      return theme.palette.green;
    case "warning":
      return theme.palette.yellow;
    case "error":
      return theme.palette.red1;
  }
};

type IconForTypeProps = {
  type: TypeOptions;
};

const IconForType: React.FC<IconForTypeProps> = ({ type }) => {
  const theme = useTheme();
  const color = getColorForType(type, theme);

  switch (type) {
    case "info":
      return <IconCircledInfo size="16px" color={color} />;
    case "success":
      return <IconCheckMark size="16px" color={color} />;
    case "warning":
      return <IconAlertCircle size="16px" color={color} />;
    case "error":
      return <IconAlertCircle size="16px" color={color} />;
  }
};

/* ========================================================================== */

type ToastProps = {
  content: string;
  type: TypeOptions;
  closeToast: () => void;
};

const Toast: React.FC<ToastProps> = ({ content, type, closeToast }) => {
  return (
    <ToastWrapper>
      <div>
        <TypeBar type={type} />
      </div>
      <MainToastContent>
        <TypeIconAndTextContent>
          <TypeIconWrapper>
            <IconForType type={type} />
          </TypeIconWrapper>
          <TextContentWrapper>{content}</TextContentWrapper>
        </TypeIconAndTextContent>
        <CloseButton onClick={closeToast}>
          <IconCross size="16px" />
        </CloseButton>
      </MainToastContent>
    </ToastWrapper>
  );
};

const ToastWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.palette.grey1};
  border-radius: 4px;
  user-select: none;
`;

type TypeBarProps = {
  type: TypeOptions;
};

const TypeBar = styled.div<TypeBarProps>`
  width: 2px;
  border-radius: 2px;
  height: calc(100% - ${props => props.theme.gridBase}px);
  background-color: ${props => getColorForType(props.type, props.theme)};
  margin-top: ${props => props.theme.gridBase * 0.5}px;
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  margin-left: ${props => props.theme.gridBase * 0.5}px;
`;

const MainToastContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gridBase * 1.5}px;
`;

const TypeIconAndTextContent = styled.div`
  display: flex;
`;

const TypeIconWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.gridBase * 0.25}px;
  margin-right: ${props => props.theme.gridBase}px;
`;

const TextContentWrapper = styled.div`
  ${normalTextStyles};
  line-height: ${props => props.theme.gridBase * 2.5}px;
  color: ${props => props.theme.palette.white};
`;

const CloseButton = styled.button`
  display: flex;
  color: ${props => props.theme.palette.grey4};
  margin-top: ${props => props.theme.gridBase * 0.25}px;
  margin-left: ${props => props.theme.gridBase * 1.5}px;
  margin-right: ${props => props.theme.gridBase * 0.25}px;
`;
