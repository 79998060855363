type IconIubendaProps = {
  size: "24px";
  className?: string;
};

export const IconIubenda: React.FC<IconIubendaProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect width="24" height="24" rx="12" fill="#1CC691" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5541 7.53406C15.5552 8.37112 15.2558 9.18077 14.7104 9.81576L15.4673 20H8.69539L9.42945 9.9937C8.78236 9.33883 8.41963 8.45519 8.42 7.53455C8.42 5.58158 10.0166 4 11.987 4C13.9579 4 15.5541 5.58158 15.5541 7.53406ZM11.987 8.33891C12.4607 8.33891 12.8447 7.95447 12.8447 7.48024C12.8447 7.00602 12.4607 6.62158 11.987 6.62158C11.5134 6.62158 11.1293 7.00602 11.1293 7.48024C11.1293 7.95447 11.5134 8.33891 11.987 8.33891ZM12.6644 17.1976V11.4124L11.4901 12.5867V17.1976H12.6644Z"
            fill="white"
          />
        </svg>
      );
  }
};
