import { css } from "styled-components";

/* ========================================================================== */

export const scrollbarMixin = css`
  scrollbar-width: thin;
  scrollbar-color: ${props => props.theme.palette.grey6} transparent;
`;

export const scrollbarHoriztonalMixin = css`
  scrollbar-width: auto;
  scrollbar-color: ${props => props.theme.palette.grey6} transparent;
`;
