import {
  Link,
  matchPath,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";
import styled from "styled-components";

import { type CompanyDetails } from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { IconCross } from "elevar-design-system/src/icons";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tabs } from "elevar-design-system/src/Tabs";
import {
  heading2Styles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { useCompanyDetailsQuery } from "../../../api/handlers/company";
import {
  RedirectWithoutLastLocation,
  useLastLocation
} from "../../../context/LastLocation";
import { TitleProvider } from "../../../context/Title";
import { useCompanyId } from "../../../utils/idHooks";
import { Billing } from "./Billing";
import { CompanyInfo } from "./CompanyInfo";
import { TeamMembers } from "./TeamMembers";

/* ========================================================================== */

export const CompanySettings: React.FC = () => {
  const companyDetails = useCompanyDetailsQuery();

  if (companyDetails.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (companyDetails.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return <CompanySettingsInner companyDetails={companyDetails.data} />;
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const companyInfoPath = "/company/:companyId/settings/info";
const teamMembersPath = "/company/:companyId/settings/team";
const billingPath = "/company/:companyId/settings/billing";

type CompanySettingsInnerProps = {
  companyDetails: CompanyDetails;
};

const CompanySettingsInner: React.FC<CompanySettingsInnerProps> = ({
  companyDetails
}) => {
  if (companyDetails.role !== "Owner" && companyDetails.role !== "Admin") {
    return <RedirectWithoutLastLocation to="/" />;
  }

  return (
    <Switch>
      <Route
        exact={true}
        path={companyInfoPath}
        render={() => (
          <TitleProvider page="Company Info">
            <CompanySettingsPage>
              <CompanyInfo />
            </CompanySettingsPage>
          </TitleProvider>
        )}
      />
      <Route
        exact={true}
        path={teamMembersPath}
        render={() => (
          <TitleProvider page="Team Members">
            <CompanySettingsPage>
              <TeamMembers />
            </CompanySettingsPage>
          </TitleProvider>
        )}
      />
      <Route
        exact={true}
        path={billingPath}
        render={() => (
          <TitleProvider page="Billing">
            <CompanySettingsPage>
              <Billing />
            </CompanySettingsPage>
          </TitleProvider>
        )}
      />
      <RedirectWithoutLastLocation to="/" />
    </Switch>
  );
};

/* ========================================================================== */

type CompanySettingsPageProps = {
  children: React.ReactNode;
};

const CompanySettingsPage: React.FC<CompanySettingsPageProps> = ({
  children
}) => {
  const match = useRouteMatch();
  const lastLocation = useLastLocation();
  const companyId = useCompanyId();
  const companyDetails = useCompanyDetailsQuery();

  const isTabActive = (path: string) => matchPath(match.url, path) !== null;

  return (
    <CompanySettingsPageWrapper>
      <div>
        <CompanySettingsPageHeader>
          <div>
            <CompanyName>{companyDetails.data?.name ?? "..."}</CompanyName>
            <CompanySettingsPageHeading>
              Company Settings
            </CompanySettingsPageHeading>
          </div>
          <div>
            <CompanySettingsPageCloseLink to={lastLocation ?? "/"}>
              <IconCross size="16px" />
            </CompanySettingsPageCloseLink>
          </div>
        </CompanySettingsPageHeader>
        <TabsWrapper>
          <Tabs
            items={[
              {
                title: "Company Info",
                isActive: isTabActive(companyInfoPath),
                type: "INTERNAL_LINK",
                to: `/company/${companyId}/settings/info`
              },
              {
                title: "Team Members",
                isActive: isTabActive(teamMembersPath),
                type: "INTERNAL_LINK",
                to: `/company/${companyId}/settings/team`
              },
              {
                title: "Billing",
                isActive: isTabActive(billingPath),
                type: "INTERNAL_LINK",
                to: `/company/${companyId}/settings/billing`
              }
            ]}
          />
        </TabsWrapper>
        {children}
      </div>
    </CompanySettingsPageWrapper>
  );
};

const CompanySettingsPageWrapper = styled.div`
  background-color: ${props => props.theme.palette.grey7};

  > div {
    min-height: 100vh;
    max-width: ${props => props.theme.gridBase * 62.5}px;
    display: flex;
    flex-direction: column;
    padding: ${props => props.theme.gridBase * 5}px 0;
    margin: 0 auto;
  }
`;

const CompanySettingsPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CompanyName = styled.div`
  ${subheadingStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const CompanySettingsPageHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;

const CompanySettingsPageCloseLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.gridBase}px;
  border-radius: 2px;
  background-color: ${props => props.theme.palette.white};
  color: ${props => props.theme.palette.grey3};
`;

const TabsWrapper = styled.div`
  margin-left: ${props => props.theme.gridBase * -2}px;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;
