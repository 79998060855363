import { startCase } from "lodash-es";
import styled from "styled-components";

import { ButtonPrimaryAsLink } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconCircledInfo } from "elevar-design-system/src/icons";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { useLastLocation } from "../context/LastLocation";
import { useUserRequired } from "../context/User";
import { UserButtonDropdown } from "./UserButtonDropdown";

/* ========================================================================== */

type PageNotFoundProps = {
  type: "COMPANY" | "WEBSITE";
};

export const PageNotFound: React.FC<PageNotFoundProps> = ({ type }) => {
  const lastLocation = useLastLocation();
  const { accountDetails } = useUserRequired();

  const value = startCase(type.toLowerCase());

  return (
    <>
      <PageNotFoundWrapper>
        <IconWrapper>
          <IconCircledInfo size="24px" />
        </IconWrapper>
        <Heading>Oops! We couldn't find this {value}</Heading>
        <Text>
          It looks like this {value} doesn't exist, or you don't have access to
          it.
        </Text>
        <ButtonPrimaryAsLink variant="SMALL" to={lastLocation ?? "/"}>
          Go Back
        </ButtonPrimaryAsLink>
      </PageNotFoundWrapper>
      <UserButtonDropdownWrapper>
        <UserButtonDropdown
          userImage={accountDetails.profile.picture}
          userName={`${accountDetails.first_name} ${accountDetails.last_name}`}
          showAccountSettingsLink={false}
        />
      </UserButtonDropdownWrapper>
    </>
  );
};

/* ========================================================================== */

const PageNotFoundWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const Heading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const Text = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const UserButtonDropdownWrapper = styled.div`
  position: fixed;
  bottom: ${props => props.theme.gridBase * 2.45}px;
  left: ${props => props.theme.gridBase * 3}px;
`;
