type IconShoppingCartProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconShoppingCart: React.FC<IconShoppingCartProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9.5 22C9.77614 22 10 21.7761 10 21.5C10 21.2239 9.77614 21 9.5 21C9.22386 21 9 21.2239 9 21.5C9 21.7761 9.22386 22 9.5 22Z"
            stroke={color}
          />
          <path
            d="M19.5 22C19.7761 22 20 21.7761 20 21.5C20 21.2239 19.7761 21 19.5 21C19.2239 21 19 21.2239 19 21.5C19 21.7761 19.2239 22 19.5 22Z"
            stroke={color}
          />
          <path
            d="M1 2H5L7.68 15.39C7.77144 15.8504 8.02191 16.264 8.38755 16.5583C8.75318 16.8526 9.2107 17.009 9.68 17H19.4C19.8693 17.009 20.3268 16.8526 20.6925 16.5583C21.0581 16.264 21.3086 15.8504 21.4 15.39L23 7H6"
            stroke={color}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6 13C6.55228 13 7 13.4477 7 14C7 14.5523 6.55228 15 6 15C5.44772 15 5 14.5523 5 14C5 13.4477 5.44772 13 6 13Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 15C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13C12.4477 13 12 13.4477 12 14C12 14.5523 12.4477 15 13 15Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.666626 0.666687H3.33329L5.11996 9.59335C5.18092 9.90028 5.3479 10.176 5.59166 10.3722C5.83541 10.5684 6.14042 10.6727 6.45329 10.6667H12.9333C13.2462 10.6727 13.5512 10.5684 13.7949 10.3722C14.0387 10.176 14.2057 9.90028 14.2666 9.59335L15.3333 4.00002H3.99996"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
