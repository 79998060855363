import { transparentize } from "polished";
import { useState } from "react";
import styled from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconCircledCheck
} from "elevar-design-system/src/icons";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogo } from "elevar-design-system/src/logos";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../../../components/PageCard";

/* ========================================================================== */

type ConnectedData = {
  type: "CONNECTED_DATA";
  items: Array<{ key: string; value: string }>;
} & (
  | { isEditAllowed: true; onEditClick: () => void }
  | { isEditAllowed: false }
);

type TooltipFnArgs = { disabled: boolean; children: () => React.ReactElement };
type TooltipFn = (args: TooltipFnArgs) => React.ReactNode;

type NotConnectedData = {
  type: "NOT_CONNECTED_DATA";
  connectExplainer: string;
  isConnectAllowed: boolean;
  onConnectClick: () => void;
  connectDisabledTooltip: TooltipFn;
};

type MismatchedData = {
  type: "MISMATCHED_DATA";
  mismatchExplainer: string;
  isConnectAllowed: boolean;
  onConnectClick: () => void;
  connectDisabledTooltip: TooltipFn;
};

/* ========================================================================== */

type PageCardDataSourceProps = {
  icon: React.ReactNode;
  title: string;
  usageExplainerItems?: Array<string>;
  data: ConnectedData | NotConnectedData | MismatchedData;
};

export const PageCardDataSource: React.FC<PageCardDataSourceProps> = ({
  icon,
  title,
  usageExplainerItems,
  data
}) => {
  const [showExplainer, setShowExplainer] = useState(false);

  return (
    <CustomPageCard>
      {data.type === "CONNECTED_DATA" && (
        <MainContentWrapper>
          <ConnectedDataWrapper>
            <div>
              <div>
                {icon}
                <CardHeading>{title}</CardHeading>
              </div>
              {data.isEditAllowed ? (
                <EditButton onClick={data.onEditClick}>Edit</EditButton>
              ) : null}
            </div>
            <div>
              {data.items.map(item => (
                <div key={item.key}>
                  <div>{item.key}:</div>
                  <div>{item.value}</div>
                </div>
              ))}
            </div>
          </ConnectedDataWrapper>
        </MainContentWrapper>
      )}
      {data.type === "NOT_CONNECTED_DATA" || data.type === "MISMATCHED_DATA" ? (
        <MainContentWrapper>
          <ConnectIconsWrapper>
            <div>{icon}</div>
            <div>
              <IconChevronRight size="16px" />
            </div>
            <div>
              <ElevarLogo />
            </div>
          </ConnectIconsWrapper>
          <CardHeadingWithMargin>{title}</CardHeadingWithMargin>
          {data.type === "NOT_CONNECTED_DATA" && (
            <CardText>{data.connectExplainer}</CardText>
          )}
          {data.type === "MISMATCHED_DATA" && (
            <CardErrorNotice>{data.mismatchExplainer}</CardErrorNotice>
          )}
          {data.connectDisabledTooltip({
            disabled: data.isConnectAllowed,
            children: () => (
              <TooltipContents>
                <ButtonPrimary
                  variant="SMALL"
                  state={data.isConnectAllowed ? "IDLE" : "DISABLED"}
                  onClick={() => data.onConnectClick()}
                >
                  Connect
                </ButtonPrimary>
              </TooltipContents>
            )
          })}
        </MainContentWrapper>
      ) : null}
      {usageExplainerItems ? (
        <UsageExplainerWrapper>
          <ToggleExplainerButton
            onClick={() => setShowExplainer(!showExplainer)}
          >
            <div>
              <div>What do we do with this?</div>
              <div>
                {showExplainer ? (
                  <IconChevronUp size="16px" />
                ) : (
                  <IconChevronDown size="16px" />
                )}
              </div>
            </div>
          </ToggleExplainerButton>
          {showExplainer ? (
            <ExplainerWrapper>
              {usageExplainerItems.map((explainerItem, index) => (
                <ExplainerItem key={index}>
                  <div>
                    <IconCircledCheck size="16px" />
                  </div>
                  <div>{explainerItem}</div>
                </ExplainerItem>
              ))}
            </ExplainerWrapper>
          ) : null}
        </UsageExplainerWrapper>
      ) : null}
    </CustomPageCard>
  );
};

/* ========================================================================== */

const CustomPageCard = styled(PageCard)`
  padding: 0;
`;

const MainContentWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 3}px;
`;

const ConnectedDataWrapper = styled.div`
  > :first-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;

    > :first-child {
      display: flex;
      align-items: center;

      > :first-child {
        margin-right: ${props => props.theme.gridBase * 3}px;
      }
    }
  }

  > :last-child {
    margin-left: ${props => props.theme.gridBase * 6}px;

    > div {
      ${normalTextStyles};
      display: flex;
      gap: ${props => props.theme.gridBase * 0.75}px;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 0.75}px;
      }

      > div:first-child {
        color: ${props => props.theme.palette.grey3};
        white-space: nowrap;
      }

      > div:last-child {
        color: ${props => props.theme.palette.grey2};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const CardHeading = styled.div`
  ${heading3Styles};
`;

const EditButton = styled.button`
  ${normalTextStyles};
  ${linkStyles};
`;

const ConnectIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div {
    display: flex;
  }

  > div:nth-child(2) {
    margin: 0 ${props => props.theme.gridBase}px;
    color: ${props => props.theme.palette.grey5};
  }

  > div:nth-child(3) {
    > svg {
      height: ${props => props.theme.gridBase * 3}px;
      width: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const CardHeadingWithMargin = styled(CardHeading)`
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const CardText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const TooltipContents = styled.div`
  display: inline-flex;
`;

const CardErrorNotice = styled.div`
  ${normalBodyStyles};
  margin-top: ${props => props.theme.gridBase * 2}px;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;
  color: ${props => props.theme.palette.red1};
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const UsageExplainerWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.palette.grey7};
`;

const ToggleExplainerButton = styled.button`
  width: 100%;
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:first-child {
      ${normalBodyStyles};
      font-weight: 500;
      color: ${props => props.theme.palette.grey2};
    }

    > div:last-child {
      display: flex;
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const ExplainerWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 0.25}px;
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
`;

const ExplainerItem = styled.div`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.purple2};
    margin-right: ${props => props.theme.gridBase * 1.5}px;
    margin-top: ${props => props.theme.gridBase * 0.25}px;
  }

  > div:last-child {
    ${smallTextStyles};
    line-height: ${props => props.theme.gridBase * 2.5}px;
    color: ${props => props.theme.palette.grey2};
  }
`;
