type IconAwinProps = {
  size: "24px";
  className?: string;
};

export const IconAwin: React.FC<IconAwinProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.7296 6.99418L12.0036 0.0627158C11.8831 -0.0578314 11.6721 0.00244219 11.6721 0.183263L12.1543 6.90377L7.99545 3.34763C7.8749 3.22708 7.66394 3.34763 7.69408 3.52845L9.3516 10.1585L5.34341 7.80788C5.19272 7.71747 5.0119 7.86815 5.07218 8.04897L12.0941 23.9009C12.1543 24.0516 12.3954 24.0215 12.4256 23.8708L13.9625 15.7339L17.8803 9.10376C18.2721 8.44075 18.2118 7.59692 17.7296 6.99418Z"
            fill="#FF6600"
          />
          <path
            d="M12.1242 6.90377L7.93516 3.34763C7.81462 3.22708 7.60366 3.34763 7.63379 3.52845L9.29132 10.1585L12.4557 12.027L12.1242 6.90377Z"
            fill="#E74030"
          />
          <path
            d="M12.1845 20.3749C12.1242 20.5557 11.8831 20.5256 11.853 20.3448L9.32146 10.1585L5.28313 7.77774C5.13244 7.68733 4.95162 7.83802 5.0119 8.01884L12.0338 23.8708C12.094 24.0215 12.3351 23.9913 12.3653 23.8407L13.9023 15.7037L13.8721 15.7339L12.1845 20.3749Z"
            fill="#FAB418"
          />
          <path
            d="M12.1845 20.3749L13.9324 15.7339L13.1488 17.0297C13.0584 17.1804 12.8173 17.1201 12.8173 16.9393L12.4858 12.027L9.32146 10.1585L11.8529 20.3448C11.8831 20.5256 12.1242 20.5557 12.1845 20.3749Z"
            fill="#DE0732"
          />
          <path
            d="M17.6694 6.99419L11.9434 0.0627199C11.853 -0.0578274 11.642 0.0325831 11.642 0.183267L12.1242 6.90378L15.0173 9.40513C15.5899 9.88732 15.8009 10.701 15.5296 11.4243L13.9324 15.6435V15.7037L17.8502 9.07363C18.242 8.44075 18.1515 7.59692 17.6694 6.99419Z"
            fill="#FF6600"
          />
          <path
            d="M13.2995 12.5394C13.9625 12.9311 14.294 13.6846 14.1735 14.438L13.9324 15.6736L15.5296 11.4544C15.8009 10.7311 15.5899 9.94759 15.0173 9.43526L12.1242 6.93391L12.4858 12.0572L13.2995 12.5394Z"
            fill="#DE0733"
          />
          <path
            d="M13.2995 12.5393L12.4557 12.027L12.7872 16.9393C12.7872 17.1201 13.0283 17.1804 13.1187 17.0297L13.8721 15.7339L13.9023 15.6434L14.1434 14.4078C14.3242 13.6845 13.9625 12.9311 13.2995 12.5393Z"
            fill="#CC1D46"
          />
        </svg>
      );
  }
};
