type IconSuccessCloudProps = {
  size: "24px";
  color?: string;
  className?: string;
};

export const IconSuccessCloud: React.FC<IconSuccessCloudProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M9 17L11 19L15 15"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.8799 18.09C21.7493 17.4786 22.4013 16.6061 22.7412 15.5991C23.0811 14.5921 23.0913 13.503 22.7703 12.4898C22.4493 11.4766 21.8138 10.5921 20.956 9.96456C20.0982 9.33703 19.0628 8.99916 17.9999 8.99999H16.7399C16.4392 7.82787 15.8764 6.73924 15.0941 5.81607C14.3117 4.89291 13.3301 4.15925 12.2231 3.67035C11.1162 3.18144 9.91278 2.95002 8.70346 2.99351C7.49414 3.037 6.31045 3.35426 5.24149 3.92142C4.17253 4.48858 3.24616 5.29084 2.53212 6.26782C1.81808 7.2448 1.33498 8.37104 1.11919 9.56174C0.903403 10.7524 0.960543 11.9766 1.28631 13.142C1.61208 14.3074 2.19799 15.3838 2.99993 16.29"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="12" cy="17" r="5.5" stroke={color} />
        </svg>
      );
  }
};
