type IconCaretDownProps = {
  size: "8px";
  color?: string;
  className?: string;
};

export const IconCaretDown: React.FC<IconCaretDownProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "8px":
      return (
        <svg
          className={className}
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path
            d="M4.41734 6.36751C4.21972 6.66701 3.78028 6.66701 3.58266 6.36751L1.04751 2.52537C0.828165 2.19294 1.06658 1.75 1.46485 1.75L6.53515 1.75C6.93343 1.75 7.17184 2.19294 6.95249 2.52537L4.41734 6.36751Z"
            fill={color}
          />
        </svg>
      );
  }
};
