import { transparentize } from "polished";
import { useEffect, useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { z } from "zod";

import {
  type GtmAccountData,
  type WebsiteDetails,
  type Workspace
} from "elevar-common-ts/src/apiTypes";

import { iconButtonStyles } from "elevar-design-system/src/buttons/buttonStyles";
import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconGTM, IconRefresh } from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  useWebsiteWorkspaceAccountDataRefreshMutation,
  useWebsiteWorkspaceWebSetMutation
} from "../../../../api/handlers/website";
import { Drawer } from "../../../../components/Drawer";
import { useWebsiteId } from "../../../../utils/idHooks";
import { toast } from "../../../../utils/toast";
import { track } from "../../../../utils/track";
import { PageCardDataSource } from "./PageCardDataSource";

/* ========================================================================== */

type PageCardGtmWebProps = {
  websiteDetails: WebsiteDetails;
  showMarketsNotice?: boolean;
};

export const PageCardGtmWeb: React.FC<PageCardGtmWebProps> = ({
  websiteDetails,
  showMarketsNotice = false
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);

  const workspace = websiteDetails.workspace;
  const googleAccount = websiteDetails.google_account;

  const isWorkspaceConnected =
    workspace.account !== null &&
    workspace.container !== null &&
    workspace.workspace !== null;

  const isConnectOrEditAllowed =
    googleAccount !== null && !googleAccount.last_refresh_failed;

  return (
    <>
      {isWorkspaceConnected ? (
        <WorkspaceConnected
          setIsDrawerVisible={setIsDrawerVisible}
          isEditAllowed={isConnectOrEditAllowed}
          workspace={websiteDetails.workspace}
        />
      ) : (
        <WorkspaceNotConnected
          setIsDrawerVisible={setIsDrawerVisible}
          isConnectAllowed={isConnectOrEditAllowed}
        />
      )}
      {isConnectOrEditAllowed ? (
        <Drawer
          isVisible={isDrawerVisible}
          onClose={() => setIsDrawerVisible(false)}
          disallowClose={isDrawerLoading}
        >
          <DrawerInner
            websiteDetails={websiteDetails}
            showMarketsNotice={showMarketsNotice}
            isDrawerLoading={isDrawerLoading}
            setIsDrawerLoading={setIsDrawerLoading}
            isDrawerVisible={isDrawerVisible}
            setIsDrawerVisible={setIsDrawerVisible}
          />
        </Drawer>
      ) : null}
    </>
  );
};

/* ========================================================================== */

const gtmWebExplainerItems = [
  "We use GTM to fetch your container and workspaces.",
  "We use this to import your tags and triggers per Website.",
  "We do not share your data (in accordance with our privacy policy)."
];

type GtmCollectionItem =
  | { state: "FORM_FIELD_NOT_SELECTED" }
  | { state: "DATA_NOT_FETCHED" }
  | { state: "DATA_MISMATCHED" }
  | { state: "EXISTS"; data: Array<Option> };

type GtmKeys = "account" | "container" | "workspace";
type GtmFormFields = Record<GtmKeys, string | null>;
type GtmCollectionData = Record<GtmKeys, GtmCollectionItem>;

const getGtmCollectionData = (
  gtmAccountData: GtmAccountData,
  formFields: GtmFormFields
): GtmCollectionData => {
  if (gtmAccountData.accounts === null) {
    return {
      account: { state: "DATA_NOT_FETCHED" },
      container: { state: "FORM_FIELD_NOT_SELECTED" },
      workspace: { state: "FORM_FIELD_NOT_SELECTED" }
    };
  }

  const accountCollectionExists: GtmCollectionItem = {
    state: "EXISTS",
    data: Object.values(gtmAccountData.accounts)
      .map(a => a.data)
      .sort((a1, a2) => a1.name.localeCompare(a2.name))
  };

  if (formFields.account === null) {
    return {
      account: accountCollectionExists,
      container: { state: "FORM_FIELD_NOT_SELECTED" },
      workspace: { state: "FORM_FIELD_NOT_SELECTED" }
    };
  }

  const rawAccount = gtmAccountData.accounts[formFields.account];

  if (!rawAccount) {
    return {
      account: accountCollectionExists,
      container: { state: "DATA_MISMATCHED" },
      workspace: { state: "FORM_FIELD_NOT_SELECTED" }
    };
  }

  const rawContainers = rawAccount.containers;

  if (!rawContainers) {
    return {
      account: accountCollectionExists,
      container: { state: "DATA_NOT_FETCHED" },
      workspace: { state: "FORM_FIELD_NOT_SELECTED" }
    };
  }

  const containerCollectionExists: GtmCollectionItem = {
    state: "EXISTS",
    data: Object.values(rawContainers)
      .map(c => c.data)
      .filter(c => c.type !== "server")
      .sort((c1, c2) => c1.name.localeCompare(c2.name))
  };

  if (formFields.container === null) {
    return {
      account: accountCollectionExists,
      container: containerCollectionExists,
      workspace: { state: "FORM_FIELD_NOT_SELECTED" }
    };
  }

  const rawContainer = rawContainers[formFields.container];

  if (!rawContainer) {
    return {
      account: accountCollectionExists,
      container: containerCollectionExists,
      workspace: { state: "DATA_MISMATCHED" }
    };
  }

  const rawWorkspaces = rawContainer.workspaces;

  if (!rawWorkspaces) {
    return {
      account: accountCollectionExists,
      container: containerCollectionExists,
      workspace: { state: "DATA_NOT_FETCHED" }
    };
  }

  const workspaceCollectionExists: GtmCollectionItem = {
    state: "EXISTS",
    data: Object.values(rawWorkspaces)
      .map(w => w.data)
      .sort((w1, w2) => w1.name.localeCompare(w2.name))
  };

  return {
    account: accountCollectionExists,
    container: containerCollectionExists,
    workspace: workspaceCollectionExists
  };
};

/* -------------------------------------------------------------------------- */

type GtmFormState =
  | "OK"
  | "REFRESHING_ACCOUNTS"
  | "REFRESHING_CONTAINERS"
  | "REFRESHING_WORKSPACES";

type DrawerInnerProps = {
  websiteDetails: WebsiteDetails;
  showMarketsNotice: boolean;
  isDrawerLoading: boolean;
  setIsDrawerLoading: (isDrawerLoading: boolean) => void;
  isDrawerVisible: boolean;
  setIsDrawerVisible: (isDrawerVisible: boolean) => void;
};

const DrawerInner: React.FC<DrawerInnerProps> = ({
  websiteDetails,
  showMarketsNotice,
  isDrawerLoading,
  setIsDrawerLoading,
  isDrawerVisible,
  setIsDrawerVisible
}) => {
  const theme = useTheme();
  const websiteId = useWebsiteId();

  const { mutateAsync: websiteWorkspaceAccountDataRefreshMutation } =
    useWebsiteWorkspaceAccountDataRefreshMutation();
  const { mutateAsync: websiteWorkspaceWebSetMutation } =
    useWebsiteWorkspaceWebSetMutation();

  /* ------------------------------------------------------------------------ */

  const initialGtmFormFields = useMemo(() => {
    return {
      account: websiteDetails.workspace.account,
      container: websiteDetails.workspace.container,
      workspace: websiteDetails.workspace.workspace
    };
  }, [
    websiteDetails.workspace.account,
    websiteDetails.workspace.container,
    websiteDetails.workspace.workspace
  ]);

  const [gtmFormState, setGtmFormState] = useState<GtmFormState>("OK");
  const [gtmFormFields, setGtmFormFields] =
    useState<GtmFormFields>(initialGtmFormFields);

  useEffect(() => {
    setGtmFormFields(initialGtmFormFields);
  }, [initialGtmFormFields]);

  /* ------------------------------------------------------------------------ */

  const gtmAccountData = websiteDetails.workspace.account_data;
  const collectionData = getGtmCollectionData(gtmAccountData, gtmFormFields);

  const isDataMismatched =
    collectionData.account.state === "DATA_MISMATCHED" ||
    collectionData.container.state === "DATA_MISMATCHED" ||
    collectionData.workspace.state === "DATA_MISMATCHED";

  /* ------------------------------------------------------------------------ */

  useEffect(() => {
    const refreshGtmAccountsEffect = async () => {
      if (
        isDrawerVisible &&
        collectionData.account.state === "DATA_NOT_FETCHED"
      ) {
        setIsDrawerLoading(true);
        setGtmFormState("REFRESHING_ACCOUNTS");
        await websiteWorkspaceAccountDataRefreshMutation({
          item: "ACCOUNTS"
        });
        setGtmFormState("OK");
        setIsDrawerLoading(false);
      }
    };

    void refreshGtmAccountsEffect();
  }, [
    isDrawerVisible,
    collectionData.account.state,
    setIsDrawerLoading,
    websiteWorkspaceAccountDataRefreshMutation
  ]);

  useEffect(() => {
    const refreshGtmContainersEffect = async () => {
      if (
        isDrawerVisible &&
        collectionData.container.state === "DATA_NOT_FETCHED" &&
        gtmFormFields.account !== null
      ) {
        setIsDrawerLoading(true);
        setGtmFormState("REFRESHING_CONTAINERS");
        await websiteWorkspaceAccountDataRefreshMutation({
          item: "CONTAINERS",
          account: gtmFormFields.account
        });
        setGtmFormState("OK");
        setIsDrawerLoading(false);
      }
    };

    void refreshGtmContainersEffect();
  }, [
    isDrawerVisible,
    collectionData.container.state,
    setIsDrawerLoading,
    websiteWorkspaceAccountDataRefreshMutation,
    gtmFormFields.account
  ]);

  useEffect(() => {
    const refreshGtmWorkspacesEffect = async () => {
      if (
        isDrawerVisible &&
        collectionData.workspace.state === "DATA_NOT_FETCHED" &&
        gtmFormFields.account !== null &&
        gtmFormFields.container !== null
      ) {
        setIsDrawerLoading(true);
        setGtmFormState("REFRESHING_WORKSPACES");
        await websiteWorkspaceAccountDataRefreshMutation({
          item: "WORKSPACES",
          account: gtmFormFields.account,
          container: gtmFormFields.container
        });
        setGtmFormState("OK");
        setIsDrawerLoading(false);
      }
    };

    void refreshGtmWorkspacesEffect();
  }, [
    isDrawerVisible,
    collectionData.workspace.state,
    setIsDrawerLoading,
    websiteWorkspaceAccountDataRefreshMutation,
    gtmFormFields.account,
    gtmFormFields.container
  ]);

  /* ------------------------------------------------------------------------ */

  const accounts =
    collectionData.account.state === "EXISTS"
      ? collectionData.account.data
      : [];
  const containers =
    collectionData.container.state === "EXISTS"
      ? collectionData.container.data
      : [];
  const workspaces =
    collectionData.workspace.state === "EXISTS"
      ? collectionData.workspace.data
      : [];

  const activeAccountOption =
    accounts.find(o => o.value === gtmFormFields.account) ?? null;

  const activeContainerOption =
    containers.find(o => o.value === gtmFormFields.container) ?? null;

  const activeWorkspaceOption =
    workspaces.find(o => o.value === gtmFormFields.workspace) ?? null;

  /* ------------------------------------------------------------------------ */

  const allFieldsDisabled =
    gtmFormState === "REFRESHING_ACCOUNTS" ||
    gtmFormState === "REFRESHING_CONTAINERS" ||
    gtmFormState === "REFRESHING_WORKSPACES";

  const accountFieldDisabled = allFieldsDisabled;

  const containerFieldDisabled =
    accountFieldDisabled || activeAccountOption === null;

  const workspaceFieldDisabled =
    containerFieldDisabled || activeContainerOption === null;

  /* ------------------------------------------------------------------------ */

  return (
    <>
      <DrawerWebsiteName>{websiteDetails.name}</DrawerWebsiteName>
      <DrawerTitle>Google Tag Manager (Web)</DrawerTitle>
      {isDataMismatched ? (
        <DrawerErrorNotice>
          The previously selected workspace no longer exists in our stored data.
          Please re-select and save below to ensure our services continue to
          work as expected.
        </DrawerErrorNotice>
      ) : null}
      <DrawerSubTitle>Select your GTM Workspace</DrawerSubTitle>
      <DrawerHelpLink
        href="https://docs.getelevar.com/docs/how-to-use-elevars-gtm-event-builder#select-your-gtm-workspace"
        text="Where & how do I find this?"
      />
      <DrawerInputWrapper
        labelText="Account"
        disabled={accountFieldDisabled || isDrawerLoading}
        asDiv={true}
      >
        <InputFieldRefreshButtonWrapper>
          <Tooltip
            text={
              isDrawerLoading ? "Refreshing Accounts..." : "Refresh Accounts"
            }
            placement="left"
            disabled={
              accountFieldDisabled && gtmFormState !== "REFRESHING_ACCOUNTS"
            }
            hideOnClick={false}
          >
            <GtmRefreshButtonWrapper>
              <GtmRefreshButton
                aria-label="Refresh Accounts"
                disabled={accountFieldDisabled || isDrawerLoading}
                onClick={async () => {
                  setIsDrawerLoading(true);
                  setGtmFormState("REFRESHING_ACCOUNTS");
                  setGtmFormFields({
                    account: null,
                    container: null,
                    workspace: null
                  });
                  await websiteWorkspaceAccountDataRefreshMutation({
                    item: "ACCOUNTS"
                  });
                  setGtmFormState("OK");
                  setIsDrawerLoading(false);
                }}
              >
                {gtmFormState === "REFRESHING_ACCOUNTS" ? (
                  <Spinner size="16px" color={theme.palette.purple2} />
                ) : (
                  <IconRefresh
                    size="16px"
                    color={
                      accountFieldDisabled
                        ? transparentize(0.5, theme.palette.grey3)
                        : theme.palette.grey3
                    }
                  />
                )}
              </GtmRefreshButton>
            </GtmRefreshButtonWrapper>
          </Tooltip>
          <InputFieldSelect
            variant="SMALL"
            disabled={accountFieldDisabled || isDrawerLoading}
            value={activeAccountOption}
            setValue={option => {
              if (option !== activeAccountOption) {
                setGtmFormFields({
                  account: option.value,
                  container: null,
                  workspace: null
                });
              }
            }}
            options={accounts}
            placeholder="Select an Account"
            contentsWhenEmpty={() => (
              <DrawerInputEmptyContentWrapper>
                No GTM accounts were found for the authenticated Google account.
                If you wish to setup this connection, please ensure that this
                Google account has access to the GTM account you want to select.
              </DrawerInputEmptyContentWrapper>
            )}
          />
        </InputFieldRefreshButtonWrapper>
      </DrawerInputWrapper>
      <DrawerInputWrapper
        labelText="Container"
        disabled={containerFieldDisabled || isDrawerLoading}
        asDiv={true}
      >
        <InputFieldRefreshButtonWrapper>
          <Tooltip
            text={
              isDrawerLoading
                ? "Refreshing Containers..."
                : "Refresh Containers"
            }
            placement="left"
            disabled={
              containerFieldDisabled && gtmFormState !== "REFRESHING_CONTAINERS"
            }
            hideOnClick={false}
          >
            <GtmRefreshButtonWrapper>
              <GtmRefreshButton
                aria-label="Refresh Containers"
                disabled={containerFieldDisabled || isDrawerLoading}
                onClick={async () => {
                  if (gtmFormFields.account !== null) {
                    setIsDrawerLoading(true);
                    setGtmFormState("REFRESHING_CONTAINERS");
                    setGtmFormFields({
                      account: gtmFormFields.account,
                      container: null,
                      workspace: null
                    });
                    await websiteWorkspaceAccountDataRefreshMutation({
                      item: "CONTAINERS",
                      account: gtmFormFields.account
                    });
                    setGtmFormState("OK");
                    setIsDrawerLoading(false);
                  }
                }}
              >
                {gtmFormState === "REFRESHING_CONTAINERS" ? (
                  <Spinner size="16px" color={theme.palette.purple2} />
                ) : (
                  <IconRefresh
                    size="16px"
                    color={
                      containerFieldDisabled
                        ? transparentize(0.5, theme.palette.grey3)
                        : theme.palette.grey3
                    }
                  />
                )}
              </GtmRefreshButton>
            </GtmRefreshButtonWrapper>
          </Tooltip>
          <InputFieldSelect
            variant="SMALL"
            disabled={containerFieldDisabled || isDrawerLoading}
            value={activeContainerOption}
            setValue={option => {
              if (option !== activeContainerOption) {
                setGtmFormFields({
                  account: gtmFormFields.account,
                  container: option.value,
                  workspace: null
                });
              }
            }}
            options={containers}
            placeholder="Select a Container"
            contentsWhenEmpty={() => (
              <DrawerInputEmptyContentWrapper>
                No containers of type "Web" were found for the selected account.
                If you wish to setup this connection, please ensure that the
                selected account has access to the container you wish to select,
                and that the authenticated Google account has permission to
                access this container.
              </DrawerInputEmptyContentWrapper>
            )}
          />
        </InputFieldRefreshButtonWrapper>
        {showMarketsNotice ? (
          <MarketsNotice>
            <p>
              If you use Shopify Markets, select your primary market's
              container. You'll be able to configure your GTM containers for
              other markets later.
            </p>
            <StyledLinkExternal
              href="https://docs.getelevar.com/docs/shopify-markets-implementation"
              text="Learn more"
            />
          </MarketsNotice>
        ) : null}
      </DrawerInputWrapper>
      <DrawerInputWrapper
        labelText="Workspace"
        disabled={workspaceFieldDisabled || isDrawerLoading}
        asDiv={true}
      >
        <InputFieldRefreshButtonWrapper>
          <Tooltip
            text={
              isDrawerLoading
                ? "Refreshing Workspaces..."
                : "Refresh Workspaces"
            }
            placement="left"
            disabled={
              workspaceFieldDisabled && gtmFormState !== "REFRESHING_WORKSPACES"
            }
            hideOnClick={false}
          >
            <GtmRefreshButtonWrapper>
              <GtmRefreshButton
                aria-label="Refresh Workspaces"
                disabled={workspaceFieldDisabled || isDrawerLoading}
                onClick={async () => {
                  if (
                    gtmFormFields.account !== null &&
                    gtmFormFields.container !== null
                  ) {
                    setIsDrawerLoading(true);
                    setGtmFormState("REFRESHING_WORKSPACES");
                    setGtmFormFields({
                      account: gtmFormFields.account,
                      container: gtmFormFields.container,
                      workspace: null
                    });
                    await websiteWorkspaceAccountDataRefreshMutation({
                      item: "WORKSPACES",
                      account: gtmFormFields.account,
                      container: gtmFormFields.container
                    });
                    setGtmFormState("OK");
                    setIsDrawerLoading(false);
                  }
                }}
              >
                {gtmFormState === "REFRESHING_WORKSPACES" ? (
                  <Spinner size="16px" color={theme.palette.purple2} />
                ) : (
                  <IconRefresh
                    size="16px"
                    color={
                      workspaceFieldDisabled
                        ? transparentize(0.5, theme.palette.grey3)
                        : theme.palette.grey3
                    }
                  />
                )}
              </GtmRefreshButton>
            </GtmRefreshButtonWrapper>
          </Tooltip>
          <InputFieldSelect
            variant="SMALL"
            disabled={workspaceFieldDisabled || isDrawerLoading}
            value={activeWorkspaceOption}
            setValue={option => {
              if (option !== activeWorkspaceOption) {
                setGtmFormFields({
                  account: gtmFormFields.account,
                  container: gtmFormFields.container,
                  workspace: option.value
                });
              }
            }}
            options={workspaces}
            placeholder="Select a Workspace"
            contentsWhenEmpty={() => (
              <DrawerInputEmptyContentWrapper>
                No workspaces were found for the selected container.
              </DrawerInputEmptyContentWrapper>
            )}
          />
        </InputFieldRefreshButtonWrapper>
      </DrawerInputWrapper>
      {gtmFormFields.account &&
      gtmFormFields.container &&
      gtmFormFields.workspace ? (
        <ConfirmWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isDrawerLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsDrawerLoading(true);

              try {
                await websiteWorkspaceWebSetMutation({
                  account: gtmFormFields.account,
                  container: gtmFormFields.container,
                  workspace: gtmFormFields.workspace
                });

                toast.success("GTM details updated");
                setIsDrawerLoading(false);
                setIsDrawerVisible(false);
                track.connectionsGtmWebSuccess({ websiteId });
              } catch (error) {
                setIsDrawerLoading(false);

                const expectedErrorSchema = z.object({
                  cause: z.object({
                    errors: z.object({ error: z.string() })
                  })
                });

                const parsedError = expectedErrorSchema.safeParse(error);

                if (parsedError.success) {
                  const errors = parsedError.data.cause.errors;
                  const conatinerTypeErrorText =
                    "GTM container must be a web container";

                  if (errors.error === conatinerTypeErrorText) {
                    toast.errorExpected("Container must be of type 'Web'");
                  } else {
                    toast.errorUnexpected(error);
                  }
                } else {
                  toast.errorUnexpected(error);
                }
              }
            }}
          >
            Confirm & Save Details
          </ButtonPrimary>
        </ConfirmWrapper>
      ) : null}
    </>
  );
};

const DrawerWebsiteName = styled.div`
  ${subheadingStyles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DrawerTitle = styled.div`
  ${heading2Styles};
`;

const DrawerErrorNotice = styled.div`
  ${normalBodyStyles};
  margin-top: ${props => props.theme.gridBase * 2}px;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;
  color: ${props => props.theme.palette.red1};
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
  margin-top: ${props => props.theme.gridBase * 2}px;
`;

const DrawerSubTitle = styled.div`
  ${heading3Styles};
  margin-top: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DrawerHelpLink = styled(StyledLinkExternal)`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const DrawerInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const InputFieldRefreshButtonWrapper = styled.div`
  position: relative;
`;

const GtmRefreshButtonWrapper = styled.span`
  position: absolute;
  bottom: calc(100% + ${props => props.theme.gridBase}px);
  right: 0;
`;

const GtmRefreshButton = styled.button`
  ${iconButtonStyles};
  background: ${props => props.theme.palette.grey8};

  svg path {
    transition: stroke ${props => props.theme.other.transition};
  }

  &:hover {
    background: ${props => props.theme.palette.grey7};
  }

  &:disabled {
    background: ${props => transparentize(0.5, props.theme.palette.grey8)};
  }
`;

const DrawerInputEmptyContentWrapper = styled.div`
  ${smallTextStyles};
  line-height: ${props => props.theme.gridBase * 2.5}px;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase}px;
`;

const MarketsNotice = styled.div`
  margin-top: ${props => props.theme.gridBase * 1.5}px;

  > p {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const ConfirmWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const getGtmValueNamesFromAccountData = (
  accountData: GtmAccountData,
  account: string | null,
  container: string | null,
  workspace: string | null
) => {
  if (account === null) {
    throw new Error("`account` must not be `null`");
  }
  if (container === null) {
    throw new Error("`container` must not be `null`");
  }
  if (workspace === null) {
    throw new Error("`workspace` must not be `null`");
  }

  const accountObject = accountData.accounts?.[account] ?? null;
  const containerObject = accountObject?.containers?.[container] ?? null;
  const workspaceObject = containerObject?.workspaces?.[workspace] ?? null;

  const containerId = containerObject?.data.client_id ?? null;
  const accountName = accountObject?.data.name ?? null;
  const containerName = containerObject?.data.name ?? null;
  const workspaceName = workspaceObject?.data.name ?? null;

  if (
    accountName === null ||
    containerName === null ||
    workspaceName === null
  ) {
    return null;
  } else {
    return {
      id: containerId,
      account: accountName,
      container: containerName,
      workspace: workspaceName
    };
  }
};

type WorkspaceConnectedProps = {
  setIsDrawerVisible: (isDrawerVisible: boolean) => void;
  isEditAllowed: boolean;
  workspace: Workspace;
};

const WorkspaceConnected: React.FC<WorkspaceConnectedProps> = ({
  setIsDrawerVisible,
  isEditAllowed,
  workspace
}) => {
  const gtmValueNames = getGtmValueNamesFromAccountData(
    workspace.account_data,
    workspace.account,
    workspace.container,
    workspace.workspace
  );

  return (
    <PageCardDataSource
      icon={<IconGTM size="24px" />}
      title="Google Tag Manager (Web)"
      usageExplainerItems={gtmWebExplainerItems}
      data={
        gtmValueNames
          ? {
              type: "CONNECTED_DATA",
              items: [
                ...(gtmValueNames.id !== null
                  ? [{ key: "ID", value: gtmValueNames.id }]
                  : []),
                { key: "Account", value: gtmValueNames.account },
                { key: "Container", value: gtmValueNames.container },
                { key: "Workspace", value: gtmValueNames.workspace }
              ],
              isEditAllowed,
              onEditClick: () => setIsDrawerVisible(true)
            }
          : {
              type: "MISMATCHED_DATA",
              mismatchExplainer:
                "The previously selected workspace no longer exists in our stored data. Please re-connect to ensure our services continue to work as expected.",
              isConnectAllowed: isEditAllowed,
              onConnectClick: () => setIsDrawerVisible(true),
              connectDisabledTooltip: ({ disabled, children }) => (
                <Tooltip
                  placement="right"
                  text="Google authentication required (see above)"
                  disabled={disabled}
                >
                  {children()}
                </Tooltip>
              )
            }
      }
    />
  );
};

/* ========================================================================== */

type WorkspaceNotConnectedProps = {
  setIsDrawerVisible: (isDrawerVisible: boolean) => void;
  isConnectAllowed: boolean;
};

const WorkspaceNotConnected: React.FC<WorkspaceNotConnectedProps> = ({
  setIsDrawerVisible,
  isConnectAllowed
}) => {
  return (
    <PageCardDataSource
      icon={<IconGTM size="24px" />}
      title="Google Tag Manager (Web)"
      usageExplainerItems={gtmWebExplainerItems}
      data={{
        type: "NOT_CONNECTED_DATA",
        connectExplainer:
          "Some of Elevar's features require a connection to GTM (Web).",
        isConnectAllowed,
        onConnectClick: () => setIsDrawerVisible(true),
        connectDisabledTooltip: ({ disabled, children }) => (
          <Tooltip
            placement="right"
            text="Google authentication required (see above)"
            disabled={disabled}
          >
            {children()}
          </Tooltip>
        )
      }}
    />
  );
};
