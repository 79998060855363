import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { IconGTM } from "elevar-design-system/src/icons";

import { PageCardDataSource } from "./PageCardDataSource";

/* ========================================================================== */

type PageCardGtmServerProps = {
  websiteDetails: WebsiteDetails;
};

export const PageCardGtmServer: React.FC<PageCardGtmServerProps> = ({
  websiteDetails
}) => {
  return (
    <PageCardDataSource
      icon={<IconGTM size="24px" />}
      title="Google Tag Manager (Server)"
      data={{
        type: "CONNECTED_DATA",
        items: [{ key: "ID", value: websiteDetails.workspace.ss_client_id! }],
        isEditAllowed: false
      }}
    />
  );
};
