import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { useCubeQuery } from "../../../api/handlers/cube";

/* ========================================================================== */

type LastPurchaseQueryData = {
  "ServerSideEvents.count": number;
  "ServerSideEvents.time": string;
};

export const useLastPurchaseText = () => {
  const [lastPurchaseText, setLastPurchaseText] = useState<string | null>(null);

  const lastPurchaseQueryResult = useCubeQuery<LastPurchaseQueryData>({
    query: {
      measures: ["ServerSideEvents.count"],
      order: { "ServerSideEvents.time": "desc" },
      limit: 1,
      timeDimensions: [
        {
          dimension: "ServerSideEvents.time",
          dateRange: "from 1 days ago to now",
          granularity: "second"
        }
      ],
      filters: [
        {
          member: "ServerSideEvents.event",
          operator: "equals",
          values: ["Purchase"]
        }
      ]
    }
  });

  const lastPurchaseData =
    lastPurchaseQueryResult.data?.resultSet.rawData() ?? null;

  useEffect(() => {
    setLastPurchaseText(
      lastPurchaseData?.[0]
        ? dayjs(lastPurchaseData[0]["ServerSideEvents.time"]).fromNow()
        : null
    );

    const intervalId = setInterval(() => {
      setLastPurchaseText(
        lastPurchaseData?.[0]
          ? dayjs(lastPurchaseData[0]["ServerSideEvents.time"]).fromNow()
          : null
      );
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastPurchaseData]);

  return useMemo(() => lastPurchaseText, [lastPurchaseText]);
};
