import styled from "styled-components";

import { BrandShowcase } from "./BrandShowcase";
import { ContactCallout } from "./ContactCallout";

/* ========================================================================== */

type QuestionFlowShellProps = {
  children: React.ReactNode;
  className?: string;
};

export const QuestionFlowShell: React.FC<QuestionFlowShellProps> = ({
  children,
  className
}) => {
  return (
    <QuestionFlowShellWrapper>
      <div className={className}>{children}</div>
      <div>
        <div>
          <BrandShowcase />
        </div>
        <div>
          <ContactCallout linkType="KNOWLEDGE_BASE" />
        </div>
      </div>
    </QuestionFlowShellWrapper>
  );
};

/* ========================================================================== */

const QuestionFlowShellWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.gridBase * 12}px;

  > div:first-child {
    width: 100%;
    max-width: ${props => props.theme.gridBase * 50}px;
  }

  > div:last-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.gridBase * 4}px;
    padding: 0 ${props => props.theme.gridBase * 5}px;

    > div:last-child {
      width: 100%;
      display: flex;
      justify-content: center;
      border-top: 1px solid ${props => props.theme.palette.grey7};
      padding: ${props => props.theme.gridBase * 2.5}px;
    }
  }
`;
