type IconPebblePostProps = {
  size: "24px";
  className?: string;
};

export const IconPebblePost: React.FC<IconPebblePostProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.20333 12.2851L0.580817 1.54151C0.558902 1.50634 0.516243 1.49006 0.47647 1.50169C0.436696 1.51332 0.409532 1.55001 0.41001 1.59145V22.4308C0.410562 22.4713 0.437414 22.5067 0.476256 22.5182C0.515099 22.5297 0.556879 22.5145 0.579314 22.4808L7.20183 12.3819C7.2207 12.3526 7.22128 12.315 7.20333 12.2851Z"
            fill="#1B2CCC"
          />
          <path
            d="M13.8758 1.14H3.753C3.70564 1.14036 3.66209 1.166 3.63879 1.20724C3.6155 1.24847 3.61601 1.29901 3.64014 1.33976L10.3106 12.2676C10.3379 12.3108 10.3379 12.3657 10.3106 12.4089L3.64862 22.6601C3.62229 22.7007 3.6203 22.7525 3.64341 22.7951C3.66652 22.8377 3.71105 22.8643 3.75949 22.8643H12.7551V17.1212H13.8743C20.4693 17.1212 23.5921 13.6704 23.5921 9.13087C23.5921 4.59134 20.4743 1.14 13.8758 1.14ZM13.8758 11.2493H12.7566V7.01142H13.8758C15.4509 7.01142 16.2035 7.92083 16.2035 9.13137C16.2035 10.3419 15.4489 11.2493 13.8758 11.2493Z"
            fill="black"
          />
        </svg>
      );
  }
};
