export type CleaveValues<T = string> = {
  prettyValue: string;
  rawValue: T;
};

export const emptyStringCleaveValues: CleaveValues = {
  prettyValue: "",
  rawValue: ""
};

export const emptyNumberCleaveValues: CleaveValues<number> = {
  prettyValue: "",
  rawValue: 0
};
