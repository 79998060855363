type IconMixpanelProps = {
  size: "24px";
  className?: string;
};

export const IconMixpanel: React.FC<IconMixpanelProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.1744 9.87558H10.1115C9.37808 9.41675 9.10348 8.77374 8.73503 7.58156L7.63324 3.4975C7.12924 1.66228 6.71562 0.789856 4.69617 0.789856H0.476562V1.89168H1.0744C2.31178 1.89168 2.45081 2.35049 2.81924 3.7269L3.78204 7.30696C4.28603 9.0518 5.06807 9.87558 7.17791 9.87558H7.1744ZM13.9696 9.87558H16.9031C19.0129 9.87558 19.7463 9.04835 20.2538 7.30696L21.2166 3.7269C21.585 2.35049 21.7658 1.89168 22.9615 1.89168H23.5593V0.789856H19.3849C17.3203 0.789856 16.9066 1.61709 16.4478 3.4975L15.346 7.58156C14.9776 8.81891 14.703 9.41675 13.9696 9.87558ZM10.1115 13.7268H13.9697L13.9696 9.87558H10.1115V13.7268ZM0.476562 22.8124H4.69617C6.71562 22.8124 7.12924 21.94 7.63324 20.1048L8.73503 16.0208C9.10348 14.8286 9.37808 14.1855 10.1115 13.7268H7.1744C5.06461 13.7268 4.28255 14.554 3.77856 16.2953L2.81577 19.8754C2.44733 21.2518 2.31178 21.7107 1.07092 21.7107H0.476562V22.8124ZM19.3779 22.8124H23.5523V21.7107H22.9545C21.7623 21.7107 21.5781 21.2518 21.2097 19.8754L20.2469 16.2953C19.7429 14.5505 19.0095 13.7268 16.8962 13.7268H13.9697C14.7031 14.1855 14.9671 14.7834 15.3356 16.0208L16.4374 20.1048C16.8962 21.9852 17.3098 22.8124 19.3744 22.8124H19.3779Z"
            fill="#4F44E0"
          />
        </svg>
      );
  }
};
