import { Link } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import {
  IconCompass,
  IconDollarSign,
  IconGrid,
  IconHouse,
  IconImage,
  IconShoppingCart
} from "elevar-design-system/src/icons";
import {
  heading3Styles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { type CroIdea } from "../../api/handlers/croIdeas";
import { PageCard } from "../../components/PageCard";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";

/* ========================================================================== */

type CroIdeasCardLinkProps = {
  croIdea: CroIdea;
};

export const CroIdeasCardLink: React.FC<CroIdeasCardLinkProps> = ({
  croIdea
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const croIdeaUrl = `${websiteUrl}/cro-ideas/${croIdea.slug}`;

  return (
    <IdeaLink to={croIdeaUrl}>
      <IdeaPageCard>
        <div>
          <IdeaImageWrapper>
            <img
              src={croIdea.image.file}
              alt={croIdea.image.title}
              width={croIdea.image.width}
              height={croIdea.image.height}
            />
          </IdeaImageWrapper>
        </div>
        <div>
          <IdeaMainDetails>
            <div>{croIdea.title}</div>
            <div>
              <img
                src={croIdea.submitter.picture.file}
                alt={croIdea.submitter.picture.title}
                width={croIdea.submitter.picture.width}
                height={croIdea.submitter.picture.height}
              />
              <div>
                {croIdea.submitter.name} from {croIdea.submitter.company}
              </div>
            </div>
          </IdeaMainDetails>
        </div>
        <div>
          <CroIdeasStatMetrics value={croIdea.metrics} allowWrap={false} />
        </div>
        <div>
          <CroIdeasStatImpact value={croIdea.impact} />
        </div>
        <div>
          <CroIdeasStatEffort value={croIdea.effort} />
        </div>
        <div>
          <CroIdeasStatPage value={croIdea.page} />
        </div>
      </IdeaPageCard>
    </IdeaLink>
  );
};

const IdeaLink = styled(Link)`
  display: block;
`;

const IdeaPageCard = styled(PageCard)`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: ${props => props.theme.gridBase * 3}px;
  grid-template-areas:
    "section-1 section-2 section-2 section-2 section-2 section-2"
    "section-1 section-3 section-3 section-4 section-5 section-6";

  > div:nth-child(1) {
    grid-area: section-1;
    align-self: start;
  }

  > div:nth-child(2) {
    grid-area: section-2;
    align-self: start;
  }

  > div:nth-child(3) {
    grid-area: section-3;
    align-self: end;
  }

  > div:nth-child(4) {
    grid-area: section-4;
    align-self: end;
  }

  > div:nth-child(5) {
    grid-area: section-5;
    align-self: end;
  }

  > div:nth-child(6) {
    grid-area: section-6;
    align-self: end;
  }
`;

const IdeaImageWrapper = styled.div`
  > img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
`;

const IdeaMainDetails = styled.div`
  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > div:last-child {
    display: flex;

    > img {
      width: ${props => props.theme.gridBase * 2}px;
      height: ${props => props.theme.gridBase * 2}px;
      border-radius: 2px;
      margin-right: ${props => props.theme.gridBase}px;
    }

    > div {
      ${smallTextStyles};
      color: ${props => props.theme.palette.grey3};
    }
  }
`;

/* ========================================================================== */

const getFormattedMetric = (value: CroIdea["metrics"][number]) => {
  switch (value) {
    case "ADD_TO_CART":
      return "Add to Cart Rate";
    case "AOV":
      return "Average Order Value (AOV)";
    case "CONVERSION":
      return "Conversion Rate";
    case "INITIATE_CHECKOUT":
      return "Initiate Checkout";
    case "PDP_VIEWS":
      return "PDP Views";
    case "PLP_VIEWS":
      return "PLP Views";
    case "REVENUE_PER_SESSION":
      return "Revenue per Session";
  }
};

type CroIdeasStatMetricsProps = {
  value: CroIdea["metrics"];
  allowWrap?: boolean;
};

export const CroIdeasStatMetrics: React.FC<CroIdeasStatMetricsProps> = ({
  value,
  allowWrap = true
}) => {
  const formattedValue = value
    .map(metric => getFormattedMetric(metric))
    .join(", ");

  return (
    <div>
      <StatMetricsHeading>Primary Metrics</StatMetricsHeading>
      <StatMetricsValue allowWrap={allowWrap}>
        {formattedValue}
      </StatMetricsValue>
    </div>
  );
};

const StatMetricsHeading = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

type StatMetricsValueProps = {
  allowWrap: boolean;
};

const StatMetricsValue = styled.div<StatMetricsValueProps>`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};

  ${props =>
    !props.allowWrap &&
    css`
      max-width: 100%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

/* ========================================================================== */

type CroIdeasStatImpactProps = {
  value: CroIdea["impact"];
};

export const CroIdeasStatImpact: React.FC<CroIdeasStatImpactProps> = ({
  value
}) => {
  return (
    <div>
      <StatImpactHeading>Impact</StatImpactHeading>
      <StatImpactValueWrapper>
        <StatImpactValueItem isActive={value === 1}>1</StatImpactValueItem>
        <StatImpactValueItem isActive={value === 2}>2</StatImpactValueItem>
        <StatImpactValueItem isActive={value === 3}>3</StatImpactValueItem>
        <StatImpactValueItem isActive={value === 4}>4</StatImpactValueItem>
        <StatImpactValueItem isActive={value === 5}>5</StatImpactValueItem>
      </StatImpactValueWrapper>
    </div>
  );
};

const StatImpactHeading = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const StatImpactValueWrapper = styled.div`
  display: flex;
`;

type StatImpactValueItemProps = {
  isActive: boolean;
};

const StatImpactValueItem = styled.div<StatImpactValueItemProps>`
  ${subheadingStyles};
  width: ${props => props.theme.gridBase * 2.5}px;
  height: ${props => props.theme.gridBase * 2.5}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props =>
    props.isActive ? props.theme.palette.grey2 : props.theme.palette.grey8};
  color: ${props =>
    props.isActive ? props.theme.palette.white : props.theme.palette.grey3};

  &:not(:last-child) {
    margin-right: ${props => props.theme.gridBase * 0.25}px;
  }
`;

/* ========================================================================== */

const getFormattedEffort = (value: CroIdea["effort"]) => {
  switch (value) {
    case "EASY":
      return "Easy";
    case "MEDIUM":
      return "Medium";
    case "HARD":
      return "Hard";
  }
};

type CroIdeasStatEffortProps = {
  value: CroIdea["effort"];
};

export const CroIdeasStatEffort: React.FC<CroIdeasStatEffortProps> = ({
  value
}) => {
  const theme = useTheme();
  const formattedValue = getFormattedEffort(value);

  return (
    <div>
      <StatEffortHeading>Effort</StatEffortHeading>
      <StatEffortValueWrapper>
        <StatEffortValueIconWrapper>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <rect
              x="3"
              y="3"
              width="2"
              height="10"
              rx="1"
              transform="rotate(-90 3 3)"
              fill={
                value === "HARD" ? theme.palette.grey2 : theme.palette.grey6
              }
            />
            <rect
              x="3"
              y="9"
              width="2"
              height="10"
              rx="1"
              transform="rotate(-90 3 9)"
              fill={
                value === "HARD" || value === "MEDIUM"
                  ? theme.palette.grey2
                  : theme.palette.grey6
              }
            />
            <rect
              x="3"
              y="15"
              width="2"
              height="10"
              rx="1"
              transform="rotate(-90 3 15)"
              fill={theme.palette.grey2}
            />
          </svg>
        </StatEffortValueIconWrapper>
        <StatEffortValueText>{formattedValue}</StatEffortValueText>
      </StatEffortValueWrapper>
    </div>
  );
};

const StatEffortHeading = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const StatEffortValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatEffortValueIconWrapper = styled.div`
  display: flex;
  margin-right: ${props => props.theme.gridBase}px;
`;

const StatEffortValueText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
`;

/* ========================================================================== */

type StatPageIconProps = {
  value: CroIdea["page"];
};

const StatPageIcon: React.FC<StatPageIconProps> = ({ value }) => {
  switch (value) {
    case "SITEWIDE":
      return <IconCompass size="16px" />;
    case "HOMEPAGE":
      return <IconHouse size="16px" />;
    case "PLP":
      return <IconGrid size="16px" />;
    case "PDP":
      return <IconImage size="16px" />;
    case "CART":
      return <IconShoppingCart size="16px" />;
    case "CHECKOUT":
      return <IconDollarSign size="16px" />;
  }
};

export const getFormattedPage = (value: CroIdea["page"]) => {
  switch (value) {
    case "SITEWIDE":
      return "Sitewide";
    case "HOMEPAGE":
      return "Homepage";
    case "PLP":
      return "PLP";
    case "PDP":
      return "PDP";
    case "CART":
      return "Cart";
    case "CHECKOUT":
      return "Checkout";
  }
};

type CroIdeasStatPageProps = {
  value: CroIdea["page"];
};

export const CroIdeasStatPage: React.FC<CroIdeasStatPageProps> = ({
  value
}) => {
  const formattedValue = getFormattedPage(value);

  return (
    <div>
      <StatPageHeading>Page</StatPageHeading>
      <StatPageValueWrapper>
        <StatPageValueIconWrapper>
          <StatPageIcon value={value} />
        </StatPageValueIconWrapper>
        <StatPageValueText>{formattedValue}</StatPageValueText>
      </StatPageValueWrapper>
    </div>
  );
};

const StatPageHeading = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const StatPageValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatPageValueIconWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey2};
  margin-right: ${props => props.theme.gridBase}px;
`;

const StatPageValueText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
`;
