import { produce } from "immer";
import { uniq } from "lodash-es";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type ContainerInfo,
  useContainerInfoQuery,
  useMyTrackingVersionMutation
} from "../../api/handlers/website";
import { InputFieldNumber } from "../../components/InputFieldNumber";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { ContainerSetupWeb } from "./ContainerSetupWeb";
import { destinationAwin as destination } from "./data";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

type DestinationAwinProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
};

export const DestinationAwin: React.FC<DestinationAwinProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig
}) => {
  const containerInfo = useContainerInfoQuery({ destination });

  if (containerInfo.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <DestinationAwinInner
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      containerInfo={containerInfo.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-awin-in-elevar-server-side-destination";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  containerInfo: ContainerInfo;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationAwinInnerProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  containerInfo: ContainerInfo;
};

const DestinationAwinInner: React.FC<DestinationAwinInnerProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  containerInfo
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Awin Settings" },
          { name: "Consent Mode" },
          { name: "Transaction Identifier" },
          { name: "Product Identifier" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" },
          { name: "Web Container Setup" }
        ]}
        context={{ setSaveModalState, containerInfo }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () => (
                  <>
                    The Merchant ID used is <span>{config.adAccountId}</span>
                  </>
                )
              },
              {
                step: 2,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 3,
                type: "TRANSACTION_IDENTIFIER",
                value: config.dataConfig.orderAttributeId
              },
              {
                step: 4,
                type: "PRODUCT_IDENTIFIER",
                value: config.dataConfig.productAttributeMapping
              },
              {
                step: 5,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 6,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  const [isLoading, setIsLoading] = useState(false);
  const [merchantId, setMerchantId] = useState(config.adAccountId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [productToCommissionGroupMapping, setProductToCommissionGroupMapping] =
    useState(config.dataConfig.productToCommissionGroupMapping);

  const areAwinFieldsFilledIn = merchantId !== "";

  const areMappingsValid = productToCommissionGroupMapping.every(
    mapping => mapping.productId !== "" && mapping.groupName !== ""
  );
  const areMappingsUnique =
    uniq(productToCommissionGroupMapping.map(mapping => mapping.productId))
      .length === productToCommissionGroupMapping.length;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Awin Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Awin account.
            </Step1Explainer>
          }
        >
          <InputWrapper
            labelText="Merchant ID"
            disabled={isLoading}
            tooltip={{
              maxWidth: `${theme.gridBase * 29}px`,
              render: () => (
                <Step1InputWrapperTooltipContent>
                  <p>
                    Your Merchant ID is available under Account &gt; Overview.
                    It can also be provided by your account contact.
                  </p>
                  <StyledLinkExternal
                    href="https://docs.getelevar.com/docs/how-to-find-awin-merchant-id"
                    text="How do I find this?"
                  />
                </Step1InputWrapperTooltipContent>
              )
            }}
          >
            <InputFieldText
              variant="SMALL"
              disabled={isLoading}
              value={merchantId}
              onChange={event => setMerchantId(event.target.value)}
              placeholder="1234"
              spellCheck={false}
              autoCapitalize="off"
            />
          </InputWrapper>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper>
            <div>
              <div>
                Here you can further customize how to send data to Awin.
              </div>
              <div>
                <Step1AdvancedOptionWrapper>
                  {productToCommissionGroupMapping.length === 0 ? (
                    <Step1IconTextButton
                      disabled={isLoading}
                      onClick={() => {
                        setProductToCommissionGroupMapping([
                          { productId: "", groupName: "" }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Specify Commission Groups</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1CustomItemsExistWrapper>
                      <div>
                        <div>Specify Commission Groups</div>
                        <div>
                          What Commission Groups do you have for products?
                          Provide them here for more accurate tracking.
                        </div>
                      </div>
                      <div>
                        <div>
                          {productToCommissionGroupMapping.map(
                            (item, index) => (
                              <Step1MapItemWrapper key={index}>
                                <div>
                                  <InputWrapper
                                    labelText="Product ID"
                                    tooltip={{
                                      maxWidth: `${theme.gridBase * 36.5}px`,
                                      render: () => (
                                        <Step1InputWrapperTooltipContent>
                                          Product SKUs and Variant IDs are not
                                          supported here, so ensure this is the
                                          Product ID of the product that belongs
                                          to the commission group referenced.
                                        </Step1InputWrapperTooltipContent>
                                      )
                                    }}
                                    disabled={isLoading}
                                  >
                                    <InputFieldNumber
                                      variant="SMALL"
                                      disabled={isLoading}
                                      value={item.productId}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setProductToCommissionGroupMapping(
                                          produce(
                                            productToCommissionGroupMapping,
                                            draft => {
                                              draft[index]!.productId = value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Enter one Product ID per row"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                  <InputWrapper
                                    labelText="Commission Group Code"
                                    tooltip={{
                                      maxWidth: `${theme.gridBase * 30}px`,
                                      render: () => (
                                        <Step1InputWrapperTooltipContent>
                                          <p>
                                            This is the code for the commission
                                            group you want to attribute the
                                            transaction to.
                                          </p>
                                          <StyledLinkExternal
                                            href="https://wiki.awin.com/index.php/Advertiser_Tracking_Guide/Commission_Groups"
                                            text="Learn More"
                                          />
                                        </Step1InputWrapperTooltipContent>
                                      )
                                    }}
                                    disabled={isLoading}
                                  >
                                    <InputFieldText
                                      variant="SMALL"
                                      disabled={isLoading}
                                      value={item.groupName}
                                      onChange={event => {
                                        const value = event.target.value;
                                        setProductToCommissionGroupMapping(
                                          produce(
                                            productToCommissionGroupMapping,
                                            draft => {
                                              draft[index]!.groupName = value;
                                            }
                                          )
                                        );
                                      }}
                                      placeholder="Enter one code"
                                      spellCheck={false}
                                      autoCapitalize="off"
                                    />
                                  </InputWrapper>
                                </div>
                                <div>
                                  <Step1OverrideItemRemoveButton
                                    aria-label={`Remove item ${index + 1}`}
                                    disabled={isLoading}
                                    onClick={() => {
                                      setProductToCommissionGroupMapping(
                                        produce(
                                          productToCommissionGroupMapping,
                                          draft => {
                                            draft.splice(index, 1);
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    <IconCross size="16px" />
                                  </Step1OverrideItemRemoveButton>
                                </div>
                              </Step1MapItemWrapper>
                            )
                          )}
                        </div>
                        <Step1IconTextButton
                          disabled={isLoading}
                          onClick={() => {
                            setProductToCommissionGroupMapping([
                              ...productToCommissionGroupMapping,
                              { productId: "", groupName: "" }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1CustomItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
              </div>
            </div>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areAwinFieldsFilledIn
              ? "All fields are required"
              : !areMappingsValid
                ? "All commission group rows must have all values filled in"
                : !areMappingsUnique
                  ? "All commission group rows must use unique Product IDs"
                  : null
          }
          onSave={async data => {
            const action = async () => {
              setIsLoading(true);
              await configMutation({
                ...data,
                adAccountId: merchantId,
                dataConfig: { productToCommissionGroupMapping },
                ...(!isStepCompleted ? { completedStep: 1 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
              setIsLoading(false);
            };

            if (config.live) {
              setSaveModalState({ isVisible: true, onConfirm: action });
            } else {
              await action();
            }
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }

  a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

const Step1AdvancedOptionsWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div {
    > div:first-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

const Step1AdvancedOptionWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const Step1IconTextButton = styled.button`
  ${iconTextButtonStyles};
`;

const Step1CustomItemsExistWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};

      > a {
        ${linkStyles};
      }
    }
  }
`;

const Step1MapItemWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    flex: 1;
    max-width: ${props => props.theme.gridBase * 75}px;
    display: grid;
    gap: ${props => props.theme.gridBase}px;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1600px) {
      width: 100%;
      grid-template-columns: 1fr;
      gap: ${props => props.theme.gridBase * 1.5}px;
      justify-items: stretch;
    }
  }

  > div:last-child {
    margin-left: ${props => props.theme.gridBase}px;
  }

  &:not(:last-child) {
    @media screen and (max-width: 1600px) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
      border-bottom: 1px solid ${props => props.theme.palette.grey6};
      padding-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step1OverrideItemRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();
  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 2 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <TransactionIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["id", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { orderAttributeId },
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <ProductIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { productAttributeMapping },
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 5 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 6;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 6 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { containerInfo }
  } = useSetupFlowDetails();

  const { mutateAsync: myTrackingVersionMutation } =
    useMyTrackingVersionMutation();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 7;

  return (
    <ContainerSetupWeb
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      isRequiredOverride={true}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      onSave={async () => {
        setIsLoading(true);
        await myTrackingVersionMutation("destination-awin");
        await configMutation({ completedStep: 7 });
        setIsLoading(false);
      }}
    />
  );
};
