type IconUpscribeProps = {
  size: "24px";
  className?: string;
};

export const IconUpscribe: React.FC<IconUpscribeProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3209 2.93048C12.1497 2.84492 11.9786 2.84492 11.7219 2.93048L5.64706 5.06952L10.8663 6.86631C12.3209 7.37968 13.3476 8.83423 13.3476 10.3743V20.5562L20.4492 17.9893C20.7914 17.9037 21.0481 17.5615 21.0481 17.1337V7.20856L16.8556 8.6631C15.3155 9.17647 13.6898 8.40642 13.1765 6.86631L18.2246 5.06952L12.3209 2.93048ZM10.6096 20.6417V10.4599C10.6096 10.1176 10.3529 9.7754 10.0107 9.60428L2.90909 7.20856V17.3048C2.90909 17.6471 3.16578 17.9893 3.50802 18.1604L10.6096 20.6417ZM10.7807 0.192513C11.5508 -0.0641711 12.4064 -0.0641711 13.262 0.192513L21.5615 3.1016C23.016 3.61497 24.0428 5.06952 24.0428 6.60963V17.2193C24.0428 18.8449 23.016 20.2139 21.5615 20.7273L13.262 23.8075C12.492 24.0642 11.6364 24.0642 10.7807 23.8075L2.48128 20.8984C1.02674 20.385 0 18.9305 0 17.3904V6.69519C0 5.06952 1.02674 3.70053 2.48128 3.18717L10.7807 0.192513Z"
            fill="#1EBEFE"
          />
        </svg>
      );
  }
};
