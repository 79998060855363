import { type OnboardingState } from "../context/OnboardingDetails";

/* ========================================================================== */

type ElevarItem = NonNullable<(typeof window)["ElevarDataLayer"]>[0];

export const pushToElevarDataLayer = (item: ElevarItem) => {
  window.ElevarDataLayer = window.ElevarDataLayer ?? [];
  window.ElevarDataLayer.push(item);
};

/* ========================================================================== */

type GtmItem = {
  event?: string;
  action?: string;
  user?: {
    id?: number;
    email?: string;
  };
  website?: {
    id?: number;
    name?: string;
    reason?: string;
    plan_name?: string;
    plan_amount?: number;
    onboarding_state?: OnboardingState["name"];
  };
  company?: {
    name?: string;
    owner_email?: string;
  };
  feature?: {
    myTracking?: {
      onboardingStep?: string;
      destinationName?: string;
      event?: { type: "SERVER" | "WEB"; name: string; isEnabled?: boolean };
    };
    products?: { explore?: { category?: string; name?: string } };
    featureTips?: { page: string };
    croIdeas?: { sort?: string; filter?: string; title?: string };
    preBuiltTags?: { name?: string; query?: string };
  };
  interface?: {
    plan?: { name: string };
    product?: { name: string };
    tab?: { location: string; tabName: string };
    state?: string;
  };
};

export const pushToGtmDataLayer = (item: GtmItem) => {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(item);
};
