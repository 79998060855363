import { createContext, useContext } from "react";
import { Helmet } from "react-helmet-async";

import {
  type CompanyDetails,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

/* ========================================================================== */

const titleCompanyContext = createContext<string | undefined>(undefined);

type TitleCompanyProviderProps = {
  companyDetails: CompanyDetails;
  children: React.ReactNode;
};

export const TitleCompanyProvider: React.FC<TitleCompanyProviderProps> = ({
  companyDetails,
  children
}) => {
  return (
    <titleCompanyContext.Provider value={companyDetails.name}>
      {children}
    </titleCompanyContext.Provider>
  );
};

/* ========================================================================== */

const titleWebsiteContext = createContext<string | undefined>(undefined);

type TitleWebsiteProviderProps = {
  websiteDetails: WebsiteDetails;
  children: React.ReactNode;
};

export const TitleWebsiteProvider: React.FC<TitleWebsiteProviderProps> = ({
  websiteDetails,
  children
}) => {
  return (
    <titleWebsiteContext.Provider value={websiteDetails.name}>
      {children}
    </titleWebsiteContext.Provider>
  );
};

/* ========================================================================== */

type TitleProviderProps = {
  page: string;
  children: React.ReactNode;
};

export const TitleProvider: React.FC<TitleProviderProps> = ({
  page,
  children
}) => {
  const companyName = useContext(titleCompanyContext);
  const websiteName = useContext(titleWebsiteContext);

  if (websiteName && !companyName) {
    throw new Error("`TitleProvider`: Company not provided when Website was");
  }

  const title =
    companyName && websiteName
      ? `${page} | ${websiteName} | ${companyName} | Elevar`
      : companyName
        ? `${page} | ${companyName} | Elevar`
        : `${page} | Elevar`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};
