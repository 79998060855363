type IconPodsightsProps = {
  size: "24px";
  className?: string;
};

export const IconPodsights: React.FC<IconPodsightsProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2.72392 11.5931C2.72392 12.4173 2.13129 12.9853 1.4216 12.9853C1.1308 12.9853 0.879018 12.8868 0.700123 12.6905V14.0496H0V10.2283H0.547731L0.626135 10.4903H0.71006C0.883435 10.3048 1.11828 10.2027 1.42012 10.2027C2.12982 10.2009 2.72392 10.7689 2.72392 11.5931ZM1.99731 11.5931C1.99731 11.1125 1.65608 10.8742 1.31521 10.8742C0.951901 10.8742 0.700123 11.142 0.700123 11.5931C0.700123 12.0442 0.951901 12.3138 1.31521 12.3138C1.65645 12.3138 1.99583 12.0737 1.99583 11.5931H1.99731Z"
            fill="#697FFF"
          />
          <path
            d="M3.02464 11.5985C3.02464 10.725 3.71777 10.2009 4.46684 10.2009C5.21592 10.2009 5.88107 10.7308 5.88107 11.5877C5.88107 12.4558 5.199 12.9853 4.44549 12.9853C3.6839 12.9853 3.02464 12.4558 3.02464 11.5985ZM5.14894 11.5931C5.14894 11.1672 4.89126 10.8343 4.46684 10.8343C4.04243 10.8343 3.75678 11.1672 3.75678 11.5931C3.75678 12.0191 4.04169 12.3519 4.46684 12.3519C4.892 12.3519 5.14894 12.0187 5.14894 11.5931Z"
            fill="#697FFF"
          />
          <path
            d="M8.90544 9.08195V12.9579H8.35772L8.27968 12.6959H8.19575C8.02238 12.8814 7.78754 12.9835 7.48569 12.9835C6.77563 12.9835 6.183 12.4155 6.183 11.5913C6.183 10.7671 6.77563 10.1991 7.48569 10.1991C7.77649 10.1991 8.02791 10.2976 8.2068 10.4939V9.08195H8.90544ZM8.20606 11.5931C8.20606 11.1402 7.95465 10.8742 7.59097 10.8742C7.25011 10.8742 6.90924 11.1143 6.90924 11.5931C6.90924 12.0719 7.25011 12.3138 7.59097 12.3138C7.95538 12.3138 8.20863 12.046 8.20863 11.5931H8.20606Z"
            fill="#697FFF"
          />
          <path
            d="M10.5214 10.7797C10.2865 10.7797 10.186 10.8943 10.186 11.009C10.186 11.1945 10.4374 11.2491 10.6053 11.2818C11.103 11.3857 11.6058 11.5334 11.6058 12.1118C11.6058 12.674 11.1306 12.9853 10.5549 12.9853C9.91217 12.9853 9.41377 12.6028 9.38101 12.0245H10.0686C10.0859 12.188 10.1964 12.4012 10.5383 12.4012C10.8232 12.4012 10.8902 12.2534 10.8902 12.1391C10.8902 11.9371 10.6948 11.8771 10.5048 11.8332C10.1735 11.7613 9.46493 11.6312 9.46493 11.009C9.46493 10.4741 9.99021 10.2009 10.5324 10.2009C11.1582 10.2009 11.5723 10.5723 11.6058 11.0309H10.9182C10.8957 10.9378 10.8067 10.7797 10.5214 10.7797Z"
            fill="#697FFF"
          />
          <path
            d="M11.8741 9.46405C11.8743 9.40266 11.8868 9.34191 11.9111 9.28529C11.9354 9.22868 11.971 9.17732 12.0157 9.13418C12.0604 9.09105 12.1134 9.05698 12.1717 9.03395C12.23 9.01092 12.2923 8.99938 12.3552 8.99999C12.4173 9.00008 12.4788 9.01219 12.5361 9.03562C12.5933 9.05904 12.6453 9.09332 12.6889 9.13647C12.7326 9.17962 12.767 9.23078 12.7903 9.28701C12.8136 9.34323 12.8252 9.40341 12.8245 9.46405C12.8228 9.58511 12.7728 9.70075 12.6852 9.7864C12.5976 9.87204 12.4792 9.92094 12.3552 9.92271C12.2297 9.92238 12.1092 9.8743 12.0194 9.78866C11.9296 9.70303 11.8775 9.58659 11.8741 9.46405ZM12.713 12.9579H11.9974V10.2282H12.713V12.9579Z"
            fill="#697FFF"
          />
          <path
            d="M15.8712 10.2283V12.6848C15.8712 13.422 15.4859 14.0769 14.5295 14.0769C13.6354 14.0769 13.1487 13.5018 13.1487 13.0726H13.8481C13.8481 13.0726 13.9541 13.4274 14.5078 13.4274C14.9771 13.4274 15.1729 13.1758 15.1729 12.794V12.6905C14.994 12.8868 14.7422 12.9853 14.4518 12.9853C13.7417 12.9853 13.1491 12.4173 13.1491 11.5931C13.1491 10.7689 13.7417 10.2009 14.4518 10.2009C14.7537 10.2009 14.9885 10.3048 15.1619 10.4885H15.2454L15.3238 10.2265L15.8712 10.2283ZM15.1718 11.5942C15.1718 11.1413 14.92 10.8753 14.5567 10.8753C14.2159 10.8753 13.875 11.1154 13.875 11.5942C13.875 12.073 14.2159 12.3149 14.5567 12.3149C14.9208 12.3138 15.1725 12.046 15.1725 11.5931L15.1718 11.5942Z"
            fill="#697FFF"
          />
          <path
            d="M19.2034 11.4784V12.9579H18.4543V11.6423C18.4543 11.3749 18.4992 10.8343 17.9962 10.8343C17.7499 10.8343 17.2746 10.9597 17.2746 11.6751V12.9579H16.5255V9.08195H17.2355V10.5122H17.3309C17.4649 10.3756 17.7223 10.2009 18.1355 10.2009C18.8571 10.2009 19.2034 10.725 19.2034 11.4784Z"
            fill="#697FFF"
          />
          <path
            d="M21.6689 12.7559C21.6689 12.7559 21.4342 12.9853 21.0038 12.9853C20.4671 12.9853 20.0029 12.6305 20.0029 12.0787V10.8124H19.4438V10.2775H19.7235C19.9135 10.2775 20.0423 10.1521 20.0423 9.954V9.40942H20.7185V10.2283H21.4234V10.8124H20.7189V12.0345C20.7189 12.22 20.8754 12.362 21.0768 12.362C21.1908 12.3568 21.302 12.325 21.4009 12.2692L21.6689 12.7559Z"
            fill="#697FFF"
          />
          <path
            d="M22.9152 10.7797C22.6808 10.7797 22.5799 10.8943 22.5799 11.009C22.5799 11.1945 22.8317 11.2491 22.9992 11.2818C23.4968 11.3857 24 11.5334 24 12.1118C24 12.674 23.5248 12.9853 22.9491 12.9853C22.306 12.9853 21.808 12.6028 21.7752 12.0245H22.4625C22.4794 12.188 22.5914 12.4012 22.9322 12.4012C23.2174 12.4012 23.2845 12.2534 23.2845 12.1391C23.2845 11.9371 23.0889 11.8771 22.8986 11.8332C22.5674 11.7613 21.8592 11.6312 21.8592 11.009C21.8592 10.4741 22.3845 10.2009 22.9266 10.2009C23.5525 10.2009 23.9665 10.5723 24 11.0309H23.3125C23.29 10.9378 23.2005 10.7797 22.9152 10.7797Z"
            fill="#697FFF"
          />
        </svg>
      );
  }
};
