import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { IconCompany, IconUser } from "./icons";

/* ========================================================================== */

type AvatarSize = "SMALL" | "MEDIUM" | "LARGE";
export type AvatarType = "COMPANY" | "USER";

/* ========================================================================== */

type AvatarProps = {
  size: AvatarSize;
  type: AvatarType;
  imageSrc?: string;
};

export const Avatar: React.FC<AvatarProps> = ({ size, type, imageSrc }) => {
  const [imageError, setImageError] = useState(false);

  useEffect(() => setImageError(false), [imageSrc]);

  return (
    <AvatarWrapper size={size}>
      {imageSrc && !imageError ? (
        <AvatarImage src={imageSrc} onError={() => setImageError(true)} />
      ) : (
        <AvatarPlaceholder>
          {type === "COMPANY" && <IconCompany size="24px" />}
          {type === "USER" && <IconUser size="24px" />}
        </AvatarPlaceholder>
      )}
    </AvatarWrapper>
  );
};

/* ========================================================================== */

type AvatarWrapperProps = {
  size: AvatarSize;
};

const AvatarWrapper = styled.div<AvatarWrapperProps>`
  background-color: ${props => props.theme.palette.grey6};
  box-shadow: ${props => props.theme.other.boxShadowAvatar};
  overflow: hidden;

  ${props =>
    props.size === "SMALL" &&
    css`
      width: ${props.theme.gridBase * 3}px;
      height: ${props.theme.gridBase * 3}px;
      border-radius: 2px;
    `};

  ${props =>
    props.size === "MEDIUM" &&
    css`
      width: ${props.theme.gridBase * 4}px;
      height: ${props.theme.gridBase * 4}px;
      border-radius: 2px;
    `};

  ${props =>
    props.size === "LARGE" &&
    css`
      width: ${props.theme.gridBase * 10}px;
      height: ${props.theme.gridBase * 10}px;
      border-radius: 4px;
    `};
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  color: ${props => props.theme.palette.grey3};
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 75%;
    height: 75%;
  }
`;
