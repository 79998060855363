import cubejs, { type CubeApi, type Meta } from "@cubejs-client/core";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { useWebsiteMonitoringTokenQuery } from "../api/handlers/website";
import { useWebsiteId } from "../utils/idHooks";

/* ========================================================================== */

type CubeApiDetails = { cubeApi: CubeApi; meta: Meta };

const cubeApiDetailsContext = createContext<CubeApiDetails | undefined>(
  undefined
);

/* ========================================================================== */

const cubeApiUrl = `${import.meta.env.VITE_CUBEJS_HTTP_URL}/cubejs-api/v1`;

const fetchCubeApiDetails = async (token: string) => {
  const cubeApi = cubejs(token, { apiUrl: cubeApiUrl });
  const meta = await cubeApi.meta();
  return { cubeApi, meta };
};

type UseCubeApiDetailsQueryArgs = {
  websiteId: number;
  token: string;
};

const useCubeApiDetailsQuery = (args: UseCubeApiDetailsQueryArgs) => {
  return useQuery({
    queryKey: ["websites", args.websiteId, "cubeApiDetails"],
    queryFn: () => fetchCubeApiDetails(args.token)
  });
};

/* ========================================================================== */

type CubeApiDetailsProviderProps = {
  children: React.ReactNode;
};

export const CubeApiDetailsProvider: React.FC<CubeApiDetailsProviderProps> = ({
  children
}) => {
  const websiteMonitoringToken = useWebsiteMonitoringTokenQuery();

  if (websiteMonitoringToken.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (websiteMonitoringToken.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <CubeApiDetailsProviderInner token={websiteMonitoringToken.data}>
      {children}
    </CubeApiDetailsProviderInner>
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type CubeApiDetailsProviderInnerProps = {
  token: string;
  children: React.ReactNode;
};

const CubeApiDetailsProviderInner: React.FC<
  CubeApiDetailsProviderInnerProps
> = ({ token, children }) => {
  const websiteId = useWebsiteId();
  const cubeApiDetails = useCubeApiDetailsQuery({ websiteId, token });

  if (cubeApiDetails.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (cubeApiDetails.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <cubeApiDetailsContext.Provider value={cubeApiDetails.data}>
      {children}
    </cubeApiDetailsContext.Provider>
  );
};

/* ========================================================================== */

export const useCubeApiDetails = () => {
  const cubeApiDetails = useContext(cubeApiDetailsContext);

  if (cubeApiDetails !== undefined) {
    return cubeApiDetails;
  } else {
    throw new Error("`useCubeApiDetails`: value not set");
  }
};
