import { useMutation, useQuery } from "@tanstack/react-query";

import { type CompanyRole } from "elevar-common-ts/src/apiTypes";

import { apiRequest, useExtendedQueryClient } from "../utils";

/* ========================================================================== */

export type InviteDetails = {
  id: number;
  email: string;
  role: CompanyRole;
  company: string;
  is_new_user: boolean;
};

const fetchInviteDetails = (inviteToken: string) => {
  return apiRequest<InviteDetails>({
    endpoint: `/invites/token/${inviteToken}`,
    requiresAuth: false
  });
};

export const useInviteDetailsQuery = (inviteToken: string) => {
  return useQuery({
    queryKey: ["inviteDetails", inviteToken],
    queryFn: () => fetchInviteDetails(inviteToken)
  });
};

/* ========================================================================== */

const acceptInvite = (inviteToken: string) => {
  return apiRequest({
    endpoint: `/invites/token/${inviteToken}/accept`,
    method: "POST"
  });
};

export const useInviteAcceptMutation = (inviteToken: string) => {
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => acceptInvite(inviteToken),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "details"],
        ["account", "companyList"]
      ]);
    }
  });
};
