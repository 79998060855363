import { useQuery } from "@tanstack/react-query";

import { graphqlRequest } from "../utils";

/* ========================================================================== */

export type FacebookVersions = {
  default: string;
  versions: Array<string>;
};

/* -------------------------------------------------------------------------- */

type FacebookVersionsResponse = {
  data: { facebookVersions: FacebookVersions };
};

const fetchFacebookVersions = async () => {
  const response = await graphqlRequest<FacebookVersionsResponse>({
    data: {
      query: /* GraphQL */ `
        query FacebookVersions {
          facebookVersions {
            default
            versions
          }
        }
      `
    }
  });

  return response.data.facebookVersions;
};

export const useFacebookVersionsQuery = () => {
  return useQuery({
    queryKey: ["cms", "facebookVersions"],
    queryFn: fetchFacebookVersions
  });
};
