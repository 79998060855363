import { Link, type LinkProps } from "react-router-dom";
import styled from "styled-components";

import { IconChevronLeft } from "elevar-design-system/src/icons";
import { normalTextStyles } from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

export const BackLink: React.FC<LinkProps> = ({ ...props }) => {
  return (
    <BackLinkWrapper {...props}>
      <div>
        <IconChevronLeft size="16px" />
      </div>
      <div>Back</div>
    </BackLinkWrapper>
  );
};

/* ========================================================================== */

const BackLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  width: max-content;

  > div:first-child {
    display: flex;
    border-radius: 2px;
    background-color: ${props => props.theme.palette.white};
    color: ${props => props.theme.palette.grey2};
    padding: ${props => props.theme.gridBase * 0.5}px;
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
  }
`;
