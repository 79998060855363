import { useId } from "react";

/* ========================================================================== */

type IconBingProps = {
  size: "24px";
  className?: string;
};

export const IconBing: React.FC<IconBingProps> = ({ size, className }) => {
  const idPrefix = useId();
  const clipPathId = `${idPrefix}-clipPath`;
  const linearGradient1Id = `${idPrefix}-linearGradient1`;
  const linearGradient2Id = `${idPrefix}-linearGradient2`;
  const linearGradient3Id = `${idPrefix}-linearGradient3`;
  const linearGradient4Id = `${idPrefix}-linearGradient4`;
  const linearGradient5Id = `${idPrefix}-linearGradient4`;

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath={`url(#${clipPathId})`}>
            <path
              d="M20.3536 15.4073C20.3535 17.0452 19.7333 18.6223 18.6176 19.8214C18.7348 19.6914 18.8401 19.551 18.9321 19.4021C19.0036 19.2887 19.0665 19.17 19.1201 19.0471C19.1396 19.0066 19.1569 18.9651 19.1718 18.9227C19.1892 18.8821 19.2044 18.8405 19.2173 18.7983C19.2319 18.7591 19.2445 18.7186 19.2557 18.6781C19.2669 18.6375 19.2788 18.5942 19.2878 18.5516L19.2927 18.5369C19.3025 18.495 19.3102 18.453 19.3186 18.4104C19.327 18.3678 19.3347 18.323 19.3417 18.279C19.3479 18.2385 19.3528 18.1979 19.3563 18.156C19.3662 18.0601 19.3711 17.9638 19.371 17.8674C19.3725 17.3382 19.229 16.8188 18.9559 16.3655C18.8963 16.2639 18.8297 16.1665 18.7567 16.0741C18.6714 15.964 18.5779 15.8606 18.4772 15.7645C18.2265 15.5211 17.9338 15.325 17.6133 15.1858C17.4729 15.1237 17.3275 15.0737 17.1786 15.0362H17.1724L17.1206 15.0188L16.3652 14.7595L14.3894 14.0802C14.3832 14.0802 14.3748 14.0802 14.3699 14.0802L14.2462 14.0348C13.8497 13.8774 13.5205 13.5868 13.3153 13.2129L12.5961 11.3693L11.7694 9.26146L11.6107 8.85471L11.5702 8.77224C11.524 8.6596 11.5002 8.53899 11.5003 8.41722C11.4985 8.38579 11.4985 8.35429 11.5003 8.32287C11.5151 8.17948 11.5634 8.04157 11.6412 7.92022C11.719 7.79886 11.8242 7.69745 11.9483 7.62411C12.0724 7.55078 12.2119 7.50757 12.3558 7.49796C12.4996 7.48835 12.6437 7.51261 12.7764 7.56878L16.4588 9.45574L17.1849 9.82684C17.5694 10.0552 17.9287 10.3234 18.257 10.6271C19.4804 11.7476 20.2258 13.2958 20.339 14.951C20.3473 15.104 20.3536 15.255 20.3536 15.4073Z"
              fill={`url(#${linearGradient1Id})`}
            />
            <path
              d="M19.3689 17.8646C19.3689 18.0479 19.3521 18.2309 19.3186 18.4111C19.3088 18.4579 19.299 18.5054 19.2879 18.5509C19.2669 18.6347 19.2445 18.7158 19.218 18.7976C19.2033 18.8395 19.1886 18.8814 19.1725 18.922C19.1565 18.9625 19.1383 19.0044 19.1208 19.0464C19.0672 19.1693 19.0043 19.288 18.9328 19.4014C18.8406 19.5502 18.7354 19.6906 18.6183 19.8207C18.0816 20.4141 16.2596 21.4722 15.588 21.9027L14.0973 22.8112C13.005 23.4842 11.972 23.9608 10.6728 23.993C10.6113 23.993 10.5512 23.993 10.4911 23.993C10.4073 23.993 10.3241 23.993 10.2416 23.9881C9.18963 23.9476 8.16326 23.6518 7.25107 23.1262C6.33888 22.6006 5.56826 21.8609 5.00568 20.9711C4.48675 20.1541 4.15987 19.2301 4.04962 18.2685C4.12977 18.7157 4.31477 19.1375 4.58946 19.4994C4.86415 19.8613 5.2207 20.1529 5.62988 20.3503C6.03906 20.5478 6.48921 20.6454 6.94341 20.6352C7.39761 20.625 7.84294 20.5073 8.24286 20.2918L8.25264 20.2869L8.39871 20.1995L8.99205 19.8501L9.74753 19.4028V19.3818L9.84468 19.3238L16.6035 15.32L17.1234 15.0118L17.1752 15.0293H17.1814C17.3304 15.0665 17.4758 15.1166 17.6161 15.1788C17.9366 15.318 18.2293 15.5141 18.48 15.7575C18.5807 15.8536 18.6742 15.9571 18.7595 16.0671C18.8325 16.1596 18.899 16.2569 18.9587 16.3585C19.23 16.8138 19.3719 17.3346 19.3689 17.8646Z"
              fill={`url(#${linearGradient2Id})`}
            />
            <path
              d="M9.74753 4.50144V19.4077L8.98925 19.8522L8.39521 20.2016L8.24915 20.2904C8.24576 20.2908 8.24259 20.2923 8.24006 20.2946C7.84019 20.5101 7.39492 20.6278 6.94076 20.6381C6.4866 20.6483 6.03648 20.5507 5.62732 20.3534C5.21815 20.156 4.86159 19.8645 4.58686 19.5027C4.31213 19.1409 4.12706 18.7192 4.04682 18.272C4.03564 18.2098 4.02725 18.1455 4.02026 18.0833C4.01118 17.9659 4.00489 17.8506 4.00279 17.7339V0.960946C4.00295 0.786467 4.05055 0.615314 4.14048 0.465798C4.23041 0.316282 4.3593 0.194029 4.51336 0.112118C4.66742 0.0302079 4.84085 -0.00827756 5.01509 0.000778607C5.18934 0.00983478 5.35784 0.066092 5.50257 0.163532L8.44273 2.08404C8.45876 2.09726 8.47582 2.10918 8.49375 2.11968C8.8812 2.38425 9.19802 2.73962 9.41656 3.15478C9.63511 3.56994 9.74873 4.03227 9.74753 4.50144Z"
              fill={`url(#${linearGradient3Id})`}
            />
            <g opacity="0.149">
              <g opacity="0.149">
                <path
                  opacity="0.149"
                  d="M19.3689 17.8646C19.3689 18.0479 19.3521 18.2309 19.3186 18.4111C19.3088 18.4579 19.299 18.5054 19.2879 18.5509C19.2669 18.6347 19.2445 18.7158 19.218 18.7976C19.2033 18.8395 19.1886 18.8814 19.1725 18.922C19.1565 18.9625 19.1383 19.0044 19.1208 19.0464C19.0672 19.1693 19.0043 19.288 18.9328 19.4014C18.8408 19.5503 18.7355 19.6907 18.6183 19.8207C18.0816 20.4141 16.2596 21.4722 15.588 21.9027L14.0973 22.8112C13.005 23.4842 11.972 23.9608 10.6728 23.993C10.6113 23.993 10.5512 23.993 10.4911 23.993C10.4073 23.993 10.3241 23.993 10.2416 23.9881C9.18963 23.9476 8.16326 23.6518 7.25107 23.1262C6.33888 22.6006 5.56826 21.8609 5.00568 20.9711C4.48675 20.1541 4.15987 19.2301 4.04962 18.2685C4.12977 18.7157 4.31477 19.1375 4.58946 19.4994C4.86415 19.8613 5.2207 20.1529 5.62988 20.3503C6.03906 20.5478 6.48921 20.6454 6.94341 20.6352C7.39761 20.625 7.84294 20.5073 8.24286 20.2918L8.25264 20.2869L8.39871 20.1995L8.99205 19.8501L9.74753 19.4028V19.3818L9.84468 19.3238L16.6035 15.32L17.1234 15.0118L17.1752 15.0293H17.1814C17.3304 15.0665 17.4758 15.1166 17.6161 15.1788C17.9366 15.318 18.2293 15.5141 18.48 15.7575C18.5807 15.8536 18.6742 15.9571 18.7595 16.0671C18.8325 16.1596 18.899 16.2569 18.9587 16.3585C19.23 16.8138 19.3719 17.3346 19.3689 17.8646Z"
                  fill={`url(#${linearGradient4Id})`}
                />
              </g>
            </g>
            <g opacity="0.098">
              <g opacity="0.098">
                <path
                  opacity="0.098"
                  d="M9.74753 4.50144V19.4077L8.98925 19.8522L8.39521 20.2016L8.24915 20.2904C8.24576 20.2908 8.24259 20.2923 8.24006 20.2946C7.84019 20.5101 7.39492 20.6278 6.94076 20.6381C6.4866 20.6483 6.03648 20.5507 5.62732 20.3534C5.21815 20.156 4.86159 19.8645 4.58686 19.5027C4.31213 19.1409 4.12706 18.7192 4.04682 18.272C4.03564 18.2098 4.02725 18.1455 4.02026 18.0833C4.01118 17.9659 4.00489 17.8506 4.00279 17.7339V0.960946C4.00295 0.786467 4.05055 0.615314 4.14048 0.465798C4.23041 0.316282 4.3593 0.194029 4.51336 0.112118C4.66742 0.0302079 4.84085 -0.00827756 5.01509 0.000778607C5.18934 0.00983478 5.35784 0.066092 5.50257 0.163532L8.44273 2.08404C8.45876 2.09726 8.47582 2.10918 8.49375 2.11968C8.8812 2.38425 9.19802 2.73962 9.41656 3.15478C9.63511 3.56994 9.74873 4.03227 9.74753 4.50144Z"
                  fill={`url(#${linearGradient5Id})`}
                />
              </g>
            </g>
          </g>
          <defs>
            <linearGradient
              id={linearGradient1Id}
              x1="10.2212"
              y1="10.5978"
              x2="20.7471"
              y2="16.6809"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#37BDFF" />
              <stop offset="0.18" stopColor="#33BFFD" />
              <stop offset="0.36" stopColor="#28C5F5" />
              <stop offset="0.53" stopColor="#15D0E9" />
              <stop offset="0.55" stopColor="#12D1E7" />
              <stop offset="0.59" stopColor="#1CD2E5" />
              <stop offset="0.77" stopColor="#42D8DC" />
              <stop offset="0.91" stopColor="#59DBD6" />
              <stop offset="1" stopColor="#62DCD4" />
            </linearGradient>
            <linearGradient
              id={linearGradient2Id}
              x1="4.05263"
              y1="19.7225"
              x2="19.3801"
              y2="19.7225"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#39D2FF" />
              <stop offset="0.15" stopColor="#38CEFE" />
              <stop offset="0.29" stopColor="#35C3FA" />
              <stop offset="0.43" stopColor="#2FB0F3" />
              <stop offset="0.55" stopColor="#299AEB" />
              <stop offset="0.58" stopColor="#2692EC" />
              <stop offset="0.76" stopColor="#1A6CF1" />
              <stop offset="0.91" stopColor="#1355F4" />
              <stop offset="1" stopColor="#104CF5" />
            </linearGradient>
            <linearGradient
              id={linearGradient3Id}
              x1="6.88914"
              y1="21.0553"
              x2="6.88914"
              y2="0.567445"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#1B48EF" />
              <stop offset="0.12" stopColor="#1C51F0" />
              <stop offset="0.32" stopColor="#1E69F5" />
              <stop offset="0.57" stopColor="#2190FB" />
              <stop offset="1" stopColor="#26B8F4" />
            </linearGradient>
            <linearGradient
              id={linearGradient4Id}
              x1="7.09419"
              y1="23.4988"
              x2="16.5606"
              y2="14.0494"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="0.37" stopColor="#FDFDFD" />
              <stop offset="0.51" stopColor="#F6F6F6" />
              <stop offset="0.6" stopColor="#EBEBEB" />
              <stop offset="0.68" stopColor="#DADADA" />
              <stop offset="0.75" stopColor="#C4C4C4" />
              <stop offset="0.81" stopColor="#A8A8A8" />
              <stop offset="0.86" stopColor="#888888" />
              <stop offset="0.91" stopColor="#626262" />
              <stop offset="0.95" stopColor="#373737" />
              <stop offset="0.99" stopColor="#090909" />
              <stop offset="1" />
            </linearGradient>
            <linearGradient
              id={linearGradient5Id}
              x1="6.88914"
              y1="0.208609"
              x2="6.88914"
              y2="20.8502"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="0.37" stopColor="#FDFDFD" />
              <stop offset="0.51" stopColor="#F6F6F6" />
              <stop offset="0.6" stopColor="#EBEBEB" />
              <stop offset="0.68" stopColor="#DADADA" />
              <stop offset="0.75" stopColor="#C4C4C4" />
              <stop offset="0.81" stopColor="#A8A8A8" />
              <stop offset="0.86" stopColor="#888888" />
              <stop offset="0.91" stopColor="#626262" />
              <stop offset="0.95" stopColor="#373737" />
              <stop offset="0.99" stopColor="#090909" />
              <stop offset="1" />
            </linearGradient>
            <clipPath id={clipPathId}>
              <rect
                width="16.3536"
                height="24"
                fill="white"
                transform="translate(4)"
              />
            </clipPath>
          </defs>
        </svg>
      );
  }
};
