export type Channel = { name: string; code: string };

const draftOrdersChannel: Channel = {
  name: "Draft Orders",
  code: "shopify_draft_order"
};
export const facebookChannel: Channel = {
  name: "Facebook",
  code: "2329312"
};
const gorgiasChannel: Channel = {
  name: "Gorgias",
  code: "1424624"
};
const happyReturns2Channel: Channel = {
  name: "Happy Returns 2",
  code: "exchange"
};
const loopReturnsChannel: Channel = {
  name: "Loop Returns",
  code: "1662707"
};
const matrixifyAppChannel: Channel = {
  name: "Matrixify App",
  code: "Matrixify App"
};
const pointOfSaleChannel: Channel = {
  name: "Point of Sale",
  code: "pos"
};
export const reChargeChannel: Channel = {
  name: "ReCharge",
  code: "294517"
};
export const unifiedCheckoutChannel: Channel = {
  name: "Unified Checkout",
  code: "subscription_contract"
};
const returnlyExchangesChannel: Channel = {
  name: "Returnly Returns",
  code: "Returnly Exchanges"
};
const webChannel: Channel = {
  name: "Web",
  code: "web"
};

export const userFacingPredefinedChannels: Array<Channel> = [
  { name: "Amazon by Codisto", code: "amazon" },
  { name: "Amazon by Codisto - US", code: "amazon-us" },
  { name: "Bold Subscription", code: "457101" },
  { name: "Borderfree", code: "1605585" },
  { name: "Cleverific Edit Order", code: "Edit Order" },
  { name: "Convictional", code: "1848011" },
  draftOrdersChannel,
  { name: "eShopWorld", code: "2984603" },
  { name: "Etsy Marketplace Integration", code: "2438651" },
  facebookChannel,
  { name: "Faire", code: "faire" },
  { name: "Flow", code: "flow" },
  { name: "Global-E", code: "2893285" },
  gorgiasChannel,
  { name: "Grin (Influencer)", code: "2376822" },
  happyReturns2Channel,
  { name: "Honeycomb", code: "Honeycomb" },
  { name: "Indigo8", code: "Iconic_Order_SC2162437122" },
  { name: "Leap Services", code: "18191b06c07b47afabb71b0eca90f6bc" },
  loopReturnsChannel,
  matrixifyAppChannel,
  { name: "Narvar", code: "3070771" },
  { name: "OpenBorder", code: "63627558913" },
  pointOfSaleChannel,
  { name: "Refundid", code: "4200331" },
  { name: "Reamaze", code: "188741" },
  returnlyExchangesChannel,
  { name: "Shop", code: "3890849" },
  { name: "Shopify Collective", code: "shopify-collective-automatic-payments" },
  { name: "Shopify Mobile", code: "iphone" },
  { name: "Sticky.io", code: "4092473" },
  { name: "Tapcart", code: "1520611" },
  { name: "TikTok", code: "tiktok" },
  { name: "Upfluence", code: "38759497729" },
  { name: "Walmart Marketplace", code: "Walmart.com" },
  webChannel,
  { name: "Zapier", code: "116649" }
];

export const allPredefinedChannels = [
  ...userFacingPredefinedChannels,
  reChargeChannel,
  unifiedCheckoutChannel
];

export const generalBestPracticeChannelsToBlock = [
  returnlyExchangesChannel,
  happyReturns2Channel,
  loopReturnsChannel,
  matrixifyAppChannel
];

export const popularPredefinedChannelsToBlock = [
  draftOrdersChannel,
  pointOfSaleChannel,
  gorgiasChannel
];

export const generalBestPracticeChannelsToAllow = [webChannel];

export const popularPredefinedChannelsToAllow = [webChannel];
