type IconRefreshProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconRefresh: React.FC<IconRefreshProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M23 4V10H17"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 20V14H7"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.51 9.00001C4.01717 7.5668 4.87913 6.28542 6.01547 5.27543C7.1518 4.26545 8.52547 3.55978 10.0083 3.22427C11.4911 2.88877 13.0348 2.93436 14.4952 3.35679C15.9556 3.77922 17.2853 4.56473 18.36 5.64001L23 10M1 14L5.64 18.36C6.71475 19.4353 8.04437 20.2208 9.50481 20.6432C10.9652 21.0657 12.5089 21.1113 13.9917 20.7758C15.4745 20.4402 16.8482 19.7346 17.9845 18.7246C19.1209 17.7146 19.9828 16.4332 20.49 15"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.3334 2.66666V6.66666H11.3334"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.666626 13.3333V9.33334H4.66663"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.33996 6C2.67807 5.04453 3.25271 4.19027 4.01027 3.51695C4.76783 2.84362 5.6836 2.37318 6.67215 2.14951C7.6607 1.92584 8.6898 1.95623 9.66342 2.23785C10.637 2.51947 11.5235 3.04314 12.24 3.76L15.3333 6.66667M0.666626 9.33334L3.75996 12.24C4.47646 12.9569 5.36287 13.4805 6.3365 13.7622C7.31012 14.0438 8.33922 14.0742 9.32777 13.8505C10.3163 13.6268 11.2321 13.1564 11.9896 12.4831C12.7472 11.8097 13.3218 10.9555 13.66 10"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
