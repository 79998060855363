type IconNorthbeamProps = {
  size: "24px";
  className?: string;
};

export const IconNorthbeam: React.FC<IconNorthbeamProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M23.9788 5H22.327L18.0892 13.4756L16.5591 11.9328L20.0198 5.01132L19.9986 5H18.3468L15.4423 10.8075L14.1826 9.53786L16.4473 5.01132L16.426 5H14.7742L11.7339 11.0807L9.72824 9.05945H8.80255L7.91083 10.8287L6.15428 9.05945H5.22859L0 19.4402H1.68153L5.94338 10.9802L7.19745 12.2442L3.57254 19.4402H5.25407L8.31281 13.368L9.83723 14.9052L7.55273 19.4402H9.23425L12.1557 13.6398L13.9788 15.4756H14.787L15.85 13.3496L17.9604 15.4756H18.7686L24 5.01132L23.9788 5ZM10.2661 13.4756L9.02619 12.2258L9.65464 10.9788L10.8917 12.2243L10.2675 13.4742L10.2661 13.4756ZM14.109 13.4756L12.8691 12.2258L13.4975 10.9788L14.7346 12.2243L14.1104 13.4742L14.109 13.4756Z"
            fill="#367BF3"
          />
        </svg>
      );
  }
};
