type IconTaboolaProps = {
  size: "24px";
  className?: string;
};

export const IconTaboola: React.FC<IconTaboolaProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5936 6.26823C15.892 6.2575 15.6548 7.91406 15.6484 9.16291C15.6398 10.4107 15.8576 12.0919 17.5603 12.1016C19.2609 12.1123 19.4991 10.4332 19.5066 9.18437C19.513 7.93659 19.2952 6.27788 17.5936 6.26823ZM17.5399 15.3707C14.7744 15.354 12.9636 14.1998 11.9992 12.5388C11.0248 14.2237 9.18978 15.387 6.38713 15.3707C1.96035 15.345 -0.0202294 12.4052 0.000155699 9.13716C0.0194679 5.86803 2.03223 2.97549 6.45902 3.00231C9.2249 3.01773 11.0358 4.16193 12.0007 5.81573C12.9748 4.13764 14.81 2.98387 17.6118 3.00017C22.0375 3.02592 24.0181 5.94206 23.9999 9.21012C23.9806 12.4803 21.9667 15.3954 17.5399 15.3707ZM6.44078 6.2693C4.73809 6.25857 4.50098 7.91513 4.49346 9.16291C4.48703 10.4107 4.70375 12.093 6.40537 12.1027C8.107 12.1123 8.34518 10.4343 8.35269 9.18437C8.3602 7.93659 8.1424 6.27895 6.44078 6.2693ZM11.7956 17.4511C8.18747 17.4307 4.8765 16.7977 1.68569 15.3718L1.66209 19.2986C5.17047 20.9541 7.93426 21.7416 11.7699 21.7641C15.289 21.7845 18.821 20.9133 22.0772 19.2986L22.0987 15.3718C18.4605 16.758 15.5862 17.4736 11.7956 17.4511Z"
            fill="#054164"
          />
        </svg>
      );
  }
};
