import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { destinationBing as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-bing-as-server-side-destination";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationBingProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationBing: React.FC<DestinationBingProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Microsoft Advertising Settings" },
          { name: "Events" },
          { name: "Consent Mode" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" }
        ]}
        context={{ setSaveModalState, shopifyOAuthScopes }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
  }
};

/* ========================================================================== */

const recommendedEvents: ReturnType<typeof useConfig>["enabledEvents"] = {
  addPaymentInfo: false,
  addToCart: false,
  beginCheckout: false,
  pageView: false,
  purchase: true,
  viewItem: false,
  newCustomerPurchase: false,
  returningCustomerPurchase: false
};

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () => (
                  <>
                    The UET ID used is <span>{config.pixelId}</span>
                  </>
                )
              },
              {
                step: 2,
                type: "EVENTS",
                selectedEvents: {
                  enabledEvents: config.enabledEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                },
                recommendedEvents: getRecommendedEventState({
                  destination,
                  enabledEvents: recommendedEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                })
              },
              {
                step: 3,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 4,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 5,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for your data by configuring this
          server-side integration.
        </Step0Explainer>
      }
      whatsNextOverrides={{
        link: {
          href: "https://docs.getelevar.com/docs/how-to-set-up-bing-as-server-side-destination#Step-9-Remove-Previous-Tracking"
        }
      }}
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  const [isLoading, setIsLoading] = useState(false);
  const [pixelId, setPixelId] = useState(config.pixelId);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areMicrosoftAdvertisingFieldsFilledIn = pixelId !== "";

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Microsoft Advertising Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your data to this destination, we need the
              following information from your account.
            </Step1Explainer>
          }
        >
          <div ref={inputWrapperRef}>
            <InputWrapper
              labelText="UET ID"
              disabled={isLoading}
              tooltip={{
                maxWidth: `${theme.gridBase * 38}px`,
                render: () => (
                  <Step1InputWrapperTooltipContent>
                    <p>
                      This is a unique identifier for your Microsoft Advertising
                      account. Find your UET ID inside your ads resource center.
                    </p>
                    <StyledLinkExternal
                      href="https://docs.getelevar.com/docs/how-to-find-bingmicrosoft-account-uet-id"
                      text="How do I find this?"
                    />
                  </Step1InputWrapperTooltipContent>
                )
              }}
            >
              <InputFieldText
                variant="SMALL"
                disabled={isLoading}
                value={pixelId}
                onChange={event => setPixelId(event.target.value)}
                spellCheck={false}
                autoCapitalize="off"
                placeholder="12345"
              />
            </InputWrapper>
          </div>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <InputWrapper labelText="Conversion Value" disabled={isLoading}>
              <InputFieldSelect
                variant="SMALL"
                disabled={isLoading}
                value={selectedConversionValueOption}
                setValue={option => setConversionValue(option.value)}
                options={conversionValueOptions}
                placeholder="Select a Conversion Value"
              />
            </InputWrapper>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areMicrosoftAdvertisingFieldsFilledIn
              ? "All fields are required"
              : null
          }
          onSave={async data => {
            const action = async () => {
              setIsLoading(true);
              await configMutation({
                ...data,
                pixelId,
                dataConfig: { conversionValue },
                ...(!isStepCompleted ? { completedStep: 1 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
              setIsLoading(false);
            };

            if (config.live) {
              setSaveModalState({ isVisible: true, onConfirm: action });
            } else {
              await action();
            }
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1InputWrapperTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, shopifyOAuthScopes }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [eventMapping, setEventMapping] = useState(config.eventMapping);
  const [eventCategories, setEventCategories] = useState(
    config.eventCategories
  );

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Microsoft Advertising.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={isLoading}
        isStepCompleted={isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        showBreakdownByDefault={true}
        details={{
          eventState,
          setEventState,
          destinationName: destination.name,
          eventMapping,
          setEventMapping,
          eventCategories,
          setEventCategories
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async () => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...eventState,
              eventMapping,
              eventCategories,
              ...(!isStepCompleted ? { completedStep: 2 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 5 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};
