type IconSteelHouseProps = {
  size: "24px";
  className?: string;
};

export const IconSteelHouse: React.FC<IconSteelHouseProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.304 8.304H20.448V20.536H3.464V18.072H18.336V10.768H8.304V8.304ZM3.464 3.376H20.448V5.84H5.75199V13.144H15.784V15.608H3.464V3.376ZM1 23H23V1H1V23Z"
            fill="#E4002B"
          />
        </svg>
      );
  }
};
