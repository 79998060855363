import { useState } from "react";
import styled from "styled-components";

import { Modal } from "./Modal";

/* ========================================================================== */

type ImageLightboxProps = {
  children: React.ReactNode;
};

export const ImageLightbox: React.FC<ImageLightboxProps> = ({ children }) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <button onClick={() => setIsModalShown(true)}>{children}</button>
      <Modal isVisible={isModalShown} onClose={() => setIsModalShown(false)}>
        <ImageWrapper>{children}</ImageWrapper>
      </Modal>
    </>
  );
};

/* ========================================================================== */

const ImageWrapper = styled.div`
  margin: ${props => props.theme.gridBase * -4}px;

  > img {
    height: auto;
    width: auto;
    max-height: 90vh;
    max-width: 90vw;
  }
`;
