import styled from "styled-components";

import { IconExternalLink } from "../icons";
import { normalTextStyles } from "../typography/typography";
import { linkStyles } from "./links";

/* ========================================================================== */

export const LinkExternal = styled.a.attrs(props => ({
  rel: props.href?.startsWith("#") ? undefined : "noreferrer noopener",
  target: props.href?.startsWith("#") ? undefined : "_blank"
}))``;

/* ========================================================================== */

type StyledLinkExternalProps = {
  href: string;
  text: string;
  onClick?: () => void;
  className?: string;
};

export const StyledLinkExternal: React.FC<StyledLinkExternalProps> = ({
  href,
  onClick,
  text,
  className
}) => {
  return (
    <StyledLinkExternalInner
      href={href}
      onClick={onClick}
      className={className}
    >
      <div>{text}</div>
      <div>
        <IconExternalLink size="16px" />
      </div>
    </StyledLinkExternalInner>
  );
};

const StyledLinkExternalInner = styled(LinkExternal)`
  ${normalTextStyles};
  ${linkStyles};
  display: flex;
  align-items: center;
  width: max-content;
  white-space: nowrap;

  > div:first-child {
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;
