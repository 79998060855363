import Cleave from "cleave.js/react";
import styled from "styled-components";

import {
  type InputFieldProps,
  inputFieldStyles
} from "elevar-design-system/src/inputs/sharedInputStyles";

/* ========================================================================== */

type InputFieldDestinationNameProps = InputFieldProps & {
  type: never; // Stops the component user from passing this prop.
};

export const InputFieldDestinationName = styled(Cleave)
  .withConfig({
    shouldForwardProp: prop => !["variant", "error"].includes(prop)
  })
  .attrs<
    React.ComponentPropsWithRef<typeof Cleave>,
    React.ComponentPropsWithRef<typeof Cleave>
  >(props => ({
    maxLength: props.maxLength,
    options: {
      prefix: props.prefix,
      blocks: [props.prefix?.length ?? 0, 20],
      delimiterLazyShow: true,
      delimiter: " - ",
      rawValueTrimPrefix: true,
      noImmediatePrefix: true
    }
  }))<InputFieldDestinationNameProps>`
  ${inputFieldStyles}
`;
