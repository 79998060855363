import styled from "styled-components";

import { IconChevronRight } from "elevar-design-system/src/icons";
import { normalTextStyles } from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type BannerProps = {
  textColor: string;
  backgroundColor: string;
  borderColor?: string;
  primaryText: string;
  secondaryText?: string;
  actionText?: string;
};

export const Banner: React.FC<BannerProps> = ({
  textColor,
  backgroundColor,
  borderColor,
  primaryText,
  secondaryText,
  actionText
}) => {
  return (
    <Wrapper
      textColor={textColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
    >
      <div>
        <PrimaryText>{primaryText}</PrimaryText>
        {secondaryText ? <SecondaryText>{secondaryText}</SecondaryText> : null}
      </div>
      {actionText ? (
        <div>
          <ActionText>{actionText}</ActionText>
          <ActionIconWrapper>
            <IconChevronRight size="24px" />
          </ActionIconWrapper>
        </div>
      ) : null}
    </Wrapper>
  );
};

/* ========================================================================== */

type WrapperProps = {
  textColor: string;
  backgroundColor: string;
  borderColor?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
  border-radius: 4px;
  background: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  border: 1px solid ${props => props.borderColor ?? props.backgroundColor};

  > div {
    display: flex;
    align-items: center;
  }
`;

const PrimaryText = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  color: currentColor;
  white-space: nowrap;
`;

const SecondaryText = styled.div`
  ${normalTextStyles};
  color: currentColor;
  margin-left: ${props => props.theme.gridBase * 1.5}px;
`;

const ActionText = styled.div`
  ${normalTextStyles};
  color: currentColor;
  white-space: nowrap;
  margin-left: ${props => props.theme.gridBase * 2}px;
`;

const ActionIconWrapper = styled.div`
  display: flex;
  margin-left: ${props => props.theme.gridBase}px;
`;
