import { sortBy } from "lodash-es";
import { transparentize } from "polished";
import { useState } from "react";
import { Link, Prompt, useHistory } from "react-router-dom";
import styled from "styled-components";

import { Avatar } from "elevar-design-system/src/Avatar";
import {
  ButtonPrimary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type CompanyMember,
  type CompanyWebsites,
  useCompanyMembersQuery,
  useCompanyWebsitesQuery
} from "../../../api/handlers/company";
import {
  useWebsiteDeleteMutation,
  useWebsiteDetailsMutation
} from "../../../api/handlers/website";
import { ActionWarningModal } from "../../../components/ActionWarningModal";
import { PageCard } from "../../../components/PageCard";
import { useCompanyId, useWebsiteId } from "../../../utils/idHooks";
import { toast } from "../../../utils/toast";
import { isValidWebsiteName } from "../../../utils/validate";

/* ========================================================================== */

export const WebsiteInfo: React.FC = () => {
  const websiteId = useWebsiteId();

  const companyWebsites = useCompanyWebsitesQuery();
  const companyMembers = useCompanyMembersQuery();

  if (companyWebsites.error !== null || companyMembers.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (companyWebsites.data === undefined || companyMembers.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  /**
   * NOTE:
   * This check is needed in the case where a Website is deleted, as
   * there is a time between when it is deleted and when the user is
   * redirected that the component still expects the website data to
   * exist in `companyWebsites`. The reason we render a loading screen
   * when it doesn't exist is because this will only happen during the
   * transition to the root route, so rendering an error state would
   * result in bad UX.
   *
   * If there is a better way to prevent the app from erroring without
   * having to do this check, please feel free to make that change.
   */

  const website = companyWebsites.data.find(w => w.id === websiteId);

  if (!website) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <WebsiteInfoContents
      website={website}
      companyMembers={companyMembers.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type WebsiteInfoContentsProps = {
  website: CompanyWebsites[number];
  companyMembers: Array<CompanyMember>;
};

const WebsiteInfoContents: React.FC<WebsiteInfoContentsProps> = ({
  website,
  companyMembers
}) => {
  const history = useHistory();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const { mutateAsync: websiteDeleteMutation } = useWebsiteDeleteMutation();
  const { mutateAsync: websiteDetailsMutation } = useWebsiteDetailsMutation();

  const [websiteName, setWebsiteName] = useState(website.name);
  const [isWebsiteFormLoading, setIsWebsiteFormLoading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const isWebsiteFormDirty = websiteName !== website.name;

  const websiteMembers = companyMembers.filter(member =>
    member.websites.includes(websiteId)
  );

  const orderedWebsiteMembers = sortBy(websiteMembers, [
    member => member.first_name,
    member => member.last_name
  ]);

  const isWebsiteCancellable = website.permissions.includes("CANCELLABLE");
  const isWebsiteOnFreePlan = website.plan.id === "DEFAULT_FREE_PLAN";

  const companyUrl = `/company/${companyId}`;
  const companyTeamMembersUrl = `${companyUrl}/settings/team`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const websitePlanUrl = `${websiteUrl}/settings/plan`;

  return (
    <>
      <WebsiteInfoWrapper>
        <PageHeading>Website Info</PageHeading>
        <WebsiteDetailsPageCard>
          <WebsiteInfoInputWrapper
            labelText="Website Name"
            disabled={isWebsiteFormLoading}
          >
            <InputFieldText
              variant="SMALL"
              disabled={isWebsiteFormLoading}
              value={websiteName}
              onChange={event => setWebsiteName(event.target.value)}
              spellCheck={false}
            />
          </WebsiteInfoInputWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={
              isWebsiteFormLoading
                ? "LOADING"
                : !isWebsiteFormDirty || !isValidWebsiteName(websiteName)
                  ? "DISABLED"
                  : "IDLE"
            }
            onClick={async () => {
              setIsWebsiteFormLoading(true);
              const trimmedWebsiteName = websiteName.trim();
              await websiteDetailsMutation({ name: trimmedWebsiteName });
              setWebsiteName(trimmedWebsiteName);
              toast.success("Website updated");
              setIsWebsiteFormLoading(false);
            }}
          >
            Save Changes
          </ButtonPrimary>
        </WebsiteDetailsPageCard>
        <PageCardHeading>Website Members</PageCardHeading>
        <WebsiteMembersPageCard>
          {orderedWebsiteMembers.map(member => (
            <WebsiteMemberWrapper key={member.id}>
              <Avatar
                type="USER"
                size="MEDIUM"
                imageSrc={member.picture?.file}
              />
              <WebsiteMemberName>
                {member.first_name} {member.last_name}
              </WebsiteMemberName>
            </WebsiteMemberWrapper>
          ))}
        </WebsiteMembersPageCard>
        <WebsiteMembersNotice>
          Note: Team Members are managed in{" "}
          <WebsiteMembersNoticeLink to={companyTeamMembersUrl}>
            Company Settings
          </WebsiteMembersNoticeLink>
        </WebsiteMembersNotice>
        {!isWebsiteCancellable ? (
          <WebsiteInfoWarningCard>
            Website cannot be deleted automatically due to the plan it is on.
            Please reach out to our support team if you are sure you want to
            delete this Website.
          </WebsiteInfoWarningCard>
        ) : !isWebsiteOnFreePlan ? (
          <WebsiteInfoWarningCard>
            Website is on a paid plan. You must cancel the plan before you can
            delete this Website.{" "}
            <Link to={websitePlanUrl}>Click here to cancel plan</Link>.
          </WebsiteInfoWarningCard>
        ) : null}
        <ButtonWarning
          variant="SMALL"
          state={
            isWebsiteCancellable && isWebsiteOnFreePlan ? "IDLE" : "DISABLED"
          }
          onClick={() => setIsModalShown(true)}
        >
          Delete Website
        </ButtonWarning>
      </WebsiteInfoWrapper>
      {isWebsiteCancellable && isWebsiteOnFreePlan ? (
        <ActionWarningModal
          isVisible={isModalShown}
          onClose={() => setIsModalShown(false)}
          isLoading={isModalLoading}
          subheading={website.name}
          heading="Are you sure you want to delete this Website?"
          text="By deleting this Website you're accepting the following:"
          checkBoxItems={[
            "All Website data will be deleted",
            "All Team Members will lose access to this Website",
            "All GTM Event Data will be deleted from Elevar"
          ]}
          confirmActionText="Delete Website"
          onConfirmAction={async () => {
            setIsModalLoading(true);
            await websiteDeleteMutation();
            history.push("/");
            toast.success("Website deleted");
          }}
          cancelActionText="Don't Delete"
        />
      ) : null}
      <Prompt
        when={isWebsiteFormDirty}
        message={
          "You made some changes that are unsaved. " +
          "Are you sure you want to navigate away?"
        }
      />
    </>
  );
};

const WebsiteInfoWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 4}px;
  max-width: ${props => props.theme.gridBase * 70.5}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const WebsiteDetailsPageCard = styled(PageCard)`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const WebsiteInfoInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageCardHeading = styled.h2`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const WebsiteMembersPageCard = styled(PageCard)`
  padding: 0;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const WebsiteMemberWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.gridBase * 3}px;

  &:not(:first-child) {
    border-top: 2px solid ${props => props.theme.palette.grey8};
  }
`;

const WebsiteMemberName = styled.div`
  ${normalTextStyles};
  margin-left: ${props => props.theme.gridBase * 2}px;
`;

const WebsiteMembersNotice = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const WebsiteMembersNoticeLink = styled(Link)`
  ${smallTextStyles};
  ${linkStyles};
`;

const WebsiteInfoWarningCard = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.red1};
  background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
  border-radius: 4px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > a {
    font-weight: 500;
  }
`;
