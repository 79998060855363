type IconPepperjamProps = {
  size: "24px";
  className?: string;
};

export const IconPepperjam: React.FC<IconPepperjamProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M20.4822 3.51162C18.933 1.96356 16.9897 0.868978 14.8628 0.346491C12.736 -0.175995 10.5067 -0.106464 8.41651 0.54755C6.32635 1.20156 4.45503 2.41512 3.00532 4.05672C1.55561 5.69832 0.582799 7.70536 0.192297 9.86035C-0.198204 12.0154 0.00849673 14.2361 0.790002 16.282C1.57151 18.328 2.89801 20.121 4.62578 21.4668C6.35356 22.8127 8.4167 23.66 10.5917 23.9171C12.7666 24.1741 14.9704 23.831 16.9643 22.925C17.1574 22.837 17.3077 22.6758 17.382 22.477C17.4563 22.2782 17.4485 22.0581 17.3605 21.865C17.2725 21.6719 17.1113 21.5216 16.9125 21.4473C16.7137 21.373 16.4936 21.3808 16.3005 21.4688C14.9478 22.0862 13.4773 22.403 11.9903 22.3974C9.62055 22.3926 7.32371 21.5775 5.48102 20.0875C3.63832 18.5975 2.36057 16.5221 1.85985 14.2058C1.35914 11.8896 1.66557 9.47177 2.72828 7.35367C3.791 5.23557 5.54608 3.54457 7.70223 2.56135C9.85839 1.57814 12.2859 1.36182 14.582 1.94832C16.878 2.53482 18.9044 3.88885 20.3249 5.78568C21.7454 7.68251 22.4745 10.0081 22.3912 12.3763C22.3079 14.7446 21.4172 17.0132 19.867 18.8056L12.3251 5.71928C12.2726 5.62844 12.2026 5.54886 12.1192 5.4851C12.0358 5.42134 11.9407 5.37465 11.8392 5.34771C11.7378 5.32076 11.632 5.31409 11.528 5.32808C11.424 5.34206 11.3237 5.37643 11.233 5.42921C11.1057 5.50079 11.0012 5.6067 10.9312 5.73485L5.05778 16.3701C4.9618 16.5554 4.9418 16.7708 5.00205 16.9706C5.06229 17.1704 5.19803 17.3389 5.38045 17.4402C5.56287 17.5416 5.7776 17.5678 5.97906 17.5134C6.18053 17.459 6.35286 17.3283 6.45947 17.1489L11.6457 7.74588L18.9987 20.5324C19.1043 20.7159 19.2783 20.8501 19.4827 20.9056C19.687 20.9611 19.905 20.9333 20.0889 20.8283C20.1487 20.7934 20.2037 20.7508 20.2525 20.7018C21.395 19.6168 22.3126 18.3173 22.953 16.8777C23.5934 15.4381 23.9439 13.8864 23.9846 12.3113C24.0252 10.7362 23.7553 9.16849 23.1901 7.69772C22.6249 6.22695 21.7755 4.88191 20.6905 3.73939C20.6185 3.66347 20.5464 3.58949 20.4724 3.51551L20.4822 3.51162Z"
            fill="#212322"
          />
          <path
            d="M11.7236 15.0035C12.6214 15.0035 13.3491 14.2757 13.3491 13.3779C13.3491 12.4802 12.6214 11.7524 11.7236 11.7524C10.8258 11.7524 10.098 12.4802 10.098 13.3779C10.098 14.2757 10.8258 15.0035 11.7236 15.0035Z"
            fill="#3182F2"
          />
        </svg>
      );
  }
};
