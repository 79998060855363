type IconPlusProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconPlus: React.FC<IconPlusProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M12 4V20" stroke={color} strokeLinecap="round" />
          <path d="M20 12L4 12" stroke={color} strokeLinecap="round" />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path d="M8 12V4" stroke={color} strokeLinecap="round" />
          <path d="M4 8H12" stroke={color} strokeLinecap="round" />
        </svg>
      );
  }
};
