import styled from "styled-components";

import { CheckBox, type CheckBoxVariant } from "../CheckBox";
import { linkStyles } from "../links/links";
import { normalTextStyles } from "../typography/typography";
import { LabeledCheckBox } from "./LabeledCheckBox";

/* ========================================================================== */

type LabeledCheckBoxSingleProps = {
  variant: CheckBoxVariant;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  isDisabled?: boolean;
  children: React.ReactNode;
};

export const LabeledCheckBoxSingle: React.FC<LabeledCheckBoxSingleProps> = ({
  variant,
  isChecked,
  setIsChecked,
  isDisabled,
  children
}) => {
  return (
    <StyledLabeledCheckBox
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      isDisabled={isDisabled}
    >
      {({ isHovered, isFocused }) => (
        <>
          <CheckBox
            variant={variant}
            isChecked={isChecked}
            isHovered={isHovered}
            isFocused={isFocused}
          />
          <LabelChildren>{children}</LabelChildren>
        </>
      )}
    </StyledLabeledCheckBox>
  );
};

/* ========================================================================== */

const StyledLabeledCheckBox = styled(LabeledCheckBox)`
  padding-top: ${props => props.theme.gridBase * 0.75}px;
  padding-bottom: ${props => props.theme.gridBase * 0.75}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase}px;
`;

const LabelChildren = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-left: ${props => props.theme.gridBase}px;

  > a {
    ${linkStyles};
  }
`;
