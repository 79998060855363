import styled from "styled-components";

import { normalTextStyles } from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type StatusProps = {
  reduceHeight?: boolean;
  textColor: string;
  backgroundColor: string;
  icon?: React.ReactElement;
  text: string;
};

export const Status: React.FC<StatusProps> = ({
  reduceHeight = false,
  textColor,
  backgroundColor,
  icon,
  text
}) => {
  return (
    <Wrapper
      reduceHeight={reduceHeight}
      textColor={textColor}
      backgroundColor={backgroundColor}
      iconExists={Boolean(icon)}
    >
      {icon ? <div>{icon}</div> : null}
      <div>{text}</div>
    </Wrapper>
  );
};

/* ========================================================================== */

type WrapperProps = {
  reduceHeight: boolean;
  textColor: string;
  backgroundColor: string;
  iconExists: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 0.75}px;
  border-radius: 4px;
  width: max-content;
  user-select: none;
  background-color: ${props => props.backgroundColor};
  padding-top: ${props =>
    props.reduceHeight
      ? props.theme.gridBase * 0.25
      : props.theme.gridBase * 0.5}px;
  padding-bottom: ${props =>
    props.reduceHeight
      ? props.theme.gridBase * 0.25
      : props.theme.gridBase * 0.5}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props =>
    props.iconExists ? props.theme.gridBase * 1.25 : props.theme.gridBase}px;

  > div:not(:last-child) {
    display: flex;
    color: ${props => props.textColor};
  }

  > div:last-child {
    ${normalTextStyles};
    color: ${props => props.textColor};
    font-weight: 500;
  }
`;
