import { useTheme } from "styled-components";

/* ========================================================================== */

export const useGraphTheme = () => {
  const theme = useTheme();

  return {
    grid: { line: { stroke: theme.palette.grey6 } },
    axis: {
      domain: { line: { stroke: theme.palette.grey6 } },
      ticks: {
        text: {
          fontFamily: '"Elevar_Inter", Helvetica, Arial, sans-serif',
          fontFeatureSettings: '"cv06" 1, "tnum" 1',
          fontWeight: 400,
          letterSpacing: 0,
          fontSize: theme.fontBase * 0.75,
          lineHeight: theme.gridBase * 2,
          fill: theme.palette.grey3
        }
      },
      legend: {
        text: {
          fontFamily: '"Elevar_Inter", Helvetica, Arial, sans-serif',
          fontFeatureSettings: '"cv06" 1, "tnum" 1',
          fontWeight: 400,
          letterSpacing: 0,
          fontSize: theme.fontBase * 0.75,
          lineHeight: theme.gridBase * 2,
          fill: theme.palette.grey3
        }
      }
    },
    tooltip: {
      container: {
        boxShadow: "none",
        padding: 0
      }
    }
  };
};
