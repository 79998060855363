import { uniq } from "lodash-es";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { destinationIr as destination } from "./data";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-impact-radius-in-elevar-server-side-destination";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationIrProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
};

export const DestinationIr: React.FC<DestinationIrProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Impact Radius Settings" },
          { name: "Consent Mode" },
          { name: "Transaction Identifier" },
          { name: "Product Identifier" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" }
        ]}
        context={{ setSaveModalState }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
  }
};

/* ========================================================================== */

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () => (
                  <>
                    The Impact Radius Account ID used is{" "}
                    <span>{config.accountSID}</span>
                  </>
                )
              },
              {
                step: 2,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 3,
                type: "TRANSACTION_IDENTIFIER",
                value: config.dataConfig.orderAttributeId
              },
              {
                step: 4,
                type: "PRODUCT_IDENTIFIER",
                value: config.dataConfig.productAttributeMapping
              },
              {
                step: 5,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 6,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for Impact Radius transactions by
          configuring this server-side integration.
        </Step0Explainer>
      }
      integrationNotes={
        <div>
          By setting up this destination, you are confirming that you have
          discussed enabling this integration with your Impact Radius Account
          representative (and that they will be monitoring orders), and that you
          have removed any existing Impact Radius pixels if they exist (or that
          you plan to run them in parallel until your Impact Radius account
          representative has confirmed that things are working correctly).
        </div>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  const [isLoading, setIsLoading] = useState(false);
  const [accountId, setAccountId] = useState(config.accountSID);
  const [authToken, setAuthToken] = useState(config.authToken);
  const [campaignId, setCampaignId] = useState(config.campaignId);
  const [actionTrackerId, setActionTrackerId] = useState(
    config.actionTrackerId
  );

  const areImpactRadiusFieldsFilledIn =
    accountId !== "" &&
    authToken !== "" &&
    campaignId !== "" &&
    actionTrackerId !== "";
  const areImpactRadiusFieldsUnique =
    uniq([accountId, authToken, campaignId, actionTrackerId]).length === 4;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Impact Radius Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              Please enter all four values from your Impact Tech Plan below.
              Once you are done, save & continue to review before going live.
            </Step1Explainer>
          }
        >
          <Step1SectionInnerWrapper>
            <div>
              <InputWrapper
                labelText="Account SID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This value is required. You can find this in your
                      Conversion API Tech Plan provided by your Impact Customer
                      Success Manager.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={accountId}
                  onChange={event => setAccountId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Auth Token"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This value is required. You can find this in your
                      Conversion API Tech Plan provided by your Impact Customer
                      Success Manager.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={authToken}
                  onChange={event => setAuthToken(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Campaign ID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This value is required. You can find this in your
                      Conversion API Tech Plan provided by your Impact Customer
                      Success Manager.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={campaignId}
                  onChange={event => setCampaignId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Action Tracker ID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 40}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This value is required. You can find this in your
                      Conversion API Tech Plan provided by your Impact Customer
                      Success Manager.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={actionTrackerId}
                  onChange={event => setActionTrackerId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areImpactRadiusFieldsFilledIn
              ? "All fields are required"
              : !areImpactRadiusFieldsUnique
                ? "All fields must be unique"
                : null
          }
          onSave={async data => {
            const action = async () => {
              setIsLoading(true);
              await configMutation({
                ...data,
                accountSID: accountId,
                authToken,
                campaignId,
                actionTrackerId,
                ...(!isStepCompleted ? { completedStep: 1 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
              setIsLoading(false);
            };

            if (config.live) {
              setSaveModalState({ isVisible: true, onConfirm: action });
            } else {
              await action();
            }
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 2 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <TransactionIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["order_number", "name", "id"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { orderAttributeId },
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <ProductIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["sku", "product_id", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { productAttributeMapping },
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 5 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 6;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 6 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};
