import styled, { useTheme } from "styled-components";

import { IconHelp } from "../icons";
import { Tooltip, TooltipBig } from "../Tooltip";
import { normalTextStyles } from "../typography/typography";

/* ========================================================================== */

/**
 * This type allow one to ensure that the entire object type (`T`) is
 * passed (so no properties are left out on the object we are typing),
 * or none of the properties are passed at all.
 *
 * This is useful because it means that we can create component prop
 * types where a group of props are optional *together*, but we don't
 * have to consolidate these optionals into a single prop to achieve
 * this. Instead, TypeScript will error out if only some of the object
 * type properties are passed, but it won't if none of them are passed
 * or all of them are passed.
 */

type OptionalGroup<T extends Record<string, unknown>> =
  | T
  | { [K in keyof T]?: undefined };

/* ========================================================================== */

type InputWrapperTooltipOptions = {
  maxWidth?: string;
} & ({ text: string } | { render: () => React.ReactNode });

type InputWrapperProps = {
  labelText: string;
  children: React.ReactNode;
  tooltip?: InputWrapperTooltipOptions;
  optional?: boolean;
  disabled?: boolean;
  className?: string;
  asDiv?: boolean;
} & OptionalGroup<{
  error: boolean;
  errorText: string;
}>;

export const InputWrapper: React.FC<InputWrapperProps> = ({
  labelText,
  children,
  tooltip,
  optional = false,
  disabled = false,
  className,
  asDiv,
  error = false,
  errorText = ""
}) => {
  const theme = useTheme();

  return (
    <Label className={className} as={asDiv ? "div" : undefined}>
      <div>
        <div>
          <LabelText disabled={disabled}>{labelText}</LabelText>
          {tooltip ? (
            <>
              {"text" in tooltip && (
                <TooltipIconWrapper>
                  <Tooltip
                    text={tooltip.text}
                    placement="top"
                    maxWidth={tooltip.maxWidth}
                    offset={theme.gridBase}
                    delay={[150, 0]}
                  >
                    <TooltipInner>
                      <IconHelp size="16px" />
                    </TooltipInner>
                  </Tooltip>
                </TooltipIconWrapper>
              )}
              {"render" in tooltip && (
                <TooltipIconWrapper>
                  <TooltipBig
                    render={tooltip.render}
                    placement="top"
                    maxWidth={tooltip.maxWidth}
                    offset={theme.gridBase}
                    delay={[150, 0]}
                  >
                    <TooltipInner>
                      <IconHelp size="16px" />
                    </TooltipInner>
                  </TooltipBig>
                </TooltipIconWrapper>
              )}
            </>
          ) : null}
          {optional ? (
            <OptionalText disabled={disabled}>Optional</OptionalText>
          ) : null}
        </div>
        <ErrorText visible={error && !disabled}>{errorText}</ErrorText>
      </div>
      {children}
    </Label>
  );
};

/* ========================================================================== */

const Label = styled.label`
  display: block;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => props.theme.gridBase}px;

    > div:first-child {
      display: flex;
    }
  }
`;

type TextProps = {
  disabled: boolean;
};

const LabelText = styled.div<TextProps>`
  ${normalTextStyles};
  transition: color ${props => props.theme.other.transition};
  color: ${props =>
    props.disabled ? props.theme.palette.grey4 : props.theme.palette.grey2};
`;

const TooltipIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.gridBase * 0.75}px;
`;

const TooltipInner = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

const OptionalText = styled.div<TextProps>`
  ${normalTextStyles};
  margin-left: ${props => props.theme.gridBase}px;
  transition: color ${props => props.theme.other.transition};
  color: ${props =>
    props.disabled ? props.theme.palette.grey6 : props.theme.palette.grey4};
`;

type ErrorTextProps = {
  visible: boolean;
};

const ErrorText = styled.div<ErrorTextProps>`
  ${normalTextStyles};
  color: ${props => props.theme.palette.red1};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition};
`;
