type IconRakeutenProps = {
  size: "24px";
  className?: string;
};

export const IconRakeuten: React.FC<IconRakeutenProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M24 12.0026C24 18.6295 18.6277 24 11.9993 24C5.36808 24 0 18.63 0 12.0026C0 5.37425 5.36808 0 11.9993 0C18.6277 0 24 5.37425 24 12.0026Z"
            fill="#BF0000"
          />
          <path
            d="M10.413 18.4497V14.4501H12.1463L15.1471 18.4497H18.2148L14.5891 13.6235C15.7189 12.8126 16.4615 11.4934 16.4615 9.9997C16.4615 7.54604 14.4662 5.55359 12.013 5.55359H7.96216V18.4502H10.413V18.4497ZM10.413 8.00204H12.0121C13.1124 8.00204 14.0126 8.89743 14.0126 9.9997C14.0126 11.1053 13.1124 11.9997 12.0121 11.9997H10.413V8.00204Z"
            fill="white"
          />
        </svg>
      );
  }
};
