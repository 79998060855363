import { ResponsiveLine, type Serie } from "@nivo/line";
import { sumBy, zipWith } from "lodash-es";
import { transparentize } from "polished";
import { useEffect, useId, useMemo, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled, { css, type DefaultTheme, useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWhite
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCircledInfo,
  IconCog,
  IconHelp
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tag } from "elevar-design-system/src/Tag";
import { Tooltip, TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading1Styles,
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  type ConversionAccuracyConfig,
  useConversionAccuracyConfigQuery,
  useConversionAccuracyConfigUpdateMutation,
  useEventsConnectorConfigQuery
} from "../../../api/handlers/website";
import { Drawer } from "../../../components/Drawer";
import { NoneExplainer } from "../../../components/NoneExplainer";
import { PageCard } from "../../../components/PageCard";
import { Range } from "../../../components/Range";
import { Status } from "../../../components/Status";
import {
  type CubeDateRange,
  type CubeGranularity,
  getCubeDateRangeFromMonitoringTimePeriod,
  getCubeGranularityFromMonitoringTimePeriod,
  useMonitoringTimePeriod
} from "../../../context/MonitoringTimePeriod";
import { TimezoneNotice } from "../../../context/Timezone";
import { useGraphTheme } from "../../../utils/graphTheme";
import { useCompanyId, useWebsiteId } from "../../../utils/idHooks";
import { toast } from "../../../utils/toast";
import { track } from "../../../utils/track";
import { nivoDateFormatter } from "../utils";
import {
  getPercentageColor,
  getPercentageMatch,
  type TransformedDataItem,
  useConversionAccuracyData
} from "./conversionAccuracyData";

/* ========================================================================== */

export const ConversionAccuracy: React.FC = () => {
  const eventsConnectorConfig = useEventsConnectorConfigQuery();
  const conversionAccuracyConfig = useConversionAccuracyConfigQuery();

  if (
    eventsConnectorConfig.error !== null ||
    conversionAccuracyConfig.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    eventsConnectorConfig.data === undefined ||
    conversionAccuracyConfig.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <ConversionAccuracyInner
      eventsConnectorConfig={eventsConnectorConfig.data}
      conversionAccuracyConfig={conversionAccuracyConfig.data}
    />
  );
};

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 22}px;
`;

/* ========================================================================== */

type ScrollToInfo = { configKey: string | null; configId: number | null };

type ConversionAccuracyInnerProps = {
  eventsConnectorConfig: EventsConnectorConfig;
  conversionAccuracyConfig: ConversionAccuracyConfig;
};

const ConversionAccuracyInner: React.FC<ConversionAccuracyInnerProps> = ({
  eventsConnectorConfig,
  conversionAccuracyConfig
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { monitoringTimePeriod } = useMonitoringTimePeriod();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const result = useConversionAccuracyData({
    eventsConnectorConfig,
    monitoringTimePeriod
  });

  const target = conversionAccuracyConfig.accuracy_target;

  const scrollToInfo = useMemo<ScrollToInfo>(() => {
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get("key");
    const id = searchParams.get("id");
    const idAsNumber = id !== null ? Number(id) : null;
    return {
      configKey: key,
      configId: Number.isSafeInteger(idAsNumber) ? idAsNumber : null
    };
  }, [location.search]);

  return (
    <>
      <RouteHeader>
        <RouteHeading>
          <div>Conversion Accuracy</div>
          <div>
            <TooltipBig
              placement="right"
              maxWidth={`${theme.gridBase * 43.5}px`}
              render={() => (
                <RouteHeadingTooltipContent>
                  <div>
                    We use your Shopify orders as the source of truth to compare
                    to your successfully tracked conversions by channel (e.g.
                    Facebook CAPI).
                  </div>
                  <div>
                    You can change your accuracy target in the settings drawer
                    (openable via the button at the top right of this page).
                  </div>
                </RouteHeadingTooltipContent>
              )}
            >
              <RouteHeadingTooltipTarget>
                <IconHelp size="16px" />
              </RouteHeadingTooltipTarget>
            </TooltipBig>
          </div>
        </RouteHeading>
        <div>
          <TimezoneNotice />
          <ButtonWhiteWithIcon
            variant="SMALL"
            onClick={() => setIsDrawerVisible(true)}
          >
            <IconCog size="16px" />
            <span>Settings</span>
          </ButtonWhiteWithIcon>
        </div>
      </RouteHeader>
      {result.status === "ERROR" ? (
        <LoadingOrErrorWrapper>
          <ErrorOccurred />
        </LoadingOrErrorWrapper>
      ) : result.status === "LOADING" ? (
        <LoadingOrErrorWrapper>
          <Spinner size="24px" />
        </LoadingOrErrorWrapper>
      ) : result.status === "SUCCESS_NONE" ? (
        <NoneExplainer details={result.details} />
      ) : (
        <PageContent>
          <div>
            <ConversionAccuracySummary target={target} data={result.data} />
          </div>
          {result.data.map(dataItem => (
            <div key={dataItem.config.id}>
              <ConversionAccuracyBreakdown
                eventsConnectorConfig={eventsConnectorConfig}
                target={target}
                scrollToInfo={scrollToInfo}
                dataItem={dataItem}
              />
            </div>
          ))}
        </PageContent>
      )}
      <ConversionAccuracyDrawer
        isVisible={isDrawerVisible}
        setIsVisible={setIsDrawerVisible}
        conversionAccuracyConfig={conversionAccuracyConfig}
      />
    </>
  );
};

const RouteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:last-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2.5}px;
  }
`;

const RouteHeading = styled.h1`
  display: flex;

  > div:first-child {
    ${heading3Styles};
    margin-right: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const RouteHeadingTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 2.5}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;

  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }
`;

const RouteHeadingTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const ButtonWhiteWithIcon = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;

  > span {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;

const LoadingOrErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 15}px;
`;

const PageContent = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

/* ========================================================================== */

const generateColor = (index: number) => {
  const goldenAngleApproximation = 137.508;
  const hue = Math.round(index * goldenAngleApproximation);
  return `hsl(${hue},85%,55%)`;
};

const getGraphColor = (theme: DefaultTheme, index: number) => {
  if (index === 0) return theme.palette.purple2;
  else if (index === 1) return theme.palette.blue2;
  else if (index === 2) return theme.palette.green;
  else if (index === 3) return theme.palette.yellow;
  else if (index === 4) return theme.palette.orange;
  else if (index === 5) return theme.palette.red1;
  else if (index === 6) return theme.palette.pink;
  else return generateColor(index);
};

type ConversionAccuracySummaryProps = {
  target: number;
  data: Array<TransformedDataItem>;
};

const ConversionAccuracySummary: React.FC<ConversionAccuracySummaryProps> = ({
  target,
  data
}) => {
  const theme = useTheme();
  const graphTheme = useGraphTheme();
  const { monitoringTimePeriod } = useMonitoringTimePeriod();

  const cubeDateRange =
    getCubeDateRangeFromMonitoringTimePeriod(monitoringTimePeriod);
  const cubeGranularity =
    getCubeGranularityFromMonitoringTimePeriod(monitoringTimePeriod);

  const summaryData = useMemo(() => {
    return data.map<Serie>((dataItem, index) => ({
      id: index + 1,
      data: zipWith(
        dataItem.shopifyPoints,
        dataItem.channelPoints,
        (shopify, channel) => ({
          x: shopify.time,
          y:
            shopify.successCount === 0 &&
            channel.successCount + channel.ignoredCount === 0
              ? 100
              : shopify.successCount === 0
                ? null
                : (100 / shopify.successCount) *
                  (channel.successCount + channel.ignoredCount)
        })
      )
    }));
  }, [data]);

  const yNumberValues = summaryData
    .flatMap(serie => serie.data.map(point => point.y))
    .filter((y): y is number => typeof y === "number");

  const yMax = Math.max(...yNumberValues);
  const yScaleMax = Math.max(yMax, target);

  return (
    <PageCard>
      <ConversionAccuracySummaryHeading>
        Channel Summary
      </ConversionAccuracySummaryHeading>
      <GraphContainer>
        <ResponsiveLine
          data={summaryData}
          theme={graphTheme}
          axisLeft={{
            tickSize: 0,
            tickPadding: theme.gridBase * 2,
            legend: "% Match",
            legendPosition: "middle",
            legendOffset: theme.gridBase * -8.5,
            format: (value: string | number) => `${value}%`
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: theme.gridBase * 2,
            tickRotation: 45,
            format: (value: Date | string | number) => {
              return nivoDateFormatter(value, cubeDateRange, cubeGranularity);
            }
          }}
          xFormat={value => {
            return nivoDateFormatter(value, cubeDateRange, cubeGranularity);
          }}
          yFormat={value => `${Number(value).toFixed(1)}%`}
          gridYValues={[0]}
          yScale={{ type: "linear", min: 0, max: yScaleMax }}
          margin={{
            top: theme.gridBase * 2,
            bottom: theme.gridBase * 9.5,
            left: theme.gridBase * 10,
            right: theme.gridBase * 8
          }}
          curve="monotoneX"
          animate={false}
          lineWidth={2}
          colors={({ id }: { id: number }) => getGraphColor(theme, id - 1)}
          pointSize={10}
          pointBorderWidth={2}
          pointBorderColor={theme.palette.white}
          enableSlices="x"
          sliceTooltip={({ slice }) => {
            const points = [...slice.points].reverse();
            return (
              <GraphTooltip>
                <div>{points[0]!.data.xFormatted}</div>
                <div>
                  {points.map(point => {
                    const id = point.serieId as number;
                    return (
                      <div key={id}>
                        <GraphTooltipItem color={point.color}>
                          <div>{data[id - 1]!.title}</div>
                          <div>{Number(point.data.y).toFixed(1)}%</div>
                        </GraphTooltipItem>
                      </div>
                    );
                  })}
                </div>
              </GraphTooltip>
            );
          }}
          markers={[
            {
              axis: "y",
              value: target,
              lineStyle: {
                stroke: theme.palette.green,
                strokeWidth: 1,
                strokeDasharray: "3 3",
                strokeLinecap: "round"
              },
              legend: "Target",
              legendPosition: "right",
              textStyle: {
                ...graphTheme.axis.legend.text,
                fill: theme.palette.green
              }
            }
          ]}
        />
      </GraphContainer>
      <GraphLegend>
        {data.map((dateItem, index) => (
          <GraphLegendItem
            key={dateItem.config.id}
            color={getGraphColor(theme, index)}
          >
            {dateItem.title}
          </GraphLegendItem>
        ))}
      </GraphLegend>
    </PageCard>
  );
};

const ConversionAccuracySummaryHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;

const GraphContainer = styled.div`
  width: 100%;
  height: ${props => props.theme.gridBase * 45}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const GraphTooltip = styled.div`
  background-color: ${props => props.theme.palette.white};
  padding: ${props => props.theme.gridBase * 2}px;
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  border-radius: 4px;

  > div:first-child {
    ${subheadingStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    > div:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }
  }
`;

type GraphTooltipItemProps = {
  color: string;
};

const GraphTooltipItem = styled.div<GraphTooltipItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey2};
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.gridBase * 4}px;

    &::before {
      content: "";
      display: block;
      width: ${props => props.theme.gridBase}px;
      height: ${props => props.theme.gridBase}px;
      margin-right: ${props => props.theme.gridBase}px;
      background-color: ${props => props.color};
      border-radius: 2px;
    }
  }

  > div:last-child {
    ${normalTextStyles};
    font-feature-settings:
      "cv06" 1,
      "tnum" 1;
  }
`;

const GraphLegend = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: ${props => props.theme.gridBase * 3}px;
  row-gap: ${props => props.theme.gridBase * 1.5}px;
`;

type GraphLegendItemProps = {
  color: string;
};

const GraphLegendItem = styled.div<GraphLegendItemProps>`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey2};
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: ${props => props.theme.gridBase}px;
    height: ${props => props.theme.gridBase}px;
    margin-right: ${props => props.theme.gridBase}px;
    background-color: ${props => props.color};
    border-radius: 2px;
  }
`;

/* ========================================================================== */

const getBreakdownData = (
  cubeDateRange: CubeDateRange,
  cubeGranularity: CubeGranularity,
  dataItem: TransformedDataItem
) => {
  const itemRows = zipWith(
    dataItem.shopifyPoints,
    dataItem.channelPoints,
    (shopify, channel) => ({
      time: nivoDateFormatter(shopify.time, cubeDateRange, cubeGranularity),
      shopifySuccessCount: shopify.successCount,
      channelSuccessCount: channel.successCount,
      channelIgnoredCount: channel.ignoredCount,
      channelFailureCount: channel.failureCount,
      percentageMatch: getPercentageMatch({
        shopify: shopify.successCount,
        channel: channel.successCount + channel.ignoredCount
      })
    })
  );

  const totalShopifySuccessCount = sumBy(itemRows, r => r.shopifySuccessCount);
  const totalChannelSuccessCount = sumBy(itemRows, r => r.channelSuccessCount);
  const totalChannelIgnoredCount = sumBy(itemRows, r => r.channelIgnoredCount);
  const totalChannelFailureCount = sumBy(itemRows, r => r.channelFailureCount);

  return {
    itemRows: itemRows.reverse(),
    totalRow: {
      shopifySuccessCount: totalShopifySuccessCount,
      channelSuccessCount: totalChannelSuccessCount,
      channelIgnoredCount: totalChannelIgnoredCount,
      channelFailureCount: totalChannelFailureCount,
      percentageMatch: getPercentageMatch({
        shopify: totalShopifySuccessCount,
        channel: totalChannelSuccessCount + totalChannelIgnoredCount
      })
    }
  };
};

/* -------------------------------------------------------------------------- */

type BreakdownConditionsTooltip = {
  warnIfBelowTarget: boolean;
  maxWidth: string;
  content: React.ReactNode;
};

const useTooltip = (
  dataItem: TransformedDataItem
): BreakdownConditionsTooltip | null => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const shorthand = dataItem.destination.shorthand;
  const id = dataItem.config.id;
  const url = `${websiteUrl}/my-tracking/destination-${shorthand}/${id}`;

  switch (dataItem.destination.name) {
    case "ShareASale": {
      return {
        warnIfBelowTarget: false,
        maxWidth: `${theme.gridBase * 36.5}px`,
        content: (
          <BreakdownLimitTooltipContent>
            ShareASale requires a click ID to be included with a conversion. Any
            orders without a click ID will be ignored.
          </BreakdownLimitTooltipContent>
        )
      };
    }
    case "Voluum": {
      return {
        warnIfBelowTarget: false,
        maxWidth: `${theme.gridBase * 38}px`,
        content: (
          <BreakdownLimitTooltipContent>
            Voluum requires a click ID (vlmcid) to be included with a
            conversion. Any orders without this click ID will be ignored.
          </BreakdownLimitTooltipContent>
        )
      };
    }
    case "Pinterest": {
      return {
        warnIfBelowTarget: true,
        maxWidth: `${theme.gridBase * 45}px`,
        content: (
          <BreakdownLimitTooltipContent>
            <p>
              Pinterest has a limit of 5000 requests per minute. If your store
              has more requests, we recommend disabling the pageview and other
              events.
            </p>
            <Link to={url}>Go to destination</Link>
          </BreakdownLimitTooltipContent>
        )
      };
    }
    case "Google Ads": {
      return {
        warnIfBelowTarget: false,
        maxWidth: `${theme.gridBase * 69.5}px`,
        content: (
          <BreakdownLimitTooltipContent>
            We pass any potential conversions to Google Ads, including events
            with a click ID, email, or phone number. To match on email or phone
            when a click ID is not available, please enable{" "}
            <LinkExternal href="https://support.google.com/google-ads/answer/11347292">
              Enhanced Conversions for Leads
            </LinkExternal>{" "}
            setting. Google Ads will then determine which potential conversions
            are actual conversions.
          </BreakdownLimitTooltipContent>
        )
      };
    }
    case "Outbrain": {
      return {
        warnIfBelowTarget: false,
        maxWidth: `${theme.gridBase * 36.5}px`,
        content: (
          <BreakdownLimitTooltipContent>
            Outbrain requires a click ID to be included with a conversion. Any
            orders without a click ID will be ignored.
          </BreakdownLimitTooltipContent>
        )
      };
    }
    case "Mixpanel": {
      return {
        warnIfBelowTarget: true,
        maxWidth: `${theme.gridBase * 46.5}px`,
        content: (
          <BreakdownLimitTooltipContent>
            <p>
              Mixpanel has a limit of 30,000 requests per minute. If your store
              has more requests, we recommend disabling the page view and other
              events.
            </p>
            <Link to={url}>Go to destination</Link>
          </BreakdownLimitTooltipContent>
        )
      };
    }
    case "Reddit": {
      return {
        warnIfBelowTarget: true,
        maxWidth: `${theme.gridBase * 42.5}px`,
        content: (
          <BreakdownLimitTooltipContent>
            <p>
              Reddit has a limit of 10 requests per second. If your store has
              more requests, we recommend disabling the page view and other
              events.
            </p>
            <Link to={url}>Go to destination</Link>
          </BreakdownLimitTooltipContent>
        )
      };
    }
    default: {
      return null;
    }
  }
};

const BreakdownLimitTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > a {
    ${linkStyles};
  }
`;

/* -------------------------------------------------------------------------- */

type ConversionAccuracyBreakdownProps = {
  eventsConnectorConfig: EventsConnectorConfig;
  target: number;
  scrollToInfo: ScrollToInfo;
  dataItem: TransformedDataItem;
};

const ConversionAccuracyBreakdown: React.FC<
  ConversionAccuracyBreakdownProps
> = ({ eventsConnectorConfig, target, scrollToInfo, dataItem }) => {
  const theme = useTheme();
  const history = useHistory();
  const { monitoringTimePeriod } = useMonitoringTimePeriod();

  const ref = useRef<HTMLDivElement | null>(null);

  const cubeDateRange =
    getCubeDateRangeFromMonitoringTimePeriod(monitoringTimePeriod);
  const cubeGranularity =
    getCubeGranularityFromMonitoringTimePeriod(monitoringTimePeriod);

  const [isIgnoredHovered, setIsIgnoredHovered] = useState(false);
  const [isFailuresHovered, setIsFailuresHovered] = useState(false);

  const { itemRows, totalRow } = useMemo(() => {
    return getBreakdownData(cubeDateRange, cubeGranularity, dataItem);
  }, [cubeDateRange, cubeGranularity, dataItem]);

  const title = dataItem.title;
  const destination = dataItem.destination;
  const conditionsTooltip = useTooltip(dataItem);
  const current = totalRow.percentageMatch ?? Number.POSITIVE_INFINITY;
  const consentModeEnabled =
    eventsConnectorConfig.globalConfig.consentModeEnabled &&
    dataItem.config.consentMode.enabled;
  const marketsImpactingCounts =
    eventsConnectorConfig.globalConfig.marketsEnabled &&
    !dataItem.config.all_markets;

  const filters = dataItem.config.orderFilters;
  const someFiltersActive =
    filters.channels.length > 0 ||
    filters.tags.length > 0 ||
    filters.gateways.length > 0 ||
    filters.customerTags.length > 0 ||
    filters.appIds.length > 0;

  const shouldScrollTo =
    scrollToInfo.configKey === dataItem.destination.configKey &&
    scrollToInfo.configId === dataItem.config.id;

  useEffect(() => {
    if (shouldScrollTo) {
      ref.current?.scrollIntoView({ block: "start", behavior: "instant" });
      history.replace({ search: "", state: { preventLastLocation: true } });
    }
  }, [shouldScrollTo, history]);

  return (
    <ConversionAccuracyBreakdownPageCard ref={ref}>
      <ConversionAccuracyBreakdownHeader>
        <div>
          <ConversionAccuracyBreakdownHeading>
            <destination.icon size="24px" />
            <div>{title}</div>
          </ConversionAccuracyBreakdownHeading>
          {consentModeEnabled ? (
            <ConversionAccuracyBreakdownNotice>
              <div>Consent Mode Enabled</div>
              <TooltipBig
                placement="top"
                maxWidth={`${theme.gridBase * 34.5}px`}
                render={() => (
                  <ConversionAccuracyBreakdownNoticeTooltipContent>
                    We ignore the orders from shoppers who opted out of data
                    tracking.
                  </ConversionAccuracyBreakdownNoticeTooltipContent>
                )}
              >
                <ConversionAccuracyBreakdownNoticeTooltipTrigger>
                  <IconHelp size="16px" />
                </ConversionAccuracyBreakdownNoticeTooltipTrigger>
              </TooltipBig>
            </ConversionAccuracyBreakdownNotice>
          ) : null}
          {filters.condition === "ALLOW" || someFiltersActive ? (
            <ConversionAccuracyBreakdownNotice>
              <div>Transactions Filters Active</div>
              <TooltipBig
                placement="top"
                maxWidth={
                  filters.condition === "ALLOW"
                    ? `${theme.gridBase * 38.5}px`
                    : `${theme.gridBase * 37.5}px`
                }
                render={() =>
                  filters.condition === "ALLOW" ? (
                    <ConversionAccuracyBreakdownNoticeTooltipContent>
                      You selected to only send certain orders to this
                      destination. Your choices in the "Filter Transactions" and
                      "Subscriptions" steps impacts filtering.
                    </ConversionAccuracyBreakdownNoticeTooltipContent>
                  ) : (
                    <ConversionAccuracyBreakdownNoticeTooltipContent>
                      You selected to block certain orders from being sent to
                      this destination. Your choices in the "Filter
                      Transactions" and "Subscriptions" steps impacts filtering.
                    </ConversionAccuracyBreakdownNoticeTooltipContent>
                  )
                }
              >
                <ConversionAccuracyBreakdownNoticeTooltipTrigger>
                  <IconHelp size="16px" />
                </ConversionAccuracyBreakdownNoticeTooltipTrigger>
              </TooltipBig>
            </ConversionAccuracyBreakdownNotice>
          ) : null}
        </div>
        {conditionsTooltip !== null ? (
          <div>
            <TooltipBig
              placement="top"
              maxWidth={conditionsTooltip.maxWidth}
              render={() => conditionsTooltip.content}
            >
              <ConversionAccuracyLimitationsTooltipTrigger>
                <Status
                  textColor={
                    conditionsTooltip.warnIfBelowTarget && current < target
                      ? theme.palette.orange
                      : theme.palette.grey3
                  }
                  backgroundColor={theme.palette.white}
                  icon={<IconCircledInfo size="16px" />}
                  text="Destination Conditions"
                />
              </ConversionAccuracyLimitationsTooltipTrigger>
            </TooltipBig>
          </div>
        ) : null}
      </ConversionAccuracyBreakdownHeader>
      <ConversionAccuracyBreakdownContentsGrid>
        <div>
          <ConversionAccuracyBreakdownLeft>
            <div>
              <ConversionAccuracyVisual current={current} target={target} />
            </div>
          </ConversionAccuracyBreakdownLeft>
        </div>
        <div>
          <TableHeaderRow>
            <TableCell type="HEADER">
              {(() => {
                switch (cubeGranularity) {
                  case "hour":
                    return "Time";
                  case "day":
                  case "week":
                    return "Date";
                }
              })()}
            </TableCell>
            <TableCell type="HEADER">
              <TableColumnHeaderWithTooltip>
                <TooltipBig
                  placement="top"
                  maxWidth={
                    marketsImpactingCounts
                      ? `${theme.gridBase * 48.5}px`
                      : `${theme.gridBase * 38}px`
                  }
                  render={() => (
                    <TableHeaderTooltipContent>
                      <div>Shopify Orders</div>
                      <div>
                        <p>
                          The total number of orders recorded by Shopify (online
                          store, POS, wholesale channel, etc.) within the time
                          period.
                        </p>
                        {marketsImpactingCounts ? (
                          <p>
                            Note that as this destination is only sending data
                            to specific Market Groups, this is only the total
                            number of orders recorded for those Market Groups.
                          </p>
                        ) : null}
                      </div>
                    </TableHeaderTooltipContent>
                  )}
                >
                  <TableHeaderTooltipTarget>
                    <IconHelp size="16px" />
                  </TableHeaderTooltipTarget>
                </TooltipBig>
                <div>Shopify</div>
              </TableColumnHeaderWithTooltip>
            </TableCell>
            <TableCell type="HEADER">
              <TableColumnHeaderWithTooltip>
                <TooltipBig
                  placement="top"
                  maxWidth={`${theme.gridBase * 45}px`}
                  render={() => (
                    <TableHeaderTooltipContent>
                      <div>Ignored Orders</div>
                      <div>
                        <p>
                          You may see ignored orders if you filtered
                          transactions, have enabled consent mode and consent
                          was denied or not provided, or the click ID is missing
                          and required by the destination.{" "}
                          <LinkExternal href="https://docs.getelevar.com/docs/elevars-channel-accuracy-report#why-am-i-missing-click-ids">
                            Why could click IDs be missing?
                          </LinkExternal>
                        </p>
                      </div>
                    </TableHeaderTooltipContent>
                  )}
                >
                  <TableHeaderTooltipTarget>
                    <IconHelp size="16px" />
                  </TableHeaderTooltipTarget>
                </TooltipBig>
                <div>Ignored</div>
              </TableColumnHeaderWithTooltip>
            </TableCell>
            <TableCell type="HEADER">Success</TableCell>
            <TableCell type="HEADER">% Match</TableCell>
            <TableCell type="HEADER">Failures</TableCell>
          </TableHeaderRow>
          <div>
            {itemRows.map(row => (
              <TableItemRow
                key={row.time}
                row={row}
                totalRow={totalRow}
                destinationTitle={title}
                isIgnoredHovered={isIgnoredHovered}
                setIsIgnoredHovered={setIsIgnoredHovered}
                isFailuresHovered={isFailuresHovered}
                setIsFailuresHovered={setIsFailuresHovered}
              />
            ))}
            <TableItemRow
              row={totalRow}
              totalRow={totalRow}
              destinationTitle={title}
              isIgnoredHovered={isIgnoredHovered}
              setIsIgnoredHovered={setIsIgnoredHovered}
              isFailuresHovered={isFailuresHovered}
              setIsFailuresHovered={setIsFailuresHovered}
            />
          </div>
        </div>
      </ConversionAccuracyBreakdownContentsGrid>
    </ConversionAccuracyBreakdownPageCard>
  );
};

const ConversionAccuracyBreakdownPageCard = styled(PageCard)`
  scroll-margin-top: ${props => props.theme.gridBase * 2}px;
`;

const ConversionAccuracyBreakdownHeader = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div {
    display: flex;
  }
`;

const ConversionAccuracyBreakdownHeading = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase * 1.25}px;
  margin-right: ${props => props.theme.gridBase * 2}px;

  > div {
    ${heading3Styles};
  }
`;

const ConversionAccuracyBreakdownNotice = styled.div`
  ${smallTextStyles};
  display: flex;
  gap: ${props => props.theme.gridBase}px;
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  padding-right: ${props => props.theme.gridBase * 1.25}px;
  padding-left: ${props => props.theme.gridBase * 1.25}px;
  border-radius: 4px;
  white-space: nowrap;
  background-color: ${props => transparentize(0.9, props.theme.palette.orange)};
  color: ${props => props.theme.palette.orange};

  &:not(:last-child) {
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const ConversionAccuracyBreakdownNoticeTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const ConversionAccuracyBreakdownNoticeTooltipTrigger = styled.div`
  display: flex;
`;

const ConversionAccuracyLimitationsTooltipTrigger = styled.div`
  display: flex;
  margin-top: ${props => props.theme.gridBase}px;
  margin-left: ${props => props.theme.gridBase * -0.5}px;
`;

const ConversionAccuracyBreakdownContentsGrid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.gridBase * 40}px auto;
  column-gap: ${props => props.theme.gridBase * 3}px;

  @media screen and (max-width: 1439px) {
    grid-template-columns: 1fr;
    gap: ${props => props.theme.gridBase * 3}px;
  }
`;

const ConversionAccuracyBreakdownLeft = styled.div`
  height: 100%;
  max-height: ${props => props.theme.gridBase * 60}px;
  padding: ${props => props.theme.gridBase * 4}px;
  border: 1px solid ${props => props.theme.palette.grey6};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
  }
`;

const sharedTableRowStyles = css`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
`;

const TableHeaderRow = styled.div`
  ${sharedTableRowStyles};
  ${normalBodyStyles};
  font-weight: 500;
  padding-bottom: ${props => props.theme.gridBase}px;
`;

const TableColumnHeaderWithTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TableHeaderTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 2.5}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;

  > div:first-child {
    ${normalBodyStyles};
    font-weight: 500;
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};

    p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }

    a {
      ${linkStyles};
    }
  }
`;

const TableHeaderTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
  margin-right: ${props => props.theme.gridBase * 0.5}px;
`;

/* ========================================================================== */

type TableCellType = "HEADER" | "ITEM";

type TableCellProps = {
  type: TableCellType;
  link?: {
    to: string;
    isHovered: boolean;
    setIsHovered: (isHovered: boolean) => void;
  } | null;
  children: React.ReactNode;
};

const TableCell: React.FC<TableCellProps> = ({ type, link, children }) => {
  if (link) {
    return (
      <Tooltip
        text="More details"
        placement="top"
        followCursor={true}
        delay={[0, 40]} // Prevents hovering between rows feeling disjointed
      >
        <TableCellLink
          type={type}
          to={link.to}
          isHovered={link.isHovered}
          onMouseEnter={() => link.setIsHovered(true)}
          onMouseLeave={() => link.setIsHovered(false)}
        >
          {children}
        </TableCellLink>
      </Tooltip>
    );
  } else {
    return <TableCellText type={type}>{children}</TableCellText>;
  }
};

const sharedTableCellStyles = css<{ type: TableCellType }>`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  ${props =>
    props.type === "ITEM"
      ? css`
          border-top: 1px solid ${props.theme.palette.grey6};
        `
      : null};

  &:first-child {
    justify-content: left;
  }

  &:not(:first-child) {
    justify-content: right;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

type TableCellLinkProps = {
  type: TableCellType;
  isHovered: boolean;
};

const TableCellLink = styled(Link)<TableCellLinkProps>`
  ${sharedTableCellStyles};
  background-color: ${props =>
    props.isHovered ? props.theme.palette.grey8 : "transparent"};
  transition: background-color ${props => props.theme.other.transition};
`;

type TableCellTextProps = {
  type: TableCellType;
};

const TableCellText = styled.div<TableCellTextProps>`
  ${sharedTableCellStyles};
`;

/* ========================================================================== */

type BreakdownData = ReturnType<typeof getBreakdownData>;
type TableItemRowItemData = BreakdownData["itemRows"][number];
type TableItemRowTotalData = BreakdownData["totalRow"];

type TableItemRowProps = {
  row: TableItemRowItemData | TableItemRowTotalData;
  totalRow: TableItemRowTotalData;
  destinationTitle: string;
  isIgnoredHovered: boolean;
  setIsIgnoredHovered: (isIgnoredHovered: boolean) => void;
  isFailuresHovered: boolean;
  setIsFailuresHovered: (isIgnoredHovered: boolean) => void;
};

const TableItemRow: React.FC<TableItemRowProps> = ({
  row,
  totalRow,
  destinationTitle,
  isIgnoredHovered,
  setIsIgnoredHovered,
  isFailuresHovered,
  setIsFailuresHovered
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const eventsLogUrl = `${websiteUrl}/channel-accuracy/events-log`;
  const baseRowUrl = `${eventsLogUrl}?channelDefault=${destinationTitle}`;

  return (
    <TableItemRowWrapper
      isIgnoredHovered={isIgnoredHovered}
      isFailuresHovered={isFailuresHovered}
    >
      <TableCell type="ITEM">{"time" in row ? row.time : "Total"}</TableCell>
      <TableCell type="ITEM">
        {row.shopifySuccessCount.toLocaleString("en")}
      </TableCell>
      <TableCell
        type="ITEM"
        link={
          totalRow.channelIgnoredCount !== 0
            ? {
                to: `${baseRowUrl}&stateDefault=Ignored`,
                isHovered: isIgnoredHovered,
                setIsHovered: setIsIgnoredHovered
              }
            : null
        }
      >
        {row.channelIgnoredCount.toLocaleString("en")}
      </TableCell>
      <TableCell type="ITEM">
        {row.channelSuccessCount.toLocaleString("en")}
      </TableCell>
      <TableCell type="ITEM">{row.percentageMatch ?? "∞"}%</TableCell>
      <TableCell
        type="ITEM"
        link={
          totalRow.channelFailureCount !== 0
            ? {
                to: `${baseRowUrl}&stateDefault=Failure`,
                isHovered: isFailuresHovered,
                setIsHovered: setIsFailuresHovered
              }
            : null
        }
      >
        <TableFailuresTag
          text={row.channelFailureCount.toLocaleString("en")}
          textColor={
            row.channelFailureCount === 0
              ? theme.palette.green
              : theme.palette.red1
          }
        />
      </TableCell>
    </TableItemRowWrapper>
  );
};

type TableRowWrapperProps = {
  isIgnoredHovered: boolean;
  isFailuresHovered: boolean;
};

const TableItemRowWrapper = styled.div<TableRowWrapperProps>`
  ${sharedTableRowStyles};
  ${normalTextStyles};
  font-feature-settings:
    "cv06" 1,
    "tnum" 1;

  &:last-child {
    font-weight: 500;
  }
`;

const TableFailuresTag = styled(Tag)`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

/* ========================================================================== */

type ConversionAccuracyVisualProps = {
  current: number;
  target: number;
};

const ConversionAccuracyVisual: React.FC<ConversionAccuracyVisualProps> = ({
  current,
  target
}) => {
  const theme = useTheme();

  const idPrefix = useId();
  const linearGradientId = `${idPrefix}-linearGradient`;

  const currentColor = getPercentageColor({
    theme,
    current,
    target,
    context: "CONVERSION_ACCURACY"
  });

  return (
    <ConversionAccuracyVisualWrapper currentColor={currentColor}>
      <div>
        <div>
          <svg width="255" height="132" viewBox="0 0 255 132" fill="none">
            <defs>
              <linearGradient
                id={linearGradientId}
                x1="241"
                y1="129.5"
                x2="17.1329"
                y2="127.641"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={theme.palette.purple2} />
                <stop
                  offset="1"
                  stopColor={theme.palette.purple2}
                  stopOpacity="0.2"
                />
              </linearGradient>
            </defs>
            <path
              d="M8.07031 123.363C10.2502 59.2808 62.8865 8 127.5 8C179.646 8 223.99 41.3996 240.308 87.9731C244.21 99.1089 246.509 110.998 246.93 123.363"
              stroke={theme.palette.grey8}
              strokeWidth="16"
              strokeLinecap="round"
            />
            {current > 0 ? (
              <CurrentPercentPath
                d="M8.07031 123.363C10.2502 59.2808 62.8865 8 127.5 8C179.646 8 223.99 41.3996 240.308 87.9731C244.21 99.1089 246.509 110.998 246.93 123.363"
                stroke={`url(#${linearGradientId})`}
                strokeWidth="16"
                strokeLinecap="round"
                strokeLinejoin="round"
                percent={current > 100 ? 100 : current}
              />
            ) : null}
          </svg>
        </div>
        <div>
          <svg width="188" height="90" viewBox="0 0 188 90" fill="none">
            <path
              d="M186.64 89C183.332 39.8374 143.039 1 93.82 1C44.6008 1 4.30817 39.8374 1 89"
              stroke={theme.palette.grey6}
              strokeLinejoin="round"
              strokeDasharray="2 3"
            />
          </svg>
        </div>
      </div>
      <div>
        <div>{Number.isFinite(current) ? current : "∞"}%</div>
        <div>Your target: {target}%</div>
      </div>
    </ConversionAccuracyVisualWrapper>
  );
};

type ConversionAccuracyVisualWrapperProps = {
  currentColor: string;
};

const ConversionAccuracyVisualWrapper = styled.div<ConversionAccuracyVisualWrapperProps>`
  position: relative;

  > div:first-child {
    > div:first-child {
      display: flex;
      justify-content: center;
    }

    > div:last-child {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-top: ${props => props.theme.gridBase * 4.5}px;
      display: flex;
      justify-content: center;
    }
  }

  > div:last-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin-top: ${props => props.theme.gridBase * 8}px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div:first-child {
      ${heading1Styles};
      color: ${props => props.currentColor};
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }

    > div:last-child {
      ${normalTextStyles};
      color: ${props => props.theme.palette.grey3};
    }
  }
`;

const percentPathLength = 367;

type CurrentPercentPathProps = {
  percent: number;
};

const CurrentPercentPath = styled.path<CurrentPercentPathProps>`
  stroke-dasharray: ${percentPathLength} ${percentPathLength};
  stroke-dashoffset: ${props =>
    percentPathLength - (props.percent / 100) * percentPathLength};
`;

/* ========================================================================== */

type ConversionAccuracyDrawerProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  conversionAccuracyConfig: ConversionAccuracyConfig;
};

const ConversionAccuracyDrawer: React.FC<ConversionAccuracyDrawerProps> = ({
  isVisible,
  setIsVisible,
  conversionAccuracyConfig
}) => {
  const theme = useTheme();

  const { mutateAsync: conversionAccuracyConfigUpdateMutation } =
    useConversionAccuracyConfigUpdateMutation();

  const initialAccuracyTarget = conversionAccuracyConfig.accuracy_target;

  const [accuracyTarget, setAccuracyTarget] = useState(initialAccuracyTarget);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Drawer
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      disallowClose={isLoading}
    >
      <DrawerInner>
        <div>
          <DrawerTitle>Conversion Accuracy Settings</DrawerTitle>
          <DrawerSubtitle>Customize your target.</DrawerSubtitle>
          <DrawerAccuracyTargetHeading>
            <div>Accuracy Target</div>
            <div>
              <TooltipBig
                placement="top"
                maxWidth={`${theme.gridBase * 35}px`}
                render={() => (
                  <DrawerAccuracyTargetTooltipContent>
                    We use your Shopify orders as the source of truth to compare
                    to your successfully tracked conversions by channel (e.g.
                    Facebook CAPI).
                  </DrawerAccuracyTargetTooltipContent>
                )}
              >
                <DrawerAccuracyTargetTooltipTarget>
                  <IconHelp size="16px" />
                </DrawerAccuracyTargetTooltipTarget>
              </TooltipBig>
            </div>
          </DrawerAccuracyTargetHeading>
          <DrawerAccuracyTargetDescription>
            Used in the graph and tables on this page, where the value is in
            relation to Shopify orders vs tracked conversions. We recommend a
            target of 95%.
          </DrawerAccuracyTargetDescription>
          <DrawerAccuracyTargetValue>
            {accuracyTarget}%
          </DrawerAccuracyTargetValue>
          <Range
            step={1}
            min={70}
            max={100}
            disabled={isLoading}
            values={[accuracyTarget]}
            onChange={values => setAccuracyTarget(values[0]!)}
          />
        </div>
        <div>
          <DrawerActionsWrapper>
            <div>
              <ButtonPrimary
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);

                  await conversionAccuracyConfigUpdateMutation({
                    accuracy_target: accuracyTarget
                  });

                  setIsVisible(false);
                  toast.success("Configuration updated");
                  track.conversionAccuracyTargetUpdate();
                  setIsLoading(false);
                }}
              >
                Update
              </ButtonPrimary>
            </div>
            <div>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => setAccuracyTarget(95)}
              >
                Reset Target To Default
              </ButtonSecondary>
            </div>
          </DrawerActionsWrapper>
        </div>
      </DrawerInner>
    </Drawer>
  );
};

const DrawerInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const DrawerTitle = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DrawerSubtitle = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const DrawerAccuracyTargetHeading = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;

  > div:first-child {
    ${heading3Styles};
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const DrawerAccuracyTargetTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const DrawerAccuracyTargetTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const DrawerAccuracyTargetDescription = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const DrawerAccuracyTargetValue = styled.div`
  ${heading1Styles};
  font-feature-settings:
    "cv06" 1,
    "tnum" 1;
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const DrawerActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;
