import { createContext, useContext, useMemo } from "react";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

/* ========================================================================== */

type ServerSideDetails = { isFullyManaged: boolean };

const serverSideDetailsContext = createContext<ServerSideDetails | undefined>(
  undefined
);

/* ========================================================================== */

type ServerSideDetailsProviderProps = {
  websiteDetails: WebsiteDetails;
  children: React.ReactNode;
};

export const ServerSideDetailsProvider: React.FC<
  ServerSideDetailsProviderProps
> = ({ websiteDetails, children }) => {
  const isFullyManaged = websiteDetails.server_side_type === "FULLY_MANAGED";
  const value = useMemo(() => ({ isFullyManaged }), [isFullyManaged]);

  return (
    <serverSideDetailsContext.Provider value={value}>
      {children}
    </serverSideDetailsContext.Provider>
  );
};

/* ========================================================================== */

export const useServerSideDetails = () => {
  const serverSideDetails = useContext(serverSideDetailsContext);

  if (serverSideDetails !== undefined) {
    return serverSideDetails;
  } else {
    throw new Error("`useServerSideDetails`: value not set");
  }
};
