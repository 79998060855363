import { transparentize } from "polished";
import styled, { useTheme } from "styled-components";

import { type ImageUpload } from "elevar-common-ts/src/apiTypes";

import { Avatar } from "elevar-design-system/src/Avatar";
import { ButtonDropdown } from "elevar-design-system/src/buttons/ButtonDropdown";
import { IconDotsHorizontal } from "elevar-design-system/src/icons";
import { normalTextStyles } from "elevar-design-system/src/typography/typography";

import { clearApiAuthToken } from "../api/utils";
import { track } from "../utils/track";

/* ========================================================================== */

type UserButtonDropdownProps = {
  userImage: ImageUpload | undefined;
  userName: string;
  showAccountSettingsLink: boolean;
};

export const UserButtonDropdown: React.FC<UserButtonDropdownProps> = ({
  userImage,
  userName,
  showAccountSettingsLink
}) => {
  const theme = useTheme();

  return (
    <UserButtonDropdownWrapper>
      <UserButtonDropdownInternal
        dropdownPlacement="top-start"
        dropdownWidthOverride={`${theme.gridBase * 16}px`}
        dropdownOptions={[
          ...(showAccountSettingsLink
            ? ([
                {
                  value: "General",
                  type: "LINK_INTERNAL",
                  to: "/account/general"
                },
                {
                  value: "Password",
                  type: "LINK_INTERNAL",
                  to: "/account/password"
                },
                {
                  value: "Memberships",
                  type: "LINK_INTERNAL",
                  to: "/account/memberships"
                },
                {
                  value: "Notifications",
                  type: "LINK_INTERNAL",
                  to: "/account/notifications"
                }
              ] as const)
            : []),
          {
            value: "Log Out",
            type: "BUTTON",
            onClick: () => {
              clearApiAuthToken();
              track.userLogout();
              window.location.href = "/";
            }
          }
        ]}
      >
        <UserAvatarWrapper>
          <UserAvatarHoverOverlay>
            <IconDotsHorizontal size="24px" />
          </UserAvatarHoverOverlay>
          <Avatar size="MEDIUM" type="USER" imageSrc={userImage?.file} />
        </UserAvatarWrapper>
        <UserName>{userName}</UserName>
      </UserButtonDropdownInternal>
    </UserButtonDropdownWrapper>
  );
};

/* ========================================================================== */

const UserButtonDropdownWrapper = styled.div`
  display: flex;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const UserAvatarHoverOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 2px;
  transition: opacity ${props => props.theme.other.transition};
  background-color: ${props => transparentize(0.2, props.theme.palette.grey1)};
  color: ${props => props.theme.palette.white};
`;

const UserButtonDropdownInternal = styled(ButtonDropdown)`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.gridBase * 0.5}px 0 0 0;

  &:hover {
    ${UserAvatarHoverOverlay} {
      opacity: 1;
    }
  }
`;

const UserAvatarWrapper = styled.div`
  position: relative;
`;

const UserName = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin: 0 ${props => props.theme.gridBase * 1.5}px;
`;
