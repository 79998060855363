import { forwardRef } from "react";
import { Link } from "react-router-dom";
import styled, {
  css,
  type StyledComponentPropsWithRef,
  useTheme
} from "styled-components";

import { type OptionalPromise } from "elevar-common-ts/src/utils";

import { PopOutBase, type PopOutRenderArgs, Tooltip } from "../Tooltip";
import { normalBodyStyles } from "../typography/typography";

/* ========================================================================== */

type PopOutBaseProps = React.ComponentProps<typeof PopOutBase>;
export type DropdownPlacement = PopOutBaseProps["placement"];

type ButtonDropdownBaseProps = {
  className?: string;
  buttonProps?: Omit<
    StyledComponentPropsWithRef<"button">,
    "ref" | "key" | "className"
  >;
  dropdownPlacement: DropdownPlacement;
  dropdownContent: (args: PopOutRenderArgs) => React.ReactNode;
  children: React.ReactNode;
};

export const ButtonDropdownBase = forwardRef<
  HTMLButtonElement,
  ButtonDropdownBaseProps
>(
  (
    {
      className,
      buttonProps = {},
      dropdownPlacement,
      dropdownContent,
      children
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <PopOutBase
        render={popOutArgs => (
          <MinimalDropdownWrapper>
            {dropdownContent(popOutArgs)}
          </MinimalDropdownWrapper>
        )}
        placement={dropdownPlacement}
        offset={theme.gridBase * 0.5}
        interactive={true}
        trigger="click"
        animate={false}
      >
        <button {...buttonProps} ref={ref} className={className}>
          {children}
        </button>
      </PopOutBase>
    );
  }
);

const MinimalDropdownWrapper = styled.div`
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${props => props.theme.palette.grey6};
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
`;

/* ========================================================================== */

type DropdownOption = { value: string; tooltipContent?: string | null } & (
  | {
      type: "BUTTON";
      onClick: () => OptionalPromise<void>;
      disabled?: boolean;
      warn?: boolean;
    }
  | { type: "LINK_INTERNAL"; to: string }
);

/* -------------------------------------------------------------------------- */

type ButtonDropdownProps = Pick<
  ButtonDropdownBaseProps,
  "buttonProps" | "dropdownPlacement"
> & {
  className?: string;
  dropdownWidthOverride?: string;
  dropdownOptions: Array<DropdownOption>;
  children: React.ReactNode;
};

export const ButtonDropdown: React.FC<ButtonDropdownProps> = ({
  className,
  buttonProps,
  dropdownPlacement,
  dropdownWidthOverride,
  dropdownOptions,
  children
}) => {
  return (
    <ButtonDropdownBase
      className={className}
      buttonProps={buttonProps}
      dropdownPlacement={dropdownPlacement}
      dropdownContent={popOutArgs => (
        <DropdownWrapper widthOverride={dropdownWidthOverride}>
          {dropdownOptions.map((option, index) =>
            option.tooltipContent ? (
              <Tooltip key={index} placement="top" text={option.tooltipContent}>
                <span>
                  <DropdownOption
                    key={option.value}
                    details={option}
                    popOutArgs={popOutArgs}
                  />
                </span>
              </Tooltip>
            ) : (
              <DropdownOption
                key={option.value}
                details={option}
                popOutArgs={popOutArgs}
              />
            )
          )}
        </DropdownWrapper>
      )}
    >
      {children}
    </ButtonDropdownBase>
  );
};

type DropdownWrapperProps = {
  widthOverride?: string;
};

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  width: ${props => props.widthOverride ?? "auto"};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

/* -------------------------------------------------------------------------- */

type DropdownOptionProps = {
  details: DropdownOption;
  popOutArgs: PopOutRenderArgs;
};

const DropdownOption: React.FC<DropdownOptionProps> = ({
  details,
  popOutArgs
}) => {
  switch (details.type) {
    case "BUTTON": {
      return (
        <DropdownOptionButton
          disabled={details.disabled ?? false}
          isWarnEnabled={details.warn ?? false}
          onClick={async () => {
            popOutArgs.hidePopOut();
            await details.onClick();
          }}
        >
          {details.value}
        </DropdownOptionButton>
      );
    }
    case "LINK_INTERNAL": {
      return (
        <DropdownOptionLinkInternal to={details.to}>
          {details.value}
        </DropdownOptionLinkInternal>
      );
    }
  }
};

const commonDropdownOptionStyles = css`
  ${normalBodyStyles};
  display: block;
  color: ${props => props.theme.palette.grey2};
  padding: ${props => props.theme.gridBase}px;
  width: 100%;
  white-space: nowrap;
  background-color: transparent;
  text-align: left;
  border-radius: 2px;
  user-select: none;
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey8};
  }
`;

type DropdownOptionButtonProps = {
  isWarnEnabled: boolean;
};

const DropdownOptionButton = styled.button<DropdownOptionButtonProps>`
  ${commonDropdownOptionStyles};

  &:disabled {
    cursor: not-allowed;
    color: ${props => props.theme.palette.grey4};
  }

  ${props =>
    props.isWarnEnabled &&
    css`
      color: ${props => props.theme.palette.red1};
    `};
`;

const DropdownOptionLinkInternal = styled(Link)`
  ${commonDropdownOptionStyles};
`;
