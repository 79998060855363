type IconCircledCheckProps = {
  size: "16px";
  color?: string;
  className?: string;
};

export const IconCircledCheck: React.FC<IconCircledCheckProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M5 8L7 10L11 6"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="8" cy="8" r="6.5" stroke={color} />
        </svg>
      );
  }
};
