import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useTheme } from "styled-components";

/* ========================================================================== */

let stripePromise: ReturnType<typeof loadStripe> | null = null;

const interFontUrl =
  "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";

/* ========================================================================== */

type StripeProviderProps = {
  variant: "SMALL" | "LARGE";
  children: React.ReactNode;
};

export const StripeProvider: React.FC<StripeProviderProps> = ({
  variant,
  children
}) => {
  const theme = useTheme();

  if (stripePromise === null) {
    stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "setup",
        amount: 0,
        currency: "usd",
        paymentMethodTypes: ["card"],
        fonts: [{ cssSrc: interFontUrl }],
        appearance: {
          theme: "flat",
          variables: {
            borderRadius: "4px",
            colorDanger: theme.palette.red1,
            colorTextPlaceholder: theme.palette.grey4,
            focusOutline: `1px solid ${theme.palette.blue1}`,
            colorText: theme.palette.grey2,
            colorBackground: theme.palette.grey8,
            focusBoxShadow: theme.other.focusBoxShadow,
            fontFamily: "Inter",
            fontWeightNormal: "400",
            fontSizeSm: `${theme.fontBase * 0.875}px`,
            fontSizeBase:
              variant === "SMALL"
                ? `${theme.fontBase * 0.875}px`
                : `${theme.fontBase}px`,
            gridColumnSpacing: `${theme.gridBase * 3}px`,
            gridRowSpacing: `${theme.gridBase * 2.5}px`
          },
          rules: {
            ".Label": {
              textTransform: "capitalize",
              paddingBottom: `${theme.gridBase * 0.5}px`,
              lineHeight: `${theme.gridBase * 2.5}px`
            },
            ".Input": {
              lineHeight: `${theme.gridBase * 3}px`,
              paddingTop:
                variant === "SMALL"
                  ? `${theme.gridBase}px`
                  : `${theme.gridBase * 1.5}px`,
              paddingBottom:
                variant === "SMALL"
                  ? `${theme.gridBase}px`
                  : `${theme.gridBase * 1.5}px`,
              paddingLeft:
                variant === "SMALL"
                  ? `${theme.gridBase * 1.5}px`
                  : `${theme.gridBase * 2}px`,
              paddingRight:
                variant === "SMALL"
                  ? `${theme.gridBase * 1.5}px`
                  : `${theme.gridBase * 2}px`
            },
            ".Input:hover": {
              backgroundColor: theme.palette.grey7
            },
            // Pseudo-class used twice to increase specificity
            ".Input:focus:focus": {
              backgroundColor: theme.palette.white
            },
            ".Input--invalid": {
              backgroundColor: theme.palette.red4,
              boxShadow: "none"
            },
            ".Input--invalid:hover": {
              backgroundColor: theme.palette.red3
            },
            // Pseudo-class used twice to increase specificity
            ".Input--invalid:focus:focus": {
              outline: `1px solid ${theme.palette.red1}`,
              boxShadow: theme.other.focusBoxShadowError
            },
            ".Error": {
              paddingTop: `${theme.gridBase * 0.5}px`
            }
          }
        }
      }}
    >
      {children}
    </Elements>
  );
};
