import { type Environment } from "downshift";
import { createContext, useContext } from "react";

/* ========================================================================== */

const closestShadowRootContext = createContext<ShadowRoot | undefined>(
  undefined
);

export const ClosestShadowRootProvider = closestShadowRootContext.Provider;

/* ========================================================================== */

const useClosestShadowRoot = () => {
  return useContext(closestShadowRootContext);
};

export const useClosestShadowRootOrThrow = () => {
  const shadowRoot = useClosestShadowRoot();

  if (shadowRoot !== undefined) {
    return shadowRoot;
  } else {
    throw new Error("`useClosestShadowRootOrThrow`: value not set");
  }
};

export const useClosestShadowRootOrDocument = () => {
  const shadowRoot = useClosestShadowRoot();

  if (shadowRoot !== undefined) {
    return shadowRoot;
  } else {
    return document;
  }
};

/* ========================================================================== */

export const useDownshiftEnvironment = (): Environment => {
  const shadowRoot = useClosestShadowRoot();

  if (shadowRoot !== undefined) {
    const modifiedDocument = {
      body: window.document.body,
      createElement: window.document.createElement.bind(document),
      getElementById: shadowRoot.getElementById.bind(shadowRoot),
      activeElement: shadowRoot.activeElement
    };

    return {
      document: modifiedDocument as Document,
      addEventListener: shadowRoot.addEventListener.bind(shadowRoot),
      removeEventListener: shadowRoot.removeEventListener.bind(shadowRoot),
      Node: window.Node
    };
  } else {
    return window;
  }
};
