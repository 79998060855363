import { useState } from "react";
import styled from "styled-components";

import {
  ButtonSecondary,
  ButtonWarning
} from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  normalBodyStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { Modal } from "./Modal";

/* ========================================================================== */

type ActionWarningModalProps = {
  isVisible: boolean;
  onClose: () => void;
  isLoading: boolean;
  subheading: string;
  heading: string;
  text: string;
  checkBoxItems: Array<string>;
  confirmActionText: string;
  onConfirmAction: () => void;
  cancelActionText: string;
};

export const ActionWarningModal: React.FC<ActionWarningModalProps> = ({
  isVisible,
  onClose,
  isLoading,
  subheading,
  heading,
  text,
  checkBoxItems,
  confirmActionText,
  onConfirmAction,
  cancelActionText
}) => {
  const [checkedItems, setCheckedItems] = useState<Array<string>>([]);

  const allItemsChecked = checkBoxItems.every(item =>
    checkedItems.includes(item)
  );

  return (
    <Modal isVisible={isVisible} onClose={onClose} disallowClose={isLoading}>
      <ModalContents>
        <ModalSubheading>{subheading}</ModalSubheading>
        <ModalHeading>{heading}</ModalHeading>
        <ModalText>{text}</ModalText>
        {checkBoxItems.map((item, index) => (
          <LabeledCheckBoxMulti
            key={index}
            variant="WARNING"
            isChecked={checkedItems.includes(item)}
            setIsChecked={isChecked => {
              if (isChecked) {
                setCheckedItems([...checkedItems, item]);
              } else {
                setCheckedItems(
                  checkedItems.filter(checkedItem => checkedItem !== item)
                );
              }
            }}
            text={item}
            isDisabled={isLoading}
          />
        ))}
        <ModalButtonWrapper>
          <Tooltip
            placement="top"
            text="Please agree to the above"
            disabled={allItemsChecked}
          >
            <span>
              <ButtonWarning
                variant="SMALL"
                state={
                  isLoading ? "LOADING" : allItemsChecked ? "IDLE" : "DISABLED"
                }
                onClick={onConfirmAction}
              >
                {confirmActionText}
              </ButtonWarning>
            </span>
          </Tooltip>
          <ButtonSecondary
            variant="SMALL"
            state={isLoading ? "DISABLED" : "IDLE"}
            onClick={() => onClose()}
          >
            {cancelActionText}
          </ButtonSecondary>
        </ModalButtonWrapper>
      </ModalContents>
    </Modal>
  );
};

/* ========================================================================== */

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 54.5}px;
`;

const ModalSubheading = styled.div`
  ${subheadingStyles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const ModalHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ModalText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 1.75}px;
  max-width: 95%;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.gridBase * 2.5}px;

  > :first-child {
    flex: 2;
    margin-right: ${props => props.theme.gridBase * 3}px;
    display: flex;
    flex-direction: column;
  }

  > :last-child {
    flex: 1;
  }
`;
