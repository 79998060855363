import { produce } from "immer";
import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { css, useTheme } from "styled-components";

import {
  type EventsConnectorConfig,
  type FacebookActionSource
} from "elevar-common-ts/src/apiTypes";
import {
  reChargeChannel,
  unifiedCheckoutChannel,
  userFacingPredefinedChannels
} from "elevar-common-ts/src/channels";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type FacebookVersions,
  useFacebookVersionsQuery
} from "../../api/handlers/facebookVersions";
import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  type ContainerInfo,
  useContainerInfoQuery,
  useMyTrackingVersionMutation
} from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { ContainerSetupWeb } from "./ContainerSetupWeb";
import { ContentType } from "./ContentType";
import { destinationFb as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { InputFieldChannelCode } from "./InputFieldChannelCode";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { containsNumbersOnly, conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";

/* ========================================================================== */

type DestinationFbProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationFb: React.FC<DestinationFbProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const containerInfo = useContainerInfoQuery({ destination });
  const facebookVersions = useFacebookVersionsQuery();

  if (containerInfo.error !== null || facebookVersions.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined || facebookVersions.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <DestinationFbInner
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      shopifyOAuthScopes={shopifyOAuthScopes}
      containerInfo={containerInfo.data}
      facebookVersions={facebookVersions.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-facebook-pixel-conversion-api-tracking-in-elevar-server-side-destination";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
  facebookVersions: FacebookVersions;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationFbInnerProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
  facebookVersions: FacebookVersions;
};

const DestinationFbInner: React.FC<DestinationFbInnerProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes,
  containerInfo,
  facebookVersions
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Facebook Settings" },
          { name: "Events" },
          { name: "Consent Mode" },
          { name: "Content Type" },
          { name: "Product Identifier" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" },
          { name: "Web Container Setup" }
        ]}
        context={{
          setSaveModalState,
          shopifyOAuthScopes,
          containerInfo,
          facebookVersions
        }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
  }
};

/* ========================================================================== */

const recommendedEvents: ReturnType<typeof useConfig>["enabledEvents"] = {
  addPaymentInfo: true,
  addToCart: true,
  beginCheckout: true,
  pageView: true,
  purchase: true,
  signUp: true,
  subscribe: true,
  subscriptionPurchase: false,
  viewItem: true,
  viewSearchResults: false,
  newCustomerPurchase: true,
  returningCustomerPurchase: true,
  channelLiftStudy: false
};

const recommendedWebEvents: ReturnType<typeof useConfig>["enabledWebEvents"] = {
  addPaymentInfo: true,
  addToCart: true,
  beginCheckout: true,
  pageView: true,
  purchase: true,
  signUp: true,
  subscribe: true,
  subscriptionPurchase: false,
  viewItem: true,
  viewItemList: false,
  viewSearchResults: false
};

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () => (
                  <>
                    The Facebook Dataset ID used is{" "}
                    <span>{config.pixelId}</span>
                  </>
                )
              },
              {
                step: 2,
                type: "EVENTS",
                selectedEvents: {
                  enabledEvents: config.enabledEvents,
                  enabledWebEvents: config.enabledWebEvents,
                  webhookOverrides: config.webhookOverrides
                },
                recommendedEvents: getRecommendedEventState({
                  destination,
                  enabledEvents: recommendedEvents,
                  enabledWebEvents: recommendedWebEvents,
                  webhookOverrides: config.webhookOverrides
                })
              },
              {
                step: 3,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 4,
                type: "CONTENT_TYPE",
                value: config.dataConfig.contentType
              },
              {
                step: 5,
                type: "PRODUCT_IDENTIFIER",
                value: config.dataConfig.productAttributeMapping
              },
              {
                step: 6,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 7,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
      integrationNotes={
        <div>
          Before continuing, please take and save a screenshot of the Match
          Quality Score report in Facebook Event Manager. This will help to
          analyze the before and after results of Elevar's integration.{" "}
          <LinkExternal href="https://docs.getelevar.com/docs/how-to-compare-before-and-after-match-quality-score-in-facebook#take-a-screenshot">
            How do I do this?
          </LinkExternal>
        </div>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

type ChannelActionSourceMapItemEditable = {
  channel: string;
  actionSource: FacebookActionSource | null;
};

type GatewayActionSourceMapItemEditable = {
  gateway: string;
  actionSource: FacebookActionSource | null;
};

type TagActionSourceMapItemEditable = {
  tag: string;
  actionSource: FacebookActionSource | null;
};

const facebookActionSourceOptions: Array<Option<FacebookActionSource>> = [
  { name: "Email", value: "email" },
  { name: "Website", value: "website" },
  { name: "Phone Call", value: "phone_call" },
  { name: "Chat", value: "chat" },
  { name: "Physical Store", value: "physical_store" },
  { name: "System Generated", value: "system_generated" },
  { name: "Other", value: "other" }
];

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, facebookVersions }
  } = useSetupFlowDetails();

  const {
    channelMap: initialChannelActionSourceMap,
    gatewayMap: initialGatewayActionSourceMap,
    tagMap: initialTagActionSourceMap
  } = config.actionSourceMaps;

  const normalizedApiVersion =
    typeof config.apiVersion === "number"
      ? `${config.apiVersion}.0`
      : config.apiVersion;

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  const subscriptionChannelActionSourceMapItems: Array<ChannelActionSourceMapItemEditable> =
    initialChannelActionSourceMap.filter(
      mapItem =>
        (mapItem.channel === unifiedCheckoutChannel.code ||
          mapItem.channel === reChargeChannel.code) &&
        mapItem.actionSource === "system_generated"
    );
  const subscriptionTagActionSourceMapItem = initialTagActionSourceMap.find(
    mapItem =>
      mapItem.tag === config.subscriptionTagName &&
      mapItem.actionSource === "system_generated"
  );

  const [isLoading, setIsLoading] = useState(false);
  const [pixelId, setPixelId] = useState(config.pixelId);
  const [accessToken, setAccessToken] = useState(config.accessToken);
  const [apiVersion, setApiVersion] = useState(normalizedApiVersion);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );
  const [channelActionSourceMap, setChannelActionSourceMap] = useState<
    Array<ChannelActionSourceMapItemEditable>
  >(initialChannelActionSourceMap);
  const [gatewayActionSourceMap, setGatewayActionSourceMap] = useState<
    Array<GatewayActionSourceMapItemEditable>
  >(initialGatewayActionSourceMap);
  const [tagActionSourceMap, setTagActionSourceMap] = useState<
    Array<TagActionSourceMapItemEditable>
  >(initialTagActionSourceMap);

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areFacebookFieldsFilledIn =
    pixelId !== "" && accessToken !== "" && apiVersion !== "";
  const areFacebookFieldsUnique = uniq([pixelId, accessToken]).length === 2;

  const isPixelIdValid = containsNumbersOnly(pixelId);

  const areChannelActionSourceMapItemsFilledIn = channelActionSourceMap.every(
    i => i.channel !== "" && i.actionSource !== null
  );
  const areChannelActionSourceMapItemsUnique =
    uniq(channelActionSourceMap.map(i => i.channel)).length ===
    channelActionSourceMap.length;

  const areGatewayActionSourceMapItemsFilledIn = gatewayActionSourceMap.every(
    i => i.gateway !== "" && i.actionSource !== null
  );
  const areGatewayActionSourceMapItemsUnique =
    uniq(gatewayActionSourceMap.map(i => i.gateway)).length ===
    gatewayActionSourceMap.length;

  const areTagActionSourceMapItemsFilledIn = tagActionSourceMap.every(
    i => i.tag !== "" && i.actionSource !== null
  );
  const areTagActionSourceMapItemsUnique =
    uniq(tagActionSourceMap.map(i => i.tag)).length ===
    tagActionSourceMap.length;

  const facebookVersionOptions: Array<Option> = facebookVersions.versions.map(
    version => ({ name: version, value: version })
  );

  const selectedFacebookVersionOption =
    facebookVersionOptions.find(o => o.value === apiVersion) ??
    facebookVersionOptions.find(o => o.value === facebookVersions.default) ??
    null;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Facebook Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Facebook account.
            </Step1Explainer>
          }
          media={{
            type: "VIDEO",
            url: "https://player.vimeo.com/video/641505568"
          }}
        >
          <Step1SectionInnerWrapper ref={inputWrapperRef}>
            <div>
              <InputWrapper
                labelText="Facebook Dataset ID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 34}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This is the same dataset ID used and deduplicated with
                      your primary dataset events setup via GTM.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={pixelId}
                  onChange={event => setPixelId(event.target.value)}
                  placeholder="1234567890"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Conversion API Token"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 36}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This value is generated inside your Facebook Events
                      Manager settings. Please see{" "}
                      <LinkExternal href="https://docs.getelevar.com/docs/how-to-create-facebook-conversion-api-token">
                        here
                      </LinkExternal>{" "}
                      for how to create it.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldTextArea
                  variant="SMALL"
                  disabled={isLoading}
                  value={accessToken}
                  onChange={event => setAccessToken(event.target.value)}
                  placeholder="Token"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Facebook API Version"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 34}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      Elevar sets this to the latest version by default. See{" "}
                      <LinkExternal href="https://developers.facebook.com/docs/graph-api/changelog/versions">
                        here
                      </LinkExternal>{" "}
                      for more info.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={isLoading}
                  value={selectedFacebookVersionOption}
                  setValue={({ value }) => setApiVersion(value)}
                  options={facebookVersionOptions}
                  maxItemsInView={3}
                  placeholder="Select a Version"
                />
              </InputWrapper>
              <Step1FacebookApiVersionLinkWrapper>
                <StyledLinkExternal
                  href="https://docs.getelevar.com/docs/how-to-set-up-facebook-pixel-conversion-api-tracking-in-elevar-server-side-destination#tip-what-facebook-api-version-to-use"
                  text="Which version should I choose?"
                />
              </Step1FacebookApiVersionLinkWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <div>
              <InputWrapper labelText="Conversion Value" disabled={isLoading}>
                <InputFieldSelect
                  variant="SMALL"
                  disabled={isLoading}
                  value={selectedConversionValueOption}
                  setValue={option => setConversionValue(option.value)}
                  options={conversionValueOptions}
                  placeholder="Select a Conversion Value"
                />
              </InputWrapper>
            </div>
            <div>
              <div>
                Here you can further customize how to send data to Facebook.
              </div>
              <div>
                <Step1AdvancedOptionWrapper
                  showBottomSeparator={
                    channelActionSourceMap.length > 0 ||
                    gatewayActionSourceMap.length > 0
                  }
                >
                  {channelActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={isLoading}
                      onClick={() => {
                        setChannelActionSourceMap([
                          { channel: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Channel</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Channel</div>
                        <div>
                          Note, a channel code is required. Please follow the
                          Setup Guide for details on where to find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {channelActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Channel Code"
                                  tooltip={{
                                    text: 'Examples: "Point of Sale", "Wholesale", "Global-E"'
                                  }}
                                  disabled={
                                    isLoading ||
                                    subscriptionChannelActionSourceMapItems.includes(
                                      item
                                    )
                                  }
                                >
                                  <InputFieldChannelCode
                                    value={item.channel}
                                    setValue={value => {
                                      setChannelActionSourceMap(
                                        produce(
                                          channelActionSourceMap,
                                          draft => {
                                            draft[index]!.channel = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={userFacingPredefinedChannels.map(
                                      c => ({ name: c.name, value: c.code })
                                    )}
                                    placeholder="Code"
                                    disabled={
                                      isLoading ||
                                      subscriptionChannelActionSourceMapItems.includes(
                                        item
                                      )
                                    }
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Facebook{" "}
                                        <LinkExternal href="https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={
                                    isLoading ||
                                    subscriptionChannelActionSourceMapItems.includes(
                                      item
                                    )
                                  }
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={
                                      isLoading ||
                                      subscriptionChannelActionSourceMapItems.includes(
                                        item
                                      )
                                    }
                                    value={
                                      facebookActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setChannelActionSourceMap(
                                        produce(
                                          channelActionSourceMap,
                                          draft => {
                                            draft[index]!.actionSource = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={facebookActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.channel}`}
                                  disabled={
                                    isLoading ||
                                    subscriptionChannelActionSourceMapItems.includes(
                                      item
                                    )
                                  }
                                  onClick={() => {
                                    setChannelActionSourceMap(
                                      produce(channelActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={isLoading}
                          onClick={() => {
                            setChannelActionSourceMap([
                              ...channelActionSourceMap,
                              { channel: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
                <Step1AdvancedOptionWrapper
                  showBottomSeparator={
                    gatewayActionSourceMap.length > 0 ||
                    tagActionSourceMap.length > 0
                  }
                >
                  {gatewayActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={isLoading}
                      onClick={() => {
                        setGatewayActionSourceMap([
                          { gateway: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Order Gateway</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Order Gateway</div>
                        <div>
                          Please follow the Setup Guide for details on where to
                          find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {gatewayActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Order Gateway"
                                  tooltip={{
                                    text: 'Examples: "Money Order", "Paypal"'
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={isLoading}
                                    value={item.gateway}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setGatewayActionSourceMap(
                                        produce(
                                          gatewayActionSourceMap,
                                          draft => {
                                            draft[index]!.gateway = value;
                                          }
                                        )
                                      );
                                    }}
                                    placeholder="Gateway"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Facebook{" "}
                                        <LinkExternal href="https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={isLoading}
                                    value={
                                      facebookActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setGatewayActionSourceMap(
                                        produce(
                                          gatewayActionSourceMap,
                                          draft => {
                                            draft[index]!.actionSource = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={facebookActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.gateway}`}
                                  disabled={isLoading}
                                  onClick={() => {
                                    setGatewayActionSourceMap(
                                      produce(gatewayActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={isLoading}
                          onClick={() => {
                            setGatewayActionSourceMap([
                              ...gatewayActionSourceMap,
                              { gateway: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
                <Step1AdvancedOptionWrapper showBottomSeparator={false}>
                  {tagActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={isLoading}
                      onClick={() => {
                        setTagActionSourceMap([
                          { tag: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Tag</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Tag</div>
                        <div>
                          Please follow the Setup Guide for details on where to
                          find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {tagActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Tag Name"
                                  tooltip={{
                                    text: 'Examples: "Subscription", "Customer Service"'
                                  }}
                                  disabled={
                                    isLoading ||
                                    item === subscriptionTagActionSourceMapItem
                                  }
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={
                                      isLoading ||
                                      item ===
                                        subscriptionTagActionSourceMapItem
                                    }
                                    value={item.tag}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setTagActionSourceMap(
                                        produce(tagActionSourceMap, draft => {
                                          draft[index]!.tag = value;
                                        })
                                      );
                                    }}
                                    placeholder="Tag"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Facebook{" "}
                                        <LinkExternal href="https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={
                                    isLoading ||
                                    item === subscriptionTagActionSourceMapItem
                                  }
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={
                                      isLoading ||
                                      item ===
                                        subscriptionTagActionSourceMapItem
                                    }
                                    value={
                                      facebookActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setTagActionSourceMap(
                                        produce(tagActionSourceMap, draft => {
                                          draft[index]!.actionSource = value;
                                        })
                                      );
                                    }}
                                    options={facebookActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.tag}`}
                                  disabled={
                                    isLoading ||
                                    item === subscriptionTagActionSourceMapItem
                                  }
                                  onClick={() => {
                                    setTagActionSourceMap(
                                      produce(tagActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={isLoading}
                          onClick={() => {
                            setTagActionSourceMap([
                              ...tagActionSourceMap,
                              { tag: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
              </div>
            </div>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areFacebookFieldsFilledIn
              ? "All fields are required"
              : !isPixelIdValid
                ? "The Facebook Dataset ID field must only contain numbers"
                : !areChannelActionSourceMapItemsFilledIn
                  ? "All channel action source map item fields must be filled in"
                  : !areChannelActionSourceMapItemsUnique
                    ? "All channel action source map items must use unique channel codes"
                    : !areGatewayActionSourceMapItemsFilledIn
                      ? "All gateway action source map item fields must be filled in"
                      : !areGatewayActionSourceMapItemsUnique
                        ? "All gateway action source map items must use unique gateways names"
                        : !areTagActionSourceMapItemsFilledIn
                          ? "All tag action source map item fields must be filled in"
                          : !areTagActionSourceMapItemsUnique
                            ? "All tag action source map items must use unique tag names"
                            : !areFacebookFieldsUnique
                              ? "All fields must be unique"
                              : null
          }
          onSave={async data => {
            type C = ReturnType<typeof useConfig>;
            type ChannelMap = C["actionSourceMaps"]["channelMap"];
            type GatewayMap = C["actionSourceMaps"]["gatewayMap"];
            type TagMap = C["actionSourceMaps"]["tagMap"];

            const action = async () => {
              setIsLoading(true);
              await configMutation({
                ...data,
                pixelId,
                accessToken,
                apiVersion,
                dataConfig: { conversionValue },
                actionSourceMaps: {
                  channelMap: channelActionSourceMap as ChannelMap,
                  gatewayMap: gatewayActionSourceMap as GatewayMap,
                  tagMap: tagActionSourceMap as TagMap
                },
                ...(!isStepCompleted ? { completedStep: 1 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
              setIsLoading(false);
            };

            if (config.live) {
              setSaveModalState({ isVisible: true, onConfirm: action });
            } else {
              await action();
            }
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const Step1FacebookApiVersionLinkWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 1.5}px;
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    width: ${props => props.inputWrapperWidth}px;
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:last-child {
    > div:first-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

type Step1AdvancedOptionWrapperProps = {
  showBottomSeparator: boolean;
};

const Step1AdvancedOptionWrapper = styled.div<Step1AdvancedOptionWrapperProps>`
  ${props =>
    props.showBottomSeparator
      ? css`
          margin-bottom: ${props => props.theme.gridBase * 3}px;
          border-bottom: 1px solid ${props => props.theme.palette.grey6};
          padding-bottom: ${props => props.theme.gridBase * 3}px;
        `
      : css`
          margin-bottom: ${props => props.theme.gridBase * 1.5}px;
        `}
`;

const Step1IconTextButton = styled.button`
  ${iconTextButtonStyles};
`;

const Step1MapItemsExistWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const Step1MapItemWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    flex: 1;
    max-width: ${props => props.theme.gridBase * 90}px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.gridBase}px;

    @media screen and (max-width: 1450px) {
      width: 100%;
      grid-template-columns: 1fr;
      gap: ${props => props.theme.gridBase * 1.5}px;
      justify-items: stretch;
    }
  }

  > div:last-child {
    margin-left: ${props => props.theme.gridBase}px;
  }

  &:not(:last-child) {
    @media screen and (max-width: 1450px) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
      border-bottom: 1px solid ${props => props.theme.palette.grey6};
      padding-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step1MapItemRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, shopifyOAuthScopes }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: config.enabledWebEvents,
    webhookOverrides: config.webhookOverrides
  });

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Facebook.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={isLoading}
        isStepCompleted={isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: recommendedWebEvents
        }}
        onSave={async () => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...eventState,
              ...(!isStepCompleted ? { completedStep: 2 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [contentType, setContentType] = useState(config.dataConfig.contentType);

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <ContentType
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      contentType={contentType}
      setContentType={setContentType}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { contentType },
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <ProductIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      media={{
        type: "VIDEO",
        url: "https://player.vimeo.com/video/640915353"
      }}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { productAttributeMapping },
            ...(!isStepCompleted ? { completedStep: 5 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 6;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 6 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 7;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      media={{
        type: "VIDEO",
        url: "https://player.vimeo.com/video/640915069"
      }}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 7 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { containerInfo }
  } = useSetupFlowDetails();

  const { mutateAsync: myTrackingVersionMutation } =
    useMyTrackingVersionMutation();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 8;

  return (
    <ContainerSetupWeb
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      onSave={async () => {
        setIsLoading(true);
        await myTrackingVersionMutation("destination-fb");
        await configMutation({ completedStep: 8 });
        setIsLoading(false);
      }}
    />
  );
};
