type IconShareASaleProps = {
  size: "24px";
  className?: string;
};

export const IconShareASale: React.FC<IconShareASaleProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M13.7102 2.91034L15.9905 7.54587L21.1212 8.2893C23.1384 8.59542 23.6208 9.99482 22.1298 11.438L18.4024 15.0677L19.2795 20.1842C19.6303 22.1959 18.4024 23.0705 16.6045 22.1084L12 19.7032L7.39553 22.1084C5.55374 23.0705 4.36973 22.1959 4.72055 20.1842L5.59759 15.0677L1.87016 11.438C0.379184 9.99482 0.861552 8.59542 2.87875 8.2893L8.00946 7.54587L10.2898 2.91034C11.2984 1.02989 12.7893 1.02989 13.7102 2.91034Z"
            fill="#FAB418"
          />
        </svg>
      );
  }
};
