type IconCirclePartialProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconCirclePartial: React.FC<IconCirclePartialProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2.5C13.8789 2.5 15.7156 3.05716 17.2779 4.10104C18.8402 5.14491 20.0578 6.62861 20.7769 8.36451C21.4959 10.1004 21.684 12.0105 21.3175 13.8534C20.9509 15.6962 20.0461 17.3889 18.7175 18.7175C17.3889 20.0461 15.6962 20.9509 13.8534 21.3175C12.0105 21.684 10.1004 21.4959 8.36451 20.7769C6.62861 20.0578 5.14491 18.8402 4.10104 17.2779C3.05716 15.7157 2.5 13.8789 2.5 12"
            stroke={color}
            strokeLinecap="round"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14"
            stroke={color}
            strokeLinecap="round"
          />
        </svg>
      );
  }
};
