import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { normalTextStyles } from "./typography/typography";

/* ========================================================================== */

export type TabItem = { title: string; isActive: boolean } & (
  | { type: "BUTTON"; onClick: () => void }
  | { type: "INTERNAL_LINK"; to: string }
);

/* ========================================================================== */

type TabsProps<T> = {
  items: Array<T>;
};

export const Tabs = <T extends TabItem>({
  items
}: TabsProps<T>): ReturnType<React.FC> => {
  return (
    <TabsWrapper>
      <div>
        {items.map((item, index) =>
          item.type === "BUTTON" ? (
            <TabButton
              key={index}
              onClick={item.onClick}
              isActiveItem={item.isActive}
            >
              <div>{item.title}</div>
            </TabButton>
          ) : (
            <TabInteralLink
              key={index}
              to={{ pathname: item.to, state: { preventLastLocation: true } }}
              replace={true}
              isActiveItem={item.isActive}
            >
              <div>{item.title}</div>
            </TabInteralLink>
          )
        )}
      </div>
    </TabsWrapper>
  );
};

/* ========================================================================== */

const TabsWrapper = styled.div`
  position: relative;

  > div {
    display: flex;
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: ${props => props.theme.gridBase * 2}px;
    right: 0;
    height: 1px;
    background-color: ${props => props.theme.palette.grey6};
  }
`;

type SharedTabStylesProps = {
  isActiveItem: boolean;
};

const sharedTabStyles = css<SharedTabStylesProps>`
  padding: 0 ${props => props.theme.gridBase * 2}px;
  z-index: 1;

  > div {
    ${normalTextStyles};
    font-weight: 500;
    padding: ${props => props.theme.gridBase * 0.75}px 0;
    color: ${props =>
      props.isActiveItem
        ? props.theme.palette.blue1
        : props.theme.palette.grey4};
    transition: color ${props => props.theme.other.transition};
    position: relative;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${props =>
        props.isActiveItem
          ? props.theme.palette.blue1
          : props.theme.palette.grey6};
      transition: background-color ${props => props.theme.other.transition};
    }
  }

  &:hover {
    > div {
      color: ${props => props.theme.palette.blue1};
    }
  }
`;

const TabButton = styled.button.withConfig({
  shouldForwardProp: prop => !["isActiveItem"].includes(prop)
})<SharedTabStylesProps>`
  ${sharedTabStyles};
`;

const TabInteralLink = styled(Link).withConfig({
  shouldForwardProp: prop => !["isActiveItem"].includes(prop)
})<SharedTabStylesProps>`
  ${sharedTabStyles};
`;
