import { createContext, useContext } from "react";

/* ========================================================================== */

const pageScrollContainerContext = createContext<HTMLDivElement | undefined>(
  undefined
);

export const PageScrollContainerProvider = pageScrollContainerContext.Provider;

export const usePageScrollContainer = () => {
  const pageScrollContainer = useContext(pageScrollContainerContext);

  if (pageScrollContainer !== undefined) {
    return pageScrollContainer;
  } else {
    throw new Error("`usePageScrollContainer`: value not set");
  }
};
