import { useId, useState } from "react";
import styled, { useTheme } from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogoWithText } from "elevar-design-system/src/logos";
import {
  heading2Styles,
  largeTextStyles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { clearApiAuthToken } from "./api/utils";
import { track } from "./utils/track";

/* ========================================================================== */

const storageKey = "isLargeViewportOnlyWarningOverridden";
const getIsOverriden = () => Boolean(sessionStorage.getItem(storageKey));
const setIsOverriden = () => sessionStorage.setItem(storageKey, "true");
const getBreakpoint = getIsOverriden() ? 0 : 1200;

/* ========================================================================== */

type LargeViewportOnlyProps = {
  showLogout: boolean;
  children?: React.ReactNode;
};

export const LargeViewportOnly: React.FC<LargeViewportOnlyProps> = ({
  showLogout,
  children
}) => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint);

  return (
    <>
      <NotSuppportedOverlay
        showLogout={showLogout}
        breakpoint={breakpoint}
        continueToApp={() => {
          setIsOverriden();
          setBreakpoint(0);
        }}
      />
      <VisuallyHideIfNotSuppported breakpoint={breakpoint}>
        {children}
      </VisuallyHideIfNotSuppported>
    </>
  );
};

type VisuallyHideIfNotSuppportedProps = {
  breakpoint: number;
};

const VisuallyHideIfNotSuppported = styled.div<VisuallyHideIfNotSuppportedProps>`
  height: 100%;

  @media screen and (max-width: ${props => props.breakpoint}px) {
    display: none;
  }
`;

/* ========================================================================== */

type NotSuppportedOverlayProps = {
  showLogout: boolean;
  breakpoint: number;
  continueToApp: () => void;
};

const NotSuppportedOverlay: React.FC<NotSuppportedOverlayProps> = ({
  showLogout,
  breakpoint,
  continueToApp
}) => {
  return (
    <PositioningWrapper breakpoint={breakpoint}>
      <ContentWrapper>
        <LogoWrapper>
          <ElevarLogoWithText />
        </LogoWrapper>
        <IllustrationWrapper>
          <LaptopIllustration />
        </IllustrationWrapper>
        <TextWrapper>
          <Heading>Screen Size Not Optimized</Heading>
          <BodyText>
            The majority of our users are using larger screen sizes, so we've
            focused here for now. You can either zoom out in your browser, or
            still use our app at smaller sizes by clicking the button below, but
            we just want to warn you the layout might not be perfect.
          </BodyText>
        </TextWrapper>
        <ContinueToAppButton variant="SMALL" onClick={continueToApp}>
          Continue to App
        </ContinueToAppButton>
        {showLogout ? (
          <LogOutButton
            onClick={() => {
              clearApiAuthToken();
              track.userLogout();
              window.location.href = "/";
            }}
          >
            Log Out
          </LogOutButton>
        ) : null}
      </ContentWrapper>
    </PositioningWrapper>
  );
};

type PositioningWrapperProps = {
  breakpoint: number;
};

const PositioningWrapper = styled.div<PositioningWrapperProps>`
  position: fixed;
  inset: 0;
  background-color: ${props => props.theme.palette.grey7};
  z-index: 4999; // positioned above overlays + pop outs but below toasts

  @media not screen and (max-width: ${props => props.breakpoint}px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.gridBase * 5}px;
  margin-bottom: ${props => props.theme.gridBase * 6}px;

  > svg {
    height: ${props => props.theme.gridBase * 3}px;
  }
`;

const IllustrationWrapper = styled.div`
  display: flex;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
  margin-bottom: ${props => props.theme.gridBase * 6}px;

  > svg {
    width: 100%;
    max-width: ${props => props.theme.gridBase * 30}px;
  }
`;

const TextWrapper = styled.div`
  text-align: center;
  padding: 0 ${props => props.theme.gridBase * 3}px;
  max-width: ${props => props.theme.gridBase * 68}px;
`;

const Heading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const BodyText = styled.div`
  ${largeTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ContinueToAppButton = styled(ButtonPrimary)`
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const LogOutButton = styled.button`
  ${normalBodyStyles};
  ${linkStyles};
  padding: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const LaptopIllustration: React.FC = () => {
  const theme = useTheme();
  const idPrefix = useId();
  const linearGradient1Id = `${idPrefix}-linearGradient1`;
  const linearGradient2Id = `${idPrefix}-linearGradient2`;
  const linearGradient3Id = `${idPrefix}-linearGradient3`;

  return (
    <svg viewBox="0 0 250 250">
      <polygon
        points="250.06 182.92 220.19 178.35 128.96 236.51 155.03 236.51 250.06 182.92"
        fill={`url(#${linearGradient1Id})`}
        opacity={0.02}
      />
      <path
        d="M18.85,169.72l82.2-47,113.66,65.69-82.44,47.11a7.69,7.69,0,0,1-7.68,0L18.86,174.44A2.73,2.73,0,0,1,18.85,169.72Z"
        fill="#D7DEE2"
      />
      <rect
        x={197.97}
        y={180.38}
        width={16.74}
        height={7.98}
        transform="translate(412.68 368.74) rotate(-180)"
        fill="#D7DEE2"
      />
      <rect
        x={17.49}
        y={163.98}
        width={16.74}
        height={8.04}
        transform="translate(51.73 336) rotate(-180)"
        fill="#D7DEE2"
      />
      <path
        d="M18.85,161.72l82.2-47,113.66,65.69-82.44,47.1a7.64,7.64,0,0,1-7.68,0l-105.73-61A2.73,2.73,0,0,1,18.85,161.72Z"
        fill={`url(#${linearGradient2Id})`}
      />
      <path
        d="M64.26,145l38-21.94a1.08,1.08,0,0,1,1.1,0L199,178.34a1.11,1.11,0,0,1,0,1.91l-38,21.88a1.07,1.07,0,0,1-1.09,0L64.26,146.94A1.11,1.11,0,0,1,64.26,145Z"
        fill="#E4E9ED"
      />
      <rect
        x={102.93}
        y={19.42}
        width={5.01}
        height={5.01}
        transform="translate(207.7 -11.8) rotate(150)"
        fill="#D7DEE2"
      />
      <path
        d="M106.34,16.72l.23,94.7,113.62,65.76-.28-94.95a7.71,7.71,0,0,0-3.83-6.66L110.43,14.37A2.73,2.73,0,0,0,106.34,16.72Z"
        fill="#D7DEE2"
      />
      <rect
        x={100.69}
        y={20.07}
        width={16.74}
        height={6.39}
        transform="translate(143.29 129.4) rotate(-119.92)"
        fill="#D7DEE2"
      />
      <rect
        x={204.87}
        y={168.32}
        width={16.74}
        height={6.39}
        transform="translate(170.93 441.88) rotate(-119.92)"
        fill="#D7DEE2"
      />
      <path
        d="M100.79,19.93l.22,94.7,113.63,65.75-.29-94.94a7.65,7.65,0,0,0-3.83-6.66L104.87,17.58A2.72,2.72,0,0,0,100.79,19.93Z"
        fill={`url(#${linearGradient3Id})`}
      />
      <path
        d="M104.77,22.48l-.43,89.06a1.53,1.53,0,0,0,.75,1.32L208.46,173a1.25,1.25,0,0,0,1.88-1.07l.36-85.77a3.91,3.91,0,0,0-1.93-3.38L105,22.34A.16.16,0,0,0,104.77,22.48Z"
        fill="#F4F7F9"
      />
      <path
        d="M63.62,181.44l23-13.19a1.08,1.08,0,0,1,1.07,0L123.51,189a1.07,1.07,0,0,1,0,1.85l-23,13.17a1.05,1.05,0,0,1-1.07,0L63.62,183.29A1.07,1.07,0,0,1,63.62,181.44Z"
        fill="#E4E9ED"
      />
      <path
        d="M158.57,106.6a7.19,7.19,0,0,1-5.65.08h0l8.61,5c2.24,1.3,4.28,1.16,5.2-.35h0Z"
        fill={theme.palette.blue3}
      />
      <path
        d="M161.1,101.36a7.36,7.36,0,0,1-.77,2.9l7.91,4.57,1.52-2.48Z"
        fill="#31AFFF"
      />
      <polygon
        points="161.13 94.67 161.13 98.02 171.28 103.88 172.79 101.41 161.13 94.67"
        fill={theme.palette.blueBuxton}
      />
      <path
        d="M157.78,86h0a12.76,12.76,0,0,0-5.37-1.81A5.57,5.57,0,0,0,146.15,89a13.19,13.19,0,0,0-.2,2.72V102a15.83,15.83,0,0,1-.12,2.52c-.27,1.63-1,3-2.46,3.35a3.43,3.43,0,0,1-2.09-.24,1.06,1.06,0,0,0-.25-.11.21.21,0,0,1,0,.08c0,.12,0,.27,0,.4v1.39a12.15,12.15,0,0,0,5.8,1.79,4.74,4.74,0,0,0,4.14-1.92,5.94,5.94,0,0,0,.89-2.95,21.47,21.47,0,0,1-3.44-2.49c2.81,1,5.39.94,7.11-.37,1.48-1.13,2.25-3.11,2.25-5.58V89.38l16.53,9.55,1.52-2.48Zm-5.92,14.64a2.69,2.69,0,0,1-1.29-2c0-.72.58-1,1.29-.55a2.68,2.68,0,0,1,1.3,2C153.16,100.84,152.58,101.08,151.86,100.67Z"
        fill="#6254EB"
      />
      <defs>
        <linearGradient
          id={linearGradient1Id}
          x1={125.62}
          y1={224.04}
          x2={252}
          y2={190.18}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} />
          <stop offset={0.78} stopOpacity={0.05} />
          <stop offset={1} stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id={linearGradient2Id}
          x1={17.49}
          y1={171.6}
          x2={214.71}
          y2={171.6}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor={theme.palette.white} />
          <stop offset={1} stopColor={theme.palette.white} />
        </linearGradient>
        <linearGradient
          id={linearGradient3Id}
          x1={100.79}
          y1={98.79}
          x2={214.64}
          y2={98.79}
          xlinkHref={`#${linearGradient2Id}`}
        />
      </defs>
    </svg>
  );
};
