import { Route, Switch, useParams } from "react-router-dom";
import styled from "styled-components";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import {
  type AccountCompanyList,
  useAccountCompanyListQuery
} from "../api/handlers/account";
import { RedirectWithoutLastLocation } from "../context/LastLocation";

/* ========================================================================== */

type AliasType = "COMPANY" | "WEBSITE";

export type AliasRedirectState = {
  alias: {
    path: string;
    type: AliasType;
  };
};

type AliasRouteParams = { path?: string };

/* ========================================================================== */

export const Alias: React.FC = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path="/alias/company/:path*"
        render={() => <AliasWithData type="COMPANY" />}
      />
      <Route
        exact={true}
        path="/alias/website/:path*"
        render={() => <AliasWithData type="WEBSITE" />}
      />
      <RedirectWithoutLastLocation to="/" />
    </Switch>
  );
};

/* ========================================================================== */

type AliasWithDataProps = {
  type: AliasType;
};

const AliasWithData: React.FC<AliasWithDataProps> = ({ type }) => {
  const accountCompanyList = useAccountCompanyListQuery();

  if (accountCompanyList.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  return (
    <>
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
      {accountCompanyList.data !== undefined ? (
        <>
          {type === "COMPANY" ? (
            <CompanyAlias accountCompanyList={accountCompanyList.data} />
          ) : null}
          {type === "WEBSITE" ? (
            <WebsiteAlias accountCompanyList={accountCompanyList.data} />
          ) : null}
        </>
      ) : null}
    </>
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type CompanyAliasProps = {
  accountCompanyList: AccountCompanyList;
};

const CompanyAlias: React.FC<CompanyAliasProps> = ({ accountCompanyList }) => {
  const params = useParams<AliasRouteParams>();
  const path = `${params.path ?? ""}${location.search}`;

  const companies = accountCompanyList;

  if (companies.length === 0) {
    return <RedirectWithoutLastLocation to="/" />;
  }

  if (companies.length === 1) {
    const company = companies[0]!;
    const to = `/company/${company.id}/${path}`;
    return <RedirectWithoutLastLocation to={to} />;
  }

  return (
    <RedirectWithoutLastLocation
      to={{
        pathname: "/",
        state: { alias: { path, type: "COMPANY" } }
      }}
    />
  );
};

/* ========================================================================== */

type WebsiteAliasProps = {
  accountCompanyList: AccountCompanyList;
};

const WebsiteAlias: React.FC<WebsiteAliasProps> = ({ accountCompanyList }) => {
  const params = useParams<AliasRouteParams>();
  const path = `${params.path ?? ""}${location.search}`;

  const websites = accountCompanyList.flatMap(company =>
    company.websites.map(website => ({ ...website, company: company.id }))
  );

  if (websites.length === 0) {
    return <RedirectWithoutLastLocation to="/" />;
  }

  if (websites.length === 1) {
    const website = websites[0]!;
    const to = `/company/${website.company}/website/${website.id}/${path}`;
    return <RedirectWithoutLastLocation to={to} />;
  }

  return (
    <RedirectWithoutLastLocation
      to={{
        pathname: "/",
        state: { alias: { path, type: "WEBSITE" } }
      }}
    />
  );
};
