import { useId } from "react";

/* ========================================================================== */

type IconCircledInfoProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconCircledInfo: React.FC<IconCircledInfoProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  const idPrefix = useId();
  const clipPathId = `${idPrefix}-clipPath`;

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 16V12"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 8H12.01"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath={`url(#${clipPathId})`}>
            <path
              d="M8.00002 1.33332C4.31812 1.33332 1.33335 4.31809 1.33335 7.99999C1.33335 11.6819 4.31812 14.6667 8.00002 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 7.99999C14.6667 4.31809 11.6819 1.33332 8.00002 1.33332Z"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 10.6667L8 7.99999"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 5.33334L7.99292 5.33334"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id={clipPathId}>
              <rect
                width="16"
                height="16"
                fill="white"
                transform="translate(16 16) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      );
  }
};
