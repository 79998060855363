import { useEffect, useState } from "react";
import styled from "styled-components";

import { browserExtensionConstants } from "elevar-common-ts/src/constants";

import { IconCheckMark } from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../api/handlers/appEducationSlideshows";
import { FeatureTipsButton } from "../components/FeatureTipsButton";
import { PageCard } from "../components/PageCard";
import { VideoPlayer } from "../components/VideoPlayer";

/* ========================================================================== */

const extensionQuerySelector = (selector: string) => {
  const extensionElement = browserExtensionConstants.CUSTOM_ELEMENT_NAME;
  const shadowHost = document.body.querySelector(extensionElement);
  const shadowRoot = shadowHost?.shadowRoot;
  return shadowRoot?.querySelector(selector);
};

const getIsInstalled = (): boolean => {
  const selector = `#${browserExtensionConstants.EXTENSION_ROOT_ELEMENT_ID}`;
  return Boolean(extensionQuerySelector(selector));
};

const getIsAuthed = (): boolean => {
  const selector = `#${browserExtensionConstants.IS_TOKEN_PRESENT_ELEMENT_ID}`;
  return Boolean(extensionQuerySelector(selector));
};

/* ========================================================================== */

type EventBuilderProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
};

export const EventBuilder: React.FC<EventBuilderProps> = ({
  appEducationSlideshows
}) => {
  const [isInstalled, setIsInstalled] = useState(getIsInstalled);
  const [isAuthed, setIsAuthed] = useState(getIsAuthed);

  useEffect(() => {
    const mutationObserver = new MutationObserver(() => {
      setIsInstalled(getIsInstalled);
      setIsAuthed(getIsAuthed);
    });

    mutationObserver.observe(document.body, {
      subtree: true,
      childList: true,
      attributeFilter: ["id"]
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, []);

  return (
    <EventBuilderWrapper>
      <PageHeader>
        <PageHeading>Event Builder</PageHeading>
        <FeatureTipsButton
          data={appEducationSlideshows}
          route="EVENT_BUILDER"
        />
      </PageHeader>
      <StatusPageCard>
        {!isInstalled || !isAuthed ? (
          <StatusPromptBanner>
            Both of these need to be checked for you to use the extension
          </StatusPromptBanner>
        ) : null}
        <StatusInner>
          <div>
            <StatusItem>
              <div>
                <StatusItemHeader>
                  <StatusItemCheckWrapper isChecked={isInstalled}>
                    <IconCheckMark size="16px" />
                  </StatusItemCheckWrapper>
                  <StatusItemHeading>
                    1. Install Chrome Extension
                  </StatusItemHeading>
                </StatusItemHeader>
                <StatusItemExplainer>
                  Firstly, you will need to install our extension from the
                  Chrome Store. This lets us access your website's page elements
                  for selectors, which we use when creating your events and
                  showing what's tagged.
                </StatusItemExplainer>
              </div>
              <div>
                <StyledLinkExternal
                  href="https://chrome.google.com/webstore/detail/elevar-gtm-event-builder/aanpkkmackhljjnbcopdoidpdjngfooc"
                  text="Get Extension"
                />
              </div>
            </StatusItem>
          </div>
          <div>
            <StatusItem>
              <div>
                <StatusItemHeader>
                  <StatusItemCheckWrapper isChecked={isAuthed}>
                    <IconCheckMark size="16px" />
                  </StatusItemCheckWrapper>
                  <StatusItemHeading>
                    2. Authenticate Extension
                  </StatusItemHeading>
                </StatusItemHeader>
                <StatusItemExplainer>
                  Secondly, you will need to authenticate the extension using
                  our super simple 1 click auth process. This will mean your
                  personal account will be shared between Elevar App & the Event
                  Builder. Now create your first event!
                </StatusItemExplainer>
              </div>
              <div>
                <StyledLinkExternal
                  href="/extension-sync"
                  text="Go to Auth page"
                />
              </div>
            </StatusItem>
          </div>
        </StatusInner>
      </StatusPageCard>
      <GetStartedPageCard>
        <div>
          <GetStartedHeading>Get Started</GetStartedHeading>
          <GetStartedExplainer>
            Save time by point-and-clicking your way to creating custom events
            for Google Analytics through your own browser. We automatically
            create the tag and trigger inside of Google Tag Manager so you can
            unlock insights about what features help convert your users.
          </GetStartedExplainer>
        </div>
        <div>
          <VideoPlayer url="https://player.vimeo.com/video/440335114" />
        </div>
      </GetStartedPageCard>
    </EventBuilderWrapper>
  );
};

/* ========================================================================== */

const EventBuilderWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  min-height: ${props => props.theme.gridBase * 5}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
`;

const StatusPageCard = styled(PageCard)`
  padding: 0;
  width: 100%;
  overflow: hidden;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const StatusPromptBanner = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.white};
  background: ${props => props.theme.other.gradientRight};
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
`;

const StatusInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    padding-right: ${props => props.theme.gridBase * 3}px;
    border-right: 1px solid ${props => props.theme.palette.grey8};
  }

  > div:last-child {
    padding-left: ${props => props.theme.gridBase * 3}px;
    border-left: 1px solid ${props => props.theme.palette.grey8};
  }
`;

const StatusItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StatusItemHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

type StatusItemCheckWrapperProps = {
  isChecked: boolean;
};

const StatusItemCheckWrapper = styled.div<StatusItemCheckWrapperProps>`
  width: ${props => props.theme.gridBase * 2.5}px;
  height: ${props => props.theme.gridBase * 2.5}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: ${props => props.theme.gridBase * 2}px;
  color: ${props => props.theme.palette.white};
  background-color: ${props =>
    props.isChecked ? props.theme.palette.purple2 : props.theme.palette.grey6};
  transition: background-color ${props => props.theme.other.transition};
`;

const StatusItemHeading = styled.div`
  ${heading3Styles};
`;

const StatusItemExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  width: 95%;
`;

const GetStartedPageCard = styled(PageCard)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > div:first-child {
    padding-right: ${props => props.theme.gridBase * 3}px;
  }
`;

const GetStartedHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const GetStartedExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  width: 95%;
`;
