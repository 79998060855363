import { type UnknownRecord } from "type-fest";

/* ========================================================================== */

export const assertUnreachable = (_value: never): Error => {
  return new Error("Unreachable scenario reached");
};

export const sleepForMs = (ms: number) => {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
};

export const sleepUntilNextTick = (args?: { fakeTimersActive: boolean }) => {
  if (args?.fakeTimersActive) {
    return Promise.resolve();
  } else {
    return sleepForMs(0);
  }
};

export const isNotNull = <T>(v: T | null): v is T => v !== null;
export const isDefined = <T>(v: T | undefined): v is T => v !== undefined;

export type NonEmptyArray<T> = [T, ...Array<T>];
export type OptionalPromise<T> = T | Promise<T>;

export const toQueryString = (
  obj: Record<
    string,
    string | number | Array<string | number> | undefined | null
  >
) => {
  const params = new URLSearchParams();
  Object.entries(obj)
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, value]): [string, string | Array<string>] => [
      key,
      Array.isArray(value) ? value.map(x => String(x)) : String(value)
    ])
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => params.append(key, v));
      } else {
        params.append(key, value);
      }
    });
  return params.toString();
};

/**
 * This is flagged as unsafe because `Object.keys` returns `Array<string>`
 * for good reason (see {@link https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208 here}
 * for why this is the case).
 *
 * For scenarios where we are confident that the object we want to get the
 * keys of is exactly the shape of it's type (or when additional keys would
 * not matter), this utility is preferred for ergonomic reasons, and because
 * the types won't deviate if the type parameter is inferred.
 */
export const unsafeTypedObjectKeys = <T extends UnknownRecord>(
  value: T
): Array<keyof T> => {
  return Object.keys(value) as Array<keyof T>;
};

/**
 * This is flagged as unsafe because `Object.entries` returns `Array<[string, T]>`
 * for good reason (see {@link https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208 here}
 * for why this is the case).
 *
 * For scenarios where we are confident that the object we want to get the
 * entries of is exactly the shape of it's type (or when additional keys would
 * not matter), this utility is preferred for ergonomic reasons, and because
 * the types won't deviate if the type parameter is inferred.
 */
export const unsafeTypedEntries = <T extends string | number | symbol, U>(
  value: Record<T, U>
): Array<[T, U]> => {
  return Object.entries<U>(value) as Array<[T, U]>;
};

export const typedFromEntries = <T extends string | number | symbol, U>(
  value: Array<[T, U]>
): Record<T, U> => {
  return Object.fromEntries<U>(value) as Record<T, U>;
};
