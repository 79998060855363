import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import {
  IconCircledInfo,
  IconHelp,
  IconLock
} from "elevar-design-system/src/icons";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading1Styles,
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type WebsiteRoiStats } from "../api/handlers/website";
import { PageCard } from "./PageCard";

/* ========================================================================== */

type RoiStatsProps = {
  stats: WebsiteRoiStats;
};

export const RoiStats: React.FC<RoiStatsProps> = ({ stats }) => {
  const theme = useTheme();

  const rawMinutesSaved = stats.time_saved * 60;
  const daysSaved = Math.floor(rawMinutesSaved / 60 / 24);
  const hoursSaved = Math.floor(rawMinutesSaved / 60 - daysSaved * 24);
  const minutesSaved = Math.floor(
    rawMinutesSaved - daysSaved * 24 * 60 - hoursSaved * 60
  );

  const dollarsSaved = Math.round(stats.money_saved).toLocaleString("en");

  return (
    <div>
      <RoiStatsCancelPlanHeading>
        Elevar powers your underlying data foundation
      </RoiStatsCancelPlanHeading>
      <RoiStatsItems>
        <RoiStatsItem
          Icon={IconEventsCreated}
          name="Events Created"
          value={stats.events_created}
          hoverText="We estimate you save 15 minutes per Google Tag Manager event created with Elevar. Tag away!"
        />
        <RoiStatsItem
          Icon={IconContainersDownloaded}
          name="Containers Downloaded"
          value={stats.containers_downloaded}
          hoverText="The total number of containers you've downloaded from our pre-built tags and browser extension."
        />
        <RoiStatsItem
          Icon={IconConversionsTracked}
          name="Conversions Tracked"
          value={stats.conversions_tracked}
          hoverText="The total number of conversions sent to server-side destinations in the last 7 days."
        />
      </RoiStatsItems>
      <RoiStatsSavings>
        <div>Elevar has saved you</div>
        <div>
          {daysSaved !== 0 ? <span>{daysSaved}d</span> : null}
          {hoursSaved !== 0 ? <span>{hoursSaved}h</span> : null}
          {minutesSaved !== 0 || (daysSaved === 0 && hoursSaved === 0) ? (
            <span>{minutesSaved}m</span>
          ) : null}
        </div>
        <div>
          <div>${dollarsSaved}</div>
          <div>
            <TooltipBig
              placement="top"
              maxWidth={`${theme.gridBase * 48}px`}
              render={() => (
                <RoiStatsTooltipContent>
                  <p>
                    Quantifies the savings from using Elevar's pre-built tags
                    compared to hiring a GTM expert or reading through
                    documentation on how to build it yourself.
                  </p>
                  <p>
                    Includes the time and $ saved by creating custom events with
                    our browser extension instead of going through your source
                    code to find the proper triggers.
                  </p>
                  <p>
                    For example: [(15 custom events created x 15 mins) + (4
                    pre-built containers downloaded x 3 hours)] x $125 per hour
                    of a GTM Expert = $1,969.
                  </p>
                </RoiStatsTooltipContent>
              )}
            >
              <RoiStatsTooltipTarget>
                <IconHelp size="16px" />
              </RoiStatsTooltipTarget>
            </TooltipBig>
          </div>
        </div>
      </RoiStatsSavings>
    </div>
  );
};

const RoiStatsCancelPlanHeading = styled(PageCard)`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 0.25}px;
`;

const RoiStatsItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: ${props => props.theme.gridBase * 19}px;
  gap: ${props => props.theme.gridBase * 0.25}px;
  margin-bottom: ${props => props.theme.gridBase * 0.25}px;
`;

const RoiStatsSavings = styled(PageCard)`
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${props => props.theme.gridBase * 0.25}px;

  > div:nth-child(1) {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    padding: ${props => props.theme.gridBase * 3}px;
  }

  > div:nth-child(2) {
    ${heading3Styles};
    padding: ${props => props.theme.gridBase * 3}px;

    > span:not(:last-child) {
      margin-right: ${props => props.theme.gridBase * 0.5}px;
    }
  }

  > div:nth-child(3) {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 0.75}px;
    padding: ${props => props.theme.gridBase * 3}px;

    > div:first-child {
      ${heading3Styles};
    }
  }
`;

const RoiStatsTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const RoiStatsTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
  padding: ${props => props.theme.gridBase * 0.5}px;
  margin-right: ${props => props.theme.gridBase * 0.5}px;
`;

/* ========================================================================== */

type IconProps = {
  locked: boolean;
};

type RoiStatsItemProps = {
  Icon: React.FC<IconProps>;
  name: string;
  value: number | null;
  hoverText: string;
};

const RoiStatsItem: React.FC<RoiStatsItemProps> = ({
  Icon,
  name,
  value,
  hoverText
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showHoverOverlay, setShowHoverOverlay] = useState(false);

  useEffect(() => {
    const time = isHovered ? 150 : 0;
    const timeoutId = setTimeout(() => {
      setShowHoverOverlay(isHovered);
    }, time);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isHovered]);

  if (value === null) {
    return (
      <RoiStatsItemWrapper
        isLink={false}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div>
          <div>
            <RoiStatsItemIconWrapper>
              <Icon locked={true} />
            </RoiStatsItemIconWrapper>
            <RoiStatsItemName>{name}</RoiStatsItemName>
          </div>
          <div>
            <RoiStatsItemFeatureNotInPlan>
              Feature Not in Plan
            </RoiStatsItemFeatureNotInPlan>
          </div>
        </div>
        <div>
          <RoiStatsItemStateIconWrapper>
            <IconLock size="16px" />
          </RoiStatsItemStateIconWrapper>
        </div>
      </RoiStatsItemWrapper>
    );
  } else {
    return (
      <RoiStatsItemWrapper
        isLink={false}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div>
          <div>
            <RoiStatsItemIconWrapper>
              <Icon locked={false} />
            </RoiStatsItemIconWrapper>
            <RoiStatsItemName>{name}</RoiStatsItemName>
          </div>
          <div>
            <RoiStatsItemValue>{value.toLocaleString("en")}</RoiStatsItemValue>
          </div>
        </div>
        <div>
          <RoiStatsItemStateIconWrapper>
            <IconCircledInfo size="16px" />
          </RoiStatsItemStateIconWrapper>
        </div>
        <RoiStatsItemHoverOverlay isVisible={showHoverOverlay}>
          <RoiStatsItemHoverText>{hoverText}</RoiStatsItemHoverText>
        </RoiStatsItemHoverOverlay>
      </RoiStatsItemWrapper>
    );
  }
};

type RoiStatsItemWrapperProps = {
  isLink: boolean;
};

const RoiStatsItemWrapper = styled(PageCard).withConfig({
  shouldForwardProp: prop => !["isLink"].includes(prop)
})<RoiStatsItemWrapperProps>`
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  cursor: ${props => (props.isLink ? "pointer" : "auto")};

  > div:first-child {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const RoiStatsItemIconWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const RoiStatsItemName = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
`;

const RoiStatsItemStateIconWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

const RoiStatsItemFeatureNotInPlan = styled.div`
  ${normalTextStyles};
  font-weight: 500;
`;

const RoiStatsItemValue = styled.div`
  ${heading1Styles};
`;

type RoiStatsItemHoverOverlayProps = {
  isVisible: boolean;
};

const RoiStatsItemHoverOverlay = styled.div<RoiStatsItemHoverOverlayProps>`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.palette.grey8};
  padding: 0 ${props => props.theme.gridBase * 3}px;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition};
`;

const RoiStatsItemHoverText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const IconContainersDownloaded: React.FC<IconProps> = ({ locked }) => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M21.4001 31.7L9.40006 19.6C8.60006 18.8 8.10006 17.6 8.20006 16.4L9.30006 5.8C9.40006 4.9 10.1001 4.1 11.1001 4L21.7001 2.9C22.9001 2.8 24.1001 3.2 24.9001 4.1L37.0001 16.2C38.6001 17.8 38.6001 20.3 37.0001 21.9L27.1001 31.8C25.5001 33.3 23.0001 33.3 21.4001 31.7Z"
        fill={locked ? theme.palette.grey5 : theme.palette.blue2}
      />
      <path
        d="M16.5 12C17.3284 12 18 11.3284 18 10.5C18 9.67157 17.3284 9 16.5 9C15.6716 9 15 9.67157 15 10.5C15 11.3284 15.6716 12 16.5 12Z"
        fill={theme.palette.white}
      />
      <path
        d="M14 38C20.6274 38 26 32.6274 26 26C26 19.3726 20.6274 14 14 14C7.37258 14 2 19.3726 2 26C2 32.6274 7.37258 38 14 38Z"
        fill={locked ? theme.palette.grey6 : "#99D6FF"}
      />
      <path
        d="M20 27V29.7C20 30.4 19.4 31 18.7 31H9.3C8.6 31 8 30.4 8 29.7V27"
        stroke={theme.palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7 23.7L14 27L17.3 23.7"
        stroke={theme.palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 27V19"
        stroke={theme.palette.white}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IconEventsCreated: React.FC<IconProps> = ({ locked }) => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M24.3 24H8.7C6.1 24 4 21.9 4 19.3V9.7C4 7.1 6.1 5 8.7 5H24.3C26.9 5 29 7.1 29 9.7V19.3C29 21.9 26.9 24 24.3 24Z"
        fill={locked ? theme.palette.grey5 : "#6254EB"}
      />
      <path
        d="M11 14.6V14.5C11 14.3 11.2 14.1 11.4 14.1H21.5C21.7 14.1 21.9 14.3 21.9 14.5V14.6C21.9 14.8 21.7 15 21.5 15H11.4C11.2 15 11 14.8 11 14.6Z"
        fill={theme.palette.white}
      />
      <path
        d="M16.4 9H16.5C16.8 9 17 9.2 17 9.4V19.5C17 19.7 16.8 19.9 16.6 19.9H16.5C16.3 19.9 16.1 19.7 16.1 19.5V9.4C16 9.2 16.2 9 16.4 9Z"
        fill={theme.palette.white}
      />
      <path
        d="M24.7001 36.2L18.4001 17.8C18.1001 17 18.9001 16.3 19.7001 16.5L38.0001 22.8C38.9001 23.1 38.9001 24.3 38.1001 24.7L30.4001 27.8C30.2001 27.9 30.0001 28.1 29.9001 28.3L26.6001 36.2C26.2001 37.1 25.0001 37.1 24.7001 36.2Z"
        fill={locked ? theme.palette.grey6 : "#B0ABF4"}
      />
      <path
        d="M8 3C4.7 3 2 5.7 2 9"
        stroke={locked ? theme.palette.grey4 : "#5424E1"}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M2 20C2 23.3 4.7 26 8 26"
        stroke={locked ? theme.palette.grey4 : "#5424E1"}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M31 9C31 5.7 28.3 3 25 3"
        stroke={locked ? theme.palette.grey4 : "#5424E1"}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

const IconConversionsTracked: React.FC<IconProps> = ({ locked }) => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M36 16C36 17.2 33.8 18.3 30.2 19.1C27.4 19.6 23.9 20 20 20C16.1 20 12.6 19.6 9.8 19.1C6.2 18.3 4 17.2 4 16C4 14.9 5.7 13.9 8.5 13.2C11.4 12.4 15.5 12 20 12C24.5 12 28.6 12.5 31.5 13.2C34.3 13.9 36 14.9 36 16Z"
        fill={locked ? theme.palette.grey4 : "#10A5DE"}
      />
      <path
        d="M20 23C26.0751 23 31 18.0751 31 12C31 5.92487 26.0751 1 20 1C13.9249 1 9 5.92487 9 12C9 18.0751 13.9249 23 20 23Z"
        fill={locked ? theme.palette.grey5 : theme.palette.blue3}
      />
      <path
        d="M36 16C36 17.2 33.8 18.3 30.2 19.1C27.4 19.6 23.9 20 20 20C16.1 20 12.6 19.6 9.8 19.1C6.2 18.3 4 17.2 4 16C4 18 11.3 25.5 16 30.1V36.2C16 36.7 16.3 37.1 16.8 37.2L22.8 38.7C23.4 38.9 24 38.4 24 37.7V30.1C28.7 25.5 36 18 36 16Z"
        fill={locked ? theme.palette.grey6 : "#9DE5F4"}
      />
      <path
        d="M26.4333 11.4667L24.2667 8L20.28 15.8L16.5533 11.4667L14.3 14.9333L13 13.2"
        stroke={theme.palette.white}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
