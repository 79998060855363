type IconYoutubeProps = {
  size: "24px";
  className?: string;
};

export const IconYoutube: React.FC<IconYoutubeProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M22.9035 6.50269C22.6401 5.5221 21.8644 4.74642 20.8838 4.48298C19.1129 4 11.9854 4 11.9854 4C11.9854 4 4.85784 4.01464 3.08693 4.49761C2.10634 4.76105 1.33066 5.53674 1.06722 6.51732C0.598877 8.28823 0.598877 11.991 0.598877 11.991C0.598877 11.991 0.598877 15.6939 1.08185 17.4794C1.34529 18.46 2.12098 19.2357 3.10157 19.4991C4.87247 19.9821 12 19.9821 12 19.9821C12 19.9821 19.1276 19.9821 20.8985 19.4991C21.8791 19.2357 22.6547 18.46 22.9182 17.4794C23.4012 15.7085 23.4012 11.991 23.4012 11.991C23.4012 11.991 23.3865 8.28823 22.9035 6.50269Z"
            fill="#FF0000"
          />
          <path
            d="M9.7168 15.4157L15.6296 11.991L9.7168 8.56628V15.4157Z"
            fill="white"
          />
        </svg>
      );
  }
};
