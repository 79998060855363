import { transparentize } from "polished";
import styled, {
  css,
  type StyledComponentPropsWithRef
} from "styled-components";

import { IconCircledInfo } from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import {
  LabeledCheckBoxStandalone,
  type LabeledCheckBoxStandaloneProps
} from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxStandalone";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Tooltip, TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  normalBodyStyles,
  normalTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type EventTableRow<TKey extends string, TContext> = {
  key: TKey;
  name: string;
  tooltip: { maxWidth?: number; content: () => React.ReactNode };
  tag?: { text: string; color: string } | null;
} & (TContext extends undefined
  ? { context?: TContext }
  : { context: TContext });

type EventTableColumnSize = { min: string; max: string; gap?: string };
type EventTableIntersectTooltip = { maxWidth?: number; text: string } | null;
type CheckBoxProps = LabeledCheckBoxStandaloneProps;
type InputTextProps = StyledComponentPropsWithRef<typeof InputFieldText>;

type EventTableColumn<TKey extends string, TContext> = {
  /** Setting to `null` will hide this column. */
  name: string | null;
  size: EventTableColumnSize;
  tooltip?: { maxWidth?: number; content: () => React.ReactNode } | null;
  getIntersectTooltip?: (
    row: EventTableRow<TKey, TContext>
  ) => EventTableIntersectTooltip;
} & (
  | {
      type: "CHECK_BOX";
      getAccessors: (
        row: EventTableRow<TKey, TContext>,
        intersectTooltip: EventTableIntersectTooltip
      ) => {
        isChecked: CheckBoxProps["isChecked"];
        setIsChecked: CheckBoxProps["setIsChecked"];
        isDisabled: CheckBoxProps["isDisabled"];
      } | null;
    }
  | {
      type: "INPUT_TEXT";
      getAccessors: (
        row: EventTableRow<TKey, TContext>,
        intersectTooltip: EventTableIntersectTooltip
      ) => {
        value: InputTextProps["value"];
        onChange: InputTextProps["onChange"];
        placeholder: InputTextProps["placeholder"];
        isDisabled: InputTextProps["disabled"];
        isVisible: boolean;
      } | null;
    }
);

/* ========================================================================== */

export type EventTableProps<TKey extends string, TContext = undefined> = {
  rows: Array<EventTableRow<TKey, TContext>>;
  columns: Array<EventTableColumn<NoInfer<TKey>, NoInfer<TContext>>>;
};

export const EventTable = <TKey extends string, TContext>({
  rows,
  columns
}: EventTableProps<TKey, TContext>): ReturnType<React.FC> => {
  const filteredColumns = columns.filter(c => c.name !== null);

  return (
    <EventTableWrapper>
      <EventTableHead>
        <tr>
          <EventTableBlankColumn />
          {filteredColumns.map(({ type, name, size, tooltip }, index) => (
            <EventTableColumnName key={index} data={{ size, type }}>
              <div>
                <div>{name}</div>
                {tooltip ? (
                  <TooltipBig
                    placement="top"
                    maxWidth={`${tooltip.maxWidth}px`}
                    render={() => (
                      <EventTableTooltipBigContent>
                        {tooltip.content()}
                      </EventTableTooltipBigContent>
                    )}
                  >
                    <EventTableTooltipBigTrigger>
                      <IconCircledInfo size="16px" />
                    </EventTableTooltipBigTrigger>
                  </TooltipBig>
                ) : null}
              </div>
            </EventTableColumnName>
          ))}
        </tr>
      </EventTableHead>
      <EventTableBody>
        {rows.map(row => (
          <tr key={row.key}>
            <EventTableRowName>
              <div>
                <div>{row.name}</div>
                <TooltipBig
                  placement="top"
                  maxWidth={`${row.tooltip.maxWidth}px`}
                  render={() => (
                    <EventTableTooltipBigContent>
                      {row.tooltip.content()}
                    </EventTableTooltipBigContent>
                  )}
                >
                  <EventTableTooltipBigTrigger>
                    <IconCircledInfo size="16px" />
                  </EventTableTooltipBigTrigger>
                </TooltipBig>
                {row.tag ? (
                  <Tag color={row.tag.color}>{row.tag.text}</Tag>
                ) : null}
              </div>
            </EventTableRowName>
            {filteredColumns.map(
              ({ type, size, getIntersectTooltip, getAccessors }, index) => {
                const tooltip = getIntersectTooltip?.(row) ?? null;
                return (
                  <EventTableIntersect key={index} data={{ size, type }}>
                    <Tooltip
                      placement="top"
                      maxWidth={
                        tooltip?.maxWidth ? `${tooltip.maxWidth}px` : undefined
                      }
                      text={tooltip?.text ?? ""}
                      disabled={!tooltip}
                      hideOnClick={false}
                    >
                      <div>
                        {(() => {
                          switch (type) {
                            case "CHECK_BOX": {
                              const accessors = getAccessors(row, tooltip);
                              return accessors ? (
                                <LabeledCheckBoxStandalone
                                  variant="NORMAL"
                                  isChecked={accessors.isChecked}
                                  setIsChecked={accessors.setIsChecked}
                                  isDisabled={accessors.isDisabled}
                                />
                              ) : null;
                            }
                            case "INPUT_TEXT": {
                              const accessors = getAccessors(row, tooltip);
                              return accessors ? (
                                <InputFieldHideable
                                  variant="SMALL"
                                  value={accessors.value}
                                  onChange={accessors.onChange}
                                  placeholder={accessors.placeholder}
                                  disabled={accessors.isDisabled}
                                  isVisible={accessors.isVisible}
                                  spellCheck={false}
                                  autoCapitalize="off"
                                />
                              ) : null;
                            }
                          }
                        })()}
                      </div>
                    </Tooltip>
                  </EventTableIntersect>
                );
              }
            )}
          </tr>
        ))}
      </EventTableBody>
    </EventTableWrapper>
  );
};

/* ========================================================================== */

const EventTableWrapper = styled.table`
  border-collapse: collapse;

  th,
  td {
    padding: 0;
  }
`;

const EventTableHead = styled.thead``;

const EventTableBlankColumn = styled.th`
  min-width: min-content;
  width: min-content;
`;

type EventTableColumnNameProps = {
  data: {
    size: EventTableColumnSize;
    type: EventTableColumn<string, unknown>["type"];
  };
};

const EventTableColumnName = styled.th<EventTableColumnNameProps>`
  min-width: ${props => props.data.size.min};
  width: ${props => props.data.size.max};

  > div {
    display: flex;
    justify-content: ${props =>
      props.data.type === "INPUT_TEXT" ? "left" : "center"};
    align-items: center;
    text-align: ${props =>
      props.data.type === "INPUT_TEXT" ? "left" : "center"};
    gap: ${props => props.theme.gridBase}px;
    padding-bottom: ${props => props.theme.gridBase}px;

    ${props =>
      props.data.size.gap
        ? css`
            padding-left: ${props.data.size.gap};
            padding-right: ${props.data.size.gap};
          `
        : null};

    > div:first-child {
      ${subheadingStyles};
      color: ${props => props.theme.palette.grey3};

      @media screen and (max-width: 1350px) {
        width: min-content;
      }
    }

    > div:not(:first-child) {
      color: ${props => props.theme.palette.grey4};
    }
  }
`;

const EventTableTooltipBigTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

type TagProps = {
  color: string;
};

const Tag = styled.div<TagProps>`
  ${normalTextStyles};
  color: ${props => props.color};
  background-color: ${props => transparentize(0.9, props.color)};
  padding-top: ${props => props.theme.gridBase * 0.25}px;
  padding-bottom: ${props => props.theme.gridBase * 0.25}px;
  padding-right: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase}px;
  margin-left: ${props => props.theme.gridBase}px;
  margin-right: ${props => props.theme.gridBase}px;
  border-radius: 4px;
  white-space: nowrap;
`;

const EventTableTooltipBigContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }

  a {
    ${linkStyles};
  }

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }
`;

const EventTableBody = styled.tbody`
  > tr {
    border-top: 1px solid ${props => props.theme.palette.grey7};
  }
`;

const EventTableRowName = styled.td`
  > div {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    white-space: nowrap;
    padding-left: ${props => props.theme.gridBase * 2}px;
    padding-right: ${props => props.theme.gridBase * 4}px;
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 1.5}px;
    white-space: nowrap;
  }
`;

type EventTableIntersectProps = {
  data: {
    size: EventTableColumnSize;
    type: EventTableColumn<string, unknown>["type"];
  };
};

const EventTableIntersect = styled.td<EventTableIntersectProps>`
  height: ${props =>
    props.data.type === "INPUT_TEXT"
      ? props.theme.gridBase * 7
      : props.theme.gridBase * 5.5}px;

  > div {
    display: flex;
    justify-content: center;

    ${props =>
      props.data.size.gap
        ? css`
            padding-left: ${props.data.size.gap};
            padding-right: ${props.data.size.gap};
          `
        : null};
  }
`;

type InputFieldHideableProps = {
  isVisible: boolean;
};

const InputFieldHideable = styled(InputFieldText)<InputFieldHideableProps>`
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition:
    background-color ${props => props.theme.other.transition},
    color ${props => props.theme.other.transition},
    border-color ${props => props.theme.other.transition},
    box-shadow ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition},
    visibility ${props => props.theme.other.transition};
`;
