type IconServerProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconServer: React.FC<IconServerProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M5.75 6.375C5.75 6.02982 6.02982 5.75 6.375 5.75H13.875C14.2202 5.75 14.5 6.02982 14.5 6.375C14.5 6.72018 14.2202 7 13.875 7H6.375C6.02982 7 5.75 6.72018 5.75 6.375Z"
            fill={color}
          />
          <path
            d="M18.25 6.375C18.25 6.72018 17.9702 7 17.625 7C17.2798 7 17 6.72018 17 6.375C17 6.02982 17.2798 5.75 17.625 5.75C17.9702 5.75 18.25 6.02982 18.25 6.375Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V8.75C22 9.85457 21.1046 10.75 20 10.75H4C2.89543 10.75 2 9.85457 2 8.75V4ZM4 3H20C20.5523 3 21 3.44772 21 4V8.75C21 9.30228 20.5523 9.75 20 9.75H4C3.44772 9.75 3 9.30228 3 8.75V4C3 3.44772 3.44772 3 4 3Z"
            fill={color}
          />
          <path
            d="M6.375 17C6.02982 17 5.75 17.2798 5.75 17.625C5.75 17.9702 6.02982 18.25 6.375 18.25H13.875C14.2202 18.25 14.5 17.9702 14.5 17.625C14.5 17.2798 14.2202 17 13.875 17H6.375Z"
            fill={color}
          />
          <path
            d="M17.625 18.25C17.9702 18.25 18.25 17.9702 18.25 17.625C18.25 17.2798 17.9702 17 17.625 17C17.2798 17 17 17.2798 17 17.625C17 17.9702 17.2798 18.25 17.625 18.25Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 15.25C2 14.1454 2.89543 13.25 4 13.25H20C21.1046 13.25 22 14.1454 22 15.25V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V15.25ZM4 14.25H20C20.5523 14.25 21 14.6977 21 15.25V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V15.25C3 14.6977 3.44772 14.25 4 14.25Z"
            fill={color}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 1H2C1.44772 1 1 1.44772 1 2V5C1 5.55228 1.44772 6 2 6H14C14.5523 6 15 5.55228 15 5V2C15 1.44772 14.5523 1 14 1ZM2 0C0.895431 0 0 0.89543 0 2V5C0 6.10457 0.895431 7 2 7H14C15.1046 7 16 6.10457 16 5V2C16 0.895431 15.1046 0 14 0H2Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 10H2C1.44772 10 1 10.4477 1 11V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V11C15 10.4477 14.5523 10 14 10ZM2 9C0.895431 9 0 9.89543 0 11V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V11C16 9.89543 15.1046 9 14 9H2Z"
            fill={color}
          />
          <rect x="3" y="3" width="7" height="1" rx="0.5" fill={color} />
          <rect x="3" y="12" width="7" height="1" rx="0.5" fill={color} />
          <circle cx="12.5" cy="3.5" r="0.5" fill={color} />
          <circle cx="12.5" cy="12.5" r="0.5" fill={color} />
        </svg>
      );
  }
};
