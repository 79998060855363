type IconLabelProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconLabel: React.FC<IconLabelProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle cx="8" cy="8" r="1" fill={color} />
          <path
            d="M2.5 3C2.5 2.72386 2.72386 2.5 3 2.5H13.1741C13.5708 2.5 13.9514 2.65714 14.2325 2.93703L21.8041 10.4757C22.392 11.0611 22.3931 12.0126 21.8064 12.5993L12.6171 21.7886C12.0313 22.3744 11.0815 22.3744 10.4957 21.7886L2.93934 14.2322C2.65804 13.9509 2.5 13.5694 2.5 13.1716V3Z"
            stroke={color}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <circle cx="5.33342" cy="5.33317" r="0.666667" fill={color} />
          <path
            d="M9.37064 2.07629L9.72058 1.72483L9.37064 2.07629L14.4184 7.10207C14.745 7.4273 14.7456 7.95593 14.4196 8.28187L8.29344 14.4081C7.96801 14.7335 7.44037 14.7335 7.11493 14.4081L2.07733 9.37047C1.92105 9.21419 1.83325 9.00222 1.83325 8.78121V2.00016C1.83325 1.90812 1.90787 1.8335 1.99992 1.8335H8.78267C9.00305 1.8335 9.21446 1.9208 9.37064 2.07629Z"
            stroke={color}
          />
        </svg>
      );
  }
};
