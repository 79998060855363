import { useTheme } from "styled-components";

/* ========================================================================== */

type IconDataLayerV2Props = {
  size: "24px";
  className?: string;
};

export const IconDataLayerV2: React.FC<IconDataLayerV2Props> = ({
  size,
  className
}) => {
  const theme = useTheme();

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10.56 22.62L3.65992 17.52C2.33992 16.56 2.33992 14.58 3.65992 13.62L10.62 8.58C11.46 7.98 12.6 7.98 13.4399 8.58L20.4 13.62C21.7199 14.58 21.7199 16.56 20.4 17.52L13.4399 22.56C12.54 23.22 11.46 23.22 10.56 22.62Z"
            fill="#6254EB"
          />
          <path
            d="M20.3401 13.68L19.7401 13.2L13.4401 17.82C12.6001 18.42 11.4601 18.42 10.6201 17.82L4.26011 13.2L3.60011 13.68C2.28011 14.64 2.28011 16.62 3.60011 17.5799L10.5601 22.62C11.4001 23.2199 12.5401 23.2199 13.3801 22.62L20.3401 17.5799C21.6601 16.56 21.6601 14.64 20.3401 13.68Z"
            fill="#5424E1"
          />
          <path
            d="M21.3599 8.40002V10.8C21.3599 11.52 20.9999 12.24 20.3999 12.72L13.4399 17.76C12.5999 18.36 11.4599 18.36 10.6199 17.76L3.59989 12.72C2.93989 12.24 2.63989 11.52 2.63989 10.8V8.40002H21.3599Z"
            fill="#6254EB"
          />
          <path
            d="M10.56 15.4201L3.65992 10.3201C2.33992 9.36006 2.33992 7.38006 3.65992 6.42006L10.62 1.38005C11.46 0.780054 12.6 0.780054 13.4399 1.38005L20.4 6.42006C21.7199 7.38006 21.7199 9.36006 20.4 10.3201L13.4399 15.3601C12.54 16.0201 11.46 16.0201 10.56 15.4201Z"
            fill="#B0ABF4"
          />
          <path
            d="M7.74207 10.8H11.0455V10.2375H8.6114V10.1966L9.78752 8.93864C10.6875 7.97474 10.9534 7.52474 10.9534 6.94436C10.9534 6.1364 10.2989 5.49207 9.37845 5.49207C8.46057 5.49207 7.76253 6.11594 7.76253 7.0364H8.36595C8.36595 6.44066 8.75198 6.04436 9.35798 6.04436C9.92558 6.04436 10.3602 6.39206 10.3602 6.94436C10.3602 7.4276 10.0764 7.78556 9.47048 8.44772L7.74207 10.3398V10.8ZM12.5507 10.8409C12.8038 10.8409 13.011 10.6338 13.011 10.3807C13.011 10.1276 12.8038 9.92048 12.5507 9.92048C12.2976 9.92048 12.0905 10.1276 12.0905 10.3807C12.0905 10.6338 12.2976 10.8409 12.5507 10.8409ZM15.5025 10.8716C16.6582 10.8716 17.3229 9.89234 17.3229 8.18186C17.3229 6.4841 16.6479 5.49207 15.5025 5.49207C14.3571 5.49207 13.6821 6.4841 13.6821 8.18186C13.6821 9.89234 14.3468 10.8716 15.5025 10.8716ZM15.5025 10.3091C14.7406 10.3091 14.2957 9.54464 14.2957 8.18186C14.2957 6.8216 14.7457 6.04436 15.5025 6.04436C16.2593 6.04436 16.7093 6.8216 16.7093 8.18186C16.7093 9.54464 16.2644 10.3091 15.5025 10.3091Z"
            fill={theme.palette.white}
          />
        </svg>
      );
  }
};
