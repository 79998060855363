import { produce } from "immer";
import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { css, useTheme } from "styled-components";

import {
  type EventsConnectorConfig,
  type PinterestActionSource
} from "elevar-common-ts/src/apiTypes";
import { userFacingPredefinedChannels } from "elevar-common-ts/src/channels";

import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconChevronDown,
  IconChevronUp,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import {
  type ContainerInfo,
  useContainerInfoQuery,
  useMyTrackingVersionMutation
} from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { ContainerSetupWeb } from "./ContainerSetupWeb";
import { destinationPin as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { InputFieldChannelCode } from "./InputFieldChannelCode";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { containsNumbersOnly, conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

type DestinationPinProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationPin: React.FC<DestinationPinProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const containerInfo = useContainerInfoQuery({ destination });

  if (containerInfo.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerInfo.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <DestinationPinInner
      isCompanyAdmin={isCompanyAdmin}
      eventsConnectorConfig={eventsConnectorConfig}
      shopifyOAuthScopes={shopifyOAuthScopes}
      containerInfo={containerInfo.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-pinterest-in-elevar-server-side-destination";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationPinInnerProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
  containerInfo: ContainerInfo;
};

const DestinationPinInner: React.FC<DestinationPinInnerProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes,
  containerInfo
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Pinterest Settings" },
          { name: "Events" },
          { name: "Consent Mode" },
          { name: "Transaction Identifier" },
          { name: "Product Identifier" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" },
          { name: "Web Container Setup" }
        ]}
        context={{ setSaveModalState, shopifyOAuthScopes, containerInfo }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
  }
};

/* ========================================================================== */

const recommendedEvents: ReturnType<typeof useConfig>["enabledEvents"] = {
  addToCart: true,
  pageView: true,
  purchase: true,
  signUp: true,
  subscribe: true,
  viewItem: true,
  viewItemList: true,
  viewSearchResults: true
};

const recommendedWebEvents: ReturnType<typeof useConfig>["enabledWebEvents"] = {
  addToCart: true,
  pageView: true,
  purchase: true,
  signUp: true,
  subscribe: true,
  viewItem: true,
  viewItemList: true,
  viewSearchResults: true
};

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () => (
                  <>
                    The Pinterest Tag ID used is <span>{config.tagId}</span>
                  </>
                )
              },
              {
                step: 2,
                type: "EVENTS",
                selectedEvents: {
                  enabledEvents: config.enabledEvents,
                  enabledWebEvents: config.enabledWebEvents,
                  webhookOverrides: config.webhookOverrides
                },
                recommendedEvents: getRecommendedEventState({
                  destination,
                  enabledEvents: recommendedEvents,
                  enabledWebEvents: recommendedWebEvents,
                  webhookOverrides: config.webhookOverrides
                })
              },
              {
                step: 3,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 4,
                type: "TRANSACTION_IDENTIFIER",
                value: config.dataConfig.orderAttributeId
              },
              {
                step: 5,
                type: "PRODUCT_IDENTIFIER",
                value: config.dataConfig.productAttributeMapping
              },
              {
                step: 6,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 7,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
      integrationNotes={
        <div>
          <p>
            Pinterest has a limit of 5000 requests per minute. If your store has
            more requests, we recommend disabling the pageview and other events
            (see the “Events” step).
          </p>
          <p>
            The Pinterest Sales Channel does not allow you to separate use of
            the Catalog vs the Pixel in the app. You can either use another
            software for your Pinterest Feed Management like{" "}
            <LinkExternal href="https://help.pinterest.com/en/business/article/upload-your-products-with-a-third-party">
              GoDataFeed
            </LinkExternal>{" "}
            or continue to use the native app for your Pinterest Pixel tracking.
          </p>
        </div>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

type ChannelActionSourceMapItemEditable = {
  channel: string;
  actionSource: PinterestActionSource | null;
};

type GatewayActionSourceMapItemEditable = {
  gateway: string;
  actionSource: PinterestActionSource | null;
};

type TagActionSourceMapItemEditable = {
  tag: string;
  actionSource: PinterestActionSource | null;
};

const pinterestActionSourceOptions: Array<Option<PinterestActionSource>> = [
  { name: "App (Android)", value: "app_android" },
  { name: "App (iOS)", value: "app_ios" },
  { name: "Web", value: "web" },
  { name: "Offline", value: "offline" }
];

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const {
    channelMap: initialChannelActionSourceMap,
    gatewayMap: initialGatewayActionSourceMap,
    tagMap: initialTagActionSourceMap
  } = config.actionSourceMaps;

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  const [isLoading, setIsLoading] = useState(false);
  const [tagId, setTagId] = useState(config.tagId);
  const [adAccountId, setAdAccountId] = useState(config.adAccountId);
  const [accessToken, setAccessToken] = useState(config.accessToken);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );
  const [channelActionSourceMap, setChannelActionSourceMap] = useState<
    Array<ChannelActionSourceMapItemEditable>
  >(initialChannelActionSourceMap);
  const [gatewayActionSourceMap, setGatewayActionSourceMap] = useState<
    Array<GatewayActionSourceMapItemEditable>
  >(initialGatewayActionSourceMap);
  const [tagActionSourceMap, setTagActionSourceMap] = useState<
    Array<TagActionSourceMapItemEditable>
  >(initialTagActionSourceMap);

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const arePinterestFieldsFilledIn =
    tagId !== "" && adAccountId !== "" && accessToken !== "";
  const arePinterestFieldsUnique =
    uniq([tagId, adAccountId, accessToken]).length === 3;

  const isTagIdValid = containsNumbersOnly(tagId);
  const isAdAccountIdValid = containsNumbersOnly(adAccountId);
  const isAccessTokenValid =
    accessToken.startsWith("pina_") && accessToken.length > 5;

  const areChannelActionSourceMapItemsFilledIn = channelActionSourceMap.every(
    i => i.channel !== "" && i.actionSource !== null
  );
  const areChannelActionSourceMapItemsUnique =
    uniq(channelActionSourceMap.map(i => i.channel)).length ===
    channelActionSourceMap.length;

  const areGatewayActionSourceMapItemsFilledIn = gatewayActionSourceMap.every(
    i => i.gateway !== "" && i.actionSource !== null
  );
  const areGatewayActionSourceMapItemsUnique =
    uniq(gatewayActionSourceMap.map(i => i.gateway)).length ===
    gatewayActionSourceMap.length;

  const areTagActionSourceMapItemsFilledIn = tagActionSourceMap.every(
    i => i.tag !== "" && i.actionSource !== null
  );
  const areTagActionSourceMapItemsUnique =
    uniq(tagActionSourceMap.map(i => i.tag)).length ===
    tagActionSourceMap.length;

  const pinterestVersionOptions: Array<Option> = [{ name: "5", value: "5" }];
  const selectedPinterestVersionOption = pinterestVersionOptions[0]!;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="Pinterest Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your Pinterest account.
            </Step1Explainer>
          }
        >
          <Step1SectionInnerWrapper ref={inputWrapperRef}>
            <div>
              <InputWrapper
                labelText="Pinterest Tag ID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 35}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is the same pixel ID used and deduplicated with
                        your primary pixel events setup via GTM.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-pinterest-tag-id"
                        text="How do I find this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={tagId}
                  onChange={event => setTagId(event.target.value)}
                  placeholder="1234567890"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Pinterest Ad Account ID"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 36}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is the Pinterest Ad Account that you want to send
                        server-side data to.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-find-pinterest-ad-account-id"
                        text="How do I find this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={adAccountId}
                  onChange={event => setAdAccountId(event.target.value)}
                  placeholder="1234567890"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Conversion Access Token"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 30}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>
                        This is an authentication token generated in Ads
                        Manager.
                      </p>
                      <StyledLinkExternal
                        href="https://docs.getelevar.com/docs/how-to-generate-pinterest-access-token"
                        text="How do I generate this?"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldTextArea
                  variant="SMALL"
                  disabled={isLoading}
                  value={accessToken}
                  onChange={event => setAccessToken(event.target.value)}
                  placeholder="Token"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="Pinterest API Version"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 25}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      <p>Elevar sets this to the latest version by default.</p>
                      <StyledLinkExternal
                        href="https://developers.pinterest.com/docs/new/changelog/"
                        text="Learn More"
                      />
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldSelect
                  variant="SMALL"
                  disabled={true}
                  value={selectedPinterestVersionOption}
                  setValue={() => null}
                  options={pinterestVersionOptions}
                  placeholder="Select a Version"
                />
              </InputWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <div>
              <InputWrapper labelText="Conversion Value" disabled={isLoading}>
                <InputFieldSelect
                  variant="SMALL"
                  disabled={isLoading}
                  value={selectedConversionValueOption}
                  setValue={option => setConversionValue(option.value)}
                  options={conversionValueOptions}
                  placeholder="Select a Conversion Value"
                />
              </InputWrapper>
            </div>
            <div>
              <div>
                Here you can further customize how to send data to Pinterest.
              </div>
              <div>
                <Step1AdvancedOptionWrapper
                  showBottomSeparator={
                    channelActionSourceMap.length > 0 ||
                    gatewayActionSourceMap.length > 0
                  }
                >
                  {channelActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={isLoading}
                      onClick={() => {
                        setChannelActionSourceMap([
                          { channel: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Channel</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Channel</div>
                        <div>
                          Note, a channel code is required. Please follow the
                          Setup Guide for details on where to find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {channelActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Channel Code"
                                  tooltip={{
                                    text: 'Examples: "Point of Sale", "Wholesale", "Global-E"'
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldChannelCode
                                    value={item.channel}
                                    setValue={value => {
                                      setChannelActionSourceMap(
                                        produce(
                                          channelActionSourceMap,
                                          draft => {
                                            draft[index]!.channel = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={userFacingPredefinedChannels.map(
                                      c => ({ name: c.name, value: c.code })
                                    )}
                                    placeholder="Code"
                                    disabled={isLoading}
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Pinterest{" "}
                                        <LinkExternal href="https://developers.pinterest.com/docs/conversions/conversions/#Required,%20recommended,%20and%20optional%20fields">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={isLoading}
                                    value={
                                      pinterestActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setChannelActionSourceMap(
                                        produce(
                                          channelActionSourceMap,
                                          draft => {
                                            draft[index]!.actionSource = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={pinterestActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.channel}`}
                                  disabled={isLoading}
                                  onClick={() => {
                                    setChannelActionSourceMap(
                                      produce(channelActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={isLoading}
                          onClick={() => {
                            setChannelActionSourceMap([
                              ...channelActionSourceMap,
                              { channel: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
                <Step1AdvancedOptionWrapper
                  showBottomSeparator={
                    gatewayActionSourceMap.length > 0 ||
                    tagActionSourceMap.length > 0
                  }
                >
                  {gatewayActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={isLoading}
                      onClick={() => {
                        setGatewayActionSourceMap([
                          { gateway: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Order Gateway</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Order Gateway</div>
                        <div>
                          Please follow the Setup Guide for details on where to
                          find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {gatewayActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Order Gateway"
                                  tooltip={{
                                    text: 'Examples: "Money Order", "Paypal"'
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={isLoading}
                                    value={item.gateway}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setGatewayActionSourceMap(
                                        produce(
                                          gatewayActionSourceMap,
                                          draft => {
                                            draft[index]!.gateway = value;
                                          }
                                        )
                                      );
                                    }}
                                    placeholder="Gateway"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Pinterest{" "}
                                        <LinkExternal href="https://developers.pinterest.com/docs/conversions/conversions/#Required,%20recommended,%20and%20optional%20fields">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={isLoading}
                                    value={
                                      pinterestActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setGatewayActionSourceMap(
                                        produce(
                                          gatewayActionSourceMap,
                                          draft => {
                                            draft[index]!.actionSource = value;
                                          }
                                        )
                                      );
                                    }}
                                    options={pinterestActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.gateway}`}
                                  disabled={isLoading}
                                  onClick={() => {
                                    setGatewayActionSourceMap(
                                      produce(gatewayActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={isLoading}
                          onClick={() => {
                            setGatewayActionSourceMap([
                              ...gatewayActionSourceMap,
                              { gateway: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
                <Step1AdvancedOptionWrapper showBottomSeparator={false}>
                  {tagActionSourceMap.length === 0 ? (
                    <Step1IconTextButton
                      disabled={isLoading}
                      onClick={() => {
                        setTagActionSourceMap([
                          { tag: "", actionSource: null }
                        ]);
                      }}
                    >
                      <div>
                        <IconCog size="24px" />
                      </div>
                      <div>Customize by Tag</div>
                    </Step1IconTextButton>
                  ) : (
                    <Step1MapItemsExistWrapper>
                      <div>
                        <div>Customize by Tag</div>
                        <div>
                          Please follow the Setup Guide for details on where to
                          find this.
                        </div>
                      </div>
                      <div>
                        <div>
                          {tagActionSourceMap.map((item, index) => (
                            <Step1MapItemWrapper key={index}>
                              <div>
                                <InputWrapper
                                  labelText="Tag Name"
                                  tooltip={{
                                    text: 'Examples: "Subscription", "Customer Service"'
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldText
                                    variant="SMALL"
                                    disabled={isLoading}
                                    value={item.tag}
                                    onChange={event => {
                                      const value = event.target.value;
                                      setTagActionSourceMap(
                                        produce(tagActionSourceMap, draft => {
                                          draft[index]!.tag = value;
                                        })
                                      );
                                    }}
                                    placeholder="Tag"
                                    spellCheck={false}
                                    autoCapitalize="off"
                                  />
                                </InputWrapper>
                                <InputWrapper
                                  labelText="Action Source"
                                  tooltip={{
                                    maxWidth: `${theme.gridBase * 36}px`,
                                    render: () => (
                                      <Step1InputWrapperTooltipContent>
                                        Learn more about the "action_source"
                                        setting from Pinterest{" "}
                                        <LinkExternal href="https://developers.pinterest.com/docs/conversions/conversions/#Required,%20recommended,%20and%20optional%20fields">
                                          here
                                        </LinkExternal>
                                        .
                                      </Step1InputWrapperTooltipContent>
                                    )
                                  }}
                                  disabled={isLoading}
                                >
                                  <InputFieldSelect
                                    variant="SMALL"
                                    disabled={isLoading}
                                    value={
                                      pinterestActionSourceOptions.find(
                                        o => o.value === item.actionSource
                                      ) ?? null
                                    }
                                    setValue={({ value }) => {
                                      setTagActionSourceMap(
                                        produce(tagActionSourceMap, draft => {
                                          draft[index]!.actionSource = value;
                                        })
                                      );
                                    }}
                                    options={pinterestActionSourceOptions}
                                    maxItemsInView={3}
                                    placeholder="Source"
                                  />
                                </InputWrapper>
                              </div>
                              <div>
                                <Step1MapItemRemoveButton
                                  aria-label={`Remove: ${item.tag}`}
                                  disabled={isLoading}
                                  onClick={() => {
                                    setTagActionSourceMap(
                                      produce(tagActionSourceMap, draft => {
                                        draft.splice(index, 1);
                                      })
                                    );
                                  }}
                                >
                                  <IconCross size="16px" />
                                </Step1MapItemRemoveButton>
                              </div>
                            </Step1MapItemWrapper>
                          ))}
                        </div>
                        <Step1IconTextButton
                          disabled={isLoading}
                          onClick={() => {
                            setTagActionSourceMap([
                              ...tagActionSourceMap,
                              { tag: "", actionSource: null }
                            ]);
                          }}
                        >
                          <div>
                            <IconCircledPlus size="24px" />
                          </div>
                          <div>Add Another</div>
                        </Step1IconTextButton>
                      </div>
                    </Step1MapItemsExistWrapper>
                  )}
                </Step1AdvancedOptionWrapper>
              </div>
            </div>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !arePinterestFieldsFilledIn
              ? "All fields are required"
              : !isTagIdValid
                ? "The Pinterest Tag ID field must only contain numbers"
                : !isAdAccountIdValid
                  ? "The Pinterest Ad Account ID field must only contain numbers"
                  : !isAccessTokenValid
                    ? "The Conversion Access Token field must start with 'pina_'"
                    : !areChannelActionSourceMapItemsFilledIn
                      ? "All channel action source map item fields must be filled in"
                      : !areChannelActionSourceMapItemsUnique
                        ? "All channel action source map items must use unique channel codes"
                        : !areGatewayActionSourceMapItemsFilledIn
                          ? "All gateway action source map item fields must be filled in"
                          : !areGatewayActionSourceMapItemsUnique
                            ? "All gateway action source map items must use unique gateways names"
                            : !areTagActionSourceMapItemsFilledIn
                              ? "All tag action source map item fields must be filled in"
                              : !areTagActionSourceMapItemsUnique
                                ? "All tag action source map items must use unique tag names"
                                : !arePinterestFieldsUnique
                                  ? "All fields must be unique"
                                  : null
          }
          onSave={async data => {
            type C = ReturnType<typeof useConfig>;
            type ChannelMap = C["actionSourceMaps"]["channelMap"];
            type GatewayMap = C["actionSourceMaps"]["gatewayMap"];
            type TagMap = C["actionSourceMaps"]["tagMap"];

            const action = async () => {
              setIsLoading(true);
              await configMutation({
                ...data,
                tagId,
                adAccountId,
                accessToken,
                dataConfig: { conversionValue },
                actionSourceMaps: {
                  channelMap: channelActionSourceMap as ChannelMap,
                  gatewayMap: gatewayActionSourceMap as GatewayMap,
                  tagMap: tagActionSourceMap as TagMap
                },
                ...(!isStepCompleted ? { completedStep: 1 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
              setIsLoading(false);
            };

            if (config.live) {
              setSaveModalState({ isVisible: true, onConfirm: action });
            } else {
              await action();
            }
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > p {
    margin-bottom: ${props => props.theme.gridBase * 0.75}px;
  }

  > a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  padding-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    width: ${props => props.inputWrapperWidth}px;
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }

  > div:last-child {
    > div:first-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }
  }
`;

type Step1AdvancedOptionWrapperProps = {
  showBottomSeparator: boolean;
};

const Step1AdvancedOptionWrapper = styled.div<Step1AdvancedOptionWrapperProps>`
  ${props =>
    props.showBottomSeparator
      ? css`
          margin-bottom: ${props => props.theme.gridBase * 3}px;
          border-bottom: 1px solid ${props => props.theme.palette.grey6};
          padding-bottom: ${props => props.theme.gridBase * 3}px;
        `
      : css`
          margin-bottom: ${props => props.theme.gridBase * 1.5}px;
        `}
`;

const Step1IconTextButton = styled.button`
  ${iconTextButtonStyles};
`;

const Step1MapItemsExistWrapper = styled.div`
  > div:first-child {
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const Step1MapItemWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    flex: 1;
    max-width: ${props => props.theme.gridBase * 90}px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.gridBase}px;

    @media screen and (max-width: 1450px) {
      width: 100%;
      grid-template-columns: 1fr;
      gap: ${props => props.theme.gridBase * 1.5}px;
      justify-items: stretch;
    }
  }

  > div:last-child {
    margin-left: ${props => props.theme.gridBase}px;
  }

  &:not(:last-child) {
    @media screen and (max-width: 1450px) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
      border-bottom: 1px solid ${props => props.theme.palette.grey6};
      padding-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const Step1MapItemRemoveButton = styled.button`
  ${iconButtonStyles};
  color: ${props => props.theme.palette.grey3};
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, shopifyOAuthScopes }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: config.enabledWebEvents,
    webhookOverrides: config.webhookOverrides
  });

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to Pinterest.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={isLoading}
        isStepCompleted={isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: recommendedWebEvents
        }}
        onSave={async () => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...eventState,
              ...(!isStepCompleted ? { completedStep: 2 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <TransactionIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["id", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { orderAttributeId },
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <ProductIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["product_id", "sku", "variant_id"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { productAttributeMapping },
            ...(!isStepCompleted ? { completedStep: 5 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 6;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 6 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 7;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 7 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const { eventsConnectorConfig } = useMyTrackingDetails();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { containerInfo }
  } = useSetupFlowDetails();

  const { mutateAsync: myTrackingVersionMutation } =
    useMyTrackingVersionMutation();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 8;

  return (
    <ContainerSetupWeb
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      containerUrl={containerInfo.web_container_url ?? ""}
      eventsConnectorConfig={eventsConnectorConfig}
      details={{ destination, config }}
      onSave={async () => {
        setIsLoading(true);
        await myTrackingVersionMutation("destination-pin");
        await configMutation({ completedStep: 8 });
        setIsLoading(false);
      }}
    />
  );
};
