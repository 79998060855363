import styled from "styled-components";

import {
  baseButtonStyles,
  smallButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimaryAsLink,
  ButtonPrimaryAsLinkExternal
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconLock } from "elevar-design-system/src/icons";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  normalBodyStyles,
  normalTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { useCompanyId, useWebsiteId } from "../utils/idHooks";

/* ========================================================================== */

const correctImageWidth = 960;
const correctImageHeight = 588;

type NotEnabledType =
  | {
      reason: "SERVICE_CODE_MISSING";
      isCompanyAdmin: boolean;
    }
  | {
      reason: "OTHER";
      subheadingIcon: React.ReactNode;
      subheadingText: string;
      buttonText: string;
      buttonLink:
        | { type: "INTERNAL"; to: string }
        | { type: "EXTERNAL"; href: string };
    };

/* ========================================================================== */

type NotEnabledProps = {
  type: NotEnabledType;
  title: string;
  mainText: string;
  imageSrc: string;
  imageAlt: string;
  columnData?: {
    column1Heading: string;
    column1Text: string;
    column2Heading: string;
    column2Text: string;
    column3Heading: string;
    column3Text: string;
  };
};

export const NotEnabled: React.FC<NotEnabledProps> = ({
  type,
  title,
  mainText,
  imageSrc,
  imageAlt,
  columnData
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const companyUrl = `/company/${companyId}`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;

  return (
    <NotInPlanWrapper>
      <div>
        <TopSectionWrapper>
          <TopSectionLeftColumnWrapper>
            {type.reason === "SERVICE_CODE_MISSING" ? (
              <Subheading>
                <div>
                  <IconLock size="24px" />
                </div>
                <div>Locked</div>
              </Subheading>
            ) : (
              <Subheading>
                <div>{type.subheadingIcon}</div>
                <div>{type.subheadingText}</div>
              </Subheading>
            )}
            <div>{title}</div>
            <div>{mainText}</div>
            <div>
              {type.reason === "SERVICE_CODE_MISSING" ? (
                type.isCompanyAdmin ? (
                  <ButtonPrimaryAsLink variant="SMALL" to={managePlanUrl}>
                    Upgrade Now
                  </ButtonPrimaryAsLink>
                ) : (
                  <Tooltip
                    placement="right"
                    text="Only an Admin can upgrade a Company's plan"
                  >
                    <DisabledButtonLink>Upgrade Now</DisabledButtonLink>
                  </Tooltip>
                )
              ) : type.buttonLink.type === "INTERNAL" ? (
                <ButtonPrimaryAsLink variant="SMALL" to={type.buttonLink.to}>
                  {type.buttonText}
                </ButtonPrimaryAsLink>
              ) : (
                <ButtonPrimaryAsLinkExternal
                  variant="SMALL"
                  href={type.buttonLink.href}
                >
                  {type.buttonText}
                </ButtonPrimaryAsLinkExternal>
              )}
            </div>
          </TopSectionLeftColumnWrapper>
          <TopSectionRightColumnWrapper>
            <img
              src={imageSrc}
              alt={imageAlt}
              width={correctImageWidth}
              height={correctImageHeight}
            />
          </TopSectionRightColumnWrapper>
        </TopSectionWrapper>
        {columnData ? (
          <BottomSectionWrapper>
            <div>
              <div>{columnData.column1Heading}</div>
              <div>{columnData.column1Text}</div>
            </div>
            <div>
              <div>{columnData.column2Heading}</div>
              <div>{columnData.column2Text}</div>
            </div>
            <div>
              <div>{columnData.column3Heading}</div>
              <div>{columnData.column3Text}</div>
            </div>
          </BottomSectionWrapper>
        ) : null}
      </div>
    </NotInPlanWrapper>
  );
};

/* ========================================================================== */

const NotInPlanWrapper = styled.div`
  margin: 0 ${props => props.theme.gridBase * 10}px;
  padding: ${props => props.theme.gridBase * 10}px 0;

  > div {
    margin: 0 auto;
    max-width: ${props => props.theme.gridBase * 145}px;
    padding-top: ${props => props.theme.gridBase * 6.5}px;
    padding-left: ${props => props.theme.gridBase * 3}px;
    padding-right: ${props => props.theme.gridBase * 3}px;
    padding-bottom: ${props => props.theme.gridBase * 3}px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(33, 43, 54, 0.04);
    background-color: ${props => props.theme.palette.white};
  }
`;

const TopSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: ${props => props.theme.gridBase * 6.5}px;
`;

const TopSectionLeftColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${props => props.theme.gridBase * 4}px;

  > div:nth-child(2) {
    ${heading2Styles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
    max-width: 85%;
  }

  > div:nth-child(3) {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
    margin-bottom: ${props => props.theme.gridBase * 3}px;
    max-width: 85%;
  }
`;

const Subheading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.orange};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${subheadingStyles};
    color: ${props => props.theme.palette.orange};
  }
`;

const DisabledButtonLink = styled.div`
  ${baseButtonStyles};
  ${smallButtonStyles};
  display: inline-block;
  background-color: ${props => props.theme.palette.grey5};
  color: ${props => props.theme.palette.white};
  user-select: none;
  cursor: not-allowed;

  &:hover {
    background-color: ${props => props.theme.palette.grey5};
  }
`;

const TopSectionRightColumnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${props => props.theme.gridBase * 4}px;

  > img {
    width: 100%;
    height: auto;
    object-fit: scale-down;
    max-width: ${correctImageWidth / 2}px;
    max-height: ${correctImageHeight / 2}px;
  }
`;

const BottomSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase * 4.5}px;
  background-color: ${props => props.theme.palette.grey8};
  padding-top: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
  padding-bottom: ${props => props.theme.gridBase * 3.5}px;
  border-radius: 4px;

  > div {
    > div:first-child {
      ${normalTextStyles};
      font-weight: 500;
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey3};
    }
  }
`;
