import Cleave from "cleave.js/react";
import styled from "styled-components";

import {
  type InputFieldProps,
  inputFieldStyles
} from "elevar-design-system/src/inputs/sharedInputStyles";

/* ========================================================================== */

type InputFieldPhoneProps = InputFieldProps & {
  type: never; // Stops the component user from passing this prop.
};

export const InputFieldPhone = styled(Cleave)
  .withConfig({
    shouldForwardProp: prop => !["variant", "error"].includes(prop)
  })
  .attrs<
    React.ComponentPropsWithRef<typeof Cleave>,
    React.ComponentPropsWithRef<typeof Cleave>
  >(props => ({
    placeholder: props.placeholder ?? "+1 111 1234567",
    type: "tel",
    options: {
      prefix: "+",
      noImmediatePrefix: true,
      numericOnly: true
    }
  }))<InputFieldPhoneProps>`
  ${inputFieldStyles}
`;
