import { capitalize } from "lodash-es";
import { useRef } from "react";
import { Link } from "react-router-dom";
import Xarrow from "react-xarrows";
import styled, { css, useTheme } from "styled-components";

import { ButtonPrimaryAsLink } from "elevar-design-system/src/buttons/ButtonVariants";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { Status } from "../../components/Status";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { type Destination, type Source, type sourceEnrich } from "./data";
import { type EnchancedDestination } from "./getEnchancedDestinations";

/* ========================================================================== */

type SummaryCardBannerOrNotice = {
  icon: React.ReactElement;
  text: string;
  textColor: string;
  backgroundColor: string;
};

type SummaryCardDetails =
  | {
      type: "SOURCE";
      data: Source | typeof sourceEnrich;
      explainer: string;
      banner?: SummaryCardBannerOrNotice | null;
      notice: SummaryCardBannerOrNotice | null;
      state:
        | { value: "SETUP" }
        | { value: "NOT_SETUP" }
        | { value: "UPGRADE_REQUIRED"; isAllowed: boolean }
        | { value: "UPDATE_REQUIRED" };
    }
  | {
      type: "DESTINATION";
      data: Destination;
      instance: EnchancedDestination["instances"][number];
      banner?: SummaryCardBannerOrNotice | null;
      notice: SummaryCardBannerOrNotice | null;
      state: { value: "SETUP" } | { value: "NOT_SETUP" };
    };

/* ========================================================================== */

type SummaryCardProps = {
  centerRef: React.MutableRefObject<HTMLElement | null>;
  details: SummaryCardDetails;
  lineState: "GREEN" | "ORANGE" | "GREY";
};

export const SummaryCard: React.FC<SummaryCardProps> = ({
  centerRef,
  details,
  lineState
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { isDeadended } = useMyTrackingDetails();

  const cardRef = useRef<HTMLAnchorElement | null>(null);

  const isDisabled =
    isDeadended ||
    (details.type === "SOURCE" &&
      details.state.value === "UPGRADE_REQUIRED" &&
      !details.state.isAllowed);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  const to =
    details.type === "SOURCE"
      ? `${myTrackingUrl}/source-${details.data.shorthand}`
      : details.instance.url;

  return (
    <>
      <Tooltip
        text={
          isDeadended
            ? "Plan change required"
            : "Please upgrade your Shopify source first"
        }
        placement="top"
        maxWidth={`${theme.gridBase * 17.5}px`}
        delay={[150, 0]}
        disabled={!isDisabled}
      >
        <SummaryCardLink
          ref={cardRef}
          to={isDisabled ? "#" : to}
          tabIndex={isDisabled ? -1 : undefined}
          isDisabled={isDisabled}
        >
          <SummaryPageCard>
            {details.banner ? (
              <SummaryCardBanner data={details.banner}>
                <div>{details.banner.icon}</div>
                <div>{details.banner.text}</div>
              </SummaryCardBanner>
            ) : null}
            <SummaryCardInner>
              <SummaryCardHeader>
                <SummaryCardHeading>
                  <div>
                    <details.data.icon size="24px" />
                  </div>
                  <div>
                    {details.type === "SOURCE"
                      ? details.data.name
                      : details.instance.title}
                  </div>
                  {details.type === "DESTINATION" &&
                  details.data.state !== "STABLE" ? (
                    <div>{capitalize(details.data.state)}</div>
                  ) : null}
                </SummaryCardHeading>
                {details.notice ? (
                  <div>
                    <Status
                      textColor={details.notice.textColor}
                      backgroundColor={details.notice.backgroundColor}
                      icon={details.notice.icon}
                      text={details.notice.text}
                    />
                  </div>
                ) : null}
              </SummaryCardHeader>
              {details.type === "SOURCE" &&
              (details.state.value === "NOT_SETUP" ||
                (details.state.value === "UPGRADE_REQUIRED" &&
                  details.state.isAllowed) ||
                details.state.value === "UPDATE_REQUIRED") ? (
                <SummaryCardSourceExplainer>
                  {details.explainer}
                </SummaryCardSourceExplainer>
              ) : null}
              {details.type === "DESTINATION" &&
              details.state.value === "SETUP" &&
              (details.instance.summaryItems.length > 0 ||
                (details.notice &&
                  details.instance.enabledEventsState !== "NONE")) ? (
                <SummaryCardDestinationSummaryItems>
                  <div>
                    {details.instance.summaryItems.map((item, index) => (
                      <div key={index}>{item}</div>
                    ))}
                  </div>
                  {details.notice
                    ? (() => {
                        switch (details.instance.enabledEventsState) {
                          case "SERVER_AND_WEB":
                            return (
                              <div>
                                <span>Server</span>
                                <span>•</span>
                                <span>Web</span>
                              </div>
                            );
                          case "SERVER":
                            return <div>Server</div>;
                          case "WEB":
                            return <div>Web</div>;
                          case "NONE":
                            return null;
                        }
                      })()
                    : null}
                </SummaryCardDestinationSummaryItems>
              ) : null}
              {!isDisabled && details.state.value === "NOT_SETUP" ? (
                <SummaryCardActionWrapper>
                  <ButtonPrimaryAsLink as="div" variant="SMALL">
                    Set Up
                  </ButtonPrimaryAsLink>
                </SummaryCardActionWrapper>
              ) : !isDisabled &&
                (details.state.value === "UPGRADE_REQUIRED" ||
                  details.state.value === "UPDATE_REQUIRED") ? (
                <SummaryCardActionWrapper>
                  <ButtonPrimaryAsLink as="div" variant="SMALL">
                    {details.state.value === "UPGRADE_REQUIRED"
                      ? " Go to Upgrade"
                      : " Go to Update"}
                  </ButtonPrimaryAsLink>
                </SummaryCardActionWrapper>
              ) : null}
            </SummaryCardInner>
          </SummaryPageCard>
        </SummaryCardLink>
      </Tooltip>
      <Xarrow
        path="grid"
        startAnchor="right"
        endAnchor="left"
        showHead={false}
        strokeWidth={1.5}
        start={details.type === "SOURCE" ? cardRef : centerRef}
        end={details.type === "SOURCE" ? centerRef : cardRef}
        color={
          lineState === "GREEN"
            ? theme.palette.green
            : lineState === "ORANGE"
              ? theme.palette.orange
              : theme.palette.grey5
        }
        zIndex={lineState === "GREEN" ? 2 : lineState === "ORANGE" ? 3 : 1}
        dashness={
          lineState === "GREY" ? { strokeLen: 6, nonStrokeLen: 6 } : undefined
        }
      />
    </>
  );
};

/* ========================================================================== */

type SummaryCardLinkProps = {
  isDisabled: boolean;
};

const SummaryCardLink = styled(Link)<SummaryCardLinkProps>`
  width: 100%;

  ${props =>
    props.isDisabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;

          &:hover {
            ${ButtonPrimaryAsLink} {
              background-color: ${props => props.theme.palette.purple2};
            }
          }
        `
      : css`
          &:hover {
            ${ButtonPrimaryAsLink} {
              background-color: ${props => props.theme.palette.purple1};
            }
          }
        `}
`;

const SummaryPageCard = styled(PageCard)`
  padding: 0;
  overflow: hidden;
`;

type SummaryCardBannerProps = {
  data: SummaryCardBannerOrNotice;
};

const SummaryCardBanner = styled.div<SummaryCardBannerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.gridBase * 0.75}px;
  background-color: ${props => props.data.backgroundColor};
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;

  > div:first-child {
    display: flex;
    color: ${props => props.data.textColor};
  }

  > div:last-child {
    ${normalTextStyles};
    color: ${props => props.data.textColor};
    font-weight: 500;
  }
`;

const SummaryCardInner = styled.div`
  padding: ${props => props.theme.gridBase * 3}px;
`;

const SummaryCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.gridBase * 1.5}px;
  height: ${props => props.theme.gridBase * 3.5}px;
`;

const SummaryCardHeading = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  > div:nth-child(1) {
    display: flex;
    color: ${props => props.theme.palette.purple2};
    margin-right: ${props => props.theme.gridBase * 2}px;
  }

  > div:nth-child(2) {
    ${heading3Styles};
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  > div:nth-child(3) {
    ${normalTextStyles};
    display: flex;
    align-items: center;
    border-radius: 4px;
    width: max-content;
    padding-top: ${props => props.theme.gridBase * 0.5}px;
    padding-bottom: ${props => props.theme.gridBase * 0.5}px;
    padding-left: ${props => props.theme.gridBase}px;
    padding-right: ${props => props.theme.gridBase}px;
    color: ${props => props.theme.palette.white};
    background-color: ${props => props.theme.palette.grey5};
    margin-left: ${props => props.theme.gridBase * 2}px;
  }
`;

const SummaryCardSourceExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const SummaryCardDestinationSummaryItems = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.gridBase * 2}px;
  margin-top: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 5}px;

  > div:first-child {
    overflow-x: hidden;

    > div {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      text-align: left;

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 0.5}px;
      }
    }
  }

  > div:not(:first-child) {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey4};
    display: flex;
    gap: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const SummaryCardActionWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;
