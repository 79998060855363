type IconTargetProps = {
  size: "24px";
  color?: string;
  className?: string;
};

export const IconTarget: React.FC<IconTargetProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M13.65 19.4334C13.3569 19.5429 13.0566 19.6375 12.75 19.7165C12.0309 19.9016 11.2769 20 10.5 20C5.52944 20 1.5 15.9706 1.5 11C1.5 6.02944 5.52944 2 10.5 2C15.4706 2 19.5 6.02944 19.5 11C19.5 11.3671 19.478 11.7291 19.4353 12.0847C19.3974 12.4 19.3432 12.7104 19.2736 13.0149"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.8501 16.2295C11.4186 16.3405 10.9663 16.3996 10.5001 16.3996C7.51776 16.3996 5.1001 13.9819 5.1001 10.9996C5.1001 8.01727 7.51776 5.59961 10.5001 5.59961C13.4824 5.59961 15.9001 8.01727 15.9001 10.9996C15.9001 11.0768 15.8985 11.1536 15.8953 11.23"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5002 12.2992C11.2182 12.2992 11.8002 11.7172 11.8002 10.9992C11.8002 10.2812 11.2182 9.69922 10.5002 9.69922C9.78223 9.69922 9.2002 10.2812 9.2002 10.9992C9.2002 11.7172 9.78223 12.2992 10.5002 12.2992Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.25 13L16.9996 22L18.3307 18.0807L22.25 16.7496L13.25 13Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
