import { useState } from "react";
import styled, { useTheme } from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimaryAsLink,
  ButtonPrimaryAsLinkExternal,
  ButtonWhite
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCheckMark,
  IconCross,
  IconFacebook,
  IconHelp,
  IconKlaviyo,
  IconShopify
} from "elevar-design-system/src/icons";
import {
  LinkExternal,
  StyledLinkExternal
} from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Tag } from "elevar-design-system/src/Tag";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { VideoPlayer } from "../../components/VideoPlayer";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";

/* ========================================================================== */

type DashboardLegacyProps = {
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
};

export const DashboardLegacy: React.FC<DashboardLegacyProps> = ({
  website,
  isCompanyAdmin
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const [isFaqsModalVisible, setIsFaqsModalVisible] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);

  const type =
    website.server_side_type === "GTM"
      ? "SS_GTM"
      : website.data_layer_version === "1.0.0"
        ? "V1"
        : website.plan.id !== "DEFAULT_FREE_PLAN"
          ? "WEB_ONLY"
          : "FREE";

  const companyUrl = `/company/${companyId}`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;

  return (
    <>
      <SsGtmPageWrapper>
        <SsGtmPageHeader>
          <div>Dashboard</div>
          {type !== "FREE" ? (
            <ButtonWhiteWithIcon
              variant="SMALL"
              onClick={() => setIsFaqsModalVisible(true)}
            >
              <IconHelp size="16px" />
              <span>FAQs</span>
            </ButtonWhiteWithIcon>
          ) : null}
        </SsGtmPageHeader>
        <SsGtmPageContent>
          <div>
            <SsGtmLeftPageCard1>
              <div>
                {type === "FREE" ? (
                  <div>
                    <div>Upgrade to Unlock</div>
                    <Tag text="Locked" textColor={theme.palette.orange} />
                  </div>
                ) : (
                  <div>
                    <div>Important Update on Your Plan</div>
                    <Tag text="Updates" textColor={theme.palette.orange} />
                  </div>
                )}
                {(() => {
                  switch (type) {
                    case "SS_GTM":
                      return (
                        <div>
                          <p>
                            Your integration uses your own server (on Google
                            Cloud) for server-side tracking. Any tracking you've
                            already set up where Elevar sends data to your
                            server endpoint will continue to work! ✅
                          </p>
                          <p>
                            However, due to changes in the Shopify ecosystem
                            such as Checkout Extensibility, Pixel API, and Theme
                            App Extensions, we are no longer updating or
                            building GTM server-side integrations. The majority
                            of Elevar customers are already using Elevar's
                            native server-side tracking which is our most
                            stable, accurate, and robust offering.
                          </p>
                          <p>
                            You can continue using your own GTM server but we
                            will no longer support and update this
                            functionality. Migrating to Elevar's server will
                            enable you with benefits like:
                          </p>
                        </div>
                      );
                    case "V1":
                      return (
                        <div>
                          <p>
                            You're currently on Data Layer – Version 1.0. Any
                            tracking you've already set up via your GTM web
                            container will continue to work! ✅
                          </p>
                          <p>
                            However, due to changes in the Shopify ecosystem
                            such as Checkout Extensibility, Pixel API, and Theme
                            App Extensions, we are no longer updating or
                            supporting your Data Layer version.
                          </p>
                          <p>
                            The majority of Elevar customers are already using
                            Elevar's Server-Side Tracking which is our most
                            stable, accurate, and robust offering. Migrating to
                            Elevar's server will enable you with benefits like:
                          </p>
                        </div>
                      );
                    case "WEB_ONLY":
                      return (
                        <div>
                          <p>
                            Any tracking you've already set up via your GTM web
                            container will continue to work! ✅
                          </p>
                          <p>
                            However, due to changes in the Shopify ecosystem
                            such as Checkout Extensibility, Pixel API, and Theme
                            App Extensions, we are no longer updating web only
                            plans. The majority of Elevar customers are already
                            using Elevar's native server-side tracking which is
                            our most stable, accurate, and robust offering.
                          </p>
                          <p>
                            You can continue using web tracking but we will no
                            longer update this functionality. Migrating to
                            Elevar's server will enable you with benefits like:
                          </p>
                        </div>
                      );
                    case "FREE":
                      return (
                        <div>
                          <p>
                            Ensure that 100% of your conversions are tracked and
                            delivered to your marketing channels like Facebook,
                            TikTok, Google Ads, GA4, and 40+ other channels with
                            Elevar's server-side tracking.
                          </p>
                          <p>
                            Upgrading to a server-side tracking plan will enable
                            you with benefits like:
                          </p>
                        </div>
                      );
                  }
                })()}
              </div>
              <div>
                <CheckListItem>
                  <div>
                    <CheckListItemIconWrapper>
                      <IconCheckMark size="16px" />
                    </CheckListItemIconWrapper>
                  </div>
                  <div>
                    Identify returning users without relying on cookies.
                  </div>
                </CheckListItem>
                <CheckListItem>
                  <div>
                    <CheckListItemIconWrapper>
                      <IconCheckMark size="16px" />
                    </CheckListItemIconWrapper>
                  </div>
                  <div>
                    Send 3x more Klaviyo abandoned browse and cart emails.
                  </div>
                </CheckListItem>
                <CheckListItem>
                  <div>
                    <CheckListItemIconWrapper>
                      <IconCheckMark size="16px" />
                    </CheckListItemIconWrapper>
                  </div>
                  <div>
                    Get improved match rates for Facebook, TikTok, and more!
                  </div>
                </CheckListItem>
                <CheckListItem>
                  <div>
                    <CheckListItemIconWrapper>
                      <IconCheckMark size="16px" />
                    </CheckListItemIconWrapper>
                  </div>
                  <div>
                    Get more granular purchase data (first-time vs one-time and
                    new vs returning).
                  </div>
                </CheckListItem>
                <CheckListItem>
                  <div>
                    <CheckListItemIconWrapper>
                      <IconCheckMark size="16px" />
                    </CheckListItemIconWrapper>
                  </div>
                  <div>
                    Compatibility with Shopify Markets, Checkout Extensibility,
                    and more!
                  </div>
                </CheckListItem>
              </div>
            </SsGtmLeftPageCard1>
            <SsGtmLeftPageCard2>
              <div>
                <div>Your Next Steps</div>
                <div>
                  <div>
                    <div />
                    <div>
                      <div>Step 1</div>
                      {type === "SS_GTM" ? (
                        <div>
                          <p>Book a call with our customer success team.</p>
                          <ButtonPrimaryAsLinkExternal
                            variant="SMALL"
                            href="https://www.getelevar.com/book-a-migration-call-with-elevar-team"
                          >
                            Book a Call
                          </ButtonPrimaryAsLinkExternal>
                        </div>
                      ) : (
                        <div>
                          {isCompanyAdmin ? (
                            <>
                              <p>Upgrade your plan.</p>
                              <ButtonPrimaryAsLink
                                variant="SMALL"
                                to={managePlanUrl}
                              >
                                Go to Plans
                              </ButtonPrimaryAsLink>
                            </>
                          ) : (
                            <p>
                              Upgrade your plan (reach out to your Website's
                              owner or one of your admins to do this).
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div />
                    <div>
                      <div>Step 2</div>
                      <div>
                        {type === "SS_GTM" ? (
                          <p>
                            We migrate all your tracking to Elevar's Server for
                            you.
                          </p>
                        ) : (
                          <p>Complete our guided setup – takes minutes!</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div />
                    <div>
                      <div>Step 3</div>
                      <div>
                        {type === "SS_GTM" ? (
                          <p>
                            We go live together on a call and you get improved
                            campaign ROI!
                          </p>
                        ) : (
                          <p>Enjoy server-side tracking.</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {type === "SS_GTM" || type === "WEB_ONLY" ? (
                <div>
                  Want to learn more?{" "}
                  <button onClick={() => setIsVideoModalVisible(true)}>
                    Watch Video
                  </button>
                </div>
              ) : null}
            </SsGtmLeftPageCard2>
          </div>
          <div>
            <SsGtmRightPageCard>
              <div>What You'll Get:</div>
              <div>
                <div>
                  <div>
                    <IconShopify size="24px" />
                  </div>
                  <div>Markets & Checkout Extensibility Compatibility</div>
                </div>
                <div>
                  Tailor your setup to reflect the needs of different regions
                  when selling internationally, and ensure that your checkout
                  tracking is compatible with Checkout Extensibility.
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <IconKlaviyo size="24px" />
                  </div>
                  <div>Send 3x More Abandoned Cart Emails</div>
                </div>
                <div>
                  Experience a 50% or greater increase in product view and
                  add-to-cart events. This results in more abandoned flow emails
                  being sent and a direct boost in ROI from your Elevar
                  integration.
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <IconFacebook size="24px" />
                  </div>
                  <div>20% Increase in Meta Performance</div>
                </div>
                <div>
                  Meta can't optimize for what it can't see. It's essential to
                  send 100% of conversion events and provide the most
                  comprehensive user data to maximize match quality scores.
                  Elevar delivers on both fronts.
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <ElevarLogo />
                  </div>
                  <div>Identify Returning Users Without Relying on Cookies</div>
                </div>
                <div>
                  Boost your marketing performance: Elevar's Session Enrichment
                  instantly improves campaign ROI by recognizing returning users
                  & enhancing data sent to Facebook CAPI, Klaviyo, Google Ads,
                  and more!
                </div>
              </div>
              <StyledLinkExternal
                href="https://www.getelevar.com/server-side-tracking/"
                text="Learn More About Our New Server-Side Tracking"
              />
            </SsGtmRightPageCard>
          </div>
        </SsGtmPageContent>
      </SsGtmPageWrapper>
      <Modal
        isVisible={isFaqsModalVisible}
        onClose={() => setIsFaqsModalVisible(false)}
      >
        <ModalContents>
          <ModalHeader>
            <FaqsModalTitle>Frequently Asked Questions</FaqsModalTitle>
            <FaqsModalCloseButton onClick={() => setIsFaqsModalVisible(false)}>
              <IconCross size="16px" />
            </FaqsModalCloseButton>
          </ModalHeader>
          <FaqsModalQuestionsWrapper>
            {type === "SS_GTM" ? (
              <FaqsModalQuestion>
                <div>Why did you sunset Server-Side tracking using GTM?</div>
                <div>
                  Most merchants don't have the resources to manage their own
                  server. This can result in data loss during peak season (e.g.
                  Black Friday, Christmas, etc) if a server doesn't have enough
                  capacity for the new traffic. In addition, there are many ROI
                  enhancements that we can't support in GTM server-side
                  containers like our new Session Enrichment feature. When using
                  Elevar's server for your tracking, we can guarantee that 100%
                  of your conversions are tracked and delivered to your
                  marketing channels.
                </div>
              </FaqsModalQuestion>
            ) : type === "WEB_ONLY" ? (
              <FaqsModalQuestion>
                <div>
                  Why are you no longer building towards web only tracking?
                </div>
                <div>
                  Due to changes in the Shopify ecosystem such as Checkout
                  Extensibility, Pixel API, and Theme App Extensions - web only
                  tracking is no longer able to address the tracking needs of
                  today's e-commerce landscape. With web-only tracking you could
                  be missing 20-25% of conversions and won't be able to track
                  checkout events on Checkout Extensibility. Elevar's
                  Server-Side Tracking is the way to ensure accurate and robust
                  data collection.
                </div>
              </FaqsModalQuestion>
            ) : type === "V1" ? (
              <FaqsModalQuestion>
                <div>Why did you sunset the 1.0 Data Layer?</div>
                <div>
                  Due to changes in the Shopify ecosystem such as Checkout
                  Extensibility, Pixel API, and Theme App Extensions - the 1.0
                  Data Layer is no longer able to address the tracking needs of
                  today's e-commerce landscape. With web-only tracking you could
                  be missing 20-25% of conversions and won't be able to track
                  checkout events on Checkout Extensibility. Elevar's
                  Server-Side Tracking is the way to ensure accurate and robust
                  data collection.
                </div>
              </FaqsModalQuestion>
            ) : null}
            <FaqsModalQuestion>
              <div>What is Elevar's Server-Side tracking?</div>
              {type === "SS_GTM" ? (
                <div>
                  Elevar's Server-Side tracking uses Elevar's servers – we do
                  the server maintenance and hosting. You'll enjoy no extra fees
                  to Google Cloud, no DNS setup, access to our latest features
                  (like Klaviyo Server-Side), and automated destination updates.
                  Learn more{" "}
                  <LinkExternal href="https://docs.getelevar.com/docs/introducing-elevars-server-side-tracking">
                    here
                  </LinkExternal>
                  .
                </div>
              ) : (
                <div>
                  It's server-side tracking that uses Elevar's servers. The
                  majority of Elevar customers are already using Elevar's
                  Server-Side Tracking which is our most stable, accurate, and
                  robust offering. Learn more{" "}
                  <LinkExternal href="https://docs.getelevar.com/docs/getting-started-with-server-side-tracking-in-elevar">
                    here
                  </LinkExternal>
                  .
                </div>
              )}
            </FaqsModalQuestion>
            <FaqsModalQuestion>
              <div>What happens if I switch to a new plan?</div>
              <div>
                When switching to a new plan with Elevar's Server-Side tracking,
                you will complete a new guided setup. Once completed, your
                website data will be sent server-side to marketing destinations
                through Elevar's Servers. You'll continue to use your GTM web
                container for any browser-side tracking.
              </div>
            </FaqsModalQuestion>
            <FaqsModalQuestion>
              <div>What happens if I don't switch to a new plan?</div>
              <div>
                Any tracking you've already setup will continue to work — Elevar
                isn't going to remove anything from your setup. But keep in mind
                that over time things may stop working due to changes out of our
                control, especially after moving to Checkout Extensibility.
              </div>
            </FaqsModalQuestion>
            <FaqsModalQuestion>
              <div>When will I be charged for a new plan?</div>
              <div>
                You'll start paying a new monthly subscription when you change
                your plan. Your base plan cost will include a specific order
                volume. For every extra order, we'll charge a fee at the end of
                your billing period.
              </div>
            </FaqsModalQuestion>
            {type === "SS_GTM" || type === "WEB_ONLY" ? (
              <FaqsModalFooter>
                Want to learn more?{" "}
                <button onClick={() => setIsVideoModalVisible(true)}>
                  Watch Video
                </button>
              </FaqsModalFooter>
            ) : null}
          </FaqsModalQuestionsWrapper>
        </ModalContents>
      </Modal>
      <Modal
        isVisible={isVideoModalVisible}
        onClose={() => setIsVideoModalVisible(false)}
      >
        <VideoModalContents>
          <VideoModalHeader>
            <VideoModalTitle>Elevar Server-Side Migration</VideoModalTitle>
            <VideoModalCloseButton
              onClick={() => setIsVideoModalVisible(false)}
            >
              <IconCross size="16px" />
            </VideoModalCloseButton>
          </VideoModalHeader>
          <VideoPlayer
            url="https://player.vimeo.com/video/857505727"
            playing={isVideoModalVisible}
          />
        </VideoModalContents>
      </Modal>
    </>
  );
};

/* ========================================================================== */

const SsGtmPageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const SsGtmPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${props => props.theme.gridBase * 5}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${heading2Styles};
    padding-top: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const ButtonWhiteWithIcon = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  margin-left: ${props => props.theme.gridBase * 2}px;

  > span {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;

const SsGtmPageContent = styled.div`
  display: grid;
  gap: ${props => props.theme.gridBase * 3}px;
  grid-template-rows: auto auto;
  grid-template-columns: 2fr minmax(
      ${props => props.theme.gridBase * 56.5}px,
      1fr
    );

  @media screen and (max-width: 1375px) {
    grid-template-columns: 1fr;
  }

  > div {
    > :not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }
  }
`;

const SsGtmLeftPageCard1 = styled(PageCard)`
  > div:first-child {
    > div:first-child {
      display: flex;
      align-items: center;
      gap: ${props => props.theme.gridBase * 2}px;
      margin-bottom: ${props => props.theme.gridBase * 2}px;

      > div:first-child {
        ${heading2Styles};
      }
    }

    > div:last-child {
      margin-bottom: ${props => props.theme.gridBase * 2}px;

      > p {
        ${normalBodyStyles};
        color: ${props => props.theme.palette.grey2};

        &:not(:last-child) {
          margin-bottom: ${props => props.theme.gridBase}px;
        }
      }
    }
  }
`;

const CheckListItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:first-child {
    margin-right: ${props => props.theme.gridBase * 1.5}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }
`;

const CheckListItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.theme.gridBase * 2}px;
  width: ${props => props.theme.gridBase * 2}px;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.purple2};
  color: ${props => props.theme.palette.white};
`;

const SsGtmLeftPageCard2 = styled(PageCard)`
  > div:first-child {
    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase * 3}px;
    }

    > div:last-child {
      > div {
        display: flex;
        gap: ${props => props.theme.gridBase * 2}px;
        position: relative;

        > div:first-child {
          z-index: 1;
          width: ${props => props.theme.gridBase * 3}px;
          height: ${props => props.theme.gridBase * 3}px;
          background-color: ${props => props.theme.palette.white};
          border: 7px solid ${props => props.theme.palette.purple2};
          border-radius: 50%;
        }

        > div:last-child {
          > div:first-child {
            ${heading3Styles};
            margin-bottom: ${props => props.theme.gridBase}px;
          }

          > div:last-child {
            > p {
              ${normalBodyStyles};
              color: ${props => props.theme.palette.grey2};

              &:not(:last-child) {
                margin-bottom: ${props => props.theme.gridBase}px;
              }
            }
          }
        }

        &:not(:last-child) {
          > div:last-child {
            padding-bottom: ${props => props.theme.gridBase * 3}px;
          }

          &::before {
            content: "";
            position: absolute;
            top: ${props => props.theme.gridBase}px;
            left: ${props => props.theme.gridBase}px;
            bottom: ${props => props.theme.gridBase * -1}px;
            background-color: #dfdefa;
            width: ${props => props.theme.gridBase}px;
          }
        }
      }
    }
  }

  > div:last-child:not(:first-child) {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
    margin-top: ${props => props.theme.gridBase * 3}px;
    border-top: 1px solid ${props => props.theme.palette.grey6};
    padding-top: ${props => props.theme.gridBase * 3}px;

    > button {
      ${linkStyles};
    }
  }
`;

const SsGtmRightPageCard = styled(PageCard)`
  height: max-content;
  padding-top: ${props => props.theme.gridBase * 4}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 4}px;
  }

  > div:not(:first-child) {
    ${normalBodyStyles};
    margin-bottom: ${props => props.theme.gridBase * 4}px;

    > div:first-child {
      display: flex;
      gap: ${props => props.theme.gridBase * 1.5}px;
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;

      > div:first-child {
        display: flex;

        > svg {
          width: ${props => props.theme.gridBase * 3}px;
          height: ${props => props.theme.gridBase * 3}px;
        }
      }

      > div:last-child {
        font-weight: 500;
      }
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }
`;

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 110}px;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const FaqsModalCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey6};
  }
`;

const FaqsModalTitle = styled.div`
  ${heading2Styles};
  text-align: center;
`;

const FaqsModalQuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridBase * 3}px;
`;

const FaqsModalQuestion = styled.div`
  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 0.5}px;
    }

    a {
      ${linkStyles};
    }
  }
`;

const FaqsModalFooter = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey2};

  > button {
    ${linkStyles};
  }
`;

const VideoModalContents = styled.div`
  width: ${props => props.theme.gridBase * 125}px;
`;

const VideoModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const VideoModalTitle = styled.div`
  ${heading2Styles};
`;

const VideoModalCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey6};
  }
`;
