import styled from "styled-components";

import { CheckBox, type CheckBoxVariant } from "../CheckBox";
import { normalTextStyles } from "../typography/typography";
import { LabeledCheckBox } from "./LabeledCheckBox";

/* ========================================================================== */

type LabeledCheckBoxImageProps = {
  variant: CheckBoxVariant;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
  image: React.ReactNode;
  text: string;
  isDisabled?: boolean;
};

export const LabeledCheckBoxImage: React.FC<LabeledCheckBoxImageProps> = ({
  variant,
  isChecked,
  setIsChecked,
  image,
  text,
  isDisabled
}) => {
  return (
    <StyledLabeledCheckBox
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      isDisabled={isDisabled}
    >
      {({ isHovered, isFocused }) => (
        <Contents>
          <LeftContent>
            <ImageWrapper>{image}</ImageWrapper>
            <LabelText>{text}</LabelText>
          </LeftContent>
          <CheckBox
            variant={variant}
            isChecked={isChecked}
            isHovered={isHovered}
            isFocused={isFocused}
          />
        </Contents>
      )}
    </StyledLabeledCheckBox>
  );
};

/* ========================================================================== */

const StyledLabeledCheckBox = styled(LabeledCheckBox)`
  width: 100%;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
`;

const Contents = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.gridBase * 1.5}px;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  width: ${props => props.theme.gridBase * 3}px;
  height: ${props => props.theme.gridBase * 3}px;
  min-width: ${props => props.theme.gridBase * 3}px;
  border-radius: 2px;
  overflow: hidden;
`;

const LabelText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-left: ${props => props.theme.gridBase * 1.5}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
