type IconFacebookProps = {
  size: "24px" | "16px";
  className?: string;
};

export const IconFacebook: React.FC<IconFacebookProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M23.9982 11.9991C23.9982 5.37166 18.6256 -0.000915527 11.9982 -0.000915527C5.37075 -0.000915527 -0.00183105 5.37166 -0.00183105 11.9991C-0.00183105 17.9886 4.3864 22.9531 10.1232 23.8533V15.4678H7.07629V11.9991H10.1232V9.35534C10.1232 6.34783 11.9147 4.68658 14.6557 4.68658C15.9686 4.68658 17.3419 4.92096 17.3419 4.92096V7.87408H15.8287C14.3381 7.87408 13.8732 8.79909 13.8732 9.74808V11.9991H17.2013L16.6693 15.4678H13.8732V23.8533C19.6099 22.9531 23.9982 17.9886 23.9982 11.9991Z"
            fill="#1877F2"
          />
          <path
            d="M16.6693 15.4678L17.2013 11.9991H13.8732V9.74808C13.8732 8.79909 14.3381 7.87408 15.8287 7.87408H17.3419V4.92096C17.3419 4.92096 15.9686 4.68658 14.6557 4.68658C11.9147 4.68658 10.1232 6.34783 10.1232 9.35533V11.9991H7.07629V15.4678H10.1232V23.8533C10.7341 23.9492 11.3603 23.9991 11.9982 23.9991C12.636 23.9991 13.2622 23.9492 13.8732 23.8533V15.4678H16.6693Z"
            fill="white"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.9982 7.99908C15.9982 3.5808 12.4165 -0.000915527 7.99817 -0.000915527C3.57989 -0.000915527 -0.00183105 3.5808 -0.00183105 7.99908C-0.00183105 11.9921 2.92365 15.3017 6.74817 15.9019V10.3116H4.71692V7.99908H6.74817V6.23658C6.74817 4.23158 7.94251 3.12408 9.76989 3.12408C10.6452 3.12408 11.5607 3.28033 11.5607 3.28033V5.24908H10.5519C9.55809 5.24908 9.24817 5.86576 9.24817 6.49841V7.99908H11.4669L11.1122 10.3116H9.24817V15.9019C13.0727 15.3017 15.9982 11.9921 15.9982 7.99908Z"
            fill="#1877F2"
          />
          <path
            d="M11.1128 10.3119L11.4675 7.99939H9.24878V6.49872C9.24878 5.86606 9.5587 5.24939 10.5525 5.24939H11.5613V3.28064C11.5613 3.28064 10.6458 3.12439 9.7705 3.12439C7.94312 3.12439 6.74878 4.23189 6.74878 6.23689V7.99939H4.71753V10.3119H6.74878V15.9022C7.15608 15.9661 7.57353 15.9994 7.99878 15.9994C8.42403 15.9994 8.84148 15.9661 9.24878 15.9022V10.3119H11.1128Z"
            fill="white"
          />
        </svg>
      );
  }
};
