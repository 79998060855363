import { clamp } from "lodash-es";
import styled from "styled-components";

/* ========================================================================== */

type ProgressBarProps = {
  percentageFilled: number;
};

export const ProgressBar = styled.div<ProgressBarProps>`
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background-color: ${props => props.theme.palette.grey8};
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 100px;
    background-color: ${props => props.theme.palette.purple2};
    width: ${props => clamp(props.percentageFilled, 100)}%;
  }
`;
