import { forwardRef } from "react";
import styled, {
  css,
  type StyledComponentPropsWithRef
} from "styled-components";

import { Spinner } from "../Spinner";
import {
  baseButtonStyles,
  largeButtonStyles,
  smallButtonStyles
} from "./buttonStyles";

/* ========================================================================== */

type ButtonColors = {
  normalText: string;
  normalBackground: string;
  hoveredText: string;
  hoveredBackground: string;
  disabledText: string;
  disabledBackground: string;
};

type ButtonBaseVariant = "SMALL" | "LARGE";
type ButtonBaseState = "IDLE" | "LOADING" | "DISABLED";

/* ========================================================================== */

export type ButtonBaseProps = {
  colors: ButtonColors;
  variant: ButtonBaseVariant;
  state?: ButtonBaseState;
} & Omit<StyledComponentPropsWithRef<"button">, "disabled">;

export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  ({ colors, variant, state = "IDLE", children, ...baseButtonProps }, ref) => {
    return (
      <ButtonBaseWrapper
        ref={ref}
        variant={variant}
        colors={colors}
        state={state}
        disabled={state !== "IDLE"}
        {...baseButtonProps}
      >
        {state === "LOADING" ? (
          <SpinnerOverlayWrapper colors={colors}>
            <SpinnerOverlay
              size="24px"
              variant={variant}
              color={colors.normalText}
            />
          </SpinnerOverlayWrapper>
        ) : null}
        {children}
      </ButtonBaseWrapper>
    );
  }
);

/* ========================================================================== */

const ButtonBaseWrapper = styled.button<ButtonBaseProps>`
  ${baseButtonStyles};
  ${props => props.variant === "SMALL" && smallButtonStyles};
  ${props => props.variant === "LARGE" && largeButtonStyles};

  background-color: ${props => props.colors.normalBackground};
  color: ${props => props.colors.normalText};

  &:hover {
    background-color: ${props =>
      props.state === "LOADING"
        ? props.colors.normalBackground
        : props.colors.hoveredBackground};
    color: ${props =>
      props.state === "LOADING"
        ? props.colors.normalText
        : props.colors.hoveredText};
  }

  &:disabled {
    background-color: ${props =>
      props.state === "LOADING"
        ? props.colors.normalBackground
        : props.colors.disabledBackground};
    color: ${props =>
      props.state === "LOADING"
        ? props.colors.normalText
        : props.colors.disabledText};
  }

  ${props =>
    props.state === "LOADING" &&
    css`
      position: relative;
      overflow: hidden;
    `}
`;

type SpinnerOverlayWrapperProps = {
  colors: ButtonColors;
};

const SpinnerOverlayWrapper = styled.div<SpinnerOverlayWrapperProps>`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.colors.normalBackground};
`;

type SpinnerOverlayProps = {
  variant: ButtonBaseVariant;
};

const SpinnerOverlay = styled(Spinner)<SpinnerOverlayProps>`
  ${props =>
    props.variant === "SMALL" &&
    css`
      svg {
        width: 20px;
        height: 20px;
      }
    `}
`;
