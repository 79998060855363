import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import styled, { StyleSheetManager, ThemeProvider } from "styled-components";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { theme } from "elevar-design-system/src/theme";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { Modal } from "./components/Modal";

/* ========================================================================== */

type CallbackFn = (answer: boolean) => void;

type UserConfirmationModalProps = {
  message: string;
  withCleanup: CallbackFn;
};

const UserConfirmationModal: React.FC<UserConfirmationModalProps> = ({
  message,
  withCleanup
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [navigationConfirmed, setNavigationConfirmed] = useState(false);

  /* ------------------------------------------------------------------------ */

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsModalVisible(true);
    }, 50);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (!isModalVisible) {
      const timeoutId = setTimeout(() => {
        withCleanup(navigationConfirmed);
      }, 150);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [withCleanup, isModalVisible, navigationConfirmed]);

  /* ------------------------------------------------------------------------ */

  return (
    <Modal
      isVisible={isModalVisible}
      onClose={() => {
        setNavigationConfirmed(false);
        setIsModalVisible(false);
      }}
    >
      <ModalContents>
        <ModalTitle>Are you sure?</ModalTitle>
        <ModalBody>{message}</ModalBody>
        <ModalButtons>
          <ButtonSecondary
            variant="SMALL"
            onClick={() => {
              setNavigationConfirmed(false);
              setIsModalVisible(false);
            }}
          >
            Stay
          </ButtonSecondary>
          <ButtonPrimary
            variant="SMALL"
            onClick={() => {
              setNavigationConfirmed(true);
              setIsModalVisible(false);
            }}
          >
            Navigate
          </ButtonPrimary>
        </ModalButtons>
      </ModalContents>
    </Modal>
  );
};

const ModalContents = styled.div`
  width: ${props => props.theme.gridBase * 38}px;
`;

const ModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

export const getUserConfirmation = (message: string, callback: CallbackFn) => {
  const modal = document.createElement("div");
  document.body.append(modal);
  const root = createRoot(modal);

  const withCleanup = (answer: boolean) => {
    root.unmount();
    modal.remove();
    callback(answer);
  };

  root.render(
    <StyleSheetManager disableVendorPrefixes={true}>
      <ThemeProvider theme={theme}>
        <UserConfirmationModal message={message} withCleanup={withCleanup} />
      </ThemeProvider>
    </StyleSheetManager>
  );
};
