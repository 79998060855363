import { useTheme } from "styled-components";

/* ========================================================================== */

type ElevarLogoProps = {
  className?: string;
  color?: string;
};

export const ElevarLogo: React.FC<ElevarLogoProps> = ({ className, color }) => {
  const theme = useTheme();

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16.1193 19.806C14.7575 21.299 12.9528 22.3982 10.9348 22.8904H18.8428C20.91 22.8904 22.7639 21.6927 23.617 19.806H16.1193Z"
        fill={color ?? theme.palette.blue3}
      />
      <path
        d="M18.4489 13.6549C18.3669 14.7377 18.1208 15.7713 17.7434 16.7393H25.0115L26.406 13.6549H18.4489Z"
        fill={color ?? theme.palette.blue2}
      />
      <path
        d="M18.4805 7.48566V10.5701H27.7994L29.1939 7.48566H18.4805Z"
        fill={color ?? theme.palette.blueBuxton}
      />
      <path
        d="M15.3966 1.33331C13.6903 1.34972 11.984 1.74348 10.4746 2.51458C8.99806 3.26928 7.71836 4.35211 6.71756 5.66463C5.71676 6.99356 5.02769 8.55218 4.71596 10.1928C4.5519 11.0624 4.53549 11.9155 4.53549 12.8015C4.53549 13.7366 4.53549 14.6554 4.53549 15.5906C4.53549 16.8211 4.53549 18.0516 4.53549 19.282C4.53549 20.2828 4.53549 21.2672 4.53549 22.268C4.53549 23.0555 4.5519 23.8595 4.42064 24.647C4.17455 26.2876 3.46907 27.9611 2.15655 29.0275C1.59872 29.4705 0.942463 29.7986 0.236983 29.9135C0.20417 29.9135 0.0401048 29.9135 0.00729179 29.9463C0.00729179 29.9627 0.00729179 29.9955 0.00729179 30.0119C-0.00911473 30.1267 0.00729179 30.258 0.00729179 30.3728C0.00729179 30.6189 0.00729179 30.8814 0.00729179 31.1275C0.00729179 31.308 0.00729179 31.4721 0.00729179 31.6526C1.86123 31.6526 3.71516 31.144 5.323 30.2252C6.94725 29.3064 8.30899 27.9447 9.12932 26.2548C9.6051 25.2704 9.88402 24.1712 9.94964 23.0719C8.88322 23.0719 7.81679 22.9079 6.78318 22.5962C9.37541 22.0711 11.738 20.611 13.313 18.4945C14.6911 16.6898 15.3966 14.4585 15.3966 12.1944C15.3966 11.2264 15.3966 10.2585 15.3966 9.30688V4.41774H30.589L32 1.33331H15.3966ZM9.96605 17.9203C9.30979 17.9203 8.78478 17.3953 8.78478 16.739C8.78478 16.0828 9.30979 15.5578 9.96605 15.5578C10.6223 15.5578 11.1473 16.0828 11.1473 16.739C11.1473 17.3953 10.6223 17.9203 9.96605 17.9203Z"
        fill={color ?? theme.palette.purple2}
      />
    </svg>
  );
};
