type IconWunderkindProps = {
  size: "24px";
  className?: string;
};

export const IconWunderkind: React.FC<IconWunderkindProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 4.48659C24 5.8599 22.8867 6.97318 21.5134 6.97318C20.1401 6.97318 19.0268 5.8599 19.0268 4.48659C19.0268 3.11328 20.1401 2 21.5134 2C22.8867 2 24 3.11328 24 4.48659ZM0 2.37445V2.98293C0.345197 2.98293 0.590931 3.13506 0.860068 3.37494C1.12335 3.61482 1.33983 3.96587 1.51536 4.39298L8.57728 21.4188H9.15065L11.45 15.8722L5.84495 2.37445H0ZM15.7153 4.48074C15.6802 4.32277 15.6509 4.17065 15.6509 4.07118C15.6509 3.75524 15.7445 3.4978 15.9317 3.29888C16.119 3.1058 16.3881 3.00049 16.745 2.98293V2.37445H8.89908V2.98293C9.24428 2.98293 9.49001 3.13506 9.75915 3.37494C10.0224 3.61482 10.2389 3.96587 10.4144 4.39298L17.4764 21.4188H18.0497L20.3491 15.8722L16.0371 5.49293C15.9025 5.16528 15.7913 4.83179 15.7153 4.48074Z"
            fill="#191919"
          />
        </svg>
      );
  }
};
