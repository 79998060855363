type IconSendlaneProps = {
  size: "24px";
  className?: string;
};

export const IconSendlane: React.FC<IconSendlaneProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.3963 12.0073L21.3914 12.0122C21.3522 11.0946 20.9278 10.2389 20.2881 9.5872C20.2881 9.5872 16.5414 5.85628 14.6931 3.99267C14.605 3.88756 14.517 3.7812 14.4228 3.68722L11.7856 1.0297C10.4339 -0.333067 8.22112 -0.344196 6.85359 1.0025L4.05489 3.76018C3.36011 4.44404 2.98458 5.3678 3.00049 6.35216C3.01761 7.29696 3.43595 8.18732 4.10382 8.84892L7.4126 12.1804L4.40717 15.2052C3.71239 15.9064 3.34176 16.8512 3.38702 17.8516C3.42616 18.7692 3.85061 19.6249 4.49035 20.2766L7.07744 22.8847C7.72328 23.5352 8.5722 23.9581 9.48348 23.9964C10.4767 24.0409 11.4198 23.6687 12.1158 22.9676L16.0448 19.014L20.3761 14.6549C21.0709 13.9537 21.4416 13.009 21.3963 12.0085V12.0073ZM5.61081 6.10112C5.64384 5.91192 5.75392 5.73384 5.89214 5.60028L8.6676 2.86486C9.02112 2.5149 9.59968 2.5149 9.94708 2.87104L12.6675 5.60648C12.9598 5.9008 13.0259 6.35712 12.7776 6.76892L9.24128 10.3279L5.85912 6.92472C5.64384 6.70832 5.54965 6.4078 5.61081 6.10112ZM10.2786 21.12C9.93608 21.47 9.33548 21.465 8.98808 21.12L6.23464 18.3512C6.01936 18.1348 5.93129 17.8392 5.98633 17.5276C6.01936 17.3384 6.12456 17.1665 6.26278 17.0268L9.2474 14.023L13.2864 18.089L10.2798 21.12H10.2786ZM18.5266 12.8185V12.8136L15.1164 16.244L11.0773 12.1792L14.4986 8.73764C14.7139 8.5212 14.8962 8.27636 15.0454 8.0142L18.4997 11.4953C18.638 11.6338 18.7432 11.807 18.7762 11.9962C18.8312 12.3016 18.737 12.6021 18.5279 12.8198L18.5266 12.8185Z"
            fill="#FF3C78"
          />
        </svg>
      );
  }
};
