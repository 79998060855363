import { snakeCase } from "lodash";

import {
  type ConsentModeConfig,
  type EventsConnectorConfig,
  type GlobalConfig
} from "../apiTypes";
import {
  type Container as GtmContainer,
  type Tag,
  type Variable
} from "../gtmTypes";

/* ========================================================================== */

type Entry<T> = T extends T
  ? NonNullable<{ [K in keyof T]: [K, T[K]] }[keyof T]>
  : never;

export type WebChannelKey = Extract<
  keyof EventsConnectorConfig,
  "awin" | "ga4" | "facebook" | "tiktok" | "criteo" | "snapchat" | "pinterest"
>;

export type ChannelConfig = NonNullable<
  EventsConnectorConfig[WebChannelKey]
>[number];

type ChannelConfigEntry = Entry<ChannelConfig>;
type DataConfigEntry = Entry<NonNullable<ChannelConfig["dataConfig"]>>;

/* ========================================================================== */

type Configurable<Channel extends WebChannelKey = WebChannelKey> = {
  tag: Record<string, Tag>;
  variable: Record<string, Variable>;
  container: GtmContainer;
  channel: Channel;
  config: NonNullable<EventsConnectorConfig[Channel]>[number];
  channelName: string;
  suffix: string;
  globalConsentEnabled: boolean;
};

const getChannelName = (channel: WebChannelKey) => {
  switch (channel) {
    case "awin":
      return "Awin";
    case "ga4":
      return "GA4";
    case "facebook":
      return "Facebook";
    case "tiktok":
      return "TikTok";
    case "criteo":
      return "Criteo";
    case "snapchat":
      return "Snapchat";
    case "pinterest":
      return "Pinterest";
  }
};

const isChannel = <Channel extends WebChannelKey>(
  configurable: Configurable,
  channel: Channel
): configurable is Configurable<Channel> => {
  return configurable.channel === channel;
};

/* ========================================================================== */

const isConfigurable = <O extends Tag | Variable>(obj: O) =>
  obj.name.includes("DESTINATION_ID");

const removeTagsFromContainer = (
  container: GtmContainer
): Configurable["tag"] => {
  const items = container.containerVersion.tag ?? [];
  const ret = Object.fromEntries(
    items.filter(isConfigurable).map(i => [i.name, i])
  );
  container.containerVersion.tag = items.filter(
    i => !i.name.includes("DESTINATION_ID")
  );
  return ret;
};

const removeVarsFromContainer = (
  container: GtmContainer
): Configurable["variable"] => {
  const items = container.containerVersion.variable ?? [];
  const ret = Object.fromEntries(
    items.filter(isConfigurable).map(i => [i.name, i])
  );
  container.containerVersion.variable = items.filter(
    i => !i.name.includes("DESTINATION_ID")
  );
  return ret;
};

/* ========================================================================== */

const setVariableValue = (
  configurable: Configurable,
  variableName: string,
  value: string
) => {
  const variable = configurable.variable[variableName];
  const parameter = variable?.parameter?.[0];
  if (parameter && !("list" in parameter)) parameter.value = value;
};

const handleGA4EnhancedConversionData = (
  configurable: Configurable,
  sendUserData: boolean
) => {
  if (!isChannel(configurable, "ga4")) return;

  const completePurchaseTag =
    configurable.tag[`GA4 - Purchase${configurable.suffix}`];
  if (!completePurchaseTag) return;

  const userDataVariableParam = completePurchaseTag.parameter?.find(
    p => p.key === "userDataVariable"
  );
  if (!userDataVariableParam && sendUserData) {
    completePurchaseTag.parameter?.push({
      key: "userDataVariable",
      type: "TEMPLATE",
      value: "{{Enhanced Conversion Data}}"
    });
  } else if (userDataVariableParam && !sendUserData) {
    completePurchaseTag.parameter = completePurchaseTag.parameter?.filter(
      p => p.key !== "userDataVariable"
    );
  }

  const enhancedUserIdParam = completePurchaseTag.parameter?.find(
    p => p.key === "enhancedUserId"
  );
  if (enhancedUserIdParam && "value" in enhancedUserIdParam) {
    enhancedUserIdParam.value = String(sendUserData);
  }
};

/* ========================================================================== */

const applyDataConfig = (
  configurable: Configurable,
  entry: DataConfigEntry
) => {
  switch (entry[0]) {
    case "conversionValue":
      return setVariableValue(
        configurable,
        `${configurable.channelName} - conversion value${configurable.suffix}`,
        `{{constant - conversion value - ${entry[1]}}}`
      );
    case "contentType":
      return setVariableValue(
        configurable,
        `${configurable.channelName} - product group${configurable.suffix}`,
        `{{constant - product group - ${entry[1]}}}`
      );
    case "productAttributeMapping":
      return setVariableValue(
        configurable,
        `${configurable.channelName} - product identifier${configurable.suffix}`,
        `{{constant - product identifier - ${entry[1].replace("_", " ")}}}`
      );
    case "sendUserData":
      return handleGA4EnhancedConversionData(configurable, entry[1]);
    case "fallbackCurrencyCode":
    case "orderAttributeId":
    case "preferGaCookie":
    case "channelSourceOverrides":
    case "gatewaySourceOverrides":
    case "tagSourceOverrides":
    case "productToCommissionGroupMapping":
  }
};

const applyChannelConfig = (
  configurable: Configurable,
  entry: ChannelConfigEntry
) => {
  switch (entry[0]) {
    case "dataConfig":
      return (Object.entries(entry[1]) as Array<DataConfigEntry>).map(e =>
        applyDataConfig(configurable, e)
      );
    case "enabledWebEvents":
      return pauseDisabledTags(configurable);
    case "pixelId":
      return setVariableValue(
        configurable,
        `${configurable.channelName} - Pixel ID${configurable.suffix}`,
        entry[1]
      );
    case "consentMode":
      return applyConsent(configurable, entry[1]);
    case "measurementId":
      return setMeasurementId(configurable, entry[1]);
    case "partnerId":
      return setVariableValue(
        configurable,
        `Criteo - Partner ID${configurable.suffix}`,
        entry[1]
      );
    case "tagId":
      return setVariableValue(
        configurable,
        `Pinterest ID${configurable.suffix}`,
        entry[1]
      );
    case "adAccountId":
      return isChannel(configurable, "awin")
        ? setVariableValue(
            configurable,
            `Awin Merchant ID${configurable.suffix}`,
            entry[1]
          )
        : undefined;
    case "all_markets":
    case "market_groups":
    case "customDimensions":
    case "webhookOverrides":
    case "conversionEvents":
    case "testCode":
    case "accessToken":
    case "snapToken":
    case "applicationId":
    case "debugMode":
    case "actionSourceMaps":
    case "apiVersion":
    case "debug":
    case "live":
    case "testMode":
    case "wentLiveAt":
    case "createdAt":
    case "updatedAt":
    case "orderFilters":
    case "enabledEvents":
    case "uses_unified_checkout":
    case "subscriptionTagName":
    case "id":
    case "label":
    case "completedStep":
  }
};

/* ========================================================================== */

const pauseDisabledTags = (configurable: Configurable) => {
  if ("enabledWebEvents" in configurable.config) {
    const enabledEvents = configurable.config.enabledWebEvents;

    const disabledTriggerNames = Object.entries(enabledEvents)
      .filter(([_, enabled]) => !enabled)
      .flatMap(([eventName]) => {
        if (
          eventName === "subscribe" &&
          (isChannel(configurable, "facebook") ||
            isChannel(configurable, "pinterest"))
        ) {
          return [
            "*Update After Importing* Event - subscribe",
            "Event - subscribe - email",
            "Event - subscribe - phone"
          ];
        } else if (
          eventName === "subscribe" &&
          isChannel(configurable, "ga4")
        ) {
          return [
            "*Update After Importing* Event - subscribe",
            "Event - subscribe - email"
          ];
        } else if (
          eventName === "subscriptionPurchase" &&
          (isChannel(configurable, "snapchat") ||
            isChannel(configurable, "facebook") ||
            isChannel(configurable, "tiktok"))
        ) {
          return [
            "*Update after Import* Event - Subscription Purchase",
            "Event - subscription purchase"
          ];
        } else if (
          eventName === "subscribe" &&
          isChannel(configurable, "tiktok")
        ) {
          return "Event - subscribe - email";
        }
        const dlEvent = eventName === "pageView" ? "user_data" : eventName;
        return `Event - ${snakeCase(dlEvent)}`;
      });

    // Trigger IDs for events that are disabled
    const disabledTriggerIds = new Set(
      (configurable.container.containerVersion.trigger ?? [])
        .filter(({ name }) => disabledTriggerNames.includes(name))
        .map(trigger => trigger.triggerId)
    );

    // Pause tags that have no enabled triggers
    Object.values(configurable.tag).forEach(tag => {
      tag.paused = Boolean(
        tag.firingTriggerId?.every(triggerId =>
          disabledTriggerIds.has(triggerId)
        )
      );
    });

    if (isChannel(configurable, "tiktok")) {
      const completePaymentTag =
        configurable.tag[`TikTok - Complete Payment${configurable.suffix}`];
      if (completePaymentTag) {
        completePaymentTag.paused =
          "completePayment" in enabledEvents
            ? !enabledEvents.completePayment
            : false;
      }
    }

    if (isChannel(configurable, "ga4")) {
      const tag =
        configurable.tag[`GA4 Base Tag Configuration${configurable.suffix}`];

      if (tag) {
        tag.paused = false;
      }

      const variable =
        configurable.variable[`GA4 Config Settings${configurable.suffix}`];
      if (variable) {
        const parameter = variable.parameter?.find(
          p => p.key === "configSettingsTable"
        );
        if (parameter && "list" in parameter) {
          const pageView = parameter.list.find(
            m =>
              Array.isArray(m.map) &&
              m.map[0] &&
              "key" in m.map[0] &&
              m.map[0].key === "parameter" &&
              "value" in m.map[0] &&
              m.map[0].value === "send_page_view"
          );
          if (pageView?.map[1] && "value" in pageView.map[1]) {
            pageView.map[1].value =
              "pageView" in enabledEvents
                ? String(enabledEvents.pageView)
                : "true";
          }
        }
      }
    }

    // Pause sitewide pixels if no other events are enabled
    const activeTags = Object.values(configurable.tag).filter(
      t => !(t.paused ?? false)
    );
    const firstActiveTag = activeTags[0];

    if (
      activeTags.length === 1 &&
      firstActiveTag?.name ===
        `${configurable.channelName} - Sitewide Pixel${configurable.suffix}` &&
      configurable.channel !== "tiktok"
    ) {
      firstActiveTag.paused = true;
    }

    if (isChannel(configurable, "criteo")) {
      amendCriteoEvents(configurable);
    }
  }
};

const amendCriteoEvents = (configurable: Configurable) => {
  const userIdTag = `Criteo User Identification${configurable.suffix}`;
  const hasWebEventsEnabled =
    "enabledWebEvents" in configurable.config
      ? Object.values(configurable.config.enabledWebEvents).some(e => e)
      : false;
  if (hasWebEventsEnabled) {
    // Unpause Dynamic Loader tag
    const tag = Object.values(configurable.tag).find(
      tag => tag.name === `Criteo - Dynamic Loader${configurable.suffix}`
    );
    if (tag) tag.paused = false;
    // Remove User ID tag and Caller ID variable
    delete configurable.tag[userIdTag];
    delete configurable.variable[`Criteo - Caller ID${configurable.suffix}`];
  } else {
    // Pause all but the User ID tag
    Object.values(configurable.tag).forEach(tag => {
      tag.paused = tag.name !== userIdTag;
    });
  }
};

/* ========================================================================== */

const applyConsent = (
  configurable: Configurable,
  consentMode: ChannelConfig["consentMode"]
) => {
  const consentKeys: Array<keyof ConsentModeConfig> = [
    "ad_storage",
    "analytics_storage",
    "functionality_storage",
    "personalization_storage",
    "security_storage"
  ];
  const consentSettings =
    !configurable.globalConsentEnabled || !consentMode.enabled
      ? { consentStatus: "NOT_SET" as const }
      : {
          consentStatus: "NEEDED" as const,
          consentType: {
            type: "LIST" as const,
            list: Object.entries(consentMode)
              .filter(
                ([consentKey, enabled]) =>
                  consentKeys.includes(consentKey) && enabled
              )
              .map(([consentKey]) => ({
                type: "TEMPLATE" as const,
                value: consentKey
              }))
          }
        };

  Object.values(configurable.tag).forEach(
    tag => (tag.consentSettings = consentSettings)
  );
};

/* ========================================================================== */

const setMeasurementId = (
  configurable: Configurable,
  measurementId: string
) => {
  setVariableValue(
    configurable,
    isChannel(configurable, "ga4")
      ? `GA4 ID${configurable.suffix}`
      : `GA - UA ID Variable${configurable.suffix}`,
    measurementId
  );
};

/* ========================================================================== */

export const applyWebConfiguration = <Channel extends WebChannelKey>(
  channel: Channel,
  configs: EventsConnectorConfig[Channel],
  globalConfig: GlobalConfig,
  container: GtmContainer
) => {
  container = structuredClone(container);
  const { containerVersion } = container;

  let idCounter = Math.max(
    ...(containerVersion.variable?.map(v => Number(v.variableId)) ?? []),
    ...(containerVersion.tag?.map(v => Number(v.tagId)) ?? []),
    ...(containerVersion.trigger?.map(v => Number(v.triggerId)) ?? []),
    ...(containerVersion.folder?.map(v => Number(v.folderId)) ?? []),
    ...(containerVersion.customTemplate?.map(v => Number(v.templateId)) ?? [])
  );

  const configurableSrc: Pick<Configurable<Channel>, "tag" | "variable"> = {
    tag: removeTagsFromContainer(container),
    variable: removeVarsFromContainer(container)
  };

  configs
    .toSorted((c1, c2) => c1.id - c2.id)
    .forEach((config, i) => {
      const suffix = i === 0 ? "" : ` - ${config.id}`;

      const modifiedConfigurableSrc = JSON.parse(
        JSON.stringify(configurableSrc).replaceAll("DESTINATION_ID", suffix)
      ) as typeof configurableSrc;

      const configurable: Configurable<Channel> = {
        ...modifiedConfigurableSrc,
        container,
        channel,
        config,
        channelName: getChannelName(channel),
        suffix,
        globalConsentEnabled: globalConfig.consentModeEnabled
      };

      Object.values(configurable.variable).forEach(variable => {
        variable.variableId = String(++idCounter);
        variable.fingerprint += String(i);
      });
      Object.values(configurable.tag).forEach(tag => {
        tag.tagId = String(++idCounter);
        tag.fingerprint += String(i);
      });

      (Object.entries(config) as Array<ChannelConfigEntry>).forEach(entry => {
        applyChannelConfig(configurable, entry);
      });

      container.containerVersion.tag = Array.prototype.concat(
        container.containerVersion.tag ?? [],
        Object.values(configurable.tag)
      );
      container.containerVersion.variable = Array.prototype.concat(
        container.containerVersion.variable ?? [],
        Object.values(configurable.variable)
      );
    });
  return container;
};
