import { css } from "styled-components";

import {
  largeTextStyles,
  normalBodyStyles,
  smallTextStyles
} from "../typography/typography";

/* ========================================================================== */

const inputBorderWidth = 1;

export type InputFieldProps = {
  variant: "TINY" | "SMALL" | "LARGE";
  error?: boolean;
};

export const inputFieldFocusStyles = css<Omit<InputFieldProps, "variant">>`
  background-color: ${props => props.theme.palette.white};
  border-color: ${props =>
    props.error ? props.theme.palette.red1 : props.theme.palette.blue1};
  box-shadow: ${props =>
    props.error
      ? props.theme.other.focusBoxShadowError
      : props.theme.other.focusBoxShadow};
`;

export const inputFieldStyles = css<InputFieldProps>`
  ${props => props.variant === "TINY" && smallTextStyles};
  ${props => props.variant === "SMALL" && normalBodyStyles};
  ${props => props.variant === "LARGE" && largeTextStyles};

  padding-top: ${props =>
    props.variant === "TINY"
      ? props.theme.gridBase * 0.5 - inputBorderWidth
      : props.variant === "SMALL"
        ? props.theme.gridBase - inputBorderWidth
        : props.theme.gridBase * 1.5 - inputBorderWidth}px;

  padding-bottom: ${props =>
    props.variant === "TINY"
      ? props.theme.gridBase * 0.5 - inputBorderWidth
      : props.variant === "SMALL"
        ? props.theme.gridBase - inputBorderWidth
        : props.theme.gridBase * 1.5 - inputBorderWidth}px;

  padding-left: ${props =>
    props.variant === "TINY"
      ? props.theme.gridBase - inputBorderWidth
      : props.variant === "SMALL"
        ? props.theme.gridBase * 1.5 - inputBorderWidth
        : props.theme.gridBase * 2 - inputBorderWidth}px;

  padding-right: ${props =>
    props.variant === "TINY"
      ? props.theme.gridBase - inputBorderWidth
      : props.variant === "SMALL"
        ? props.theme.gridBase * 1.5 - inputBorderWidth
        : props.theme.gridBase * 2 - inputBorderWidth}px;

  display: block;
  width: 100%;
  border-width: ${inputBorderWidth}px;
  border-style: solid;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  position: relative;
  transition:
    background-color ${props => props.theme.other.transition},
    color ${props => props.theme.other.transition},
    border-color ${props => props.theme.other.transition},
    box-shadow ${props => props.theme.other.transition};
  border-color: ${props =>
    props.error ? props.theme.palette.red4 : props.theme.palette.grey8};
  background-color: ${props =>
    props.error ? props.theme.palette.red4 : props.theme.palette.grey8};
  text-overflow: ellipsis;

  &::placeholder {
    color: ${props => props.theme.palette.grey4};
    text-overflow: ellipsis;
    opacity: 1;
  }

  &:hover:not(:disabled) {
    border-color: ${props =>
      props.error ? props.theme.palette.red3 : props.theme.palette.grey7};
    background-color: ${props =>
      props.error ? props.theme.palette.red3 : props.theme.palette.grey7};
  }

  &:focus:not(:disabled) {
    ${inputFieldFocusStyles};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${props => props.theme.palette.grey3};
    border-color: ${props => props.theme.palette.grey8};
    background-color: ${props => props.theme.palette.grey8};

    &::placeholder {
      color: ${props => props.theme.palette.grey5};
    }
  }
`;
