import { uniqBy } from "lodash-es";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { type CompanyDetails } from "elevar-common-ts/src/apiTypes";

import { Avatar } from "elevar-design-system/src/Avatar";
import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { IconCross, IconHelp } from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { LabeledCheckBoxImage } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxImage";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading1Styles,
  normalTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  type CompanyMember,
  useCompanyDetailsQuery,
  useCompanyMembersQuery
} from "../../api/handlers/company";
import { useWebsiteCreateMutation } from "../../api/handlers/website";
import { QuestionFlowShell } from "../../components/QuestionFlowShell";
import { useLastLocation } from "../../context/LastLocation";
import { useUserRequired } from "../../context/User";
import { useCompanyId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import { isValidWebsiteName } from "../../utils/validate";

/* ========================================================================== */

export const CreateWebsite: React.FC = () => {
  return (
    <CreateWebsiteWrapper>
      <CreateWebsiteWithData />
    </CreateWebsiteWrapper>
  );
};

const CreateWebsiteWrapper = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.white};
`;

/* ========================================================================== */

const CreateWebsiteWithData: React.FC = () => {
  const companyDetails = useCompanyDetailsQuery();
  const companyMembers = useCompanyMembersQuery();

  if (companyDetails.error !== null || companyMembers.error !== null) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (companyDetails.data === undefined || companyMembers.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <CreateWebsiteInner
      companyDetails={companyDetails.data}
      companyMembers={companyMembers.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type CreateWebsiteInnerProps = {
  companyDetails: CompanyDetails;
  companyMembers: Array<CompanyMember>;
};

const CreateWebsiteInner: React.FC<CreateWebsiteInnerProps> = ({
  companyDetails,
  companyMembers
}) => {
  const theme = useTheme();
  const history = useHistory();
  const lastLocation = useLastLocation();
  const { accountDetails } = useUserRequired();
  const companyId = useCompanyId();

  const { mutateAsync: websiteCreateMutation } = useWebsiteCreateMutation();

  const companyOwner = companyMembers.find(member => member.role === "Owner")!;
  const currentUserMembers = companyMembers.filter(
    member => member.id === accountDetails.id
  );
  const initialMembersChecked = uniqBy(
    [companyOwner, ...currentUserMembers],
    member => member.id
  );

  const [isLoading, setIsLoading] = useState(false);
  const [websiteName, setWebsiteName] = useState("");
  const [membersChecked, setMembersChecked] = useState<Array<CompanyMember>>(
    initialMembersChecked
  );

  const filteredMembers = companyMembers.filter(
    member => member.role !== "Owner" && member.id !== accountDetails.id
  );

  return (
    <>
      <LogoWrapper>
        <ElevarLogo />
      </LogoWrapper>
      <PageCloseButtonWrapper>
        <PageCloseButton
          disabled={isLoading}
          onClick={() => history.push(lastLocation ?? "/")}
        >
          <IconCross size="16px" />
        </PageCloseButton>
      </PageCloseButtonWrapper>
      <CreateWebsiteQuestionFlowShell>
        <CompanyName>{companyDetails.name}</CompanyName>
        <PageTitle>Add New Website</PageTitle>
        <WebsiteNameInputWrapper labelText="Website URL" disabled={isLoading}>
          <InputFieldText
            variant="LARGE"
            value={websiteName}
            onChange={event => setWebsiteName(event.target.value)}
            placeholder="eg. www.getelevar.com"
            spellCheck={false}
            disabled={isLoading}
          />
        </WebsiteNameInputWrapper>
        {companyDetails.role !== "Owner" ? (
          <CompanyOwnerWrapper>
            <CompanyOwnerTitle>
              <div>Company Owner</div>
              <Tooltip
                text="Company owners must be members of all Websites."
                placement="right"
                delay={[150, 0]}
                maxWidth={`${theme.gridBase * 21}px`}
              >
                <TooltipContentsWrapper>
                  <IconHelp size="16px" />
                </TooltipContentsWrapper>
              </Tooltip>
            </CompanyOwnerTitle>
            <CompanyOwnerItem>
              <CompanyOwnerImageWrapper>
                <Avatar
                  size="SMALL"
                  type="USER"
                  imageSrc={companyOwner.picture?.file}
                />
              </CompanyOwnerImageWrapper>
              <CompanyOwnerName>
                {companyOwner.first_name} {companyOwner.last_name}
              </CompanyOwnerName>
            </CompanyOwnerItem>
          </CompanyOwnerWrapper>
        ) : null}
        {filteredMembers.length > 0 ? (
          <SelectTeamMembersWrapper>
            <SelectTeamMembersHeader>
              <div>Select Team Members</div>
              {filteredMembers.length > 1 ? (
                <button
                  onClick={() => {
                    setMembersChecked(
                      membersChecked.length - initialMembersChecked.length <
                        filteredMembers.length
                        ? [...initialMembersChecked, ...filteredMembers]
                        : [...initialMembersChecked]
                    );
                  }}
                >
                  {membersChecked.length - initialMembersChecked.length <
                  filteredMembers.length
                    ? "Select All"
                    : "Deselect All"}
                </button>
              ) : null}
            </SelectTeamMembersHeader>
            {filteredMembers.map(member => (
              <LabeledCheckBoxImage
                key={member.id}
                variant="NORMAL"
                isChecked={membersChecked.includes(member)}
                setIsChecked={isChecked => {
                  if (isChecked) {
                    setMembersChecked([...membersChecked, member]);
                  } else {
                    setMembersChecked(
                      membersChecked.filter(
                        checkedMember => checkedMember !== member
                      )
                    );
                  }
                }}
                image={
                  <Avatar
                    size="SMALL"
                    type="USER"
                    imageSrc={member.picture?.file}
                  />
                }
                text={`${member.first_name} ${member.last_name}`}
                isDisabled={isLoading}
              />
            ))}
          </SelectTeamMembersWrapper>
        ) : null}
        <AddWebsiteButtonWrapper>
          <ButtonPrimary
            variant="LARGE"
            state={
              isLoading
                ? "LOADING"
                : !isValidWebsiteName(websiteName)
                  ? "DISABLED"
                  : "IDLE"
            }
            onClick={async () => {
              setIsLoading(true);
              const trimmedWebsiteName = websiteName.trim();

              const website = await websiteCreateMutation({
                name: trimmedWebsiteName,
                memberships: membersChecked.map(member => member.id)
              });

              history.push(`/company/${companyId}/website/${website.id}`);
              track.websiteCreate({ name: trimmedWebsiteName });
            }}
          >
            Add Website
          </ButtonPrimary>
        </AddWebsiteButtonWrapper>
      </CreateWebsiteQuestionFlowShell>
    </>
  );
};

const LogoWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  left: ${props => props.theme.gridBase * 4}px;
`;

const PageCloseButtonWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  right: ${props => props.theme.gridBase * 4}px;
`;

const PageCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey6};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.palette.grey8};
  }
`;

const CreateWebsiteQuestionFlowShell = styled(QuestionFlowShell)`
  padding-top: ${props => props.theme.gridBase * 6}px;
`;

const CompanyName = styled.div`
  ${subheadingStyles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const PageTitle = styled.div`
  ${heading1Styles};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const WebsiteNameInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const CompanyOwnerWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const CompanyOwnerTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > :first-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
    margin-right: ${props => props.theme.gridBase}px;
  }
`;

const TooltipContentsWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

const CompanyOwnerItem = styled.div`
  display: inline-flex;
  align-items: center;
`;

const CompanyOwnerImageWrapper = styled.div`
  margin-left: ${props => props.theme.gridBase}px;
  margin-right: ${props => props.theme.gridBase * 1.5}px;
`;

const CompanyOwnerName = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-right: ${props => props.theme.gridBase}px;
`;

const SelectTeamMembersWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const SelectTeamMembersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase}px;

  > div {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey2};
  }

  > button {
    ${normalTextStyles};
    ${linkStyles};
  }
`;

const AddWebsiteButtonWrapper = styled.div`
  display: flex;
  margin-top: ${props => props.theme.gridBase * 2.5}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;
