import styled, {
  css,
  type StyledComponentPropsWithRef,
  useTheme
} from "styled-components";

import { IconCross, IconSearch } from "elevar-design-system/src/icons";
import {
  type InputFieldProps,
  inputFieldStyles
} from "elevar-design-system/src/inputs/sharedInputStyles";

/* ========================================================================== */

type InputFieldSearchWithoutIconProps = StyledComponentPropsWithRef<
  typeof InputFieldSearchWithoutIcon
>;

type RenderedOn = "CARD" | "PAGE";

type InputFieldSearchProps = InputFieldProps &
  Omit<InputFieldSearchWithoutIconProps, "options"> & {
    canClear: boolean;
    onClear: () => void;
    renderedOn?: RenderedOn;
  };

export const InputFieldSearch: React.FC<InputFieldSearchProps> = ({
  variant,
  error,
  disabled,
  canClear,
  onClear,
  renderedOn = "CARD",
  ...baseElementProps
}) => {
  const theme = useTheme();

  return (
    <Wrapper variant={variant} renderedOn={renderedOn}>
      <InputFieldSearchWithoutIcon
        variant={variant}
        error={error}
        renderedOn={renderedOn}
        disabled={disabled}
        {...baseElementProps}
      />
      <div>
        {canClear ? (
          <button onClick={onClear}>
            <IconCross
              size="24px"
              color={disabled ? theme.palette.grey6 : theme.palette.grey5}
            />
          </button>
        ) : (
          <IconSearch
            size="24px"
            color={disabled ? theme.palette.grey6 : theme.palette.grey5}
          />
        )}
      </div>
    </Wrapper>
  );
};

/* ========================================================================== */

type Props = {
  renderedOn: RenderedOn;
  variant: InputFieldProps["variant"];
};

const Wrapper = styled.div<Props>`
  position: relative;

  > div:last-child {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${props =>
      props.variant === "LARGE"
        ? props.theme.gridBase * 2
        : props.theme.gridBase * 1.5}px;

    svg path {
      transition: stroke ${props => props.theme.other.transition};
    }
  }
`;

const InputFieldSearchWithoutIcon = styled.input<InputFieldProps & Props>`
  ${inputFieldStyles};
  padding-right: ${props =>
    props.variant === "LARGE"
      ? props.theme.gridBase * 6.5
      : props.theme.gridBase * 6}px;

  ${props =>
    props.renderedOn === "PAGE" &&
    css<Props>`
      &,
      &:hover&:not(:disabled),
      &:disabled {
        border-color: ${props => props.theme.palette.white};
        background-color: ${props => props.theme.palette.white};
      }

      &:focus&:not(:disabled) {
        border-color: ${props => props.theme.palette.blue1};
      }
    `};
`;
