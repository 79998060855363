import "elevar-design-system/styles/reset.css";
import "./global.css";

import { init as initSentry } from "@sentry/browser";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import dayjs from "dayjs";
import dayjsRelativeTimePlugin from "dayjs/plugin/relativeTime";
import dayjsTimezonePlugin from "dayjs/plugin/timezone";
import dayjsUtcPlugin from "dayjs/plugin/utc";
import { useMemo, useReducer } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { z } from "zod";

import { theme } from "elevar-design-system/src/theme";
import { ToastContainer } from "elevar-design-system/src/toast/ToastContainer";
import { TooltipAppendToProvider } from "elevar-design-system/src/Tooltip";

import {
  shopifyAccessErrorSchema,
  shopifyScopeErrorSchema
} from "./api/handlers/shopify";
import {
  GlobalErrorProvider,
  globalErrorReducer,
  initialGlobalErrorState
} from "./context/GlobalError";
import { LastLocationProvider } from "./context/LastLocation";
import { GoogleAuthProvider } from "./utils/googleAuth";
import { App } from "./App";
import { getUserConfirmation } from "./getUserConfirmation";

/* ========================================================================== */

initSentry({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

  /*
   * This Sentry lifecycle hook has been implemented so that any fetch
   * request that is being made to the Segment API URL (api.segment.io)
   * doesn't get included as a breadcrumb, as currently, the Segment
   * script will continue attempting to send requests if the previous
   * one failed (which happens a lot in Firefox due to the default
   * tracking protection), resulting in a lot of noise in breadcrumbs
   * if we don't ignore the ones created for the Segment API calls.
   */
  beforeBreadcrumb: b => {
    if (
      (b.category === "xhr" || b.category === "fetch") &&
      typeof b.data?.url === "string" &&
      b.data.url.includes("api.segment.io")
    ) {
      return null;
    } else {
      return b;
    }
  },

  /*
   * This Sentry lifecycle hook has been implemented so that any event
   * that originates from a Chrome extension or our GTM container is
   * dropped from being sent. Chrome extension errors are not relevant
   * to this workspace, and errors from our GTM container are not too
   * actionable for the dev team.
   */
  beforeSend: event => {
    if (
      event.breadcrumbs?.some(
        b =>
          (b.category === "xhr" || b.category === "fetch") &&
          typeof b.data?.url === "string" &&
          (b.data.url.includes("chrome-extension://") ||
            b.data.url.includes("www.googletagmanager.com/gtm.js"))
      )
    ) {
      return null;
    } else {
      return event;
    }
  }
});

/* ========================================================================== */

dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsTimezonePlugin);
dayjs.extend(dayjsRelativeTimePlugin);

/* ========================================================================== */

const TopLevelComponent: React.FC = () => {
  const [globalError, globalErrorDispatch] = useReducer(
    globalErrorReducer,
    initialGlobalErrorState
  );

  const queryClient = useMemo(() => {
    const inWebsiteSchema = z.object({ websiteId: z.number() });

    const onErrorInternal = (
      error: unknown,
      meta: Record<string, unknown> | undefined
    ) => {
      const parsedMeta = inWebsiteSchema.safeParse(meta);
      const parsedError = z
        .union([shopifyAccessErrorSchema, shopifyScopeErrorSchema])
        .safeParse(error);

      if (parsedMeta.success && parsedError.success) {
        globalErrorDispatch({
          type: "SET_REACTIVE",
          payload: {
            websiteId: parsedMeta.data.websiteId,
            code: parsedError.data.cause.errors.code
          }
        });
      }
    };

    return new QueryClient({
      queryCache: new QueryCache({
        onError: (error, query) => {
          onErrorInternal(error, query.meta);
        }
      }),
      mutationCache: new MutationCache({
        onError: (error, _variables, _context, mutation) => {
          onErrorInternal(error, mutation.meta);
        }
      }),
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          staleTime: 10_000 // 10 seconds
        }
      }
    });
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <GlobalErrorProvider
          globalError={globalError}
          globalErrorDispatch={globalErrorDispatch}
        >
          <BrowserRouter getUserConfirmation={getUserConfirmation}>
            <LastLocationProvider>
              <TooltipAppendToProvider value={window.document.body}>
                <StyleSheetManager disableVendorPrefixes={true}>
                  <ThemeProvider theme={theme}>
                    <GoogleAuthProvider>
                      <ToastContainer />
                      <App />
                    </GoogleAuthProvider>
                  </ThemeProvider>
                </StyleSheetManager>
              </TooltipAppendToProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </GlobalErrorProvider>
        <ReactQueryDevtools buttonPosition="bottom-right" />
      </QueryClientProvider>
    </HelmetProvider>
  );
};

const root = createRoot(document.querySelector("#root")!);
root.render(<TopLevelComponent />);
