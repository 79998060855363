import { useState } from "react";
import styled, {
  type StyledComponentPropsWithRef,
  useTheme
} from "styled-components";

import { IconEye, IconEyeOff } from "../icons";
import { InputFieldText } from "./InputFieldText";
import { type InputFieldProps } from "./sharedInputStyles";

/* ========================================================================== */

type InputFieldTextProps = StyledComponentPropsWithRef<typeof InputFieldText>;

type InputFieldPasswordProps = InputFieldProps &
  Omit<InputFieldTextProps, "type">;

export const InputFieldPassword: React.FC<InputFieldPasswordProps> = ({
  variant,
  error,
  disabled,
  ...baseElementProps
}) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper variant={variant}>
      <InputFieldText
        variant={variant}
        type={showPassword ? "text" : "password"}
        error={error}
        disabled={disabled}
        {...baseElementProps}
      />
      <button
        tabIndex={-1}
        disabled={disabled}
        onPointerUp={() => setShowPassword(!showPassword)}
        onKeyPress={event => {
          if (event.key === "Enter") {
            event.preventDefault();
            setShowPassword(!showPassword);
          }
        }}
      >
        {showPassword ? (
          <IconEyeOff
            size="24px"
            color={disabled ? theme.palette.grey5 : theme.palette.grey4}
          />
        ) : (
          <IconEye
            size="24px"
            color={disabled ? theme.palette.grey5 : theme.palette.grey4}
          />
        )}
      </button>
    </Wrapper>
  );
};

/* ========================================================================== */

type WrapperProps = {
  variant: InputFieldProps["variant"];
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;

  > ${InputFieldText} {
    padding-right: ${props =>
      props.variant === "LARGE"
        ? props.theme.gridBase * 6.5
        : props.theme.gridBase * 6}px;
  }

  > button:last-child {
    display: flex;
    align-items: center;
    position: absolute;
    top: ${props =>
      props.variant === "LARGE"
        ? props.theme.gridBase * 1.5
        : props.theme.gridBase}px;
    bottom: ${props =>
      props.variant === "LARGE"
        ? props.theme.gridBase * 1.5
        : props.theme.gridBase}px;
    right: ${props =>
      props.variant === "LARGE"
        ? props.theme.gridBase * 2
        : props.theme.gridBase * 1.5}px;

    &:disabled {
      cursor: not-allowed;
    }

    > svg path {
      transition: stroke ${props => props.theme.other.transition};
    }
  }
`;
