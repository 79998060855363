import { useId } from "react";

/* ========================================================================== */

type IconPinterestProps = {
  size: "24px" | "16px";
  className?: string;
};

export const IconPinterest: React.FC<IconPinterestProps> = ({
  size,
  className
}) => {
  const idPrefix = useId();
  const clipPath1Id = `${idPrefix}-clipPath1`;
  const clipPath2Id = `${idPrefix}-clipPath2`;

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath={`url(#${clipPath1Id})`}>
            <path
              d="M1 12.0002C1 16.5045 3.70858 20.3741 7.58445 22.0754C7.55351 21.3073 7.57895 20.3852 7.77591 19.5494C7.98741 18.6565 9.19131 13.5555 9.19131 13.5555C9.19131 13.5555 8.83991 12.8531 8.83991 11.8151C8.83991 10.1849 9.7847 8.96744 10.9614 8.96744C11.9619 8.96744 12.4453 9.71896 12.4453 10.6189C12.4453 11.6247 11.8038 13.1292 11.4739 14.5227C11.1983 15.6896 12.059 16.6413 13.2101 16.6413C15.2942 16.6413 16.6979 13.9644 16.6979 10.7928C16.6979 8.38195 15.0742 6.57743 12.1207 6.57743C8.78396 6.57743 6.70522 9.06584 6.70522 11.8454C6.70522 12.8038 6.98778 13.4796 7.43036 14.0029C7.63386 14.2433 7.66213 14.34 7.58848 14.616C7.53572 14.8184 7.41455 15.3057 7.36436 15.4988C7.29114 15.7774 7.06538 15.877 6.81359 15.7741C5.27668 15.1467 4.56091 13.4636 4.56091 11.5716C4.56091 8.44683 7.19627 4.69995 12.4226 4.69995C16.6224 4.69995 19.3866 7.73905 19.3866 11.0013C19.3866 15.3165 16.9876 18.5403 13.4512 18.5403C12.2637 18.5403 11.1466 17.8983 10.7639 17.1691C10.7639 17.1691 10.1253 19.7036 9.99001 20.193C9.75677 21.0411 9.30027 21.8888 8.88287 22.5495C9.87219 22.8415 10.9173 23.0006 12.0004 23.0006C18.0748 23.0006 23.0001 18.0756 23.0001 12.0002C23.0001 5.92499 18.0748 1 12.0004 1C5.92542 1 1 5.92499 1 12.0002Z"
              fill="#CB1F27"
            />
          </g>
          <defs>
            <clipPath id={clipPath1Id}>
              <rect
                width="22"
                height="22"
                fill="white"
                transform="translate(1 1)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <g clipPath={`url(#${clipPath2Id})`}>
            <path
              d="M0 8.00012C0 11.276 1.96988 14.0902 4.78869 15.3276C4.76619 14.7689 4.78469 14.0983 4.92794 13.4905C5.08175 12.8411 5.95731 9.13125 5.95731 9.13125C5.95731 9.13125 5.70175 8.62044 5.70175 7.8655C5.70175 6.67994 6.38888 5.7945 7.24463 5.7945C7.97231 5.7945 8.32388 6.34106 8.32388 6.99556C8.32388 7.72706 7.85731 8.82125 7.61737 9.83469C7.41694 10.6833 8.04287 11.3755 8.88006 11.3755C10.3958 11.3755 11.4167 9.42869 11.4167 7.12206C11.4167 5.36869 10.2357 4.05631 8.08781 4.05631C5.66106 4.05631 4.14925 5.86606 4.14925 7.88756C4.14925 8.58456 4.35475 9.07606 4.67662 9.45669C4.82462 9.6315 4.84519 9.70181 4.79163 9.90256C4.75325 10.0497 4.66512 10.4041 4.62862 10.5446C4.57537 10.7472 4.41119 10.8196 4.22806 10.7448C3.11031 10.2885 2.58975 9.06444 2.58975 7.68844C2.58975 5.41587 4.50637 2.69088 8.30737 2.69088C11.3617 2.69088 13.3721 4.90112 13.3721 7.27369C13.3721 10.412 11.6273 12.7566 9.05544 12.7566C8.19175 12.7566 7.37931 12.2897 7.101 11.7594C7.101 11.7594 6.63656 13.6026 6.53819 13.9586C6.36856 14.5754 6.03656 15.1919 5.733 15.6724C6.4525 15.8847 7.21256 16.0004 8.00031 16.0004C12.4181 16.0004 16.0001 12.4186 16.0001 8.00012C16.0001 3.58181 12.4181 0 8.00031 0C3.58213 0 0 3.58181 0 8.00012Z"
              fill="#CB1F27"
            />
          </g>
          <defs>
            <clipPath id={clipPath2Id}>
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};
