import { sortBy } from "lodash-es";
import { useState } from "react";
import styled from "styled-components";

import { ButtonDropdownBase } from "elevar-design-system/src/buttons/ButtonDropdown";
import {
  baseButtonStyles,
  smallButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import { IconChevronDown, IconCircle } from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { LabeledCheckBoxMulti } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxMulti";
import {
  heading2Styles,
  heading3Styles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../../api/handlers/appEducationSlideshows";
import { type CroIdea } from "../../api/handlers/croIdeas";
import { FeatureTipsButton } from "../../components/FeatureTipsButton";
import { track } from "../../utils/track";
import { CroIdeasCardLink, getFormattedPage } from "./CroIdeasCardLink";

/* ========================================================================== */

type FilterSelections = Record<Lowercase<CroIdea["page"]>, boolean>;

const initialFilterSelections: FilterSelections = {
  sitewide: false,
  homepage: false,
  plp: false,
  pdp: false,
  cart: false,
  checkout: false
};

type CroIdeasListSort =
  | "IMPACT_DESC"
  | "IMPACT_ASC"
  | "EFFORT_DESC"
  | "EFFORT_ASC";

const croIdeasListSortOptionImpactDesc: Option<CroIdeasListSort> = {
  name: "Impact (High - Low)",
  value: "IMPACT_DESC"
};

const croIdeasListSortOptionImpactAsc: Option<CroIdeasListSort> = {
  name: "Impact (Low - High)",
  value: "IMPACT_ASC"
};

const croIdeasListSortOptionEffortDesc: Option<CroIdeasListSort> = {
  name: "Effort (High - Low)",
  value: "EFFORT_DESC"
};

const croIdeasListSortOptionEffortAsc: Option<CroIdeasListSort> = {
  name: "Effort (Low - High)",
  value: "EFFORT_ASC"
};

const croIdeasListSortOptions: Array<Option<CroIdeasListSort>> = [
  croIdeasListSortOptionImpactDesc,
  croIdeasListSortOptionImpactAsc,
  croIdeasListSortOptionEffortDesc,
  croIdeasListSortOptionEffortAsc
];

/* ========================================================================== */

type CroIdeasListProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  croIdeas: Array<CroIdea>;
};

export const CroIdeasList: React.FC<CroIdeasListProps> = ({
  appEducationSlideshows,
  croIdeas
}) => {
  const [filterSelections, setFilterSelections] = useState(
    initialFilterSelections
  );
  const [sortSelection, setSortSelection] = useState(
    croIdeasListSortOptionImpactDesc
  );

  const filterCount = Object.values(filterSelections).filter(Boolean).length;

  const filteredCroIdeas =
    filterCount === 0
      ? croIdeas
      : croIdeas.filter(
          croIdea =>
            (croIdea.page === "SITEWIDE" && filterSelections.sitewide) ||
            (croIdea.page === "HOMEPAGE" && filterSelections.homepage) ||
            (croIdea.page === "PLP" && filterSelections.plp) ||
            (croIdea.page === "PDP" && filterSelections.pdp) ||
            (croIdea.page === "CART" && filterSelections.cart) ||
            (croIdea.page === "CHECKOUT" && filterSelections.checkout)
        );

  const sortFilteredCroIdeas = () => {
    switch (sortSelection.value) {
      case "IMPACT_DESC": {
        return sortBy(filteredCroIdeas, [
          croIdea => croIdea.impact !== 5,
          croIdea => croIdea.impact !== 4,
          croIdea => croIdea.impact !== 3,
          croIdea => croIdea.impact !== 2,
          croIdea => croIdea.impact !== 1,
          croIdea => croIdea.title,
          croIdea => croIdea.id
        ]);
      }
      case "IMPACT_ASC": {
        return sortBy(filteredCroIdeas, [
          croIdea => croIdea.impact !== 1,
          croIdea => croIdea.impact !== 2,
          croIdea => croIdea.impact !== 3,
          croIdea => croIdea.impact !== 4,
          croIdea => croIdea.impact !== 5,
          croIdea => croIdea.title,
          croIdea => croIdea.id
        ]);
      }
      case "EFFORT_DESC": {
        return sortBy(filteredCroIdeas, [
          croIdea => croIdea.effort !== "HARD",
          croIdea => croIdea.effort !== "MEDIUM",
          croIdea => croIdea.effort !== "EASY",
          croIdea => croIdea.title,
          croIdea => croIdea.id
        ]);
      }
      case "EFFORT_ASC": {
        return sortBy(filteredCroIdeas, [
          croIdea => croIdea.effort !== "EASY",
          croIdea => croIdea.effort !== "MEDIUM",
          croIdea => croIdea.effort !== "HARD",
          croIdea => croIdea.title,
          croIdea => croIdea.id
        ]);
      }
    }
  };

  const filteredAndSortedCroIdeas = sortFilteredCroIdeas();

  return (
    <PageWrapper>
      <PageHeader>
        <PageHeading>CRO Ideas</PageHeading>
        <FeatureTipsButton data={appEducationSlideshows} route="CRO_IDEAS" />
      </PageHeader>
      <SortAndFilterWrapper>
        <div>
          <InputFieldSelect
            value={sortSelection}
            setValue={selection => {
              setSortSelection(selection);
              track.croIdeasListSort({ sort: selection.value });
            }}
            options={croIdeasListSortOptions}
            variant="SMALL"
            renderedOn="PAGE"
            placeholder=""
          />
        </div>
        <div>
          <FilterDropdown
            isActive={filterCount > 0}
            dropdownPlacement="bottom-end"
            dropdownContent={() => (
              <FilterDropdownContentWrapper>
                <FilterDropdownLabeledCheckBoxMulti
                  variant="NORMAL"
                  text={getFormattedPage("SITEWIDE")}
                  isChecked={filterSelections.sitewide}
                  setIsChecked={isChecked => {
                    setFilterSelections(previousFilterSelections => ({
                      ...previousFilterSelections,
                      sitewide: isChecked
                    }));

                    if (isChecked) {
                      track.croIdeasListFilter({
                        filter: getFormattedPage("SITEWIDE")
                      });
                    }
                  }}
                />
                <FilterDropdownLabeledCheckBoxMulti
                  variant="NORMAL"
                  text={getFormattedPage("HOMEPAGE")}
                  isChecked={filterSelections.homepage}
                  setIsChecked={isChecked => {
                    setFilterSelections(previousFilterSelections => ({
                      ...previousFilterSelections,
                      homepage: isChecked
                    }));

                    if (isChecked) {
                      track.croIdeasListFilter({
                        filter: getFormattedPage("HOMEPAGE")
                      });
                    }
                  }}
                />
                <FilterDropdownLabeledCheckBoxMulti
                  variant="NORMAL"
                  text={getFormattedPage("PLP")}
                  isChecked={filterSelections.plp}
                  setIsChecked={isChecked => {
                    setFilterSelections(previousFilterSelections => ({
                      ...previousFilterSelections,
                      plp: isChecked
                    }));

                    if (isChecked) {
                      track.croIdeasListFilter({
                        filter: getFormattedPage("PLP")
                      });
                    }
                  }}
                />
                <FilterDropdownLabeledCheckBoxMulti
                  variant="NORMAL"
                  text={getFormattedPage("PDP")}
                  isChecked={filterSelections.pdp}
                  setIsChecked={isChecked => {
                    setFilterSelections(previousFilterSelections => ({
                      ...previousFilterSelections,
                      pdp: isChecked
                    }));

                    if (isChecked) {
                      track.croIdeasListFilter({
                        filter: getFormattedPage("PDP")
                      });
                    }
                  }}
                />
                <FilterDropdownLabeledCheckBoxMulti
                  variant="NORMAL"
                  text={getFormattedPage("CART")}
                  isChecked={filterSelections.cart}
                  setIsChecked={isChecked => {
                    setFilterSelections(previousFilterSelections => ({
                      ...previousFilterSelections,
                      cart: isChecked
                    }));

                    if (isChecked) {
                      track.croIdeasListFilter({
                        filter: getFormattedPage("CART")
                      });
                    }
                  }}
                />
                <FilterDropdownLabeledCheckBoxMulti
                  variant="NORMAL"
                  text={getFormattedPage("CHECKOUT")}
                  isChecked={filterSelections.checkout}
                  setIsChecked={isChecked => {
                    setFilterSelections(previousFilterSelections => ({
                      ...previousFilterSelections,
                      checkout: isChecked
                    }));

                    if (isChecked) {
                      track.croIdeasListFilter({
                        filter: getFormattedPage("CHECKOUT")
                      });
                    }
                  }}
                />
              </FilterDropdownContentWrapper>
            )}
          >
            <div>Filter Pages</div>
            <div>
              <div>
                <FilterActiveCount>
                  <div>
                    <IconCircle size="24px" />
                  </div>
                  <div>{filterCount > 0 ? filterCount : 1}</div>
                </FilterActiveCount>
              </div>
              <div>
                <FilterDropdownIconWrapper>
                  <IconChevronDown size="16px" />
                </FilterDropdownIconWrapper>
              </div>
            </div>
          </FilterDropdown>
        </div>
      </SortAndFilterWrapper>
      {filteredAndSortedCroIdeas.length === 0 ? (
        <NoIdeasFoundWrapper>No Ideas Found</NoIdeasFoundWrapper>
      ) : (
        filteredAndSortedCroIdeas.map(croIdea => (
          <CroIdeasCardLinkWrapper key={croIdea.id}>
            <CroIdeasCardLink croIdea={croIdea} />
          </CroIdeasCardLinkWrapper>
        ))
      )}
    </PageWrapper>
  );
};

/* ========================================================================== */

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  min-height: ${props => props.theme.gridBase * 5}px;
`;

const PageHeading = styled.div`
  ${heading2Styles};
`;

const SortAndFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    width: ${props => props.theme.gridBase * 24}px;
  }
`;

type FilterDropdownProps = {
  isActive: boolean;
};

const FilterDropdown = styled(ButtonDropdownBase)<FilterDropdownProps>`
  ${baseButtonStyles};
  ${smallButtonStyles};
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;

  background-color: ${props =>
    props.isActive ? props.theme.palette.blue1 : props.theme.palette.white};

  > div:first-child {
    color: ${props =>
      props.isActive ? props.theme.palette.white : props.theme.palette.grey2};
    transition: color ${props => props.theme.other.transition};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    width: ${props => props.theme.gridBase * 3}px;
    height: ${props => props.theme.gridBase * 3}px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    > div:first-child {
      position: absolute;
      visibility: ${props => (props.isActive ? "visible" : "hidden")};
      opacity: ${props => (props.isActive ? 1 : 0)};
      transition:
        opacity ${props => props.theme.other.transition},
        visibility ${props => props.theme.other.transition};
    }

    > div:last-child {
      position: absolute;
      visibility: ${props => (props.isActive ? "hidden" : "visible")};
      opacity: ${props => (props.isActive ? 0 : 1)};
      transition:
        opacity ${props => props.theme.other.transition},
        visibility ${props => props.theme.other.transition};
    }
  }
`;

const FilterDropdownContentWrapper = styled.div`
  width: ${props => props.theme.gridBase * 20}px;
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const FilterDropdownLabeledCheckBoxMulti = styled(LabeledCheckBoxMulti)`
  width: 100%;
`;

const FilterActiveCount = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.white};
  width: ${props => props.theme.gridBase * 3}px;
  height: ${props => props.theme.gridBase * 3}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > div {
    display: flex;
    position: absolute;
  }
`;

const FilterDropdownIconWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

const NoIdeasFoundWrapper = styled.div`
  ${heading3Styles};
  text-align: center;
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const CroIdeasCardLinkWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;
