type IconGoogleAdsProps = {
  size: "24px" | "16px";
  className?: string;
};

export const IconGoogleAds: React.FC<IconGoogleAdsProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.48639 4.53107C8.69903 3.97288 8.99141 3.459 9.42556 3.04257C11.1621 1.3503 14.0328 1.77558 15.2112 3.902C16.0972 5.51454 17.0364 7.09163 17.949 8.68645C19.4729 11.3356 21.0146 13.9848 22.5208 16.6428C23.7878 18.8667 22.4144 21.6753 19.8982 22.0563C18.3565 22.2867 16.9123 21.5779 16.1149 20.1957C14.7771 17.8655 13.4303 15.5353 12.0924 13.214C12.0659 13.1608 12.0304 13.1165 11.995 13.0722C11.8532 12.957 11.7912 12.7887 11.7026 12.638C11.109 11.5926 10.4976 10.5559 9.904 9.5193C9.52302 8.84593 9.12432 8.18142 8.74333 7.50806C8.39779 6.90557 8.23831 6.24992 8.25603 5.55884C8.28261 5.20443 8.32691 4.85003 8.48639 4.53107Z"
            fill="#3C8BD9"
          />
          <path
            d="M8.48657 4.53101C8.40683 4.84997 8.33595 5.16893 8.31823 5.50562C8.29165 6.24986 8.47771 6.94095 8.84983 7.58774C9.82444 9.26229 10.799 10.9457 11.7648 12.6291C11.8534 12.7797 11.9243 12.9304 12.0129 13.0721C11.4813 13.9936 10.9497 14.9062 10.4092 15.8276C9.66496 17.1123 8.92071 18.4059 8.1676 19.6906C8.13216 19.6906 8.1233 19.6729 8.11444 19.6463C8.10558 19.5754 8.13216 19.5134 8.14988 19.4425C8.51315 18.1135 8.2119 16.9351 7.29932 15.9251C6.74113 15.3137 6.03232 14.9682 5.2172 14.853C4.15399 14.7024 3.21482 14.977 2.37311 15.6416C2.22249 15.7567 2.12503 15.9251 1.94782 16.0137C1.91238 16.0137 1.89466 15.996 1.8858 15.9694C2.31109 15.234 2.72751 14.4986 3.1528 13.7632C4.90709 10.7153 6.66139 7.66748 8.42455 4.62847C8.44227 4.59303 8.46885 4.56645 8.48657 4.53101Z"
            fill="#FABC04"
          />
          <path
            d="M1.92125 15.996C2.08959 15.8454 2.24908 15.6859 2.42628 15.5441C4.57928 13.843 7.81321 15.0746 8.28279 17.768C8.39798 18.4148 8.33596 19.035 8.14103 19.6552C8.13217 19.7084 8.12331 19.7527 8.10559 19.8058C8.02585 19.9476 7.95497 20.0982 7.86637 20.24C7.07782 21.5424 5.91715 22.1892 4.39322 22.0917C2.64778 21.9677 1.27447 20.6564 1.03524 18.9198C0.920063 18.0781 1.0884 17.2896 1.52255 16.563C1.61115 16.4036 1.71747 16.2618 1.81493 16.1023C1.85923 16.0669 1.84151 15.996 1.92125 15.996Z"
            fill="#34A852"
          />
          <path
            d="M1.92135 15.9961C1.88591 16.0315 1.88591 16.0935 1.82389 16.1024C1.81503 16.0404 1.85047 16.0049 1.88591 15.9606L1.92135 15.9961Z"
            fill="#FABC04"
          />
          <path
            d="M8.10562 19.806C8.07018 19.7439 8.10562 19.6996 8.14106 19.6553C8.14992 19.6642 8.16764 19.6819 8.1765 19.6908L8.10562 19.806Z"
            fill="#E1C025"
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M5.63037 3.13689C5.76789 2.7759 5.95698 2.44356 6.23775 2.17425C7.36083 1.07982 9.21735 1.35486 9.97944 2.73006C10.5524 3.77292 11.1598 4.79286 11.75 5.82426C12.7356 7.53753 13.7326 9.2508 14.7067 10.9698C15.5261 12.408 14.6379 14.2244 13.0106 14.4708C12.0136 14.6198 11.0796 14.1614 10.5639 13.2675C9.69867 11.7605 8.82771 10.2535 7.96248 8.75229C7.94529 8.71791 7.92237 8.68926 7.89945 8.66061C7.80777 8.58612 7.76766 8.47725 7.71036 8.37984C7.32645 7.7037 6.93108 7.03329 6.54717 6.36288C6.30078 5.9274 6.04293 5.49765 5.79654 5.06217C5.57307 4.67253 5.46993 4.24851 5.48139 3.80157C5.49858 3.57237 5.52723 3.34317 5.63037 3.13689Z"
            fill="#3C8BD9"
          />
          <path
            d="M5.63042 3.13681C5.57885 3.34309 5.53301 3.54937 5.52155 3.76711C5.50436 4.24843 5.62469 4.69537 5.86535 5.11366C6.49565 6.19663 7.12595 7.28533 7.75052 8.37403C7.80782 8.47144 7.85366 8.56885 7.91096 8.66053C7.56716 9.25645 7.22336 9.84664 6.87383 10.4426C6.39251 11.2734 5.91119 12.11 5.42414 12.9408C5.40122 12.9408 5.39549 12.9294 5.38976 12.9122C5.38403 12.8663 5.40122 12.8262 5.41268 12.7804C5.64761 11.9209 5.45279 11.1588 4.8626 10.5056C4.50161 10.1102 4.04321 9.88675 3.51605 9.81226C2.82845 9.71485 2.22107 9.89248 1.67672 10.3222C1.57931 10.3967 1.51628 10.5056 1.40168 10.5629C1.37876 10.5629 1.3673 10.5514 1.36157 10.5342C1.63661 10.0586 1.90592 9.58306 2.18096 9.10747C3.3155 7.13635 4.45004 5.16523 5.59031 3.19984C5.60177 3.17692 5.61896 3.15973 5.63042 3.13681Z"
            fill="#FABC04"
          />
          <path
            d="M1.38461 10.5515C1.49348 10.4541 1.59662 10.351 1.71122 10.2593C3.10361 9.15916 5.19506 9.95563 5.49875 11.6975C5.57324 12.1158 5.53313 12.5169 5.40707 12.918C5.40134 12.9524 5.39561 12.9811 5.38415 13.0154C5.33258 13.1071 5.28674 13.2045 5.22944 13.2962C4.71947 14.1385 3.96884 14.5568 2.98328 14.4938C1.85447 14.4136 0.966321 13.5655 0.811611 12.4424C0.737122 11.8981 0.845991 11.3881 1.12676 10.9183C1.18406 10.8151 1.25282 10.7234 1.31585 10.6203C1.3445 10.5974 1.33304 10.5515 1.38461 10.5515Z"
            fill="#34A852"
          />
          <path
            d="M1.38448 10.5515C1.36156 10.5745 1.36156 10.6146 1.32145 10.6203C1.31572 10.5802 1.33864 10.5573 1.36156 10.5286L1.38448 10.5515Z"
            fill="#FABC04"
          />
          <path
            d="M5.38419 13.0154C5.36127 12.9753 5.38419 12.9466 5.40711 12.918C5.41284 12.9237 5.4243 12.9352 5.43003 12.9409L5.38419 13.0154Z"
            fill="#E1C025"
          />
        </svg>
      );
  }
};
