import dayjs from "dayjs";
import { useState } from "react";
import styled, { css, useTheme } from "styled-components";
import { type RequireAllOrNone } from "type-fest";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonPrimaryAsLinkExternal,
  ButtonSecondary,
  ButtonSecondaryAsLink,
  ButtonSecondaryAsLinkExternal
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconBolt,
  IconCheckMark,
  IconCircledInfo,
  IconCircledPause,
  IconCross
} from "elevar-design-system/src/icons";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type EventsConnectorConfigCompletedStep,
  type EventsConnectorConfigMutation
} from "../../api/handlers/website";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import { type Destination } from "./data";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

type ConfigSummaryElementArgs = { isLoading: boolean; initialIsOpen: boolean };

type OverviewProps<T extends Destination> = {
  destination: T;
  config: EventsConnectorConfig[T["configKey"]][number] | null;
  configMutation: EventsConnectorConfigMutation<T>;
  configLabel: string | null;
  completedStep: EventsConnectorConfigCompletedStep<T>;
  configSummaryElement: (args: ConfigSummaryElementArgs) => React.ReactNode;
  description: StepSectionProps["description"];
  integrationNotes?: React.ReactNode;
  whatsNextOverrides?: RequireAllOrNone<
    {
      title: StepSectionProps["description"];
      explainer: StepSectionProps["description"];
      media: StepSectionProps["media"];
      link: { href: string; text?: string };
    },
    "title" | "explainer" | "media"
  >;
};

export const Overview = <T extends Destination>({
  destination,
  config,
  configMutation,
  configLabel,
  completedStep,
  configSummaryElement,
  description,
  integrationNotes,
  whatsNextOverrides
}: OverviewProps<T>): ReturnType<React.FC> => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const [isLoading, setIsLoading] = useState(false);
  const [testCode, setTestCode] = useState("");
  const [isGoLiveModalVisible, setIsGoLiveModalVisible] = useState(false);
  const [isTestModeModalVisible, setIsTestModeModalVisible] = useState(false);
  const [isGoOfflineModalVisible, setIsGoOfflineModalVisible] = useState(false);
  const [isWhatsNextPrimary, setIsWhatsNextPrimary] = useState(false);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  if (config !== null && completedStep === destination.stepCount) {
    return (
      <>
        {config.wentLiveAt === null ? (
          <PageCard>
            <OverviewFinishedHeading>Overview</OverviewFinishedHeading>
            <OverviewFinishedExplainer>
              Please review your configuration before continuing.
            </OverviewFinishedExplainer>
            {configSummaryElement({ isLoading, initialIsOpen: true })}
            <OverviewFinishedGoLiveSectionWrapper>
              <StepSection
                title="Go Live"
                description={
                  destination.name === "Google Ads" ? (
                    <OverviewFinishedExplainer>
                      Once you are ready, go live by pressing the button below.
                      We recommend testing this configuration for at least 2
                      weeks.{" "}
                      <LinkExternal href="https://docs.getelevar.com/docs/how-to-split-test-google-ads-server-side-vs-client-side">
                        How do I do this?
                      </LinkExternal>
                    </OverviewFinishedExplainer>
                  ) : destination.name === "Attentive" ? (
                    <OverviewFinishedExplainer>
                      We recommend running a split test with the new Elevar
                      events via test mode before going live.{" "}
                      <LinkExternal href="https://docs.getelevar.com/docs/how-to-analyze-attentive-server-side-performance-setup-abandoned-flows">
                        How do I set this up?
                      </LinkExternal>
                    </OverviewFinishedExplainer>
                  ) : "testCode" in config || "testMode" in config ? (
                    <OverviewFinishedExplainer>
                      We recommend testing your configuration before going live,
                      but you can continue without testing via test mode if you
                      want to.
                    </OverviewFinishedExplainer>
                  ) : (
                    <OverviewFinishedExplainer>
                      Once you are ready, go live by pressing the button below.
                    </OverviewFinishedExplainer>
                  )
                }
              >
                {"testCode" in config || "testMode" in config ? (
                  <>
                    <OverviewFinishedGoLiveActionsWrapper>
                      <div>
                        <Tooltip
                          text="Please exit test mode first"
                          placement="top"
                          disabled={
                            "testCode" in config
                              ? config.testCode === null
                              : !config.testMode
                          }
                        >
                          <OverviewButtonTooltipInner>
                            <OverviewGoLiveButton
                              variant="SMALL"
                              state={
                                (
                                  "testCode" in config
                                    ? config.testCode !== null
                                    : config.testMode
                                )
                                  ? "DISABLED"
                                  : "IDLE"
                              }
                              onClick={() => {
                                setIsGoLiveModalVisible(true);
                              }}
                            >
                              <div>Go Live</div>
                              <div>
                                <IconBolt size="24px" />
                              </div>
                            </OverviewGoLiveButton>
                          </OverviewButtonTooltipInner>
                        </Tooltip>
                      </div>
                      <div>
                        {(
                          "testCode" in config
                            ? config.testCode !== null
                            : config.testMode
                        ) ? (
                          <ButtonPrimary
                            variant="SMALL"
                            state={isLoading ? "LOADING" : "IDLE"}
                            onClick={async () => {
                              setIsLoading(true);
                              await configMutation(
                                "testCode" in config
                                  ? // @ts-expect-error - can we narrow?
                                    { testCode: null }
                                  : { testMode: false }
                              );
                              setIsLoading(false);
                            }}
                          >
                            Exit Test Mode
                          </ButtonPrimary>
                        ) : (
                          <ButtonSecondary
                            variant="SMALL"
                            onClick={() => {
                              setIsTestModeModalVisible(true);
                            }}
                          >
                            Enter Test Mode
                          </ButtonSecondary>
                        )}
                      </div>
                    </OverviewFinishedGoLiveActionsWrapper>
                    {(
                      "testCode" in config
                        ? config.testCode !== null
                        : config.testMode
                    ) ? (
                      <OverviewFinishedGoLiveTestModeExplainer>
                        If your site is <span>high traffic</span>, events should
                        show instantly. If your site is <span>low traffic</span>
                        , please mimic the events of a visitor to ensure that
                        test events are sent.
                      </OverviewFinishedGoLiveTestModeExplainer>
                    ) : null}
                  </>
                ) : (
                  <OverviewGoLiveButton
                    variant="SMALL"
                    onClick={() => setIsGoLiveModalVisible(true)}
                  >
                    <div>Go Live</div>
                    <div>
                      <IconBolt size="24px" />
                    </div>
                  </OverviewGoLiveButton>
                )}
              </StepSection>
            </OverviewFinishedGoLiveSectionWrapper>
          </PageCard>
        ) : (
          <OverviewFinishedWentLiveWrapper>
            <PageCard>
              <OverviewFinishedWentLiveHeader>
                <div>
                  {config.live ? (
                    <IconCheckMark size="24px" color={theme.palette.green} />
                  ) : (
                    <IconCircledPause size="24px" color={theme.palette.grey5} />
                  )}
                </div>
                <div>{destination.name} Integration</div>
              </OverviewFinishedWentLiveHeader>
              <OverviewFinishedWentLiveExplainer>
                {config.live ? (
                  <div>
                    You've completed the required steps to configure our
                    integration for this destination, and the integration is
                    live.
                  </div>
                ) : (
                  <div>
                    You've completed the required steps to configure our
                    integration for this destination, but the integration is not
                    live.
                  </div>
                )}
              </OverviewFinishedWentLiveExplainer>
              <OverviewFinishedWentLiveStatusSection>
                <div>
                  {("testCode" in config && config.testCode !== null) ||
                  ("testMode" in config && config.testMode) ? (
                    <OverviewFinishedWentLiveTestModeExplainer>
                      If your site is <span>high traffic</span>, events should
                      show instantly. If your site is <span>low traffic</span>,
                      please mimic the events of a visitor to ensure that test
                      events are sent.
                    </OverviewFinishedWentLiveTestModeExplainer>
                  ) : (
                    <OverviewFinishedDates>
                      {config.wentLiveAt ? (
                        <div>
                          Last deployed:{" "}
                          <span>
                            {dayjs(config.wentLiveAt).format("MMM DD YYYY")}
                          </span>
                        </div>
                      ) : null}
                      {config.updatedAt ? (
                        <div>
                          Last updated:{" "}
                          <span>
                            {dayjs(config.updatedAt).format("MMM DD YYYY")}
                          </span>
                        </div>
                      ) : null}
                    </OverviewFinishedDates>
                  )}
                </div>
                <div>
                  <OverviewFinishedWentLiveActionsWrapper>
                    {"testCode" in config || "testMode" in config ? (
                      <div>
                        {(
                          "testCode" in config
                            ? config.testCode !== null
                            : config.testMode
                        ) ? (
                          <ButtonPrimary
                            variant="SMALL"
                            state={isLoading ? "LOADING" : "IDLE"}
                            onClick={async () => {
                              setIsLoading(true);
                              await configMutation(
                                "testCode" in config
                                  ? // @ts-expect-error - can we narrow?
                                    { testCode: null }
                                  : { testMode: false }
                              );
                              setIsLoading(false);
                            }}
                          >
                            Exit Test Mode
                          </ButtonPrimary>
                        ) : config.live ? (
                          <Tooltip
                            text="Please go offline first"
                            placement="top"
                          >
                            <OverviewButtonTooltipInner>
                              <ButtonSecondary variant="SMALL" state="DISABLED">
                                Enter Test Mode
                              </ButtonSecondary>
                            </OverviewButtonTooltipInner>
                          </Tooltip>
                        ) : (
                          <OverviewButtonTooltipInner>
                            <ButtonSecondary
                              variant="SMALL"
                              onClick={() => {
                                setIsTestModeModalVisible(true);
                              }}
                            >
                              Enter Test Mode
                            </ButtonSecondary>
                          </OverviewButtonTooltipInner>
                        )}
                      </div>
                    ) : null}
                    <div>
                      {config.live ? (
                        <OverviewGoOfflineButton
                          variant="SMALL"
                          onClick={() => setIsGoOfflineModalVisible(true)}
                        >
                          <div>Go Offline</div>
                          <div>
                            <IconBolt size="24px" />
                          </div>
                        </OverviewGoOfflineButton>
                      ) : ("testCode" in config && config.testCode !== null) ||
                        ("testMode" in config && config.testMode) ? (
                        <Tooltip
                          text="Please exit test mode first"
                          placement="top"
                        >
                          <OverviewButtonTooltipInner>
                            <OverviewGoLiveButton
                              variant="SMALL"
                              state="DISABLED"
                            >
                              <div>Go Live</div>
                              <div>
                                <IconBolt size="24px" />
                              </div>
                            </OverviewGoLiveButton>
                          </OverviewButtonTooltipInner>
                        </Tooltip>
                      ) : (
                        <OverviewGoLiveButton
                          variant="SMALL"
                          onClick={() => setIsGoLiveModalVisible(true)}
                        >
                          <div>Go Live</div>
                          <div>
                            <IconBolt size="24px" />
                          </div>
                        </OverviewGoLiveButton>
                      )}
                    </div>
                  </OverviewFinishedWentLiveActionsWrapper>
                </div>
              </OverviewFinishedWentLiveStatusSection>
              {configSummaryElement({ isLoading, initialIsOpen: false })}
            </PageCard>
            <PageCard>
              <OverviewWhatsNextContent>
                <StepSection
                  title="What's Next?"
                  description={
                    whatsNextOverrides?.title ? (
                      <OverviewWhatsNextDescription>
                        <div>{whatsNextOverrides.title}</div>
                        <div>{whatsNextOverrides.explainer}</div>
                      </OverviewWhatsNextDescription>
                    ) : (
                      <OverviewWhatsNextDescription>
                        <div>Remove Previous Tracking</div>
                        <div>
                          To avoid duplicate events sent to your destination, we
                          recommend removing your previous tracking.
                        </div>
                      </OverviewWhatsNextDescription>
                    )
                  }
                  media={
                    whatsNextOverrides?.media ?? {
                      type: "VIDEO",
                      url: "https://player.vimeo.com/video/735557862"
                    }
                  }
                >
                  {isWhatsNextPrimary ? (
                    <ButtonPrimaryAsLinkExternal
                      variant="SMALL"
                      onClick={() => setIsWhatsNextPrimary(false)}
                      onAuxClick={event => {
                        if (event.button === 1) setIsWhatsNextPrimary(false);
                      }}
                      href={
                        whatsNextOverrides?.link.href ??
                        "https://docs.getelevar.com/docs/how-to-remove-previous-tracking"
                      }
                    >
                      {whatsNextOverrides?.link.text ??
                        "How to Remove Previous Tracking"}
                    </ButtonPrimaryAsLinkExternal>
                  ) : (
                    <ButtonSecondaryAsLinkExternal
                      variant="SMALL"
                      href={
                        whatsNextOverrides?.link.href ??
                        "https://docs.getelevar.com/docs/how-to-remove-previous-tracking"
                      }
                    >
                      {whatsNextOverrides?.link.text ??
                        "How to Remove Previous Tracking"}
                    </ButtonSecondaryAsLinkExternal>
                  )}
                </StepSection>
              </OverviewWhatsNextContent>
              <OverviewWhatsNextButtonWrapper>
                {isWhatsNextPrimary ? (
                  <ButtonSecondaryAsLink variant="SMALL" to={myTrackingUrl}>
                    Go to My Tracking
                  </ButtonSecondaryAsLink>
                ) : (
                  <ButtonPrimaryAsLink variant="SMALL" to={myTrackingUrl}>
                    Go to My Tracking
                  </ButtonPrimaryAsLink>
                )}
              </OverviewWhatsNextButtonWrapper>
            </PageCard>
          </OverviewFinishedWentLiveWrapper>
        )}
        <Modal
          isVisible={isGoLiveModalVisible}
          onClose={() => setIsGoLiveModalVisible(false)}
          disallowClose={isLoading}
        >
          <OverviewModalContents>
            <OverviewModalTitle>Are you sure?</OverviewModalTitle>
            {destination.name === "Facebook" ? (
              <OverviewModalBody>
                <p>
                  Before continuing, please take and save a screenshot of the
                  Match Quality Score report in Facebook if you haven't done
                  this yet. This will help to analyze the before and after
                  results of Elevar's integration.
                </p>
                <p>
                  Once this configuration is made live, we will start sending
                  all of your configured events to this destination. This will
                  impact your tracking.
                </p>
              </OverviewModalBody>
            ) : destination.name === "Attentive" ? (
              <OverviewModalBody>
                Once this configuration is made live, we will start sending all
                of your configured events to this destination. This will impact
                your live Journeys in Attentive. We recommend testing your
                configuration before going live.
              </OverviewModalBody>
            ) : "testMode" in config || "testCode" in config ? (
              <OverviewModalBody>
                Once this configuration is made live, we will start sending all
                of your configured events to this destination. This will impact
                your tracking. We recommend testing your configuration before
                going live.
              </OverviewModalBody>
            ) : (
              <OverviewModalBody>
                Once this configuration is made live, we will start sending all
                of your configured events to this destination. This will impact
                your tracking.
              </OverviewModalBody>
            )}
            <OverviewModalButtons>
              <ButtonSecondary
                variant="SMALL"
                state={isLoading ? "DISABLED" : "IDLE"}
                onClick={() => setIsGoLiveModalVisible(false)}
              >
                No, Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);
                  if (config.wentLiveAt === null) setIsWhatsNextPrimary(true);
                  // @ts-expect-error - can we narrow?
                  await configMutation({ live: true });
                  setIsLoading(false);
                  setIsGoLiveModalVisible(false);
                  track.destinationGoLive(destination.shorthand);
                }}
              >
                Yes, Go Live
              </ButtonPrimary>
            </OverviewModalButtons>
          </OverviewModalContents>
        </Modal>
        {"testCode" in config ? (
          <Modal
            isVisible={isTestModeModalVisible}
            onClose={() => setIsTestModeModalVisible(false)}
            disallowClose={isLoading}
          >
            <OverviewModalContents>
              <OverviewModalCloseButtonWrapper>
                <OverviewModalCloseButton
                  isVisible={!isLoading}
                  onClick={() => setIsTestModeModalVisible(false)}
                >
                  <IconCross size="16px" />
                </OverviewModalCloseButton>
              </OverviewModalCloseButtonWrapper>
              <OverviewModalTitle>Enter Test Mode</OverviewModalTitle>
              <OverviewTestModeModalBody>
                To enter test mode, insert your {destination.name} test code
                below.{" "}
                {destination.name === "Facebook" ? (
                  <LinkExternal href="https://docs.getelevar.com/docs/how-to-test-facebook-conversion-api-integration-for-shopify-stores">
                    How do I find that?
                  </LinkExternal>
                ) : destination.name === "TikTok" ? (
                  <LinkExternal href="https://docs.getelevar.com/docs/how-to-test-tiktok-events-api-integration-for-shopify-stores">
                    How do I find that?
                  </LinkExternal>
                ) : null}
              </OverviewTestModeModalBody>
              <OverviewTestModeModalInputWrapper>
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={testCode}
                  onChange={event => setTestCode(event.target.value)}
                  placeholder="Test Code"
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </OverviewTestModeModalInputWrapper>
              <OverviewTestModeModalButton
                variant="SMALL"
                state={
                  testCode === "" ? "DISABLED" : isLoading ? "LOADING" : "IDLE"
                }
                onClick={async () => {
                  setIsLoading(true);
                  // @ts-expect-error - can we narrow?
                  await configMutation({ testCode });
                  setIsLoading(false);
                  setIsTestModeModalVisible(false);
                }}
              >
                Enter Test Mode
              </OverviewTestModeModalButton>
            </OverviewModalContents>
          </Modal>
        ) : "testMode" in config ? (
          <Modal
            isVisible={isTestModeModalVisible}
            onClose={() => setIsTestModeModalVisible(false)}
            disallowClose={isLoading}
          >
            <OverviewModalContents>
              <OverviewModalTitle>Enter Test Mode</OverviewModalTitle>
              {destination.name === "Attentive" ? (
                <OverviewModalBody>
                  Test mode sends data to Attentive's Custom Events API and adds
                  "Elevar SS" to the event names. This allows you to send
                  messages to the net new cart/browse abandoners identified by
                  Elevar.
                </OverviewModalBody>
              ) : destination.name === "CJ Affiliate" ? (
                <OverviewModalBody>
                  Test mode sends data to CJ's Test Mode API. This allows you to
                  QA the integration before going live.
                </OverviewModalBody>
              ) : destination.name === "Reddit" ? (
                <OverviewModalBody>
                  Test mode allows you to QA your integration before going live.
                  When in test mode, check your Reddit admin panel to validate
                  the incoming events.{" "}
                  <LinkExternal href="https://docs.getelevar.com/docs/how-to-test-reddit-server-side-integration">
                    How do I do this?
                  </LinkExternal>
                </OverviewModalBody>
              ) : (
                <OverviewModalBody>
                  Test mode adds an additional parameter to your hits that tells
                  the destination these are test hits. This allows you to QA the
                  integration before going live.
                </OverviewModalBody>
              )}
              <OverviewModalButtons>
                <ButtonSecondary
                  variant="SMALL"
                  state={isLoading ? "DISABLED" : "IDLE"}
                  onClick={() => setIsTestModeModalVisible(false)}
                >
                  Go Back
                </ButtonSecondary>
                <ButtonPrimary
                  variant="SMALL"
                  state={isLoading ? "LOADING" : "IDLE"}
                  onClick={async () => {
                    setIsLoading(true);
                    // @ts-expect-error - can we narrow?
                    await configMutation({ testMode: true });
                    setIsLoading(false);
                    setIsTestModeModalVisible(false);
                  }}
                >
                  Enter Test Mode
                </ButtonPrimary>
              </OverviewModalButtons>
            </OverviewModalContents>
          </Modal>
        ) : null}
        <Modal
          isVisible={isGoOfflineModalVisible}
          onClose={() => setIsGoOfflineModalVisible(false)}
          disallowClose={isLoading}
        >
          <OverviewModalContents>
            <OverviewModalTitle>Are you sure?</OverviewModalTitle>
            <OverviewModalBody>
              When this configuration is taken offline, we will stop sending all
              of your configured events to this destination. This will impact
              your tracking.
            </OverviewModalBody>
            <OverviewModalButtons>
              <ButtonSecondary
                variant="SMALL"
                state={isLoading ? "DISABLED" : "IDLE"}
                onClick={() => setIsGoOfflineModalVisible(false)}
              >
                No, Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);
                  // @ts-expect-error - can we narrow?
                  await configMutation({ live: false });
                  setIsLoading(false);
                  setIsGoOfflineModalVisible(false);
                  track.destinationGoOffline(destination.shorthand);
                }}
              >
                Yes, Go Offline
              </ButtonPrimary>
            </OverviewModalButtons>
          </OverviewModalContents>
        </Modal>
      </>
    );
  } else {
    const isStepCompleted = completedStep !== null && completedStep >= 0;

    return (
      <PageCard>
        <StepSection
          title="Overview"
          description={description}
          descriptionSpacing={Boolean(integrationNotes) || !isStepCompleted}
          media={{ type: "NONE", spanContent: true }}
        >
          <OverviewGetStartedWrapper>
            {integrationNotes ? (
              <OverviewIntegrationNotes>
                <div>
                  <div>
                    <IconCircledInfo size="16px" />
                  </div>
                  <div>Integration Notes</div>
                </div>
                <div>{integrationNotes}</div>
              </OverviewIntegrationNotes>
            ) : null}
            {!isStepCompleted ? (
              <ButtonPrimary
                variant="SMALL"
                state={isLoading ? "LOADING" : "IDLE"}
                onClick={async () => {
                  setIsLoading(true);
                  // @ts-expect-error - why is this not allowed?
                  await configMutation({
                    label: configLabel,
                    completedStep: 0
                  });
                  track.destinationGetStarted(destination.shorthand);
                }}
              >
                Get Started
              </ButtonPrimary>
            ) : null}
          </OverviewGetStartedWrapper>
        </StepSection>
      </PageCard>
    );
  }
};

/* ========================================================================== */

const OverviewFinishedHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const OverviewFinishedExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  a {
    ${linkStyles};
  }
`;

const OverviewFinishedGoLiveSectionWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const OverviewFinishedGoLiveActionsWrapper = styled.div`
  display: flex;

  > div:not(:last-child) {
    margin-right: ${props => props.theme.gridBase * 2}px;
  }
`;

const OverviewButtonTooltipInner = styled.div`
  display: inline-block;
`;

const sharedTestModeExplainerStyles = css`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }

  > a {
    ${linkStyles};
  }
`;

const OverviewFinishedGoLiveTestModeExplainer = styled.div`
  ${sharedTestModeExplainerStyles};
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const OverviewGoLiveButton = styled(ButtonPrimary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > div:last-child {
    display: flex;
    margin-left: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const OverviewWhatsNextContent = styled.div`
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey6};
`;

const OverviewWhatsNextDescription = styled.div`
  margin-top: ${props => props.theme.gridBase}px;

  > div:first-child {
    ${normalTextStyles};
    font-weight: 500;
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};
  }
`;

const OverviewWhatsNextButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const OverviewFinishedWentLiveWrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const OverviewFinishedWentLiveHeader = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase}px;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    ${heading3Styles};
  }
`;

const OverviewFinishedWentLiveExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  border-bottom: 1px solid ${props => props.theme.palette.grey6};
  padding-bottom: ${props => props.theme.gridBase * 3}px;
`;

const OverviewFinishedWentLiveStatusSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${props => props.theme.gridBase * 6}px;

  @media screen and (max-width: 1450px) {
    flex-direction: column-reverse;
    gap: ${props => props.theme.gridBase * 3}px;
  }
`;

const OverviewFinishedWentLiveTestModeExplainer = styled.div`
  ${sharedTestModeExplainerStyles};
  max-width: ${props => props.theme.gridBase * 68}px;
`;

const OverviewFinishedDates = styled.div`
  > div {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey3};

    > span {
      color: ${props => props.theme.palette.grey2};
    }

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }
  }
`;

const OverviewFinishedWentLiveActionsWrapper = styled.div`
  display: flex;
  width: max-content;
  gap: ${props => props.theme.gridBase * 2}px;

  @media screen and (max-width: 1450px) {
    flex-direction: row-reverse;
  }
`;

const OverviewGoOfflineButton = styled(ButtonSecondary)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > div:last-child {
    display: flex;
    margin-left: ${props => props.theme.gridBase * 0.75}px;
  }
`;

const OverviewModalContents = styled.div`
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const OverviewModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const OverviewModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  a {
    ${linkStyles};
  }
`;

const OverviewModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

const OverviewModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gridBase * -2.5}px;
  right: ${props => props.theme.gridBase * -2.5}px;
`;

type OverviewModalCloseButtonProps = {
  isVisible: boolean;
};

const OverviewModalCloseButton = styled.button<OverviewModalCloseButtonProps>`
  display: flex;
  border-radius: 2px;
  color: ${props => props.theme.palette.grey3};
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition},
    background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey8};
  }
`;

const OverviewTestModeModalBody = styled(OverviewModalBody)`
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const OverviewTestModeModalInputWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const OverviewTestModeModalButton = styled(ButtonPrimary)`
  width: 100%;
`;

const OverviewGetStartedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${props => props.theme.gridBase * 3}px;
`;

const OverviewIntegrationNotes = styled.div`
  width: 100%;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.gridBase}px;

    > div:first-child {
      display: flex;
      color: ${props => props.theme.palette.orange};
      margin-right: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      font-weight: 500;
    }
  }

  > div:last-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey2};

    p:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }

    span {
      font-weight: 500;
      color: ${props => props.theme.palette.grey1};
    }

    a {
      ${linkStyles};
    }
  }
`;
