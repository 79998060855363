import { PaymentElement } from "@stripe/react-stripe-js";
import { transparentize } from "polished";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconAlertCircle } from "elevar-design-system/src/icons";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type CompanyPaymentMethods,
  useCompanyDetailsMutation,
  useCompanyPaymentMethodsCreateMutation
} from "../../../../api/handlers/company";
import {
  getWhatWouldChange,
  useWebsitePlanChangeMutation,
  websitePlanChangeErrorSchema
} from "../../../../api/handlers/website";
import { ActionWarningModal } from "../../../../components/ActionWarningModal";
import { PageCard } from "../../../../components/PageCard";
import { useOnboardingDetails } from "../../../../context/OnboardingDetails";
import { StripeProvider } from "../../../../context/Stripe";
import { useUserRequired } from "../../../../context/User";
import { useCompanyId, useWebsiteId } from "../../../../utils/idHooks";
import { toast } from "../../../../utils/toast";
import { track } from "../../../../utils/track";
import { PageCardReferral } from "./PageCardReferral";
import {
  type CurrentPlan,
  type Plan,
  type Product,
  trackingManagementTier1AddOn
} from "./shared";

/* ========================================================================== */

type StepCheckoutDetails =
  | {
      type: "STRIPE";
      activePaymentMethod: CompanyPaymentMethods[number] | null;
    }
  | { type: "SHOPIFY" };

type ExpandedCard = "BILLING" | "REFERRAL" | null;

type StepCheckoutProps = {
  currentPlan: CurrentPlan;
  selectedPlan: Plan;
  isAddOnSelected: boolean;
  selectedProduct: Product | null;
  setIsPlanChangeInProgress: (isPlanChangeInProgress: boolean) => void;
  hasReferralInfo: boolean;
  globalHowDidYouHearAboutUs: string;
  setGlobalHowDidYouHearAboutUs: (value: string) => void;
  subscriptionType: WebsiteDetails["subscription_type"];
  websiteName: WebsiteDetails["name"];
  details: StepCheckoutDetails;
};

export const StepCheckout: React.FC<StepCheckoutProps> = props => {
  return (
    <StripeProvider variant="LARGE">
      <StepCheckoutInner {...props} />
    </StripeProvider>
  );
};

/* ========================================================================== */

const StepCheckoutInner: React.FC<StepCheckoutProps> = ({
  currentPlan,
  selectedPlan,
  isAddOnSelected,
  selectedProduct,
  setIsPlanChangeInProgress,
  hasReferralInfo,
  globalHowDidYouHearAboutUs,
  setGlobalHowDidYouHearAboutUs,
  subscriptionType,
  websiteName,
  details
}) => {
  const history = useHistory();
  const user = useUserRequired();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { onboardingState } = useOnboardingDetails();

  const { mutateAsync: companyPaymentMethodsCreateMutation } =
    useCompanyPaymentMethodsCreateMutation();
  const { mutateAsync: companyDetailsMutation } = useCompanyDetailsMutation();
  const { mutateAsync: websitePlanChangeMutation } =
    useWebsitePlanChangeMutation({ subscriptionType });

  const isReferralCardVisible = !hasReferralInfo;

  const isBillingCardVisible =
    details.type === "STRIPE" &&
    (hasReferralInfo || globalHowDidYouHearAboutUs !== "") &&
    !details.activePaymentMethod;

  const [isPlanChangeModalVisible, setIsPlanChangeModalVisible] =
    useState(false);
  const [expandedCard, setExpandedCard] = useState<ExpandedCard>(
    isReferralCardVisible && globalHowDidYouHearAboutUs === ""
      ? "REFERRAL"
      : isBillingCardVisible
        ? "BILLING"
        : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasPaymentInfoBeenReady, setHasPaymentInfoBeenReady] = useState(
    details.type === "SHOPIFY" || details.activePaymentMethod !== null
  );
  const [isPaymentInfoReady, setIsPaymentInfoReady] = useState(
    details.type === "SHOPIFY" || details.activePaymentMethod !== null
  );
  const [error, setError] = useState<string | null>(null);
  const [
    wasBillingCardVisibleBeforeReview,
    setWasBillingCardVisibleBeforeReview
  ] = useState(false);
  const [
    wasReferralCardVisibleBeforeReview,
    setWasReferralCardVisibleBeforeReview
  ] = useState(false);

  const isReviewCardVisible =
    (hasReferralInfo || globalHowDidYouHearAboutUs !== "") &&
    (details.type === "SHOPIFY" ||
      details.activePaymentMethod ||
      hasPaymentInfoBeenReady);

  const selectedAddOn = isAddOnSelected ? trackingManagementTier1AddOn : null;

  useEffect(() => {
    track.managePlanAddPaymentInfo({
      plan: selectedPlan,
      addOn: selectedAddOn,
      product: selectedProduct,
      user
    });
  }, [selectedPlan, selectedAddOn, selectedProduct, user]);

  const prepareForCheckout = () => {
    setError(null);
    setIsLoading(true);
    setIsPlanChangeInProgress(true);
    setWasBillingCardVisibleBeforeReview(isBillingCardVisible);
    setWasReferralCardVisibleBeforeReview(isReferralCardVisible);
  };

  const performCheckout = async () => {
    const recommendedProductName =
      onboardingState.name === "PLAN_SELECTION" && !isAddOnSelected
        ? onboardingState.info.recommendedProduct?.name ?? null
        : null;

    if (details.type === "STRIPE" && !details.activePaymentMethod) {
      try {
        const result = await companyPaymentMethodsCreateMutation({
          setActive: true
        });
        if (result.error?.message) {
          setIsLoading(false);
          toast.errorExpected(result.error.message);
          return;
        }
      } catch (error) {
        setIsLoading(false);
        toast.errorUnexpected(error);
        return;
      }
    }

    if (!hasReferralInfo) {
      await companyDetailsMutation({
        how_did_you_hear_about_us: globalHowDidYouHearAboutUs
      });
    }

    try {
      const result = await websitePlanChangeMutation({
        planId: selectedPlan.id,
        planCode: selectedPlan.code,
        addOnIds: selectedAddOn ? [selectedAddOn.id] : [],
        productId: selectedProduct?.id ?? null
      });

      sessionStorage.removeItem("planCode");

      track.managePlanCheckoutComplete({
        oldPlan: currentPlan.local,
        newPlan: selectedPlan,
        selectedAddOn,
        selectedProduct,
        user,
        websiteId
      });

      if (
        recommendedProductName !== null &&
        selectedProduct?.name !== recommendedProductName
      ) {
        track.managePlanRecommendedProductNotPurchased({
          recommendedProductName,
          purchasedProductName: selectedProduct?.name ?? null
        });
      }

      if ("redirect_url" in result) {
        window.location.replace(result.redirect_url);
      } else {
        toast.success("Checkout successful");

        const websiteUrl = `/company/${companyId}/website/${websiteId}`;

        if (selectedProduct) {
          history.replace(`${websiteUrl}?product_id=${selectedProduct.id}`);
          track.productPurchase({
            productName: selectedProduct.name,
            onboardingState: onboardingState.name
          });
        } else {
          history.replace(websiteUrl);
        }
      }
    } catch (error) {
      const parsedError = websitePlanChangeErrorSchema.safeParse(error);

      if (parsedError.success) {
        setError(parsedError.data.cause.errors.user_message);
      } else {
        toast.errorUnexpected(error);
      }

      setIsLoading(false);
      setIsPlanChangeInProgress(false);
    }
  };

  const canPlanChangeModalShow =
    currentPlan.remote.id !== "DEFAULT_FREE_PLAN" &&
    selectedPlan !== currentPlan.local;

  return (
    <PageCardsWrapper>
      {isReferralCardVisible || wasReferralCardVisibleBeforeReview ? (
        <PageCardReferral
          isExpanded={expandedCard === "REFERRAL"}
          globalHowDidYouHearAboutUs={globalHowDidYouHearAboutUs}
          onSaveButtonClick={params => {
            setGlobalHowDidYouHearAboutUs(params.newHowDidYouHearAboutUs);
            setExpandedCard(
              details.type === "STRIPE" && !details.activePaymentMethod
                ? "BILLING"
                : null
            );
          }}
          onEditButtonClick={() => {
            if (!isLoading) setExpandedCard("REFERRAL");
          }}
        />
      ) : null}
      {(isBillingCardVisible || wasBillingCardVisibleBeforeReview) &&
      details.type === "STRIPE" ? (
        <BillingPageCard>
          <div>Enter Billing Info</div>
          <PaymentElement
            onChange={event => {
              setIsPaymentInfoReady(event.complete);
              if (event.complete) setHasPaymentInfoBeenReady(true);
            }}
          />
        </BillingPageCard>
      ) : null}
      {isReviewCardVisible ? (
        <PageCard>
          {details.type === "SHOPIFY" && selectedProduct ? (
            <ConfirmAndPayShopifyNotice>
              <div>
                <IconAlertCircle size="16px" />
              </div>
              <div>
                Note: Shopify charges monthly and one-time purchases separately.
              </div>
            </ConfirmAndPayShopifyNotice>
          ) : null}
          {details.type === "STRIPE" &&
          details.activePaymentMethod &&
          !(isBillingCardVisible || wasBillingCardVisibleBeforeReview) ? (
            <ConfirmAndPayStripeActivePaymentMethodInfo>
              Note: This will be billed to your active payment method (
              {details.activePaymentMethod.brand} - ****{" "}
              {details.activePaymentMethod.last4} -{" "}
              {String(details.activePaymentMethod.exp_month).padStart(2, "0")}/
              {String(details.activePaymentMethod.exp_year).slice(2, 4)}).
            </ConfirmAndPayStripeActivePaymentMethodInfo>
          ) : null}
          <Tooltip
            text={
              expandedCard === "REFERRAL"
                ? "You must save your referral info above before checking out"
                : "Please ensure that the billing info provided above is valid"
            }
            placement="top"
            disabled={expandedCard !== "REFERRAL" && isPaymentInfoReady}
          >
            <div>
              <ConfirmAndPayButton
                variant="LARGE"
                state={
                  isLoading
                    ? "LOADING"
                    : expandedCard !== "REFERRAL" && isPaymentInfoReady
                      ? "IDLE"
                      : "DISABLED"
                }
                onClick={async () => {
                  prepareForCheckout();

                  if (canPlanChangeModalShow) {
                    const { updates } = await getWhatWouldChange({
                      websiteId,
                      subscriptionType,
                      planId: selectedPlan.id,
                      planCode: selectedPlan.code,
                      addOnIds: isAddOnSelected
                        ? [trackingManagementTier1AddOn.id]
                        : []
                    });

                    if (
                      updates.includes("WILL_UNINSTALL_DATA_LAYER") ||
                      updates.includes("WILL_UNINSTALL_DATA_LAYER_LISTENER")
                    ) {
                      setIsLoading(false);
                      setIsPlanChangeInProgress(false);
                      setIsPlanChangeModalVisible(true);
                    } else {
                      await performCheckout();
                    }
                  } else {
                    await performCheckout();
                  }
                }}
              >
                {error ? "Try Again" : "Confirm & Pay"}
              </ConfirmAndPayButton>
            </div>
          </Tooltip>
          {error ? (
            <ConfirmAndPayError>
              <div>{error}</div>
              <div>
                <div>Need Help?</div>
                <StyledLinkExternal
                  href="https://docs.getelevar.com/page/contact-support"
                  text="Contact Support"
                />
              </div>
            </ConfirmAndPayError>
          ) : null}
        </PageCard>
      ) : null}
      {canPlanChangeModalShow ? (
        <ActionWarningModal
          isVisible={isPlanChangeModalVisible}
          onClose={() => setIsPlanChangeModalVisible(false)}
          isLoading={false}
          subheading={websiteName}
          heading="Are you sure you want to change plan?"
          text="When changing plan, some of your Website's settings will be reset, and you'll be guided through a new set up process. By continuing, you accept the following:"
          checkBoxItems={[
            "My data sources will be reset",
            "All of my connected marketing destinations will be reset",
            "This could harm my data accuracy until set up again"
          ]}
          confirmActionText="Change Plan"
          onConfirmAction={async () => {
            setIsPlanChangeModalVisible(false);
            prepareForCheckout();
            await performCheckout();
          }}
          cancelActionText="Don't Change Plan"
        />
      ) : null}
    </PageCardsWrapper>
  );
};

/* ========================================================================== */

const PageCardsWrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const BillingPageCard = styled(PageCard)`
  display: flex;
  flex-direction: column;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const ConfirmAndPayShopifyNotice = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    color: ${props => props.theme.palette.orange};
  }

  > div:last-child {
    ${normalTextStyles};
  }
`;

const ConfirmAndPayStripeActivePaymentMethodInfo = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ConfirmAndPayButton = styled(ButtonPrimary)`
  width: 100%;
`;

const ConfirmAndPayError = styled.div`
  ${normalBodyStyles};
  margin-bottom: ${props => props.theme.gridBase * -0.5}px;

  > div:first-child {
    color: ${props => props.theme.palette.red1};
    background-color: ${props => transparentize(0.9, props.theme.palette.red1)};
    padding-top: ${props => props.theme.gridBase * 1.5}px;
    padding-bottom: ${props => props.theme.gridBase * 1.5}px;
    padding-left: ${props => props.theme.gridBase * 2}px;
    padding-right: ${props => props.theme.gridBase * 2}px;
    margin-top: ${props => props.theme.gridBase * 2}px;
    margin-bottom: ${props => props.theme.gridBase * 2}px;
    border-radius: 4px;
  }

  > div:last-child {
    display: flex;
    gap: ${props => props.theme.gridBase}px;
    color: ${props => props.theme.palette.grey3};
  }
`;
