import { transparentize } from "polished";
import styled, { useTheme } from "styled-components";

import { IconCircledInfo } from "../icons";
import { Radio } from "../Radio";
import { Tooltip, TooltipBig } from "../Tooltip";
import { normalTextStyles } from "../typography/typography";
import { LabeledRadio } from "./LabeledRadio";

/* ========================================================================== */

type LabeledRadioTextTooltipOptions = {
  maxWidth?: string;
} & ({ text: string } | { render: () => React.ReactNode });

type LabeledRadioTextTagOptions = {
  text: string;
  color: string;
};

type LabeledRadioTextProps = {
  groupName: string;
  isSelected: boolean;
  setIsSelected: () => void;
  text: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  tooltip?: LabeledRadioTextTooltipOptions | null;
  tag?: LabeledRadioTextTagOptions | null;
};

export const LabeledRadioText: React.FC<LabeledRadioTextProps> = ({
  groupName,
  isSelected,
  setIsSelected,
  text,
  isDisabled,
  isFullWidth = false,
  tooltip,
  tag
}) => {
  const theme = useTheme();

  return (
    <LabeledRadioTextWrapper>
      <StyledLabeledRadio
        groupName={groupName}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        isDisabled={isDisabled}
        isFullWidth={isFullWidth}
      >
        {({ isHovered, isFocused }) => (
          <>
            <Radio
              isSelected={isSelected}
              isHovered={isHovered}
              isFocused={isFocused}
            />
            <LabelText>{text}</LabelText>
          </>
        )}
      </StyledLabeledRadio>
      {tooltip ? (
        <>
          {"text" in tooltip && (
            <TooltipIconWrapper>
              <Tooltip
                text={tooltip.text}
                placement="top"
                maxWidth={tooltip.maxWidth}
                offset={theme.gridBase}
                delay={[150, 0]}
              >
                <TooltipInner>
                  <IconCircledInfo size="16px" />
                </TooltipInner>
              </Tooltip>
            </TooltipIconWrapper>
          )}
          {"render" in tooltip && (
            <TooltipIconWrapper>
              <TooltipBig
                render={tooltip.render}
                placement="top"
                maxWidth={tooltip.maxWidth}
                offset={theme.gridBase}
                delay={[150, 0]}
              >
                <TooltipInner>
                  <IconCircledInfo size="16px" />
                </TooltipInner>
              </TooltipBig>
            </TooltipIconWrapper>
          )}
        </>
      ) : null}
      {tag ? <Tag color={tag.color}>{tag.text}</Tag> : null}
    </LabeledRadioTextWrapper>
  );
};

/* ========================================================================== */

const LabeledRadioTextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type StyledLabeledRadioProps = {
  isFullWidth: boolean;
};

const StyledLabeledRadio = styled(LabeledRadio)<StyledLabeledRadioProps>`
  flex: ${props => (props.isFullWidth ? "1" : "inherit")};
  padding-top: ${props => props.theme.gridBase * 1.25}px;
  padding-bottom: ${props => props.theme.gridBase * 1.25}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const LabelText = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-left: ${props => props.theme.gridBase * 1.5}px;
`;

const TooltipIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.gridBase * 0.75}px;
`;

const TooltipInner = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

type TagProps = {
  color: string;
};

const Tag = styled.div<TagProps>`
  ${normalTextStyles};
  color: ${props => props.color};
  background-color: ${props => transparentize(0.9, props.color)};
  padding-top: ${props => props.theme.gridBase * 0.25}px;
  padding-bottom: ${props => props.theme.gridBase * 0.25}px;
  padding-right: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase}px;
  margin-left: ${props => props.theme.gridBase * 1.5}px;
  border-radius: 4px;
  white-space: nowrap;
`;
