import { useId } from "react";
import styled, { useTheme } from "styled-components";

import { type OrderAttributeId } from "elevar-common-ts/src/apiTypes";
import { type NonEmptyArray } from "elevar-common-ts/src/utils";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { PageCard } from "../../components/PageCard";
import { type Destination } from "./data";
import { getTransactionIdentifierDisplayName } from "./shared";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

const getTransactionIdentifierTooltip = (value: OrderAttributeId) => {
  switch (value) {
    case "id":
      return 'Example: "400234805932"';
    case "name":
      return 'Example: "#1234"';
    case "order_number":
      return 'Example: "1234"';
  }
};

/* ========================================================================== */

type TransactionIdentifierProps = {
  isLoading: boolean;
  isStepCompleted: boolean;
  setupGuideHref: StepSectionProps["setupGuideHref"];
  destinationName: Destination["name"];
  media?: StepSectionProps["media"];
  hasRecommendedOption?: boolean;
  options: NonEmptyArray<OrderAttributeId>;
  orderAttributeId: OrderAttributeId;
  setOrderAttributeId: React.Dispatch<React.SetStateAction<OrderAttributeId>>;
  onSave: () => void;
};

export const TransactionIdentifier: React.FC<TransactionIdentifierProps> = ({
  isLoading,
  isStepCompleted,
  setupGuideHref,
  destinationName,
  media = { type: "VIDEO", url: "https://player.vimeo.com/video/669140481" },
  hasRecommendedOption = true,
  options,
  orderAttributeId,
  setOrderAttributeId,
  onSave
}) => {
  const theme = useTheme();
  const radioGroupName = useId();

  return (
    <PageCard>
      <StepSection
        title="What is your Transaction Identifier?"
        setupGuideHref={setupGuideHref}
        description={
          hasRecommendedOption ? (
            <TransactionIdentifierExplainer>
              {getTransactionIdentifierDisplayName(options[0])} is almost always
              used as the primary transaction identifier in {destinationName}{" "}
              integrations, but you can override this if needed.
            </TransactionIdentifierExplainer>
          ) : (
            <TransactionIdentifierExplainer>
              Please choose the primary transaction identifier you'd like to use
              in this {destinationName} integration.
            </TransactionIdentifierExplainer>
          )
        }
        media={media}
      >
        <TransactionIdentifierWrapper>
          <TransactionIdentifierRadioWrapper>
            {options.map((option, index) => (
              <LabeledRadioText
                key={option}
                groupName={radioGroupName}
                isSelected={orderAttributeId === option}
                setIsSelected={() => setOrderAttributeId(option)}
                text={getTransactionIdentifierDisplayName(option)}
                isDisabled={isLoading}
                tooltip={{ text: getTransactionIdentifierTooltip(option) }}
                tag={
                  index === 0 && hasRecommendedOption
                    ? { text: "Recommended", color: theme.palette.green }
                    : null
                }
              />
            ))}
          </TransactionIdentifierRadioWrapper>
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={onSave}
          >
            {isStepCompleted ? "Save" : "Save & Continue"}
          </ButtonPrimary>
        </TransactionIdentifierWrapper>
      </StepSection>
    </PageCard>
  );
};

/* ========================================================================== */

const TransactionIdentifierExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const TransactionIdentifierWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const TransactionIdentifierRadioWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;
