import Confetti from "react-confetti";
import styled from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading1Styles,
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";
import { useWindowSize } from "elevar-design-system/src/useWindowSize";

import { Modal } from "./Modal";

/* ========================================================================== */

type CongratsModalProps = {
  rainConfetti: boolean;
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
};

export const CongratsModal: React.FC<CongratsModalProps> = ({
  rainConfetti,
  isVisible,
  onClose,
  title,
  content
}) => {
  const windowSize = useWindowSize();

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <CongratsModalContents>
        <CongratsModalHeader>
          <div>🎉 🎉 🎉</div>
          <div>{title}</div>
        </CongratsModalHeader>
        <CongratsModalExplainer>{content}</CongratsModalExplainer>
        <CongratsModalButtonWrapper>
          <ButtonPrimary variant="SMALL" onClick={onClose}>
            Continue
          </ButtonPrimary>
        </CongratsModalButtonWrapper>
      </CongratsModalContents>
      {rainConfetti ? (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          recycle={false}
          numberOfPieces={1500}
          gravity={0.05}
        />
      ) : null}
    </Modal>
  );
};

/* ========================================================================== */

const CongratsModalContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.theme.gridBase * 50}px;
  position: relative;
`;

const CongratsModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.gridBase * 2}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${heading1Styles};
  }

  > div:last-child {
    ${heading3Styles};
  }
`;

const CongratsModalExplainer = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;

  > p:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  a {
    ${linkStyles};
  }
`;

const CongratsModalButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
`;
