import styled from "styled-components";

/* ========================================================================== */

export const PageCard = styled.section`
  box-shadow: ${props => props.theme.other.boxShadowCard};
  background-color: ${props => props.theme.palette.white};
  padding: ${props => props.theme.gridBase * 3}px;
  border-radius: 4px;
`;
