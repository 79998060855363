import { sortBy } from "lodash-es";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import {
  type CompanyListCompany,
  type CompanyListWebsite
} from "elevar-common-ts/src/apiTypes";

import { Avatar } from "elevar-design-system/src/Avatar";
import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import {
  ButtonPrimaryAsLink,
  ButtonSecondaryAsLink
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconAlertCircle,
  IconArrowRight,
  IconCircledPlus,
  IconCog,
  IconCross
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tooltip } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type AccountCompanyList,
  useAccountCompanyListQuery
} from "../api/handlers/account";
import { Modal } from "../components/Modal";
import { UserButtonDropdown } from "../components/UserButtonDropdown";
import { RedirectWithoutLastLocation } from "../context/LastLocation";
import { TitleProvider } from "../context/Title";
import { useUserRequired } from "../context/User";
import { type AliasRedirectState } from "./Alias";

/* ========================================================================== */

export const CompaniesAndWebsites: React.FC = () => {
  return (
    <TitleProvider page="Companies & Websites">
      <CompaniesAndWebsitesWrapper>
        <CompaniesAndWebsitesWithData />
      </CompaniesAndWebsitesWrapper>
    </TitleProvider>
  );
};

const CompaniesAndWebsitesWrapper = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.white};
`;

/* ========================================================================== */

const CompaniesAndWebsitesWithData: React.FC = () => {
  const accountCompanyList = useAccountCompanyListQuery();

  if (accountCompanyList.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (accountCompanyList.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  if (accountCompanyList.data.length === 0) {
    return <RedirectWithoutLastLocation to="/company/create?initial" />;
  }

  return (
    <CompaniesAndWebsitesInner accountCompanyList={accountCompanyList.data} />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type CompaniesAndWebsitesInnerProps = {
  accountCompanyList: AccountCompanyList;
};

const CompaniesAndWebsitesInner: React.FC<CompaniesAndWebsitesInnerProps> = ({
  accountCompanyList
}) => {
  const { accountDetails } = useUserRequired();

  const orderedCompanies = sortBy(accountCompanyList, c => c.name);

  return (
    <>
      <LogoWrapper>
        <ElevarLogo />
      </LogoWrapper>
      <UserButtonDropdownWrapper>
        <UserButtonDropdown
          userImage={accountDetails.profile.picture}
          userName={`${accountDetails.first_name} ${accountDetails.last_name}`}
          showAccountSettingsLink={true}
        />
      </UserButtonDropdownWrapper>
      <MainPageContentWrapper>
        <PageHeading>Your Companies and Websites</PageHeading>
        <PageExplainer>
          Associate one or more Websites to your Company. Websites allow you to
          collaborate and share data across your team. A Website is similar to a
          unique Google Analytics property.
        </PageExplainer>
        <CompanyWebsiteListsWrapper>
          {orderedCompanies.map(company => (
            <div key={company.id}>
              <CompanyWebsiteList company={company} />
            </div>
          ))}
        </CompanyWebsiteListsWrapper>
        <AddCompanyLink to="/company/create">
          <div>
            <IconCircledPlus size="24px" />
          </div>
          <div>Add a new Company & Website</div>
        </AddCompanyLink>
      </MainPageContentWrapper>
    </>
  );
};

const LogoWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  left: ${props => props.theme.gridBase * 4}px;
`;

const UserButtonDropdownWrapper = styled.div`
  position: fixed;
  bottom: ${props => props.theme.gridBase * 2.45}px;
  left: ${props => props.theme.gridBase * 3}px;
`;

const MainPageContentWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.gridBase * 62.5}px;
  margin: 0 auto;
  padding-top: ${props => props.theme.gridBase * 4}px;
  padding-bottom: ${props => props.theme.gridBase * 6}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const PageExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  max-width: ${props => props.theme.gridBase * 60}px;
  margin-bottom: ${props => props.theme.gridBase * 5}px;
`;

const CompanyWebsiteListsWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 6}px;
  }
`;

const AddCompanyLink = styled(Link)`
  display: flex;
  align-items: center;
  padding-top: ${props => props.theme.gridBase * 2.5}px;
  padding-bottom: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  margin-top: ${props => props.theme.gridBase * 6}px;
  border-radius: 4px;
  background-color: ${props => props.theme.palette.grey8};
  transition: background-color ${props => props.theme.other.transition};

  > div:first-child {
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.purple2};
  }

  > div:last-child {
    ${normalBodyStyles};
    font-weight: 500;
    margin-left: ${props => props.theme.gridBase * 1.5}px;
    color: ${props => props.theme.palette.grey1};
  }

  &:hover {
    background-color: ${props => props.theme.palette.grey7};
  }
`;

/* ========================================================================== */

type CompanyWebsiteListProps = {
  company: CompanyListCompany;
};

const CompanyWebsiteList: React.FC<CompanyWebsiteListProps> = ({ company }) => {
  const theme = useTheme();
  const location = useLocation<Partial<AliasRedirectState> | undefined>();

  const alias = location.state?.alias;
  const isCompanyAdmin = company.role === "Owner" || company.role === "Admin";

  return (
    <>
      <CompanyHeader>
        <div>
          <Avatar
            size="MEDIUM"
            type="COMPANY"
            imageSrc={company.picture?.file}
          />
          <CompanyName>{company.name}</CompanyName>
        </div>
        {isCompanyAdmin ? (
          <div>
            <Tooltip text="Company Settings" placement="top">
              <CompanyConfigureLink
                to={
                  alias?.type === "COMPANY"
                    ? `/company/${company.id}/${alias.path}`
                    : `/company/${company.id}/settings/info`
                }
              >
                <IconCog size="24px" color={theme.palette.grey3} />
              </CompanyConfigureLink>
            </Tooltip>
          </div>
        ) : null}
      </CompanyHeader>
      {company.websites.length === 0 ? (
        <>
          <NoWebsitesFoundCopy>No Websites Found</NoWebsitesFoundCopy>
          {isCompanyAdmin ? (
            <NoWebsitesFoundButton
              variant="LARGE"
              to={`/company/${company.id}/website/create`}
            >
              <span>
                <div>
                  <IconCircledPlus size="24px" />
                </div>
                <div>Add your first Website</div>
              </span>
            </NoWebsitesFoundButton>
          ) : null}
        </>
      ) : (
        <>
          <WebsiteListWrapper>
            {company.websites.map(website => (
              <div key={website.id}>
                <WebsiteListItem
                  alias={alias}
                  companyId={company.id}
                  isCompanyAdmin={isCompanyAdmin}
                  website={website}
                />
              </div>
            ))}
          </WebsiteListWrapper>
          {isCompanyAdmin ? (
            <AddNewWebsiteButtonWrapper>
              <AddNewWebsiteButton to={`/company/${company.id}/website/create`}>
                <div>
                  <IconCircledPlus size="24px" />
                </div>
                <div>Add New Website</div>
              </AddNewWebsiteButton>
            </AddNewWebsiteButtonWrapper>
          ) : null}
        </>
      )}
    </>
  );
};

const CompanyHeader = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
`;

const CompanyName = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  margin-left: ${props => props.theme.gridBase * 2}px;
`;

const CompanyConfigureLink = styled(Link)`
  ${iconButtonStyles};
`;

const NoWebsitesFoundCopy = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey2};
  padding: ${props => props.theme.gridBase * 3}px 0;
`;

const NoWebsitesFoundButton = styled(ButtonPrimaryAsLink)`
  width: 100%;
  display: block;

  > span {
    display: flex;

    > div {
      display: flex;
      align-items: center;
    }

    > div:first-child {
      margin-right: ${props => props.theme.gridBase * 1.5}px;
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.white};
      font-weight: 500;
    }
  }
`;

const WebsiteListWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 2}px;

  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const IconArrowRightWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  transition: color ${props => props.theme.other.transition};
`;

/* ========================================================================== */

type WebsiteListItemProps = {
  alias: AliasRedirectState["alias"] | undefined;
  companyId: number;
  isCompanyAdmin: boolean;
  website: CompanyListWebsite;
};

const WebsiteListItem: React.FC<WebsiteListItemProps> = ({
  alias,
  companyId,
  isCompanyAdmin,
  website
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const companyUrl = `/company/${companyId}`;
  const billingUrl = `${companyUrl}/settings/billing`;

  const websiteUrl = `${companyUrl}/website/${website.id}`;
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;

  return (
    <>
      <WebsiteListItemWrapper
        to={
          alias?.type === "WEBSITE"
            ? `/company/${companyId}/website/${website.id}/${alias.path}`
            : `/company/${companyId}/website/${website.id}`
        }
        onClick={event => {
          if (website.subscription_status.is_delinquent) {
            event.preventDefault();
            setIsModalVisible(true);
          }
        }}
      >
        <div>
          <WebsiteName>{website.name}</WebsiteName>
          {website.subscription_status.is_delinquent ? (
            <Tooltip text="Payment Failed" placement="top">
              <WebsiteErrorTooltipInner>
                <IconAlertCircle size="16px" />
              </WebsiteErrorTooltipInner>
            </Tooltip>
          ) : website.last_google_refresh_failed ? (
            <Tooltip text="Google Authentication Revoked" placement="top">
              <WebsiteWarningTooltipInner>
                <IconAlertCircle size="16px" />
              </WebsiteWarningTooltipInner>
            </Tooltip>
          ) : website.subscription_status.is_overdue ? (
            <Tooltip text="Payment Failed" placement="top">
              <WebsiteWarningTooltipInner>
                <IconAlertCircle size="16px" />
              </WebsiteWarningTooltipInner>
            </Tooltip>
          ) : null}
        </div>
        <div>
          <IconArrowRightWrapper>
            <IconArrowRight size="24px" />
          </IconArrowRightWrapper>
        </div>
      </WebsiteListItemWrapper>
      {website.subscription_status.is_delinquent ? (
        <Modal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        >
          <ModalContents>
            <ModalCloseButtonWrapper>
              <ModalCloseButton onClick={() => setIsModalVisible(false)}>
                <IconCross size="16px" />
              </ModalCloseButton>
            </ModalCloseButtonWrapper>
            <ModalHeading>Billing Issue</ModalHeading>
            <ModalText>
              The active payment method for this Website has failed multiple
              times, so we've been unable to charge the subscription for it. To
              resolve this issue, please update your billing, or change your
              plan. If you have any questions, please{" "}
              <LinkExternal href="https://docs.getelevar.com/page/contact-support">
                contact support
              </LinkExternal>
              .
            </ModalText>
            {isCompanyAdmin ? (
              <ModalActionsWrapper>
                <BillingLink variant="SMALL" to={billingUrl}>
                  Go to Billing
                </BillingLink>
                <PlanLink variant="SMALL" to={managePlanUrl}>
                  Manage Plan
                </PlanLink>
              </ModalActionsWrapper>
            ) : null}
          </ModalContents>
        </Modal>
      ) : null}
    </>
  );
};

const WebsiteListItemWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.gridBase * 3}px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.palette.grey6};
  transition: box-shadow ${props => props.theme.other.transition};

  > div {
    display: flex;
    align-items: center;
  }

  &:hover {
    box-shadow: 0 2px 6px rgba(33, 43, 54, 0.04);

    ${IconArrowRightWrapper} {
      color: ${props => props.theme.palette.purple2};
    }
  }
`;

const WebsiteName = styled.div`
  ${heading3Styles};
  font-weight: 500;
`;

const WebsiteErrorTooltipInner = styled.div`
  display: flex;
  color: ${props => props.theme.palette.red1};
  margin-left: ${props => props.theme.gridBase}px;
`;

const WebsiteWarningTooltipInner = styled.span`
  display: flex;
  color: ${props => props.theme.palette.orange};
  margin-left: ${props => props.theme.gridBase}px;
`;

const AddNewWebsiteButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 2}px;
  display: flex;
`;

const AddNewWebsiteButton = styled(Link)`
  ${iconTextButtonStyles};
`;

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.theme.gridBase * 40}px;
  position: relative;
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const ModalCloseButton = styled.button`
  display: flex;
  border-radius: 2px;
  padding: ${props => props.theme.gridBase}px;
  background-color: ${props => props.theme.palette.grey8};
  color: ${props => props.theme.palette.grey3};
  transition: background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey6};
  }
`;

const ModalHeading = styled.div`
  ${heading2Styles};
  text-align: center;
  margin-top: ${props => props.theme.gridBase * 0.5}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const ModalText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  text-align: center;
  margin-bottom: ${props => props.theme.gridBase}px;

  > a {
    ${linkStyles};
  }
`;

const ModalActionsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${props => props.theme.gridBase}px;
  padding-top: ${props => props.theme.gridBase * 2.5}px;
`;

const BillingLink = styled(ButtonPrimaryAsLink)`
  width: 100%;
`;

const PlanLink = styled(ButtonSecondaryAsLink)`
  width: 100%;
`;
