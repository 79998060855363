type IconPartnerizeProps = {
  size: "24px";
  className?: string;
};

export const IconPartnerize: React.FC<IconPartnerizeProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12.0007 17.6339C10.4852 17.6332 8.9988 17.2176 7.70269 16.4322C7.55255 16.3588 7.41936 16.2549 7.31165 16.1271C7.20394 15.9993 7.12407 15.8504 7.07714 15.69C7.03022 15.5296 7.01726 15.3612 7.03911 15.1955C7.06096 15.0298 7.11713 14.8705 7.20403 14.7278C7.29093 14.585 7.40666 14.462 7.54381 14.3665C7.68096 14.271 7.83652 14.2051 8.00056 14.1731C8.1646 14.1412 8.33351 14.1438 8.49649 14.1808C8.65946 14.2177 8.81293 14.2883 8.94709 14.388C10.0632 15.0616 11.3705 15.3482 12.666 15.2032C13.9616 15.0583 15.1732 14.4899 16.1129 13.5863C17.0526 12.6827 17.6679 11.4943 17.8634 10.2054C18.059 8.91644 17.8238 7.59902 17.1944 6.45737C16.5649 5.31571 15.5764 4.41362 14.3821 3.89095C13.1878 3.36828 11.8544 3.25424 10.5887 3.56652C9.32302 3.8788 8.19569 4.59995 7.38155 5.61814C6.56741 6.63634 6.11194 7.89469 6.08577 9.1981C6.05595 9.49885 5.91391 9.77732 5.68794 9.97801C5.46197 10.1787 5.16868 10.2869 4.86652 10.281C4.56435 10.2751 4.2755 10.1556 4.05754 9.9462C3.83957 9.73685 3.70849 9.45305 3.69043 9.15137C3.72303 7.51382 4.23842 5.92236 5.17195 4.57657C6.10548 3.23078 7.41559 2.19059 8.93796 1.58646C10.4603 0.982319 12.1272 0.841133 13.7295 1.18061C15.3318 1.52008 16.7982 2.3251 17.9448 3.49469C19.0914 4.66429 19.8671 6.1464 20.1747 7.75513C20.4822 9.36386 20.308 11.0276 19.6737 12.5377C19.0394 14.0477 17.9734 15.3369 16.6093 16.2435C15.2453 17.1501 13.6439 17.6338 12.006 17.6339H12.0007Z"
            fill="#292824"
          />
          <path
            d="M15.3055 9.31694C15.3055 9.97058 15.1117 10.6095 14.7485 11.153C14.3853 11.6965 13.8691 12.12 13.2652 12.3701C12.6613 12.6202 11.9968 12.6856 11.3557 12.5579C10.7146 12.4303 10.1258 12.1155 9.66372 11.6532C9.20161 11.1909 8.88698 10.6019 8.75962 9.96081C8.63227 9.3197 8.6979 8.65521 8.94822 8.05139C9.19854 7.44758 9.62231 6.93156 10.1659 6.56861C10.7095 6.20565 11.3486 6.01207 12.0022 6.01233C12.4361 6.01233 12.8658 6.09782 13.2666 6.2639C13.6675 6.42999 14.0317 6.67343 14.3385 6.98031C14.6452 7.28719 14.8885 7.65149 15.0544 8.05242C15.2204 8.45334 15.3057 8.88303 15.3055 9.31694Z"
            fill="#517EC0"
          />
          <path
            d="M4.88136 23C4.56266 23 4.25701 22.8734 4.03165 22.648C3.80629 22.4227 3.67969 22.117 3.67969 21.7983V12.6122C3.7043 12.3115 3.84115 12.031 4.06304 11.8265C4.28493 11.6221 4.57562 11.5085 4.87736 11.5085C5.17909 11.5085 5.46978 11.6221 5.69167 11.8265C5.91356 12.031 6.05041 12.3115 6.07503 12.6122V21.7983C6.07503 22.117 5.94842 22.4227 5.72306 22.648C5.49771 22.8734 5.19205 23 4.87335 23H4.88136Z"
            fill="#292824"
          />
        </svg>
      );
  }
};
