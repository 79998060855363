import { useId } from "react";

/* ========================================================================== */

type IconClarityProps = {
  size: "24px";
  className?: string;
};

export const IconClarity: React.FC<IconClarityProps> = ({
  size,
  className
}) => {
  const idPrefix = useId();
  const filterId = `${idPrefix}-filter`;
  const maskId = `${idPrefix}-mask`;
  const linearGradient1Id = `${idPrefix}-linearGradient1`;
  const linearGradient2Id = `${idPrefix}-linearGradient2`;
  const linearGradient3Id = `${idPrefix}-linearGradient3`;
  const linearGradient4Id = `${idPrefix}-linearGradient4`;

  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g filter={`url(#${filterId})`}>
            <mask
              id={maskId}
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="1"
              width="24"
              height="22"
            >
              <path
                d="M0.169329 20.6439L10.938 2.11231C11.4125 1.2959 12.5876 1.2959 13.062 2.11231L23.8307 20.6439C24.3088 21.4667 23.7176 22.5 22.7687 22.5H1.2313C0.282424 22.5 -0.308784 21.4667 0.169329 20.6439Z"
                fill="#C4C4C4"
              />
            </mask>
            <g mask="url(#mask0_3090_589)">
              <path
                d="M12.0001 0.284729L17.379 9.54107L4.46977 13.2436L12.0001 0.284729Z"
                fill={`url(#${linearGradient1Id})`}
              />
              <path
                d="M12.0001 0.284729L17.379 9.54107L4.46977 13.2436L12.0001 0.284729Z"
                fill={`url(#${linearGradient2Id})`}
                fillOpacity="0.8"
              />
              <path
                d="M4.46977 13.2436L24.9094 22.5L17.379 9.54108L4.46977 13.2436Z"
                fill={`url(#${linearGradient3Id})`}
              />
              <path
                d="M24.9094 22.5H-0.909668L4.46977 13.2436L24.9094 22.5Z"
                fill={`url(#${linearGradient4Id})`}
              />
            </g>
          </g>
          <defs>
            <filter
              id={filterId}
              x="0"
              y="-2.5"
              width="34"
              height="25"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="10" />
              <feGaussianBlur stdDeviation="5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.215686 0 0 0 0 0.2 0 0 0 0 1 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_3090_589"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-4" />
              <feGaussianBlur stdDeviation="5" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_innerShadow_3090_589"
                result="effect2_innerShadow_3090_589"
              />
            </filter>
            <linearGradient
              id={linearGradient1Id}
              x1="11.2031"
              y1="6.79688"
              x2="22.5937"
              y2="13.2656"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#41A5EE" />
              <stop offset="1" stopColor="#98F2E8" />
            </linearGradient>
            <linearGradient
              id={linearGradient2Id}
              x1="12"
              y1="-3.75"
              x2="12"
              y2="14.625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id={linearGradient3Id}
              x1="11.9998"
              y1="12.318"
              x2="23.0955"
              y2="20.6079"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2B7CD3" />
              <stop offset="1" stopColor="#185ABD" />
            </linearGradient>
            <linearGradient
              id={linearGradient4Id}
              x1="3.17488"
              y1="11.3907"
              x2="24.9636"
              y2="13.71"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#185ABD" />
              <stop offset="1" stopColor="#103F91" />
            </linearGradient>
          </defs>
        </svg>
      );
  }
};
