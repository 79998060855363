type IconLightBulbProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconLightBulb: React.FC<IconLightBulbProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 17.9584C9 17.5724 8.77437 17.2261 8.44208 17.0296C6.38176 15.8113 5 13.567 5 11C5 7.13401 8.13401 4 12 4C15.866 4 19 7.13401 19 11C19 13.567 17.6182 15.8113 15.5579 17.0296C15.2256 17.2261 15 17.5724 15 17.9584V21C15 22.1046 14.1046 23 13 23H11C9.89543 23 9 22.1046 9 21V17.9584ZM18 11C18 13.1991 16.8175 15.123 15.0489 16.1689C14.4567 16.5191 14 17.1701 14 17.9584V18H10V17.9584C10 17.1701 9.5433 16.5191 8.95108 16.1689C7.18248 15.123 6 13.1991 6 11C6 7.68629 8.68629 5 12 5C15.3137 5 18 7.68629 18 11ZM14 19V21C14 21.5523 13.5523 22 13 22H11C10.4477 22 10 21.5523 10 21V19H14Z"
            fill={color}
          />
          <rect x="20" y="11" width="3" height="1" rx="0.5" fill={color} />
          <rect x="1" y="11" width="3" height="1" rx="0.5" fill={color} />
          <rect
            x="3.70703"
            y="3"
            width="3"
            height="1"
            rx="0.5"
            transform="rotate(45 3.70703 3)"
            fill={color}
          />
          <rect
            width="3"
            height="1"
            rx="0.5"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 20.4141 3)"
            fill={color}
          />
          <rect
            x="12.5"
            width="3"
            height="1"
            rx="0.5"
            transform="rotate(90 12.5 0)"
            fill={color}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.2667 3.73333L11.8 4.2M2 8H2.66667H2ZM8 2V2.66667V2ZM13.3333 8H14H13.3333ZM3.73333 3.73333L4.2 4.2L3.73333 3.73333Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.99999 10.6667C5.4403 10.2469 5.02688 9.66167 4.81828 8.99388C4.60968 8.3261 4.61648 7.6096 4.83771 6.9459C5.05895 6.28219 5.4834 5.70492 6.05096 5.29585C6.61851 4.88678 7.30038 4.66666 7.99999 4.66666C8.6996 4.66666 9.38147 4.88678 9.94902 5.29585C10.5166 5.70492 10.941 6.28219 11.1623 6.9459C11.3835 7.6096 11.3903 8.3261 11.1817 8.99388C10.9731 9.66167 10.5597 10.2469 9.99999 10.6667C9.7397 10.9243 9.54372 11.2396 9.4279 11.5871C9.31209 11.9345 9.27969 12.3044 9.33332 12.6667C9.33332 13.0203 9.19285 13.3594 8.9428 13.6095C8.69275 13.8595 8.35361 14 7.99999 14C7.64637 14 7.30723 13.8595 7.05718 13.6095C6.80713 13.3594 6.66666 13.0203 6.66666 12.6667C6.72029 12.3044 6.68789 11.9345 6.57208 11.5871C6.45626 11.2396 6.26028 10.9243 5.99999 10.6667"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.46664 11.3333H9.53331"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
