import { uniq } from "lodash-es";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled, { useTheme } from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { InputFieldSelect } from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldText } from "elevar-design-system/src/inputs/InputFieldText";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  heading3Styles,
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { destinationRtbh as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { MarketGroupSettings } from "./MarketGroupSettings";
import { Overview } from "./Overview";
import { ProductIdentifier } from "./ProductIdentifier";
import { conversionValueOptions } from "./shared";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-setup-elevars-rtb-house-integration";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationRtbhProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationRtbh: React.FC<DestinationRtbhProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "RTB House Settings" },
          { name: "Events" },
          { name: "Consent Mode" },
          { name: "Transaction Identifier" },
          { name: "Product Identifier" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" },
          { name: "Web Container Setup" }
        ]}
        context={{ setSaveModalState, shopifyOAuthScopes }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
    case 6:
      return <Step6 />;
    case 7:
      return <Step7 />;
    case 8:
      return <Step8 />;
  }
};

/* ========================================================================== */

const recommendedEvents: ReturnType<typeof useConfig>["enabledEvents"] = {
  pageView: true,
  viewItemList: true,
  viewItem: true,
  viewSearchResults: true,
  addToCart: true,
  beginCheckout: true,
  viewCart: true,
  purchase: true
};

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "CUSTOM",
                render: () => (
                  <>
                    The RTB House settings are <span>configured</span>
                  </>
                )
              },
              {
                step: 2,
                type: "EVENTS",
                selectedEvents: {
                  enabledEvents: config.enabledEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                },
                recommendedEvents: getRecommendedEventState({
                  destination,
                  enabledEvents: recommendedEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                })
              },
              {
                step: 3,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 4,
                type: "TRANSACTION_IDENTIFIER",
                value: config.dataConfig.orderAttributeId
              },
              {
                step: 5,
                type: "PRODUCT_IDENTIFIER",
                value: config.dataConfig.productAttributeMapping
              },
              {
                step: 6,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 7,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Send meaningful data to {destination.name} so that you can focus on
          making strategic business decisions rather than worrying about your
          data.
        </Step0Explainer>
      }
      integrationNotes={
        <div>
          <p>
            To set up this integration, please contact your RTB House account
            manager to:
          </p>
          <p>
            <div>
              <span>1.</span> Request the "<span>pkey</span>", "
              <span>tagging hash</span>", and the web tag setup for your
              account.
            </div>
            <div>
              <span>2.</span> Ask how to access your <span>RTB UID</span>.
              You'll need to save it in a cookie called "<span>_rtb_e</span>" as
              soon as it's available after page load.
            </div>
          </p>
          <p></p>
          <StyledLinkExternal href={setupGuideHref} text="Learn more" />
        </div>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const theme = useTheme();
  const [inputWrapperRef, { width: inputWrapperWidth }] = useMeasure();
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  const [isLoading, setIsLoading] = useState(false);
  const [pixelId, setPixelId] = useState(config.pixelId);
  const [accessToken, setAccessToken] = useState(config.accessToken);
  const [isAdvancedShown, setIsAdvancedShown] = useState(false);
  const [conversionValue, setConversionValue] = useState(
    config.dataConfig.conversionValue
  );

  const selectedConversionValueOption =
    conversionValueOptions.find(o => o.value === conversionValue) ?? null;

  const areCriteoFieldsFilledIn = pixelId !== "" && accessToken !== "";
  const areCriteoFieldsUnique = uniq([pixelId, accessToken]).length === 2;

  return (
    <Step1Wrapper>
      <PageCard>
        <StepSection
          title="RTB House Settings"
          setupGuideHref={setupGuideHref}
          description={
            <Step1Explainer>
              In order to send your transactions data to this destination, we
              need the following information from your RTB House account.
            </Step1Explainer>
          }
        >
          <Step1SectionInnerWrapper ref={inputWrapperRef}>
            <div>
              <InputWrapper
                labelText="RTB House Pkey"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 38.5}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This is a unique identifier for your RTB house account.
                      Please contact your RTB House account manager to request
                      your "pkey" value.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={pixelId}
                  onChange={event => setPixelId(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
            <div>
              <InputWrapper
                labelText="RTB House Tagging Hash"
                disabled={isLoading}
                tooltip={{
                  maxWidth: `${theme.gridBase * 38.5}px`,
                  render: () => (
                    <Step1InputWrapperTooltipContent>
                      This allows us to connect your RTB House account. Please
                      contact your RTB House account manager to request your
                      "tagging hash" value.
                    </Step1InputWrapperTooltipContent>
                  )
                }}
              >
                <InputFieldText
                  variant="SMALL"
                  disabled={isLoading}
                  value={accessToken}
                  onChange={event => setAccessToken(event.target.value)}
                  spellCheck={false}
                  autoCapitalize="off"
                />
              </InputWrapper>
            </div>
          </Step1SectionInnerWrapper>
        </StepSection>
        <Step1AdvancedOptionsToggleButton
          onClick={() => setIsAdvancedShown(!isAdvancedShown)}
        >
          <div>Advanced Options</div>
          <div>
            {isAdvancedShown ? (
              <IconChevronUp size="16px" />
            ) : (
              <IconChevronDown size="16px" />
            )}
          </div>
        </Step1AdvancedOptionsToggleButton>
        {isAdvancedShown ? (
          <Step1AdvancedOptionsWrapper inputWrapperWidth={inputWrapperWidth}>
            <InputWrapper labelText="Conversion Value" disabled={isLoading}>
              <InputFieldSelect
                variant="SMALL"
                disabled={isLoading}
                value={selectedConversionValueOption}
                setValue={option => setConversionValue(option.value)}
                options={conversionValueOptions}
                placeholder="Select a Conversion Value"
              />
            </InputWrapper>
          </Step1AdvancedOptionsWrapper>
        ) : null}
        <MarketGroupSettings
          config={config}
          destination={destination}
          isLoading={isLoading}
          isStepCompleted={isStepCompleted}
          saveButtonDisabledTooltipOverride={
            !areCriteoFieldsFilledIn
              ? "All fields are required"
              : !areCriteoFieldsUnique
                ? "All fields must be unique"
                : null
          }
          onSave={async data => {
            const action = async () => {
              setIsLoading(true);
              await configMutation({
                ...data,
                pixelId,
                accessToken,
                dataConfig: { conversionValue },
                ...(!isStepCompleted ? { completedStep: 1 } : {})
              });
              if (isStepCompleted) toast.success("Destination updated");
              setIsLoading(false);
            };

            if (config.live) {
              setSaveModalState({ isVisible: true, onConfirm: action });
            } else {
              await action();
            }
          }}
        />
      </PageCard>
    </Step1Wrapper>
  );
};

const Step1Wrapper = styled.div`
  > ${PageCard}:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const Step1SectionInnerWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const Step1InputWrapperTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const Step1AdvancedOptionsToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

type Step1AdvancedOptionsWrapperProps = {
  inputWrapperWidth: number;
};

const Step1AdvancedOptionsWrapper = styled.div<Step1AdvancedOptionsWrapperProps>`
  width: ${props => props.inputWrapperWidth}px;
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, shopifyOAuthScopes }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step2Explainer>
            Select what events you want to send to RTB House.
          </Step2Explainer>
        }
      />
      <EventDestinationTable
        isLoading={isLoading}
        isStepCompleted={isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        details={{
          eventState,
          setEventState,
          destinationName: destination.name
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async () => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...eventState,
              ...(!isStepCompleted ? { completedStep: 2 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }}
      />
    </PageCard>
  );
};

const Step2Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <TransactionIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["id", "order_number", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { orderAttributeId },
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [productAttributeMapping, setProductAttributeMapping] = useState(
    config.dataConfig.productAttributeMapping
  );

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <ProductIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      options={["variant_id", "product_id", "sku"]}
      productAttributeMapping={productAttributeMapping}
      setProductAttributeMapping={setProductAttributeMapping}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { productAttributeMapping },
            ...(!isStepCompleted ? { completedStep: 5 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step6: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 6;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 6 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step7: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 7;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 7 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step8: React.FC = () => {
  const { configMutation, completedStep } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 8;

  return (
    <PageCard>
      <StepSection
        title="Web Container Setup"
        description={
          <Step8Explainer>
            RTB House requires a tag that fires on every page. If you haven't
            already, please contact your RTB House account manager to request
            that your web tag be setup. Note that this needs to be done before
            going live with this integration.
          </Step8Explainer>
        }
        setupGuideHref={setupGuideHref}
        descriptionSpacing={!isStepCompleted}
      >
        {!isStepCompleted ? (
          <ButtonPrimary
            variant="SMALL"
            state={isLoading ? "LOADING" : "IDLE"}
            onClick={async () => {
              setIsLoading(true);
              await configMutation({ completedStep: 8 });
              setIsLoading(false);
            }}
          >
            Mark as Complete
          </ButtonPrimary>
        ) : null}
      </StepSection>
    </PageCard>
  );
};

const Step8Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;
