type IconGridProps = {
  size: "16px";
  color?: string;
  className?: string;
};

export const IconGrid: React.FC<IconGridProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.66667 2H2V6.66667H6.66667V2Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 2H9.33337V6.66667H14V2Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 9.33331H9.33337V14H14V9.33331Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66667 9.33331H2V14H6.66667V9.33331Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
