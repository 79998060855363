type IconEnrichProps = {
  size: "24px";
  color?: string;
  className?: string;
};

export const IconEnrich: React.FC<IconEnrichProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M13 21.8C12.3538 21.9311 11.6849 22 11 22C5.47715 22 1 17.5228 1 12C1 6.47715 5.47715 2 11 2C16.1578 2 20.4035 5.90483 20.9423 10.9195"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.6922 12.8262H7.78261C7.04461 12.8262 6.33685 13.1193 5.81501 13.6412C5.29317 14.163 5 14.8708 5 15.6088V16.5001"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5653 10.5652C12.1021 10.5652 13.3479 9.3194 13.3479 7.78261C13.3479 6.24582 12.1021 5 10.5653 5C9.02853 5 7.78271 6.24582 7.78271 7.78261C7.78271 9.3194 9.02853 10.5652 10.5653 10.5652Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.5 22C20.9853 22 23 19.9853 23 17.5C23 15.0147 20.9853 13 18.5 13C16.0147 13 14 15.0147 14 17.5C14 19.9853 16.0147 22 18.5 22Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.5 15.7V19.3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.7 17.5H20.3"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
