import { css } from "styled-components";

/* ========================================================================== */

export const linkStyles = css`
  font-weight: 500;
  color: ${props => props.theme.palette.purple2};
  transition: color ${props => props.theme.other.transition};

  &:hover {
    color: ${props => props.theme.palette.purple1};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${props => props.theme.palette.purple2};
  }
`;
