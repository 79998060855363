type IconTikTokProps = {
  size: "24px";
  className?: string;
};

export const IconTikTok: React.FC<IconTikTokProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M2.20227 20.538L2.281 20.7603C2.27092 20.7348 2.24211 20.6571 2.20227 20.538ZM5.3178 15.5008C5.45606 14.3065 5.92555 13.6377 6.81028 12.9522C8.07618 12.0238 9.65747 12.549 9.65747 12.549V9.43345C10.0419 9.4232 10.4265 9.44697 10.8067 9.5045V13.5139C10.8067 13.5139 9.2259 12.9887 7.96001 13.9176C7.07575 14.6026 6.6053 15.2718 6.46753 16.4662C6.46321 17.1148 6.57986 17.9625 7.11704 18.6956C6.98422 18.6245 6.84885 18.5435 6.71091 18.4527C5.52759 17.6236 5.31204 16.3798 5.3178 15.5008ZM17.3359 3.67283C16.4651 2.6772 16.1358 1.67198 16.0167 0.96582H17.1122C17.1122 0.96582 16.8938 2.81738 18.4856 4.63821L18.5077 4.6627C18.0787 4.38112 17.6852 4.04876 17.3359 3.67283ZM22.6131 6.49553V10.4243C22.6131 10.4243 21.2152 10.3672 20.1807 10.0926C18.7362 9.70852 17.8078 9.1195 17.8078 9.1195C17.8078 9.1195 17.1664 8.69945 17.1146 8.67017V16.783C17.1146 17.2348 16.996 18.3629 16.6345 19.3038C16.1626 20.5346 15.4344 21.3426 15.3005 21.5077C15.3005 21.5077 14.4148 22.5998 12.8522 23.3353C11.4437 23.9987 10.2071 23.9819 9.83749 23.9987C9.83749 23.9987 7.69982 24.087 5.77625 22.7827C5.3603 22.4952 4.97202 22.1696 4.61645 21.8101L4.62605 21.8173C6.5501 23.1216 8.68729 23.0333 8.68729 23.0333C9.05741 23.0165 10.294 23.0333 11.702 22.3699C13.2631 21.6344 14.1503 20.5423 14.1503 20.5423C14.2828 20.3772 15.0144 19.5693 15.4843 18.3379C15.8449 17.3975 15.9644 16.2689 15.9644 15.8172V7.70526C16.0162 7.73503 16.6571 8.15507 16.6571 8.15507C16.6571 8.15507 17.586 8.74458 19.0305 9.12814C20.0655 9.40273 21.4629 9.45985 21.4629 9.45985V6.38128C21.941 6.49313 22.3486 6.52338 22.6131 6.49553Z"
            fill="#EE1D52"
          />
          <path
            d="M21.4633 6.38128V9.45889C21.4633 9.45889 20.0659 9.40177 19.0309 9.12718C17.5864 8.74313 16.6575 8.15411 16.6575 8.15411C16.6575 8.15411 16.0166 7.73407 15.9648 7.7043V15.8181C15.9648 16.2699 15.8462 17.3985 15.4848 18.3389C15.0129 19.5702 14.2846 20.3781 14.1507 20.5433C14.1507 20.5433 13.2645 21.6354 11.7024 22.3708C10.2944 23.0343 9.05782 23.0175 8.6877 23.0343C8.6877 23.0343 6.55051 23.1226 4.62646 21.8183L4.61686 21.8111C4.41373 21.6059 4.22251 21.3892 4.04416 21.1621C3.43018 20.381 3.05382 19.4574 2.95925 19.1939C2.95908 19.1927 2.95908 19.1916 2.95925 19.1905C2.80707 18.7325 2.48736 17.6327 2.53104 16.5675C2.60833 14.6881 3.242 13.5345 3.40953 13.2455C3.85325 12.4575 4.43035 11.7524 5.11516 11.1616C5.71946 10.6519 6.40442 10.2463 7.14193 9.96151C7.93922 9.62713 8.79296 9.44791 9.6574 9.43345V12.549C9.6574 12.549 8.07612 12.0257 6.8107 12.9522C5.92596 13.6377 5.45647 14.3065 5.31822 15.5008C5.31246 16.3798 5.528 17.6236 6.71037 18.4531C6.8483 18.5443 6.98368 18.6253 7.11649 18.696C7.32305 18.9762 7.57446 19.2203 7.86057 19.4185C9.01558 20.1813 9.98336 20.2346 11.2209 19.7392C12.0461 19.408 12.6673 18.6615 12.9554 17.8344C13.1363 17.3178 13.1339 16.7979 13.1339 16.2603V0.96582H16.0142C16.1333 1.67198 16.4626 2.6772 17.3334 3.67283C17.6828 4.04876 18.0762 4.38112 18.5052 4.6627C18.632 4.79951 19.28 5.4759 20.112 5.89115C20.5421 6.10579 20.9956 6.27025 21.4633 6.38128Z"
            fill="black"
          />
          <path
            d="M1.81287 18.2372V18.2396L1.88391 18.4417C1.87575 18.4182 1.84935 18.3466 1.81287 18.2372Z"
            fill="#69C9D0"
          />
          <path
            d="M7.14197 9.96152C6.40445 10.2463 5.71949 10.6519 5.11519 11.1616C4.43016 11.7537 3.8532 12.4603 3.41004 13.2499C3.24251 13.5379 2.60884 14.6924 2.53155 16.5718C2.48787 17.6371 2.80758 18.7369 2.95976 19.1948C2.95959 19.1959 2.95959 19.1971 2.95976 19.1982C3.05577 19.4593 3.43069 20.383 4.04467 21.1664C4.22302 21.3935 4.41424 21.6102 4.61737 21.8154C3.96652 21.3653 3.38605 20.8211 2.89495 20.2005C2.28624 19.4262 1.91084 18.5122 1.81291 18.2424C1.8128 18.2405 1.8128 18.2386 1.81291 18.2366V18.2333C1.66026 17.7758 1.33958 16.6755 1.38423 15.6088C1.46152 13.7294 2.09518 12.5759 2.26272 12.2869C2.70575 11.4972 3.28272 10.7906 3.96786 10.1987C4.57204 9.68873 5.25703 9.28313 5.99464 8.99854C6.45473 8.80764 6.93402 8.66682 7.42424 8.57849C8.16302 8.44953 8.91756 8.43835 9.65984 8.54537V9.43346C8.79459 9.44762 7.94 9.62685 7.14197 9.96152Z"
            fill="#69C9D0"
          />
          <path
            d="M16.0167 0.965864H13.1364V16.2608C13.1364 16.7984 13.1364 17.3169 12.9579 17.8349C12.6669 18.6615 12.0482 19.408 11.2234 19.7392C9.98537 20.2366 9.01759 20.1814 7.86307 19.4186C7.57646 19.2212 7.32441 18.9779 7.11707 18.6985C8.10069 19.2232 8.98111 19.2141 10.0718 18.7763C10.896 18.445 11.5158 17.6985 11.8057 16.8714C11.9872 16.3549 11.9848 15.835 11.9848 15.2978V0H15.962C15.962 0 15.9174 0.380201 16.0167 0.965864ZM21.4634 5.53019V6.38133C20.9965 6.27012 20.5439 6.10566 20.1145 5.89119C19.2825 5.47595 18.6345 4.79956 18.5077 4.66274C18.6548 4.75929 18.8074 4.84713 18.9647 4.92581C19.9762 5.43082 20.9723 5.58156 21.4634 5.53019Z"
            fill="#69C9D0"
          />
        </svg>
      );
  }
};
