import { transparentize } from "polished";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled, { useTheme } from "styled-components";

import { Avatar, type AvatarType } from "elevar-design-system/src/Avatar";
import { IconUpload } from "elevar-design-system/src/icons";

/* ========================================================================== */

type AvatarPickerProps = {
  type: AvatarType;
  imageSrc?: string;
  imageSelectHandler: (image: File) => void;
  disabled?: boolean;
};

export const AvatarPicker: React.FC<AvatarPickerProps> = ({
  type,
  imageSrc,
  imageSelectHandler,
  disabled = false
}) => {
  const theme = useTheme();
  const onDrop = useCallback(
    (files: Array<File>) => (files[0] ? imageSelectHandler(files[0]) : null),
    [imageSelectHandler]
  );

  const [isHovered, setIsHovered] = useState(false);
  const dropzone = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"]
    },
    multiple: false,
    noDragEventsBubbling: true,
    disabled
  });

  return (
    <AvatarPickerWrapper
      {...dropzone.getRootProps({
        onMouseEnter: () => !disabled && setIsHovered(true),
        onMouseLeave: () => !disabled && setIsHovered(false)
      })}
      isDisabled={disabled}
    >
      <input {...dropzone.getInputProps()} />
      <Avatar size="LARGE" type={type} imageSrc={imageSrc} />
      <Overlay visible={isHovered || dropzone.isDragActive}>
        <IconUpload size="24px" color={theme.palette.white} />
      </Overlay>
    </AvatarPickerWrapper>
  );
};

/* ========================================================================== */

type AvatarPickerWrapperProps = {
  isDisabled: boolean;
};

const AvatarPickerWrapper = styled.div<AvatarPickerWrapperProps>`
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
  display: inline-block;
  position: relative;
  border-radius: 4px;
`;

type OverlayProps = {
  visible: boolean;
};

const Overlay = styled.div<OverlayProps>`
  position: absolute;
  inset: 0;
  border-radius: 4px;
  background-color: ${props => transparentize(0.2, props.theme.palette.grey1)};
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition};
`;
