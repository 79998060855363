type IconTwitterProps = {
  size: "24px" | "16px";
  color?: string;
  className?: string;
};

export const IconTwitter: React.FC<IconTwitterProps> = ({
  size,
  color,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.1881 10.1929L22.7504 0.23999H20.7214L13.2868 8.88199L7.34879 0.23999H0.5L9.47943 13.3082L0.5 23.7454H2.5291L10.3802 14.6192L16.6512 23.7454H23.5L14.1876 10.1929H14.1881ZM11.409 13.4234L10.4992 12.1221L3.26021 1.76747H6.37679L12.2187 10.124L13.1285 11.4253L20.7224 22.2874H17.6058L11.409 13.4239V13.4234Z"
            fill={color ?? "black"}
          />
        </svg>
      );
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M9.45842 6.79533L15.1666 0.160034H13.8139L8.85754 5.92137L4.89887 0.160034H0.333008L6.31929 8.87219L0.333008 15.8303H1.68574L6.91984 9.74615L11.1005 15.8303H15.6663L9.45809 6.79533H9.45842ZM7.60567 8.94895L6.99914 8.08141L2.17315 1.17835H4.25087L8.14549 6.74934L8.75203 7.61688L13.8146 14.8583H11.7369L7.60567 8.94928V8.94895Z"
            fill={color ?? "black"}
          />
        </svg>
      );
  }
};
