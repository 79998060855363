import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { IconKey, IconPaperPlane } from "elevar-design-system/src/icons";
import { InputFieldPassword } from "elevar-design-system/src/inputs/InputFieldPassword";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import {
  useChangePasswordMutation,
  useResetPasswordMutation
} from "../../api/handlers/account";
import { PageCard } from "../../components/PageCard";
import { useUserRequired } from "../../context/User";
import { toast } from "../../utils/toast";

/* ========================================================================== */

export const Password: React.FC = () => {
  const { resetKey } = useParams<{ resetKey?: string }>();

  if (!resetKey) {
    return <ResetKeyAbsent />;
  } else {
    return <ResetKeyPresent resetKey={resetKey} />;
  }
};

/* ========================================================================== */

type ResetKeyAbsentState = "RESET_PASSWORD" | "LOADING" | "EMAIL_SENT";

const ResetKeyAbsent: React.FC = () => {
  const { accountDetails } = useUserRequired();

  const { mutateAsync: resetPasswordMutation } = useResetPasswordMutation();

  const [state, setState] = useState<ResetKeyAbsentState>("RESET_PASSWORD");

  switch (state) {
    case "RESET_PASSWORD":
    case "LOADING": {
      return (
        <PageCard>
          <Heading>
            <HeadingIcon>
              <IconKey size="24px" />
            </HeadingIcon>
            <HeadingHeader>Reset Password</HeadingHeader>
          </Heading>
          <ExplainerText>
            Forgot your password, or just want to change it? Click the button
            below and we'll send you a reset link.
          </ExplainerText>
          <ButtonWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={state === "LOADING" ? "LOADING" : "IDLE"}
              onClick={async () => {
                try {
                  setState("LOADING");
                  await resetPasswordMutation({ email: accountDetails.email });
                  setState("EMAIL_SENT");
                } catch (error) {
                  setState("RESET_PASSWORD");
                  toast.errorUnexpected(error);
                }
              }}
            >
              Send Email
            </ButtonPrimary>
          </ButtonWrapper>
        </PageCard>
      );
    }

    case "EMAIL_SENT": {
      return (
        <PageCard>
          <Heading>
            <HeadingIcon>
              <IconPaperPlane size="24px" />
            </HeadingIcon>
            <HeadingHeader>Password Reset Requested</HeadingHeader>
          </Heading>
          <ExplainerText>
            An email has been sent to <span>{accountDetails.email}</span> with a
            reset link. Please open this link to complete the password reset.
          </ExplainerText>
        </PageCard>
      );
    }
  }
};

const Heading = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const HeadingIcon = styled.div`
  display: flex;
  color: ${props => props.theme.palette.purple2};
  margin-right: ${props => props.theme.gridBase * 2}px;
`;

const HeadingHeader = styled.div`
  ${heading3Styles};
`;

const ExplainerText = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};

  > span {
    color: ${props => props.theme.palette.blue1};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 2}px;
`;

/* ========================================================================== */

type ResetKeyPresentState = "CHANGE_PASSWORD" | "LOADING" | "PASSWORD_UPDATED";

type ResetKeyPresentProps = {
  resetKey: string;
};

const ResetKeyPresent: React.FC<ResetKeyPresentProps> = ({ resetKey }) => {
  const { mutateAsync: changePasswordMutation } = useChangePasswordMutation();

  const [password, setPassword] = useState("");
  const [state, setState] = useState<ResetKeyPresentState>("CHANGE_PASSWORD");

  switch (state) {
    case "CHANGE_PASSWORD":
    case "LOADING": {
      return (
        <PageCard>
          <Heading>
            <HeadingIcon>
              <IconKey size="24px" />
            </HeadingIcon>
            <HeadingHeader>Change Password</HeadingHeader>
          </Heading>
          <InputWrapperNewPassword
            labelText="Enter New Password"
            disabled={state === "LOADING"}
          >
            <InputFieldPassword
              variant="SMALL"
              disabled={state === "LOADING"}
              value={password}
              onChange={event => setPassword(event.target.value)}
              placeholder="Must be at least 6 characters"
              spellCheck={false}
              autoCapitalize="off"
            />
          </InputWrapperNewPassword>
          <ButtonWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={
                state === "LOADING"
                  ? "LOADING"
                  : password.length < 6
                    ? "DISABLED"
                    : "IDLE"
              }
              onClick={async () => {
                try {
                  setState("LOADING");
                  await changePasswordMutation({ resetKey, password });
                  setState("PASSWORD_UPDATED");
                } catch (error) {
                  setState("CHANGE_PASSWORD");
                  toast.errorUnexpected(error);
                }
              }}
            >
              Save New Password
            </ButtonPrimary>
          </ButtonWrapper>
        </PageCard>
      );
    }

    case "PASSWORD_UPDATED": {
      return (
        <PageCard>
          <Heading>
            <HeadingIcon>
              <IconKey size="24px" />
            </HeadingIcon>
            <HeadingHeader>Password Updated</HeadingHeader>
          </Heading>
          <ExplainerText>
            Your password has been updated successfully.
          </ExplainerText>
        </PageCard>
      );
    }
  }
};

const InputWrapperNewPassword = styled(InputWrapper)`
  padding-top: ${props => props.theme.gridBase}px;
`;
