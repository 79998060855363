import { useEffect, useLayoutEffect, useRef } from "react";

/* ========================================================================== */

type EffectHook = typeof useEffect | typeof useLayoutEffect;

const createUpdateEffect: (hook: EffectHook) => EffectHook = hook => {
  return (effect, deps) => {
    const isMounted = useRef(false);

    // for react-refresh
    hook(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    hook(() => {
      if (!isMounted.current) {
        isMounted.current = true;
      } else {
        return effect();
      }
    }, deps);
  };
};

export const useUpdateEffect = createUpdateEffect(useEffect);
export const useUpdateLayoutEffect = createUpdateEffect(useLayoutEffect);
