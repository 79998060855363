import { Route, Switch, useParams } from "react-router-dom";
import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { useWebsiteDetailsQuery } from "../api/handlers/website";
import { RedirectWithoutLastLocation } from "../context/LastLocation";
import { useWebsiteId } from "../utils/idHooks";

/* ========================================================================== */

export const WebsiteRedirect: React.FC = () => {
  const { websiteId } = useParams<{ websiteId?: string }>();

  if (Number.isSafeInteger(Number(websiteId))) {
    return (
      <Switch>
        <Route
          exact={true}
          path="/website/:websiteId/:path*"
          render={() => <WebsiteRedirectInner1 />}
        />
        <RedirectWithoutLastLocation to="/" />
      </Switch>
    );
  } else {
    return <RedirectWithoutLastLocation to="/" />;
  }
};

/* ========================================================================== */

const WebsiteRedirectInner1: React.FC = () => {
  const websiteDetails = useWebsiteDetailsQuery();

  if (websiteDetails.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  return (
    <>
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
      {websiteDetails.data !== undefined ? (
        <WebsiteRedirectInner2 websiteDetails={websiteDetails.data} />
      ) : null}
    </>
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type WebsiteRedirectRouteParams = { path?: string };

type WebsiteRedirectInner2Props = {
  websiteDetails: WebsiteDetails;
};

const WebsiteRedirectInner2: React.FC<WebsiteRedirectInner2Props> = ({
  websiteDetails
}) => {
  const websiteId = useWebsiteId();
  const params = useParams<WebsiteRedirectRouteParams>();

  const companyId = websiteDetails.company;
  const path = `${params.path ?? ""}${location.search}`;

  return (
    <RedirectWithoutLastLocation
      to={`/company/${companyId}/website/${websiteId}/${path}`}
    />
  );
};
