import styled, { css, useTheme } from "styled-components";

import { IconCheckMark } from "./icons";

/* ========================================================================== */

export type CheckBoxVariant = "NORMAL" | "WARNING" | "DISABLED";

type CheckBoxProps = {
  variant?: CheckBoxVariant;
  isChecked: boolean;
  isHovered?: boolean;
  isFocused?: boolean;
};

export const CheckBox: React.FC<CheckBoxProps> = ({
  variant = "NORMAL",
  isChecked,
  isHovered = false,
  isFocused = false
}) => {
  const theme = useTheme();

  return (
    <CheckBoxContainer
      variant={variant}
      isChecked={isChecked}
      isHovered={isHovered}
      isFocused={isFocused}
    >
      <div>
        <IconCheckMark size="16px" color={theme.palette.white} />
      </div>
    </CheckBoxContainer>
  );
};

/* ========================================================================== */

type CheckBoxContainerProps = {
  variant: CheckBoxVariant;
  isChecked: boolean;
  isHovered: boolean;
  isFocused: boolean;
};

const CheckBoxContainer = styled.div<CheckBoxContainerProps>`
  width: ${props => props.theme.gridBase * 2}px;
  height: ${props => props.theme.gridBase * 2}px;
  display: flex;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.isChecked
      ? props.variant === "NORMAL"
        ? props.theme.palette.blue1
        : props.variant === "DISABLED"
          ? props.theme.palette.grey5
          : props.theme.palette.red1
      : props.theme.palette.grey5};
  background-color: ${props =>
    props.isChecked
      ? props.variant === "NORMAL"
        ? props.theme.palette.blue1
        : props.variant === "DISABLED"
          ? props.theme.palette.grey5
          : props.theme.palette.red1
      : props.theme.palette.white};
  transition:
    background-color ${props => props.theme.other.transition},
    border-color ${props => props.theme.other.transition},
    box-shadow ${props => props.theme.other.transition};

  > div {
    display: flex;
    margin-left: -1px;
    margin-top: -1px;
    opacity: ${props => (props.isChecked ? 1 : 0)};
    visibility: ${props => (props.isChecked ? "visible" : "hidden")};
    transition:
      opacity ${props => props.theme.other.transition},
      visibility ${props => props.theme.other.transition};
  }

  ${props =>
    (props.isHovered || props.isFocused) &&
    props.variant !== "DISABLED" &&
    css<CheckBoxContainerProps>`
      border-color: ${props =>
        props.variant === "NORMAL"
          ? props.theme.palette.blue1
          : props.theme.palette.red1};
    `}

  ${props =>
    props.isFocused &&
    props.variant !== "DISABLED" &&
    css<CheckBoxContainerProps>`
      box-shadow: 0 0 0 2px
        ${props =>
          props.variant === "NORMAL"
            ? props.theme.palette.blue4
            : props.theme.palette.red2};
    `}
`;
