type IconOmetriaProps = {
  size: "24px";
  className?: string;
};

export const IconOmetria: React.FC<IconOmetriaProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6.77197 23.2877L7.91855 19.2747C9.29097 19.6395 10.7068 19.8306 12.1661 19.8306C14.7199 19.8306 17.1867 19.2399 19.4191 18.1281L21.3822 21.8371C18.5505 23.253 15.4061 24 12.1661 24C10.3246 24 8.50921 23.7568 6.77197 23.2877Z"
            fill="#FF7070"
          />
          <path
            d="M2.62012 21.6721L4.65269 17.9978C5.68635 18.5364 6.78081 18.962 7.91871 19.266L6.77213 23.2791C5.33021 22.9055 3.94042 22.3583 2.62012 21.6721Z"
            fill="#1E1F4D"
          />
          <path
            d="M12.2445 0C16.6918 0 20.2966 3.50054 20.2966 7.81759C20.2966 12.1346 16.6918 15.6352 12.2445 15.6352C7.79716 15.6352 4.19238 12.1346 4.19238 7.81759C4.19238 3.50054 7.79716 0 12.2445 0ZM12.2445 11.5961C14.39 11.5961 16.1272 9.91096 16.1272 7.82627C16.1272 5.74158 14.39 4.05646 12.2445 4.05646C10.099 4.05646 8.36176 5.74158 8.36176 7.82627C8.36176 9.90228 10.099 11.5961 12.2445 11.5961Z"
            fill="#1E1F4D"
          />
        </svg>
      );
  }
};
