import { useElements, useStripe } from "@stripe/react-stripe-js";
import { type Stripe, type StripeElements } from "@stripe/stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  type AccountDetails,
  type CompanyDetails,
  type CompanyRole,
  type ImageUpload,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import { useCompanyId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import { apiRequest, useExtendedQueryClient } from "../utils";
import { createWebsite } from "./website";

/* ========================================================================== */

type CompanyAndWebsiteIds = {
  companyId: number;
  websiteId: number;
};

const createCompany = async (
  companyName: string,
  websiteName: string,
  accountId: number
): Promise<CompanyAndWebsiteIds> => {
  const companyDetails = await apiRequest<CompanyDetails>({
    endpoint: "/companies",
    method: "POST",
    data: { name: companyName }
  });

  const websiteDetails = await createWebsite(companyDetails.id, {
    name: websiteName,
    memberships: [accountId]
  });

  return {
    companyId: companyDetails.id,
    websiteId: websiteDetails.id
  };
};

type CreateCompanyMutationArgs = {
  companyName: string;
  websiteName: string;
};

export const useCompanyCreateMutation = (accountId: number) => {
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: CreateCompanyMutationArgs) => {
      return createCompany(data.companyName, data.websiteName, accountId);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "details"],
        ["account", "companyList"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

const deleteCompany = (companyId: number) => {
  return apiRequest({
    endpoint: `/companies/${companyId}`,
    method: "DELETE"
  });
};

export const useCompanyDeleteMutation = () => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: () => deleteCompany(companyId),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "details"],
        ["account", "companyList"]
      ]);
    }
  });
};

/* ========================================================================== */

const fetchCompanyDetails = (companyId: number) => {
  return apiRequest<CompanyDetails>({ endpoint: `/companies/${companyId}` });
};

export const useCompanyDetailsQuery = (args?: { retry: false }) => {
  const companyId = useCompanyId();

  return useQuery({
    queryKey: ["companies", companyId, "details"],
    queryFn: () => fetchCompanyDetails(companyId),
    ...(args ? { retry: args.retry } : {})
  });
};

/* -------------------------------------------------------------------------- */

type UpdateCompanyDetails = Partial<
  Pick<
    CompanyDetails,
    | "name"
    | "picture"
    | "how_did_you_hear_about_us"
    | "signup_form_partner_referral"
  >
>;

const updateCompanyDetails = (
  companyId: number,
  data: UpdateCompanyDetails
) => {
  return apiRequest<CompanyDetails, UpdateCompanyDetails>({
    endpoint: `/companies/${companyId}`,
    method: "PATCH",
    data
  });
};

export const useCompanyDetailsMutation = () => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: UpdateCompanyDetails) => {
      return updateCompanyDetails(companyId, data);
    },
    onSuccess: async data => {
      queryClient.base.setQueryData(["companies", companyId, "details"], data);
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "details"],
        ["account", "companyList"]
      ]);
    }
  });
};

/* ========================================================================== */

export type CompanyMember = Pick<
  AccountDetails,
  "id" | "first_name" | "last_name" | "username"
> & {
  role: CompanyRole;
  websites: Array<number>;
  picture?: ImageUpload;
};

const fetchCompanyMembers = (companyId: number) => {
  return apiRequest<Array<CompanyMember>>({
    endpoint: `/companies/${companyId}/members`
  });
};

export const useCompanyMembersQuery = () => {
  const companyId = useCompanyId();

  return useQuery({
    queryKey: ["companies", companyId, "members"],
    queryFn: () => fetchCompanyMembers(companyId)
  });
};

/* -------------------------------------------------------------------------- */

type UpdateCompanyMemberData = Pick<CompanyMember, "role" | "websites">;

const updateCompanyMember = (
  companyId: number,
  memberId: number,
  data: UpdateCompanyMemberData
) => {
  return apiRequest<CompanyMember, UpdateCompanyMemberData>({
    endpoint: `/companies/${companyId}/members/${memberId}`,
    method: "PATCH",
    data
  });
};

type CompanyMemberMutationArgs = {
  memberId: number;
  data: UpdateCompanyMemberData;
};

export const useCompanyMembersUpdateMutation = (accountId: number) => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: ({ memberId, data }: CompanyMemberMutationArgs) => {
      return updateCompanyMember(companyId, memberId, data);
    },
    onSuccess: async (_data, { memberId }) => {
      await queryClient.extensions.invalidateOrRemoveQueriesList(
        memberId === accountId
          ? [
              ["account", "details"],
              ["account", "companyList"],
              ["companies", companyId, "details"],
              ["companies", companyId, "members"]
            ]
          : [["companies", companyId, "members"]]
      );
    }
  });
};

/* -------------------------------------------------------------------------- */

const removeCompanyMember = (companyId: number, memberId: number) => {
  return apiRequest({
    endpoint: `/companies/${companyId}/members/${memberId}`,
    method: "DELETE"
  });
};

export const useCompanyMembersRemoveMutation = (accountId: number) => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (memberId: number) => removeCompanyMember(companyId, memberId),
    onSuccess: async (_data, memberId) => {
      if (memberId === accountId) {
        queryClient.base.removeQueries({ queryKey: ["companies", companyId] });
        await queryClient.extensions.invalidateOrRemoveQueriesList([
          ["account", "details"],
          ["account", "companyList"]
        ]);
      } else {
        await queryClient.extensions.invalidateOrRemoveQueriesList([
          ["companies", companyId, "members"]
        ]);
      }
    }
  });
};

/* ========================================================================== */

const makeCompanyOwner = (companyId: number, memberId: number) => {
  return apiRequest({
    endpoint: `/companies/${companyId}/members/${memberId}/make_owner`,
    method: "POST"
  });
};

export const useCompanyTransferOwnershipMutation = (accountId: number) => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (memberId: number) => makeCompanyOwner(companyId, memberId),
    onSuccess: async (_data, memberId) => {
      await queryClient.extensions.invalidateOrRemoveQueriesList(
        memberId === accountId
          ? [
              ["account", "details"],
              ["account", "companyList"],
              ["companies", companyId, "details"],
              ["companies", companyId, "members"]
            ]
          : [["companies", companyId, "members"]]
      );
    }
  });
};

/* ========================================================================== */

export type CompanyInvite = Pick<AccountDetails, "id" | "email"> & {
  role: CompanyRole;
  websites: Array<number>;
};

const fetchCompanyInvites = (companyId: number) => {
  return apiRequest<Array<CompanyInvite>>({
    endpoint: `/companies/${companyId}/invites`
  });
};

export const useCompanyInvitesQuery = () => {
  const companyId = useCompanyId();

  return useQuery({
    queryKey: ["companies", companyId, "invites"],
    queryFn: () => fetchCompanyInvites(companyId)
  });
};

/* -------------------------------------------------------------------------- */

type CompanyInvitesSendData = Omit<CompanyInvite, "id">;

const sendCompanyInvite = (companyId: number, data: CompanyInvitesSendData) => {
  return apiRequest<CompanyInvite, CompanyInvitesSendData>({
    endpoint: `/companies/${companyId}/invites`,
    method: "POST",
    data
  });
};

export const useCompanyInvitesSendMutation = () => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (data: CompanyInvitesSendData) => {
      return sendCompanyInvite(companyId, data);
    },
    onSuccess: async () => {
      track.companyMemberAdd();
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["companies", companyId, "invites"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

const resendCompanyInvite = (inviteId: number) => {
  return apiRequest<CompanyInvite>({
    endpoint: `/invites/${inviteId}/resend`,
    method: "POST"
  });
};

export const useCompanyInvitesResendMutation = () => {
  return useMutation({
    mutationFn: (inviteId: number) => resendCompanyInvite(inviteId)
  });
};

/* -------------------------------------------------------------------------- */

const cancelCompanyInvite = (inviteId: number) => {
  return apiRequest({
    endpoint: `/invites/${inviteId}`,
    method: "DELETE"
  });
};

export const useCompanyInvitesCancelMutation = () => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (inviteId: number) => cancelCompanyInvite(inviteId),
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["companies", companyId, "invites"]
      ]);
    }
  });
};

/* ========================================================================== */

type PaymentMethod = {
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  is_default: boolean;
};

export type CompanyPaymentMethods = Array<PaymentMethod>;

const fetchCompanyPaymentMethods = (companyId: number) => {
  return apiRequest<CompanyPaymentMethods>({
    endpoint: `/companies/${companyId}/cards`
  });
};

export const useCompanyPaymentMethodsQuery = () => {
  const companyId = useCompanyId();

  return useQuery({
    queryKey: ["companies", companyId, "paymentMethods"],
    queryFn: () => fetchCompanyPaymentMethods(companyId)
  });
};

/* -------------------------------------------------------------------------- */

const updateActivePaymentMethodForQueryCache = (
  paymentMethods: CompanyPaymentMethods,
  paymentMethodId: string
) => {
  return paymentMethods.map(paymentMethod => {
    if (paymentMethod.id === paymentMethodId) {
      return { ...paymentMethod, is_default: true };
    } else {
      return { ...paymentMethod, is_default: false };
    }
  });
};

const setActiveCompanyPaymentMethod = (
  companyId: number,
  paymentMethodId: string
) => {
  return apiRequest({
    endpoint: `/companies/${companyId}/cards/${paymentMethodId}/default`,
    method: "POST"
  });
};

export const useCompanyPaymentMethodsSetActiveMutation = () => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) => {
      return setActiveCompanyPaymentMethod(companyId, paymentMethodId);
    },
    onSuccess: (_data, paymentMethodId) => {
      queryClient.base.setQueryData<CompanyPaymentMethods>(
        ["companies", companyId, "paymentMethods"],
        paymentMethods => {
          if (paymentMethods) {
            return updateActivePaymentMethodForQueryCache(
              paymentMethods,
              paymentMethodId
            );
          }
        }
      );
    }
  });
};

/* -------------------------------------------------------------------------- */

type SetupIntent = { client_secret: string };

const createCompanyPaymentMethod = async (
  stripe: Stripe,
  elements: StripeElements,
  companyId: number,
  setActive: boolean
) => {
  await elements.submit();

  const setupIntent = await apiRequest<SetupIntent>({
    endpoint: `/companies/${companyId}/cards/init_setup`,
    method: "POST"
  });

  const result = await stripe.confirmSetup({
    elements,
    clientSecret: setupIntent.client_secret,
    redirect: "if_required"
  });

  if (result.setupIntent?.payment_method && setActive) {
    await setActiveCompanyPaymentMethod(
      companyId,
      typeof result.setupIntent.payment_method === "string"
        ? result.setupIntent.payment_method
        : result.setupIntent.payment_method.id
    );
  }

  return result;
};

type CreatePaymentMethodMutationArgs = {
  setActive: boolean;
};

export const useCompanyPaymentMethodsCreateMutation = () => {
  const stripe = useStripe()!;
  const elements = useElements()!;
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: ({ setActive }: CreatePaymentMethodMutationArgs) => {
      return createCompanyPaymentMethod(stripe, elements, companyId, setActive);
    },
    onSuccess: async () => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["companies", companyId, "paymentMethods"]
      ]);
    }
  });
};

/* -------------------------------------------------------------------------- */

const deleteCompanyPaymentMethod = (
  companyId: number,
  paymentMethodId: string
) => {
  return apiRequest({
    endpoint: `/companies/${companyId}/cards/${paymentMethodId}`,
    method: "DELETE"
  });
};

export const useCompanyPaymentMethodsDeleteMutation = () => {
  const companyId = useCompanyId();
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) => {
      return deleteCompanyPaymentMethod(companyId, paymentMethodId);
    },
    onSuccess: (_data, paymentMethodId) => {
      queryClient.base.setQueryData<CompanyPaymentMethods>(
        ["companies", companyId, "paymentMethods"],
        paymentMethods => {
          if (paymentMethods) {
            return paymentMethods.filter(paymentMethod => {
              if (paymentMethod.id === paymentMethodId) {
                return false;
              } else {
                return true;
              }
            });
          }
        }
      );
    }
  });
};

/* ========================================================================== */

type PaymentHistoryItem = {
  id: string;
  created: number;
  amount: number | null;
  paid: boolean;
  invoice_url: string | null;
  receipt_url: string | null;
  source: PaymentMethod | null;
};

export type CompanyPaymentHistory = Array<PaymentHistoryItem>;

const fetchCompanyPaymentHistory = (companyId: number) => {
  return apiRequest<CompanyPaymentHistory>({
    endpoint: `/companies/${companyId}/charges`
  });
};

export const useCompanyPaymentHistoryQuery = () => {
  const companyId = useCompanyId();

  return useQuery({
    queryKey: ["companies", companyId, "paymentHistory"],
    queryFn: () => fetchCompanyPaymentHistory(companyId)
  });
};

/* ========================================================================== */

export type CompanyWebsites = Array<WebsiteDetails>;

const fetchCompanyWebsites = (companyId: number) => {
  return apiRequest<CompanyWebsites>({
    endpoint: `/companies/${companyId}/websites`
  });
};

export const useCompanyWebsitesQuery = () => {
  const companyId = useCompanyId();

  return useQuery({
    queryKey: ["companies", companyId, "websites"],
    queryFn: () => fetchCompanyWebsites(companyId)
  });
};
