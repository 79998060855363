import { hideVisually, transparentize } from "polished";
import { useState } from "react";
import styled, { css } from "styled-components";

/* ========================================================================== */

type ChildrenProps = { isHovered: boolean; isFocused: boolean };

type LabeledRadioProps = {
  groupName: string;
  isSelected: boolean;
  setIsSelected: () => void;
  children: (childrenProps: ChildrenProps) => React.ReactNode;
  isDisabled?: boolean;
  className?: string;
};

export const LabeledRadio: React.FC<LabeledRadioProps> = ({
  groupName,
  isSelected,
  setIsSelected,
  children,
  isDisabled = false,
  className
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <LabelWrapper
      className={className}
      isHovered={isHovered}
      isDisabled={isDisabled}
      onMouseEnter={() => !isDisabled && setIsHovered(true)}
      onMouseLeave={() => !isDisabled && setIsHovered(false)}
      onFocus={() => !isDisabled && setIsFocused(true)}
      onBlur={() => !isDisabled && setIsFocused(false)}
      onMouseDown={event => event.preventDefault()}
      onClick={event => {
        if (!isDisabled && event.currentTarget === event.target) {
          event.preventDefault();
          event.currentTarget.control?.focus();
          event.currentTarget.control?.click();
        }
      }}
    >
      <HiddenInput
        type="radio"
        name={groupName}
        checked={isSelected}
        onChange={() => setIsSelected()}
        onMouseDown={event => event.preventDefault()}
        disabled={isDisabled}
      />
      {children({ isHovered, isFocused })}
    </LabelWrapper>
  );
};

/* ========================================================================== */

type LabelWrapperProps = {
  isHovered: boolean;
  isDisabled: boolean;
};

const LabelWrapper = styled.label<LabelWrapperProps>`
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color ${props => props.theme.other.transition};
  background-color: ${props =>
    props.isHovered ? props.theme.palette.grey8 : props.theme.palette.white};
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 2;
    background-color: ${props =>
      transparentize(0.4, props.theme.palette.white)};
    visibility: ${props => (props.isDisabled ? "visible" : "hidden")};
    opacity: ${props => (props.isDisabled ? 1 : 0)};
    transition:
      opacity ${props => props.theme.other.transition},
      visibility ${props => props.theme.other.transition};
  }

  ${props =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
    `};
`;

const HiddenInput = styled.input`
  ${hideVisually()};
`;
