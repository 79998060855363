type IconPandectesProps = {
  size: "24px";
  className?: string;
};

export const IconPandectes: React.FC<IconPandectesProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M0.583216 14.8718H0V10.9733H0.583216V11.2481C0.813047 11.0183 1.07398 10.9033 1.36602 10.9033C1.71336 10.9033 1.99935 11.0312 2.224 11.287C2.4521 11.541 2.56615 11.8616 2.56615 12.2486C2.56615 12.6271 2.45296 12.9424 2.22659 13.1947C2.00194 13.4453 1.71854 13.5706 1.37639 13.5706C1.08089 13.5706 0.816503 13.4522 0.583216 13.2155V14.8718ZM1.96998 12.2512C1.96998 12.0093 1.90431 11.8123 1.77298 11.6602C1.63992 11.5064 1.4723 11.4295 1.27012 11.4295C1.05584 11.4295 0.882169 11.5038 0.749109 11.6525C0.616049 11.8011 0.549519 11.9963 0.549519 12.2383C0.549519 12.475 0.616049 12.6703 0.749109 12.8241C0.880441 12.9744 1.05325 13.0496 1.26752 13.0496C1.46971 13.0496 1.63646 12.9736 1.76779 12.8215C1.90258 12.6694 1.96998 12.4793 1.96998 12.2512Z"
            fill="#3D4E60"
          />
          <path
            d="M5.04417 10.9733H5.62998V13.498H5.04417V13.2336C4.80397 13.4583 4.54563 13.5706 4.26914 13.5706C3.92008 13.5706 3.63149 13.4444 3.40339 13.1921C3.17702 12.9347 3.06383 12.6133 3.06383 12.2279C3.06383 11.8495 3.17702 11.5341 3.40339 11.2818C3.62977 11.0295 3.91317 10.9033 4.25359 10.9033C4.54736 10.9033 4.81089 11.0243 5.04417 11.2662V10.9733ZM3.66001 12.2279C3.66001 12.4698 3.72481 12.6668 3.85441 12.8189C3.98747 12.9727 4.15509 13.0496 4.35727 13.0496C4.57328 13.0496 4.74781 12.9753 4.88087 12.8267C5.01393 12.6729 5.08046 12.4776 5.08046 12.2409C5.08046 12.0041 5.01393 11.8088 4.88087 11.655C4.74781 11.5047 4.57501 11.4295 4.36246 11.4295C4.162 11.4295 3.99438 11.5056 3.8596 11.6576C3.72654 11.8114 3.66001 12.0015 3.66001 12.2279Z"
            fill="#3D4E60"
          />
          <path
            d="M6.36613 10.9733H6.95194V11.2066C7.15585 11.0044 7.38568 10.9033 7.64143 10.9033C7.9352 10.9033 8.16416 10.9958 8.32833 11.1807C8.47003 11.338 8.54088 11.5946 8.54088 11.9505V13.498H7.95507V12.0879C7.95507 11.8391 7.92051 11.6671 7.85139 11.5721C7.78399 11.4753 7.6613 11.4269 7.48331 11.4269C7.28977 11.4269 7.15239 11.4909 7.07117 11.6188C6.99168 11.7449 6.95194 11.9652 6.95194 12.2797V13.498H6.36613V10.9733Z"
            fill="#3D4E60"
          />
          <path
            d="M11.1407 9.12H11.7265V13.498H11.1407V13.2336C10.9109 13.4583 10.6508 13.5706 10.3605 13.5706C10.0149 13.5706 9.72805 13.4444 9.49995 13.1921C9.27357 12.9347 9.16039 12.6133 9.16039 12.2279C9.16039 11.8512 9.27357 11.5367 9.49995 11.2844C9.72459 11.0304 10.0071 10.9033 10.3476 10.9033C10.6431 10.9033 10.9074 11.0243 11.1407 11.2662V9.12ZM9.75656 12.2279C9.75656 12.4698 9.82136 12.6668 9.95097 12.8189C10.084 12.9727 10.2516 13.0496 10.4538 13.0496C10.6698 13.0496 10.8444 12.9753 10.9774 12.8267C11.1105 12.6729 11.177 12.4776 11.177 12.2409C11.177 12.0041 11.1105 11.8088 10.9774 11.655C10.8444 11.5047 10.6716 11.4295 10.459 11.4295C10.2586 11.4295 10.0909 11.5056 9.95615 11.6576C9.82309 11.8114 9.75656 12.0015 9.75656 12.2279Z"
            fill="#3D4E60"
          />
          <path
            d="M14.7619 12.3731H12.9526C12.9681 12.5804 13.0355 12.7454 13.1548 12.8681C13.274 12.9891 13.4269 13.0496 13.6136 13.0496C13.7587 13.0496 13.8788 13.015 13.9739 12.9459C14.0672 12.8768 14.1735 12.7489 14.2927 12.5623L14.7852 12.837C14.7091 12.9666 14.6288 13.0781 14.5441 13.1714C14.4594 13.263 14.3687 13.339 14.272 13.3995C14.1752 13.4583 14.0706 13.5015 13.9583 13.5291C13.846 13.5568 13.7242 13.5706 13.5928 13.5706C13.2161 13.5706 12.9137 13.4496 12.6856 13.2077C12.4575 12.964 12.3434 12.6409 12.3434 12.2383C12.3434 11.8391 12.454 11.5159 12.6752 11.2688C12.8982 11.0252 13.1936 10.9033 13.5617 10.9033C13.9333 10.9033 14.227 11.0217 14.443 11.2585C14.6573 11.4935 14.7644 11.8192 14.7644 12.2357L14.7619 12.3731ZM14.1631 11.8961C14.0819 11.5851 13.8857 11.4295 13.5747 11.4295C13.5038 11.4295 13.4373 11.4408 13.3751 11.4632C13.3129 11.484 13.2559 11.5151 13.204 11.5566C13.1539 11.5963 13.1107 11.6447 13.0744 11.7017C13.0381 11.7587 13.0105 11.8235 12.9915 11.8961H14.1631Z"
            fill="#3D4E60"
          />
          <path
            d="M17.3669 11.0822V11.8572C17.2338 11.6948 17.1146 11.5833 17.0092 11.5229C16.9055 11.4606 16.7837 11.4295 16.6437 11.4295C16.4242 11.4295 16.2419 11.5064 16.0968 11.6602C15.9516 11.814 15.879 12.0067 15.879 12.2383C15.879 12.475 15.949 12.6694 16.089 12.8215C16.2307 12.9736 16.4113 13.0496 16.6307 13.0496C16.7707 13.0496 16.8943 13.0193 17.0014 12.9589C17.1051 12.9001 17.2269 12.7861 17.3669 12.6167V13.3866C17.1301 13.5092 16.8934 13.5706 16.6567 13.5706C16.2661 13.5706 15.9395 13.4444 15.6769 13.1921C15.4142 12.9381 15.2829 12.6228 15.2829 12.246C15.2829 11.8693 15.4159 11.5514 15.682 11.2922C15.9482 11.033 16.2748 10.9033 16.6618 10.9033C16.9107 10.9033 17.1457 10.963 17.3669 11.0822Z"
            fill="#3D4E60"
          />
          <path
            d="M18.5333 11.5177V13.498H17.9501V11.5177H17.7013V10.9733H17.9501V10.048H18.5333V10.9733H18.9869V11.5177H18.5333Z"
            fill="#3D4E60"
          />
          <path
            d="M21.7293 12.3731H19.9201C19.9356 12.5804 20.003 12.7454 20.1223 12.8681C20.2415 12.9891 20.3944 13.0496 20.5811 13.0496C20.7262 13.0496 20.8463 13.015 20.9414 12.9459C21.0347 12.8768 21.1409 12.7489 21.2602 12.5623L21.7527 12.837C21.6766 12.9666 21.5963 13.0781 21.5116 13.1714C21.4269 13.263 21.3362 13.339 21.2394 13.3995C21.1427 13.4583 21.0381 13.5015 20.9258 13.5291C20.8135 13.5568 20.6917 13.5706 20.5603 13.5706C20.1836 13.5706 19.8812 13.4496 19.6531 13.2077C19.425 12.964 19.3109 12.6409 19.3109 12.2383C19.3109 11.8391 19.4215 11.5159 19.6427 11.2688C19.8656 11.0252 20.1611 10.9033 20.5292 10.9033C20.9007 10.9033 21.1945 11.0217 21.4105 11.2585C21.6248 11.4935 21.7319 11.8192 21.7319 12.2357L21.7293 12.3731ZM21.1306 11.8961C21.0494 11.5851 20.8532 11.4295 20.5422 11.4295C20.4713 11.4295 20.4048 11.4408 20.3426 11.4632C20.2804 11.484 20.2234 11.5151 20.1715 11.5566C20.1214 11.5963 20.0782 11.6447 20.0419 11.7017C20.0056 11.7587 19.978 11.8235 19.959 11.8961H21.1306Z"
            fill="#3D4E60"
          />
          <path
            d="M23.8367 11.4036L23.3546 11.6602C23.2785 11.5047 23.1844 11.4269 23.072 11.4269C23.0185 11.4269 22.9727 11.4451 22.9347 11.4814C22.8966 11.5159 22.8776 11.5609 22.8776 11.6162C22.8776 11.7129 22.99 11.8088 23.2146 11.9039C23.5239 12.0369 23.7322 12.1596 23.8393 12.272C23.9464 12.3843 24 12.5355 24 12.7256C24 12.9692 23.9101 13.1731 23.7304 13.3373C23.5559 13.4928 23.3451 13.5706 23.098 13.5706C22.6746 13.5706 22.3748 13.3641 22.1985 12.9511L22.6962 12.7204C22.7653 12.8414 22.818 12.9183 22.8543 12.9511C22.9252 13.0168 23.0098 13.0496 23.1083 13.0496C23.3053 13.0496 23.4038 12.9597 23.4038 12.78C23.4038 12.6763 23.3278 12.5796 23.1757 12.4897C23.117 12.4603 23.0582 12.4318 22.9995 12.4042C22.9407 12.3765 22.8811 12.348 22.8206 12.3186C22.6513 12.2357 22.532 12.1527 22.4629 12.0698C22.3748 11.9644 22.3307 11.8287 22.3307 11.6628C22.3307 11.4434 22.4059 11.2619 22.5562 11.1185C22.71 10.9751 22.8966 10.9033 23.1161 10.9033C23.4392 10.9033 23.6794 11.0701 23.8367 11.4036Z"
            fill="#3D4E60"
          />
        </svg>
      );
  }
};
