import { uniq } from "lodash-es";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import {
  ButtonPrimary,
  ButtonPrimaryAsLink,
  ButtonSecondary,
  ButtonSecondaryAsLinkExternal
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCircledPlus,
  IconCross,
  IconExternalLink,
  IconLock
} from "elevar-design-system/src/icons";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputFieldTextArea } from "elevar-design-system/src/inputs/InputFieldTextArea";
import { scrollbarMixin } from "elevar-design-system/src/scrollbar";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { submitDestinationSuggestion } from "../../api/handlers/website";
import { Drawer } from "../../components/Drawer";
import { InputFieldSearch } from "../../components/InputFieldSearch";
import { Modal } from "../../components/Modal";
import { useMyTrackingDetails } from "../../context/MyTrackingDetails";
import { useServerSideDetails } from "../../context/ServerSideDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { toast } from "../../utils/toast";

/* ========================================================================== */

export type AddDestinationButtonItem = {
  name: string;
  shorthand: string;
  icon: React.ReactNode;
  isBeta: boolean;
  state: "AVAILABLE" | "LOCKED_LIMIT_REACHED" | "LOCKED_PERMISSION_NEEDED";
  category: string;
  keywords: Array<string>;
  isPopular: boolean;
};

type AddDestinationButtonSort = "CATEGORY" | "A_TO_Z" | "Z_TO_A" | "NEWEST";

const sortOptions: Array<Option<AddDestinationButtonSort>> = [
  { name: "By Category", value: "CATEGORY" },
  { name: "A to Z", value: "A_TO_Z" },
  { name: "Z to A", value: "Z_TO_A" },
  { name: "Recently added", value: "NEWEST" }
];

type AddDestinationGroupItem = {
  title?: string;
  items: Array<AddDestinationButtonItem>;
};

const getGroupedDestinations = (
  sort: AddDestinationButtonSort,
  destinations: Array<AddDestinationButtonItem>
): Array<AddDestinationGroupItem> => {
  switch (sort) {
    case "CATEGORY":
      return [
        { title: "Popular", items: destinations.filter(d => d.isPopular) },
        ...uniq(destinations.map(d => d.category)).map(category => ({
          title: category,
          items: destinations.filter(d => d.category === category)
        }))
      ];
    case "A_TO_Z":
      return [
        { items: destinations.toSorted((a, b) => a.name.localeCompare(b.name)) }
      ];
    case "Z_TO_A":
      return [
        { items: destinations.toSorted((a, b) => b.name.localeCompare(a.name)) }
      ];
    case "NEWEST":
      return [{ items: destinations.toReversed() }];
  }
};

/* ========================================================================== */

type AddDestinationButtonProps = {
  type: "PRIMARY" | "SECONDARY";
  getDestinationUrl: (name: string) => string;
  destinations: Array<AddDestinationButtonItem>;
};

export const AddDestinationButton: React.FC<AddDestinationButtonProps> = ({
  type,
  getDestinationUrl,
  destinations
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { isFullyManaged } = useServerSideDetails();
  const { sourceInfo, canAddDestinations } = useMyTrackingDetails();

  const [query, setQuery] = useState("");
  const [sort, setSort] = useState<AddDestinationButtonSort>("CATEGORY");
  const [suggestion, setSuggestion] = useState("");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const normalizedQuery = query.toLowerCase().trim();
  const normalizedSort: AddDestinationButtonSort = query ? "A_TO_Z" : sort;

  const groups = useMemo(
    () =>
      getGroupedDestinations(
        normalizedSort,
        destinations.filter(destination =>
          [destination.name, ...destination.keywords].some(item =>
            item.toLowerCase().includes(normalizedQuery)
          )
        )
      ),
    [normalizedSort, destinations, normalizedQuery]
  );

  const isUpgradeRequired =
    sourceInfo.shopify?.state === "UPGRADE_REQUIRED" ||
    sourceInfo.customPages?.state === "UPGRADE_REQUIRED";

  const isPlanChangeRequired = !canAddDestinations;

  const isDisabled =
    !isFullyManaged || isUpgradeRequired || isPlanChangeRequired;

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const managePlanUrl = `${websiteUrl}/settings/plan/manage`;
  const preBuiltTagsUrl = `${websiteUrl}/pre-built-tags`;

  const TypeButton = type === "PRIMARY" ? ButtonPrimary : ButtonSecondary;
  const activeSortOption = sortOptions.find(o => o.value === sort)!;

  return (
    <>
      <TooltipBig
        placement="bottom-end"
        disabled={!isDisabled}
        maxWidth={`${theme.gridBase * 34}px`}
        render={() =>
          !isFullyManaged ? (
            <AddDestinationButtonTooltipContent>
              <p>You cannot add new destinations</p>
            </AddDestinationButtonTooltipContent>
          ) : isUpgradeRequired ? (
            <AddDestinationButtonTooltipContent>
              <p>Source upgrade(s) required</p>
            </AddDestinationButtonTooltipContent>
          ) : isPlanChangeRequired ? (
            <AddDestinationButtonTooltipContent>
              <p>
                Upgrade to our latest plan options to add additional
                destinations.
              </p>
              <ButtonPrimaryAsLink variant="SMALL" to={managePlanUrl}>
                Explore Plans
              </ButtonPrimaryAsLink>
            </AddDestinationButtonTooltipContent>
          ) : null
        }
      >
        <TypeButtonWrapper>
          <TypeButton
            variant="SMALL"
            state={isDisabled ? "DISABLED" : "IDLE"}
            onClick={() => setIsDrawerVisible(true)}
          >
            <IconCircledPlus size="16px" />
            <div>Add Destination</div>
          </TypeButton>
        </TypeButtonWrapper>
      </TooltipBig>
      <Drawer
        isVisible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
      >
        <DrawerInner>
          <div>
            <DrawerTitle>Add Destination</DrawerTitle>
            <DrawerFilterWrapper>
              <div>
                <InputFieldSearch
                  variant="SMALL"
                  value={query}
                  onChange={event => setQuery(event.target.value)}
                  placeholder="Destination"
                  canClear={query !== ""}
                  onClear={() => setQuery("")}
                  autoFocus={true}
                />
              </div>
              {query === "" ? (
                <div>
                  <InputFieldSelect
                    variant="SMALL"
                    value={activeSortOption}
                    setValue={option => setSort(option.value)}
                    options={sortOptions}
                    placeholder=""
                  />
                </div>
              ) : null}
            </DrawerFilterWrapper>
            {groups.some(group => group.items.length > 0) ? (
              <DrawerDestinationList>
                {groups
                  .filter(group => group.items.length > 0)
                  .map(group => (
                    <DrawerDestinationListGroup key={group.title}>
                      {group.title ? <div>{group.title}</div> : null}
                      <div>
                        {group.items.map(item => {
                          const isDisabled = item.state !== "AVAILABLE";

                          return (
                            <TooltipBig
                              key={item.shorthand}
                              placement="top"
                              disabled={!isDisabled}
                              maxWidth={`${theme.gridBase * 34}px`}
                              render={() => (
                                <AddDestinationButtonTooltipContent>
                                  {item.state === "LOCKED_LIMIT_REACHED" ? (
                                    <p>You've added all allowed instances</p>
                                  ) : (
                                    <>
                                      <p>
                                        Upgrade to our latest plan options to
                                        add additional destinations.
                                      </p>
                                      <ButtonPrimaryAsLink
                                        variant="SMALL"
                                        to={managePlanUrl}
                                      >
                                        Explore Plans
                                      </ButtonPrimaryAsLink>
                                    </>
                                  )}
                                </AddDestinationButtonTooltipContent>
                              )}
                            >
                              <DrawerDestinationListItem
                                to={
                                  isDisabled
                                    ? "#"
                                    : `${getDestinationUrl(item.shorthand)}/new`
                                }
                                tabIndex={isDisabled ? -1 : undefined}
                                isDisabled={isDisabled}
                              >
                                <div>
                                  <div>{item.icon}</div>
                                  <div>{item.name}</div>
                                </div>
                                <div>
                                  {item.isBeta ? (
                                    <DrawerDestinationListItemStatus>
                                      Beta
                                    </DrawerDestinationListItemStatus>
                                  ) : null}
                                  {item.state === "LOCKED_PERMISSION_NEEDED" ? (
                                    <DrawerDestinationListItemLocked>
                                      <IconLock size="16px" />
                                    </DrawerDestinationListItemLocked>
                                  ) : null}
                                </div>
                              </DrawerDestinationListItem>
                            </TooltipBig>
                          );
                        })}
                      </div>
                    </DrawerDestinationListGroup>
                  ))}
              </DrawerDestinationList>
            ) : (
              <DrawerDestinationListEmpty>
                <div>Oops, no destinations match your search.</div>
                <ButtonSecondary
                  variant="SMALL"
                  onClick={() => setIsModalVisible(true)}
                >
                  Suggest New Destination
                </ButtonSecondary>
              </DrawerDestinationListEmpty>
            )}
          </div>
          <div>
            <DrawerPreBuiltTagsWrapper>
              <DrawerPreBuiltTagsExplainer>
                Don't see your marketing destination? Explore more in our
                Pre-Built Tag Library. We're in the process of migrating all
                destinations to here.
              </DrawerPreBuiltTagsExplainer>
              <DrawerPreBuiltTagsLink variant="SMALL" href={preBuiltTagsUrl}>
                <div>View Pre-Built Tags Library</div>
                <IconExternalLink size="16px" />
              </DrawerPreBuiltTagsLink>
            </DrawerPreBuiltTagsWrapper>
          </div>
        </DrawerInner>
      </Drawer>
      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        disallowClose={isModalLoading}
      >
        <ModalContents>
          <ModalCloseButtonWrapper>
            <ModalCloseButton
              isVisible={!isModalLoading}
              onClick={() => setIsModalVisible(false)}
            >
              <IconCross size="16px" />
            </ModalCloseButton>
          </ModalCloseButtonWrapper>
          <ModalHeading>Suggest New Destination</ModalHeading>
          <ModalInputWrapper>
            <InputFieldTextArea
              variant="SMALL"
              disabled={isModalLoading}
              value={suggestion}
              onChange={event => setSuggestion(event.target.value)}
              placeholder="What destination(s) are you looking for?"
              spellCheck={false}
              autoCapitalize="off"
            />
          </ModalInputWrapper>
          <ModalButtonWrapper>
            <ButtonPrimary
              variant="SMALL"
              state={
                isModalLoading
                  ? "LOADING"
                  : suggestion === ""
                    ? "DISABLED"
                    : "IDLE"
              }
              onClick={async () => {
                setIsModalLoading(true);

                try {
                  await submitDestinationSuggestion({ websiteId, suggestion });
                  setIsModalVisible(false);
                  setTimeout(() => {
                    setSuggestion("");
                    setIsModalLoading(false);
                  }, 150);
                  toast.success("Submission successful");
                } catch (error) {
                  setIsModalLoading(false);
                  toast.errorUnexpected(error);
                }
              }}
            >
              Submit Suggestion
            </ButtonPrimary>
          </ModalButtonWrapper>
        </ModalContents>
      </Modal>
    </>
  );
};

/* ========================================================================== */

const AddDestinationButtonTooltipContent = styled.div`
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 2.5}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;

  > p {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }
  }

  > a {
    width: 100%;
  }
`;

const TypeButtonWrapper = styled.div`
  > button {
    align-items: center;
    gap: ${props => props.theme.gridBase}px;
    padding-right: ${props => props.theme.gridBase * 2.5}px;
  }
`;

const DrawerInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    flex-direction: column;
  }
`;

const DrawerTitle = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const DrawerFilterWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;

  > div:first-child:last-child {
    width: 100%;
  }

  > div:not(:first-child) {
    width: ${props => props.theme.gridBase * 27.5}px;
  }
`;

const DrawerDestinationList = styled.div`
  ${scrollbarMixin};
  overflow: auto;
  min-height: ${props => props.theme.gridBase * 18}px;

  // Accounts for drawer heading + footer
  max-height: calc(100vh - ${props => props.theme.gridBase * 49}px);

  // Spaces scrollbar away from items
  margin-right: ${props => props.theme.gridBase * -1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;

  // Ensures keyboard focus states are fully visible
  margin-left: ${props => props.theme.gridBase * -1.5}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  margin-top: ${props => props.theme.gridBase * -0.25}px;
  padding-top: ${props => props.theme.gridBase * 0.25}px;
  margin-bottom: ${props => props.theme.gridBase * -0.25}px;
  padding-bottom: ${props => props.theme.gridBase * 0.25}px;
`;

const DrawerDestinationListEmpty = styled.div`
  margin-top: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => props.theme.gridBase}px;

  > div:first-child {
    ${normalBodyStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase * 1.5}px;
  }
`;

const DrawerDestinationListGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }

  > div:not(:last-child) {
    ${subheadingStyles};
    color: ${props => props.theme.palette.grey3};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
  }
`;

type DrawerDestinationListItemProps = {
  isDisabled: boolean;
};

const DrawerDestinationListItem = styled(Link)<DrawerDestinationListItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${props => props.theme.gridBase}px;
  padding-bottom: ${props => props.theme.gridBase}px;
  padding-left: ${props => props.theme.gridBase * 1.5}px;
  padding-right: ${props => props.theme.gridBase * 1.5}px;
  width: 100%;
  border-radius: 2px;
  user-select: none;
  transition: background-color ${props => props.theme.other.transition};

  ${props =>
    props.isDisabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          &:hover {
            background-color: ${props => props.theme.palette.grey8};
          }
        `}

  > div:first-child {
    display: flex;
    gap: ${props => props.theme.gridBase * 1.5}px;
    opacity: ${props => (props.isDisabled ? 0.5 : 1)};

    > div:first-child {
      display: flex;
      color: ${props => props.theme.palette.purple2};
    }

    > div:last-child {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase}px;
  }
`;

const DrawerDestinationListItemStatus = styled.div`
  ${smallTextStyles};
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: max-content;
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase}px;
  color: ${props => props.theme.palette.white};
  background-color: ${props => props.theme.palette.grey5};
  margin-left: ${props => props.theme.gridBase * 2}px;
`;

const DrawerDestinationListItemLocked = styled.div`
  color: ${props => props.theme.palette.orange};
`;

const DrawerPreBuiltTagsWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 2}px;
  border-top: 1px solid ${props => props.theme.palette.grey6};
  padding-top: ${props => props.theme.gridBase * 3}px;
`;

const DrawerPreBuiltTagsExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const DrawerPreBuiltTagsLink = styled(ButtonSecondaryAsLinkExternal)`
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
`;

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gridBase * -2.5}px;
  right: ${props => props.theme.gridBase * -2.5}px;
`;

type ModalCloseButtonProps = {
  isVisible: boolean;
};

const ModalCloseButton = styled.button<ModalCloseButtonProps>`
  display: flex;
  border-radius: 2px;
  color: ${props => props.theme.palette.grey3};
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition},
    background-color ${props => props.theme.other.transition};

  &:hover {
    background-color: ${props => props.theme.palette.grey8};
  }
`;

const ModalHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalInputWrapper = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const ModalButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
`;
