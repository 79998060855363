import { transparentize } from "polished";
import { Fragment, useState } from "react";
import styled, { css, useTheme } from "styled-components";

import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonWhite
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  IconCheckMark,
  IconCircledInfo,
  IconCross,
  IconPlus,
  IconPointIn,
  IconPointOut
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading1Styles,
  heading3Styles,
  largeTextStyles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { Banner } from "../../../../components/Banner";
import { Modal } from "../../../../components/Modal";
import { PageCard } from "../../../../components/PageCard";
import { useOnboardingDetails } from "../../../../context/OnboardingDetails";
import { useTrialDetails } from "../../../../context/TrialDetails";
import { track } from "../../../../utils/track";
import { PlanBanner } from "./PlanBanner";
import {
  type CurrentPlan,
  type Plan,
  type PlanDetailsItemValueNonNumber,
  plans,
  starterPlan
} from "./shared";

/* ========================================================================== */

type DisablePlanSelectInfo = {
  tooltip: { maxWidth: number; content: () => JSX.Element };
  getDisabled: (plan: Plan) => boolean;
} | null;

type PlanSelectorProps = {
  specialPlan: Plan | null;
  currentPlan: CurrentPlan;
  selectedPlan: Plan | null;
  setSelectedPlan: (plan: Plan | null) => void;
  setIsAddOnSelected: (isAddOnSelected: boolean) => void;
  goToNextStep: (plan: Plan) => void;
  marketsEnabled: boolean;
};

export const PlanSelector: React.FC<PlanSelectorProps> = ({
  specialPlan,
  currentPlan,
  selectedPlan,
  setSelectedPlan,
  setIsAddOnSelected,
  goToNextStep,
  marketsEnabled
}) => {
  const theme = useTheme();
  const { trialState, formattedTrialEndDate } = useTrialDetails();
  const { onboardingState } = useOnboardingDetails();

  const [comparePlans, setComparePlans] = useState(false);
  const [planToCheck, setPlanToCheck] = useState<Plan | null>(null);

  const isSpecialPlanShown = specialPlan !== null;

  const disablePlanSelectInfo: DisablePlanSelectInfo = marketsEnabled
    ? {
        tooltip: {
          maxWidth: theme.gridBase * 43.5,
          content: () => (
            <DisablePlanSelectTooltipContent>
              You cannot select this plan while allowing different pixels per
              Shopify Market, as this plan does not support this. To select this
              plan, stop allowing different pixels per Shopify Market.
            </DisablePlanSelectTooltipContent>
          )
        },
        getDisabled: (plan: Plan) => {
          return (
            plan.features["Shopify Markets"].type === "BOOLEAN" &&
            !plan.features["Shopify Markets"].value
          );
        }
      }
    : null;

  const selectPlan = (plan: Plan | null) => {
    setSelectedPlan(plan);
    if (plan?.id === specialPlan?.id) setIsAddOnSelected(false);

    if (plan) {
      track.managePlanPlanSelected({
        plan,
        onboardingState: onboardingState.name
      });
    }
  };

  return (
    <>
      {trialState === "IN_PROGRESS" ? (
        <TrialBannerWrapper>
          <Banner
            textColor={theme.palette.blue1}
            backgroundColor={theme.palette.white}
            borderColor={theme.palette.blue1}
            primaryText="Free Trial"
            secondaryText={`Your trial ends on ${formattedTrialEndDate}.`}
          />
        </TrialBannerWrapper>
      ) : null}
      <PlanSelectorHeader>
        <div>Select Plan</div>
        {trialState === "ELIGIBLE" ? (
          <div>
            Track <span>unlimited orders</span> during your 15-day free trial
          </div>
        ) : trialState === "IN_PROGRESS" ? (
          <div>
            Track <span>unlimited orders</span> during your free trial
          </div>
        ) : null}
      </PlanSelectorHeader>
      {currentPlan.remote.id !== "DEFAULT_FREE_PLAN" && !currentPlan.local ? (
        <NonRegularCurrentPlanBanner>
          <div>
            <div>
              <div>
                {currentPlan.remote.name} - ${currentPlan.remote.amount / 100}
              </div>
              {currentPlan.remote.public_description !== "" ? (
                <div>{currentPlan.remote.public_description}</div>
              ) : null}
            </div>
            <div>Current Plan</div>
          </div>
        </NonRegularCurrentPlanBanner>
      ) : null}
      {specialPlan ? (
        <PlanBanner
          title={`Custom Plan: ${specialPlan.name}`}
          description={specialPlan.description}
          buttonDisabled={disablePlanSelectInfo?.getDisabled(specialPlan)}
          buttonDisabledTooltip={disablePlanSelectInfo?.tooltip}
          buttonOnClick={() => {
            selectPlan(specialPlan);
            goToNextStep(specialPlan);
          }}
        />
      ) : null}
      {comparePlans ? (
        <PlanSelectorDetailView
          currentPlan={currentPlan}
          selectedPlan={selectedPlan}
          selectPlan={plan => {
            return isSpecialPlanShown && plan
              ? setPlanToCheck(plan)
              : selectPlan(plan);
          }}
          setComparePlans={setComparePlans}
          disablePlanSelectInfo={disablePlanSelectInfo}
        />
      ) : (
        <PlanSelectorSummaryView
          currentPlan={currentPlan}
          selectedPlan={selectedPlan}
          selectPlan={plan => {
            return isSpecialPlanShown && plan
              ? setPlanToCheck(plan)
              : selectPlan(plan);
          }}
          setComparePlans={setComparePlans}
          disablePlanSelectInfo={disablePlanSelectInfo}
        />
      )}
      {isSpecialPlanShown ? (
        <Modal
          isVisible={planToCheck !== null}
          onClose={() => setPlanToCheck(null)}
        >
          <SpecialPlanModalContents>
            <SpecialPlanModalTitle>Are you sure?</SpecialPlanModalTitle>
            <SpecialPlanModalBody>
              You are trying to select a plan that is not the custom plan that
              was made available to you via the link our team sent. If you want
              to select the custom plan, you must press the "Continue with
              Custom Plan" button in the banner at the top of the page.
            </SpecialPlanModalBody>
            <SpecialPlanModalButtons>
              <ButtonSecondary
                variant="SMALL"
                onClick={() => setPlanToCheck(null)}
              >
                No, Go Back
              </ButtonSecondary>
              <ButtonPrimary
                variant="SMALL"
                onClick={() => {
                  selectPlan(planToCheck);
                  setPlanToCheck(null);
                }}
              >
                Yes, Select Plan
              </ButtonPrimary>
            </SpecialPlanModalButtons>
          </SpecialPlanModalContents>
        </Modal>
      ) : null}
    </>
  );
};

const DisablePlanSelectTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const TrialBannerWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PlanSelectorHeader = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${heading1Styles};
  }

  > div:not(:first-child) {
    ${largeTextStyles};
    margin-top: ${props => props.theme.gridBase}px;

    > span {
      font-weight: 600;
    }
  }
`;

const NonRegularCurrentPlanBanner = styled(PageCard)`
  padding: 0;
  background-color: ${props => props.theme.palette.white};
  margin-bottom: ${props => props.theme.gridBase * 4}px;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${props => props.theme.gridBase * 3}px;
    background-color: ${props =>
      transparentize(0.95, props.theme.palette.orange)};

    > div:first-child {
      > div:first-child {
        ${heading3Styles};
      }

      > div:not(:first-child) {
        ${normalBodyStyles};
        color: ${props => props.theme.palette.grey3};
        margin-top: ${props => props.theme.gridBase}px;
        max-width: ${props => props.theme.gridBase * 70}px;
      }
    }

    > div:last-child {
      ${smallTextStyles};
      border-radius: 4px;
      padding-top: ${props => props.theme.gridBase * 0.5}px;
      padding-bottom: ${props => props.theme.gridBase * 0.5}px;
      padding-right: ${props => props.theme.gridBase}px;
      padding-left: ${props => props.theme.gridBase}px;
      color: ${props => props.theme.palette.white};
      background-color: ${props => props.theme.palette.orange};
    }
  }
`;

const SpecialPlanModalContents = styled.div`
  width: ${props => props.theme.gridBase * 44}px;
  position: relative;
`;

const SpecialPlanModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const SpecialPlanModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const SpecialPlanModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

type PlanSelectorViewProps = {
  currentPlan: CurrentPlan;
  selectedPlan: Plan | null;
  selectPlan: (plan: Plan | null) => void;
  setComparePlans: (comparePlans: boolean) => void;
  disablePlanSelectInfo: DisablePlanSelectInfo;
};

const PlanSelectorSummaryView: React.FC<PlanSelectorViewProps> = ({
  currentPlan,
  selectedPlan,
  selectPlan,
  setComparePlans,
  disablePlanSelectInfo
}) => {
  const theme = useTheme();

  const filteredPlans = plans.filter(p => p !== starterPlan);

  return (
    <SummaryViewWrapper planCount={filteredPlans.length}>
      <div>
        {filteredPlans.map(plan => {
          const usage = plan.usage["Orders Included/Month"].value;
          const usageLong = usage.toLocaleString("en");

          return (
            <SummaryViewPlanCard
              key={plan.id}
              isSelected={selectedPlan?.id === plan.id}
            >
              <SummaryViewPlanCardInner>
                <SummaryViewPlanHeader>
                  <div>{plan.name}</div>
                  <div>
                    <div>${plan.dollarPrice}</div>
                    <div>/month</div>
                  </div>
                </SummaryViewPlanHeader>
                <SummaryViewPlanInfo>
                  <div>
                    <div>
                      <IconCheckMark size="16px" color={theme.palette.green} />
                    </div>
                    <div>
                      <span>{usageLong}</span> orders/mo included
                    </div>
                    <div>
                      <TooltipBig
                        placement="top"
                        maxWidth={`${theme.gridBase * 35.5}px`}
                        render={() => (
                          <SummaryViewPlanInfoTooltipContent>
                            This is how many orders are included in your plan.
                            There'll be a flat fee for every extra order over
                            this amount.
                          </SummaryViewPlanInfoTooltipContent>
                        )}
                      >
                        <SummaryViewPlanInfoTooltipTrigger>
                          <IconCircledInfo size="16px" />
                        </SummaryViewPlanInfoTooltipTrigger>
                      </TooltipBig>
                    </div>
                  </div>
                  <div>
                    <div>
                      <IconPlus size="16px" color={theme.palette.blue1} />
                    </div>
                    <div>
                      ${plan.usage["Extra Order Fee"].value} per every extra
                      order
                    </div>
                  </div>
                  <div>
                    <div>
                      <IconCheckMark size="16px" color={theme.palette.green} />
                    </div>
                    <div>{plan.supportTier} Support</div>
                    <div>
                      <TooltipBig
                        placement="top"
                        maxWidth={`${theme.gridBase * 44.25}px`}
                        render={() => {
                          switch (plan.supportTier) {
                            case "Standard":
                              return (
                                <SummaryViewPlanInfoTooltipContent>
                                  Includes knowledge base access and email
                                  support. Email support tickets will be
                                  answered on a first come, first serve basis.
                                </SummaryViewPlanInfoTooltipContent>
                              );
                            case "Priority":
                              return (
                                <SummaryViewPlanInfoTooltipContent>
                                  Includes knowledge base access and email
                                  support. Email support tickets will be
                                  addressed before other, non-priority tickets.
                                </SummaryViewPlanInfoTooltipContent>
                              );
                          }
                        }}
                      >
                        <SummaryViewPlanInfoTooltipTrigger>
                          <IconCircledInfo size="16px" />
                        </SummaryViewPlanInfoTooltipTrigger>
                      </TooltipBig>
                    </div>
                  </div>
                  {plan.features["Shopify Markets"].type === "BOOLEAN" &&
                  plan.features["Shopify Markets"].value ? (
                    <div>
                      <div>
                        <IconCheckMark
                          size="16px"
                          color={theme.palette.green}
                        />
                      </div>
                      <div>Shopify Markets</div>
                      <div>
                        <TooltipBig
                          placement="top"
                          maxWidth={`${theme.gridBase * 48}px`}
                          render={() => (
                            <SummaryViewPlanInfoTooltipContent>
                              Are you selling internationally with Shopify
                              Markets? You may want to use different marketing
                              pixels depending on the market.
                            </SummaryViewPlanInfoTooltipContent>
                          )}
                        >
                          <SummaryViewPlanInfoTooltipTrigger>
                            <IconCircledInfo size="16px" />
                          </SummaryViewPlanInfoTooltipTrigger>
                        </TooltipBig>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </SummaryViewPlanInfo>
                <SelectPlanButton
                  type="SUMMARY"
                  plan={plan}
                  currentPlan={currentPlan}
                  selectedPlan={selectedPlan}
                  selectPlan={selectPlan}
                  disablePlanSelectInfo={disablePlanSelectInfo}
                />
              </SummaryViewPlanCardInner>
            </SummaryViewPlanCard>
          );
        })}
      </div>
      <SummaryViewTestStarterPlanPageCard>
        <div>
          <div>
            {selectedPlan === starterPlan
              ? "You're getting up to 100 orders/month for free on our Starter Plan!"
              : "Have less than 100 orders/month? Try our Starter Plan!"}
          </div>
          <div>
            <TooltipBig
              placement="top"
              render={() => (
                <SummaryViewTestStarterPlanPageCardTooltipContent>
                  <div>
                    <div>{starterPlan.name}</div>
                    <div>${starterPlan.dollarPrice} /month</div>
                  </div>
                  <div>
                    <div>
                      <div>
                        - {starterPlan.usage["Orders Included/Month"].value}{" "}
                        orders/mo included
                      </div>
                      <div>
                        - ${starterPlan.usage["Extra Order Fee"].value} per
                        every extra order
                      </div>
                      <div>- {starterPlan.supportTier} Support</div>
                    </div>
                    <div>
                      <div>Not Included:</div>
                      <div>
                        <div>x Real-Time Reporting</div>
                        <div>x Email Alerts</div>
                      </div>
                    </div>
                  </div>
                </SummaryViewTestStarterPlanPageCardTooltipContent>
              )}
            >
              <SummaryViewTestStarterPlanPageCardTooltipTrigger>
                <IconCircledInfo size="16px" />
              </SummaryViewTestStarterPlanPageCardTooltipTrigger>
            </TooltipBig>
          </div>
        </div>
        <div>
          <SelectPlanButton
            type="SUMMARY"
            plan={starterPlan}
            currentPlan={currentPlan}
            selectedPlan={selectedPlan}
            selectPlan={selectPlan}
            disablePlanSelectInfo={disablePlanSelectInfo}
          />
        </div>
      </SummaryViewTestStarterPlanPageCard>
      <ComparePlansButtonWrapper>
        <ButtonWhiteWithIcon
          variant="SMALL"
          onClick={() => setComparePlans(true)}
        >
          <IconPointOut size="16px" />
          <span>View All Features</span>
        </ButtonWhiteWithIcon>
      </ComparePlansButtonWrapper>
    </SummaryViewWrapper>
  );
};

type SummaryViewWrapperProps = {
  planCount: number;
};

const SummaryViewWrapper = styled.div<SummaryViewWrapperProps>`
  > div:first-child {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(${props => props.planCount}, 1fr);
    gap: ${props => props.theme.gridBase * 2}px;
  }
`;

type SummaryViewPlanCardProps = {
  isSelected: boolean;
};

const SummaryViewPlanCard = styled(PageCard)<SummaryViewPlanCardProps>`
  padding: 0;
  overflow: hidden;
  text-align: left;
  position: relative;
  transition: box-shadow ${props => props.theme.other.transition};

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    border-width: 1px;
    border-style: solid;
    border-color: ${props =>
      props.isSelected ? props.theme.palette.purple2 : "transparent"};
    transition: border-color ${props => props.theme.other.transition};
    border-radius: 4px;
  }
`;

const SummaryViewPlanCardInner = styled.div`
  padding: ${props => props.theme.gridBase * 3}px;
`;

const SummaryViewPlanHeader = styled.div`
  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 0.75}px;
    padding-bottom: ${props => props.theme.gridBase * 2}px;
    border-bottom: 1px solid ${props => props.theme.palette.grey7};
    margin-bottom: ${props => props.theme.gridBase * 2}px;

    > div:first-child {
      ${heading1Styles};
    }

    > div:last-child {
      ${smallTextStyles};
      color: ${props => props.theme.palette.grey3};
      padding-top: ${props => props.theme.gridBase * 0.75}px;
    }
  }
`;

const SummaryViewPlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.gridBase}px;
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;

  > div {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 0.75}px;
    height: ${props => props.theme.gridBase * 2.5}px;

    > div:nth-child(2) {
      ${normalTextStyles};
      color: ${props => props.theme.palette.grey3};

      > span {
        font-weight: 600;
        color: ${props => props.theme.palette.grey1};
      }
    }
  }
`;

const SummaryViewPlanInfoTooltipTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
`;

const SummaryViewPlanInfoTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

const SummaryViewTestStarterPlanPageCard = styled(PageCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.gridBase * 1.5}px;
  padding: ${props => props.theme.gridBase * 1.5}px;
  margin-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey1};
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const SummaryViewTestStarterPlanPageCardTooltipTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const SummaryViewTestStarterPlanPageCardTooltipContent = styled.div`
  padding: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    ${heading3Styles};
    margin-bottom: ${props => props.theme.gridBase}px;

    > div:first-child {
      margin-bottom: ${props => props.theme.gridBase * 0.25}px;
    }
  }

  > div:last-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey3};
    padding-right: ${props => props.theme.gridBase * 0.25}px;

    > div:first-child {
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      > div:first-child {
        margin-bottom: ${props => props.theme.gridBase * 0.5}px;
      }
    }
  }
`;

const ComparePlansButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const ButtonWhiteWithIcon = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  margin-left: ${props => props.theme.gridBase * 2}px;

  > span {
    margin-left: ${props => props.theme.gridBase}px;
  }
`;

/* ========================================================================== */

type SelectPlanButtonProps = {
  type: "DETAIL" | "SUMMARY";
  plan: Plan;
  currentPlan: CurrentPlan;
  selectedPlan: Plan | null;
  selectPlan: (plan: Plan | null) => void;
  disablePlanSelectInfo: DisablePlanSelectInfo;
};

const SelectPlanButton: React.FC<SelectPlanButtonProps> = ({
  type,
  plan,
  currentPlan,
  selectedPlan,
  selectPlan,
  disablePlanSelectInfo
}) => {
  const { trialState } = useTrialDetails();

  if (selectedPlan?.id === plan.id) {
    return (
      <PlanButtonSecondary variant="SMALL" onClick={() => selectPlan(null)}>
        <div>
          <IconCheckMark size="16px" />
        </div>
        <div>
          {currentPlan.remote.id === plan.id
            ? type === "DETAIL"
              ? "Current"
              : "Current Plan"
            : "Selected"}
        </div>
      </PlanButtonSecondary>
    );
  } else {
    const Button =
      selectedPlan !== null || plan === starterPlan
        ? PlanButtonSecondary
        : PlanButtonPrimary;

    const disabled = disablePlanSelectInfo?.getDisabled(plan) ?? false;

    return (
      <TooltipBig
        placement="top"
        disabled={!disabled}
        maxWidth={
          disablePlanSelectInfo?.tooltip.maxWidth
            ? `${disablePlanSelectInfo.tooltip.maxWidth}px`
            : undefined
        }
        render={disablePlanSelectInfo?.tooltip.content ?? (() => null)}
      >
        <div>
          <Button
            variant="SMALL"
            state={disabled ? "DISABLED" : "IDLE"}
            onClick={() => selectPlan(plan)}
          >
            {trialState === "ELIGIBLE"
              ? type === "DETAIL"
                ? "Start Trial"
                : "Start 15-Day Free Trial"
              : "Select Plan"}
          </Button>
        </div>
      </TooltipBig>
    );
  }
};

const PlanButtonSecondary = styled(ButtonSecondary)`
  width: 100%;
  display: flex;
  align-items: center;

  > div:first-child {
    display: flex;
    margin-right: ${props => props.theme.gridBase * 0.5}px;
  }
`;

const PlanButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
`;

/* ========================================================================== */

const usageOrder: Array<keyof Plan["usage"]> = [
  "Orders Included/Month",
  "Extra Order Fee"
];

const featuresOrder: Array<keyof Plan["features"]> = [
  "Session Enrichment",
  "Built-in Consent Mode",
  "Conversion Accuracy Monitoring",
  "Facebook Conversion API",
  "Multiple Pixels per Destination",
  "Google Ads",
  "Real-Time Reporting",
  "Email Alerts",
  "Shopify Markets"
];

const supportOrder: Array<keyof Plan["support"]> = [
  "Support Level",
  "Onboarding Phone Calls"
];

const servicesOrder: Array<keyof Plan["services"]> = [
  "One-Time Audits & Setups",
  "Tracking Management Tier 1",
  "Tracking Management Tier 2"
];

const PlanSelectorDetailView: React.FC<PlanSelectorViewProps> = ({
  currentPlan,
  selectedPlan,
  selectPlan,
  setComparePlans,
  disablePlanSelectInfo
}) => {
  const { trialState } = useTrialDetails();

  return (
    <DetailViewWrapper>
      <div>
        <DetailViewGrid
          count={plans.length}
          selected={selectedPlan ? plans.indexOf(selectedPlan) + 2 : null}
        >
          <div>
            <div>
              <DetailViewAllPlansIncludeSection>
                <div>
                  <div>All plans include:</div>
                  {trialState === "ELIGIBLE" ? (
                    <div>
                      <div>
                        <IconCheckMark size="16px" />
                      </div>
                      <div>A 15-Day Free Trial</div>
                    </div>
                  ) : null}
                  <div>
                    <div>
                      <IconCheckMark size="16px" />
                    </div>
                    <DetailViewItem name="Server-Side Tracking" />
                  </div>
                  <div>
                    <div>
                      <IconCheckMark size="16px" />
                    </div>
                    <div>99% Conversion Accuracy</div>
                  </div>
                </div>
              </DetailViewAllPlansIncludeSection>
              <DetailViewFeatureNameSection>
                <div>Usage</div>
                <div>
                  {usageOrder.map(item => (
                    <DetailViewItem key={item} name={item} />
                  ))}
                </div>
              </DetailViewFeatureNameSection>
              <DetailViewFeatureNameSection>
                <div>Features</div>
                <div>
                  {featuresOrder.map(item => (
                    <DetailViewItem key={item} name={item} />
                  ))}
                </div>
              </DetailViewFeatureNameSection>
              <DetailViewFeatureNameSection>
                <div>Support</div>
                <div>
                  {supportOrder.map(item => (
                    <DetailViewItem key={item} name={item} />
                  ))}
                </div>
              </DetailViewFeatureNameSection>
              <DetailViewFeatureNameSection>
                <div>Professional Services</div>
                <div>
                  {servicesOrder.map(item => (
                    <DetailViewItem key={item} name={item} />
                  ))}
                </div>
              </DetailViewFeatureNameSection>
              {plans.map(plan => (
                <Fragment key={plan.id}>
                  <DetailViewPlanInfo>
                    <DetailViewPlanName>{plan.name}</DetailViewPlanName>
                    <DetailViewPlanPrice>
                      <div>${plan.dollarPrice}</div>
                      <div>/month</div>
                    </DetailViewPlanPrice>
                    <SelectPlanButton
                      type="DETAIL"
                      plan={plan}
                      currentPlan={currentPlan}
                      selectedPlan={selectedPlan}
                      selectPlan={selectPlan}
                      disablePlanSelectInfo={disablePlanSelectInfo}
                    />
                  </DetailViewPlanInfo>
                  <DetailViewPlanBreakdown plan={plan} type="USAGE" />
                  <DetailViewPlanBreakdown plan={plan} type="FEATURES" />
                  <DetailViewPlanBreakdown plan={plan} type="SUPPORT" />
                  <DetailViewPlanBreakdown plan={plan} type="SERVICES" />
                </Fragment>
              ))}
            </div>
            {plans.map(plan => (
              <div key={plan.id} />
            ))}
          </div>
        </DetailViewGrid>
      </div>
      <ComparePlansButtonWrapper>
        <ButtonWhiteWithIcon
          variant="SMALL"
          onClick={() => setComparePlans(false)}
        >
          <IconPointIn size="16px" />
          <span>Hide Feature Comparison</span>
        </ButtonWhiteWithIcon>
      </ComparePlansButtonWrapper>
    </DetailViewWrapper>
  );
};

const DetailViewWrapper = styled(PageCard)`
  padding-top: 0;
`;

const gridStyles = (planCount: number) => css`
  display: grid;
  grid-template-columns:
    ${props => props.theme.gridBase * 34}px
    repeat(${planCount}, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: column;
`;

const overlayStyles = (column: number, selected: number | null) => css`
  &::after {
    content: "";
    grid-row: 1 / -1;
    grid-column: ${column};
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: ${props =>
      column === selected ? props.theme.palette.purple2 : "transparent"};
    transition:
      border-color ${props => props.theme.other.transition},
      outline ${props => props.theme.other.transition};
    margin-left: ${props => props.theme.gridBase * 0.5}px;
    margin-right: ${props => props.theme.gridBase * 0.5}px;
    margin-top: ${props => props.theme.gridBase * 2}px;
  }
`;

type DetailViewGridProps = {
  count: number;
  selected: number | null;
};

const DetailViewGrid = styled.div<DetailViewGridProps>`
  > div {
    position: relative;

    > div {
      ${props => gridStyles(props.count)};
    }

    > div:nth-child(2),
    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5) {
      pointer-events: none;
      position: absolute;
      inset: 0;
    }

    > div:nth-child(2) {
      ${props => overlayStyles(2, props.selected)};
    }
    > div:nth-child(3) {
      ${props => overlayStyles(3, props.selected)};
    }
    > div:nth-child(4) {
      ${props => overlayStyles(4, props.selected)};
    }
    > div:nth-child(5) {
      ${props => overlayStyles(5, props.selected)};
    }
  }
`;

const DetailViewAllPlansIncludeSection = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div {
    display: flex;
    flex-direction: column;

    > div:first-child {
      ${heading3Styles};
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }

    > div:not(:first-child) {
      display: flex;
      align-items: center;
      gap: ${props => props.theme.gridBase}px;

      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${props => props.theme.gridBase * 2}px;
        width: ${props => props.theme.gridBase * 2}px;
        border-radius: 50%;
        background-color: ${props => props.theme.palette.purple2};
        color: ${props => props.theme.palette.white};
      }

      > div:last-child {
        ${normalBodyStyles};
      }

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase}px;
      }
    }
  }
`;

const DetailViewFeatureNameSection = styled.div`
  border-top: 1px solid ${props => props.theme.palette.grey7};
  padding: ${props => props.theme.gridBase * 3}px 0;

  > div:first-child {
    ${subheadingStyles};
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

const DetailViewFeatureNameTooltipTrigger = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey4};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const DetailViewPlanInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.gridBase * 3}px;
`;

const DetailViewPlanName = styled.div`
  ${heading3Styles};
  text-align: center;
  display: flex;
  align-items: center;
  min-height: ${props => props.theme.gridBase * 6}px;
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DetailViewPlanPrice = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 0.75}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;

  > div:first-child {
    ${heading1Styles};
  }

  > div:last-child {
    ${smallTextStyles};
    color: ${props => props.theme.palette.grey3};
    padding-top: ${props => props.theme.gridBase * 0.75}px;
  }
`;

/* ========================================================================== */

type DetailViewItemName =
  | "Server-Side Tracking"
  | keyof Plan["usage"]
  | keyof Plan["features"]
  | keyof Plan["support"]
  | keyof Plan["services"];

type DetailViewItemProps = {
  name: DetailViewItemName;
};

const DetailViewItem: React.FC<DetailViewItemProps> = ({ name }) => {
  const tooltip = useTooltipFromName(name);

  return (
    <DetailViewItemWrapper>
      <div>{name}</div>
      {tooltip ? (
        <div>
          <TooltipBig
            placement="top"
            maxWidth={`${tooltip.maxWidth}px`}
            render={tooltip.render}
          >
            <DetailViewFeatureNameTooltipTrigger>
              <IconCircledInfo size="16px" />
            </DetailViewFeatureNameTooltipTrigger>
          </TooltipBig>
        </div>
      ) : null}
    </DetailViewItemWrapper>
  );
};

const DetailViewItemWrapper = styled.div`
  ${normalBodyStyles};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;

  > div:not(:first-child) {
    display: flex;
    color: ${props => props.theme.palette.grey4};
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;

/* ========================================================================== */

const useTooltipFromName = (name: DetailViewItemName) => {
  const theme = useTheme();

  switch (name) {
    case "Server-Side Tracking":
      return {
        maxWidth: theme.gridBase * 43.5,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Configure server-side tracking for destinations like GA4, Google
            Ads, Facebook CAPI, Klaviyo, TikTok, Impact Radius, Snapchat, and
            more.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Orders Included/Month":
      return {
        maxWidth: theme.gridBase * 35.5,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            This is how many orders are included in your plan. There'll be a
            flat fee for every extra order over this amount.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Extra Order Fee":
      return null;
    case "Session Enrichment":
      return {
        maxWidth: theme.gridBase * 44,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Get improved campaign ROI by recognizing returning users & enhancing
            data sent to Facebook CAPI, Klaviyo, Google Ads, and more!
          </DetailViewFeatureNameTooltipContent>
        )
      };

    case "Built-in Consent Mode":
      return {
        maxWidth: theme.gridBase * 40,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Integrate your server-side tracking with OneTrust, Cookiebot, and
            others.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Conversion Accuracy Monitoring":
      return {
        maxWidth: theme.gridBase * 36,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Trust your data by monitoring exactly how many purchases are sent to
            your marketing destinations.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Facebook Conversion API":
      return {
        maxWidth: theme.gridBase * 43,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Configure all sitewide events for Facebook Conversion API, including
            for Recharge, Zipify, Carthook, and other third-party apps.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Multiple Pixels per Destination":
      return {
        maxWidth: theme.gridBase * 33,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Add up to 5 instances of the same marketing destination (channel).
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Google Ads":
      return {
        maxWidth: theme.gridBase * 43,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Running post-purchase upsells? Start getting up to 50% more reported
            conversions with our Google Ads integration.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Real-Time Reporting":
      return {
        maxWidth: theme.gridBase * 41,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Verify your tracking is working in real-time. And when we say
            real-time, we mean within seconds, not minutes.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Email Alerts":
      return {
        maxWidth: theme.gridBase * 34.5,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Receive daily, weekly or real-time emails for marketing channel
            health.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Shopify Markets":
      return {
        maxWidth: theme.gridBase * 48,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Are you selling internationally with Shopify Markets? You may want
            to use different marketing pixels depending on the market.
          </DetailViewFeatureNameTooltipContent>
        )
      };

    case "Support Level":
      return null;
    case "Onboarding Phone Calls":
      return {
        maxWidth: theme.gridBase * 49,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Our Onboarding Specialist will guide you on how to install the
            Elevar data layer, import pre-built tags, and configure your
            server-side tracking. Only available when in-app onboarding steps
            are not completed. If your onboarding steps are completed, there are
            no longer steps for you to be guided through.
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "One-Time Audits & Setups":
      return {
        maxWidth: theme.gridBase * 33,
        render: () => (
          <DetailViewFeatureNameTooltipContent>
            Elevar's done-for-you services like installation, configuration,
            testing of your tracking, and more!
          </DetailViewFeatureNameTooltipContent>
        )
      };
    case "Tracking Management Tier 1":
      return null;
    case "Tracking Management Tier 2":
      return null;
  }
};

const DetailViewFeatureNameTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;

  > a {
    ${linkStyles};
  }
`;

/* ========================================================================== */

const getUsageItem = (
  plan: Plan,
  key: keyof Plan["usage"]
): PlanDetailsItemValueNonNumber => {
  switch (key) {
    case "Orders Included/Month":
      return {
        type: "STRING_BIG",
        value: plan.usage[key].value.toLocaleString("en")
      };
    case "Extra Order Fee":
      return {
        type: "STRING",
        value: `$${plan.usage[key].value}/order`
      };
  }
};

type DetailViewPlanBreakdownProps = {
  plan: Plan;
  type: "USAGE" | "FEATURES" | "SUPPORT" | "SERVICES";
};

const DetailViewPlanBreakdown: React.FC<DetailViewPlanBreakdownProps> = ({
  plan,
  type
}) => {
  const items = (() => {
    switch (type) {
      case "USAGE":
        return usageOrder.map(key => [key, getUsageItem(plan, key)] as const);
      case "FEATURES":
        return featuresOrder.map(key => [key, plan.features[key]] as const);
      case "SUPPORT":
        return supportOrder.map(key => [key, plan.support[key]] as const);
      case "SERVICES":
        return servicesOrder.map(key => [key, plan.services[key]] as const);
    }
  })();

  return (
    <DetailViewPlanFeaturesWrapper>
      {items.map(([key, item]) => (
        <div key={key}>
          {(() => {
            switch (item.type) {
              case "BOOLEAN":
                return item.value ? (
                  <BreakdownCheckMark>
                    <IconCheckMark size="16px" />
                  </BreakdownCheckMark>
                ) : (
                  <BreakdownCross>
                    <IconCross size="16px" />
                  </BreakdownCross>
                );
              case "STRING":
                return <BreakdownText>{item.value}</BreakdownText>;
              case "STRING_BIG":
                return <BreakdownTextBig>{item.value}</BreakdownTextBig>;
              case "LINK":
                return (
                  <BreakdownLink href={item.href}>{item.text}</BreakdownLink>
                );
            }
          })()}
        </div>
      ))}
    </DetailViewPlanFeaturesWrapper>
  );
};

const DetailViewPlanFeaturesWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.palette.grey7};
  padding-top: ${props => props.theme.gridBase * 7}px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.gridBase * 3}px;

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase * 2}px;
    }
  }
`;

const sharedBreakdownIconStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.gridBase * 2}px;
  width: ${props => props.theme.gridBase * 2}px;
  border-radius: 50%;
  color: ${props => props.theme.palette.white};
`;

const BreakdownCheckMark = styled.div`
  ${sharedBreakdownIconStyles};
  background-color: ${props => props.theme.palette.purple2};
`;

const BreakdownCross = styled.div`
  ${sharedBreakdownIconStyles};
  background-color: ${props => props.theme.palette.grey6};

  > svg {
    height: ${props => props.theme.gridBase * 1.75}px;
    width: ${props => props.theme.gridBase * 1.75}px;
  }
`;

const BreakdownText = styled.div`
  ${smallTextStyles};
  color: ${props => props.theme.palette.grey2};
`;

const BreakdownTextBig = styled.div`
  ${largeTextStyles};
  font-weight: 600;
`;

const BreakdownLink = styled(LinkExternal)`
  ${smallTextStyles};
  ${linkStyles};
`;
