type IconChevronLeftProps = {
  size: "16px";
  color?: string;
  className?: string;
};

export const IconChevronLeft: React.FC<IconChevronLeftProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M10 4L6 8L10 12"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
