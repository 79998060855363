import { useId, useState } from "react";
import styled, { useTheme } from "styled-components";

import { IconChevronDown, IconChevronUp } from "elevar-design-system/src/icons";
import { LabeledRadioText } from "elevar-design-system/src/labeledRadios/LabeledRadioText";
import { normalTextStyles } from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type BestPracticesSelectionProps = {
  isDisabled: boolean;
  showBreakdownByDefault: boolean;
  bestPracticiesTextOverride?: string;
  initialIsBestPracticesSelected: boolean | (() => boolean);
  onSelect: (args: { isBestPracticesSelected: boolean }) => void;
  children: (props: { isBestPracticesSelected: boolean }) => React.ReactNode;
};

export const BestPracticesSelection: React.FC<BestPracticesSelectionProps> = ({
  isDisabled,
  showBreakdownByDefault,
  bestPracticiesTextOverride,
  initialIsBestPracticesSelected,
  onSelect,
  children
}) => {
  const theme = useTheme();
  const radioGroupName = useId();

  const [isBestPracticesSelected, setIsBestPracticesSelected] = useState(
    initialIsBestPracticesSelected
  );
  const [isBreakdownShown, setIsBreakdownShown] = useState(
    showBreakdownByDefault || !isBestPracticesSelected
  );

  return (
    <>
      <LabeledRadioText
        groupName={radioGroupName}
        isSelected={isBestPracticesSelected}
        setIsSelected={() => {
          setIsBestPracticesSelected(true);
          onSelect({ isBestPracticesSelected: true });
        }}
        text={bestPracticiesTextOverride ?? "Use best practices"}
        isDisabled={isDisabled}
        tag={{ text: "Recommended", color: theme.palette.green }}
      />
      <LabeledRadioText
        groupName={radioGroupName}
        isSelected={!isBestPracticesSelected}
        setIsSelected={() => {
          setIsBestPracticesSelected(false);
          setIsBreakdownShown(true);
          onSelect({ isBestPracticesSelected: false });
        }}
        text="Customize settings"
        isDisabled={isDisabled}
      />
      <BreakdownToggleButton
        onClick={() => setIsBreakdownShown(!isBreakdownShown)}
      >
        <div>Breakdown</div>
        <div>
          {isBreakdownShown ? (
            <IconChevronUp size="16px" />
          ) : (
            <IconChevronDown size="16px" />
          )}
        </div>
      </BreakdownToggleButton>
      {isBreakdownShown ? (
        <BreakdownWrapper>
          {children({ isBestPracticesSelected })}
        </BreakdownWrapper>
      ) : null}
    </>
  );
};

/* ========================================================================== */

const BreakdownToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 2}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

const BreakdownWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 2}px;
`;
