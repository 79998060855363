type IconYahooProps = {
  size: "24px";
  className?: string;
};

export const IconYahoo: React.FC<IconYahooProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M0 6.19414H4.57726L7.24258 13.0128L9.94256 6.19414H14.3988L7.68851 22.3363H3.20332L5.04027 18.0589L0.000141746 6.19414H0ZM19.5882 11.6425H14.5969L19.0269 1L24 1.00021L19.5882 11.6425V11.6425ZM15.9043 12.6611C17.4358 12.6611 18.6772 13.9026 18.6772 15.4338C18.6772 16.9651 17.4358 18.2065 15.9043 18.2065C14.373 18.2065 13.1318 16.9651 13.1318 15.4338C13.1318 13.9026 14.3733 12.6611 15.9043 12.6611Z"
            fill="#5F01D1"
          />
        </svg>
      );
  }
};
