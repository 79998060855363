type IconCJAffiliateProps = {
  size: "24px";
  className?: string;
};

export const IconCJAffiliate: React.FC<IconCJAffiliateProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12Z"
            fill="#025450"
          />
          <path
            d="M17.6947 9.15343H11.284C11.2429 9.15355 11.2032 9.16815 11.1718 9.19466C11.1404 9.22116 11.1193 9.25789 11.1123 9.29838L10.8132 11.0553C10.8083 11.0803 10.8091 11.1062 10.8154 11.131C10.8217 11.1557 10.8335 11.1788 10.8498 11.1985C10.8661 11.2181 10.8866 11.2339 10.9098 11.2447C10.933 11.2555 10.9583 11.261 10.9838 11.2609H15.0959C15.1382 11.261 15.18 11.2704 15.2184 11.2883C15.2567 11.3062 15.2907 11.3323 15.318 11.3646C15.3453 11.397 15.3652 11.4349 15.3764 11.4758C15.3875 11.5166 15.3897 11.5594 15.3827 11.6011L14.6785 15.7317C14.6109 16.0775 14.4282 16.3902 14.1601 16.6189C13.892 16.8476 13.5544 16.9787 13.2023 16.991H9.94861C9.90746 16.9909 9.86762 17.0055 9.83618 17.032C9.80474 17.0585 9.78374 17.0954 9.77693 17.136L9.47777 18.8928C9.47331 18.9177 9.47441 18.9432 9.48099 18.9675C9.48756 18.9919 9.49946 19.0145 9.51581 19.0337C9.53206 19.053 9.55235 19.0686 9.57527 19.0792C9.59818 19.0899 9.62315 19.0954 9.64843 19.0954H12.8229C14.6888 19.0954 16.4652 17.578 16.7839 15.7111L17.8664 9.36006C17.8709 9.33493 17.8698 9.30911 17.8633 9.28443C17.8567 9.25975 17.8449 9.2368 17.8285 9.21721C17.8121 9.19761 17.7917 9.18183 17.7686 9.17099C17.7454 9.16015 17.7202 9.1545 17.6947 9.15445V9.15343Z"
            fill="white"
          />
          <path
            d="M12.972 15.0635L13.2712 13.3066C13.2757 13.2815 13.2746 13.2557 13.2681 13.231C13.2615 13.2063 13.2496 13.1833 13.2333 13.1637C13.2169 13.1441 13.1965 13.1284 13.1733 13.1175C13.1502 13.1067 13.125 13.101 13.0995 13.101H9.926C9.76796 13.1051 9.61098 13.0741 9.46643 13.0101C9.32188 12.9461 9.19337 12.8507 9.09022 12.7309C8.98733 12.6056 8.91358 12.4589 8.87429 12.3016C8.835 12.1442 8.83115 11.9801 8.86303 11.8211L9.40685 8.63427C9.47401 8.28825 9.6564 7.97519 9.9243 7.74612C10.1922 7.51705 10.5298 7.3855 10.882 7.3729H14.1357C14.1767 7.37302 14.2164 7.35864 14.2479 7.33231C14.2793 7.30597 14.3004 7.26937 14.3074 7.22898L14.6065 5.4721C14.6112 5.44694 14.6103 5.42106 14.6039 5.3963C14.5974 5.37154 14.5855 5.34852 14.5691 5.32888C14.5527 5.30923 14.5322 5.29346 14.509 5.28267C14.4858 5.27189 14.4605 5.26637 14.4349 5.2665H11.2614C9.39143 5.26855 7.61913 6.78693 7.30045 8.65278L6.75663 11.8396C6.60242 12.7474 6.81317 13.5986 7.35185 14.2359C7.89053 14.8733 8.65845 15.2043 9.54666 15.2043H12.8003C12.8409 15.2046 12.8802 15.1906 12.9116 15.1649C12.943 15.1392 12.9643 15.1033 12.972 15.0635Z"
            fill="white"
          />
        </svg>
      );
  }
};
