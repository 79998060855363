import "react-toastify/ReactToastify.css";

import { Slide, ToastContainer as BaseToastContainer } from "react-toastify";
import styled, { css } from "styled-components";

/* ========================================================================== */

type ToastContainerProps = {
  zIndex?: number;
};

export const ToastContainer: React.FC<ToastContainerProps> = ({ zIndex }) => {
  return (
    <ToastContainerWrapper zIndex={zIndex}>
      <BaseToastContainer
        position="bottom-center"
        autoClose={3000}
        transition={Slide}
        hideProgressBar={true}
        closeButton={false}
        draggable={true}
        icon={false}
      />
    </ToastContainerWrapper>
  );
};

/* ========================================================================== */

type ToastContainerWrapperProps = {
  zIndex?: number;
};

const ToastContainerWrapper = styled.div<ToastContainerWrapperProps>`
  .Toastify__toast-container {
    padding: 0;
    width: ${props => props.theme.gridBase * 36.5}px;

    ${props =>
      props.zIndex &&
      css`
        z-index: ${props.zIndex};
      `}
  }

  .Toastify__toast-container--bottom-right {
    bottom: ${props => props.theme.gridBase * 2.25}px;
    right: ${props => props.theme.gridBase * 2.25}px;
  }

  .Toastify__toast {
    background: none;
    min-height: 0;
    margin-bottom: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    max-height: auto;
    display: block;

    &:not(:last-child) {
      margin-bottom: ${props => props.theme.gridBase}px;
    }
  }

  .Toastify__toast-body {
    margin: 0;
  }
`;
