type IconConsentmoProps = {
  size: "24px";
  className?: string;
};

export const IconConsentmo: React.FC<IconConsentmoProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15.7138 4H8.28622C3.71377 4 0 7.71377 0 12.2862C0 16.8587 3.71377 20.5725 8.28622 20.5725H15.7138C20.2862 20.5725 24 16.8587 24 12.2862C24 7.71377 20.2648 4 15.7138 4ZM15.542 18.6834C13.8032 18.6834 12.2361 17.975 11.0984 16.8372C9.96064 15.6995 9.25224 14.1324 9.25224 12.3936C9.25224 10.5474 10.0465 8.87299 11.3131 7.73524C12.4293 6.7263 13.9106 6.10376 15.542 6.10376C19.0197 6.10376 21.8318 8.91592 21.8318 12.3936C21.8318 15.8712 19.0197 18.6834 15.542 18.6834Z"
            fill="#003399"
          />
          <path
            d="M20.0715 14.1753C19.4275 15.9785 17.7316 17.0948 15.6279 17.0948C15.0698 17.0948 14.5331 17.0089 14.0179 16.8587C12.0429 16.2576 10.8193 14.5402 10.8193 12.3936C10.8193 10.2469 12.0859 8.48658 14.1252 7.90698C14.5975 7.77817 15.1127 7.69231 15.6279 7.69231C17.7316 7.69231 19.4061 8.80859 20.0715 10.6118H17.8604C17.3667 9.94633 16.5724 9.55993 15.6279 9.55993C13.9964 9.55993 12.8587 10.7191 12.8587 12.3936C12.8587 14.068 13.9964 15.2057 15.6279 15.2057C16.5724 15.2057 17.3667 14.8408 17.8604 14.1538L20.0715 14.1753Z"
            fill="#003399"
          />
        </svg>
      );
  }
};
