import { useMutation, useQuery } from "@tanstack/react-query";

import {
  type AccountDetails,
  type CompanyListCompany
} from "elevar-common-ts/src/apiTypes";

import { useUserOptional } from "../../context/User";
import { track } from "../../utils/track";
import { apiRequest, setApiAuthToken, useExtendedQueryClient } from "../utils";

/* ========================================================================== */

type AccountSignupInputData = {
  fullName: string;
  emailAddress: string;
  password: string;
};

type AccountSignupResponseData = { token: string; me: AccountDetails };

type AccountSignupRequestData = {
  full_name: string;
  username: string;
  password: string;
};

const accountEmailSignup = (data: AccountSignupInputData) => {
  return apiRequest<AccountSignupResponseData, AccountSignupRequestData>({
    endpoint: "/user/signup",
    method: "POST",
    requiresAuth: false,
    data: {
      full_name: data.fullName,
      username: data.emailAddress,
      password: data.password
    }
  });
};

export const useAccountEmailSignupMutation = () => {
  const { checkIsLoggedIn } = useUserOptional();

  return useMutation({
    mutationFn: accountEmailSignup,
    onSuccess: async data => {
      setApiAuthToken(data.token);
      await checkIsLoggedIn();
      track.userCreate({ user: data.me });
    }
  });
};

/* -------------------------------------------------------------------------- */

type AccountLoginInputData = {
  emailAddress: string;
  password: string;
};

type AccountLoginData = {
  username: string;
  password: string;
};

const accountEmailLogin = (data: AccountLoginInputData) => {
  return apiRequest<{ token: string }, AccountLoginData>({
    endpoint: "/user/auth",
    method: "POST",
    requiresAuth: false,
    data: {
      username: data.emailAddress,
      password: data.password
    }
  });
};

export const useAccountEmailLoginMutation = () => {
  const { checkIsLoggedIn } = useUserOptional();

  return useMutation({
    mutationFn: accountEmailLogin,
    onSuccess: async data => {
      setApiAuthToken(data.token);
      await checkIsLoggedIn();
    }
  });
};

/* -------------------------------------------------------------------------- */

const accountGoogleAuth = (accessToken: string) => {
  return apiRequest<{ token: string }, { access_token: string }>({
    endpoint: "/user/social-auth/google-oauth2",
    method: "POST",
    requiresAuth: false,
    data: { access_token: accessToken }
  });
};

export const useAccountGoogleAuthMutation = () => {
  const { checkIsLoggedIn } = useUserOptional();

  return useMutation({
    mutationFn: accountGoogleAuth,
    onSuccess: async data => {
      setApiAuthToken(data.token);
      await checkIsLoggedIn();
    }
  });
};

/* ========================================================================== */

type UpdateAccountDetails = Partial<
  Pick<AccountDetails, "first_name" | "last_name" | "email" | "memberships"> & {
    profile: Partial<AccountDetails["profile"]>;
  }
>;

const updateAccountDetails = (data: UpdateAccountDetails) => {
  return apiRequest<AccountDetails, UpdateAccountDetails>({
    endpoint: "/user/me",
    method: "PATCH",
    data
  });
};

export const useAccountDetailsMutation = () => {
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: updateAccountDetails,
    onSuccess: data => {
      queryClient.base.setQueryData(["account", "details"], data);
    }
  });
};

/* ========================================================================== */

type ResetPasswordMutationData = {
  email: string;
};

type ResetPasswordEndpointData = {
  username: string;
};

const resetPassword = (data: ResetPasswordMutationData) => {
  return apiRequest<unknown, ResetPasswordEndpointData>({
    endpoint: "/reset_password",
    method: "POST",
    requiresAuth: false,
    data: { username: data.email }
  });
};

export const useResetPasswordMutation = () => {
  return useMutation({ mutationFn: resetPassword });
};

/* -------------------------------------------------------------------------- */

type ChangePasswordMutationData = {
  resetKey: string;
  password: string;
};

type ChangePasswordEndpointData = {
  password: string;
};

const changePassword = (data: ChangePasswordMutationData) => {
  return apiRequest<{ token: string }, ChangePasswordEndpointData>({
    endpoint: `/reset_password/${data.resetKey}`,
    method: "POST",
    requiresAuth: false,
    data: { password: data.password }
  });
};

export const useChangePasswordMutation = () => {
  const { checkIsLoggedIn } = useUserOptional();

  return useMutation({
    mutationFn: changePassword,
    onSuccess: async data => {
      setApiAuthToken(data.token);
      await checkIsLoggedIn();
    }
  });
};

/* ========================================================================== */

const deleteAccount = () => {
  return apiRequest({
    endpoint: "/user/me/delete",
    method: "POST"
  });
};

export const useDeleteAccountMutation = () => {
  return useMutation({ mutationFn: deleteAccount });
};

/* ========================================================================== */

const leaveCompany = (companyId: number) => {
  return apiRequest({ endpoint: `/user/me/leave_company/${companyId}` });
};

export const useAccountLeaveCompanyMutation = () => {
  const queryClient = useExtendedQueryClient();

  return useMutation({
    mutationFn: (companyId: number) => leaveCompany(companyId),
    onSuccess: async (_data, companyId) => {
      await queryClient.extensions.invalidateOrRemoveQueriesList([
        ["account", "details"],
        ["account", "companyList"],
        ["companies", companyId]
      ]);
    }
  });
};

/* ========================================================================== */

export type AccountCompanyList = Array<CompanyListCompany>;

const fetchAccountCompanyList = () => {
  return apiRequest<AccountCompanyList>({ endpoint: "/companies_list" });
};

export const useAccountCompanyListQuery = () => {
  return useQuery({
    queryKey: ["account", "companyList"],
    queryFn: fetchAccountCompanyList
  });
};
