import { Link } from "react-router-dom";
import styled from "styled-components";

import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { useCompanyId, useWebsiteId } from "../utils/idHooks";
import { track } from "../utils/track";
import { AvatarStack } from "./AvatarStack";

import langleyImageSrc from "../images/avatar-langley.webp";
import marioImageSrc from "../images/avatar-mario.webp";

/* ========================================================================== */

type ContactCalloutProps = {
  linkType: "APP_SUPPORT_PAGE" | "KNOWLEDGE_BASE";
};

export const ContactCallout: React.FC<ContactCalloutProps> = ({ linkType }) => {
  return (
    <ContactCalloutWrapper>
      <AvatarStack
        avatars={[
          { src: langleyImageSrc, alt: "Langley" },
          { src: marioImageSrc, alt: "Mario" }
        ]}
      />
      <ContactCalloutText>
        <span>Need Help?</span>
        <span>
          {linkType === "APP_SUPPORT_PAGE" ? (
            <ContactCalloutAppSupportPageLink />
          ) : (
            <StyledLinkExternal
              href="https://docs.getelevar.com/page/contact-support"
              text="Contact Support"
              onClick={() => track.contactCalloutExternalLinkClick()}
            />
          )}
        </span>
      </ContactCalloutText>
    </ContactCalloutWrapper>
  );
};

const ContactCalloutWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContactCalloutText = styled.div`
  ${normalBodyStyles};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.gridBase * 0.75}px;
  margin-left: ${props => props.theme.gridBase * 1.5}px;

  a {
    ${linkStyles};
  }
`;

/* ========================================================================== */

const ContactCalloutAppSupportPageLink: React.FC = () => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const supportUrl = `${websiteUrl}/support`;

  return <Link to={supportUrl}>Visit Support</Link>;
};
