import dayjs from "dayjs";
import { sortBy, sumBy, uniqBy } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";

import {
  type EventsConnectorConfig,
  type WebsiteDetails
} from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimaryAsLink,
  ButtonPrimaryAsLinkExternal,
  ButtonSecondary,
  ButtonSecondaryAsLink,
  ButtonSecondaryAsLinkExternal
} from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCircledInfo,
  IconCircledPlus,
  IconHelp,
  IconShopify,
  IconWeb
} from "elevar-design-system/src/icons";
import { LinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { linkStyles } from "elevar-design-system/src/links/links";
import { Spinner } from "elevar-design-system/src/Spinner";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading2Styles,
  heading3Styles,
  largeTextStyles,
  normalBodyStyles,
  normalTextStyles,
  smallTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import { type AppEducationSlideshowNode } from "../../api/handlers/appEducationSlideshows";
import { type Product, useProductsQuery } from "../../api/handlers/products";
import {
  type ConversionAccuracyConfig,
  useBuildWebsiteUrl,
  useConversionAccuracyConfigQuery,
  useEventsConnectorConfigQuery,
  useWebsiteUpdatesQuery,
  type WebsiteUpdate
} from "../../api/handlers/website";
import { Banner } from "../../components/Banner";
import { FeatureTipsButton } from "../../components/FeatureTipsButton";
import { Modal } from "../../components/Modal";
import { NoneExplainer } from "../../components/NoneExplainer";
import { PageCard } from "../../components/PageCard";
import { CubeApiDetailsProvider } from "../../context/CubeApiDetails";
import { type MonitoringTimePeriod } from "../../context/MonitoringTimePeriod";
import {
  MyTrackingDetailsProvider,
  useMyTrackingDetails
} from "../../context/MyTrackingDetails";
import { useServerSideDetails } from "../../context/ServerSideDetails";
import { useTrialDetails } from "../../context/TrialDetails";
import { useCompanyId, useWebsiteId } from "../../utils/idHooks";
import { track } from "../../utils/track";
import {
  getPercentageColor,
  getPercentageMatch,
  type TransformedDataItem,
  useConversionAccuracyData
} from "../monitoring/channelAccuracy/conversionAccuracyData";
import { ProductPurchaseConfirmationModalContents } from "../website/settings/Products";
import { DashboardLegacy } from "./DashboardLegacy";

/* ========================================================================== */

type DashboardProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
};

export const Dashboard: React.FC<DashboardProps> = ({
  appEducationSlideshows,
  website,
  isCompanyAdmin
}) => {
  const { isFullyManaged } = useServerSideDetails();

  if (isFullyManaged) {
    return (
      <CubeApiDetailsProvider>
        <MyTrackingDetailsProvider websiteDetails={website}>
          <DashboardInner1
            appEducationSlideshows={appEducationSlideshows}
            website={website}
            isCompanyAdmin={isCompanyAdmin}
          />
        </MyTrackingDetailsProvider>
      </CubeApiDetailsProvider>
    );
  } else {
    return (
      <DashboardLegacy website={website} isCompanyAdmin={isCompanyAdmin} />
    );
  }
};

/* ========================================================================== */

type DashboardInner1Props = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
};

const DashboardInner1: React.FC<DashboardInner1Props> = ({
  appEducationSlideshows,
  website,
  isCompanyAdmin
}) => {
  const products = useProductsQuery();
  const eventsConnectorConfig = useEventsConnectorConfigQuery();
  const conversionAccuracyConfig = useConversionAccuracyConfigQuery();
  const websiteUpdates = useWebsiteUpdatesQuery();

  if (
    products.error !== null ||
    eventsConnectorConfig.error !== null ||
    conversionAccuracyConfig.error !== null ||
    websiteUpdates.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    products.data === undefined ||
    eventsConnectorConfig.data === undefined ||
    conversionAccuracyConfig.data === undefined ||
    websiteUpdates.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <DashboardInner2
      appEducationSlideshows={appEducationSlideshows}
      website={website}
      isCompanyAdmin={isCompanyAdmin}
      products={products.data}
      eventsConnectorConfig={eventsConnectorConfig.data}
      conversionAccuracyConfig={conversionAccuracyConfig.data}
      websiteUpdates={websiteUpdates.data}
    />
  );
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type ProductModalState =
  | { isVisible: true; product: Product }
  | { isVisible: false; product?: Product };

type DashboardInner2Props = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  website: WebsiteDetails;
  isCompanyAdmin: boolean;
  products: Array<Product>;
  eventsConnectorConfig: EventsConnectorConfig;
  conversionAccuracyConfig: ConversionAccuracyConfig;
  websiteUpdates: Array<WebsiteUpdate>;
};

const DashboardInner2: React.FC<DashboardInner2Props> = ({
  appEducationSlideshows,
  website,
  isCompanyAdmin,
  products,
  eventsConnectorConfig,
  conversionAccuracyConfig,
  websiteUpdates
}) => {
  const theme = useTheme();
  const location = useLocation();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { trialState, formattedTrialEndDate } = useTrialDetails();

  const [productModalState, setProductModalState] = useState<ProductModalState>(
    () => {
      const searchParams = new URLSearchParams(location.search);
      const productId = searchParams.get("product_id");
      const product = products.find(p => p.identifier === productId);
      return product ? { isVisible: true, product } : { isVisible: false };
    }
  );

  const companyUrl = `/company/${companyId}`;
  const bilingUrl = `${companyUrl}/settings/billing`;
  const websiteUrl = `${companyUrl}/website/${websiteId}`;
  const connectionsUrl = `${websiteUrl}/settings/connections`;

  const closeProductModal = () => {
    setProductModalState({ ...productModalState, isVisible: false });
  };

  return (
    <>
      <PageWrapper>
        <PageHeader>
          <PageHeading>Dashboard</PageHeading>
          <FeatureTipsButton data={appEducationSlideshows} route="DASHBOARD" />
        </PageHeader>
        {website.google_account?.last_refresh_failed ? (
          isCompanyAdmin ? (
            <BannerWrapper>
              <Link to={connectionsUrl}>
                <Banner
                  textColor={theme.palette.white}
                  backgroundColor={theme.palette.red1}
                  primaryText="Action Required!"
                  secondaryText="Google Account access for this Website has been revoked."
                  actionText="Go to Connections"
                />
              </Link>
            </BannerWrapper>
          ) : (
            <BannerWrapper>
              <Banner
                textColor={theme.palette.white}
                backgroundColor={theme.palette.red1}
                primaryText="Action Required!"
                secondaryText="Google Account access for this Website has been revoked."
              />
            </BannerWrapper>
          )
        ) : website.subscription_status.is_overdue ? (
          isCompanyAdmin ? (
            <BannerWrapper>
              <Link to={bilingUrl}>
                <Banner
                  textColor={theme.palette.white}
                  backgroundColor={theme.palette.red1}
                  primaryText="Action Required!"
                  secondaryText="The most recent payment for this Website failed."
                  actionText="Go to Billing"
                />
              </Link>
            </BannerWrapper>
          ) : (
            <BannerWrapper>
              <Banner
                textColor={theme.palette.white}
                backgroundColor={theme.palette.red1}
                primaryText="Action Required!"
                secondaryText="The most recent payment for this Website failed."
              />
            </BannerWrapper>
          )
        ) : trialState === "IN_PROGRESS" ? (
          <BannerWrapper>
            <Banner
              textColor={theme.palette.blue1}
              backgroundColor={theme.palette.white}
              borderColor={theme.palette.blue1}
              primaryText="Free Trial"
              secondaryText={`Your trial ends on ${formattedTrialEndDate}.`}
            />
          </BannerWrapper>
        ) : null}
        <PageGrid>
          <ChannelSummaryAreaWrapper>
            <ChannelSummary
              eventsConnectorConfig={eventsConnectorConfig}
              conversionAccuracyConfig={conversionAccuracyConfig}
            />
          </ChannelSummaryAreaWrapper>
          <UpdatesAreaWrapper>
            <Updates updates={websiteUpdates} />
          </UpdatesAreaWrapper>
        </PageGrid>
      </PageWrapper>
      <Modal
        isVisible={productModalState.isVisible}
        onClose={closeProductModal}
      >
        {productModalState.product ? (
          <ProductPurchaseConfirmationModalContents
            product={productModalState.product}
            isVisible={productModalState.isVisible}
            close={closeProductModal}
          />
        ) : null}
      </Modal>
    </>
  );
};

const PageWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 3.25}px;
  padding-bottom: ${props => props.theme.gridBase * 4}px;
  padding-left: ${props => props.theme.gridBase * 4}px;
  padding-right: ${props => props.theme.gridBase * 4}px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  min-height: ${props => props.theme.gridBase * 5}px;
`;

const PageHeading = styled.h1`
  ${heading2Styles};
`;

const BannerWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * -1}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-areas: "channel-summary channel-summary updates";
  column-gap: ${props => props.theme.gridBase * 4}px;
`;

const ChannelSummaryAreaWrapper = styled.div`
  grid-area: channel-summary;
`;

const UpdatesAreaWrapper = styled.div`
  grid-area: updates;
`;

/* ========================================================================== */

const period: MonitoringTimePeriod = { selection: "LAST_7_DAYS" };

type ChannelSummaryProps = {
  eventsConnectorConfig: EventsConnectorConfig;
  conversionAccuracyConfig: ConversionAccuracyConfig;
};

const ChannelSummary: React.FC<ChannelSummaryProps> = ({
  eventsConnectorConfig,
  conversionAccuracyConfig
}) => {
  const theme = useTheme();
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const result = useConversionAccuracyData({
    eventsConnectorConfig,
    monitoringTimePeriod: period
  });

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const conversionAccuracyUrl = `${websiteUrl}/channel-accuracy/conversions`;

  return (
    <>
      <ChannelSummaryHeader>
        <div>
          <div>Channel Summary</div>
          <div>
            <TooltipBig
              placement="right"
              maxWidth={`${theme.gridBase * 27.5}px`}
              render={() => (
                <ChannelSummaryHeaderTooltipContent>
                  This is a summary of your Conversion Accuracy report for the
                  last 7 days.
                </ChannelSummaryHeaderTooltipContent>
              )}
            >
              <ChannelSummaryHeaderTooltipTarget>
                <IconHelp size="16px" />
              </ChannelSummaryHeaderTooltipTarget>
            </TooltipBig>
          </div>
        </div>
        {result.status === "SUCCESS_SOME" ? (
          <div>
            <div>For the last 7 days</div>
            <Link to={conversionAccuracyUrl}>View Full Report</Link>
          </div>
        ) : null}
      </ChannelSummaryHeader>
      {result.status === "ERROR" ? (
        <LoadingOrErrorWrapper>
          <ErrorOccurred />
        </LoadingOrErrorWrapper>
      ) : result.status === "LOADING" ? (
        <LoadingOrErrorWrapper>
          <Spinner size="24px" />
        </LoadingOrErrorWrapper>
      ) : result.status === "SUCCESS_NONE" ? (
        <NoneExplainer details={result.details} />
      ) : (
        <ChannelSummaryInner
          conversionAccuracyConfig={conversionAccuracyConfig}
          data={result.data}
        />
      )}
    </>
  );
};

const ChannelSummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 0.75}px;

    > div:first-child {
      ${heading3Styles};
    }
  }

  > div:not(:first-child) {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 2}px;

    > div {
      ${smallTextStyles};
      color: ${props => props.theme.palette.grey3};
    }

    > a {
      ${normalBodyStyles};
      ${linkStyles};
    }
  }
`;

const ChannelSummaryHeaderTooltipContent = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
  padding-top: ${props => props.theme.gridBase * 1.5}px;
  padding-bottom: ${props => props.theme.gridBase * 1.5}px;
  padding-left: ${props => props.theme.gridBase * 2}px;
  padding-right: ${props => props.theme.gridBase * 2}px;
`;

const ChannelSummaryHeaderTooltipTarget = styled.div`
  display: flex;
  color: ${props => props.theme.palette.grey3};
  padding: ${props => props.theme.gridBase * 0.5}px;
`;

const LoadingOrErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.theme.gridBase * 15}px;
`;

/* -------------------------------------------------------------------------- */

type AugmentedDataItem = TransformedDataItem & {
  processing: { isDone: false } | { isDone: true; match: number | null };
};

const currentDate = dayjs();
const processThreshold = currentDate.subtract(7, "days");

type WhatsNextItem = {
  title: string;
  explainer: string;
  icon: React.ReactElement;
  action: { text: string; isPrimary: boolean } & (
    | { to: string }
    | { href: string }
  );
};

type GetWhatsNextFromDataItem = (
  item: TransformedDataItem
) => Array<WhatsNextItem>;

const getWhatsNextFromDataItem: GetWhatsNextFromDataItem = item => {
  const daysSinceGoingLive = Math.abs(
    currentDate.diff(dayjs(item.config.wentLiveAt), "days")
  );

  switch (item.destination.name) {
    case "GA4": {
      return [
        {
          title: "Remove Previous Tracking",
          icon: <item.destination.icon size="24px" />,
          explainer:
            "We recommend removing your previous tracking to avoid sending duplicate events.",
          action: {
            text: "Go to Guide",
            isPrimary: false,
            href: "https://docs.getelevar.com/docs/how-to-remove-previous-tracking"
          }
        },
        ...(daysSinceGoingLive >= 1
          ? [
              {
                title: "Analyze Before and After Results for GA4",
                icon: <item.destination.icon size="24px" />,
                explainer:
                  "Run your tracking via Elevar's integration for at least 7 days and compare your data.",
                action: {
                  text: "Go to Guide",
                  isPrimary: true,
                  href: "https://example.com"
                }
              }
            ]
          : [])
      ];
    }
    case "Facebook": {
      return [
        {
          title: "Remove Previous Tracking",
          icon: <item.destination.icon size="24px" />,
          explainer:
            "We recommend removing your previous tracking to avoid sending duplicate events.",
          action: {
            text: "Go to Guide",
            isPrimary: false,
            href: "https://docs.getelevar.com/docs/how-to-remove-previous-tracking"
          }
        },
        ...(daysSinceGoingLive >= 1
          ? [
              {
                title: "Compare Before and After Match Quality Score",
                icon: <item.destination.icon size="24px" />,
                explainer:
                  "Run your campaigns via Elevar's integration for at least 7 days and compare your Match Quality Score.",
                action: {
                  text: "Go to Guide",
                  isPrimary: true,
                  href: "https://example.com"
                }
              }
            ]
          : [])
      ];
    }
    case "TikTok": {
      return [
        {
          title: "Remove Previous Tracking",
          icon: <item.destination.icon size="24px" />,
          explainer:
            "We recommend removing your previous tracking to avoid sending duplicate events.",
          action: {
            text: "Go to Guide",
            isPrimary: false,
            href: "https://docs.getelevar.com/docs/how-to-remove-previous-tracking"
          }
        }
      ];
    }
    case "Google Ads": {
      return [
        {
          title: "Measure Your Improved Performance on Google Ads",
          icon: <item.destination.icon size="24px" />,
          explainer:
            "Run your tracking via Elevar's integration for at least 7 days and compare your data.",
          action: {
            text: "Go to Guide",
            isPrimary: true,
            href: "https://example.com"
          }
        }
      ];
    }
    case "Klaviyo": {
      return [
        {
          title: "Increase Your Abandoned Flow Revenue",
          icon: <item.destination.icon size="24px" />,
          explainer:
            "Modify your Klaviyo flows to see the difference in revenue and validate Elevar's performance.",
          action: {
            text: "Go to Guide",
            isPrimary: true,
            href: "https://docs.getelevar.com/docs/how-to-analyze-klaviyo-server-side-performance-setup-abandoned-flows"
          }
        },
        ...(daysSinceGoingLive >= 1
          ? [
              {
                title:
                  "Analyze Elevar's ROI for Browse & Cart Abandonment Flows",
                icon: <item.destination.icon size="24px" />,
                explainer:
                  "Run your tracking via Elevar's integration for at least a month and compare your data.",
                action: {
                  text: "Go to Guide",
                  isPrimary: true,
                  href: "https://example.com"
                }
              }
            ]
          : [])
      ];
    }
    default: {
      return [];
    }
  }
};

type UseWhatsNextItems = (args: {
  data: Array<TransformedDataItem>;
}) => Array<WhatsNextItem>;

const useWhatsNextItems: UseWhatsNextItems = ({ data }) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();
  const { sourceInfo, canAddDestinations } = useMyTrackingDetails();

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const myTrackingUrl = `${websiteUrl}/my-tracking`;

  const nonDestinationAction = {
    text: "Go to My Tracking",
    isPrimary: true,
    to: myTrackingUrl
  };

  const nonDestinationItem: WhatsNextItem | null =
    sourceInfo.shopify?.state === "UPGRADE_REQUIRED" ||
    sourceInfo.customPages?.state === "UPGRADE_REQUIRED"
      ? {
          title: "Source Upgrade Available\u00a0\u00a0🎉",
          explainer:
            "Upgrade your sources to unlock your full tracking potential!",
          icon:
            sourceInfo.shopify?.state === "UPGRADE_REQUIRED" ? (
              <IconShopify size="24px" />
            ) : (
              <IconWeb size="24px" />
            ),
          action: nonDestinationAction
        }
      : sourceInfo.shopify?.state === "UPDATE_REQUIRED"
        ? {
            title: "Source Update Available\u00a0\u00a0🎉",
            explainer:
              "Update your Shopify source to unlock your full tracking potential!",
            icon: <IconShopify size="24px" />,
            action: nonDestinationAction
          }
        : data.length < 3 && canAddDestinations
          ? {
              title: "Add Your Next Destination",
              explainer:
                "Select and add your next destination to leverage more from server-side tracking.",
              icon: <IconCircledPlus size="24px" />,
              action: nonDestinationAction
            }
          : null;

  const destinationItems = uniqBy(
    sortBy(data, item => new Date(item.config.wentLiveAt!)),
    item => item.destination
  ).flatMap(getWhatsNextFromDataItem);

  return [
    ...(nonDestinationItem ? [nonDestinationItem] : []),
    ...destinationItems
  ];
};

type ChannelSummaryInnerProps = {
  conversionAccuracyConfig: ConversionAccuracyConfig;
  data: Array<TransformedDataItem>;
};

const ChannelSummaryInner: React.FC<ChannelSummaryInnerProps> = ({
  conversionAccuracyConfig,
  data
}) => {
  const whatsNextItems = useWhatsNextItems({ data });

  const [showMore, setShowMore] = useState(false);

  const augmentedData = useMemo(
    () =>
      (showMore ? data : data.slice(0, 6)).map<AugmentedDataItem>(item => {
        return {
          ...item,
          processing: dayjs(item.config.wentLiveAt).isAfter(processThreshold)
            ? { isDone: false }
            : {
                isDone: true,
                match: getPercentageMatch({
                  shopify: sumBy(item.shopifyPoints, p => p.successCount),
                  channel:
                    sumBy(item.channelPoints, p => p.successCount) +
                    sumBy(item.channelPoints, p => p.ignoredCount)
                })
              }
        };
      }),
    [showMore, data]
  );

  return (
    <>
      <ChannelSummaryInnerDestinations>
        <div>
          {augmentedData.map((item, index) => (
            <ChannelSummaryItem
              key={index}
              item={item}
              target={conversionAccuracyConfig.accuracy_target}
            />
          ))}
        </div>
        {data.length > 6 && !showMore ? (
          <div>
            <ButtonSecondary variant="SMALL" onClick={() => setShowMore(true)}>
              Show more
            </ButtonSecondary>
          </div>
        ) : null}
      </ChannelSummaryInnerDestinations>
      {whatsNextItems.length > 0 ? (
        <ChannelSummaryInnerWhatsNext>
          <div>What's Next?</div>
          <div>
            {whatsNextItems.map((item, index) => (
              <WhatsNextPageCard key={index}>
                <div>
                  <div>{item.icon}</div>
                  <div>
                    <div>{item.title}</div>
                    <div>{item.explainer}</div>
                  </div>
                </div>
                <div>
                  {item.action.isPrimary ? (
                    "to" in item.action ? (
                      <ButtonPrimaryAsLink variant="SMALL" to={item.action.to}>
                        {item.action.text}
                      </ButtonPrimaryAsLink>
                    ) : (
                      <ButtonPrimaryAsLinkExternal
                        variant="SMALL"
                        href={item.action.href}
                      >
                        {item.action.text}
                      </ButtonPrimaryAsLinkExternal>
                    )
                  ) : "to" in item.action ? (
                    <ButtonSecondaryAsLink variant="SMALL" to={item.action.to}>
                      {item.action.text}
                    </ButtonSecondaryAsLink>
                  ) : (
                    <ButtonSecondaryAsLinkExternal
                      variant="SMALL"
                      href={item.action.href}
                    >
                      {item.action.text}
                    </ButtonSecondaryAsLinkExternal>
                  )}
                </div>
              </WhatsNextPageCard>
            ))}
          </div>
        </ChannelSummaryInnerWhatsNext>
      ) : null}
    </>
  );
};

const ChannelSummaryInnerDestinations = styled.div`
  > div:first-child {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: ${props => props.theme.gridBase * 0.25}px;
    max-height: ${props => props.theme.gridBase * 64}px;
    overflow: scroll;

    @media screen and (max-width: 1550px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  > div:not(:first-child) {
    display: flex;
    justify-content: center;
    margin-top: ${props => props.theme.gridBase * 2}px;
  }
`;

const ChannelSummaryInnerWhatsNext = styled.div`
  margin-top: ${props => props.theme.gridBase * 4}px;

  > div:first-child {
    ${heading3Styles};
  }
`;

const WhatsNextPageCard = styled(PageCard)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.gridBase * 3}px;
  margin-top: ${props => props.theme.gridBase * 2}px;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: ${props => props.theme.gridBase * 3}px;

    > div:first-child {
      color: ${props => props.theme.palette.purple2};

      > svg {
        width: ${props => props.theme.gridBase * 4}px;
        height: ${props => props.theme.gridBase * 4}px;
      }
    }

    > div:last-child {
      > div:first-child {
        ${normalBodyStyles};
        font-weight: 600;
        margin-bottom: ${props => props.theme.gridBase * 0.25}px;
      }

      > div:last-child {
        ${normalBodyStyles};
        color: ${props => props.theme.palette.grey2};
      }
    }
  }

  > div:last-child {
    > a {
      white-space: nowrap;
    }
  }
`;

/* -------------------------------------------------------------------------- */

type ChannelSummaryItemProps = {
  item: AugmentedDataItem;
  target: number;
};

const ChannelSummaryItem: React.FC<ChannelSummaryItemProps> = ({
  item,
  target
}) => {
  const companyId = useCompanyId();
  const websiteId = useWebsiteId();

  const [isHovered, setIsHovered] = useState(false);
  const [showHoverOverlay, setShowHoverOverlay] = useState(false);

  useEffect(() => {
    const time = isHovered ? 150 : 0;
    const timeoutId = setTimeout(() => {
      setShowHoverOverlay(isHovered);
    }, time);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isHovered]);

  const websiteUrl = `/company/${companyId}/website/${websiteId}`;
  const conversionAccuracyUrl = `${websiteUrl}/channel-accuracy/conversions`;
  const key = item.destination.configKey;
  const id = item.config.id;
  const baseTo = `${conversionAccuracyUrl}?key=${key}&id=${id}`;
  const to = item.processing.isDone
    ? baseTo
    : `${baseTo}&monitoringTimePeriodDefault=LAST_24_HOURS`;

  return (
    <DestinationPageCard
      item={item}
      target={target}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div>
        <div>
          <div>
            <item.destination.icon size="24px" />
          </div>
          <div>
            <IconCircledInfo size="16px" />
          </div>
        </div>
        <div>
          <div>{item.title}</div>
          <div>
            {item.processing.isDone
              ? `${item.processing.match ?? "∞"}%`
              : "We're processing your data"}
          </div>
        </div>
      </div>
      <ChannelSummaryOverlay isVisible={showHoverOverlay} to={to}>
        {!item.processing.isDone ? (
          <div>
            Your data will show here 7 days after going live. Meanwhile, explore
            our 24-hour report!
          </div>
        ) : null}
        <div>View in Report</div>
      </ChannelSummaryOverlay>
    </DestinationPageCard>
  );
};

type DestinationPageCardProps = {
  item: AugmentedDataItem;
  target: number;
};

const DestinationPageCard = styled(PageCard)<DestinationPageCardProps>`
  position: relative;
  overflow: hidden;

  > div:first-child {
    > div:first-child {
      display: flex;
      justify-content: space-between;
      margin-bottom: ${props => props.theme.gridBase * 2.5}px;

      > div:first-child {
        > svg {
          width: ${props => props.theme.gridBase * 4}px;
          height: ${props => props.theme.gridBase * 4}px;
        }
      }

      > div:last-child {
        color: ${props => props.theme.palette.grey4};
      }
    }

    > div:last-child {
      > div:first-child {
        ${normalTextStyles};
        color: ${props => props.theme.palette.grey2};
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin-bottom: ${props => props.theme.gridBase}px;
      }

      > div:last-child {
        ${({ theme, item, target }) =>
          item.processing.isDone
            ? css`
              ${heading2Styles};
              color: ${getPercentageColor({
                theme,
                current: item.processing.match ?? Number.POSITIVE_INFINITY,
                target,
                context: "DASHBOARD"
              })}};
            `
            : css`
                ${largeTextStyles};
                color: ${theme.palette.grey3};
              `}
      }
    }
  }
`;

type ChannelSummaryOverlayProps = {
  isVisible: boolean;
};

const ChannelSummaryOverlay = styled(Link)<ChannelSummaryOverlayProps>`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.gridBase}px;
  text-align: center;
  background-color: ${props => props.theme.palette.grey8};
  padding: 0 ${props => props.theme.gridBase * 3}px;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition:
    visibility ${props => props.theme.other.transition},
    opacity ${props => props.theme.other.transition};

  > div:not(:last-child) {
    ${normalTextStyles};
    color: ${props => props.theme.palette.grey3};
    max-width: ${props => props.theme.gridBase * 40}px;
  }

  > div:last-child {
    ${normalTextStyles};
    font-weight: 500;
    color: ${props => props.theme.palette.purple1};
  }
`;

/* ========================================================================== */

type UpdatesProps = {
  updates: Array<WebsiteUpdate>;
};

const Updates: React.FC<UpdatesProps> = ({ updates }) => {
  const buildWebsiteUrl = useBuildWebsiteUrl();

  if (updates.length === 0) {
    return (
      <>
        <UpdateHeading>Updates</UpdateHeading>
        <UpdatesPageCard>
          <UpdatesItemWrapper>
            <UpdatesItemDescription>
              No recent updates found for this Website.
            </UpdatesItemDescription>
          </UpdatesItemWrapper>
        </UpdatesPageCard>
      </>
    );
  } else {
    return (
      <>
        <UpdateHeading>Updates</UpdateHeading>
        <UpdatesPageCard>
          {updates.map((update, index) => (
            <UpdatesItemWrapper key={index}>
              <UpdatesItemTag tagColor={update.tagColor}>
                {update.tagName}
              </UpdatesItemTag>
              <UpdatesItemTitle>{update.title}</UpdatesItemTitle>
              <UpdatesItemDescription>
                {update.description}
              </UpdatesItemDescription>
              {update.buttonText && update.buttonExternalUrl ? (
                <UpdatesItemButtonWrapper>
                  <LinkExternal
                    href={update.buttonExternalUrl}
                    onClick={() => track.dashboardUpdateCtaClick()}
                  >
                    {update.buttonText}
                  </LinkExternal>
                </UpdatesItemButtonWrapper>
              ) : update.buttonText && update.buttonInternalUrl ? (
                <UpdatesItemButtonWrapper>
                  <Link
                    to={buildWebsiteUrl(update.buttonInternalUrl)}
                    onClick={() => track.dashboardUpdateCtaClick()}
                  >
                    {update.buttonText}
                  </Link>
                </UpdatesItemButtonWrapper>
              ) : null}
            </UpdatesItemWrapper>
          ))}
        </UpdatesPageCard>
      </>
    );
  }
};

const UpdateHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const UpdatesPageCard = styled(PageCard)`
  padding: 0;
  max-height: 75vh;
  overflow: scroll;
`;

const UpdatesItemWrapper = styled.div`
  padding: ${props => props.theme.gridBase * 3}px;

  &:not(:first-child) {
    border-top: 2px solid ${props => props.theme.palette.grey8};
  }
`;

type UpdatesItemTagProps = {
  tagColor: WebsiteUpdate["tagColor"];
};

const UpdatesItemTag = styled.div<UpdatesItemTagProps>`
  ${subheadingStyles};
  color: ${props =>
    (() => {
      switch (props.tagColor) {
        case "GREY":
          return props.theme.palette.grey4;
        case "GREEN":
          return props.theme.palette.green;
        case "ORANGE":
          return props.theme.palette.orange;
      }
    })()};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const UpdatesItemTitle = styled.div`
  ${normalTextStyles};
  font-weight: 500;
  line-height: ${props => props.theme.gridBase * 3}px;
`;

const UpdatesItemDescription = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey3};
`;

const UpdatesItemButtonWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 1.25}px;

  > a {
    ${normalTextStyles};
    ${linkStyles};
  }
`;
