type IconCartHookProps = {
  size: "24px";
  className?: string;
};

export const IconCartHook: React.FC<IconCartHookProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M1.81287 18.2372V18.2396L1.88391 18.4417C1.87575 18.4182 1.84935 18.3466 1.81287 18.2372Z"
            fill="#69C9D0"
          />
          <path
            d="M0 12C0 5.40314 5.34032 0 12 0C18.6597 0 24 5.40314 24 12C24 18.5968 18.6597 24 12 24C5.34032 24 0 18.5968 0 12ZM17.7801 12C17.7801 8.733 15.1414 6.09424 11.8744 6.09424C8.6073 6.09424 5.96858 8.733 5.96858 12C5.96858 15.267 8.6073 17.9057 11.8744 17.9057C15.1414 17.9057 17.7801 15.267 17.7801 12Z"
            fill="#9BB8C6"
          />
          <path
            d="M20.3559 3.45542C15.6439 -1.13098 8.16747 -1.06815 3.51828 3.51824C-1.13093 8.16743 -1.13093 15.7696 3.51828 20.4816C8.16747 25.1308 15.6439 25.1308 20.2932 20.6072L16.0209 16.335C13.6963 18.6596 9.98952 18.6596 7.6649 16.335C5.34027 14.0104 5.34027 10.3036 7.6649 7.97895C9.92667 5.71719 13.5706 5.65437 15.8952 7.85333L20.3559 3.45542Z"
            fill="#054D70"
          />
        </svg>
      );
  }
};
