const urlRegex = new RegExp(
  String.raw`^(https?:\/\/)?` + // protocol
    String.raw`((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|` + // domain name
    String.raw`((\d{1,3}\.){3}\d{1,3}))` + // OR ip (v4) address
    String.raw`(\:\d+)?(\/[-a-z\d%_.~+]*)*` + // port and path
    String.raw`(\?[;&a-z\d%_.~+=-]*)?` + // query string
    String.raw`(\#[-a-z\d_]*)?$`, // fragment locator
  "i"
);

export const isUrl = (value: string) => {
  return urlRegex.test(value);
};

/* ========================================================================== */

const containsOnlyPunctuationOrIsEmptyRegex = new RegExp(
  /^[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~£$€¥₹₽฿\s]+$/u
);

export const isValidCompanyName = (value: string) => {
  return value !== "" && !containsOnlyPunctuationOrIsEmptyRegex.test(value);
};

// We are looking to revisit this in the future to use `isUrl` instead.
export const isValidWebsiteName = isValidCompanyName;
