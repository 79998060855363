import styled, { useTheme } from "styled-components";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { TooltipBig } from "elevar-design-system/src/Tooltip";
import {
  heading1Styles,
  largeTextStyles
} from "elevar-design-system/src/typography/typography";

/* ========================================================================== */

type PlanBannerProps = {
  title: string;
  description?: string;
  buttonDisabled?: boolean;
  buttonDisabledTooltip?: { maxWidth?: number; content: () => React.ReactNode };
  buttonOnClick: () => void;
};

export const PlanBanner: React.FC<PlanBannerProps> = ({
  title,
  description,
  buttonDisabled = false,
  buttonDisabledTooltip,
  buttonOnClick
}) => {
  return (
    <PlanBannerWrapper>
      <div>
        <PlanBannerTitle>{title}</PlanBannerTitle>
        {description ? (
          <PlanBannerDescription>{description}</PlanBannerDescription>
        ) : null}
        <TooltipBig
          placement="top"
          disabled={!buttonDisabled || !buttonDisabledTooltip}
          maxWidth={
            buttonDisabledTooltip?.maxWidth
              ? `${buttonDisabledTooltip.maxWidth}px`
              : undefined
          }
          render={buttonDisabledTooltip?.content ?? (() => null)}
        >
          <span>
            <PlanBannerButton
              variant="LARGE"
              state={buttonDisabled ? "DISABLED" : "IDLE"}
              onClick={buttonOnClick}
            >
              Continue with Custom Plan
            </PlanBannerButton>
          </span>
        </TooltipBig>
      </div>
      <div>
        <PlanPickerVisualsWrapper>
          <div>
            <PlanBannerVisual1 />
            <PlanBannerVisual2 />
            <PlanBannerVisual3 />
            <PlanBannerVisual4 />
          </div>
          <div>
            <PlanBannerVisual3 />
            <PlanBannerVisual4 />
            <PlanBannerVisual5 />
          </div>
          <div>
            <PlanBannerVisual4 />
            <PlanBannerVisual5 />
            <PlanBannerVisual6 />
            <PlanBannerVisual1 />
          </div>
        </PlanPickerVisualsWrapper>
      </div>
    </PlanBannerWrapper>
  );
};

/* ========================================================================== */

const PlanBannerWrapper = styled.div`
  padding-top: ${props => props.theme.gridBase * 5.5}px;
  padding-bottom: ${props => props.theme.gridBase * 5.5}px;
  padding-left: ${props => props.theme.gridBase * 5}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
  background: ${props => props.theme.other.gradientLeft};
  border-radius: 4px;
  position: relative;
`;

const PlanBannerTitle = styled.div`
  ${heading1Styles};
  color: ${props => props.theme.palette.white};
`;

const PlanBannerDescription = styled.div`
  ${largeTextStyles};
  color: ${props => props.theme.palette.white};
  max-width: ${props => props.theme.gridBase * 75}px;
  margin-top: ${props => props.theme.gridBase * 2}px;
`;

const PlanBannerButton = styled(ButtonPrimary)`
  margin-top: ${props => props.theme.gridBase * 3}px;
`;

const PlanPickerVisualsWrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.gridBase * -2}px;
  right: ${props => props.theme.gridBase * 2.5}px;
  bottom: ${props => props.theme.gridBase * -2.75}px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${props => props.theme.gridBase * 5}px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.gridBase * 5}px;
    opacity: 0.5;
  }
`;

/* ========================================================================== */

const PlanBannerVisual1: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.5">
        <path
          d="M36 16C36 17.2 33.8 18.3 30.2 19.1C27.4 19.6 23.9 20 20 20C16.1 20 12.6 19.6 9.8 19.1C6.2 18.3 4 17.2 4 16C4 18 11.3 25.5 16 30.1V36.2C16 36.7 16.3 37.1 16.8 37.2L22.8 38.7C23.4 38.9 24 38.4 24 37.7V30.1C28.7 25.5 36 18 36 16Z"
          fill={theme.palette.white}
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.109 19.4327C28.8422 19.33 29.5405 19.2178 30.2 19.1C33.8 18.3 36 17.2 36 16C36 14.9 34.3 13.9 31.5 13.2C31.3207 13.1567 31.1367 13.1142 30.9484 13.0725C30.7121 15.5142 29.6776 17.7223 28.109 19.4327ZM11.891 19.4327C10.319 17.7186 9.28337 15.5046 9.05009 13.0565C8.86228 13.1028 8.67886 13.1507 8.5 13.2C5.7 13.9 4 14.9 4 16C4 17.2 6.2 18.3 9.8 19.1C10.4595 19.2178 11.1578 19.33 11.891 19.4327Z"
          fill={theme.palette.white}
        />
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 23C26.0751 23 31 18.0751 31 12C31 5.92487 26.0751 1 20 1C13.9249 1 9 5.92487 9 12C9 18.0751 13.9249 23 20 23ZM20 5.5C20.2761 5.5 20.5 5.72386 20.5 6V7.7H22.7C22.9761 7.7 23.2 7.92386 23.2 8.2C23.2 8.47614 22.9761 8.7 22.7 8.7H20.5V11.5H21.4C22.7104 11.5 23.7 12.6594 23.7 13.9C23.7 15.1406 22.7104 16.3 21.4 16.3H20.5V18C20.5 18.2761 20.2761 18.5 20 18.5C19.7239 18.5 19.5 18.2761 19.5 18V16.3H17C16.7239 16.3 16.5 16.0761 16.5 15.8C16.5 15.5239 16.7239 15.3 17 15.3H19.5V12.5H18.8C17.4896 12.5 16.5 11.3406 16.5 10.1C16.5 8.8594 17.4896 7.7 18.8 7.7H19.5V6C19.5 5.72386 19.7239 5.5 20 5.5ZM19.5 8.7H18.8C18.1104 8.7 17.5 9.3406 17.5 10.1C17.5 10.8594 18.1104 11.5 18.8 11.5H19.5V8.7ZM20.5 12.5V15.3H21.4C22.0896 15.3 22.7 14.6594 22.7 13.9C22.7 13.1406 22.0896 12.5 21.4 12.5H20.5Z"
          fill={theme.palette.white}
        />
      </g>
    </svg>
  );
};

const PlanBannerVisual2: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.5">
        <path
          opacity="0.6"
          d="M33.75 18.75C35.1307 18.75 36.25 17.6307 36.25 16.25C36.25 14.8693 35.1307 13.75 33.75 13.75C32.3693 13.75 31.25 14.8693 31.25 16.25C31.25 17.6307 32.3693 18.75 33.75 18.75Z"
          fill={theme.palette.white}
        />
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.53425 28.2405L8.49986 28.25C5.87486 28.875 3.12486 27.375 2.37486 24.75C1.62486 22.125 3.24986 19.375 5.87486 18.625L9.49986 17.625C10.1249 17.5 10.8749 17.875 10.9999 18.5L12.6613 24.5227L12.75 24.5L15 33C15.125 33.625 14.75 34.25 14 34.5L11.625 35.125C11 35.25 10.25 34.875 10.125 34.25L8.53425 28.2405Z"
          fill={theme.palette.white}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3752 26.625L35.5002 29.25C36.3752 29.375 37.0002 28.625 36.8752 27.75L30.7502 4.75001C30.5002 3.87501 29.5002 3.62501 28.7502 4.12501L12.7502 16.375C12.2502 16.75 12.1252 17.25 12.2502 17.75L14.3752 25.75C14.5002 26.25 14.8752 26.5 15.3752 26.625ZM28.4373 8.51543C28.653 8.34293 28.6879 8.02828 28.5154 7.81265C28.3429 7.59702 28.0283 7.56206 27.8127 7.73457L15.3127 17.7346C15.097 17.9071 15.0621 18.2217 15.2346 18.4373C15.4071 18.653 15.7217 18.6879 15.9373 18.5154L28.4373 8.51543Z"
          fill={theme.palette.white}
        />
      </g>
    </svg>
  );
};

const PlanBannerVisual3: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.5">
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3 24H8.7C6.1 24 4 21.9 4 19.3V9.7C4 7.1 6.1 5 8.7 5H24.3C26.9 5 29 7.1 29 9.7V19.3C29 21.9 26.9 24 24.3 24ZM11 14.6V14.5C11 14.3 11.2 14.1 11.4 14.1H16.0998V9.4C15.9998 9.2 16.1998 9 16.3998 9H16.4998C16.7998 9 16.9998 9.2 16.9998 9.4V14.1H21.5C21.7 14.1 21.9 14.3 21.9 14.5V14.6C21.9 14.8 21.7 15 21.5 15H16.9998V19.5C16.9998 19.7 16.7998 19.9 16.5998 19.9H16.4998C16.2998 19.9 16.0998 19.7 16.0998 19.5V15H11.4C11.2 15 11 14.8 11 14.6Z"
          fill={theme.palette.white}
        />
        <path
          d="M24.7004 36.2L18.4004 17.8C18.1004 17 18.9004 16.3 19.7004 16.5L38.0004 22.8C38.9004 23.1 38.9004 24.3 38.1004 24.7L30.4004 27.8C30.2004 27.9 30.0004 28.1 29.9004 28.3L26.6004 36.2C26.2004 37.1 25.0004 37.1 24.7004 36.2Z"
          fill={theme.palette.white}
        />
        <path
          opacity="0.3"
          d="M8 3C4.7 3 2 5.7 2 9"
          stroke={theme.palette.white}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.3"
          d="M2 20C2 23.3 4.7 26 8 26"
          stroke={theme.palette.white}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.3"
          d="M31 9C31 5.7 28.3 3 25 3"
          stroke={theme.palette.white}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

const PlanBannerVisual4: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.5">
        <path
          opacity="0.3"
          d="M2.62538 11.25H10.0004C10.7504 11.25 11.2504 10.75 11.2504 10V2.625C11.2504 1.875 10.6254 1.25 9.87538 1.375C5.50038 2 2.00038 5.5 1.37538 9.875C1.25038 10.625 1.87538 11.25 2.62538 11.25Z"
          fill={theme.palette.white}
        />
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5 38.75C36 38.75 38.75 36 38.75 32.5V16.25C38.75 12.75 35.875 10 32.5 10H18.5C13.75 10 10 13.75 10 18.5V32.5C10 36 12.875 38.75 16.25 38.75H32.5ZM32.375 21.875C32.375 21.5989 32.1511 21.375 31.875 21.375C31.5989 21.375 31.375 21.5989 31.375 21.875V33.125C31.375 33.4011 31.5989 33.625 31.875 33.625C32.1511 33.625 32.375 33.4011 32.375 33.125V21.875ZM24.375 15.125C24.6511 15.125 24.875 15.3489 24.875 15.625V33.125C24.875 33.4011 24.6511 33.625 24.375 33.625C24.0989 33.625 23.875 33.4011 23.875 33.125V15.625C23.875 15.3489 24.0989 15.125 24.375 15.125ZM17.375 26.875C17.375 26.5989 17.1511 26.375 16.875 26.375C16.5989 26.375 16.375 26.5989 16.375 26.875V33.125C16.375 33.4011 16.5989 33.625 16.875 33.625C17.1511 33.625 17.375 33.4011 17.375 33.125V26.875Z"
          fill={theme.palette.white}
        />
        <path
          d="M2.62538 13.875C3.37538 19.375 8.62538 23.375 14.5004 22.25C18.3754 21.5 21.5004 18.375 22.2504 14.5C23.3754 8.625 19.3754 3.375 13.8754 2.625C13.1254 2.5 12.5004 3.125 12.5004 3.875V11.25C12.5004 12 12.0004 12.5 11.2504 12.5H3.87538C3.12538 12.5 2.50038 13.125 2.62538 13.875Z"
          fill={theme.palette.white}
        />
      </g>
    </svg>
  );
};

const PlanBannerVisual5: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.5">
        <path
          d="M23.7001 38.2L11.5001 26.7C10.8001 26.1 10.8001 25.1 11.4001 24.4L11.9001 23.9C12.5001 23.2 13.5001 23.2 14.2001 23.8L26.4001 35.2C27.1001 35.8 27.1001 36.8 26.5001 37.5L26.0001 38.1C25.4001 38.8 24.4001 38.8 23.7001 38.2Z"
          fill={theme.palette.white}
        />
        <path
          opacity="0.6"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8 27H33.2C36.4 27 39 24.4 39 21.1V9.8C39 6.6 36.4 4 33.1 4H12.8C9.6 4 7 6.6 7 9.8V21.2C7 24.4 9.6 27 12.8 27ZM24.5574 9.29799C24.4771 9.11628 24.2969 8.99931 24.0983 9C23.8996 9.0007 23.7202 9.11892 23.6412 9.3012L21.1717 15H18C17.7239 15 17.5 15.2239 17.5 15.5C17.5 15.7761 17.7239 16 18 16H21.5C21.6993 16 21.8795 15.8817 21.9588 15.6988L24.1044 10.7475L28.9426 21.702C29.0229 21.8837 29.2031 22.0007 29.4017 22C29.6004 21.9993 29.7798 21.8811 29.8588 21.6988L32.3283 16H35.5C35.7761 16 36 15.7761 36 15.5C36 15.2239 35.7761 15 35.5 15H32C31.8007 15 31.6205 15.1183 31.5412 15.3012L29.3956 20.2525L24.5574 9.29799Z"
          fill={theme.palette.white}
        />
        <path
          d="M11.0004 33.6C16.8546 33.6 21.6004 28.8542 21.6004 23C21.6004 17.1458 16.8546 12.4 11.0004 12.4C5.14617 12.4 0.400391 17.1458 0.400391 23C0.400391 28.8542 5.14617 33.6 11.0004 33.6Z"
          fill={theme.palette.white}
        />
        <path
          d="M21.4 21H13C12.7 21 12.5 20.8 12.5 20.5C12.5 20.2 12.7 20 13 20H21.2C21.3 20.3 21.4 20.7 21.4 21Z"
          fill={theme.palette.white}
        />
      </g>
    </svg>
  );
};

const PlanBannerVisual6: React.FC = () => {
  const theme = useTheme();

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 34C4.8 34 3 32.2 3 30C3 27.8 4.8 26 7 26H33C35.2 26 37 27.8 37 30C37 32.2 35.2 34 33 34H7ZM6.5 30C6.5 29.7239 6.72386 29.5 7 29.5H20C20.2761 29.5 20.5 29.7239 20.5 30C20.5 30.2761 20.2761 30.5 20 30.5H7C6.72386 30.5 6.5 30.2761 6.5 30ZM32 30C32 30.5523 31.5523 31 31 31C30.4477 31 30 30.5523 30 30C30 29.4477 30.4477 29 31 29C31.5523 29 32 29.4477 32 30Z"
          fill={theme.palette.white}
        />
        <path
          opacity="0.6"
          d="M33 24H7C4.8 24 3 22.2 3 20C3 17.8 4.8 16 7 16H33C35.2 16 37 17.8 37 20C37 22.2 35.2 24 33 24Z"
          fill={theme.palette.white}
        />
        <path
          d="M31 21C31.5523 21 32 20.5523 32 20C32 19.4477 31.5523 19 31 19C30.4477 19 30 19.4477 30 20C30 20.5523 30.4477 21 31 21Z"
          fill={theme.palette.white}
        />
        <path
          d="M7 20H20"
          stroke={theme.palette.white}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          opacity="0.3"
          d="M33 14H7C4.8 14 3 12.2 3 10C3 7.8 4.8 6 7 6H33C35.2 6 37 7.8 37 10C37 12.2 35.2 14 33 14Z"
          fill={theme.palette.white}
        />
        <path
          d="M31 11C31.5523 11 32 10.5523 32 10C32 9.44772 31.5523 9 31 9C30.4477 9 30 9.44772 30 10C30 10.5523 30.4477 11 31 11Z"
          fill={theme.palette.white}
        />
        <path
          d="M7 10H20"
          stroke={theme.palette.white}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
