type IconImageProps = {
  size: "16px";
  color?: string;
  className?: string;
};

export const IconImage: React.FC<IconImageProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M12.6667 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.403 2.59695 14 3.33333 14H12.6667C13.403 14 14 13.403 14 12.6667V3.33333C14 2.59695 13.403 2 12.6667 2Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.66663 6.66669C6.21891 6.66669 6.66663 6.21897 6.66663 5.66669C6.66663 5.1144 6.21891 4.66669 5.66663 4.66669C5.11434 4.66669 4.66663 5.1144 4.66663 5.66669C4.66663 6.21897 5.11434 6.66669 5.66663 6.66669Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 10L10.6667 6.66669L3.33337 14"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
