import dayjs, { type Dayjs } from "dayjs";
import { createContext, useContext, useMemo } from "react";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

/* ========================================================================== */

type TrialState = "ELIGIBLE" | "NOT_ELIGIBLE" | "IN_PROGRESS";

type TrialDetails = {
  trialEndDate: Dayjs;
  formattedTrialEndDate: string;
  trialState: TrialState;
};

const trialDetailsContext = createContext<TrialDetails | undefined>(undefined);

/* ========================================================================== */

type TrialDetailsProviderProps = {
  websiteDetails: WebsiteDetails;
  children: React.ReactNode;
};

export const TrialDetailsProvider: React.FC<TrialDetailsProviderProps> = ({
  websiteDetails,
  children
}) => {
  const trialEndDate = dayjs(websiteDetails.trial_end);

  const dateToFormat = trialEndDate.isValid()
    ? trialEndDate
    : dayjs().add(15, "days");
  const formattedTrialEndDate = dateToFormat.format("MMM DD YYYY");

  const trialState: TrialState = trialEndDate.isValid()
    ? dayjs().isAfter(trialEndDate)
      ? "NOT_ELIGIBLE"
      : "IN_PROGRESS"
    : "ELIGIBLE";

  const value = useMemo(
    () => ({ trialState, trialEndDate, formattedTrialEndDate }),
    [trialState, trialEndDate, formattedTrialEndDate]
  );

  return (
    <trialDetailsContext.Provider value={value}>
      {children}
    </trialDetailsContext.Provider>
  );
};

/* ========================================================================== */

export const useTrialDetails = () => {
  const trialDetails = useContext(trialDetailsContext);

  if (trialDetails !== undefined) {
    return trialDetails;
  } else {
    throw new Error("`useTrialDetails`: value not set");
  }
};
