import { darken, transparentize } from "polished";
import { Link } from "react-router-dom";
import styled, { css, type DefaultTheme, useTheme } from "styled-components";

import { LinkExternal } from "../links/LinkExternal";
import { ButtonBase, type ButtonBaseProps } from "./ButtonBase";
import {
  baseButtonStyles,
  largeButtonStyles,
  smallButtonStyles
} from "./buttonStyles";

/* ========================================================================== */

type ButtonVariantProps = Omit<ButtonBaseProps, "colors">;

const getButtonBaseAsLinkStyles = (
  colors: ButtonBaseProps["colors"],
  variant: ButtonBaseProps["variant"]
) => {
  return css`
    ${baseButtonStyles};
    ${variant === "SMALL" && smallButtonStyles};
    ${variant === "LARGE" && largeButtonStyles};

    background-color: ${colors.normalBackground};
    color: ${colors.normalText};
    width: fit-content;

    &:hover {
      background-color: ${colors.hoveredBackground};
      color: ${colors.hoveredText};
    }
  `;
};

/* ========================================================================== */

const getPrimaryButtonColors = (
  palette: DefaultTheme["palette"]
): ButtonBaseProps["colors"] => {
  return {
    normalText: palette.white,
    normalBackground: palette.purple2,
    hoveredText: palette.white,
    hoveredBackground: palette.purple1,
    disabledText: palette.white,
    disabledBackground: palette.grey5
  };
};

export const ButtonPrimary: React.FC<ButtonVariantProps> = props => {
  const theme = useTheme();
  const colors = getPrimaryButtonColors(theme.palette);
  return <ButtonBase colors={colors} {...props} />;
};

export const ButtonPrimaryAsLink = styled(Link)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getPrimaryButtonColors(props.theme.palette),
      props.variant
    )}
`;

export const ButtonPrimaryAsLinkExternal = styled(LinkExternal)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getPrimaryButtonColors(props.theme.palette),
      props.variant
    )}
`;

/* ========================================================================== */

const getSecondaryButtonColors = (
  palette: DefaultTheme["palette"]
): ButtonBaseProps["colors"] => {
  return {
    normalText: palette.purple2,
    normalBackground: `${palette.purple2}14`, // 8% opacity
    hoveredText: palette.purple2,
    hoveredBackground: `${palette.purple2}29`, // 16% opacity
    disabledText: palette.grey4,
    disabledBackground: palette.grey8
  };
};

export const ButtonSecondary: React.FC<ButtonVariantProps> = props => {
  const theme = useTheme();
  const colors = getSecondaryButtonColors(theme.palette);
  return <ButtonBase colors={colors} {...props} />;
};

export const ButtonSecondaryAsLink = styled(Link)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getSecondaryButtonColors(props.theme.palette),
      props.variant
    )}
`;

export const ButtonSecondaryAsLinkExternal = styled(LinkExternal)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getSecondaryButtonColors(props.theme.palette),
      props.variant
    )}
`;

/* ========================================================================== */

const getWarningButtonColors = (
  palette: DefaultTheme["palette"]
): ButtonBaseProps["colors"] => {
  return {
    normalText: palette.white,
    normalBackground: palette.red1,
    hoveredText: palette.white,
    hoveredBackground: darken(0.1, palette.red1),
    disabledText: palette.white,
    disabledBackground: transparentize(0.5, palette.red1)
  };
};

export const ButtonWarning: React.FC<ButtonVariantProps> = props => {
  const theme = useTheme();
  const colors = getWarningButtonColors(theme.palette);
  return <ButtonBase colors={colors} {...props} />;
};

export const ButtonWarningAsLink = styled(Link)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getWarningButtonColors(props.theme.palette),
      props.variant
    )}
`;

export const ButtonWarningAsLinkExternal = styled(LinkExternal)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getWarningButtonColors(props.theme.palette),
      props.variant
    )}
`;

/* ========================================================================== */

const getWhiteButtonColors = (
  palette: DefaultTheme["palette"]
): ButtonBaseProps["colors"] => {
  return {
    normalText: palette.purple2,
    normalBackground: palette.white,
    hoveredText: palette.purple1,
    hoveredBackground: palette.white,
    disabledText: palette.grey4,
    disabledBackground: palette.white
  };
};

export const ButtonWhite: React.FC<ButtonVariantProps> = props => {
  const theme = useTheme();
  const colors = getWhiteButtonColors(theme.palette);
  return <ButtonBase colors={colors} {...props} />;
};

export const ButtonWhiteAsLink = styled(Link)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getWhiteButtonColors(props.theme.palette),
      props.variant
    )}
`;

export const ButtonWhiteAsLinkExternal = styled(LinkExternal)<{
  variant: ButtonBaseProps["variant"];
}>`
  ${props =>
    getButtonBaseAsLinkStyles(
      getWhiteButtonColors(props.theme.palette),
      props.variant
    )}
`;
