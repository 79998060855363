type IconDollarSignProps = {
  size: "16px";
  color?: string;
  className?: string;
};

export const IconDollarSign: React.FC<IconDollarSignProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8 0.666687V15.3334"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.3333 3.33331H6.33333C5.71449 3.33331 5.121 3.57915 4.68342 4.01673C4.24583 4.45432 4 5.04781 4 5.66665C4 6.28548 4.24583 6.87898 4.68342 7.31656C5.121 7.75415 5.71449 7.99998 6.33333 7.99998H9.66667C10.2855 7.99998 10.879 8.24581 11.3166 8.6834C11.7542 9.12098 12 9.71447 12 10.3333C12 10.9522 11.7542 11.5456 11.3166 11.9832C10.879 12.4208 10.2855 12.6666 9.66667 12.6666H4"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
