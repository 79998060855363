type IconOneTrustProps = {
  size: "24px";
  className?: string;
};

export const IconOneTrust: React.FC<IconOneTrustProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect width="24" height="24" fill="#55A05E" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.2345 16.8378C18.5463 16.8378 18.2966 16.5875 18.2966 15.8973V15.8966V9.2599H21V7.77359H18.2966V5H16.4303C16.4303 5.06148 16.4208 5.55459 16.4018 5.92474C16.3828 6.29679 16.3542 6.608 16.3169 6.85772C16.2649 7.31027 16.1686 7.60943 16.0247 7.75331C15.879 7.89972 15.5868 7.98465 15.1483 8.00811C14.9715 8.02268 14.7142 8.03219 14.3752 8.03663C14.2923 8.03855 14.2538 8.04012 14.2254 8.04128C14.1884 8.04279 14.1685 8.0436 14.09 8.0436V9.26053H16.185V10.9509H16.1876V15.9308C16.1876 17.3924 17.0241 18.2265 18.4848 18.2265H21V16.8486L19.2345 16.8378ZM8.27313 16.9855C6.53992 16.9855 5.40177 15.4707 5.40177 12.9956C5.40177 10.3425 6.52915 8.98484 8.27313 8.98484C10.0171 8.98484 11.0925 10.3425 11.0925 12.9956C11.0925 15.4713 10.0063 16.9855 8.27313 16.9855ZM8.25285 18.4268C11.1869 18.4268 13.4949 16.1914 13.4949 12.9956V12.9963C13.4949 9.79993 11.1559 7.56508 8.25285 7.56508C5.34981 7.56508 3 9.79929 3 12.9956C3 16.192 5.31876 18.4268 8.25285 18.4268Z"
            fill="white"
          />
        </svg>
      );
  }
};
