import {
  type ContentType,
  type ConversionValue,
  type OrderAttributeId,
  type ProductAttributeMapping
} from "elevar-common-ts/src/apiTypes";

import { type Option } from "elevar-design-system/src/inputs/InputFieldSelect";

/* ========================================================================== */

export const getContentTypeDisplayName = (value: ContentType) => {
  switch (value) {
    case "product":
      return "Product";
    case "product_group":
      return "Product Group";
  }
};

export const getTransactionIdentifierDisplayName = (
  value: OrderAttributeId
) => {
  switch (value) {
    case "id":
      return "Order ID";
    case "name":
      return "Order Name";
    case "order_number":
      return "Order Number";
  }
};

export const getProductIdentifierDisplayName = (
  value: ProductAttributeMapping
) => {
  switch (value) {
    case "sku":
      return "Product SKU";
    case "product_id":
      return "Product ID";
    case "variant_id":
      return "Variant ID";
  }
};

/* ========================================================================== */

export const conversionValueOptions: Array<Option<ConversionValue>> = [
  {
    name: "Subtotal (Includes Discounts. Excludes Taxes & Shipping)",
    value: "subtotal"
  },
  {
    name: "Revenue (Includes Taxes, Shipping, & Discounts)",
    value: "revenue"
  },
  {
    name: "Product Subtotal (Excludes Taxes & Shipping)",
    value: "product_subtotal"
  }
];

/* ========================================================================== */

export const firstTimeSubscriptionTagName = "Subscription First Order";
export const recurringSubscriptionTagName = "Subscription Recurring Order";

/* ========================================================================== */

const numbersRegex = /^\d+$/;
export const containsNumbersOnly = (v: string) => numbersRegex.test(v);
