import { useMemo } from "react";
import ReactPlayer, { type ReactPlayerProps } from "react-player";
import styled from "styled-components";
import { type OmitIndexSignature } from "type-fest";

/* ========================================================================== */

type VideoPlayerProps = {
  className?: string;
} & Pick<OmitIndexSignature<ReactPlayerProps>, "url" | "playing" | "onStart">;

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  className,
  url,
  playing,
  onStart
}) => {
  // Re-renders make vimeo flicker - this eliminates unnecessary re-renders.
  return useMemo(
    () => (
      <VideoPlayerWrapper className={className}>
        <div>
          <ReactPlayer
            width="100%"
            height="100%"
            className="react-player"
            controls={true}
            url={url}
            playing={playing}
            onStart={onStart}
          />
        </div>
      </VideoPlayerWrapper>
    ),
    [className, url, playing, onStart]
  );
};

/* ========================================================================== */

const VideoPlayerWrapper = styled.div`
  > div {
    position: relative;
    padding-bottom: 56.25%;
    border-radius: 4px;
    overflow: hidden;
    background-color: ${props => props.theme.palette.grey8};

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
