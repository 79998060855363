type IconMonitorProps = {
  size: "24px";
  color?: string;
  className?: string;
};

export const IconMonitor: React.FC<IconMonitorProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.5 16H3.8C2.80589 16 2 15.2325 2 14.2857V5.71429C2 4.76751 2.80589 4 3.8 4H18.2C19.1941 4 20 4.76751 20 5.71429V9"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 20H15"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11 16V20"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 9L17.3329 17L18.5162 13.5162L22 12.3329L14 9Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
