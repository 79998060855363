import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  InputFieldSelect,
  type Option
} from "elevar-design-system/src/inputs/InputFieldSelect";
import { InputWrapper } from "elevar-design-system/src/inputs/InputWrapper";
import { ElevarLogo } from "elevar-design-system/src/logos";
import { Option as OptionComponent } from "elevar-design-system/src/Option";
import { Spinner } from "elevar-design-system/src/Spinner";
import {
  heading1Styles,
  largeTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  type AccountCompanyList,
  useAccountCompanyListQuery
} from "../../api/handlers/account";
import {
  shopifyFinalize,
  shopifyLinkWebsite
} from "../../api/handlers/shopify";
import { setApiAuthToken } from "../../api/utils";
import { UserButtonDropdown } from "../../components/UserButtonDropdown";
import { TitleProvider } from "../../context/Title";
import { useUserOptional, useUserRequired } from "../../context/User";
import { LargeViewportOnly } from "../../LargeViewportOnly";
import { toast } from "../../utils/toast";
import { track } from "../../utils/track";
import {
  companyIdStorageKey,
  websiteIdStorageKey
} from "../myTracking/SourceShopify";

/* ========================================================================== */

type FinalizeState = "FINALIZE_LOADING" | "LINK_WEBSITE_PROMPT" | "ERROR";

type ActOnWebsite = (website: WebsiteDetails, isAuth: boolean) => void;

export const ShopifyFlowFinalize: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { checkIsLoggedIn } = useUserOptional();

  const [state, setState] = useState<FinalizeState>("FINALIZE_LOADING");

  const paramEntries = new URLSearchParams(location.search).entries();
  const params = Object.fromEntries(paramEntries);

  const actOnWebsite: ActOnWebsite = useCallback(
    (website, isAuth) => {
      const companyId = website.company;
      const websiteId = website.id;
      const websiteUrl = `/company/${companyId}/website/${websiteId}`;

      history.replace(`${websiteUrl}/my-tracking/source-shopify`);
      toast.success("Connection successful");
      track.connectionsShopifySuccess({ websiteId });

      if (!website.has_previously_subscribed && !isAuth) {
        track.shopifyOAuthChoosePlan();
      }
    },
    [history]
  );

  const hasEffectRun = useRef(false);

  useEffect(() => {
    const finalize = async () => {
      try {
        const result = await shopifyFinalize({ params });

        if ("redirect_url" in result) {
          window.location.replace(result.redirect_url);
        } else {
          setApiAuthToken(result.token);
          await checkIsLoggedIn();

          if (result.website) {
            actOnWebsite(result.website, result.is_auth);
          } else {
            setState("LINK_WEBSITE_PROMPT");
          }
        }
      } catch (error) {
        setState("ERROR");
        toast.errorUnexpected(error);
      }
    };

    if (!hasEffectRun.current) {
      void finalize();
    }

    return () => {
      hasEffectRun.current = true;
    };
  }, [checkIsLoggedIn, actOnWebsite, params]);

  switch (state) {
    case "FINALIZE_LOADING": {
      return (
        <CenteredWrapper>
          <Spinner size="24px" />
        </CenteredWrapper>
      );
    }
    case "LINK_WEBSITE_PROMPT": {
      return (
        <TitleProvider page="Select Website">
          <LargeViewportOnly showLogout={true}>
            <SelectWebsiteWrapper>
              <SelectWebsiteWithData
                params={params}
                actOnWebsite={actOnWebsite}
              />
            </SelectWebsiteWrapper>
          </LargeViewportOnly>
        </TitleProvider>
      );
    }
    case "ERROR": {
      return (
        <CenteredWrapper>
          <ErrorOccurred />
        </CenteredWrapper>
      );
    }
  }
};

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectWebsiteWrapper = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.white};
`;

/* ========================================================================== */

type SelectWebsiteWithDataProps = {
  params: Record<string, string>;
  actOnWebsite: ActOnWebsite;
};

const SelectWebsiteWithData: React.FC<SelectWebsiteWithDataProps> = ({
  params,
  actOnWebsite
}) => {
  const accountCompanyList = useAccountCompanyListQuery();

  if (accountCompanyList.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (accountCompanyList.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <SelectWebsiteInner
      params={params}
      actOnWebsite={actOnWebsite}
      accountCompanyList={accountCompanyList.data}
    />
  );
};

/* ========================================================================== */

const getCompanyId = () => {
  const companyId = Number(localStorage.getItem(companyIdStorageKey));
  return Number.isNaN(companyId) ? null : companyId;
};

const getWebsiteId = () => {
  const websiteId = Number(localStorage.getItem(websiteIdStorageKey));
  return Number.isNaN(websiteId) ? null : websiteId;
};

type SelectWebsiteInnerState = "EXISTING" | "NEW";

const option1 = { name: "Existing Website", value: "EXISTING" } as const;
const option2 = { name: "New Website", value: "NEW" } as const;

type SelectWebsiteInnerProps = {
  params: Record<string, string>;
  actOnWebsite: ActOnWebsite;
  accountCompanyList: AccountCompanyList;
};

const SelectWebsiteInner: React.FC<SelectWebsiteInnerProps> = ({
  params,
  actOnWebsite,
  accountCompanyList
}) => {
  const { accountDetails } = useUserRequired();

  const [state, setState] = useState<SelectWebsiteInnerState>("EXISTING");
  const [companyId, setCompanyId] = useState(getCompanyId);
  const [websiteId, setWebsiteId] = useState(getWebsiteId);
  const [isLoading, setIsLoading] = useState(false);

  const filteredAccountCompanyList = accountCompanyList
    .map(c => ({ ...c, websites: c.websites.filter(w => !w.has_shopify_shop) }))
    .filter(c => c.websites.length > 0);

  const companyOptions = filteredAccountCompanyList
    .map<Option<number>>(c => ({ name: c.name, value: c.id }))
    .sort((c1, c2) => c1.name.localeCompare(c2.name));

  const websiteOptions =
    filteredAccountCompanyList
      .find(c => c.id === companyId)
      ?.websites.map<Option<number>>(w => ({ name: w.name, value: w.id }))
      .sort((w1, w2) => w1.name.localeCompare(w2.name)) ?? [];

  const activeCompanyOption =
    companyOptions.find(o => o.value === companyId) ?? null;

  const activeWebsiteOption =
    websiteOptions.find(o => o.value === websiteId) ?? null;

  const companyFieldDisabled = isLoading;

  const websiteFieldDisabled =
    companyFieldDisabled || activeCompanyOption === null;

  return (
    <>
      <LogoWrapper>
        <ElevarLogo />
      </LogoWrapper>
      <UserButtonDropdownWrapper>
        <UserButtonDropdown
          userImage={accountDetails.profile.picture}
          userName={`${accountDetails.first_name} ${accountDetails.last_name}`}
          showAccountSettingsLink={false}
        />
      </UserButtonDropdownWrapper>
      <MainPageContentWrapper>
        <PageHeading>Select Website</PageHeading>
        <PageExplainer1>
          Welcome back! Please select an existing Elevar Website to connect this
          Shopify Store to, or create a new one.
        </PageExplainer1>
        <PageOptionWrapper>
          <OptionComponent
            variant="LARGE"
            firstOption={option1}
            secondOption={option2}
            activeOption={state === "EXISTING" ? option1 : option2}
            setActiveOption={option => setState(option.value)}
          />
        </PageOptionWrapper>
        {state === "EXISTING" ? (
          <>
            <PageInputWrapper
              labelText="Company"
              disabled={companyFieldDisabled}
            >
              <InputFieldSelect
                variant="LARGE"
                disabled={companyFieldDisabled}
                value={activeCompanyOption}
                setValue={option => {
                  setCompanyId(option.value);
                  setWebsiteId(null);
                }}
                options={companyOptions}
                placeholder="Select the Company"
              />
            </PageInputWrapper>
            <PageInputWrapper
              labelText="Website"
              disabled={websiteFieldDisabled}
            >
              <InputFieldSelect
                variant="LARGE"
                disabled={websiteFieldDisabled}
                value={activeWebsiteOption}
                setValue={option => setWebsiteId(option.value)}
                options={websiteOptions}
                placeholder="Select the Website"
              />
            </PageInputWrapper>
          </>
        ) : (
          <PageExplainer2>
            Note that by selecting "New Website", we will create a new Website
            that belongs to a new Company, and you will be made the owner of
            this Company.
          </PageExplainer2>
        )}
        <ButtonPrimary
          variant="LARGE"
          state={
            isLoading
              ? "LOADING"
              : state === "EXISTING" && websiteId === null
                ? "DISABLED"
                : "IDLE"
          }
          onClick={async () => {
            setIsLoading(true);

            const newParams = {
              ...params,
              ...(state === "EXISTING" && websiteId !== null
                ? { website: String(websiteId) }
                : {})
            };

            const result = await shopifyLinkWebsite({ params: newParams });

            if ("redirect_url" in result) {
              window.location.replace(result.redirect_url);
            } else {
              actOnWebsite(result.website, result.is_auth);
            }
          }}
        >
          Continue
        </ButtonPrimary>
      </MainPageContentWrapper>
    </>
  );
};

const LogoWrapper = styled.div`
  position: fixed;
  top: ${props => props.theme.gridBase * 4}px;
  left: ${props => props.theme.gridBase * 4}px;
`;

const UserButtonDropdownWrapper = styled.div`
  position: fixed;
  bottom: ${props => props.theme.gridBase * 2.45}px;
  left: ${props => props.theme.gridBase * 3}px;
`;

const MainPageContentWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.theme.gridBase * 50}px;
  margin: 0 auto;
  padding-top: ${props => props.theme.gridBase * 11.25}px;
  padding-bottom: ${props => props.theme.gridBase * 6}px;
`;

const PageHeading = styled.h1`
  ${heading1Styles};
  margin-bottom: ${props => props.theme.gridBase * 1.5}px;
`;

const PageExplainer1 = styled.div`
  ${largeTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const PageOptionWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageInputWrapper = styled(InputWrapper)`
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const PageExplainer2 = styled.div`
  ${largeTextStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;
