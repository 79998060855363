type IconPaperPlaneProps = {
  size: "24px";
  color?: string;
  className?: string;
};

export const IconPaperPlane: React.FC<IconPaperPlaneProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M22 2L11 13"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 2L15 22L11 13L2 9L22 2Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
