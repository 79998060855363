const palette = {
  grey1: "#212B36",
  grey2: "#454F5A",
  grey3: "#647380",
  grey4: "#929EAA",
  grey5: "#C4CDD5",
  grey6: "#DFE3E8",
  grey7: "#EFF2F5",
  grey8: "#F4F6F8",
  white: "#FFFFFF",
  purple1: "#5036DD",
  purple2: "#5F5CE7",
  blue1: "#4B82FB",
  blue2: "#3CB1FC",
  blue3: "#43CDE9",
  blue4: "#C0D3FE",
  blueBuxton: "#0062FF",
  green: "#56D138",
  yellow: "#FDC62E",
  orange: "#FC9526",
  red1: "#E54E33",
  red2: "#F6C1B8",
  red3: "#FBE6E3",
  red4: "#FDF2F0",
  pink: "#E75CAF"
} as const;

const getGradient = (d: "left" | "right") => {
  return `linear-gradient(to ${d}, ${palette.purple2}, ${palette.blue3})`;
};

export const theme = {
  gridBase: 8,
  fontBase: 16,
  palette,
  other: {
    gradientLeft: getGradient("left"),
    gradientRight: getGradient("right"),
    focusBoxShadow: `0 0 0 4px ${palette.blue4}`,
    focusBoxShadowError: `0 0 0 4px ${palette.red2}`,
    boxShadowDropdown: "2px 2px 8px rgba(33, 43, 54, 0.16)",
    boxShadowCard: "0 2px 6px rgba(33, 43, 54, 0.02)",
    boxShadowAvatar: "inset 0 0 2px rgba(0, 0, 0, 0.1)",
    transition: "0.15s ease",
    framerTransition: { duration: 0.15, ease: "easeInOut" }
  }
} as const;
