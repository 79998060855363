type IconTatariProps = {
  size: "24px";
  className?: string;
};

export const IconTatari: React.FC<IconTatariProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.3998 15.174H9.59985V19.9741H14.3998V15.174Z"
            fill="black"
          />
          <path
            d="M14.3998 9.33704H4.79993V14.1371H14.3998V9.33704Z"
            fill="black"
          />
          <path d="M24 3.5H0V8.30004H24V3.5Z" fill="black" />
        </svg>
      );
  }
};
