import { useState } from "react";
import styled from "styled-components";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import {
  ButtonPrimary,
  ButtonSecondary
} from "elevar-design-system/src/buttons/ButtonVariants";
import {
  heading3Styles,
  normalBodyStyles
} from "elevar-design-system/src/typography/typography";

import { type ShopifyOAuthScopes } from "../../api/handlers/shopify";
import { Modal } from "../../components/Modal";
import { PageCard } from "../../components/PageCard";
import {
  createSetupFlow,
  type SaveModalState
} from "../../context/SetupFlowDetails";
import { toast } from "../../utils/toast";
import { ConfigSummary } from "./ConfigSummary";
import { ConsentMode } from "./ConsentMode";
import { destinationOutb as destination } from "./data";
import {
  EventDestinationTable,
  getRecommendedEventState
} from "./EventDestinationTable";
import { FilterTransactions } from "./FilterTransactions";
import { Overview } from "./Overview";
import { StepSection } from "./StepSection";
import { Subscriptions } from "./Subscriptions";
import { TransactionIdentifier } from "./TransactionIdentifier";

/* ========================================================================== */

const setupGuideHref =
  "https://docs.getelevar.com/docs/how-to-set-up-outbrain-server-side-tracking";

type SetupFlowContext = {
  setSaveModalState: React.Dispatch<React.SetStateAction<SaveModalState>>;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

const { SetupFlowShell, useConfig, useSetupFlowDetails } =
  createSetupFlow<SetupFlowContext>().destination(destination);

type DestinationOutbProps = {
  isCompanyAdmin: boolean;
  eventsConnectorConfig: EventsConnectorConfig;
  shopifyOAuthScopes: ShopifyOAuthScopes;
};

export const DestinationOutb: React.FC<DestinationOutbProps> = ({
  isCompanyAdmin,
  eventsConnectorConfig,
  shopifyOAuthScopes
}) => {
  const [saveModalState, setSaveModalState] = useState<SaveModalState>({
    isVisible: false
  });
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <SetupFlowShell
        isCompanyAdmin={isCompanyAdmin}
        eventsConnectorConfig={eventsConnectorConfig}
        stepInfo={[
          { name: "Events" },
          { name: "Consent Mode" },
          { name: "Transaction Identifier" },
          { name: "Filter Transactions" },
          { name: "Subscriptions" }
        ]}
        context={{ setSaveModalState, shopifyOAuthScopes }}
      >
        <StepContent />
      </SetupFlowShell>
      <Modal
        isVisible={saveModalState.isVisible}
        onClose={() => setSaveModalState({ isVisible: false })}
        disallowClose={isLoading}
      >
        <StepModalContents>
          <StepModalTitle>Are you sure?</StepModalTitle>
          <StepModalBody>
            This integration is live. Any changes made to its configuration
            while it is live will immediately affect transactions processed by
            this integration.
          </StepModalBody>
          <StepModalButtons>
            <ButtonSecondary
              variant="SMALL"
              state={isLoading ? "DISABLED" : "IDLE"}
              onClick={() => setSaveModalState({ isVisible: false })}
            >
              No, Go Back
            </ButtonSecondary>
            <ButtonPrimary
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={async () => {
                if (saveModalState.isVisible) {
                  setIsLoading(true);
                  await saveModalState.onConfirm();
                  setSaveModalState({ isVisible: false });
                  setIsLoading(false);
                }
              }}
            >
              Yes, Save Changes
            </ButtonPrimary>
          </StepModalButtons>
        </StepModalContents>
      </Modal>
    </>
  );
};

const StepModalContents = styled.div`
  width: ${props => props.theme.gridBase * 42}px;
  position: relative;
`;

const StepModalTitle = styled.div`
  ${heading3Styles};
  text-align: center;
  color: ${props => props.theme.palette.grey1};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const StepModalBody = styled.div`
  ${normalBodyStyles};
  text-align: center;
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

const StepModalButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: ${props => props.theme.gridBase}px;
`;

/* ========================================================================== */

const StepContent: React.FC = () => {
  const { currentStep } = useSetupFlowDetails();

  switch (currentStep) {
    case 0:
      return <Step0 />;
    case 1:
      return <Step1 />;
    case 2:
      return <Step2 />;
    case 3:
      return <Step3 />;
    case 4:
      return <Step4 />;
    case 5:
      return <Step5 />;
  }
};

/* ========================================================================== */

const recommendedEvents: ReturnType<typeof useConfig>["enabledEvents"] = {
  purchase: true,
  pageView: true,
  addToCart: false,
  beginCheckout: false,
  viewItem: false,
  newCustomerPurchase: false,
  returningCustomerPurchase: false
};

const Step0: React.FC = () => {
  const { config, configMutation, configLabel, completedStep, setCurrentStep } =
    useSetupFlowDetails();

  return (
    <Overview
      destination={destination}
      config={config}
      configMutation={configMutation}
      configLabel={configLabel}
      completedStep={completedStep}
      configSummaryElement={({ isLoading, initialIsOpen }) => {
        return config ? (
          <ConfigSummary
            isLoading={isLoading}
            initialIsOpen={initialIsOpen}
            setCurrentStep={setCurrentStep}
            items={[
              {
                step: 1,
                type: "EVENTS",
                selectedEvents: {
                  enabledEvents: config.enabledEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                },
                recommendedEvents: getRecommendedEventState({
                  destination,
                  enabledEvents: recommendedEvents,
                  enabledWebEvents: {},
                  webhookOverrides: config.webhookOverrides
                })
              },
              {
                step: 2,
                type: "CONSENT_MODE",
                inEnabled: config.consentMode.enabled
              },
              {
                step: 3,
                type: "TRANSACTION_IDENTIFIER",
                value: config.dataConfig.orderAttributeId
              },
              {
                step: 4,
                type: "FILTER_TRANSACTIONS",
                filters: config.orderFilters
              },
              {
                step: 5,
                type: "SUBSCRIPTIONS",
                filters: config.orderFilters,
                tagName: config.subscriptionTagName
              }
            ]}
          />
        ) : null;
      }}
      description={
        <Step0Explainer>
          Guarantee near 100% accuracy for Outbrain transactions by configuring
          this server-side integration.
        </Step0Explainer>
      }
    />
  );
};

const Step0Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step1: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState, shopifyOAuthScopes }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [eventState, setEventState] = useState({
    enabledEvents: config.enabledEvents,
    enabledWebEvents: {},
    webhookOverrides: config.webhookOverrides
  });
  const [eventMapping, setEventMapping] = useState(config.eventMapping);

  const isStepCompleted = completedStep !== null && completedStep >= 1;

  return (
    <PageCard>
      <StepSection
        title="Events"
        setupGuideHref={setupGuideHref}
        description={
          <Step1Explainer>
            Select what events you want to send to Outbrain.
          </Step1Explainer>
        }
      />
      <EventDestinationTable
        isLoading={isLoading}
        isStepCompleted={isStepCompleted}
        shopifyOAuthScopes={shopifyOAuthScopes}
        mutualExclusivityLevel="NONE"
        showBreakdownByDefault={true}
        details={{
          eventState,
          setEventState,
          destinationName: destination.name,
          eventMapping,
          setEventMapping
        }}
        recommended={{
          enabledEvents: recommendedEvents,
          enabledWebEvents: {}
        }}
        onSave={async () => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...eventState,
              eventMapping,
              ...(!isStepCompleted ? { completedStep: 1 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }}
      />
    </PageCard>
  );
};

const Step1Explainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

/* ========================================================================== */

const Step2: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 2;

  return (
    <ConsentMode
      details={{
        type: "DESTINATION",
        isStepCompleted,
        setupGuideHref,
        name: destination.name
      }}
      isLoading={isLoading}
      initial={config.consentMode}
      onSave={async data => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            ...data,
            ...(!isStepCompleted ? { completedStep: 2 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step3: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);
  const [orderAttributeId, setOrderAttributeId] = useState(
    config.dataConfig.orderAttributeId
  );

  const isStepCompleted = completedStep !== null && completedStep >= 3;

  return (
    <TransactionIdentifier
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      hasRecommendedOption={false}
      options={["id", "order_number", "name"]}
      orderAttributeId={orderAttributeId}
      setOrderAttributeId={setOrderAttributeId}
      onSave={async () => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            dataConfig: { orderAttributeId },
            ...(!isStepCompleted ? { completedStep: 3 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step4: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 4;

  return (
    <FilterTransactions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      destinationName={destination.name}
      initialFilters={config.orderFilters}
      usesUnifiedCheckout={config.uses_unified_checkout}
      subscriptionTagName={config.subscriptionTagName}
      onSave={async orderFilters => {
        const action = async () => {
          setIsLoading(true);
          await configMutation({
            orderFilters,
            ...(!isStepCompleted ? { completedStep: 4 } : {})
          });
          if (isStepCompleted) toast.success("Destination updated");
          setIsLoading(false);
        };

        if (config.live) {
          setSaveModalState({ isVisible: true, onConfirm: action });
        } else {
          await action();
        }
      }}
    />
  );
};

/* ========================================================================== */

const Step5: React.FC = () => {
  const config = useConfig();
  const {
    configMutation,
    completedStep,
    context: { setSaveModalState }
  } = useSetupFlowDetails();

  const [isLoading, setIsLoading] = useState(false);

  const isStepCompleted = completedStep !== null && completedStep >= 5;

  return (
    <Subscriptions
      isLoading={isLoading}
      isStepCompleted={isStepCompleted}
      setupGuideHref={setupGuideHref}
      details={{
        key: destination.configKey,
        config,
        onSave: async data => {
          const action = async () => {
            setIsLoading(true);
            await configMutation({
              ...data,
              ...(!isStepCompleted ? { completedStep: 5 } : {})
            });
            if (isStepCompleted) toast.success("Destination updated");
            setIsLoading(false);
          };

          if (config.live) {
            setSaveModalState({ isVisible: true, onConfirm: action });
          } else {
            await action();
          }
        }
      }}
    />
  );
};
