import { useQuery } from "@tanstack/react-query";

import { externalRequest } from "../utils";

/* ========================================================================== */

export const fetchCloudStorageFile = <ResponseData>(fileUrl: string) => {
  return externalRequest<ResponseData>({ endpoint: fileUrl });
};

export const useCloudStorageFileQuery = <ResponseData>(
  fileUrl: string,
  enabled = true
) => {
  return useQuery({
    queryKey: ["googleCloudStorage", fileUrl],
    queryFn: () => fetchCloudStorageFile<ResponseData>(fileUrl),
    enabled
  });
};
