import { PaymentElement } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import { sortBy } from "lodash-es";
import { useId, useState } from "react";
import styled, { css, useTheme } from "styled-components";

import { type CompanyDetails } from "elevar-common-ts/src/apiTypes";

import { ButtonDropdown } from "elevar-design-system/src/buttons/ButtonDropdown";
import {
  iconButtonStyles,
  iconTextButtonStyles
} from "elevar-design-system/src/buttons/buttonStyles";
import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import {
  IconCircledPlus,
  IconDotsHorizontal
} from "elevar-design-system/src/icons";
import { LabeledCheckBoxSingle } from "elevar-design-system/src/labeledCheckBoxes/LabeledCheckBoxSingle";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { Spinner } from "elevar-design-system/src/Spinner";
import { Tag } from "elevar-design-system/src/Tag";
import {
  heading2Styles,
  heading3Styles,
  normalBodyStyles,
  normalTextStyles,
  subheadingStyles
} from "elevar-design-system/src/typography/typography";

import {
  type CompanyPaymentHistory,
  type CompanyPaymentMethods,
  type CompanyWebsites,
  useCompanyDetailsQuery,
  useCompanyPaymentHistoryQuery,
  useCompanyPaymentMethodsCreateMutation,
  useCompanyPaymentMethodsDeleteMutation,
  useCompanyPaymentMethodsQuery,
  useCompanyPaymentMethodsSetActiveMutation,
  useCompanyWebsitesQuery
} from "../../../api/handlers/company";
import { Drawer } from "../../../components/Drawer";
import { PageCard } from "../../../components/PageCard";
import { StripeProvider } from "../../../context/Stripe";
import { toast } from "../../../utils/toast";

/* ========================================================================== */

export const Billing: React.FC = () => {
  return (
    <StripeProvider variant="SMALL">
      <BillingInner1 />
    </StripeProvider>
  );
};

/* ========================================================================== */

const BillingInner1: React.FC = () => {
  const companyDetails = useCompanyDetailsQuery();
  const companyWebsites = useCompanyWebsitesQuery();
  const companyPaymentMethods = useCompanyPaymentMethodsQuery();
  const companyPaymentHistory = useCompanyPaymentHistoryQuery();

  if (
    companyDetails.error !== null ||
    companyWebsites.error !== null ||
    companyPaymentMethods.error !== null ||
    companyPaymentHistory.error !== null
  ) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (
    companyDetails.data === undefined ||
    companyWebsites.data === undefined ||
    companyPaymentMethods.data === undefined ||
    companyPaymentHistory.data === undefined
  ) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  return (
    <BillingInner2
      companyDetails={companyDetails.data}
      companyWebsites={companyWebsites.data}
      companyPaymentMethods={companyPaymentMethods.data}
      companyPaymentHistory={companyPaymentHistory.data}
    />
  );
};

const CenteredWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* ========================================================================== */

type BillingInner2Props = {
  companyDetails: CompanyDetails;
  companyWebsites: CompanyWebsites;
  companyPaymentMethods: CompanyPaymentMethods;
  companyPaymentHistory: CompanyPaymentHistory;
};

const BillingInner2: React.FC<BillingInner2Props> = ({
  companyDetails,
  companyWebsites,
  companyPaymentMethods,
  companyPaymentHistory
}) => {
  const theme = useTheme();

  const { mutateAsync: companyPaymentMethodsDeleteMutation } =
    useCompanyPaymentMethodsDeleteMutation();
  const { mutateAsync: companyPaymentMethodsSetActiveMutation } =
    useCompanyPaymentMethodsSetActiveMutation();
  const { mutateAsync: companyPaymentMethodsCreateMutation } =
    useCompanyPaymentMethodsCreateMutation();

  const [paymentMethodLoading, setPaymentMethodLoading] = useState<
    CompanyPaymentMethods[number] | null
  >(null);
  const [isDrawerShown, setIsDrawerShown] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const [isPaymentInfoReady, setIsPaymentInfoReady] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(false);

  const websitesWithShopifySubscription = companyWebsites.filter(
    website => website.subscription_type === "Shopify"
  );

  const orderedPaymentMethods = sortBy(companyPaymentMethods, [
    paymentMethod => !paymentMethod.is_default
  ]);

  const orderedPaymentHistory = sortBy(companyPaymentHistory, [
    paymentHistoryItem => !paymentHistoryItem.created
  ]);

  return (
    <>
      {websitesWithShopifySubscription.length > 0 ? (
        <ShopifySubscriptionsWrapper>
          {websitesWithShopifySubscription.map(website =>
            website.shopify_shop ? (
              <ShopifySubscriptionPageCard key={website.id}>
                <ShopifySubscriptionCardWebsiteNameAndLogo>
                  <ShopifySubscriptionCardWebsiteName>
                    {website.name}
                  </ShopifySubscriptionCardWebsiteName>
                  <ShopifySubscriptionCardLogo>
                    <ShopifyLogo />
                  </ShopifySubscriptionCardLogo>
                </ShopifySubscriptionCardWebsiteNameAndLogo>
                <ShopifySubscriptionCardHeading>
                  Website Billed via Shopify
                </ShopifySubscriptionCardHeading>
                <ShopifySubscriptionCardExplainer>
                  This website is billed via Shopify, so its payment method and
                  payment history won't be shown on this page. To view the
                  billing info for this Website, click on the link below.
                </ShopifySubscriptionCardExplainer>
                <StyledLinkExternal
                  href={`https://${website.shopify_shop.shop_url}/admin/settings/payments`}
                  text="See Billing Info in Shopify"
                />
              </ShopifySubscriptionPageCard>
            ) : null
          )}
        </ShopifySubscriptionsWrapper>
      ) : null}
      <CardHeading>Payment Methods</CardHeading>
      {orderedPaymentMethods.map(paymentMethod => (
        <PaymentMethodsPageCard key={paymentMethod.id}>
          <div>
            <div>
              <CardBrand>{paymentMethod.brand}</CardBrand>
              {paymentMethod.is_default ? (
                <CardActiveTag text="Active" textColor={theme.palette.green} />
              ) : null}
            </div>
            <div>
              <CardConsealedNumber>
                **** {paymentMethod.last4}
              </CardConsealedNumber>
              <CardExpiryDate>
                {String(paymentMethod.exp_month).padStart(2, "0")} /{" "}
                {String(paymentMethod.exp_year).slice(2, 4)}
              </CardExpiryDate>
            </div>
          </div>
          {!paymentMethod.is_default ? (
            <div>
              <PaymentMethodActionsButtonDropdown
                buttonProps={{ disabled: paymentMethodLoading !== null }}
                dropdownPlacement="bottom-end"
                dropdownOptions={[
                  {
                    value: "Delete",
                    type: "BUTTON",
                    onClick: async () => {
                      setPaymentMethodLoading(paymentMethod);
                      await companyPaymentMethodsDeleteMutation(
                        paymentMethod.id
                      );
                      toast.success("Payment method deleted");
                      setPaymentMethodLoading(null);
                    }
                  },
                  {
                    value: "Set to Active",
                    type: "BUTTON",
                    onClick: async () => {
                      setPaymentMethodLoading(paymentMethod);
                      await companyPaymentMethodsSetActiveMutation(
                        paymentMethod.id
                      );
                      toast.success("Payment method set to active");
                      setPaymentMethodLoading(null);
                    }
                  }
                ]}
              >
                {paymentMethod === paymentMethodLoading ? (
                  <Spinner size="24px" color={theme.palette.grey4} />
                ) : (
                  <IconDotsHorizontal size="24px" color={theme.palette.grey4} />
                )}
              </PaymentMethodActionsButtonDropdown>
            </div>
          ) : null}
        </PaymentMethodsPageCard>
      ))}
      <div>
        <AddPaymentMethodButton onClick={() => setIsDrawerShown(true)}>
          <div>
            <IconCircledPlus size="24px" />
          </div>
          <div>Add Payment Method</div>
        </AddPaymentMethodButton>
      </div>
      <PaymentHistoryPageCard>
        <PaymentHistoryHeading>Payment History</PaymentHistoryHeading>
        <PaymentHistoryList>
          {orderedPaymentHistory.length === 0 ? (
            <PaymentHistoryItem>
              <div>No Payments</div>
            </PaymentHistoryItem>
          ) : (
            orderedPaymentHistory.map(paymentHistoryItem => (
              <PaymentHistoryItem
                key={paymentHistoryItem.id}
                error={!paymentHistoryItem.paid}
              >
                <div>
                  <span>
                    {dayjs(paymentHistoryItem.created * 1000).format(
                      "MMM DD YYYY"
                    )}
                  </span>
                  {!paymentHistoryItem.paid ? (
                    <span>
                      <Tag text="Failed" textColor={theme.palette.red1} />
                    </span>
                  ) : null}
                </div>
                {paymentHistoryItem.source?.last4 ? (
                  <div>**** {paymentHistoryItem.source.last4}</div>
                ) : (
                  <div>N/A</div>
                )}
                <div>${Math.ceil((paymentHistoryItem.amount ?? 0) / 100)}</div>
                {paymentHistoryItem.invoice_url ? (
                  <div>
                    <StyledLinkExternal
                      href={paymentHistoryItem.invoice_url}
                      text="Invoice"
                    />
                  </div>
                ) : paymentHistoryItem.receipt_url ? (
                  <div>
                    <StyledLinkExternal
                      href={paymentHistoryItem.receipt_url}
                      text="Receipt"
                    />
                  </div>
                ) : null}
              </PaymentHistoryItem>
            ))
          )}
        </PaymentHistoryList>
      </PaymentHistoryPageCard>
      <Drawer
        isVisible={isDrawerShown}
        onClose={() => setIsDrawerShown(false)}
        disallowClose={isDrawerLoading}
      >
        <DrawerSubheading>{companyDetails.name}</DrawerSubheading>
        <DrawerHeading>Add Payment Method</DrawerHeading>
        <PaymentElement
          onChange={event => setIsPaymentInfoReady(event.complete)}
        />
        {orderedPaymentMethods.length > 0 ? (
          <ActivePaymentMethodCheckboxWrapper>
            <LabeledCheckBoxSingle
              variant="NORMAL"
              isChecked={isActiveChecked}
              setIsChecked={setIsActiveChecked}
              isDisabled={isDrawerLoading}
            >
              Set as Active Payment Method
            </LabeledCheckBoxSingle>
          </ActivePaymentMethodCheckboxWrapper>
        ) : null}
        <ButtonWrapper showMargin={orderedPaymentMethods.length === 0}>
          <ButtonPrimary
            variant="SMALL"
            state={
              isDrawerLoading
                ? "LOADING"
                : !isPaymentInfoReady
                  ? "DISABLED"
                  : "IDLE"
            }
            onClick={async () => {
              setIsDrawerLoading(true);

              try {
                const result = await companyPaymentMethodsCreateMutation({
                  setActive:
                    isActiveChecked || orderedPaymentMethods.length === 0
                });

                if (result.error) {
                  if (result.error.message) {
                    toast.errorExpected(result.error.message);
                  }
                } else {
                  toast.success("Payment method added");
                  setIsDrawerShown(false);
                  setIsActiveChecked(false);
                  setIsDrawerLoading(false);
                }
              } catch (error) {
                setIsDrawerLoading(false);
                toast.errorUnexpected(error);
              }
            }}
          >
            Add New Payment Method
          </ButtonPrimary>
        </ButtonWrapper>
      </Drawer>
    </>
  );
};

const ShopifySubscriptionsWrapper = styled.div`
  margin-bottom: ${props => props.theme.gridBase * 6}px;
`;

const ShopifySubscriptionPageCard = styled(PageCard)`
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 3}px;
  }
`;

const ShopifySubscriptionCardWebsiteNameAndLogo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.gridBase * 0.5}px;
`;

const ShopifySubscriptionCardWebsiteName = styled.div`
  ${subheadingStyles};
`;

const ShopifySubscriptionCardLogo = styled.div`
  display: flex;
`;

const ShopifySubscriptionCardHeading = styled.div`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const ShopifySubscriptionCardExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
  margin-bottom: ${props => props.theme.gridBase * 2}px;
`;

const CardHeading = styled.h2`
  ${heading3Styles};
  margin-bottom: ${props => props.theme.gridBase * 2.25}px;
`;

const PaymentMethodsPageCard = styled(PageCard)`
  padding-top: ${props => props.theme.gridBase * 2.5}px;
  padding-right: ${props => props.theme.gridBase * 2.5}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-bottom: ${props => props.theme.gridBase * 3}px;
  margin-bottom: ${props => props.theme.gridBase * 2}px;
  display: flex;
  justify-content: space-between;

  > div:first-child {
    margin-top: ${props => props.theme.gridBase * 0.5}px;

    > div:first-child {
      display: flex;
      margin-bottom: ${props => props.theme.gridBase}px;
    }

    > div:last-child {
      display: flex;
    }
  }
`;

const CardBrand = styled.div`
  ${heading3Styles};
`;

const CardActiveTag = styled(Tag)`
  margin-left: ${props => props.theme.gridBase * 1.75}px;
`;

const CardConsealedNumber = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
  margin-right: ${props => props.theme.gridBase * 3}px;
`;

const CardExpiryDate = styled.div`
  ${normalTextStyles};
  color: ${props => props.theme.palette.grey3};
`;

const PaymentMethodActionsButtonDropdown = styled(ButtonDropdown)`
  ${iconButtonStyles};
`;

const AddPaymentMethodButton = styled.button`
  ${iconTextButtonStyles};

  &:hover {
    background: ${props => props.theme.palette.white};
  }
`;

const PaymentHistoryPageCard = styled(PageCard)`
  padding: 0;
  margin-top: ${props => props.theme.gridBase * 4}px;
  overflow: hidden;
`;

const PaymentHistoryHeading = styled.div`
  ${heading3Styles};
  padding-top: ${props => props.theme.gridBase * 2}px;
  padding-bottom: ${props => props.theme.gridBase * 2}px;
  padding-left: ${props => props.theme.gridBase * 3}px;
  padding-right: ${props => props.theme.gridBase * 3}px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.palette.grey6};
`;

const PaymentHistoryList = styled.div`
  max-height: ${props => props.theme.gridBase * 33}px;
  overflow: auto;
`;

type PaymentHistoryItemProps = {
  error?: boolean;
};

const PaymentHistoryItem = styled.div<PaymentHistoryItemProps>`
  ${normalBodyStyles};
  padding: ${props => props.theme.gridBase * 1.5}px 0;
  display: grid;
  grid-template-columns:
    ${props => props.theme.gridBase * 25}px
    ${props => props.theme.gridBase * 10}px
    ${props => props.theme.gridBase * 10}px
    1fr;

  ${props =>
    props.error &&
    css`
      color: ${props.theme.palette.red1};
    `}

  &:nth-child(2n - 1) {
    background-color: ${props => props.theme.palette.grey8};
  }

  &:nth-child(2n) {
    background-color: ${props => props.theme.palette.white};
  }

  > div:nth-child(1) {
    justify-self: start;
    display: flex;
    margin-left: ${props => props.theme.gridBase * 3}px;

    > span:not(:first-child) {
      margin-left: ${props => props.theme.gridBase * 1.5}px;
    }
  }

  > div:nth-child(2) {
    justify-self: end;
  }

  > div:nth-child(3) {
    justify-self: end;
  }

  > div:nth-child(4) {
    justify-self: end;
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.gridBase * 2}px;
  }
`;

const DrawerSubheading = styled.div`
  ${subheadingStyles};
  margin-bottom: ${props => props.theme.gridBase}px;
`;

const DrawerHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;

const ActivePaymentMethodCheckboxWrapper = styled.div`
  margin-top: ${props => props.theme.gridBase * 2.5}px;
  margin-bottom: ${props => props.theme.gridBase * 3}px;
`;

type ButtonWrapperProps = {
  showMargin: boolean;
};

const ButtonWrapper = styled.div<ButtonWrapperProps>`
  margin-top: ${props => (props.showMargin ? props.theme.gridBase * 4 : 0)}px;
`;

/* ========================================================================== */

const ShopifyLogo: React.FC = () => {
  const idPrefix = useId();
  const clipPathId = `${idPrefix}-clipPath`;

  return (
    <svg width="86" height="24" viewBox="0 0 86 24" fill="none">
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M18.5503 4.5456C18.5337 4.42639 18.4278 4.36025 18.3399 4.35305C18.2527 4.34585 16.5442 4.32031 16.5442 4.32031C16.5442 4.32031 15.1152 2.95544 14.9741 2.8166C14.833 2.67775 14.5573 2.71967 14.4501 2.7511C14.4488 2.75176 14.1818 2.83297 13.7323 2.96985C13.6571 2.73015 13.5465 2.43543 13.3887 2.1394C12.88 1.18452 12.135 0.679574 11.2347 0.678268C11.2334 0.678268 11.2327 0.678268 11.2314 0.678268C11.1688 0.678268 11.1069 0.684161 11.0443 0.689398C11.0177 0.657962 10.991 0.627183 10.9631 0.597053C10.5709 0.184452 10.0682 -0.0166104 9.4656 0.00107257C8.30304 0.0338189 7.14516 0.85968 6.20632 2.32671C5.5458 3.35887 5.04308 4.65565 4.9006 5.65963C3.56558 6.06633 2.63207 6.35056 2.61143 6.35713C1.9376 6.56538 1.91629 6.58568 1.8284 7.21051C1.76315 7.68268 0 21.093 0 21.093L14.7757 23.6066L21.1797 22.0406C21.1797 22.0406 18.567 4.66481 18.5503 4.5456ZM12.9926 3.19514C12.6523 3.29862 12.2654 3.41651 11.846 3.54422C11.8373 2.96527 11.7674 2.15971 11.4931 1.46352C12.3753 1.62791 12.8094 2.60964 12.9926 3.19514ZM11.0729 3.77999C10.2986 4.01575 9.4536 4.27314 8.606 4.5312C8.84436 3.63329 9.29648 2.73932 9.8518 2.15316C10.0582 1.93507 10.3472 1.69209 10.6894 1.55324C11.011 2.21341 11.0809 3.14799 11.0729 3.77999ZM9.48888 0.762094C9.76188 0.756201 9.9916 0.815146 10.188 0.942201C9.87376 1.10266 9.57012 1.33319 9.28516 1.6338C8.54672 2.41316 7.98076 3.62281 7.75504 4.78989C7.05124 5.00404 6.36276 5.2143 5.72888 5.40685C6.12908 3.56976 7.69444 0.813179 9.48888 0.762094Z"
          fill="#95BF47"
        />
        <path
          d="M18.3406 4.35372C18.2534 4.34652 16.5448 4.32098 16.5448 4.32098C16.5448 4.32098 15.116 2.95611 14.9748 2.81726C14.9222 2.76552 14.851 2.73867 14.7764 2.72754L14.777 23.6059L21.1804 22.0407C21.1804 22.0407 18.5677 4.66548 18.551 4.54627C18.5344 4.42705 18.4278 4.36092 18.3406 4.35372Z"
          fill="#5E8E3E"
        />
        <path
          d="M11.2268 7.59235L10.4831 10.3293C10.4831 10.3293 9.65345 9.95792 8.67001 10.0188C7.22777 10.1086 7.21245 11.0032 7.22713 11.2278C7.30569 12.4519 10.5796 12.7191 10.7634 15.5864C10.9079 17.8419 9.54689 19.3849 7.58601 19.5067C5.23225 19.6528 3.93652 18.2873 3.93652 18.2873L4.43525 16.2007C4.43525 16.2007 5.73961 17.1687 6.78365 17.1038C7.46549 17.0613 7.70917 16.5157 7.68453 16.1299C7.58201 14.5332 4.91597 14.6275 4.74753 12.0039C4.60569 9.79618 6.07989 7.55895 9.33249 7.35723C10.5856 7.27799 11.2268 7.59235 11.2268 7.59235Z"
          fill="white"
        />
        <path
          d="M29.6129 13.3234C28.8766 12.9304 28.4981 12.5992 28.4981 12.1438C28.4981 11.5644 29.0238 11.1921 29.8447 11.1921C30.8003 11.1921 31.6536 11.5851 31.6536 11.5851L32.3265 9.55714C32.3265 9.55714 31.7077 9.08099 29.8863 9.08099C27.3517 9.08099 25.595 10.5088 25.595 12.516C25.595 13.6541 26.4152 14.5232 27.5095 15.1436C28.3931 15.6398 28.7088 15.9919 28.7088 16.509C28.7088 17.0469 28.267 17.4814 27.4468 17.4814C26.2243 17.4814 25.0699 16.8604 25.0699 16.8604L24.3547 18.8884C24.3547 18.8884 25.4213 19.5919 27.2156 19.5919C29.8243 19.5919 31.6965 18.3298 31.6965 16.0536C31.6959 14.8337 30.7495 13.9646 29.6129 13.3234Z"
          fill="black"
        />
        <path
          d="M40.0052 9.06081C38.722 9.06081 37.7122 9.66105 36.9342 10.5711L36.892 10.5503L38.0067 4.81903H35.1036L32.2849 19.4061H35.1881L36.1556 14.4199C36.534 12.5368 37.5226 11.3785 38.4486 11.3785C39.1004 11.3785 39.3533 11.8131 39.3533 12.4341C39.3533 12.8271 39.311 13.3033 39.2272 13.6963L38.1335 19.4068H41.0368L42.1724 13.5098C42.2988 12.8888 42.3832 12.1444 42.3832 11.6475C42.382 10.0332 41.5196 9.06081 40.0052 9.06081Z"
          fill="black"
        />
        <path
          d="M48.9453 9.06085C45.4529 9.06085 43.1396 12.1646 43.1396 15.6197C43.1396 17.8335 44.5277 19.6127 47.1365 19.6127C50.5653 19.6127 52.8793 16.5922 52.8793 13.0539C52.8793 11.0057 51.6589 9.06085 48.9453 9.06085ZM47.5149 17.4204C46.5261 17.4204 46.1053 16.5928 46.1053 15.558C46.1053 13.9236 46.9681 11.2545 48.5457 11.2545C49.5765 11.2545 49.9125 12.1236 49.9125 12.972C49.9125 14.7304 49.0509 17.4204 47.5149 17.4204Z"
          fill="black"
        />
        <path
          d="M60.3049 9.06085C58.3453 9.06085 57.2333 10.7576 57.2333 10.7576H57.1917L57.3601 9.22649H54.7937C54.6677 10.2613 54.4357 11.8333 54.2049 13.0129L52.1853 23.4615H55.0885L55.8873 19.2405H55.9509C55.9509 19.2405 56.5465 19.6127 57.6549 19.6127C61.0625 19.6127 63.2921 16.1784 63.2921 12.7018C63.2921 10.7784 62.4293 9.06085 60.3049 9.06085ZM57.5281 17.4613C56.7745 17.4613 56.3285 17.0475 56.3285 17.0475L56.8125 14.3784C57.1493 12.5992 58.0957 11.4195 59.1057 11.4195C59.9893 11.4195 60.2625 12.2263 60.2625 12.9922C60.2625 14.8337 59.1477 17.4613 57.5281 17.4613Z"
          fill="black"
        />
        <path
          d="M67.436 4.96393C66.5108 4.96393 65.7744 5.68822 65.7744 6.61906C65.7744 7.46744 66.3212 8.04687 67.1416 8.04687H67.1836C68.0884 8.04687 68.8664 7.44663 68.8876 6.39173C68.8876 5.56413 68.3196 4.96393 67.436 4.96393Z"
          fill="black"
        />
        <path
          d="M63.3765 19.4061H66.2789L68.2569 9.28882H65.3325L63.3765 19.4061Z"
          fill="black"
        />
        <path
          d="M75.6403 9.26805H73.6207L73.7255 8.79191C73.8939 7.81948 74.4831 6.95032 75.4507 6.95032C75.9671 6.95032 76.3759 7.09519 76.3759 7.09519L76.9439 4.86063C76.9439 4.86063 76.4391 4.61249 75.3659 4.61249C74.3351 4.61249 73.3051 4.90218 72.5263 5.56411C71.5375 6.39171 71.0747 7.59148 70.8435 8.79191L70.7599 9.26805H69.4131L68.9923 11.4201H70.3391L68.8035 19.4068H71.7067L73.2423 11.4201H75.2407L75.6403 9.26805Z"
          fill="black"
        />
        <path
          d="M82.624 9.28888C82.624 9.28888 80.8092 13.7859 79.9944 16.2407H79.952C79.8968 15.4502 79.2368 9.28888 79.2368 9.28888H76.1864L77.9332 18.5786C77.9756 18.7852 77.9544 18.9099 77.87 19.0547C77.5328 19.6959 76.9652 20.3169 76.292 20.7722C75.7452 21.1652 75.1352 21.4134 74.6516 21.579L75.4508 24C76.04 23.876 77.2596 23.3998 78.2904 22.4482C79.616 21.2276 80.8356 19.3444 82.0976 16.7786L85.6528 9.28821H82.624V9.28888Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect width="86" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
