type IconImpactProps = {
  size: "24px";
  className?: string;
};

export const IconImpact: React.FC<IconImpactProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9972 14.5946C13.6189 14.5946 14.9973 15.2433 15.8891 16.3784C16.1957 15.7235 16.3321 15.0018 16.2857 14.2802C16.2392 13.5586 16.0114 12.8603 15.6233 12.2502C15.2353 11.64 14.6995 11.1376 14.0656 10.7896C13.4318 10.4415 12.7204 10.259 11.9972 10.259C11.2741 10.259 10.5627 10.4415 9.92886 10.7896C9.29501 11.1376 8.75925 11.64 8.37118 12.2502C7.98312 12.8603 7.7553 13.5586 7.70883 14.2802C7.66237 15.0018 7.79875 15.7235 8.10536 16.3784C8.58985 15.8213 9.18763 15.3741 9.85877 15.0664C10.5299 14.7588 11.259 14.598 11.9972 14.5946Z"
            fill="#A9B4BD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2945 19.7027C16.2945 18.563 15.8418 17.4699 15.0359 16.664C14.23 15.8581 13.137 15.4054 11.9972 15.4054C10.8575 15.4054 9.7645 15.8581 8.9586 16.664C8.1527 17.4699 7.69995 18.563 7.69995 19.7027C7.69995 20.8424 8.1527 21.9354 8.9586 22.7413C9.7645 23.5472 10.8575 24 11.9972 24C13.137 24 14.23 23.5472 15.0359 22.7413C15.8418 21.9354 16.2945 20.8424 16.2945 19.7027Z"
            fill="#4B5E6A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2945 4.2973C16.2945 3.15758 15.8418 2.06455 15.0359 1.25865C14.23 0.45275 13.137 0 11.9972 0C10.8575 0 9.7645 0.45275 8.9586 1.25865C8.1527 2.06455 7.69995 3.15758 7.69995 4.2973C7.69995 6.72973 9.6459 8.59459 11.9972 8.59459C14.4297 8.59459 16.2945 6.72973 16.2945 4.2973Z"
            fill="#E40046"
          />
        </svg>
      );
  }
};
