import { isEqual } from "lodash-es";
import { useState } from "react";
import styled from "styled-components";
import { type UnknownRecord } from "type-fest";

import {
  type ContentType,
  type GA4EventsConnectorConfig,
  type OrderAttributeId,
  type OrderFilters,
  type ProductAttributeMapping
} from "elevar-common-ts/src/apiTypes";

import {
  IconCheckMark,
  IconChevronDown,
  IconChevronUp
} from "elevar-design-system/src/icons";
import { linkStyles } from "elevar-design-system/src/links/links";
import {
  normalBodyStyles,
  normalTextStyles
} from "elevar-design-system/src/typography/typography";

import {
  getContentTypeDisplayName,
  getProductIdentifierDisplayName,
  getTransactionIdentifierDisplayName
} from "./shared";

/* ========================================================================== */

type Events = UnknownRecord;
type TagSourceOverrides =
  GA4EventsConnectorConfig["dataConfig"]["tagSourceOverrides"];

/*
 * TODO:
 * Instead of requiring that `step` be manually passed for each item, could we
 * instead use what `useXSetupFlowDetails` returns to make this work implicitly?
 * This could also potentially allow us to pull data from the config directly
 * instead of requiring the caller to pass it. Maybe worth waiting to tackle
 * until we look into moving the `useXSetupFlowDetails` values into context?
 */
type ConfigSummaryItem<TStep extends number> = { step: TStep } & (
  | { type: "CUSTOM"; render: () => React.ReactNode }
  | { type: "EVENTS"; selectedEvents: Events; recommendedEvents: Events }
  | { type: "CONSENT_MODE"; inEnabled: boolean }
  | { type: "CONTENT_TYPE"; value: ContentType }
  | { type: "TRANSACTION_IDENTIFIER"; value: OrderAttributeId }
  | { type: "PRODUCT_IDENTIFIER"; value: ProductAttributeMapping }
  | { type: "FILTER_TRANSACTIONS"; filters: OrderFilters }
  | {
      type: "SUBSCRIPTIONS";
      filters: OrderFilters;
      tagName: string | null;
      tagSourceOverrides?: TagSourceOverrides;
    }
);

type ConfigSummaryProps<TStep extends number> = {
  isLoading: boolean;
  initialIsOpen: boolean;
  setCurrentStep: (currentStep: TStep) => void;
  items: Array<ConfigSummaryItem<NoInfer<TStep>>>;
};

export const ConfigSummary = <TStep extends number>({
  isLoading,
  initialIsOpen,
  setCurrentStep,
  items
}: ConfigSummaryProps<TStep>): ReturnType<React.FC> => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  return (
    <>
      <ConfigSummaryToggleButton onClick={() => setIsOpen(!isOpen)}>
        <div>Configuration Summary</div>
        <div>
          {isOpen ? (
            <IconChevronUp size="16px" />
          ) : (
            <IconChevronDown size="16px" />
          )}
        </div>
      </ConfigSummaryToggleButton>
      {isOpen ? (
        <ConfigSummaryList>
          {items.map((item, index) => (
            <ConfigSummaryListItem key={index}>
              <div>
                <IconCheckMark size="24px" />
              </div>
              <div>
                <div>
                  {(() => {
                    switch (item.type) {
                      case "EVENTS": {
                        return isEqual(
                          item.selectedEvents,
                          item.recommendedEvents
                        ) ? (
                          <>
                            Event <span>best practices</span> selected
                          </>
                        ) : (
                          <>
                            Event configuration is <span>customized</span>
                          </>
                        );
                      }
                      case "CONSENT_MODE": {
                        return (
                          <>
                            Consent mode is{" "}
                            <span>
                              {item.inEnabled ? "enabled" : "disabled"}
                            </span>
                          </>
                        );
                      }
                      case "CONTENT_TYPE": {
                        return (
                          <>
                            The content type used is{" "}
                            <span>{getContentTypeDisplayName(item.value)}</span>
                          </>
                        );
                      }
                      case "TRANSACTION_IDENTIFIER": {
                        return (
                          <>
                            The transaction identifier used is{" "}
                            <span>
                              {getTransactionIdentifierDisplayName(item.value)}
                            </span>
                          </>
                        );
                      }
                      case "PRODUCT_IDENTIFIER": {
                        return (
                          <>
                            The product identifier used is{" "}
                            <span>
                              {getProductIdentifierDisplayName(item.value)}
                            </span>
                          </>
                        );
                      }
                      case "FILTER_TRANSACTIONS": {
                        const someFiltersActive =
                          item.filters.channels.length > 0 ||
                          item.filters.tags.length > 0 ||
                          item.filters.gateways.length > 0 ||
                          item.filters.customerTags.length > 0 ||
                          item.filters.appIds.length > 0;
                        return (
                          <>
                            <span>{someFiltersActive ? "Some" : "No"}</span>{" "}
                            transactions are being{" "}
                            {someFiltersActive ? (
                              item.filters.condition === "ALLOW" ? (
                                <span>allowed</span>
                              ) : (
                                <span>blocked</span>
                              )
                            ) : (
                              "filtered"
                            )}
                          </>
                        );
                      }
                      case "SUBSCRIPTIONS": {
                        const subscriptionStatus =
                          item.tagName !== null
                            ? item.tagSourceOverrides?.some(
                                o => o.tagName === item.tagName
                              )
                              ? "CUSTOMIZED"
                              : "ENABLED"
                            : "DISABLED";
                        return subscriptionStatus === "CUSTOMIZED" ? (
                          <>
                            Subscriptions are <span>enabled</span> but tracking
                            is <span>customized</span>
                          </>
                        ) : subscriptionStatus === "ENABLED" ? (
                          <>
                            Subscriptions are <span>enabled</span>
                          </>
                        ) : (
                          <>
                            Subscriptions are <span>not enabled</span>
                          </>
                        );
                      }
                      case "CUSTOM": {
                        return <>{item.render()}</>;
                      }
                    }
                  })()}
                </div>
                <button
                  onClick={() => setCurrentStep(item.step)}
                  disabled={isLoading}
                >
                  Edit
                </button>
              </div>
            </ConfigSummaryListItem>
          ))}
        </ConfigSummaryList>
      ) : null}
    </>
  );
};

/* ========================================================================== */

const ConfigSummaryToggleButton = styled.button`
  margin-top: ${props => props.theme.gridBase * 3}px;
  display: flex;
  align-items: center;

  > div:first-child {
    ${normalTextStyles};
    margin-right: ${props => props.theme.gridBase}px;
  }

  > div:last-child {
    display: flex;
  }
`;

const ConfigSummaryList = styled.div`
  padding-top: ${props => props.theme.gridBase * 2}px;
`;

const ConfigSummaryListItem = styled.div`
  display: flex;

  > div:first-child {
    display: flex;
    color: ${props => props.theme.palette.green};
    margin-right: ${props => props.theme.gridBase * 2}px;
  }

  > div:last-child {
    ${normalBodyStyles};

    > div {
      display: inline;
      color: ${props => props.theme.palette.grey3};
      margin-right: ${props => props.theme.gridBase * 3}px;

      > span {
        font-weight: 500;
        color: ${props => props.theme.palette.grey1};
      }
    }

    > button {
      ${linkStyles};
    }
  }

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.gridBase * 2}px;
  }
`;
