import { type ValueOf } from "type-fest";

import { type EventsConnectorConfig } from "elevar-common-ts/src/apiTypes";

import { type OnboardingState } from "../../context/OnboardingDetails";
import { formatTitle } from "../../utils/format";
import { type Destination, destinations } from "./data";

/* ========================================================================== */

const getIsRecommended = (
  destination: Destination,
  onboardingState: OnboardingState
) => {
  return (
    onboardingState.name === "ONBOARDING" &&
    onboardingState.info.step === "INSTALL_DESTINATIONS" &&
    destination.name === "Facebook"
  );
};

/* -------------------------------------------------------------------------- */

type EnabledEventsState = "SERVER_AND_WEB" | "SERVER" | "WEB" | "NONE";

const getEnabledEventsState = (
  config: EventsConnectorConfig[Destination["configKey"]][number] | null
): EnabledEventsState => {
  const areServerEventsEnabled =
    config !== null && "enabledEvents" in config
      ? Object.values(config.enabledEvents).some(Boolean)
      : false;
  const areWebEventsEnabled =
    config !== null && "enabledWebEvents" in config
      ? Object.values(config.enabledWebEvents).some(Boolean)
      : false;

  return areServerEventsEnabled && areWebEventsEnabled
    ? "SERVER_AND_WEB"
    : areServerEventsEnabled
      ? "SERVER"
      : areWebEventsEnabled
        ? "WEB"
        : "NONE";
};

/* -------------------------------------------------------------------------- */

type DestinationConfigs = Pick<EventsConnectorConfig, Destination["configKey"]>;

type GetSummaryItemsDetailsLoose = {
  key: keyof DestinationConfigs;
  config: ValueOf<DestinationConfigs>[number];
};

type GetSummaryItemsDetailsStrict = {
  [Key in keyof DestinationConfigs]: {
    key: Key;
    config: DestinationConfigs[Key][number];
  };
}[keyof DestinationConfigs];

const getSummaryItems = (looseDetails: GetSummaryItemsDetailsLoose) => {
  const details = looseDetails as GetSummaryItemsDetailsStrict;

  switch (details.key) {
    case "ga4":
      return [`GA4 ID: ${details.config.measurementId}`];
    case "facebook":
      return [`Dataset ID: ${details.config.pixelId}`];
    case "impact_radius":
      return [`Account ID: ${details.config.accountSID}`];
    case "tiktok":
      return [`Pixel ID: ${details.config.pixelId}`];
    case "shareasale":
      return [`Merchant ID: ${details.config.merchantId}`];
    case "voluum":
      return [`Domain URL: ${details.config.domain}`];
    case "criteo":
      return [`Partner ID: ${details.config.partnerId}`];
    case "snapchat":
      return [`Pixel ID: ${details.config.pixelId}`];
    case "smartly":
      return [];
    case "pinterest":
      return [`Tag ID: ${details.config.tagId}`];
    case "google_ads":
      return [`Customer ID: ${details.config.googleAdAccountId}`];
    case "klaviyo":
      return [];
    case "partnerize":
      return [`Campaign ID: ${details.config.adAccountId}`];
    case "outbrain":
      return [];
    case "awin":
      return [`Merchant ID: ${details.config.adAccountId}`];
    case "attentive":
      return [];
    case "pubsub":
      return [`Topic ID: ${details.config.topicId}`];
    case "rakuten":
      return [`Merchant ID: ${details.config.adAccountId}`];
    case "taboola":
      return [];
    case "iterable":
      return [];
    case "postscript":
      return [];
    case "sendlane":
      return [`Token: ${details.config.payloadToken}`];
    case "pepperjam":
      return [];
    case "bing":
      return [`UET ID: ${details.config.pixelId}`];
    case "cj":
      return [`Enterprise ID: ${details.config.adAccountId}`];
    case "mixpanel":
      return [`Project Token: ${details.config.accessToken}`];
    case "reddit":
      return [`Ad Account ID: ${details.config.pixelId}`];
    case "ometria":
      return [];
    case "yotpo":
      return [];
    case "twitter":
      return [];
    case "rtb":
      return [];
  }
};

/* -------------------------------------------------------------------------- */

type SearchCategory =
  | "Advertising"
  | "Affiliate"
  | "Analytics"
  | "Attribution & BI"
  | "Email & SMS";

const getSearchCategory = (key: keyof DestinationConfigs): SearchCategory => {
  switch (key) {
    case "ga4":
      return "Analytics";
    case "facebook":
      return "Advertising";
    case "impact_radius":
      return "Affiliate";
    case "tiktok":
      return "Advertising";
    case "shareasale":
      return "Affiliate";
    case "voluum":
      return "Affiliate";
    case "criteo":
      return "Advertising";
    case "snapchat":
      return "Advertising";
    case "smartly":
      return "Advertising";
    case "pinterest":
      return "Advertising";
    case "google_ads":
      return "Advertising";
    case "klaviyo":
      return "Email & SMS";
    case "partnerize":
      return "Affiliate";
    case "outbrain":
      return "Advertising";
    case "awin":
      return "Affiliate";
    case "attentive":
      return "Email & SMS";
    case "pubsub":
      return "Attribution & BI";
    case "rakuten":
      return "Affiliate";
    case "taboola":
      return "Advertising";
    case "iterable":
      return "Email & SMS";
    case "postscript":
      return "Email & SMS";
    case "sendlane":
      return "Email & SMS";
    case "pepperjam":
      return "Affiliate";
    case "bing":
      return "Advertising";
    case "cj":
      return "Affiliate";
    case "mixpanel":
      return "Analytics";
    case "reddit":
      return "Advertising";
    case "ometria":
      return "Email & SMS";
    case "yotpo":
      return "Email & SMS";
    case "twitter":
      return "Advertising";
    case "rtb":
      return "Advertising";
  }
};

const getSearchKeywords = (key: keyof DestinationConfigs) => {
  switch (key) {
    case "ga4":
      return ["google analytics"];
    case "facebook":
      return ["meta"];
    case "pubsub":
      return ["google", "pubsub"];
    case "bing":
      return ["bing ads"];
    case "cj":
      return ["commission junction"];
    case "pepperjam":
      return ["ascend"];
    default:
      return [];
  }
};

const getIsPopular = (key: keyof DestinationConfigs) => {
  switch (key) {
    case "ga4":
    case "facebook":
    case "tiktok":
    case "google_ads":
    case "klaviyo":
    case "bing":
      return true;
    default:
      return false;
  }
};

/* ========================================================================== */

export type EnchancedDestination = {
  base: Destination & {
    searchCategory: string;
    searchKeywords: Array<string>;
    isPopular: boolean;
  };
  instances: Array<{
    id: number;
    title: string;
    url: string;
    isTestModeEnabled: boolean;
    isLive: boolean;
    hasBeenLive: boolean;
    isSetup: boolean;
    enabledEventsState: EnabledEventsState;
    summaryItems: Array<string>;
  }>;
};

type GetEnchancedDestinations = (
  getDestinationUrl: (name: string) => string,
  eventsConnectorConfig: EventsConnectorConfig,
  onboardingState: OnboardingState
) => Array<EnchancedDestination>;

export const getEnchancedDestinations: GetEnchancedDestinations = (
  getDestinationUrl,
  eventsConnectorConfig,
  onboardingState
) => {
  const enchanced = destinations.map(destination => {
    const key = destination.configKey;
    const configs = eventsConnectorConfig[key];

    return {
      base: {
        ...destination,
        searchCategory: getSearchCategory(key),
        searchKeywords: getSearchKeywords(key),
        isPopular: getIsPopular(key)
      },
      instances: configs.map(config => ({
        id: config.id,
        title: formatTitle(destination.name, config.label),
        url: `${getDestinationUrl(destination.shorthand)}/${config.id}`,
        isTestModeEnabled:
          "testCode" in config
            ? config.testCode !== null
            : "testMode" in config
              ? config.testMode
              : false,
        isLive: config.live,
        hasBeenLive: config.wentLiveAt !== null,
        isSetup: config.completedStep === destination.stepCount,
        enabledEventsState: getEnabledEventsState(config),
        summaryItems: getSummaryItems({ key, config })
      }))
    };
  });

  if (enchanced.some(destination => destination.instances.length > 0)) {
    return enchanced;
  } else {
    return enchanced.map(destination => ({
      ...destination,
      instances: getIsRecommended(destination.base, onboardingState)
        ? [
            {
              id: 0,
              title: destination.base.name,
              url: `${getDestinationUrl(destination.base.shorthand)}/new`,
              isTestModeEnabled: false,
              isLive: false,
              hasBeenLive: false,
              isSetup: false,
              enabledEventsState: "NONE",
              summaryItems: []
            }
          ]
        : []
    }));
  }
};
