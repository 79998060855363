type IconPlayFillProps = {
  size: "24px";
  color?: string;
  className?: string;
};

export const IconPlayFill: React.FC<IconPlayFillProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.6 10.6144C20.3876 11.0691 20.7814 11.2964 20.9135 11.5933C21.0288 11.8522 21.0288 12.1478 20.9135 12.4067C20.7814 12.7036 20.3876 12.9309 19.6 13.3856L9.4 19.2746C8.61241 19.7293 8.21861 19.9567 7.89547 19.9227C7.61361 19.8931 7.35757 19.7453 7.19098 19.516C7 19.2531 7 18.7984 7 17.889L7 6.11103C7 5.20159 7 4.74688 7.19098 4.48401C7.35757 4.25473 7.61361 4.1069 7.89547 4.07727C8.21861 4.04331 8.61241 4.27067 9.4 4.72539L19.6 10.6144Z"
            fill={color}
          />
        </svg>
      );
  }
};
