import styled, { css, useTheme } from "styled-components";

import { smallTextStyles } from "./typography/typography";
import { IconAlertCircle } from "./icons";

/* ========================================================================== */

type ErrorOccurredProps = {
  text?: string;
  iconColor?: string;
  textColor?: string;
  className?: string;
};

export const ErrorOccurred: React.FC<ErrorOccurredProps> = ({
  text,
  iconColor,
  textColor,
  className
}) => {
  const theme = useTheme();

  return (
    <Wrapper textColor={textColor} className={className}>
      <IconAlertCircle size="24px" color={iconColor ?? theme.palette.grey2} />
      <div>{text ?? "An error occurred."}</div>
    </Wrapper>
  );
};

/* ========================================================================== */

type WrapperProps = {
  textColor?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  > :last-child {
    ${smallTextStyles};
    margin-top: ${props => props.theme.gridBase * 0.5}px;

    ${props =>
      props.textColor &&
      css`
        color: ${props.textColor};
      `}
  }
`;
