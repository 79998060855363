import {
  Link,
  matchPath,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";
import styled from "styled-components";

import { IconCross } from "elevar-design-system/src/icons";
import { Tabs } from "elevar-design-system/src/Tabs";
import { heading2Styles } from "elevar-design-system/src/typography/typography";

import {
  RedirectWithoutLastLocation,
  useLastLocation
} from "../../context/LastLocation";
import { TitleProvider } from "../../context/Title";
import { General } from "./General";
import { Memberships } from "./Memberships";
import { Notifications } from "./Notifications";
import { Password } from "./Password";

/* ========================================================================== */

const generalPath = "/account/general";
const passwordPath = "/account/password/:resetKey?";
const membershipsPath = "/account/memberships";
const notificationsPath = "/account/notifications";

export const AccountSettings: React.FC = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={generalPath}
        render={() => (
          <TitleProvider page="General">
            <AccountSettingsPage>
              <General />
            </AccountSettingsPage>
          </TitleProvider>
        )}
      />
      <Route
        exact={true}
        path={passwordPath}
        render={() => (
          <TitleProvider page="Password">
            <AccountSettingsPage>
              <Password />
            </AccountSettingsPage>
          </TitleProvider>
        )}
      />
      <Route
        exact={true}
        path={membershipsPath}
        render={() => (
          <TitleProvider page="Memberships">
            <AccountSettingsPage>
              <Memberships />
            </AccountSettingsPage>
          </TitleProvider>
        )}
      />
      <Route
        exact={true}
        path={notificationsPath}
        render={() => (
          <TitleProvider page="Notifications">
            <AccountSettingsPage>
              <Notifications />
            </AccountSettingsPage>
          </TitleProvider>
        )}
      />
      <RedirectWithoutLastLocation to="/" />
    </Switch>
  );
};

/* ========================================================================== */

type AccountSettingsPageProps = {
  children: React.ReactNode;
};

const AccountSettingsPage: React.FC<AccountSettingsPageProps> = ({
  children
}) => {
  const match = useRouteMatch();
  const lastLocation = useLastLocation();

  const isTabActive = (path: string) => matchPath(match.url, path) !== null;

  return (
    <AccountSettingsPageWrapper>
      <div>
        <AccountSettingsPageHeader>
          <div>
            <AccountSettingsPageHeading>
              Account Settings
            </AccountSettingsPageHeading>
          </div>
          <div>
            <AccountSettingsPageCloseLink to={lastLocation ?? "/"}>
              <IconCross size="16px" />
            </AccountSettingsPageCloseLink>
          </div>
        </AccountSettingsPageHeader>
        <TabsWrapper>
          <Tabs
            items={[
              {
                title: "General",
                isActive: isTabActive(generalPath),
                type: "INTERNAL_LINK",
                to: "/account/general"
              },
              {
                title: "Password",
                isActive: isTabActive(passwordPath),
                type: "INTERNAL_LINK",
                to: "/account/password"
              },
              {
                title: "Memberships",
                isActive: isTabActive(membershipsPath),
                type: "INTERNAL_LINK",
                to: "/account/memberships"
              },
              {
                title: "Notifications",
                isActive: isTabActive(notificationsPath),
                type: "INTERNAL_LINK",
                to: "/account/notifications"
              }
            ]}
          />
        </TabsWrapper>
        {children}
      </div>
    </AccountSettingsPageWrapper>
  );
};

const AccountSettingsPageWrapper = styled.div`
  background-color: ${props => props.theme.palette.grey7};

  > div {
    min-height: 100vh;
    max-width: ${props => props.theme.gridBase * 62.5}px;
    display: flex;
    flex-direction: column;
    padding-top: ${props => props.theme.gridBase * 4}px;
    padding-bottom: ${props => props.theme.gridBase * 5}px;
    margin: 0 auto;
  }
`;

const AccountSettingsPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AccountSettingsPageHeading = styled.div`
  ${heading2Styles};
  margin-bottom: ${props => props.theme.gridBase * 2.5}px;
`;

const AccountSettingsPageCloseLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.gridBase}px;
  border-radius: 2px;
  background-color: ${props => props.theme.palette.white};
  color: ${props => props.theme.palette.grey3};
`;

const TabsWrapper = styled.div`
  margin-left: ${props => props.theme.gridBase * -2}px;
  margin-bottom: ${props => props.theme.gridBase * 4}px;
`;
