import { useParams } from "react-router-dom";
import styled from "styled-components";

import { type WebsiteDetails } from "elevar-common-ts/src/apiTypes";

import { ErrorOccurred } from "elevar-design-system/src/ErrorOccurred";
import { Spinner } from "elevar-design-system/src/Spinner";

import { type AppEducationSlideshowNode } from "../../api/handlers/appEducationSlideshows";
import { useContainerDetailsListQuery } from "../../api/handlers/containerDetails";
import { PreBuiltTagsItem } from "./PreBuiltTagsItem";
import { PreBuiltTagsList } from "./PreBuiltTagsList";

/* ========================================================================== */

type PreBuiltTagsProps = {
  appEducationSlideshows: Array<AppEducationSlideshowNode>;
  website: WebsiteDetails;
};

export const PreBuiltTags: React.FC<PreBuiltTagsProps> = ({
  appEducationSlideshows,
  website
}) => {
  const { slug } = useParams<{ slug?: string }>();

  const containerDetailsList = useContainerDetailsListQuery({
    version: website.data_layer_version
  });

  if (containerDetailsList.error) {
    return (
      <CenteredWrapper>
        <ErrorOccurred />
      </CenteredWrapper>
    );
  }

  if (containerDetailsList.data === undefined) {
    return (
      <CenteredWrapper>
        <Spinner size="24px" />
      </CenteredWrapper>
    );
  }

  if (slug) {
    const containerDetails = containerDetailsList.data.find(
      containerDetails => containerDetails.linkQueryParam === slug
    );

    if (containerDetails) {
      return <PreBuiltTagsItem containerDetails={containerDetails} />;
    } else {
      return (
        <CenteredWrapper>
          <ErrorOccurred />
        </CenteredWrapper>
      );
    }
  } else {
    return (
      <PreBuiltTagsList
        appEducationSlideshows={appEducationSlideshows}
        containerDetailsList={containerDetailsList.data}
      />
    );
  }
};

/* ========================================================================== */

const CenteredWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
