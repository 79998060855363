type IconShopifyProps = {
  size: "24px";
  className?: string;
};

export const IconShopify: React.FC<IconShopifyProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.2708 5.27885C19.2579 5.16286 19.1548 5.11131 19.0775 5.11131C19.0002 5.11131 17.3118 4.98242 17.3118 4.98242C17.3118 4.98242 16.139 3.80961 15.9972 3.69361C15.8683 3.56473 15.6235 3.6034 15.5204 3.62917C15.5075 3.62917 15.2626 3.7065 14.8631 3.83538C14.4635 2.68834 13.7805 1.64441 12.5561 1.64441H12.4401C12.105 1.20621 11.6668 1 11.306 1C8.47057 1 7.10443 4.54423 6.67913 6.34857C5.57075 6.69654 4.78457 6.92853 4.69436 6.96719C4.07573 7.16051 4.06284 7.1734 3.98551 7.75337C3.92107 8.19156 2.31006 20.6544 2.31006 20.6544L14.8502 23L21.6551 21.5308C21.668 21.505 19.2837 5.39484 19.2708 5.27885ZM14.1671 4.01582C13.8578 4.10603 13.484 4.22203 13.1103 4.35091V4.11892C13.1103 3.42296 13.0201 2.85589 12.8525 2.4048C13.4969 2.48213 13.8965 3.19098 14.1671 4.01582ZM12.0664 2.54657C12.2339 2.98477 12.3499 3.6034 12.3499 4.45401V4.58289C11.6539 4.80199 10.9193 5.02109 10.1589 5.26596C10.5842 3.64206 11.3962 2.843 12.0664 2.54657ZM11.2415 1.74751C11.3704 1.74751 11.4993 1.79906 11.6024 1.87639C10.6873 2.3017 9.72072 3.37141 9.32119 5.53661C8.71545 5.72993 8.13548 5.89748 7.58129 6.07791C8.04527 4.42824 9.20519 1.74751 11.2415 1.74751Z"
            fill="#95BF47"
          />
          <path
            d="M19.0776 5.08548C19.0002 5.08548 17.3119 4.95659 17.3119 4.95659C17.3119 4.95659 16.1391 3.78378 15.9973 3.66778C15.9457 3.61623 15.8813 3.59045 15.8297 3.59045L14.8889 22.9999L21.6938 21.5307C21.6938 21.5307 19.3095 5.39479 19.2966 5.2788C19.2451 5.1628 19.1549 5.11125 19.0776 5.08548Z"
            fill="#5E8E3E"
          />
          <path
            d="M12.5561 8.84881L11.7312 11.3362C11.7312 11.3362 10.9837 10.9367 10.0944 10.9367C8.76695 10.9367 8.70251 11.7744 8.70251 11.9806C8.70251 13.1148 11.6668 13.553 11.6668 16.2208C11.6668 18.3216 10.3393 19.6748 8.54785 19.6748C6.38265 19.6748 5.30005 18.3345 5.30005 18.3345L5.88001 16.427C5.88001 16.427 7.01417 17.4065 7.96789 17.4065C8.5994 17.4065 8.85717 16.9168 8.85717 16.5559C8.85717 15.0738 6.4342 15.0093 6.4342 12.5606C6.4342 10.5114 7.90345 8.52661 10.8806 8.52661C11.989 8.51372 12.5561 8.84881 12.5561 8.84881Z"
            fill="white"
          />
        </svg>
      );
  }
};
