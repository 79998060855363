type IconCoffeeProps = {
  size: "16px";
  color?: string;
  className?: string;
};

export const IconCoffee: React.FC<IconCoffeeProps> = ({
  size,
  color = "currentColor",
  className
}) => {
  switch (size) {
    case "16px":
      return (
        <svg
          className={className}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4.16602 1.33008C4.16602 1.05394 3.94216 0.830078 3.66602 0.830078C3.38987 0.830078 3.16602 1.05394 3.16602 1.33008V3.33008C3.16602 3.60622 3.38987 3.83008 3.66602 3.83008C3.94216 3.83008 4.16602 3.60622 4.16602 3.33008V1.33008Z"
            fill={color}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 5.99609C0.5 5.71995 0.723858 5.49609 1 5.49609H12.3327C13.1725 5.49609 13.978 5.82972 14.5719 6.42359C15.1657 7.01745 15.4993 7.82291 15.4993 8.66276C15.4993 9.50261 15.1657 10.3081 14.5719 10.9019C13.978 11.4958 13.1725 11.8294 12.3327 11.8294H12.1667V11.9961C12.1667 12.8359 11.833 13.6414 11.2392 14.2353C10.6453 14.8291 9.83985 15.1628 9 15.1628H3.66667C2.82681 15.1628 2.02136 14.8291 1.4275 14.2353C0.83363 13.6414 0.5 12.8359 0.5 11.9961V5.99609ZM12.1667 10.8294V6.49609H12.3327C12.9073 6.49609 13.4584 6.72437 13.8647 7.1307C14.2711 7.53702 14.4993 8.08813 14.4993 8.66276C14.4993 9.2374 14.2711 9.7885 13.8647 10.1948C13.4584 10.6012 12.9073 10.8294 12.3327 10.8294H12.1667ZM11.1667 6.49609V11.3037L11.1661 11.3196L11.166 11.3294C11.166 11.3381 11.1662 11.3466 11.1667 11.3552V11.9961C11.1667 12.5707 10.9384 13.1218 10.5321 13.5282C10.1257 13.9345 9.57464 14.1628 9 14.1628H3.66667C3.09203 14.1628 2.54093 13.9345 2.1346 13.5282C1.72827 13.1218 1.5 12.5707 1.5 11.9961V6.49609H11.1667Z"
            fill={color}
          />
          <path
            d="M6.33203 0.830078C6.60817 0.830078 6.83203 1.05394 6.83203 1.33008V3.33008C6.83203 3.60622 6.60817 3.83008 6.33203 3.83008C6.05589 3.83008 5.83203 3.60622 5.83203 3.33008V1.33008C5.83203 1.05394 6.05589 0.830078 6.33203 0.830078Z"
            fill={color}
          />
          <path
            d="M9.5 1.33008C9.5 1.05394 9.27614 0.830078 9 0.830078C8.72386 0.830078 8.5 1.05394 8.5 1.33008V3.33008C8.5 3.60622 8.72386 3.83008 9 3.83008C9.27614 3.83008 9.5 3.60622 9.5 3.33008V1.33008Z"
            fill={color}
          />
        </svg>
      );
  }
};
