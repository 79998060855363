import { type CubeApi, type Query, type ResultSet } from "@cubejs-client/core";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { useCubeApiDetails } from "../../context/CubeApiDetails";
import { useTimezone } from "../../context/Timezone";
import { useWebsiteId } from "../../utils/idHooks";

/* ========================================================================== */

const userTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

const fetchCubeQuery = async <T>(cubeApi: CubeApi, query: Query) => {
  const resultSet = await cubeApi.load(query, { castNumerics: true });
  return { resultSet: resultSet as ResultSet<T> };
};

type UseCubeQueryArgs = { query: Query };

export const useCubeQuery = <T>({ query }: UseCubeQueryArgs) => {
  const websiteId = useWebsiteId();
  const timezone = useTimezone();
  const cubeApiDetails = useCubeApiDetails();

  const queryWithTimezone = {
    ...query,
    timezone: timezone.selection === "SYSTEM" ? userTimezone : timezone.value
  };

  return useQuery({
    queryKey: ["websites", websiteId, "cube", queryWithTimezone],
    queryFn: () => {
      return fetchCubeQuery<T>(cubeApiDetails.cubeApi, queryWithTimezone);
    }
  });
};

/* -------------------------------------------------------------------------- */

type UseConfigurableCubeQueryArgs = { baseQuery: Query };

export const useConfigurableCubeQuery = <T>({
  baseQuery
}: UseConfigurableCubeQueryArgs) => {
  const [dimensions, setDimensions] = useState(baseQuery.dimensions ?? []);
  const query = { ...baseQuery, dimensions: dimensions.toSorted() };
  const result = useCubeQuery<T>({ query });
  return { queryResult: result, setQueryDimensions: setDimensions };
};
