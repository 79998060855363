import { useSelect } from "downshift";
import styled, { css, useTheme } from "styled-components";

import { IconChevronDown } from "../icons";
import { scrollbarMixin } from "../scrollbar";
import { useDownshiftEnvironment } from "../shadowRootHandlers";
import { largeTextStyles, normalBodyStyles } from "../typography/typography";
import {
  inputFieldFocusStyles,
  type InputFieldProps,
  inputFieldStyles
} from "./sharedInputStyles";

/* ========================================================================== */

export type Option<T = string> = {
  name: string;
  value: T;
};

/* ========================================================================== */

type InputFieldSelectProps<T> = InputFieldProps & {
  value: Option<T> | null;
  setValue: (value: Option<T>) => void;
  options: Array<Option<T>>;
  placeholder: string;
  disabled?: boolean;
  renderedOn?: "CARD" | "PAGE";
  maxItemsInView?: number;
  contentsWhenEmpty?: () => React.ReactNode;
};

export const InputFieldSelect = <T,>({
  value,
  setValue,
  options,
  variant,
  placeholder,
  disabled = false,
  renderedOn = "CARD",
  maxItemsInView = 5,
  error = false,
  contentsWhenEmpty
}: InputFieldSelectProps<T>): ReturnType<React.FC> => {
  const theme = useTheme();
  const downshiftEnvironment = useDownshiftEnvironment();

  const {
    selectedItem,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    environment: downshiftEnvironment,
    items: options,
    itemToString: item => item?.name ?? "",
    selectedItem: value,
    onSelectedItemChange: ({ selectedItem }) => setValue(selectedItem)
  });

  /**
   * NOTE:
   * The below `event.preventDefault()` call is needed so that when
   * this component is wrapped inside an `InputWrapper` component, the
   * menu closes correctly when a menu item is selected. This was not
   * happening in the explained case because the `InputWrapper` wraps
   * its children in a `label` element, meaning that any clicks inside
   * of it focuses the wrapped input. In the explained case, this meant
   * that when an menu item was selected, it triggered the label logic
   * explained above, causing the menu to stay open.
   */

  return (
    <Wrapper onClick={event => event.preventDefault()}>
      <Button
        {...getToggleButtonProps({
          variant,
          error,
          renderedOn,
          disabled,
          selectedItem,
          isMenuOpen: isOpen
        })}
      >
        {selectedItem?.name ?? placeholder}
      </Button>
      <IconWrapper variant={variant}>
        <IconChevronDown
          size="16px"
          color={disabled ? theme.palette.grey5 : theme.palette.grey4}
        />
      </IconWrapper>
      <MenuWrapper isOpen={isOpen}>
        <Menu {...getMenuProps({ variant, maxItemsInView })}>
          {isOpen
            ? options.length > 0 || contentsWhenEmpty === undefined
              ? options.map((item, index) => (
                  <MenuItem
                    key={item.name}
                    highlighted={index === highlightedIndex}
                    {...getItemProps({ item, index, variant })}
                  >
                    {item.name}
                  </MenuItem>
                ))
              : contentsWhenEmpty()
            : null}
        </Menu>
      </MenuWrapper>
    </Wrapper>
  );
};

/* ========================================================================== */

const Wrapper = styled.div`
  position: relative;
`;

type ButtonProps = InputFieldProps & {
  renderedOn: InputFieldSelectProps<unknown>["renderedOn"];
  selectedItem: Option<unknown> | null;
  isMenuOpen: boolean;
};

const Button = styled.button<ButtonProps>`
  ${inputFieldStyles};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: ${props =>
    props.variant === "LARGE"
      ? props.theme.gridBase * 6.5
      : props.theme.gridBase * 4.5}px;

  ${props =>
    props.selectedItem === null &&
    css<ButtonProps>`
      color: ${props => props.theme.palette.grey4};

      &:disabled {
        color: ${props => props.theme.palette.grey5};
      }
    `};

  ${props =>
    props.renderedOn === "PAGE" &&
    css<ButtonProps>`
      &,
      &:hover&:not(:disabled),
      &:disabled {
        border-color: ${props => props.theme.palette.white};
        background-color: ${props => props.theme.palette.white};
      }

      &:focus&:not(:disabled) {
        border-color: ${props => props.theme.palette.blue1};
      }
    `};

  ${props =>
    props.isMenuOpen &&
    css<ButtonProps>`
      &,
      &:hover&:not(:disabled) {
        ${inputFieldFocusStyles};
      }
    `};
`;

type IconWrapperProps = {
  variant: InputFieldProps["variant"];
};

const IconWrapper = styled.div<IconWrapperProps>`
  pointer-events: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${props =>
    props.variant === "LARGE"
      ? props.theme.gridBase * 2
      : props.theme.gridBase * 1.5}px;
`;

type MenuWrapperProps = {
  isOpen: boolean;
};

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: absolute;
  top: calc(100% + ${props => props.theme.gridBase}px);
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.grey6};
  background-color: ${props => props.theme.palette.white};
  box-shadow: ${props => props.theme.other.boxShadowDropdown};
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  z-index: 2;
`;

type MenuProps = {
  variant: InputFieldProps["variant"];
  maxItemsInView: number;
};

const Menu = styled.ul<MenuProps>`
  ${scrollbarMixin};
  margin: ${props => props.theme.gridBase * 0.5}px;
  overflow-y: auto;
  max-height: ${props =>
    props.variant === "SMALL"
      ? props.theme.gridBase * props.maxItemsInView * 5 +
        props.theme.gridBase * 2.5
      : props.theme.gridBase * props.maxItemsInView * 6 +
        props.theme.gridBase * 3}px;
`;

type MenuItemProps = {
  variant: InputFieldProps["variant"];
  highlighted: boolean;
};

const MenuItem = styled.li<MenuItemProps>`
  ${props => props.variant === "SMALL" && normalBodyStyles};
  ${props => props.variant === "LARGE" && largeTextStyles};
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  background-color: ${props =>
    props.highlighted ? props.theme.palette.grey8 : props.theme.palette.white};
  transition: background-color ${props => props.theme.other.transition};
  padding: ${props =>
    props.variant === "SMALL"
      ? props.theme.gridBase
      : props.theme.gridBase * 1.5}px;
`;
