type IconStackAdaptProps = {
  size: "24px";
  className?: string;
};

export const IconStackAdapt: React.FC<IconStackAdaptProps> = ({
  size,
  className
}) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.0285 5.00182L18.3609 0L9.82999 0.00218182C6.60417 0.00327273 3.67181 1.87309 2.30817 4.79564C0.944534 7.71927 1.39835 11.1676 3.47108 13.6385L7.96562 18.9982L5.63326 24L14.1653 23.9978C17.3911 23.9967 20.3234 22.128 21.6871 19.2044C23.0507 16.2807 22.5969 12.8324 20.5242 10.3615L16.0285 5.00182ZM19.1638 18.0284C18.8987 18.5956 18.3293 18.9589 17.7031 18.9589L9.52126 18.9567L11.3049 15.132L5.05617 7.68873C4.65362 7.20873 4.56526 6.54 4.83035 5.97273C5.09544 5.40545 5.6649 5.04218 6.29108 5.04218L14.4729 5.04436L12.6893 8.86909L18.938 16.3124C19.3405 16.7913 19.4289 17.4611 19.1638 18.0284Z"
            fill="#0061FF"
          />
        </svg>
      );
  }
};
