type IconGTMProps = {
  size: "24px";
  className?: string;
};

export const IconGTM: React.FC<IconGTMProps> = ({ size, className }) => {
  switch (size) {
    case "24px":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.0702 22.9638L9.909 18.9061L18.8458 9.77997L23.1541 14.0025L14.0702 22.9638Z"
            fill="#8AB4F8"
          />
          <path
            d="M14.0879 5.02348L9.94168 0.790039L0.875472 9.77043C0.314853 10.3322 0 11.0935 0 11.8871C0 12.6808 0.314853 13.4421 0.875472 14.0039L9.80547 22.9924L14.028 19.0423L7.22427 11.8871L14.0879 5.02348Z"
            fill="#4285F4"
          />
          <path
            d="M23.0942 9.8399L14.1043 0.849973C13.5398 0.300214 12.7816 -0.00512354 11.9937 6.50613e-05C11.2058 0.00525367 10.4516 0.320551 9.89448 0.877696C9.33733 1.43484 9.02204 2.189 9.01685 2.97691C9.01166 3.76481 9.317 4.52306 9.86676 5.0875L18.8567 14.0774C19.1336 14.3617 19.4642 14.5882 19.8293 14.7436C20.1944 14.8991 20.5868 14.9805 20.9836 14.9831C21.3805 14.9858 21.7739 14.9095 22.141 14.7589C22.5081 14.6082 22.8417 14.3861 23.1223 14.1055C23.4029 13.8249 23.625 13.4913 23.7757 13.1242C23.9263 12.7571 24.0025 12.3637 23.9999 11.9668C23.9973 11.57 23.9159 11.1776 23.7604 10.8125C23.605 10.4474 23.3785 10.1168 23.0942 9.8399Z"
            fill="#8AB4F8"
          />
          <path
            d="M11.9167 23.9459C13.5341 23.9459 14.8453 22.6348 14.8453 21.0174C14.8453 19.4 13.5341 18.0888 11.9167 18.0888C10.2993 18.0888 8.98819 19.4 8.98819 21.0174C8.98819 22.6348 10.2993 23.9459 11.9167 23.9459Z"
            fill="#246FDB"
          />
        </svg>
      );
  }
};
