import { tint } from "polished";
import styled from "styled-components";

import { subheadingStyles } from "./typography/typography";

/* ========================================================================== */

type TagProps = {
  text: string;
  textColor: string;
  className?: string;
};

export const Tag: React.FC<TagProps> = ({ text, textColor, className }) => {
  return (
    <TagWrapper textColor={textColor} className={className}>
      {text}
    </TagWrapper>
  );
};

/* ========================================================================== */

type TagWrapperProps = {
  textColor: string;
};

const TagWrapper = styled.div<TagWrapperProps>`
  ${subheadingStyles};
  width: max-content;
  color: ${props => props.textColor};
  border-radius: 2px;
  background-color: ${props => tint(0.95, props.textColor)};
  padding-top: ${props => props.theme.gridBase * 0.5}px;
  padding-bottom: ${props => props.theme.gridBase * 0.5}px;
  padding-left: ${props => props.theme.gridBase}px;
  padding-right: ${props => props.theme.gridBase}px;
`;
