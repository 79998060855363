import { downloadZip, type InputWithMeta } from "client-zip";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { useState } from "react";
import styled from "styled-components";

import {
  type EventsConnectorConfig,
  type MarketGroup
} from "elevar-common-ts/src/apiTypes";
import {
  applyWebConfiguration,
  type WebChannelKey
} from "elevar-common-ts/src/containers/configuration";
import { type Container as GtmContainer } from "elevar-common-ts/src/gtmTypes";

import { ButtonPrimary } from "elevar-design-system/src/buttons/ButtonVariants";
import { StyledLinkExternal } from "elevar-design-system/src/links/LinkExternal";
import { normalBodyStyles } from "elevar-design-system/src/typography/typography";

import { fetchCloudStorageFile } from "../../api/handlers/cloudStorage";
import { PageCard } from "../../components/PageCard";
import { toast } from "../../utils/toast";
import { track } from "../../utils/track";
import { type Destination } from "./data";
import { StepSection, type StepSectionProps } from "./StepSection";

/* ========================================================================== */

type WebContainerDestination = Extract<
  Destination,
  { configKey: WebChannelKey }
>;

const getDestinationShorthandFromKey = (key: WebChannelKey) => {
  switch (key) {
    case "ga4":
      return "ga4";
    case "facebook":
      return "fb";
    case "tiktok":
      return "tt";
    case "criteo":
      return "crt";
    case "snapchat":
      return "snp";
    case "pinterest":
      return "pin";
    case "awin":
      return "awin";
  }
};

/* ========================================================================== */

type ContainerSetupWebProps<T extends WebContainerDestination> = {
  isLoading: boolean;
  isStepCompleted: boolean;
  setupGuideHref: StepSectionProps["setupGuideHref"];
  isRequiredOverride?: boolean;
  containerUrl: string;
  eventsConnectorConfig: EventsConnectorConfig;
  details: {
    destination: T;
    config: EventsConnectorConfig[T["configKey"]][number];
  };
  onSave: () => void;
};

export const ContainerSetupWeb = <T extends WebContainerDestination>({
  isLoading,
  isStepCompleted,
  setupGuideHref,
  isRequiredOverride = false,
  containerUrl,
  eventsConnectorConfig,
  details,
  onSave
}: ContainerSetupWebProps<T>): ReturnType<React.FC> => {
  const [isDownloading, setIsDownloading] = useState(false);

  type Configs = EventsConnectorConfig[T["configKey"]];
  type Config = Configs[number];

  const configMatchesMarketGroup = (config: Config, group: MarketGroup) => {
    return config.all_markets || config.market_groups.includes(group.id);
  };

  const download = async () => {
    setIsDownloading(true);
    const key = details.destination.configKey;
    const sh = getDestinationShorthandFromKey(key);
    const container = await fetchCloudStorageFile<GtmContainer>(containerUrl);
    const time = dayjs().format("YYYY-MM-DD_HH-mm-ss");

    const configs = eventsConnectorConfig[key].filter(
      // Only include configs at/after this step (it's always the last)
      config => config.completedStep >= details.destination.stepCount - 1
    ) as Configs;

    const applicableMarketGroups = eventsConnectorConfig.marketGroups.filter(
      group =>
        group.gtm_container !== "" &&
        configs.some(c => configMatchesMarketGroup(c, group))
    );

    const files = applicableMarketGroups.map<InputWithMeta>(group => {
      const json = applyWebConfiguration(
        key,
        configs.filter(c => configMatchesMarketGroup(c, group)) as Configs,
        eventsConnectorConfig.globalConfig,
        container
      );

      return new File(
        [JSON.stringify(json)],
        `${sh}_${time}/${sh}-${group.gtm_container}_${time}.json`,
        { type: "application/json" }
      );
    });

    const blob = await downloadZip(files).blob();
    saveAs(blob, `${sh}_${time}`);
    toast.success("Container downloaded");
    setIsDownloading(false);
    track.destinationDownload(sh);
    track.containerSetupDownloadButtonClick();
  };

  const trackImportLinkClick = () => track.containerSetupImportLinkClick();
  const trackPublishLinkClick = () => track.containerSetupPublishLinkClick();

  const isRequired =
    isRequiredOverride ||
    ("enabledWebEvents" in details.config
      ? Object.values(details.config.enabledWebEvents).some(e => e)
      : false);

  if (isRequired) {
    return (
      <PageCard>
        <StepSection
          title="Web Container Setup"
          setupGuideHref={setupGuideHref}
          description={
            <GtmContainerSetupExplainer>
              With the configuration choices you've made, you'll need to
              download, import and publish GTM web tags for this destination.
            </GtmContainerSetupExplainer>
          }
          media={{
            type: "VIDEO",
            url: "https://player.vimeo.com/video/737673258",
            onStart: () => track.containerSetupVideoPlay()
          }}
        >
          <GtmContainerSetupRequiredInnerWrapper>
            <div>
              <div>
                <span>Part 1:</span> Start by downloading the following:
              </div>
              <ButtonPrimary
                variant="SMALL"
                state={isDownloading ? "LOADING" : "IDLE"}
                onClick={download}
              >
                Download Pre-Built Web Tags
              </ButtonPrimary>
            </div>
            <div>
              <div>
                <div>
                  <span>Part 2:</span> Once downloaded, extract the folder
                  within the zip. This folder contains a JSON file for each GTM
                  container that applies to this destination. Import each of the
                  files into the GTM containers whose ID matches the ID in the
                  file name.
                </div>
                <StyledLinkExternal
                  href="https://docs.getelevar.com/docs/web-container-setup#importing-the-container"
                  text="How do I do this?"
                  onClick={trackImportLinkClick}
                />
              </div>
              <div>
                <div>
                  <span>Part 3:</span> Once imported, publish the changes made
                  to your GTM container(s).
                </div>
                <StyledLinkExternal
                  href="https://docs.getelevar.com/docs/web-container-setup#publish-the-changes-in-your-gtm-container"
                  text="How do I do this?"
                  onClick={trackPublishLinkClick}
                />
              </div>
              <div>
                Note: Any disabled web events will be uploaded as paused tags.
              </div>
            </div>
          </GtmContainerSetupRequiredInnerWrapper>
          {!isStepCompleted ? (
            <GtmContainerSetupRequiredMarkCompleteButton
              variant="SMALL"
              state={isLoading ? "LOADING" : "IDLE"}
              onClick={onSave}
            >
              Mark as Complete
            </GtmContainerSetupRequiredMarkCompleteButton>
          ) : null}
        </StepSection>
      </PageCard>
    );
  } else {
    return (
      <PageCard>
        <StepSection
          title="Web Container Setup"
          description={
            <GtmContainerSetupExplainer>
              You're all set! We will send all your data to this destination via
              the server. What a win for site speed!
            </GtmContainerSetupExplainer>
          }
          media={{ type: "NONE", spanContent: true }}
        >
          <GtmContainerSetupNotRequiredInnerWrapper>
            <GtmContainerSetupExplainer>
              Do you have any previously imported web tags in your GTM (Google
              Tag Manager) Web Container(s) for this destination? If so, please
              pause or remove them.
            </GtmContainerSetupExplainer>
            {!isStepCompleted ? (
              <div>
                <ButtonPrimary
                  variant="SMALL"
                  state={isLoading ? "LOADING" : "IDLE"}
                  onClick={onSave}
                >
                  Continue
                </ButtonPrimary>
              </div>
            ) : null}
          </GtmContainerSetupNotRequiredInnerWrapper>
        </StepSection>
      </PageCard>
    );
  }
};

/* ========================================================================== */

const GtmContainerSetupExplainer = styled.div`
  ${normalBodyStyles};
  color: ${props => props.theme.palette.grey2};
`;

const GtmContainerSetupRequiredInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.gridBase * 3}px;

  span {
    font-weight: 500;
    color: ${props => props.theme.palette.grey1};
  }

  > div:first-child {
    > div {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};
      margin-bottom: ${props => props.theme.gridBase * 1.5}px;
    }
  }

  > div:last-child {
    > div {
      ${normalBodyStyles};
      color: ${props => props.theme.palette.grey2};

      &:not(:last-child) {
        margin-bottom: ${props => props.theme.gridBase * 2}px;
      }

      > div:first-child {
        margin-bottom: ${props => props.theme.gridBase * 0.5}px;
      }
    }
  }
`;

const GtmContainerSetupRequiredMarkCompleteButton = styled(ButtonPrimary)`
  margin-top: ${props => props.theme.gridBase * 4.5}px;
`;

const GtmContainerSetupNotRequiredInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${props => props.theme.gridBase * -2}px;
  gap: ${props => props.theme.gridBase * 2}px;
`;
